import { Directive,Input, ElementRef, OnInit } from '@angular/core';

@Directive({
    selector: '[focus]'
})
export class focusElementDirective implements OnInit {
    
    @Input() focus: boolean;

    constructor(
        private element: ElementRef
    ) {}

    public ngOnInit() {

        if(this.focus){
            setTimeout(() => {
                this.element.nativeElement.focus();
             });
        }
}
}