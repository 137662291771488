export const environmentsUrls = {
    "stages": [
        {
            "stage": "capability",
            "legacyUri": "mytelegacy-capability.ciostage.accenture.com",
            "nextUri": "myte-capability.ciostage.accenture.com"
        },
        {
            "stage": "devint",
            "legacyUri": "mytelegacy-devint.ciodev.accenture.com",
            "nextUri": "myte-devint.ciodev.accenture.com"
        },
        {
            "stage": "perf-alpha",
            "legacyUri": "mytelegacy-perf.ciostage.accenture.com",
            "nextUri": "myte-perf-alpha.ciostage.accenture.com"
        },
        {
            "stage": "training",
            "legacyUri": "mytelegacy-training.ciostage.accenture.com",
            "nextUri": "myte-training.ciostage.accenture.com"
        },
        {
            "stage": "uat",
            "legacyUri": "mytelegacy-uat.ciostage.accenture.com",
            "nextUri": "myte-uat.ciostage.accenture.com"
        },
        {
            "stage": "devint-hotfix",
            "legacyUri": "myte-devint-hotfix.ciodev.accenture.com",
            "nextUri": "mytenext-devinthotfix.ciodev.accenture.com"
        },
        {
            "stage": "perf-beta",
            "legacyUri": "myte-perf-beta.ciostage.accenture.com",
            "nextUri": "mytenext-perf.ciostage.accenture.com"
        },
        {
            "stage": "pt",
            "legacyUri": "myte-pt.ciostage.accenture.com",
            "nextUri": "mytenext-pt.ciostage.accenture.com"
        }
    ]
}