export enum TimeReportStatusCode {
    New = "N",
    Draft = "D",
    Submitted = "S",
    Processed = "P",
    DraftAdjustment = "DA",
    SubmittedAdjustment = "SA",
    SubmittedPendingAdjustment = "ARS",
    ProcessedAdjustment = "PA",
    SubmittedPendingApproval = "SFA",
    DraftPendingUpdates = "ARU",
    CompletedAdjustment = "ARS"
}

export enum TimeReportStatusCodeDescription {
    ARS = 'Completed Adjustment',
    ARU = 'Approver Requires Updates',
    D = 'Draft',
    DA = 'Draft Adjustment',
    N = 'New',
    P = 'Processed',
    PA = 'Processed Adjustment',
    S = 'Submitted',
    SA = 'Submitted Adjustment',
    SFA = 'Sent for Approval'
}

export namespace TimeReportStatusCode {
    export function getValueByString(type: string): TimeReportStatusCode {
        return (TimeReportStatusCode as any)[getKeyByValue(type)];
    }
    export function getKeyByValue(value: string): string{
        return Object.keys(TimeReportStatusCode).filter(k => (TimeReportStatusCode as any)[k] === value)[0];
    }
    export function getDescriptionByAcronym(type: string): TimeReportStatusCodeDescription {
        return (TimeReportStatusCodeDescription as any)[getKeyByValue(type)];
    }
}