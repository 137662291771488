import { OtherInformationList } from './otherInformationList';
import { commuteMetaDataList } from './commuteMetaDataList';

export class PersonalCommuteList {
    public otherInformation: OtherInformationList;
    public commuteMetaData: commuteMetaDataList;
    public currentValue: string;
    public isVisible: boolean;
    constructor(
        otherInformation?: OtherInformationList,
        commuteMetaData?: commuteMetaDataList,
        currentValue?: string,
        isVisible?: boolean
        ) {
       this.otherInformation = otherInformation;
       this.commuteMetaData = commuteMetaData;
       this.currentValue = currentValue;
       this.isVisible = isVisible;
    }
}