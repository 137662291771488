export default class AmexInput {
    public periodEnd: Date;
    public transactionId: string;
    public amexHotelImportDataId: string;
    public dateDisplay: string;
    public dateFrom: string;
    public expenseTypeCodeDisplay: string;
    public detailDisplay: string;
    public amountDisplay: number;
    public localCurrencyDisplay: string;
    public localCountryDisplay: string;
    public amexExpenseType: string;
    public dateDisplayToDateWithDayOfWeek: string;
    public expenseSelectedValue: string;
    public expenseSelectedKey: string;
    public stateProvinceCd: string;
    public shouldImportWithTransaction?: boolean;
    constructor(
        periodEnd?: Date,
        transactionId?: string,
        amexHotelImportDataId?: string,
        dateDisplay?: string,
        dateFrom?: string,
        expenseTypeCodeDisplay?: string,
        detailDisplay?: string,
        amountDisplay?: number,
        localCurrencyDisplay?: string,
        localCountryDisplay?: string,
        amexExpenseType?: string,
        dateDisplayToDateWithDayOfWeek?: string,
        expenseSelectedValue?: string,
        expenseSelectedKey?: string,
        stateProvinceCd?: string,
        shouldImportWithTransaction?: boolean
    ) {
        this.periodEnd = periodEnd;
        this.transactionId = transactionId;
        this.amexHotelImportDataId = amexHotelImportDataId;
        this.dateDisplay = dateDisplay;
        this.dateFrom = dateFrom;
        this.expenseTypeCodeDisplay = expenseTypeCodeDisplay;
        this.detailDisplay = detailDisplay;
        this.amountDisplay = amountDisplay;
        this.localCurrencyDisplay = localCurrencyDisplay;
        this.localCountryDisplay = localCountryDisplay;
        this.amexExpenseType = amexExpenseType;
        this.dateDisplayToDateWithDayOfWeek = dateDisplayToDateWithDayOfWeek;
        this.expenseSelectedValue = expenseSelectedValue;
        this.expenseSelectedKey = expenseSelectedKey;
        this.stateProvinceCd = stateProvinceCd;
        this.shouldImportWithTransaction = shouldImportWithTransaction;
    }
}