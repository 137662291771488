export const AuditorToolTips = {
  REVERSE_MULTIPLE_TOOLTIP:
    "If no expenses are selected, this button will reverse all expenses in this time report for which a physical receipt is required and has not been received",
  REVERSE_TOOLTIP: "Set expense amount to 0",
  COMPLETE_AUDIT_TOOLTIP: "Close this audit if no open issues",
};
export const AuditorButtons = {
  REVERSE_MULTIPLE_BTN: "Reverse Multiple",
  REVERSE_BTN: "Reverse",
  RAISE_ISSUE_BTN: "Raise Issue",
  COMPLETE_AUDIT_BTN: "Complete Audit",
  MY_AUDITS_BTN: "My Audits",
  MY_ESCALATED_BTN: "My Escalated Expenses",  
};

export const AuditorMessages ={
   errormessage: "Please close all open issues for the expense before escalating it.",
   auditSupport:"AuditSupport",
   auditLead:"AuditLead",
   escalateTitle:"Escalate expense",
   auditorLabelmsg: "Please select a reason before you escalate the expense. Use the Comments tab to add information about the escalation. Remember that escalations can only be undone by Audit Team Leads.",
};
