
import { ShellModel } from '../../../../app-shell/data-store';

export class OTBalanceRequest extends ShellModel {
    public passed: boolean;
    public availableHours: string;
    public requestedHours: string;
    public errorMessage: string;

    constructor(passed?: boolean, availableHours?: string, requestedHours?: string, errorMessage?: string) {
        super();
    
        this.passed = passed;
        this.availableHours = availableHours;
        this.requestedHours = requestedHours;
        this.errorMessage = errorMessage;
    }
}

export enum CashOutInputError {
    title = "Invalid Cash-Out Amount",
    message = "Text box of Cash-Out can not be zero or empty"
}

export enum CashOutExceedHours {
    message = "You do not have enough hours in your available Overtime Balance to complete this request. You can check your available Overtime Balance on the Summary Tab."
}

export enum CashOutInvalidDate {
    message = "INVALID_CASHOUT_DATE_REQUEST"
}