import { ShellModel } from "../../../app-shell/data-store";
import { ExpenseHistory } from "./expense-history.model";

export class ExpenseHistories extends ShellModel {
    public expenseHistory: Array<ExpenseHistory>;

    constructor(expenseHistory?: Array<ExpenseHistory>) {
        super();
    
        this.expenseHistory = expenseHistory ?? new Array(3).fill(new ExpenseHistory());
    }
}