import { Component, Input, Output, EventEmitter, HostListener, OnInit, ViewChild } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
    selector: 'myte-popup-template',
    templateUrl: './popup-template.component.html',
    animations: [
        trigger(
            'enterAnimation', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('200ms', style({ opacity: 1 }))
            ]),
            transition(':leave', [
                style({ opacity: 1 }),
                animate('200ms', style({ opacity: 0 }))
            ])
        ]
        )
    ],
    styleUrls: ['./popup-template.component.sass']
})
export class PopUpTemplateComponent {
    @Input() title: string;
    @Input() hasSummaryCard: boolean = false;
    @Input() isPunchClock: boolean = false;
    @Input() isRestHours: boolean = false;
    @Input() label: string;
    @Input() loading: boolean = false;
    @Input() displayCloseButton: boolean = true;
    @Input() isIdleSessionPopup: boolean = false;
    @Output() closeEvent = new EventEmitter();
    @Input() isNeedCloseDisable: boolean;
    @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) { this.closePopup(); }
    public isPersonalPopup: boolean = false;
    @Output() closePopupFocus = new EventEmitter<boolean>();

    constructor() { }

    ngOnInit() {
        if (!this.label) this.label = this.title;
        //this.title == "Personal" ? this.isPersonalPopup = true : this.isPersonalPopup = false; 
    }

    public onFocusCloseButton() {
        this.closePopupFocus.emit(true);
    }

    public onBlurCloseButton() {
        this.closePopupFocus.emit(false);
    }

    public closePopup(): void {
        this.closeEvent.emit();
    }
}
