export class LocationList {
    public name: string;
    public value: string;
    public isDefault: boolean;
    constructor(
        name?: string,
        value?: string,
        isDefault?: boolean
        ) {
       this.name = name;
       this.value = value;
       isDefault = isDefault;
    }
}