import { TimeCategoryUIType } from "../time-category/time-category-task";
import { WorkShiftEntry } from "./work-shift-entry";

export class WorkShift {
    public entries: WorkShiftEntry[];
    public editable: boolean;
    public name: string;
    public UiType: TimeCategoryUIType;
    public code: string;
    public shiftTypes: WorkShiftDropdownItem[];

    public GetCode(value: string): string {
        for (let shiftType of this.shiftTypes) {
            if (shiftType.value == value)
                return shiftType.key;
        }
        return '';
    }

    public GetValue(key: string): string {
        for (let shiftType of this.shiftTypes) {
            if (shiftType.key == key)
                return shiftType.value;
        }
        return '';
    }
}

export class WorkShiftDropdownItem {
    public key?: string;
    public value?: string;
}