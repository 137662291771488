export class ReceiptRequirement {
  type: ReceiptRequirementTypes;
  electronicProvided: boolean;
  electronicTooltip: string;
  physicalProvided: boolean;
  physicalTooltip: string;

  constructor(type?: ReceiptRequirementTypes, electronic?: boolean, electronicTooltip?: string, physical?: boolean, physicalTooltip?: string) {
    this.type = type;
    this.electronicProvided = electronic;
    this.electronicTooltip = electronicTooltip;
    this.physicalProvided = physical;
    this.physicalTooltip = physicalTooltip;
  }
}

export enum ReceiptRequirementTypes {
  None = 0,
  Neither = 1,
  Electronic = 2,
  Physical = 3,
  Both = 4,
  Either = 5
}
