import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { OAuth2Service } from '../services/oauth2.service';

@Injectable()
export class AuthGuard  {

  constructor(
    private oauth2Service: OAuth2Service,
  ) {}

  canActivate(router: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.oauth2Service.handleAuthentication(state.url);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.canActivate(route, state);
  }
}
