import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpInterceptor, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, mergeMap, materialize, dematerialize } from 'rxjs/operators';
import { LogService } from '@sharedServices/log/log.service';
import { ExpenseRequestsMocks, TimeRequestMock, SummaryRequestMock, AdjustmentRequestMock } from '@sharedInterceptors/constants';


@Injectable()
export class FakeBackendInterceptor implements HttpInterceptor {

  constructor(private http: HttpClient, private logService: LogService) {
    this.logService.logWarning('Myte is in Mock Mode!');
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return of(null).pipe(mergeMap(() => {

      //GetTimeReportSummary
      if (request.url.match(SummaryRequestMock.getTimeReportSummary.url) &&
        (request.method === SummaryRequestMock.getTimeReportSummary.requestType)) {
        return this.http.get(SummaryRequestMock.getTimeReportSummary.urlJson).pipe(
          map(viewTime => new HttpResponse({
            status: 200, body: new Blob([JSON.stringify(viewTime)], { type: 'application/json' })
          }))
        );
      }

      //GetAnnualChargeabilityInfo
      if (request.url.match('/GetAnnualChargeabilityInfo?') && request.method === 'GET') {
        return this.http.get('../../../assets/data/annual-chargeability-mock.json').pipe(
          map(viewTime => new HttpResponse({
            status: 200,
            body: new Blob([JSON.stringify(viewTime)],
              { type: 'application/json' })
          }))
        );
      }

      // ExpenseList
      // TODO (elias.e.wasinger): Compare the request's body on other way
      if ((request.url.match(ExpenseRequestsMocks.expensesGeneralGrid.url) &&
        (request.method === ExpenseRequestsMocks.expensesGeneralGrid.requestType)) &&
        (request.body === ExpenseRequestsMocks.expensesGeneralGrid.requestBody)) {
          return this.http.get(ExpenseRequestsMocks.expensesGeneralGrid.urlJson).pipe(
            map(expenseJson => new HttpResponse(
              { status: 200, body: new Blob([JSON.stringify(expenseJson)], { type: 'application/json' }) }
            ))
          );
      } else {
        if ((request.url.match(ExpenseRequestsMocks.expensesGridAfterSaveTaxiTravel.url) &&
            (ExpenseRequestsMocks.compareBody(request.body, ExpenseRequestsMocks.expensesGridAfterSaveTaxiTravel)))) {
              return this.http.get(ExpenseRequestsMocks.expensesGridAfterSaveTaxiTravel.urlJson).pipe(
                map(expenseJson => new HttpResponse(
                  { status: 200, body: new Blob([JSON.stringify(expenseJson)], { type: 'application/json' }) }
                ))
        )
        }
      }

      // GetTripNames
      if ((request.url.match(ExpenseRequestsMocks.getTripNames.url)) &&
        (request.method === ExpenseRequestsMocks.getTripNames.requestType) &&
        (request.body === ExpenseRequestsMocks.getTripNames.requestBody)) {
        return this.http.get(ExpenseRequestsMocks.getTripNames.urlJson).pipe(
          map(expenseJson => new HttpResponse(
            { status: 200, body: new Blob([JSON.stringify(expenseJson)], { type: 'application/json' }) }
          ))
        );
      }

      // viewExpenseTypes
      if (request.url.match('/ViewExpenseTypesForUser') && request.method === 'POST') {
        return this.http.get('../../../assets/data/expenses-tab/expense-types-mock.json').pipe(
          map(viewExpensesJson => new HttpResponse({ status: 200, body: new Blob([JSON.stringify(viewExpensesJson)], { type: 'application/json' }) })));
      }
      // GetUserSettings
      if (request.url.match('/GetUserSettings') && request.method === 'POST') {
        return this.http.get('../../../assets/data/user/get-usersettings-mock.json').pipe(
          map(viewExpensesJson => new HttpResponse({ status: 200, body: new Blob([JSON.stringify(viewExpensesJson)], { type: 'application/json' }) })));
      }

      // ExpenseDetails
      // TODO (elias.e.wasinger): Compare the request's body on other way
      if ((request.url === ExpenseRequestsMocks.expenseDetailsAddTaxiTravel.url) &&
          (ExpenseRequestsMocks.compareBody(request.body, ExpenseRequestsMocks.expenseDetailsAddTaxiTravelTriggerByAmount))) {
            return this.http.get(ExpenseRequestsMocks.expenseDetailsAddTaxiTravelTriggerByAmount.urlJson).pipe(
              map(expenseDetailOutput => new HttpResponse({
                status: 200,
                body: new Blob([JSON.stringify(expenseDetailOutput)],
                  { type: 'application/json' })
              }))
            );
      } else {
        if (request.url === ExpenseRequestsMocks.expenseDetailsAddTaxiTravel.url) {
          return this.http.get(ExpenseRequestsMocks.expenseDetailsAddTaxiTravel.urlJson).pipe(
            map(expenseDetailOutput => new HttpResponse({
              status: 200,
              body: new Blob([JSON.stringify(expenseDetailOutput)],
                { type: 'application/json' })
            }))
          );
        }
      }

      // Save Travel Taxi - EX01
      if ((request.url === ExpenseRequestsMocks.expensesSaveTaxiTravel.url) &&
          (ExpenseRequestsMocks.compareBody(request.body, ExpenseRequestsMocks.expensesSaveTaxiTravel))) {
            return this.http.get(ExpenseRequestsMocks.expensesSaveTaxiTravel.urlJson).pipe(
              map(expenseDetailOutput => new HttpResponse({
                status: 200,
                body: new Blob([JSON.stringify(expenseDetailOutput)],
                  { type: 'application/json' })
              }))
            );
      }
      // ExpenseDetails - Version 2

      /* if (request.url.match(RequestsMocks.expensesGeneralGrid.url)) {
        if (RequestsMocks.expensesGeneralGrid.requestBody === request.body) {
          return this.http.get(RequestsMocks.expensesGeneralGrid.urlJson).pipe(
            map(expenseDetailOutput => new HttpResponse({
              status: 200,
              body: new Blob([JSON.stringify(expenseDetailOutput)],
                { type: 'application/json' })
            })));
        }
      } */

      // Save expenses - Travel - Taxi EX01
      if (request.url.match('/Expenses/SaveExpense')) {
        return this.http.get('../../../assets/data/expenses/SaveExpenses/taxiTravelSave.json').pipe(
          map(expenseDetailOutput => new HttpResponse({
            status: 200,
            body: new Blob([JSON.stringify(expenseDetailOutput)],
              { type: 'application/json' })
          })));
      }

      // ExpenseSplit
      if (request.url.match('/ExpenseSplit?') && request.method === 'POST') {
        return this.http.get('../../../assets/data/expenses-tab/expense-split-mock.json').pipe(
          map(expenseSplit => new HttpResponse({
            status: 200,
            body: new Blob([JSON.stringify(expenseSplit)],
              { type: 'application/json' })
          }))
        );
      }

      // ViewTime
      if (request.url.match('/viewTime?') && request.method === 'GET') {
        return this.http.get('../../../assets/data/time-tab/view-time-mock.json').pipe(
          map(viewTime => new HttpResponse({
            status: 200, body: new Blob([JSON.stringify(viewTime)], { type: 'application/json' })
          }))
        );
      }

      //SaveExpenseSplit
      if (request.url.match('/SaveExpenseSplit') && request.method === 'POST') {
        return this.http.get('../../../assets/data/expenses-tab/expense-split-mock.json').pipe(
          map(expenseSplit => new HttpResponse({
            status: 200,
            body: new Blob([JSON.stringify(expenseSplit)],
              { type: 'application/json' })
          }))
        );
      }

      //GetUserPreferences 
      if (request.url.match('/GetUserPreferences') && request.method === 'GET') {
        return this.http.get('../../../assets/data/user/get-user-preferences-mock.json').pipe(
          map(expenseSplit => new HttpResponse({
            status: 200,
            body: new Blob([JSON.stringify(expenseSplit)],
              { type: 'application/json' })
          }))
        );
      }
      //SaveUserPreferences 
      if (request.url.match('/SaveUserPreferences') && request.method === 'POST') {
        return this.http.get('../../../assets/data/user/save-user-preferences-mock.json').pipe(
          map(expenseSplit => new HttpResponse({
            status: 200,
            body: new Blob([JSON.stringify(expenseSplit)],
              { type: 'application/json' })
          }))
        );
      }


      // GetTimeReportHistory
      if (request.url.match(TimeRequestMock.getTimeReportHistory.url) &&
        (request.method === TimeRequestMock.getTimeReportHistory.requestType)) {
        return this.http.get(TimeRequestMock.getTimeReportHistory.urlJson).pipe(
          map(timeReportHistory => new HttpResponse({
            status: 200,
            body: new Blob([JSON.stringify(timeReportHistory)],
              { type: 'application/json' })
          }))
        );
      }

      // GetAmexExpenses
      /* if (request.url.match('/GetAmexExpenses?') && request.method === 'GET') {
        return this.http.get('../../../assets/data/expenses-tab/expense-amex-mock.json').pipe(
          map(amexExpenses => new HttpResponse({
            status: 200,
            body: new Blob([JSON.stringify(amexExpenses)],
              { type: 'application/json' })
          }))
        );
      } */

      // GetAmexExpenses
      if ((request.url.match(ExpenseRequestsMocks.expensesAmex.url)) &&
        (request.method === ExpenseRequestsMocks.expensesAmex.requestType) &&
        (request.body === ExpenseRequestsMocks.expensesAmex.requestBody)) {
        return this.http.get(ExpenseRequestsMocks.expensesAmex.urlJson).pipe(
          map(amexExpenses => new HttpResponse({
            status: 200,
            body: new Blob([JSON.stringify(amexExpenses)],
              { type: 'application/json' })
          }))
        );
      }

      // GetFrequentTrip
      if (request.url.match('/GetTripList?') && request.method === 'GET') {
        return this.http.get('../../../assets/data/expenses-tab/expense-trip-mock.json').pipe(
          map(trips => new HttpResponse({
            status: 200,
            body: new Blob([JSON.stringify(trips)],
              { type: 'application/json' })
          }))
        );
      }

      // UserMetadata
      if (request.url.match('/getUserMetadata') && request.method === 'GET') {
        return this.http.get('../../../assets/data/user/user-metadata.json').pipe(
          map(userMetadate => new HttpResponse({
            status: 200,
            body: new Blob([JSON.stringify(userMetadate)],
              { type: 'application/json' })
          }))
        );
      }

      // GetUserInpatriationInformation
      if (request.url.match('/GetUserInpatriationInformation') && request.method === 'GET') {
        return this.http.get('../../../assets/data/user/get-user-inpatriation-information-mock.json').pipe(
          map(userInpatrationInformation => new HttpResponse({ status: 200, body: new Blob([JSON.stringify(userInpatrationInformation)], { type: 'application/json' }) }))
        );
      }

      //GetPeoplePicker
      if (request.url.match('/GetPeoplePicker') && request.method === 'GET') {
        return this.http.get('../../../assets/data/user/get-peoplepicker-mock.json').pipe(
          map(userInpatrationInformation => new HttpResponse({ status: 200, body: new Blob([JSON.stringify(userInpatrationInformation)], { type: 'application/json' }) }))
        );
      }

      // IsPilotUser
      if (request.url.match('/IsPilotUser') && request.method === 'GET') {
        return this.http.get('../../../assets/data/user/ispilot-mock.json').pipe(
          map(userInpatrationInformation => new HttpResponse({ status: 200, body: new Blob([JSON.stringify(userInpatrationInformation)], { type: 'application/json' }) }))
        );
      }


      // ChargeCodes
      if (request.url.match('/GetProjects') && request.method === 'GET') {
        return this.http.get('../../../assets/data/chargecodes-mock.json').pipe(
          map(userMetadate => new HttpResponse({
            status: 200,
            body: new Blob([JSON.stringify(userMetadate)],
              { type: 'application/json' })
          }))
        );
      }

      // GetWorkSchedule
      if (request.url.match('/GetWorkSchedule') && request.method === 'GET') {
        return this.http.get('../../../assets/data/time-tab/get-workschedule-mock.json').pipe(
          map(getTime => new HttpResponse({ status: 200, body: new Blob([JSON.stringify(getTime)], { type: 'application/json' }) }))
        );
      }

      // LocationByDay
      if (request.url.match('/GetLocationByDay') && request.method === 'GET') {
        return this.http.get('../../../assets/data/locations/location-by-day-mockdata.json').pipe(
          map(userMetadate => new HttpResponse({
            status: 200,
            body: new Blob([JSON.stringify(userMetadate)],
              { type: 'application/json' })
          }))
        );
      }

      // MultipleLocations
      if (request.url.match('/GetMultipleLocations') && request.method === 'GET') {
        return this.http.get('../../../assets/data/locations/multiple-locations-mockdata.json').pipe(
          map(userMetadate => new HttpResponse({
            status: 200,
            body: new Blob([JSON.stringify(userMetadate)],
              { type: 'application/json' })
          }))
        );
      }

      // Country
      if (request.url.match('/GetCountries') && request.method === 'GET') {
        return this.http.get('../../../assets/data/locations/countries-mockdata.json').pipe(
          map(userMetadate => new HttpResponse({
            status: 200,
            body: new Blob([JSON.stringify(userMetadate)],
              { type: 'application/json' })
          }))
        );
      }

      // LocationOne
      if (request.url.match('/GetLocationOne') && request.method === 'GET') {
        return this.http.get('../../../assets/data/locations/locationsOne-ar-mockdata.json').pipe(
          map(userMetadate => new HttpResponse({
            status: 200,
            body: new Blob([JSON.stringify(userMetadate)],
              { type: 'application/json' })
          }))
        );
      }

      // LocationTwo
      if (request.url.match('/GetLocationTwo') && request.method === 'GET') {
        return this.http.get('../../../assets/data/locations/locationsTwo-ar-lp-mockdata.json').pipe(
          map(userMetadate => new HttpResponse({
            status: 200,
            body: new Blob([JSON.stringify(userMetadate)],
              { type: 'application/json' })
          }))
        );
      }

      // GetTime old
      if (request.url.match('/GetTime') && request.method === 'POST') {
        return this.http.get('../../../assets/data/time-tab/get-time-mock.json').pipe(
          map(getTime => new HttpResponse({ status: 200, body: new Blob([JSON.stringify(getTime)], { type: 'application/json' }) }))
        );
      }
      // GetTime new
      if (request.url.match(TimeRequestMock.getTimeSheet.url) &&
        (request.method === TimeRequestMock.getTimeSheet.requestType)) {
        return this.http.get(TimeRequestMock.getTimeSheet.urlJson).pipe(
          map(getTime => new HttpResponse({ status: 200, body: new Blob([JSON.stringify(getTime)], { type: 'application/json' }) }))
        );
      }

      //SaveTimeSheet
      if (request.url.match(TimeRequestMock.saveTimeSheet.url) &&
        (request.method === TimeRequestMock.saveTimeSheet.requestType)) {
        return this.http.get(TimeRequestMock.saveTimeSheet.urlJson).pipe(
          map(expenseSplit => new HttpResponse({
            status: 200,
            body: new Blob([JSON.stringify(expenseSplit)],
              { type: 'application/json' })
          }))
        );
      }
      //TimeReportStatus
      if (request.url.match(TimeRequestMock.getTimeReportStatus.url) &&
        (request.method === TimeRequestMock.getTimeReportStatus.requestType)) {
        return this.http.get(TimeRequestMock.getTimeReportStatus.urlJson).pipe(
          map(userMetadate => new HttpResponse({
            status: 200, body: new Blob([JSON.stringify(userMetadate)], { type: 'application/json' })
          }))
        );
      }

      //GetUserExpenseSettings
      if (request.url.match('/GetUserExpenseSettings') && request.method === 'POST') {
        return this.http.get('../../../assets/data/user/get-userexpense-settings-mock.json').pipe(
          map(userMetadate => new HttpResponse({
            status: 200,
            body: new Blob([JSON.stringify(userMetadate)],
              { type: 'application/json' })
          }))
        );
      }

      //GetIssuesWithAdjustmentsByEnterpriseId
      if ((request.url.match(ExpenseRequestsMocks.expensesGetIssues.url)) &&
        (request.method === ExpenseRequestsMocks.expensesGetIssues.requestType) &&
        (request.body === ExpenseRequestsMocks.expensesGetIssues.requestBody)) {
        return this.http.get(ExpenseRequestsMocks.expensesGetIssues.urlJson).pipe(
          map(userMetadate => new HttpResponse({
            status: 200,
            body: new Blob([JSON.stringify(userMetadate)],
              { type: 'application/json' })
          }))
        );
      }

      //GetUserActionMenu
      if (request.url.match('/GetUserActionMenu') && request.method === 'GET') {
        return this.http.get('../../../assets/data/user/get-useractionmenu-mock.json').pipe(
          map(userMetadate => new HttpResponse({
            status: 200,
            body: new Blob([JSON.stringify(userMetadate)],
              { type: 'application/json' })
          }))
        );
      }

      //GetPunchClockConfigAndEntries
      if (request.url.match('/GetPunchClockConfigAndEntries') && request.method === 'GET') {
        return this.http.get('../../../assets/data/user/get-punchclock.json').pipe(
          map(userMetadate => new HttpResponse({
            status: 200,
            body: new Blob([JSON.stringify(userMetadate)],
              { type: 'application/json' })
          }))
        );
      }

      //GetTimeEntryTemplate
      if (request.url.match('/GetTimeEntryTemplate') && request.method === 'GET') {
        return this.http.get('../../../assets/data/get-tet.json').pipe(
          map(userMetadate => new HttpResponse({
            status: 200,
            body: new Blob([JSON.stringify(userMetadate)],
              { type: 'application/json' })
          }))
        );
      }

       // GetAdjustments
      if (request.url.match(AdjustmentRequestMock.getAdjustments.url) &&
        (request.method === AdjustmentRequestMock.getAdjustments.requestType)) {

        return this.http.get(AdjustmentRequestMock.getAdjustments.urlJson).pipe(
          map(viewTime => new HttpResponse({
            status: 200, body: new Blob([JSON.stringify(viewTime)], { type: 'application/json' })
          }))
        );
      }

      //GetRequestedOvertimes
      if (request.url.match('GetRequestedOvertimes') && request.method === 'POST') {
        return this.http.get('../../../assets/data/request-overtime-search.json').pipe(
          map(requestOvertime => new HttpResponse({
            status: 200,
            body: new Blob([JSON.stringify(requestOvertime)],
              { type: 'application/json' })
          }))
        );
      }
      
      return next.handle(request);
    }))
      .pipe(materialize())
      .pipe(dematerialize());
  }
}

export let fakeBackendProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: FakeBackendInterceptor,
  multi: true
};
