<div class="input-group">
  <div *ngIf="prependText" 
  class="input-group-prepend"
  [attr.aria]="'Press Enter to enter amount'"
  >
    <span class="input-group-text">{{ prependText }}</span>
  </div>
  <input
    #textbox
    tabindex="0"
    [attr.id]="id"
    [attr.aria-label]="ariaLabel"
    [attr.aria-required]="required"
    class="form-control input"
    [ngClass]="{
      invalid: isValid == null? false : !isValid,
      isAdjustment : isAdjustment
    }"
    [style.text-align]="textAlignByPrecision"
    [disabled]="readOnly"
    value="{{ precision ? localValue : value }}"
    (focus)="deleteGroupSeparator($event)"
    (change)="deleteGroupSeparator($event)"
    (blur)="onBlur($event)"
    [myteValidateInput]="validationExpressions"
    (clickOutside)="hideOptions($event)"
    [attr.maxlength]="maxlength">
</div>
