import { PostPayAuditOutput } from "@shared/clients/expense-api-client";
import { MyAudit } from "@shared/models/audit/myte-audit";
import { MyteAuditOutput } from "@shared/models/audit/myte-audit-output";

export default class AuditServiceMapper {

    //#region Audit API Mappers
    public static mapMyteAuditOutput(myteAuditOutputs: PostPayAuditOutput[]): MyteAuditOutput {
        return new MyteAuditOutput(null, this.mapMyteAudit(myteAuditOutputs));
    }

    public static mapMyteAuditOutputforViewEdit(myteAuditOutputs: PostPayAuditOutput): MyteAuditOutput {
        let myAudits: PostPayAuditOutput[] = [];
        myAudits.push(myteAuditOutputs);
        return new MyteAuditOutput(null, this.mapMyteAudit(myAudits));
    }
    private static mapMyteAudit(myteAudit: PostPayAuditOutput[]): MyAudit[] {
        let myAudits: MyAudit[] = [];
        if (myteAudit && myteAudit.length > 0) {
            myteAudit.map(myteAuditItem => {
                myAudits.push(new MyAudit(
                    myteAuditItem.auditID,
                    myteAuditItem.vip,
                    myteAuditItem.status,
                    myteAuditItem.compliancePeriod,
                    myteAuditItem.trPeriod,
                    myteAuditItem.personnelNumber,
                    myteAuditItem.enterpriseId,
                    myteAuditItem.country,
                    myteAuditItem.companyCode,
                    myteAuditItem.source,
                    myteAuditItem.envelopeBarcode,
                    myteAuditItem.envelopeIcon,
                    myteAuditItem.box,
                    myteAuditItem.ees,
                    myteAuditItem.auditStatusColorCode,
                    myteAuditItem.auditCommunicationLogTrackingId
                ));
            });
        }
        return myAudits;
    }
}