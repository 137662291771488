import { Params } from '@angular/router';
import { MyteEvent } from '../myte-event';

export class ShowGlobalSpinnerEvent extends MyteEvent<boolean> {
    public static eventId = 'showGlobalSpinner';
    constructor() { super(ShowGlobalSpinnerEvent.eventId); }
}

export class DisableLocationSaveButtonEvent extends MyteEvent<boolean> {
    public static eventId = 'disableLocationSaveButton';
    constructor() { super(DisableLocationSaveButtonEvent.eventId); }
}

export class ShowSpinnerForErrorHandler extends MyteEvent<boolean>{
    public static eventId = "ShowSpinner";
    constructor() { super(ShowSpinnerForErrorHandler.eventId) }
}

export class CatchQuestionaireErrorEvent extends MyteEvent<boolean> {
    public static eventId = 'catchQuestionaireErrorEvent';
    constructor() { super(CatchQuestionaireErrorEvent.eventId); }
}

export class SubmitInQuestionaireErrorEvent extends MyteEvent<boolean> {
    public static eventId = 'submitInQuestionaireErrorEvent';
    constructor() { super(SubmitInQuestionaireErrorEvent.eventId); }
}

export class GiftComponentChangedEvent extends MyteEvent<void> {
    public static eventId = "giftcomponenteventchanges";
    constructor() { super(GiftComponentChangedEvent.eventId); }
} 

export class AddRelatedChargeCodeEvent extends MyteEvent<Params> {
    public static eventId = 'addRelatedChargeCodeEvent';
    constructor() { super(AddRelatedChargeCodeEvent.eventId); }
}

export class ShowGlobalSpinnerAutoSaveEvent extends MyteEvent<boolean> {
    public static eventId = 'ShowGlobalSpinnerAutoSaveEvent';
    constructor() { super(ShowGlobalSpinnerAutoSaveEvent.eventId); }
}

export class ESTravelPunchClockEvent extends MyteEvent<boolean> {
    public static eventId = 'ESTravelPunchClockEvent';
    constructor() { super(ESTravelPunchClockEvent.eventId); }
}
