import { ExpenseViewFieldChargeCodeOption } from './expense-view-field-charge-code-option';
import { ExpenseViewField } from '../expense/field/expense-view-field';

export class ExpenseViewChargeCodeField extends ExpenseViewField {
  public chargeCodeOptions?: ExpenseViewFieldChargeCodeOption[] = [];

  constructor(expenseViewField: ExpenseViewField, chargeCodeOptions?: ExpenseViewFieldChargeCodeOption[]) {
    super(expenseViewField.key, expenseViewField.value, expenseViewField.label, expenseViewField.tooltip, expenseViewField.required, expenseViewField.readOnly,
      expenseViewField.hasAdjustment, expenseViewField.PreviousPPAValue, expenseViewField.uiType, expenseViewField.dataType, expenseViewField.size,
      expenseViewField.order, expenseViewField.options, expenseViewField.validations, expenseViewField.styles, expenseViewField.elementType, 
      expenseViewField.category, expenseViewField.row, expenseViewField.visible);
    this.chargeCodeOptions = chargeCodeOptions;
  }

}
