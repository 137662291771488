import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {
  public transform(value, keys: string, term: string) {
    value["itemscount"] = (value || []).length;
    if (!term) return value;
    //To update the '.' character so that it is interpreted correctly in the regular expression
    term = term.replace(/\./g,"\\.");
    let returnValue = (value || []).filter(item => keys.split(',')
    .some(key => item.hasOwnProperty(key) && new RegExp(term, 'gi').test(item[key])));
    value["itemscount"] = returnValue.length;
    return returnValue;
  }
}