import { Injectable } from "@angular/core";
import { ExpenseApiClient } from "@shared/clients/expense-api-client";
import { LogService } from "@shared/services/log/log.service";
import { Observable, catchError, of } from "rxjs";


@Injectable({
  providedIn: "root",
})
export class EscalateExpenseService {
  constructor(
    private api: ExpenseApiClient,
    private logService: LogService,
  ) {}

  public updateSelfCertification(
    expenseId: string | undefined,
    enterpriseId: string | undefined,
    periodEnd: Date | undefined,
    loginEnterpriseId: string | undefined,
    viewMode: string | undefined
  ): Observable<any> {
    return this.api
      .updateSelfCertification(
        expenseId,
        enterpriseId,
        periodEnd,
        loginEnterpriseId,
        viewMode
      )
      .pipe(
        catchError((err) => {
          this.logService.logError(err, true);
          return of(null);
        })
      );
  }

  public checkExpenseHasOpenIssues(
    expenseId: string | undefined,
    periodEnd: Date | undefined,
    enterpriseId: string | undefined,
    loginEnterpriseId: string | undefined,
    viewMode: string | undefined
  ): Observable<any> {
    return this.api
      .checkExpenseHasOpenIssues(
        expenseId,
        periodEnd,
        enterpriseId,
        loginEnterpriseId,
        viewMode
      )
      .pipe(
        catchError((err) => {
          this.logService.logError(err, true);
          return of(null);
        })
      );
  }

  public updateEscalated(
    expenseId: string | undefined,
    postPayAuditItemId: number | undefined,
    periodEnd: Date | undefined,
    enterpriseId: string | undefined,
    loginEnterpriseId: string | undefined,
    viewMode: string | undefined
  ): Observable<any> {
    return this.api
      .updateEscalated(
        expenseId,
        postPayAuditItemId,
        periodEnd,
        enterpriseId,
        loginEnterpriseId,
        viewMode
      )
      .pipe(
        catchError((err) => {
          this.logService.logError(err, true);
          return of(null);
        })
      );
  }
}