import { ShellModel } from '../../../app-shell/data-store';
import { TimeReportLogAction } from './time-report-log-action';

export class TimeReportLog extends ShellModel {
    public timeReportId: number;
    public timeReportLogId: number;
    public submitterEID: string;
    public submitDate: Date;
    public status: string;
    public comments: string;
    public actions: Array<TimeReportLogAction>;

    constructor(timeReportId?: number, timeReportLogId?: number, submitterEID?: string, submitDate?: Date, status?: string, 
        comments?: string, actions?: Array<TimeReportLogAction>) {
        super();

        this.timeReportId = timeReportId;
        this.timeReportLogId = timeReportLogId;
        this.submitterEID = submitterEID;
        this.submitDate = submitDate;
        this.status = status;
        this.comments = comments;
        this.actions = actions ?? new Array(5).fill(new TimeReportLogAction());
    }
}