enum ApprovalStatus {
    Complete = 'Complete',
    ReceiptRequired = 'ReceiptRequired',
    Escalated = 'Escalated',
    Incomplete = 'Incomplete',
    AuditClosed = 'AuditClosed',
    PendingProjectApproval = 'PendingProjectApproval',
    PendingPayablesApproval = 'PendingPayablesApproval',
    RejectedByAuditor = 'RejectedByAuditor',
    PendingAuditorApproval  = 'PendingAuditorApproval',
    RejectedByProject = 'RejectedByProject',
    RejectedByPayables = 'RejectedByPayables',
    Approved = 'Approved',
    PendingReview = 'PendingReview'
}
export default ApprovalStatus;
