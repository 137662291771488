import { Component } from '@angular/core';
import { ILoadingOverlayAngularComp } from 'ag-grid-angular';
import { ILoadingOverlayParams } from 'ag-grid-community';

@Component({
  selector: 'myte-expense-grid-overlay-loading',
  templateUrl: './expense-grid-overlay-loading.component.html',
  styleUrls: ['./expense-grid-overlay-loading.component.sass']
})
export class ExpenseGridOverlayLoadingComponent implements ILoadingOverlayAngularComp {
  public params: ILoadingOverlayParams & { loadingMessage: string };
  public loadingMessage: string;

  public agInit(params: ILoadingOverlayParams & { loadingMessage: string }): void {
    this.params = params;
    this.loadingMessage = params.loadingMessage ?? 'Loading your Expenses';
  }
}
