import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend, HttpHeaders } from '@angular/common/http';
import { take, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import jsonFile from '../../../../package.json';

@Injectable({
    providedIn: 'root'
})

export class AppConfigService {
    private appConfig: any;
    private static readonly appConfigPath = environment.name === 'Mock' ? 'assets/data/app-config/appconfig.mock.json' : 'assets/data/app-config/appconfig.json';
    // Key to search the config into the sessionStorage
    private static readonly keyConfig = 'myteConfig';
    // Actual version, from package.json
    private static readonly versionConfig = jsonFile.version;

    constructor(private httpClient: HttpClient, private handler: HttpBackend) { }

    public get getAppConfig(): any { return this.appConfig; }

    loadAppConfig() {
        return new Promise((resolve, reject) => {
            const configParsed = JSON.parse(sessionStorage.getItem(AppConfigService.keyConfig));
            if (configParsed) {
                this.appConfig = configParsed;
                resolve(true);
                return;
            }
            this.httpClient = new HttpClient(this.handler);
            let versionConfig = AppConfigService.appConfigPath +`?v=${AppConfigService.versionConfig}`;
            this.httpClient
                .get(versionConfig)
                .pipe(take(1), catchError(err => throwError(() => new Error(err || 'Server error'))))
                .subscribe((configResponse: any) => {
                    sessionStorage.setItem(AppConfigService.keyConfig, JSON.stringify(configResponse));
                    this.appConfig = configResponse;
                    resolve(true);
                });
        });
    }
}