export class MyAudit {
  public remove: boolean;
  public auditID: string;
  public vip: string;
  public status: string;
  public compliancePeriod: Date;
  public trPeriod: Date;
  public personnelNumber: string;
  public enterpriseId: string;
  public country: string;
  public companyCode: string;
  public source: string;
  public envelopeBarcode: string;
  public envelopeIcon: string;
  public box: string;
  public EES: string;
  public auditStatusColorCode: string;
  public auditCommunicationLogTrackingId: string;

  constructor(
    auditID?: string,
    vip?: string,
    status?: string,
    compliancePeriod?: Date,
    trPeriod?: Date,
    personnelNumber?: string,
    enterpriseId?: string,
    country?: string,
    companyCode?: string,
    source?: string,
    envelopeBarcode?: string,
    envelopeIcon?: string,
    box?: string,
    EES?: string,
    auditStatusColorCode?: string,
    auditCommunicationLogTrackingId?: string
  ) {
    this.auditID = auditID;
    this.vip = vip;
    this.status = status;
    this.compliancePeriod = compliancePeriod;
    this.trPeriod = trPeriod;
    this.personnelNumber = personnelNumber;
    this.enterpriseId = enterpriseId;
    this.country = country;
    this.companyCode = companyCode;
    this.source = source;
    this.envelopeBarcode = envelopeBarcode;
    this.envelopeIcon = envelopeIcon;
    this.box = box;
    this.EES = EES;
    this.auditStatusColorCode = auditStatusColorCode;
    this.auditCommunicationLogTrackingId = auditCommunicationLogTrackingId;
  }
}