export class LocationTemplate {
    public country: LocationTemplateInformation;
    public locationOne: LocationTemplateInformation;
    public locationTwo: LocationTemplateInformation;
    public locationReason: LocationReasonTemplateInformation;
    public locationType: LocationTypeTemplateInformation;
    public locationsDayOfWeek: boolean[] = Object.seal([false, false, false, false, false, false, false]);

    public toString(): string {
        let s: string = '';
        if (this.country) s = `${this.country.locationName}`;
        if (this.locationOne) s = `${s} - ${this.locationOne.locationName}`;
        if (this.locationTwo) s = `${s} - ${this.locationTwo.locationName}`;
        if (this.locationReason) s = `${s} - ${this.locationReason.reasonName}`;
        if (this.locationType) s = `${s} - ${this.locationType.typeName}`;
        return s;
    }
}

export interface LocationTemplateInformation {
    locationKey: string;
    locationName: string;
}

export interface LocationReasonTemplateInformation {
    reasonKey: string;
    reasonName: string;
}

export interface LocationTypeTemplateInformation {
    typeKey: string;
    typeName: string;
}