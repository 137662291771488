import { ReceiptRequirementTypes } from "@shared/models/expense/receipt/expense-receipt-requirement"

export const icons = {
    'Travel-Taxi': 'local_taxi',

    'CarHire/Rental': 'directions_car',

    'Accommodation-Hotel': 'business',

    'MealsandEntertainment': 'local_dining',

    'KM/MilesAllowance': 'local_gas_station',

    'Travel-Public,Limo,&Other': 'directions_bus',

    'OtherExpense': 'attach_money',

    'Travel-Air': 'flight_takeoff',

    'Travel-Rail': 'directions_railway',

    'Compensatory Memo': 'attach_money',

    'Other Allowances': 'attach_money',

    'Telecom/Internet': 'phone',

    'PerDiem-Local': 'business',

    'PerDiem-International': 'public',

    'Accommodation-Apartment': 'hotel',

    'Travel - Taxi - Local - Multiple Entry Template': 'local_taxi',

    'CarExpense(Parking,Toll,Fuel)': 'local_parking',

    'StartupAllowance': 'next_week',

    'default': 'attach_money'
}

export const iconsReceipt = {
    [ReceiptRequirementTypes.None]: 'camera',
    [ReceiptRequirementTypes.Neither]: 'camera',
    [ReceiptRequirementTypes.Electronic]: 'camera-required',
    [ReceiptRequirementTypes.Physical]: 'camera',
    [ReceiptRequirementTypes.Both]: 'camera-required',
    [ReceiptRequirementTypes.Either]: 'camera-required'
};