import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfigService } from '@authServices/app-config.service';
import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})


export class SessionManagementService {

  private timer: any;
  private idleTime: number = 0;
  public  warningPopUpEvent = new Subject<boolean>();
  private MAX_IDLE_TIME: number
  private WARNING_IDLE_TIME: number
  public showTimerInConsole: boolean = false;
  private lastActivityTime: number;
  
  constructor( private router: Router, private appConfigService: AppConfigService) {

    this.MAX_IDLE_TIME = this.appConfigService.getAppConfig.AdditionalConfig.maxIdleTime ?? 60000*15;
    this.WARNING_IDLE_TIME =  this.appConfigService.getAppConfig.AdditionalConfig.warningIdleTime ?? 60000*10;

   }
 
  public initLogOutTimer():void {

    let currentTime = Date.now();
    this.lastActivityTime = currentTime;
    
    this.timer = setInterval(()=>{
        let currentTime = Date.now();
        this.idleTime = Math.floor((currentTime-this.lastActivityTime)/1000)*1000;        
        this.checkIdleTime();
        if(this.showTimerInConsole) {console.log(this.idleTime)}
    },1000)
    
  }

  private checkIdleTime():void {

    if ( this.idleTime == this.WARNING_IDLE_TIME ){
      this.warningPopUpEvent.next(true);
    }
    if ( this.idleTime > this.MAX_IDLE_TIME){
      clearInterval(this.timer);
      this.warningPopUpEvent.next(false);
      this.router.navigateByUrl('logout');
    }
    
  }
  
  public resetLogOutTimer():void {

    clearInterval(this.timer);  
    this.initLogOutTimer(); 

  }

  public timeLeft():string {

    return this.convertToMinutesAndSeconds((this.MAX_IDLE_TIME - this.idleTime))
    
  }

  private convertToMinutesAndSeconds(miliseconds: number): string {
   
    var minutes = Math.floor(miliseconds / 60000).toString().padStart(2,'0');
    var seconds = ((miliseconds % 60000) / 1000).toFixed(0).toString().padStart(2,'0');

    return `${minutes}:${seconds}`
    
  }
}
