import { Component } from '@angular/core';
import { TimeEntryTemplateTask } from '@shared/models/time-sheet/time-entry/time-entry-template';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'myte-time-entry-template-popup-grid-cell-render',
  templateUrl: './time-entry-template-popup-grid-cell-render.component.html',
  styleUrls: ['./time-entry-template-popup-grid-cell-render.component.sass']
})
export class TimeEntryTemplatePopupGridCellRender implements ICellRendererAngularComp {
  public value: any;
  public hasError: boolean;
  public tooltip: string;
  public params: ICellRendererParams<TimeEntryTemplateTask, number> & { ariaLabel: string, id: string, cellId: string, isEditable: boolean };
  public dataType: string;
  public dayDescription: string;

  constructor() { }

  public agInit(params: ICellRendererParams<TimeEntryTemplateTask, number> & { ariaLabel: string, id: string, cellId: string, isEditable: boolean }): void {
    this.params = params;
    this.value = params.valueFormatted;
    this.hasError = params.data.assignment.hasError;
    this.tooltip = params.data.assignment.tooltip;
    //FocusService.redirectFocus(params?.eGridCell, params?.id);
  }

  public enableEdit(): void {
    this.params.api.startEditingCell({
      rowIndex: this.params.rowIndex,
      colKey: this.params.column.getId()
    });
  }

  public refresh(params: ICellRendererParams<TimeEntryTemplateTask, number>): boolean {
    return false;
  }
}