import { Injectable } from '@angular/core';
import TimeSheet from '@sharedModels/time-sheet/time-sheet';
import { map, catchError, tap, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import {
    TimeApiClient,
    TimeSheetOutput,
    WorkScheduleOutput,
    PunchClockConfigAndEntriesOutput,
    ShiftScheduleOutput,
    ShiftScheduleInput,
    SaveWorkScheduleOutput,
	PunchClockEntryLog,
    PunchClockEntryLogOutput,
    PunchClockEntryLogInput, 
    AllowanceRequestEntryOutput,
    AllowanceRequestOutput,
    AllowanceRequestApproverOutput,
    TimeCategoryRequestOutput,
    TimeCategoryRequestEntry,
    TimeCategoryRequestApproverOutput
} from '@sharedClients/time-api-client';
import { UserService } from '@sharedServices/user/user.service';
import { LogService } from '@sharedServices/log/log.service';
import TimeServiceMapper from './time.service.mapper';
import { WorkScheduleGroup } from '@sharedModels/time-sheet/work-schedule/time-sheet-work-schedule';
import { PunchClock } from '@sharedModels/time-sheet/punch-clock';
import { GlobalCacheService } from '@sharedServices/cache/global-cache.service';
import { ShiftSchedule } from '@sharedModels/time-sheet/shift-schedule/shift-schedule';
import { MyteBaseService } from '@sharedServices/myte-base.service';
import { GlobalEventsService } from '@sharedServices/events/global-events.service';
import { SubmissionDeadlineDto, TimeReportSubmitMode, ValidationErrorOutPut } from '@sharedClients/timereport-api-client';
import { ActivatedRoute } from '@angular/router';
import { SubordinatesService } from '@sharedServices/subordinates/subordinates.service';
import { PunchClockPopupSave_SuccessfullySavedMessage, WorkSchedulePopupSave_SuccessfullySavedMessage, RestHoursPunchClockPopupSave_SuccessfullySavedMessage } from '@shared/myte-static-message';
import { TimeReportUtils } from '@sharedUtils/timeReport.utils';
import moment from 'moment';
import { SubordinatesMode } from '@shared/models/subordinates/subordinate-mode';
import { OvertimeRequest } from '@shared/models/time-sheet/overtime-request/overtime-request';
import { OvertimeRequestEntry } from '@shared/models/time-sheet/overtime-request/overtime-request-entry';
import { TimeCategoryLogErrorMsg } from '@shared/constant';
import { TimeCategoryRequestValidationOutput } from '@shared/models/time-sheet/time-category-request/time-category-request-validation';

@Injectable()
export class TimeSheetService extends MyteBaseService {
    constructor(private api: TimeApiClient,
        logService: LogService,
        userService: UserService,
        eventService: GlobalEventsService,
        private cacheService: GlobalCacheService,
        public route: ActivatedRoute) {
        super(userService, cacheService, eventService, logService);
    }

    public getTimeSheet(periodEnd: Date, isSubmit: boolean = false, submissionMode: TimeReportSubmitMode = 0): Observable<TimeSheet> {                      
        const currentMode = this.globalCacheService.getSubordinateMode();
        let userEid = currentMode=='ReviewEmail' ? this.globalCacheService.getRevieweeEid() : this.userEid;
        let timeSheetRequest = this.api
            .getTimeSheet(userEid, periodEnd, submissionMode, isSubmit, this.supervisorEid,  currentMode=='ReviewEmail'? currentMode : this.viewMode)
            .pipe(map((res: TimeSheetOutput) => TimeServiceMapper.mapTimesheet(res, this.userEid, this.route?.snapshot?.queryParams?.hasRestHours)))
            .pipe(tap(res => {
                let periodEnd = this.globalCacheService.getPeriodEnd();
                this.globalCacheService.handleResetTimeReport(periodEnd);
                let timeReport = TimeReportUtils.createTimeReport(periodEnd, res.timeReportStatus, this.viewMode);
                this.globalCacheService.handleTimeReport(periodEnd, of(timeReport));
                let TETAutomaticMessageInSessionStorage = sessionStorage.getItem('TETAutomaticMessage');
                
                let isDelegateSupervisorOrApprover: boolean = currentMode == SubordinatesMode.Approver || currentMode == SubordinatesMode.Supervisor || currentMode == SubordinatesMode.Delegate;
                let isNewOrDraft = res.timeReportStatus == "Draft" || res.timeReportStatus == "New";
                if (!isDelegateSupervisorOrApprover && isNewOrDraft) {
                    this.globalCacheService.getTimeReportSettings(periodEnd, null).subscribe(setting => {
                        if(setting?.toastWanrningDisplay){
                            this.showDeadLineMessage(setting?.submissionDeadline, periodEnd);
                        }
                    });
                }
                if (res.timeEntryTemplateAutomaticMessage != '' && TETAutomaticMessageInSessionStorage === null) {
                    this.logService.logInfo(res.timeEntryTemplateAutomaticMessage, true)
                    sessionStorage.setItem('TETAutomaticMessage', res.timeEntryTemplateAutomaticMessage);
                }
            }))
            .pipe(catchError(() => {
                this.globalCacheService.clearTimeSheetFromBuffer(periodEnd);
                this.logService.logError(
                    `Unable to get your Time Sheet of period ${periodEnd.toLocaleDateString()}.
                    If the problem persists please escalate the issue at myTimeandExpenses Help.`,
                    true,'');

                return of(null);
                })
            )

        return this.globalCacheService.handleTimeSheet(periodEnd, timeSheetRequest);
    }

    public getTimeSheetStore(periodEnd: Date, isSubmit: boolean = false, submissionMode: TimeReportSubmitMode = 0): Observable<TimeSheet> {                      
        const currentMode = this.globalCacheService.getSubordinateMode();
        let userEid = currentMode=='ReviewEmail' ? this.globalCacheService.getRevieweeEid() : this.userEid;
        let timeSheetRequest = this.api
            .getTimeSheet(userEid, periodEnd, submissionMode, isSubmit, this.supervisorEid,  currentMode=='ReviewEmail'? currentMode : this.viewMode)
            .pipe(map((res: TimeSheetOutput) => TimeServiceMapper.mapTimesheet(res, this.userEid, this.route?.snapshot?.queryParams?.hasRestHours)))
            .pipe(tap(res => {
                let TETAutomaticMessageInSessionStorage = sessionStorage.getItem('TETAutomaticMessage');
                
                let isDelegateSupervisorOrApprover: boolean = currentMode == SubordinatesMode.Approver || currentMode == SubordinatesMode.Supervisor || currentMode == SubordinatesMode.Delegate;
                let isNewOrDraft = res.timeReportStatus == "Draft" || res.timeReportStatus == "New";
                if (!isDelegateSupervisorOrApprover && isNewOrDraft) {
                    this.globalCacheService.getTimeReportSettings(periodEnd, null).subscribe(setting => {
                        if(setting?.toastWanrningDisplay){
                            this.showDeadLineMessage(setting?.submissionDeadline, periodEnd);
                        }
                    });
                }
                if (res.timeEntryTemplateAutomaticMessage != '' && TETAutomaticMessageInSessionStorage === null) {
                    this.logService.logInfo(res.timeEntryTemplateAutomaticMessage, true)
                    sessionStorage.setItem('TETAutomaticMessage', res.timeEntryTemplateAutomaticMessage);
                }
            }))
            .pipe(catchError(() => {
                this.globalCacheService.clearTimeSheetFromBuffer(periodEnd);
                this.logService.logError(
                    `Unable to get your Time Sheet of period ${periodEnd.toLocaleDateString()}.
                    If the problem persists please escalate the issue at myTimeandExpenses Help.`,
                    true);

                return of(null);
                })
            )

        return this.globalCacheService.handleTimeSheet(periodEnd, timeSheetRequest);
    }

    public showDeadLineMessage(submissionDeadLine: SubmissionDeadlineDto, periodEnd: Date){                                                
        let submissionDeadLineMessageInSessionStorage = sessionStorage.getItem(periodEnd.toLocaleDateString());   
        if (submissionDeadLine == null) return;
        let deadLineDayNbr = submissionDeadLine.deadlineDay;
        let deadLineTimeNbr = submissionDeadLine.deadlineTime/100;
        let submissionDeadLineDate = this.getCalculatedDateTimeFromSapDay(periodEnd, (deadLineDayNbr <= 0 ? deadLineDayNbr : -deadLineDayNbr));
        if(deadLineTimeNbr > 0)
            submissionDeadLineDate = moment(submissionDeadLineDate.toDate().setHours(deadLineTimeNbr));
        if (periodEnd && submissionDeadLineMessageInSessionStorage === null) {
            sessionStorage.setItem(periodEnd.toLocaleDateString(), "true");
            if (submissionDeadLineDate >= moment()) {
                this.logService.logInfo(`Your next submission due date: ${submissionDeadLineDate.format('DD MMMM')}`, true)
            } else {
                this.logService.logWarningYellow(`Your submission is past the due date: ${submissionDeadLineDate.format('DD MMMM')}`, true);
            }
        }
    }

    private getCalculatedDateTimeFromSapDay(dateTime: Date, sapDay: number): moment.Moment {
        let calculatedDateTime = moment(dateTime);
        let numberOfDaysUsed = this.isWeekend(calculatedDateTime) ? 1 : 0;

        if (sapDay <= 0) {
            while (numberOfDaysUsed > sapDay) {
                calculatedDateTime = calculatedDateTime.subtract(1, 'days');
                if (!this.isWeekend(calculatedDateTime))
                    numberOfDaysUsed--;
            }
            calculatedDateTime = this.checkForWeekend(calculatedDateTime);
        }
        else {
            while (sapDay != 0) {
                calculatedDateTime = calculatedDateTime.add(1, 'days');
                if (!this.isWeekend(calculatedDateTime))
                    sapDay--;
            }
        }

        return calculatedDateTime;
    }

    private checkForWeekend(convertDate: moment.Moment): moment.Moment {
        if (convertDate.day() == 0)
            convertDate = convertDate.subtract(2, 'days');
        else if (convertDate.day() == 6)
            convertDate = convertDate.subtract(1, 'days');
        return convertDate;
    }

    private isWeekend(dateTime: moment.Moment): boolean {
        let calculatedDateTime = moment(dateTime);
        return calculatedDateTime.day() == 6 || calculatedDateTime.day() == 0;
    }


    public clearSoftWarningErrorsCacheTemp(periodEnd: Date): Observable<void> {
        return this.api.clearSoftWarningErrorsCacheTemp(this.userEid, periodEnd);
    }

    public getTimeSheetForSubmission(periodEnd: Date, submissionMode: TimeReportSubmitMode, validationErrorOutPut: ValidationErrorOutPut[]): Observable<TimeSheet> {
        return this.api.getTimeSheet(this.userEid, periodEnd, submissionMode, true, this.supervisorEid, this.viewMode)
            .pipe(map((res: TimeSheetOutput) => TimeServiceMapper.mapTimesheet(res, this.userEid, null, validationErrorOutPut)))
            .pipe(catchError(() => {
                this.logService.logError(
                    `Unable to get your Time Sheet of period ${periodEnd.toLocaleDateString()}.
                    If the problem persists please escalate the issue at myTimeandExpenses Help.`,
                    true);
                return of(null);
            }));
    }

    public getShiftSchedules(periodEnd: Date): Observable<ShiftSchedule> {
        let shiftSchedule = this.api
            .getShiftSchedule(this.userEid, periodEnd, this.supervisorEid, this.viewMode)
            .pipe(map((res: ShiftScheduleOutput) => TimeServiceMapper.mapShiftSchedules(res)))
            .pipe(catchError(() => {
                this.logService.logError(
                    `Unable to get your shift schedules. If the problem persists please escalate the issue at myTimeandExpenses Help.`,
                    true);
                return of(null);
            }));
        return shiftSchedule;
    }

    public getShiftSchedulesCustomDay(periodEnd: Date, day: Date): Observable<ShiftSchedule> {
        let shiftSchedule = this.api
            .getShiftScheduleCustomDay(this.userEid, periodEnd, day, this.supervisorEid, this.viewMode)
            .pipe(map((res: ShiftScheduleOutput) => TimeServiceMapper.mapShiftSchedulesCustomDay(res)))
            .pipe(catchError(() => {
                this.logService.logError(
                    `Unable to get your shift schedules. If the problem persists please escalate the issue at myTimeandExpenses Help.`,
                    true);
                return of(null);
            }));
        return shiftSchedule;
    }

    public getWorkSchedules(periodEnd: Date): Observable<WorkScheduleGroup[]> {
        return this.api
            .getWorkSchedules(this.userEid, periodEnd, this.supervisorEid, this.viewMode)
            .pipe(map((res: WorkScheduleOutput) => TimeServiceMapper.mapWorkSchedules(res)))
            .pipe(catchError(() => {
                this.logService.logError(
                    `Unable to get your work schedules. If the problem persists please escalate the issue at myTimeandExpenses Help.`,
                    true);
                return of(null);
            }));
    }

    public getPunchClock(periodEnd: Date, isRestHours:boolean = false): Observable<PunchClock> {
        return this.api
            .getPunchClockConfigAndEntries(this.userEid, periodEnd, this.supervisorEid, this.viewMode, isRestHours)
            .pipe(map((res: PunchClockConfigAndEntriesOutput) => TimeServiceMapper.mapPuchClock(res)))
            .pipe(catchError(() => {
                this.logService.logError(
                    `Unable to get your Working Hours. If the problem persists please escalate the issue at myTimeandExpenses Help.`,
                    true
                );
                return of(null);
            }));
    }

    public savePunchClock(punchClockInput: PunchClock, periodEnd: Date, isRestHours:boolean): Observable<boolean> {
        switch (punchClockInput.configuration.countryCd) {
            case "IE":
                if (!punchClockInput.validateIncompleteEntries()) {
                    this.logService.logError(`Please complete both the Work Start Time and Work End Time dropdown fields for the day.`, true);
                    return of(false);
                }
                if (!punchClockInput.validateBreakDuration()) {
                    this.logService.logError(`Please review and update the Work Start/End Time or Break Duration to ensure the break hours less than working hours`, true);
                    return of(false);
                }
                break;
            default:
                break;
        }
        return this.api
            .savePunchClockEntries(this.supervisorEid, this.viewMode,
                TimeServiceMapper.mapPunchClockInput(punchClockInput, periodEnd, this.userEid, isRestHours))
            .pipe(tap(() => {  
                let hasRestHours:boolean = false;                                              
                this.globalCacheService.clearTimeSheetFromBuffer(periodEnd);    
                this.globalCacheService.getTimeReportSettings(periodEnd, this.globalCacheService.getUserCountry()).subscribe(
                    x => hasRestHours = x?.hasRestHours && isRestHours
                );              
                this.logService.showSuccessWithMsgInfo(hasRestHours ? RestHoursPunchClockPopupSave_SuccessfullySavedMessage: PunchClockPopupSave_SuccessfullySavedMessage);
            }))
            .pipe(catchError(() => {
                let hasRestHours:boolean = false;                
                this.globalCacheService.getTimeReportSettings(periodEnd, this.globalCacheService.getUserCountry()).subscribe(
                    x => hasRestHours = x?.hasRestHours && isRestHours
                );              
                let punchClockType: string = hasRestHours ? "Rest Hours" : "Working Hours";
                this.logService.logError(`Unable to save your ${punchClockType} of period ${periodEnd.toLocaleDateString()}.
            If the problem persists please escalate the issue at myTimeandExpenses Help. `, true);
                return of(null);
            }));
    }

    public getPunchClockEntryLogs(): Observable<PunchClockEntryLog[]> {
        return this.api.getPunchClockEntryLogs(this.userEid, this.globalCacheService.getPeriodEnd(), this.supervisorEid, this.viewMode);
    }

    public savePunchClockEntryLog(log: PunchClockEntryLogInput): Observable<PunchClockEntryLogOutput> {
        return this.api.addPunchClockEntryLog(this.globalCacheService.getPeriodEnd(), this.supervisorEid, this.viewMode, log);
    }

    public addDateToPunchClockEntryLog(WorkStartDttm: string,BreakStartDttm: string,BreakEndDttm: string,WorkEndDttm: string): PunchClockEntryLogInput {
        let newlog=new PunchClockEntryLogInput();
        newlog.enterpriseId = this.userEid;
        newlog.workStartDttm=WorkStartDttm;
        newlog.breakStartDttm=BreakStartDttm;
        newlog.breakEndDttm=BreakEndDttm;
        newlog.workEndDttm=WorkEndDttm;
        return newlog;
    }

    public saveTimeSheet(ts: TimeSheet, periodEnd: Date, toastMessage: any = null): Observable<TimeSheet> {
        let assignedLocation = ts.assignedLocation;
        let companyCode = ts.companyHistoryList;
         return this.api
            .saveTimeSheet(this.supervisorEid, this.viewMode,
                TimeServiceMapper.mapTimeSheetToDto(ts, periodEnd, this.userEid))
            .pipe(map((res: TimeSheetOutput) =>
                TimeServiceMapper.mapTimesheet(res,
                    this.userEid,
                    false)
            ))
            .pipe(map((newTs: TimeSheet) => TimeServiceMapper.remapAssignments(newTs, ts.assignments)))
            .pipe(tap(timeSheet => {
                timeSheet.assignedLocation = assignedLocation;
                timeSheet.companyHistoryList = companyCode;
                this.globalCacheService.setTimeSheet(of(timeSheet), periodEnd);
                this.globalCacheService.clearTimeReportSummary(periodEnd, this.userEid);
                this.globalCacheService.resetAnnualChargeabilitySummary();
                if (this.globalCacheService.adjustmentStorage) this.globalCacheService.adjustmentStorage.refresh = true;
                if(toastMessage)
                    this.logService.showSuccessWithMsgInfo(toastMessage);
            }))
            .pipe(catchError(() => {
                this.globalCacheService.clearTimeSheetFromBuffer(periodEnd);
                this.logService.logError(
                    `Unable to save your Time Sheet of period ${periodEnd.toLocaleDateString()}.
            If the problem persists please escalate the issue at myTimeandExpenses Help.`,
                    true);
                ts.timeEntry.tasks = ts.timeEntry.tasks.filter(x => !x.assignment.isEmpty);
                return of(ts);
            }));
    }

    public saveWorkSchedule(wsg: WorkScheduleGroup, periodEnd: Date): Observable<SaveWorkScheduleOutput> {
        return this.api
            .saveWorkSchedule(
                this.userEid,
                periodEnd,
                this.supervisorEid, this.viewMode,
                TimeServiceMapper.mapWorkScheduleToDto(wsg)
            )
            .pipe(tap(() => {
                this.globalCacheService.clearTimeSheetFromBuffer(periodEnd);
                this.logService.showSuccessWithMsgInfo(WorkSchedulePopupSave_SuccessfullySavedMessage);
            }))
            .pipe(
                catchError(err => {
                    this.logService.logError(
                        `Unable to save your work schedule. If the problem persists please escalate the issue at myTimeandExpenses Help.`,
                        true
                    );
                    return of(null);
                })
            );
    }

    public saveWorkScheduleStore(wsg: WorkScheduleGroup, periodEnd: Date): Observable<SaveWorkScheduleOutput> {
        return this.api.saveWorkSchedule(this.userEid, periodEnd, this.supervisorEid, this.viewMode, TimeServiceMapper.mapWorkScheduleToDto(wsg))
            .pipe(tap(() => {
                this.logService.showSuccessWithMsgInfo(WorkSchedulePopupSave_SuccessfullySavedMessage);
            }))
            .pipe(catchError(err => {
                    this.logService.logError(`Unable to save your work schedule. If the problem persists please escalate the issue at myTimeandExpenses Help.`, true);
                    return of(null);
            }));
    }

    public validateWorkSchedule(wsg: WorkScheduleGroup): Observable<any> {
        return this.api
            .validateWorkSchedule(
                this.userEid,
                this.globalCacheService.getPeriodEnd(),
                TimeServiceMapper.mapWorkScheduleCannotAllowWeekendsToDto(wsg)
            )
            .pipe(
                catchError(err => {
                    this.logService.logError(
                        `Unable to validate the work schedule. If the problem persists, please escalate the problem in myTimeandExpenses Help.`,
                        true
                    );
                    return of(null);
                })
            );
    }

    public saveShiftSchedule(s: ShiftSchedule, periodEnd: Date, timePeriodDates: Date[]): Observable<ShiftSchedule> {
        let shiftInput = new ShiftScheduleInput();
        shiftInput = TimeServiceMapper.mapShiftScheduleToInput(s.shiftScheduleData, timePeriodDates, s.isByPeriod);
        shiftInput.enterpriseId = this.userEid;
        shiftInput.periodEnd = periodEnd;
        shiftInput.shiftOption = s.shiftType;
        shiftInput.isByPeriod = s.isByPeriod;

        return this.api
            .saveShiftSchedule(this.supervisorEid, this.viewMode, shiftInput)
            .pipe(map(res => TimeServiceMapper.mapShiftSchedules(res)))
            .pipe(tap(() => this.globalCacheService.clearTimeSheetFromBuffer(periodEnd)))
            .pipe(catchError(() => {
                this.logService.logError(
                    `Unable to save your shift schedule. If the problem persists please escalate the issue at myTimeandExpenses Help.`,
                    true
                );
                return of(null);
            }));
    }

    public saveShiftScheduleStore(shiftSchedule: ShiftSchedule, periodEnd: Date, timePeriodDates: Date[]): Observable<ShiftSchedule> {
        let shiftInput = new ShiftScheduleInput();
        shiftInput = TimeServiceMapper.mapShiftScheduleToInput(shiftSchedule.shiftScheduleData, timePeriodDates, shiftSchedule.isByPeriod);
        shiftInput.enterpriseId = this.userEid;
        shiftInput.periodEnd = periodEnd;
        shiftInput.shiftOption = shiftSchedule.shiftType;
        shiftInput.isByPeriod = shiftSchedule.isByPeriod;

        return this.api.saveShiftSchedule(this.supervisorEid, this.viewMode, shiftInput)
            .pipe(map(res => TimeServiceMapper.mapShiftSchedules(res)))
            .pipe(catchError(() => {
                this.logService.logError(`Unable to save your shift schedule. If the problem persists please escalate the issue at myTimeandExpenses Help.`, true);
                return of(null);
            }));
    }

    public saveShiftScheduleCustomDay(shiftType: string, periodEnd: Date, date: Date): Observable<ShiftSchedule> {
        let shiftInput = new ShiftScheduleInput();
        shiftInput.enterpriseId = this.userEid;
        shiftInput.periodEnd = periodEnd;
        shiftInput.shiftOption = shiftType;
        shiftInput.customDate = date;

        return this.api
            .saveShiftScheduleCustomDay(this.supervisorEid, this.viewMode, shiftInput)
            .pipe(map(output => {
                let shiftSchedule = new ShiftSchedule();
                shiftSchedule.error = output?.error;
                return shiftSchedule;
            }))
            .pipe(tap(() => this.globalCacheService.clearTimeSheetFromBuffer(periodEnd)))
            .pipe(catchError(() => {
                this.logService.logError(
                    `Unable to save your shift schedule. If the problem persists please escalate the issue at myTimeandExpenses Help.`,
                    true
                );
                return of(null);
            }));
    }

    public saveShiftScheduleCustomDayStore(shiftType: string, periodEnd: Date, date: Date): Observable<ShiftSchedule> {
        let shiftInput = new ShiftScheduleInput();
        shiftInput.enterpriseId = this.userEid;
        shiftInput.periodEnd = periodEnd;
        shiftInput.shiftOption = shiftType;
        shiftInput.customDate = date;

        return this.api.saveShiftScheduleCustomDay(this.supervisorEid, this.viewMode, shiftInput)
            .pipe(map(output => {
                let shiftSchedule = new ShiftSchedule();
                shiftSchedule.error = output?.error;
                return shiftSchedule;
            }))
            .pipe(catchError(() => {
                this.logService.logError(`Unable to save your shift schedule. If the problem persists please escalate the issue at myTimeandExpenses Help.`, true);
                return of(null);
            }));
    }

    public getAllowanceRequest(periodEnd: Date, timeCategoryCode: string): Observable<AllowanceRequestOutput> {
        return this.api
            .getAllowanceRequestEntries(this.userEid, timeCategoryCode, periodEnd, this.supervisorEid, this.viewMode)
            .pipe(catchError(() => {
                this.logService.logError(
                    `Unable to get your Allowance Request Info. If the problem persists please escalate the issue at myTimeandExpenses Help.`,
                    true
                );
                return of(null);
            }));
      }
    
      public getAllowanceOvertimeRequest(periodEnd: Date, timeCategoryCode: string): Observable<OvertimeRequest> {
        return this.api
            .getDailyOvertimeRequestEntries(this.userEid, timeCategoryCode, periodEnd, this.supervisorEid, this.viewMode)
            .pipe(map((res: AllowanceRequestOutput) => TimeServiceMapper.mapOvertimeRequest(res)))
            .pipe(catchError(() => {
                this.logService.logError(
                    `Unable to get your Allowance Request Info. If the problem persists please escalate the issue at myTimeandExpenses Help.`,
                    true
                );
                return of(null);
            }));
      }
    
      public saveAllowanceRequest(periodEnd: Date, timeCategoryCode: string, allowanceRequestEntries: AllowanceRequestEntryOutput[]): Observable<boolean> {
        return this.api
            .saveAllowanceRequestEntries(this.supervisorEid, this.viewMode, TimeServiceMapper.mapAllowanceRequestEntriesInput(this.userEid, periodEnd, timeCategoryCode, allowanceRequestEntries))
            .pipe(
                catchError(err => {
                    this.logService.logError(
                        `Unable to save your allowance request. If the problem persists please escalate the issue at myTimeandExpenses Help.`,
                        true
                    );
                    return of(null);
                })
            );
      }

    public saveDailyOvertimeAllowanceRequestEntries(periodEnd: Date, timeCategoryCode: string, overtimeRequestEntries: OvertimeRequestEntry[]): Observable<boolean> {
        return this.api
            .saveDailyOvertimeAllowanceRequestEntries(this.supervisorEid, this.viewMode, TimeServiceMapper.mapOvertimeRequestEntriesInput(this.userEid, periodEnd, timeCategoryCode, overtimeRequestEntries))
            .pipe(
                catchError(err => {
                    this.logService.logError(
                        `Unable to save your daily overtime request. If the problem persists please escalate the issue at myTimeandExpenses Help.`,
                        true
                    );
                    return of(null);
                })
            );
    }
    public saveTimeCategoryRequestEntries(periodEnd: Date, timeCategoryCode: string, overtimeRequestEntries: TimeCategoryRequestEntry[]): Observable<TimeCategoryRequestValidationOutput> {
        return this.api
            .saveTimeCategoryRequestEntries(this.supervisorEid, this.viewMode, TimeServiceMapper.mapTimeCategoryRequestEntriesInput(this.userEid, periodEnd, timeCategoryCode, overtimeRequestEntries))
            .pipe(
                switchMap(res => { return of(new TimeCategoryRequestValidationOutput()); }),
                catchError(err => {
                    if(err.status !== 400)
                    {
                        this.logService.logError(
                            `Unable to save your time category request. If the problem persists please escalate the issue at myTimeandExpenses Help.`,
                            true
                        );
                        return of(null);
                    }
                    return of(JSON.parse(err.response) as TimeCategoryRequestValidationOutput);
                })
            );
    }
    public validateActiveApprovers(timeCategoryCode: string, entries: AllowanceRequestApproverOutput[]): Observable<AllowanceRequestApproverOutput[]> {
        return this.api
            .validateActiveApprovers(timeCategoryCode, this.userEid, this.supervisorEid, this.viewMode, entries)
            .pipe(
                catchError(err => {
                    this.logService.logError(
                        `Unable to validate Active approver. If the problem persists please escalate the issue at myTimeandExpenses Help.`,
                        true
                    );
                    return of(null);
                })
            );
    }

    public getTimeCategoryRequest(periodEnd: Date, timeCategoryCode: string): Observable<TimeCategoryRequestOutput> {
        return this.api
            .getTimeCategoryRequestEntries(this.userEid, timeCategoryCode, periodEnd, this.supervisorEid, this.viewMode)
            .pipe(catchError(() => {
                this.logService.logError(
                    `Unable to get your Time Category Request Info. If the problem persists please escalate the issue at myTimeandExpenses Help.`,
                    true
                );
                return of(null);
            }));
    }

    /**
     * Validate TimeCategory approvers are active or not and if inactive return hasError true else false
     * @param timeCategoryCode selected time category code
     * @param entries Approver list
     * @param periodEnd End date of selected TR
     * @returns Approver list
     */
    public validateTimeCategoryActiveApprovers(timeCategoryCode: string, entries: TimeCategoryRequestApproverOutput[], periodEnd: Date): Observable<TimeCategoryRequestApproverOutput[]> {
        return this.api
            .validateTimeCategoryActiveApprovers(timeCategoryCode, this.userEid, periodEnd, this.supervisorEid, this.viewMode, entries)
            .pipe(
                catchError(err => {
                    this.logService.logError(
                        TimeCategoryLogErrorMsg,
                        true
                    );
                    return of(null);
                })
            );
    }
}
