import { MyteFile } from '../../myte-file';
import { MissingReceiptInfo } from '@sharedClients/expense-api-client';

export class Receipt extends MyteFile {
    public expenseId: string;
    public receiptType: string;
}

export class ExpenseReceipts {
    public receipts: Receipt[] = [];
    public warningMessages: string[] = [];
    public missingReceiptInfo: MissingReceiptInfo;
    public ReceiptTypesRequired: String[] = [];
}

export class ReceiptTypeRequiredUpload {
    public receiptTypeRequired: String;
    public hasUpload: boolean;
}


