import { Injectable } from '@angular/core';
import { MyteBaseService } from '@shared/services/myte-base.service';
import { LogService } from '@shared/services/log/log.service';
import { UserService } from '@shared/services/user/user.service';
import { GlobalEventsService } from '@shared/services/events/global-events.service';
import { PeriodEndService } from '@shared/services/periodend/periodend.service';
import { GlobalCacheService } from '@shared/services/cache/global-cache.service';
import { ActivityViewValidation } from '@shared/models/activity/activity-output/activity-view-validations';
import { ActivityOutPut } from '@shared/models/activity/activity-output/activity-output';
import { ActivityOutputField } from '@shared/models/activity/activity-output/activity-output-field';
import { ActivityOutputItem } from '@shared/models/activity/activity-output/activity-output-item';
import { catchError, map, Observable, of } from 'rxjs';
import { TimeApiClient } from '@shared/clients/time-api-client';
import ActivityGridServiceMapper from './activity-grid-service.mapper';
import {  Activity_ErrorTypes, Activity_SaveCommonErrorMessage, ActivityEntryFields, ActivityField_Options, ActivityTypes, Overlapping_Validation_Message, TimeRange_Validation_Message, TravelHours_ExcessWorkSchedule_Message } from '../../../shared/utils/constants';


@Injectable({
  providedIn: 'root'
})
export class ActivityService extends MyteBaseService {
  public activityOutput: ActivityOutPut;
  public activityOutputItem: ActivityOutputItem;
  public activityOutputField: ActivityOutputField;
  public countryKey: string = null;
  public isValidChargeCode:boolean = true;

  constructor(
    logService: LogService,
    userService: UserService,
    eventService: GlobalEventsService,
    private periodEndService: PeriodEndService,
    private cacheService: GlobalCacheService,
    private api: TimeApiClient) {
    super(userService, cacheService, eventService, logService)
  }

  public validationSaveActivity(activityOutPut: ActivityOutPut): boolean {
    let isValid = true;
    let hasTimeRangeError= false;
    let hasRequiredError = false;
    let errorDisplayName = new Array<any>();
    let activityOutputOverlappingEntry = new Array<ActivityOutputItem>();
    let counter = 0;
    let isValidForWorkShiftTimeRange = true;
    let Overlapping_Validation_Effective_Date = new Date(2024,2,1);
    activityOutPut.activityOutputItem.forEach((item )=>{
      let isvalidForTimeRange = true;
      if (this.cacheService.getUserCountry() == 'DE') {
        let startTime = this.calculateTime(item.activityOutputField.find(field => field.label.match('Start Time')).value);
        let endTime = this.calculateTime(item.activityOutputField.find(field => field.label.match('End Time')).value);
        let activity = item.activityOutputField.find(field => field.label.match('Activity')).value;
        let date = item.activityOutputField.find(field => field.label.match(ActivityEntryFields.Date)).value;
        if(this.IsOnCallORStandByActivity(activity) && this.cacheService.getPeriodEnd() >= Overlapping_Validation_Effective_Date)
        {
        activityOutputOverlappingEntry = this.validateOverlappingActivity(item, activityOutPut.activityOutputItem, activity, date, startTime, endTime);
        }
        else
        {
          activityOutputOverlappingEntry.splice(0,activityOutputOverlappingEntry.length);
        }
        if (activityOutputOverlappingEntry.length > 1) {
          hasTimeRangeError = true;
          if (counter == 0) {
            this.logService.logWarning(Overlapping_Validation_Message.OverlappingError, true, Activity_SaveCommonErrorMessage);
            counter++;
          }
          activityOutputOverlappingEntry.forEach(element => {
            element.activityOutputField.forEach(f => {
              if ((f.label === ActivityEntryFields.StartTime || f.label === ActivityEntryFields.EndTime) && !f.validations.some(v => v.type === Activity_ErrorTypes.OverlappingErrorType)) {
                f.validations.push(new ActivityViewValidation(Activity_ErrorTypes.OverlappingErrorType, Overlapping_Validation_Message.OverlappingError));
              }
            })
          });
        }
        let startlessend = ((startTime >= 20.00) && ((startTime < endTime && endTime <= 24.00) || (endTime <= 6.00))) || ((startTime < 6.00) && (startTime < endTime && endTime <= 6.00)) ? true : false;
        if (activity.match('nightwork') && !startlessend) {
          this.logService.logWarning(TimeRange_Validation_Message.TimeRangeError, true, 'The ACTIVITY is not ready to save.');
          isvalidForTimeRange = false;
          hasTimeRangeError = true;
        }
        if (activity.match(ActivityField_Options.WorkingTimeShift) && !(startTime >= 6 && endTime <= 20))
        {
          this.logService.logWarning(TimeRange_Validation_Message.WorkingShiftTimeRangeError, true, Activity_SaveCommonErrorMessage);
          hasTimeRangeError = true;
          isValidForWorkShiftTimeRange = false;
        }

      }
      item.activityOutputField.forEach(f => {
        let Overlappingvalidation = f.validations.filter(v=> v.type === Activity_ErrorTypes.OverlappingErrorType);
        let validValues = f.validate(f.value, true);
        if (Overlappingvalidation.length > 0) {
          Overlappingvalidation.forEach(v => {
            f.validations.push(v)
          })
        }
        if (!isvalidForTimeRange && f.label === 'Hours') {
          f.validations.push(new ActivityViewValidation('timeRangeError', TimeRange_Validation_Message.TimeRangeError));
        }
        if (!isValidForWorkShiftTimeRange && f.label === ActivityEntryFields.Hours) {
          f.validations.push(new ActivityViewValidation(Activity_ErrorTypes.TimeRangeErrorType, TimeRange_Validation_Message.WorkingShiftTimeRangeError));
          isValidForWorkShiftTimeRange = true;
        }
        this.getErrorDisplayName(validValues ? validValues : null, f.label, errorDisplayName);
        if (!f.isValid() && f.value == "") {
          isValid = false;
          hasRequiredError = hasRequiredError || f.hasRequiredError();
        }
      })
  })
    if (!isValid) {
       if (errorDisplayName.length > 0 && hasRequiredError) {
          let fields = new Array<any>();
          if (errorDisplayName.length > 0) {
              errorDisplayName.forEach(element => {
                  fields.push(element.label);
              });
              this.logService.logWarning('Please correct the following fields: ' + fields.join(", ").replace(/, ([^,]*)$/, ' and $1') + '.', true, 'The ACTIVITY is not ready to save.');
          }
      }
  }
    return isValid && !hasTimeRangeError;
  }
  
  //convert timespan to hours
  public calculateTime(time: string): number {
    return Number(time.split(':')[0]) + (Number(time.split(':')[1]) / 60);
  }

  public validateOverlappingActivity(currentActivityItem: ActivityOutputItem, activityOutputItem: ActivityOutputItem[], activity: string, date: string, startTime: number, endTime: number): ActivityOutputItem[] {
    let activityOutputItemBasedOnSameDateTime = activityOutputItem.filter
      (item => item.activityOutputField.find(field => field.label.match(ActivityEntryFields.Date)).value === date &&
        ((this.calculateTime(item.activityOutputField.find(field => field.label.match(ActivityEntryFields.StartTime)).value) >= startTime && this.calculateTime(item.activityOutputField.find(field => field.label.match(ActivityEntryFields.StartTime)).value) < endTime) ||
          (this.calculateTime(item.activityOutputField.find(field => field.label.match(ActivityEntryFields.EndTime)).value) > startTime && this.calculateTime(item.activityOutputField.find(field => field.label.match(ActivityEntryFields.EndTime)).value) <= endTime)));
    let activityOutputItemBasedOnSameDateTimeCopy = new Array<ActivityOutputItem>();
    activityOutputItemBasedOnSameDateTime.forEach(activityOutputItem => activityOutputItemBasedOnSameDateTimeCopy.push(activityOutputItem));
    activityOutputItemBasedOnSameDateTimeCopy.forEach(activityOutputItem => {
      if (activityOutputItemBasedOnSameDateTime.indexOf(currentActivityItem) != activityOutputItemBasedOnSameDateTime.indexOf(activityOutputItem)) {
        let activityvalue = activityOutputItem.activityOutputField.find(field => field.label.match(ActivityEntryFields.Activity)).value;
        if (!(this.IsOnCallORStandByActivity(activityvalue) && activity != activityvalue))
        {
          activityOutputItemBasedOnSameDateTime.splice(activityOutputItemBasedOnSameDateTime.indexOf(activityOutputItem), 1);
        }
      }
    });
    return activityOutputItemBasedOnSameDateTime;
  }
  public IsOnCallORStandByActivity(activity : string) : boolean
  {
    return activity === ActivityField_Options.OnCallDutyActive || activity === ActivityField_Options.OnCallDutyPassive || activity === ActivityField_Options.StandByDutyActive || activity === ActivityField_Options.StandByDutyPassive; 
  }

  public validationSubmitActivity(activityOutPut: ActivityOutPut): boolean {
    let isValid = true;
    let errorDisplayName = new Array<any>();
    activityOutPut.activityOutputItem.forEach(item =>
      item.activityOutputField.forEach(f => {
        let validValues = f.validations.length > 0;
        this.getErrorDisplayName(f.validations, f.label, errorDisplayName);
        if(validValues){  
          isValid = false;
        }}
    ));
    if (!isValid) {
       if (errorDisplayName.length > 0 ) {
          let fields = new Array<any>();
          if (errorDisplayName.length > 0) {
              errorDisplayName.forEach(element => {
                if (element.label == "Travel Hours in excess of Work Schedule")
                this.logService.logError(TravelHours_ExcessWorkSchedule_Message, true,"");
                  fields.push(element.label);
              });
              this.logService.logWarning('Please correct the following fields: ' + fields.join(", ").replace(/, ([^,]*)$/, ' and $1') + '.', true, 'The ACTIVITY is not ready to save.');
          }
      }
  }
    return isValid;
  }


  public getErrorDisplayName(error: ActivityViewValidation[], label: string, errorDisplayName: Array<any>) {
    if (error != null) {
      error.forEach(e => {
        if ((e.type == 'BlockSaveActivityError' && e.value != null)
          || (e.type == 'required' && e.value != null)
          || (e.type == 'BlockError' && e.value != null)) {
          if (!errorDisplayName.some(err => err.label == label)) {
            errorDisplayName.push({
              label: label,
              type: e.type
            });
          }
        }
      });
    }
  }

  public getActivityListOutput(isSubmit:boolean = false):Observable<ActivityOutPut>{
    const loginEidValue = this.userEid.toLowerCase() == this.loginEid.toLowerCase() ? undefined : this.loginEid;
    return this.api.activityList(this.userEid, this.cacheService.getPeriodEnd(), loginEidValue, this.viewMode,isSubmit)
    .pipe(map((activityListOutput) => ActivityGridServiceMapper.mapActivityOutput(activityListOutput)))
    .pipe(catchError(() => {
      this.logService.logError(
        `Unable to get your Activity within ${this.periodEndService.getActivePeriod()}.
          If the problem persists please escalate the issue at myTimeandExpenses Help.`, true);
      return of(null);
    }));  
  }

  public getActivityRowModelOutput():Observable<ActivityOutPut>{
    const loginEidValue = this.userEid.toLowerCase() == this.loginEid.toLowerCase() ? undefined : this.loginEid;
    return this.api.getActivityRowModel(this.userEid,this.periodEndService.getActivePeriod(),loginEidValue,this.viewMode)
    .pipe(map((activityListOutput) => ActivityGridServiceMapper.mapActivityOutput(activityListOutput)))
    .pipe(catchError(() => {
      this.logService.logError(
        `Unable to get your Activity within ${this.periodEndService.getActivePeriod()}.
          If the problem persists please escalate the issue at myTimeandExpenses Help.`, true);
      return of(null);
    }));  
  }

  public getActivityDetailOutput(activityInput:ActivityOutputItem[]):Observable<ActivityOutPut>{
    const loginEidValue = this.userEid.toLowerCase() == this.loginEid.toLowerCase() ? undefined : this.loginEid;
    return this.api.activityDetail(this.userEid,this.periodEndService.getActivePeriod(),loginEidValue,this.viewMode,ActivityGridServiceMapper.mapActivityInputList(activityInput))
    .pipe(map((activityListOutput) => ActivityGridServiceMapper.mapActivityOutput(activityListOutput)))
    .pipe(catchError(() => {
      this.logService.logError(
        `Unable to get your Activity within ${this.periodEndService.getActivePeriod()}.
          If the problem persists please escalate the issue at myTimeandExpenses Help.`, true);
      return of(null);
    }));  
  }

  public SaveActivities(isTriggeredBySave: boolean, activityInput:ActivityOutputItem[]):Observable<ActivityOutPut>{
    const loginEidValue = this.userEid.toLowerCase() == this.loginEid.toLowerCase() ? undefined : this.loginEid;
    return this.api.saveActivities(this.userEid,this.periodEndService.getActivePeriod(),isTriggeredBySave,loginEidValue,this.viewMode,ActivityGridServiceMapper.mapActivityInputList(activityInput))
    .pipe(map((activityListOutput) => ActivityGridServiceMapper.mapActivityOutput(activityListOutput)))
    .pipe(catchError(() => {
      this.logService.logError(
        `Save failed.
          If the problem persists please escalate the issue at myTimeandExpenses Help.`, true);
      return of(null);
    }));  
  }

}
