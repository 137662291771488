import { ActivityOutPut } from "../../../shared/models/activity/activity-output/activity-output";
import { ActivityOutputItem } from "../../../shared/models/activity/activity-output/activity-output-item";
import { ActivityOutputField } from "../../../shared/models/activity/activity-output/activity-output-field";
import { ActivityInput, ActivityInputColumnsList, ActivityInputEntry, ActivityOutput, ActivityOutputColumnsList, ActivityOutPutEntry } from "@shared/clients/time-api-client";



export default class ActivityGridServiceMapper{

    public static mapActivityOutput(activityOutputDto:ActivityOutPutEntry):ActivityOutPut {
        let activityOutput:ActivityOutPut = new ActivityOutPut();
        activityOutput.activityDisplayName = activityOutputDto.activityDisplayName;
        activityOutput.shouldDisplayTextMessage = activityOutputDto.shouldDisplayTextMessage;
        activityOutput.textDisplayed = activityOutputDto.textDisplayed;
        activityOutput.timeReportStatus = activityOutputDto.timeReportStatus;
        activityOutput.activityOutputItem = this.mapActivityOutputItem(activityOutputDto.activityOutputList);

        return activityOutput;
        
    }

    private static mapActivityOutputField(activityOutputfiledDto:ActivityOutput[]):ActivityOutputField[] {
        let activityOutputfiled:ActivityOutputField[] = [];
        activityOutputfiledDto.map(activityOutputItemsDtoItem =>{
            if(activityOutputItemsDtoItem.key == 'C14' && navigator.language.startsWith('de')){
                activityOutputItemsDtoItem.value = activityOutputItemsDtoItem.value.replace('.',',');
            }
            activityOutputfiled.push(new ActivityOutputField(
                activityOutputItemsDtoItem.key,
                activityOutputItemsDtoItem.label,
                activityOutputItemsDtoItem.visible,
                activityOutputItemsDtoItem.editable,
                activityOutputItemsDtoItem.required,
                activityOutputItemsDtoItem.type,
                activityOutputItemsDtoItem.value,
                activityOutputItemsDtoItem.options,
                activityOutputItemsDtoItem.validations,
                activityOutputItemsDtoItem.toolTip,
                activityOutputItemsDtoItem.isAdjustment,
                activityOutputItemsDtoItem.previousPPAValue,
                activityOutputItemsDtoItem.isProcessedData
            ));
        });

        return activityOutputfiled;
        
    }

    private static mapActivityOutputItem(activityOutputItemDto:ActivityOutputColumnsList[]):ActivityOutputItem[] {
        let activityOutputItems:ActivityOutputItem[] = [];
        activityOutputItemDto.map(activityOutputListDtoItem =>{
            activityOutputItems.push(new ActivityOutputItem(
                activityOutputListDtoItem.activityId,
                false,
                this.mapActivityOutputField(activityOutputListDtoItem.activityOutputList)
            ));
        })

        return activityOutputItems;
        
    }
    public static mapActivityInputList(activityInputItemDto:ActivityOutputItem[]):ActivityInputEntry {

        let columnsFieldsInput = new ActivityInputEntry();
        let activityInputList:ActivityInputColumnsList[] = [];
        
        activityInputItemDto.map(item =>{
            let activityInputListDto = new ActivityInputColumnsList();
            activityInputListDto.activityId = item.activityId;
            activityInputListDto.triggeredBy = item.triggeredBy;
            activityInputListDto.sequenceNumber = 1;
            activityInputListDto.fields = this.mapActivityInputField(item.activityOutputField);
            activityInputList.push(activityInputListDto);

        }) 
        columnsFieldsInput.columnsFields =  activityInputList;

        return columnsFieldsInput;
        
    }

    public static mapActivityInputField(activityOutPutfield: ActivityOutputField[]):ActivityInput[]{

        let activityInputField:ActivityInput[] = [];
        activityOutPutfield.map(item =>{
            if(item.key == 'C14'){
                item.value = item.value.replace(',','.');
            }
            let activityInputDTO = new ActivityInput()
            activityInputDTO.key = item.key,
            activityInputDTO.defaultExpression = null,
            activityInputDTO.value = item.value,
            activityInputDTO.displayName = item.label
            activityInputField.push(activityInputDTO)
        })

        return activityInputField;
    }

}
