<div class ="box" *ngIf="viewMode == 'ReviewEmail' && isTimeTab">
  <div class ="content">{{emailUserName}}</div>
  <div class ="content">{{emailDate}}</div>
  <div class ="content">Status: {{timeReportStatus}}</div>
</div>
<ul class="nav nav-tabs nav-sticky myte-nav" [ngClass]="shouldPreventAutoSave ? 'disabled' : ''" role="tablist" aria-label="Tab list">
  <li class="nav-item myte-tab">
    <a #time class="nav-link" id="time" role="tab" routerLink="/time" [class.active]="isLinkActive('/time')" [ngClass]="{shadowLayer:viewMode=='ReviewEmail'}" [attr.aria-selected]="isLinkActive('/time')" [queryParams]="{ periodEnd: periodEndBase64, viewMode: viewModeBase64 }" (click)="autoSave()" (keydown.Space)="redirect($event); autoSave()">Time<mat-icon fontIcon="error" *ngIf="dictionaryTabs.get('Time')" placement="top" class="icon-align"></mat-icon><span class="screenReaderReadable" *ngIf="dictionaryTabs.get('Time')"> Tab error found on this </span></a>
    <mat-icon class="nav-link" aria-label="TIME" aria-hidden="false" role="tab" [attr.aria-selected]="isLinkActive('/time')" [ngClass]="{'alert-danger': dictionaryTabs.get('Time')}" routerLink="/time" [class.active]="isLinkActive('/time')" [queryParams]="{ periodEnd: periodEndBase64, viewMode: viewModeBase64 }" routerLinkActive="active" (click)="autoSave()" (keydown.Space)="redirect($event); autoSave()" fontIcon="access_time"></mat-icon>
  </li>
  <li class="nav-item myte-tab">
    <a class="nav-link" id="expenses" role="tab" routerLink="/expenses" [ngClass]="{shadowLayer:viewMode=='ReviewEmail'}" [queryParams]="{ periodEnd: periodEndBase64, viewMode: viewModeBase64 }" [class.active]="isLinkActive('/expenses')" [attr.aria-selected]="isLinkActive('/expenses')" (click)="autoSave()" (keydown.Space)="redirect($event); autoSave()">{{expenseTabSupplementText}}<mat-icon fontIcon="error" *ngIf="dictionaryTabs.get('Expenses')" placement="top" class="icon-align"></mat-icon><span class="screenReaderReadable" *ngIf="dictionaryTabs.get('Expenses')"> Tab error found on this </span></a>
    <mat-icon class="nav-link" aria-label="EXPENSES" aria-hidden="false" role="tab" [attr.aria-selected]="isLinkActive('/expenses')" routerLink="/expenses" [queryParams]="{ periodEnd: periodEndBase64, viewMode: viewModeBase64 }" [class.active]="isLinkActive('/expenses')"  (click)="autoSave()" (keydown.Space)="redirect($event); autoSave()" fontIcon="attach_money"></mat-icon>
  </li>
  <li class="nav-item myte-tab"  *ngIf="viewMode != 'ReviewEmail'">
    <a class="nav-link" id="locations" role="tab" routerLink="/locations" [class.active]="isLinkActive('/locations')" [attr.aria-selected]="isLinkActive('/locations')"  [queryParams]="{ periodEnd: periodEndBase64, viewMode: viewModeBase64 }" (click)="autoSave()" (keydown.Space)="redirect($event); autoSave()">Locations<mat-icon fontIcon="error" *ngIf="dictionaryTabs.get('Locations')" placement="top" class="icon-align"></mat-icon><span class="screenReaderReadable" *ngIf="dictionaryTabs.get('Locations')"> Tab error found on this </span></a>
    <mat-icon class="nav-link" aria-label="LOCATIONS" aria-hidden="false" role="tab" [attr.aria-selected]="isLinkActive('/locations')" [ngClass]="{'alert-danger': dictionaryTabs.get('Locations')}" [queryParams]="{ periodEnd: periodEndBase64, viewMode: viewModeBase64 }" routerLink="/locations" [class.active]="isLinkActive('/locations')" (click)="autoSave()" (keydown.Space)="redirect($event); autoSave()" fontIcon="location_on"></mat-icon>
  </li>
  <li class="nav-item myte-tab" *ngIf="viewMode != 'ReviewEmail'">
    <a class="nav-link" id="chargecodes" role="tab" routerLink="/chargecodes" [class.active]="isLinkActive('/chargecodes')" [attr.aria-selected]="isLinkActive('/chargecodes')"  [queryParams]="{ periodEnd: periodEndBase64, viewMode: viewModeBase64 }" (click)="autoSave()" (keydown.Space)="redirect($event); autoSave()">Charge Codes</a>
    <mat-icon class="nav-link" aria-label="CHARGE CODES" aria-hidden="false" role="tab" [attr.aria-selected]="isLinkActive('/chargecodes')" routerLink="/chargecodes" [class.active]="isLinkActive('/chargecodes')"  [queryParams]="{ periodEnd: periodEndBase64, viewMode: viewModeBase64 }" (click)="autoSave()" (keydown.Space)="redirect($event); autoSave()" fontIcon="widgets"></mat-icon>
  </li>
  <li class="nav-item myte-tab" *ngIf="viewMode != 'ReviewEmail'">
    <a class="nav-link" id="adjustments" role="tab" routerLink="/adjustments"  [class.active]="isLinkActive('/adjustments')" [attr.aria-selected]="isLinkActive('/adjustments')" [queryParams]="{ periodEnd: periodEndBase64, viewMode: viewModeBase64 }" (click)="autoSave()" (keydown.Space)="redirect($event); autoSave()">Adjustments</a>
    <mat-icon class="nav-link" aria-label="ADJUSTMENTS" aria-hidden="false" role="tab" [attr.aria-selected]="isLinkActive('/adjustments')" routerLink="/adjustments" [class.active]="isLinkActive('/adjustments')" [queryParams]="{ periodEnd: periodEndBase64, viewMode: viewModeBase64 }" (click)="autoSave()" (keydown.Space)="redirect($event); autoSave()" fontIcon="adjustment"></mat-icon>
  </li>
  <li class="nav-item myte-tab" *ngIf="viewMode != 'ReviewEmail'">
    <a class="nav-link" id="summary" role="tab" [href]="summaryUrl"  routerLink="/summary" routerLinkActive="active"  [queryParams]="{ periodEnd: periodEndBase64, viewMode: viewModeBase64 }" [class.active]="isLinkActive('/summary')" [attr.aria-selected]="isLinkActive('/summary')" (click)="autoSave()" (keydown.Space)="redirect($event); autoSave()">Summary</a>
    <mat-icon class="nav-link" aria-label="SUMMARY" aria-hidden="false" role="tab" [attr.aria-selected]="isLinkActive('/summary')" routerLink="/summary" routerLinkActive="active"  [queryParams]="{ periodEnd: periodEndBase64, viewMode: viewModeBase64 }" [class.active]="isLinkActive('/summary')"  (click)="autoSave()" (keydown.Space)="redirect($event); autoSave()" fontIcon="notes"></mat-icon>
  </li>
  <li class="nav-item myte-tab" *ngIf="showCarInformationTab && viewMode != 'ReviewEmail'">
    <a class="nav-link" id="carPlan" role="tab" routerLink="/carplan" [queryParams]="{ periodEnd: periodEndBase64, viewMode: viewModeBase64 }" [class.active]="isLinkActive('/carplan')" [attr.aria-selected]="isLinkActive('/carplan')" (click)="autoSave()" (keydown.Space)="redirect($event); autoSave()">Car Information</a>
    <mat-icon class="nav-link" aria-label="CAR INFORMATION" aria-hidden="false" role="tab" [attr.aria-selected]="isLinkActive('/carplan')" routerLink="/carplan" [queryParams]="{ periodEnd: periodEndBase64, viewMode: viewModeBase64 }" [class.active]="isLinkActive('/carplan')"  (click)="autoSave()" (keydown.Space)="redirect($event); autoSave()" fontIcon="directions_car"></mat-icon>
  </li>
  <li class="nav-item myte-tab" *ngIf="showCompensatoryTab && viewMode != 'ReviewEmail'">
    <a class="nav-link" id="compensatory" role="tab" routerLink="/compensatory" [queryParams]="{ periodEnd: periodEndBase64, viewMode: viewModeBase64 }" [class.active]="isLinkActive('/compensatory')" [attr.aria-selected]="isLinkActive('/compensatory')" (click)="autoSave()" (keydown.Space)="redirect($event); autoSave()">Compensatory</a>
    <mat-icon class="nav-link"  aria-label="COMPENSATORY" aria-hidden="false" role="tab" [attr.aria-selected]="isLinkActive('/compensatory')" routerLink="/compensatory" [queryParams]="{ periodEnd: periodEndBase64, viewMode: viewModeBase64 }" [class.active]="isLinkActive('/compensatory')"  (click)="autoSave()" (keydown.Space)="redirect($event); autoSave()" fontIcon="rate_review"></mat-icon>
  </li>
  <li class="nav-item myte-tab" *ngIf="showLocationQuestionnaireTab && viewMode != 'ReviewEmail'">
    <a class="nav-link" id="questionnaire" role="tab" routerLink="/questionnaire" [queryParams]="{ periodEnd: periodEndBase64, viewMode: viewModeBase64 }" [class.active]="isLinkActive('/questionnaire')" [attr.aria-selected]="isLinkActive('/questionnaire')" (click)="autoSave()" (keydown.Space)="redirect($event); autoSave()">Questionnaire<mat-icon fontIcon="error" *ngIf="hasQuestionnaireError" ngbTooltip = "Location Questionnaire must be filled out with this time report" placement="bottom" class="icon-align"></mat-icon></a>
    <mat-icon class="nav-link" aria-label="QUESTIONNAIRE" aria-hidden="false" role="tab" [attr.aria-selected]="isLinkActive('/questionnaire')" routerLink="/questionnaire" routerLinkActive="active" [queryParams]="{ periodEnd: periodEndBase64, viewMode: viewModeBase64 }" (click)="autoSave()" (keydown.Space)="redirect($event); autoSave()" fontIcon="edit"></mat-icon>
  </li>
  <li class="nav-item myte-tab"  *ngIf="viewMode != 'ReviewEmail'">
    <a class="nav-link" id="preferences" role="tab" routerLink="/preferences" [class.active]="isLinkActive('/preferences')" [attr.aria-selected]="isLinkActive('/preferences')" [queryParams]="{ periodEnd: periodEndBase64, viewMode: viewModeBase64 }" (click)="autoSave()" (keydown.Space)="redirect($event); autoSave()">Preferences<mat-icon fontIcon="error" *ngIf="dictionaryTabs.get('Preferences')" placement="top" class="icon-align"></mat-icon><span class="screenReaderReadable" *ngIf="dictionaryTabs.get('Preferences')"> Tab error found on this </span></a>
    <mat-icon class="nav-link" aria-label="PREFERENCES" aria-hidden="false" role="tab" [attr.aria-selected]="isLinkActive('/preferences')" routerLink="/preferences" [class.active]="isLinkActive('/preferences')" [queryParams]="{ periodEnd: periodEndBase64, viewMode: viewModeBase64 }"  (click)="autoSave()" (keydown.Space)="redirect($event); autoSave()" fontIcon="people"></mat-icon>
  </li>
  <li class="nav-item myte-tab" *ngIf="showActivityTab && viewMode != 'ReviewEmail'">
    <a class="nav-link" id="activity" role="tab" routerLink="/activity" [class.active]="isLinkActive('/activity')" [attr.aria-selected]="isLinkActive('/activity')"  [queryParams]="{ periodEnd: periodEndBase64, viewMode: viewModeBase64 }" (click)="autoSave()" (keydown.Space)="redirect($event); autoSave()">Time Types<mat-icon fontIcon="error" *ngIf="dictionaryTabs.get('Activity')" placement="top" class="icon-align"></mat-icon><span class="screenReaderReadable" *ngIf="dictionaryTabs.get('Activity')"> Tab error found on this </span></a>
    <mat-icon class="nav-link" aria-label="ACTIVITY" aria-hidden="false" role="tab" [attr.aria-selected]="isLinkActive('/activity')" routerLink="/activity" [class.active]="isLinkActive('/activity')"  [queryParams]="{ periodEnd: periodEndBase64, viewMode: viewModeBase64 }" (click)="autoSave()" (keydown.Space)="redirect($event); autoSave()" fontIcon="view_list"></mat-icon>
  </li>
  <li class="nav-item myte-tab" *ngIf="isAuditmode">
    <a class="nav-link" id="issues" role="tab" routerLink="/issues" [class.active]="isLinkActive('/issues')" [attr.aria-selected]="isLinkActive('/issues')" [queryParams]="{ periodEnd: periodEndBase64, viewMode: viewModeBase64 }"  (keydown.Space)="redirect($event); autoSave()">Issues<mat-icon fontIcon="error" *ngIf="dictionaryTabs.get('Issues')" placement="top" class="icon-align"></mat-icon><span class="screenReaderReadable" *ngIf="dictionaryTabs.get('Issues')"> Tab error found on this </span></a>
    <mat-icon class="nav-link" aria-label="ISSUES" aria-hidden="false" role="tab" [attr.aria-selected]="isLinkActive('/issues')" routerLink="/issues" [class.active]="isLinkActive('/issues')" [queryParams]="{ periodEnd: periodEndBase64, viewMode: viewModeBase64 }"  (click)="autoSave()" (keydown.Space)="redirect($event); autoSave()" fontIcon="people"></mat-icon>
  </li>
  <li class="nav-item myte-tab" *ngIf="isAuditmode">
    <a class="nav-link" id="comments" role="tab" routerLink="/comments" [class.active]="isLinkActive('/comments')" [attr.aria-selected]="isLinkActive('/comments')" [queryParams]="{ periodEnd: periodEndBase64, viewMode: viewModeBase64 }"  (keydown.Space)="redirect($event); autoSave()">Comments<mat-icon fontIcon="error" *ngIf="dictionaryTabs.get('comments')" placement="top" class="icon-align"></mat-icon><span class="screenReaderReadable" *ngIf="dictionaryTabs.get('Comments')"> Tab error found on this </span></a>
    <mat-icon class="nav-link" aria-label="COMMENTS" aria-hidden="false" role="tab" [attr.aria-selected]="isLinkActive('/comments')" routerLink="/comments" [class.active]="isLinkActive('/comments')" [queryParams]="{ periodEnd: periodEndBase64, viewMode: viewModeBase64 }"  (click)="autoSave()" (keydown.Space)="redirect($event); autoSave()" fontIcon="people"></mat-icon>
  </li>
  <li class="controls-container">
    <div *ngIf="inRootPage" class="period-changer-container">
      <myte-represent-dropdown  *ngIf="!isAuditmode"></myte-represent-dropdown>
      <button class="accessibility-label" *ngIf="isAuditmode" tabindex="0" role="button"> Audit Status : {{auditStatus}}
      </button>
      <myte-period-changer *ngIf="viewMode != 'ReviewEmail' && !isExpensesTab" (isAutoSave)="autoSave($event)" [userActiveDate]="userActiveDate"></myte-period-changer>
      <myte-period-changer-store *ngIf="viewMode != 'ReviewEmail' && isExpensesTab" (isAutoSave)="autoSave($event)" [userActiveDate]="userActiveDate"></myte-period-changer-store>
    </div>
    <ng-template #printTooltip>
      <span>Print Time and Expense Report</span>
    </ng-template>
    <button [ngbTooltip]="printTooltip" placement="start" triggers="hover"
      *ngIf="shouldPrintButtonBeDisabled() && viewMode != 'ReviewEmail'"
      tabindex="0" class="print-container accessibility-div" (click)="onShowPdfViewer(true)"
      aria-label="Print time report">
      <mat-icon aria-hidden="true" fontIcon="print"></mat-icon>
    </button>
    <ng-template #logoutTooltip>
      <span>Exit to myTE</span>
    </ng-template>
    <button [ngbTooltip]="logoutTooltip" placement="start" triggers="hover"
      *ngIf="viewMode != 'ReviewEmail' && islocal" tabindex="0" class="print-container accessibility-div" (click)="logout()">
      <mat-icon aria-hidden="true" fontIcon="exit_to_app"></mat-icon>
    </button>
  </li>
</ul>
<div *ngIf="showSpinner" class="spinner-container">
    <myte-spinner></myte-spinner>
</div>

