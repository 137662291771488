
import Expense from "@shared/models/expense/expense";
import Timesheet from '@shared/models/time-sheet/time-sheet';
import TimeEntry from "@shared/models/time-sheet/time-entry/time-entry";
import { LocationInfo, SummaryExpense, TimesheetSummaryCardOutput } from "@shared/clients/timereport-api-client";
import { Injectable } from "@angular/core";
import { NumberFormatUtils } from "@shared/utils/numberFormater.utils";
import { TimeCategoryTask } from "@shared/models/time-sheet/time-category/time-category-task";
import TimeEntryCharge from "@shared/models/time-sheet/time-entry/time-entry-charge";
import { AssignmentSummary, ExpensesCard, LocationCard } from "@shared/models/time-sheet/time-sheet-summary";
import { COMP_MEMO_AMOUNT, COMP_MEMO_WARNING_TOOLTIP, KM_MILES_AMOUNT, KM_MILES_WARNING_TOOLTIP } from "../../../../../app/myte-expenses/components/myte-expense-grid/expense-grid/constants/expensesAmountTooltips";

@Injectable({ providedIn: "root" })
export class TimesheetSummaryService {
    public assignments: AssignmentSummary[] = [];
    public totalHours: number = 0;
    public scheduledHours: number = 0;
    public overtime: number = 0;
    public expenses = new ExpensesCard();
    public locations: LocationCard[] = [];
    public totalExpensesAmount: number = 0;
    private summaryCardData: TimesheetSummaryCardOutput;

    constructor() {}

    public resetValues() {
        this.assignments = new Array<AssignmentSummary>();
        this.totalHours = 0;
        this.scheduledHours = 0;
        this.overtime = 0;
        this.expenses = new ExpensesCard();
        this.locations = new Array<LocationCard>();
        this.totalExpensesAmount = 0;
    }

    public setScheduleHoursAndOvertime(tasks: TimeCategoryTask[], shouldUseTwoDecimal: boolean) {
        this.scheduledHours = this.getTaskValue(tasks, shouldUseTwoDecimal);
        this.overtime = NumberFormatUtils.formatNumberToLocaleString(this.summaryCardData?.overtime, shouldUseTwoDecimal ? 2 : 1, shouldUseTwoDecimal ? 2 : 1);
    }

    /**
    * 
    * @param task from timesheet
    * @param shouldUseTwoDecimal from preferences 
    * @returns number
    * W: work schedule
    * S: shift schedule
    */
    public getTaskValue(task: any, shouldUseTwoDecimal: boolean): number {
       let hours = task?.find(row => row?._code === 'W' || row?._code ==='S')?.totalHours;
       return hours != undefined && hours != null ?
                    NumberFormatUtils.formatNumberToLocaleString(Number(hours), shouldUseTwoDecimal ? 2 : 1, shouldUseTwoDecimal ? 2 : 1) :
                    0;
    }

    public setSummaryExpenses(isCarPlan: boolean) {
        let expensesList:any = [];
        let decimalPlaces = 0;
        this.summaryCardData?.expenses?.forEach(exp => {
            decimalPlaces = exp.decimalPlaces;
            let isKmMilesWithCarPlan = exp.expenseTypeCd == 'EX05' && isCarPlan;
            let isCompensatoryMemo = exp.expenseTypeCd == 'EX10';

            if (this.expenses.dictionaryExpenses[exp.expenseTypeCd] != undefined) {
                this.expenses.dictionaryExpenses[exp.expenseTypeCd].amount += exp.amount;
            } else {
                this.expenses.dictionaryExpenses[exp.expenseTypeCd] = {
                    amount: exp.amount,
                    expenseType: exp.expenseType,
                    displayMessage: isKmMilesWithCarPlan || isCompensatoryMemo,
                    message: isKmMilesWithCarPlan ? KM_MILES_AMOUNT : isCompensatoryMemo ? COMP_MEMO_AMOUNT : '',
                    tooltip: isKmMilesWithCarPlan ? KM_MILES_WARNING_TOOLTIP : isCompensatoryMemo ? COMP_MEMO_WARNING_TOOLTIP : '',
                };
            }

            if (!isKmMilesWithCarPlan && !isCompensatoryMemo)
                this.expenses.totalAmount += exp.amount;
        });

        let expensesKeys = Object.keys(this.expenses.dictionaryExpenses);
        this.expenses.totalAmount = NumberFormatUtils.formatNumberToLocaleString(this.expenses.totalAmount, decimalPlaces, decimalPlaces);
        expensesKeys?.forEach(expense => {
            this.expenses.dictionaryExpenses[expense].amount = NumberFormatUtils.formatNumberToLocaleString(this.expenses.dictionaryExpenses[expense].amount, decimalPlaces, decimalPlaces);
            expensesList.push(this.expenses.dictionaryExpenses[expense]);
        });

        this.expenses.dictionaryExpenses = expensesList;
    }

    public setTimesheet(timesheet: Timesheet, shouldUseTwoDecimal: boolean) {
        timesheet?.timeEntry?.tasks?.forEach((task) => {
            if (task?.charges[0] instanceof TimeEntryCharge && task?.assignment?.code != '') {
                this.totalHours += task?.total;
                this.assignments?.push(new AssignmentSummary(task?.assignment?.description, task?.assignment?.code, task?.total, shouldUseTwoDecimal));
            }
        });
        
        this.totalHours = NumberFormatUtils.formatNumberToLocaleString(this?.totalHours, shouldUseTwoDecimal ? 2 : 1, shouldUseTwoDecimal ? 2 : 1);
    }

    public getLocationHours(timeEntry: TimeEntry, shouldUseTwoDecimal: boolean) {
        this.summaryCardData?.locations?.forEach(location => {
            let newLocation = new LocationCard(location.description, '', 0, shouldUseTwoDecimal, location.locationCode.locationReasonNm);
            let lastDayPushed: number = null;
            location.dayEntries.forEach((dayEntry, indexByDay) => {
                let currentDay = dayEntry.datetime.getDate();
                if (dayEntry.checked == true) {
                    // If it's not a new element and consecutive day
                    if (newLocation.lastDayAdded != null && newLocation.lastDayAdded + 1 == currentDay) {
                        newLocation.hours += dayEntry.hours != null ? dayEntry.hours : timeEntry.totals[indexByDay].totals;
                        newLocation.lastDayAdded = currentDay;
                    } else if (newLocation.lastDayAdded == null) {
                        // if it's new day
                        newLocation.days = dayEntry.datetime.toLocaleString('us-US', { month: 'short' }).toString() + ' ' + currentDay.toString();
                        newLocation.hours = dayEntry.hours != null ? dayEntry.hours : timeEntry.totals[indexByDay].totals;
                        newLocation.lastDayAdded = currentDay;
                        lastDayPushed = currentDay;
                        } else if (newLocation.lastDayAdded != null) {
                            // if it's not a consecutive date and already exists
                            newLocation.days += lastDayPushed != newLocation.lastDayAdded ?
                                                    '-'+ newLocation.lastDayAdded.toString() + ', ' + currentDay.toString() : ', ' + currentDay.toString();
                            newLocation.hours += dayEntry.hours != null ? dayEntry.hours : timeEntry.totals[indexByDay].totals;
                            newLocation.lastDayAdded = currentDay;
                            lastDayPushed = currentDay;
                    }
                }
                // end of iteration, update elements
                if (indexByDay == location.dayEntries.length - 1) {
                    newLocation.days += lastDayPushed != newLocation.lastDayAdded ?
                                        '-'+ newLocation.lastDayAdded.toString() : '';
                    if (newLocation.reasonForTravelLocation) {
                        newLocation.fullLocation = newLocation.fullLocation.substring(0, newLocation.fullLocation.length - newLocation.reasonForTravelLocation.length - 3); 
                        // Truncate description always with | between country and location                                       
                        if (newLocation.fullLocation.indexOf(' | ') != -1) 
                            newLocation.fullLocation = newLocation.fullLocation.substring(newLocation.fullLocation.indexOf(' | ') + 2) + ' - ' + newLocation.fullLocation.substring(0, newLocation.fullLocation.indexOf(' | '));
                
                        newLocation.fullLocation += ' - ' + newLocation.reasonForTravelLocation + ' Reason';     
                    } 
                    else 
                        // Truncate description always with | between country and location
                        if (newLocation.fullLocation.indexOf(' | ') != -1) 
                            newLocation.fullLocation = newLocation.fullLocation.substring(newLocation.fullLocation.indexOf(' | ') + 2) + ' - ' + newLocation.fullLocation.substring(0, newLocation.fullLocation.indexOf(' | '));
                }
            })
              newLocation.hours = NumberFormatUtils.formatNumberToLocaleString(newLocation.hours, shouldUseTwoDecimal ? 2 : 1, shouldUseTwoDecimal ? 2 : 1);
              this.locations.push(newLocation);
        });
    }

    public setSummaryCardData(data: TimesheetSummaryCardOutput) {
        this.summaryCardData = data;
    }
}