import {
  Component,
  OnInit,
  OnDestroy,
  ElementRef,
  AfterViewInit,
  ViewChild,
} from "@angular/core";
import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import TimeReport from "../../../shared/models/time-report/time-report";
import { ActivatedRoute } from "@angular/router";
import { GlobalEventsService } from "../../../shared/services/events/global-events.service";
import { SubordinatesMode } from "../../../shared/models/subordinates/subordinate-mode";
import { GlobalCacheService } from "../../../shared/services/cache/global-cache.service";
import Subordinate from "../../../shared/models/subordinates/subordinate";
import { environment } from "../../../../environments/environment";
import { SubordinatesService } from "../../../shared/services/subordinates/subordinates.service";
import { OverwriteUniversalHeaderHyperlinksService } from "@shared/services/overwrite-uh-hyperlinks/overwrite-uh-hyperlinks.service";
import { TimeReportStoreService } from "@shared/services/store/time-report-store.service";

@Component({
  selector: "myte-page-content",
  templateUrl: "./page-content.component.html",
  styleUrls: ["./page-content.component.sass"],
})
export class PageContentComponent implements OnInit, OnDestroy {
  public isFluidHeightRouterComponent: boolean;
  public timeReport: TimeReport;
  public subordinateToRemove: Subordinate[];
  public isShowRemoveModal: boolean;
  public isShowSubmitAllModal: boolean;
  public subordinatesToSubmit: Subordinate[];
  public isReview: boolean;
  public subordinateMode: SubordinatesMode;
  private componentDestroyed: Subject<void> = new Subject();

  public get isSubordinateMode(): boolean {
    return this.subordinateMode !== SubordinatesMode.None;
  }
  isAuditMode: boolean;
  isAuditSubscription: Subscription;
  constructor(
    private route: ActivatedRoute,
    private globalEventService: GlobalEventsService,
    private subordinatesService: SubordinatesService,
    private globalEventsService: GlobalEventsService,
    public globalCacheService: GlobalCacheService,
    private overwriteUHService: OverwriteUniversalHeaderHyperlinksService,
    private timeReportStoreService: TimeReportStoreService
  ) {
    this.timeReport =
      this.route.snapshot.data?.timeReportData?.timeReportSettings?.timeReport;
    
    if (environment.name != "Local") {
      this.timeReportStoreService.timeReport$.pipe(takeUntil(this.componentDestroyed)).subscribe(() => {
        this.overwriteUHService.hideActionButtonsForElement();
      });
    }
  }

  public ngOnInit(): void {
    const subordinateUrlValue = this.route.snapshot.queryParams.viewMode;
    if (!subordinateUrlValue && this.globalCacheService.getInfoFromSessionStorage(this.globalCacheService.auditeeInfoKey)) {
      this.globalCacheService.resetSessionStorage(this.globalCacheService.auditeeInfoKey);
    }
    this.isAuditSubscription = this.subordinatesService
      .getAuditMode()
      .subscribe((res) => {
        if (res || this.globalCacheService.getInfoFromSessionStorage(this.globalCacheService.auditeeInfoKey)) {
          this.isAuditMode = true;
        } else {
          this.isAuditMode = false;
        }
      });
    this.globalCacheService
      .getTimeReport(this.globalCacheService.getPeriodEnd())
      .subscribe((timeReport) => {
        if (timeReport) {
          this.timeReport = timeReport;
        }
      });
    this.globalEventService
      .updateTimeReportEvent()
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((timeReport) => {
        if (+timeReport.periodEnd === +this.timeReport.periodEnd) {
          this.timeReport = timeReport;
        }
      });
    this.subordinateMode = this.globalCacheService.getSubordinateMode();

    this.globalEventService
      .selectShowSubordinateDeletePopup()
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((subordinates) => {
        this.subordinateToRemove = subordinates;
        this.isShowRemoveModal = true;
      });

    this.globalEventService
      .selectSubmitAllEvent()
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((payload) => {
        if (payload.shouldRefreshSubordinateList) return;
        this.isShowSubmitAllModal = !payload.shouldRefreshSubordinateList;
        this.subordinatesToSubmit = payload.subordinates;
        this.isReview = payload.isReview;
      });
    this.loadExternalScripts();
  }

  public ngOnDestroy(): void {
    this.componentDestroyed.next();
    this.componentDestroyed.complete();
    this.isAuditSubscription.unsubscribe();
  }

  public onRouterOutletActivate(routerComponent: any) {
    // routerComponent.isFluidHeightRouterComponent
    //   ?.subscribe(value => this.isFluidHeightRouterComponent = value);
  }

  public openRemovePopup() {
    this.isShowRemoveModal = true;
  }

  public closeRemovePopup() {
    this.isShowRemoveModal = false;
  }

  public closeSubmitAllPopup() {
    this.isShowSubmitAllModal = false;
  }

  /** Use this function to load external scripts.
   * Avoid delays in loading time of the myTE application.
   */
  private loadExternalScripts() {
    if (environment.name == "Local") return;

    let acnuniversalheader = document.getElementById("acnuniversalheader");
    if (acnuniversalheader != null) return;

    if (environment.name == "Production") {
      this.overwriteUHService.loadScript(
        "https://notificationsadmin.accenture.com/widget/acnuniversalheader.js?v=myte",
        false,
        "acnuniversalheader"
      );
      this.loadScript(
        "https://notificationsadmin.accenture.com/widget/acnalerts.js"
      );
      this.loadScript(
        "https://notificationsadmin.accenture.com/widget/acntoasts.js"
      );
      this.loadScript(
        "https://notificationsadmin.accenture.com/widget/acnmodal.js"
      );
      this.loadScript(
        "https://notificationsadmin.accenture.com/widget/acnchat.js"
      );
    } else {
      this.overwriteUHService.loadScript(
        "https://notificationsadmin.ciostage.accenture.com/widget/acnuniversalheader.js?v=myte",
        true,
        "acnuniversalheader"
      );
      this.loadScript(
        "https://notificationsadmin.ciostage.accenture.com/widget/acnalerts.js"
      );
      this.loadScript(
        "https://notificationsadmin.ciostage.accenture.com/widget/acntoasts.js"
      );
      this.loadScript(
        "https://notificationsadmin.ciostage.accenture.com/widget/acnmodal.js"
      );
      this.loadScript(
        "https://notificationsadmin.ciostage.accenture.com/widget/acnchat.js"
      );
    }
  }

  private loadScript(url: string, id?: string) {
    let body = <HTMLDivElement>document.body;
    let script = document.createElement("script");
    script.innerHTML = "";
    script.src = url;
    if (id != null && id != undefined) script.id = id;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }

}
