import { LocationCharge } from './location-charge';
import { ChargeCode } from './charge-code';

export class MultipleLocationsCharge {
  public chargeCode: ChargeCode;
  public totalHours: number;
  public locationsCharges: Array<LocationCharge>;

  constructor(chargeCode?: ChargeCode, totalHours?: number, locationsCharges?: Array<LocationCharge>) {
    this.chargeCode = chargeCode ?? new ChargeCode;
    this.totalHours = totalHours;
    this.locationsCharges = locationsCharges ?? new Array(2).fill(new LocationCharge());
  }
}
