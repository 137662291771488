import { Component } from '@angular/core';
import { AnnualChargeabilityRow } from '@shared/models/summary/annual-chargeability/annual-chargeability-row.model';
import { LocationsSummaryRow } from '@shared/models/summary/time-report-summary/locations-summary-row';
import { TimeReportSummaryRow } from '@shared/models/summary/time-report-summary/time-report-summary-row';
import { SummaryGridDataType } from '@shared/models/summary/time-report-summary/time-report-summary.model';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'myte-summary-grid-default-renderer',
  templateUrl: './summary-grid-default-renderer.component.html',
  styleUrls: ['./summary-grid-default-renderer.component.sass']
})
export class SummaryGridDefaultRendererComponent implements ICellRendererAngularComp {
  public params: ICellRendererParams<TimeReportSummaryRow | LocationsSummaryRow | AnnualChargeabilityRow, any>;
  public summaryGridDataType: typeof SummaryGridDataType = SummaryGridDataType;
  public toolTip: string = `The standard number of hours worked in a period but does not include Overtime or Approved Absences`;
  public appShellWidth: number;
  public titleColumn: boolean;

  public agInit(params: ICellRendererParams<TimeReportSummaryRow | LocationsSummaryRow | AnnualChargeabilityRow, any>): void {
    this.params = params;
    this.params.value = params.column.getColDef().headerName === 'Charge Code' && params.node.isRowPinned() && !params.value ? '' : params.value;
    this.titleColumn = !params.node.isRowPinned() && params.column.getColDef() && (params.column.getColDef().headerName === 'Charge Code' || params.column.getColDef().headerName === 'Country/Region' || params.column.getColDef().headerName === 'Location' || params.column.getColDef().headerName === 'Charge Code');
    this.appShellWidth = Math.floor(Math.random() * (90 - 40 + 1)) + 40; // for app-shell display

    const cell: HTMLElement = params.eGridCell;
    const elementAriaHidden: Element = cell.querySelector('[aria-hidden="true"]');
    if (elementAriaHidden)
      elementAriaHidden.setAttribute('aria-hidden', 'false');
  }

  public refresh(): boolean {
    return false;
  }
}