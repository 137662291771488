import { 
    AllowanceRequestChargeCodeOutput,
    AllowanceRequestApproverOutput,
    AllowanceRequestTimeOutput,
    AllowanceRequestReasonForOvertimeOutput,
    AllowanceRequestOvertimeSplitHoursOutput,
    AllowanceRequestDescriptionOutput
} from '../../../clients/time-api-client';
import { OvertimeRequestEntryGridConfiguration } from './overtime-request-entry-grid-configuration';

export class OvertimeRequestEntry {
    date?: Date;
    daySequenceNbr: number;
    overtimeSplitHoursInfo?: AllowanceRequestOvertimeSplitHoursOutput;
    chargeCodeInfo?: AllowanceRequestChargeCodeOutput;
    approverInfo?: AllowanceRequestApproverOutput;
    hours?: number;
    isPrepopulatedEntry?: boolean;
    isHoliday?: boolean;
    startTimeInfo?: AllowanceRequestTimeOutput;
    endTimeInfo?: AllowanceRequestTimeOutput;
    reasonForOvertimeInfo?: AllowanceRequestReasonForOvertimeOutput;
    descriptionInfo?: AllowanceRequestDescriptionOutput;
    hasDescriptionAdjustment?: boolean;
    isVisible: boolean;
    gridConfiguration?: OvertimeRequestEntryGridConfiguration;

    constructor() {
        this.chargeCodeInfo = new AllowanceRequestChargeCodeOutput();
        this.overtimeSplitHoursInfo = new AllowanceRequestOvertimeSplitHoursOutput();
        this.chargeCodeInfo.selectableChargeCodes = [];
        this.approverInfo = new AllowanceRequestApproverOutput();
        this.approverInfo.selectableApprovers = [];
        this.reasonForOvertimeInfo = new AllowanceRequestReasonForOvertimeOutput();
        this.reasonForOvertimeInfo.selectableReasons = [];
        this.startTimeInfo = new AllowanceRequestTimeOutput();
        this.startTimeInfo.hours = null;
        this.endTimeInfo = new AllowanceRequestTimeOutput();
        this.endTimeInfo.hours = null;
        this.descriptionInfo = new AllowanceRequestDescriptionOutput();
    }
}