import { Component } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';
  
  @Component({
    selector: '[toast-component]',
    styles: [],
    templateUrl: './myte-toast.html',
    animations: [],
    preserveWhitespaces: false,
  })

  export class MyteToast extends Toast {
    constructor(
      protected toastrService: ToastrService,
      public toastPackage: ToastPackage,
    ) {
      super(toastrService, toastPackage);
    }
  }