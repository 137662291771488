export default class DatePickerViewModel {
    day: number;
    month: number;
    year: number;

    constructor(date: Date) {
        date = typeof(date) == "string" ? new Date(date) : date;
        this.day = date?.getDate();
        this.month = date?.getMonth() + 1;
        this.year = date?.getFullYear();
    }
}