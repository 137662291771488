import { Injectable } from '@angular/core';
import { LocationsApiClient } from '../../../shared/clients/locations-api-client';
import { UserService } from '../../../shared/services/user/user.service';
import { PeriodEndService } from '../../../shared/services/periodend/periodend.service';
import { Observable, of } from 'rxjs';
import DropdownItem from '../../../shared/models/controls/dropdown/dropdown-item';
import { TimeEntryTemplate } from '../../../shared/models/time-sheet/time-entry/time-entry-template';
import { TimeApiClient, TimesheetTemplateOutput } from '../../../shared/clients/time-api-client';
import TimeEntryTemplateMapper from './time-entry-template.mapper';
import { LogService } from '../../../shared/services/log/log.service';
import { map, catchError, tap } from 'rxjs/operators';
import { GlobalCacheService } from '../../../shared/services/cache/global-cache.service';
import { MyteBaseService } from '../../../shared/services/myte-base.service';
import { GlobalEventsService } from '../../../../app/shared/services/events/global-events.service';
import  LocationsServiceMapper  from '../../../myte-locations/shared/services/locations/locations.service.mapper';
import { LocationOnes, LocationTwo } from '@shared/models/locations';

@Injectable()
export class TimeEntryTemplateService extends MyteBaseService {
  constructor(private locationApi: LocationsApiClient,
              private timeApi: TimeApiClient,
              private periodEndService: PeriodEndService,
              userService: UserService,
              logService: LogService,
              globalEventsService: GlobalEventsService,
              globalCacheService: GlobalCacheService) {
      super(userService, globalCacheService, globalEventsService, logService);
  }

  public getTimeEntryTemplate(): Observable<TimeEntryTemplate> {
    return this.timeApi.getTimeEntryTemplate(this.userEid, this.periodEndService.getActivePeriod(), this.supervisorEid, this.viewMode)
      .pipe(map(tet => TimeEntryTemplateMapper.mapTimeEntryTemplate(tet)))
      .pipe(catchError(err => {
        this.logService.logError(err, true);
        return of(new TimeEntryTemplate());
      }));
  }

  public saveTimeEntryTemplate(timeEntryTemplate : TimeEntryTemplate): Observable<TimesheetTemplateOutput> {
    timeEntryTemplate.enterpriseId = this.userEid;    
    let timeentryInput = TimeEntryTemplateMapper.mapTimeEntryInput(timeEntryTemplate);
    timeentryInput.periodEndTime = this.globalCacheService.getPeriodEnd();
    return this.timeApi.saveTimeEntryTemplate(this.supervisorEid,this.viewMode, timeentryInput)
      .pipe(tap(() => {
        this.globalCacheService.resetTimeSheet();
        this.globalCacheService.resetWorkLocationList(); //Clears all work locations from the buffer cache when user sets new template.
      }))
      .pipe(catchError(err => {
        this.logService.logError(err, true);
        return of(new TimesheetTemplateOutput());
      }));
  }

    public saveTimeEntryTemplateStore(timeEntryTemplate: TimeEntryTemplate, periodEnd: Date): Observable<TimesheetTemplateOutput> {
        timeEntryTemplate.enterpriseId = this.userEid;
        let timeEntryInput = TimeEntryTemplateMapper.mapTimeEntryInput(timeEntryTemplate);
        timeEntryInput.periodEndTime = periodEnd;
        return this.timeApi.saveTimeEntryTemplate(this.supervisorEid, this.viewMode, timeEntryInput)
                .pipe(catchError(error => {
                    this.logService.logError(error, true);
                    return of(new TimesheetTemplateOutput());
                }));
    }

  public getCountries(): Observable<DropdownItem[]> {
    return this.locationApi.getCountries(this.periodEndService.getActualPeriod())
      .pipe(map(x => Object.entries(x.countries).map(value => new DropdownItem(value[0], value[1]))));
  }

  public getLocationOne(countryCode: string): Observable<LocationOnes> {
    return this.locationApi.getLocationOne(this.userEid, this.periodEndService.getActualPeriod(), countryCode, this.supervisorEid, this.viewMode)
      .pipe(
        map(x => {
          let locationsOnes = new LocationOnes();
          locationsOnes.locationDropdown = Object.entries(x.locationsOne).map(value => new DropdownItem(value[0].split('/')[0],value[1]));
          locationsOnes.isReasonForTravelLocationEnable = x.isReasonForTravelLocationEnable;
          return locationsOnes;
        })
      );
  }

  public getLocationTwo(countryCode: string, locationOneCode: string): Observable<LocationTwo> {
    return this.locationApi.getLocationTwo(this.userEid, this.periodEndService.getActualPeriod(), countryCode, locationOneCode, this.supervisorEid, this.viewMode)
      .pipe(
        map(x => {
          let locationsTwos = new LocationTwo();
          locationsTwos.locationTwoDropdown = Object.entries(x.locationsTwo).map(value => new DropdownItem(value[0].split('/')[0],value[1]));
          locationsTwos.isReasonForTravelLocationEnable = x.isReasonForTravelLocationEnable;
          return locationsTwos;
        })
      );
  }

  public getLocationReason(): Observable<any[]> {
    return this.locationApi.getLocationReason(this.periodEndService.getActivePeriod()).pipe(
      map(locationReasonOutput=> LocationsServiceMapper.mapLocationReason(locationReasonOutput))
    );
  }  
}
