<form *ngIf="status"
    (drop)="dropHandler($event)"
    (dragenter)="dragEnterHandler($event)"
    (dragover)="dragOverHandler($event)"
    (dragleave)="dragLeaveHandler($event)"
    (click)="handleClick()"
    class="status-container">
    <div class="status-inner-container" ngbTooltip="{{ toolTip }}" placement="end-top" container="body" id="uploadReceiptCell">
        <span [ngClass]="colorStatusElement" >{{ status }}</span>
    </div>
    <span [ngClass]="colorStatusElement">{{ status }}</span>
    <input #inputfile [attr.aria-label]="'file '+status" type="file" myteSimplifyedUploadReceipt (cancel)="onCancelFileUpload()" (filesSelected)="onFileChange($event)">
</form>