import { TimeReportSummaryRow } from "./time-report-summary-row";
import { SummaryGridDataType } from "./time-report-summary.model";

export class SummaryGridData {
    public rowData: Array<TimeReportSummaryRow>;
    public pinnedBottomRowData: Array<TimeReportSummaryRow>;

    constructor(rowData?: Array<TimeReportSummaryRow>, pinnedBottomRowData?: Array<TimeReportSummaryRow>) {
    
        this.rowData = rowData ?? new Array(1).fill(new TimeReportSummaryRow());
        this.pinnedBottomRowData = pinnedBottomRowData ?? [new TimeReportSummaryRow(SummaryGridDataType.Total), new TimeReportSummaryRow(SummaryGridDataType.WorkSchedule), new TimeReportSummaryRow(SummaryGridDataType.Overtime), new TimeReportSummaryRow(SummaryGridDataType.StandardHours), new TimeReportSummaryRow(SummaryGridDataType.StandardPercentageHours)];
    }
}
