import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MyteControlsModule } from '../myte-controls/myte-controls.module';
import { SubordinateModeResolver } from './services/resolvers/subordinate-mode.resolver';
import { SubordinatedCategoryComponent } from './components/subordinated-category/subordinated-category.component'
import { SubordinatesBarComponent } from './components/subordinates-bar/subordinates-bar.component'
import { NgbModule , NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    SubordinatedCategoryComponent,
    SubordinatesBarComponent
  ],
  exports: [
    SubordinatesBarComponent
  ],
  imports: [
    NgbModule,
    NgbTooltipModule,
    CommonModule,
    MatIconModule,
    MyteControlsModule
  ],
  providers: [
    SubordinateModeResolver
  ]
})
export class MyteSubordinatesModule { }
