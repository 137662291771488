export interface RequestMocked {
    description: string;
    requestBody: string;
    requestType: RequestTypes;
    url: string;
    urlJson: string;
}

export enum RequestTypes {
    'POST' = 'POST',
    'GET' = 'GET'
}