import { Injectable } from '@angular/core';
import { ColDef, GridOptions, ICellRendererParams, RowClassParams, SuppressHeaderKeyboardEventParams, SuppressNavigableCallbackParams, ValueGetterParams } from 'ag-grid-community';
import { AnnualChargeabilityChildRow, AnnualChargeabilityParentRow, AnnualChargeabilityRow } from '../../../../shared/models/summary/annual-chargeability/annual-chargeability-row.model';
import { ChargeabilityCellRendererComponent } from '../../../components/myte-summary-popups/chargeability-popup/chargeability-cell-renderer/chargeability-cell-renderer.component';
import { ExpenseGridHeaderComponent } from '../../../../myte-expenses/components/myte-expense-grid/expense-grid-header/expense-grid-header.component';
import { SummaryGridDefaultRendererComponent } from '../../../components/myte-summary-grid/summary-grid-renderer/summary-grid-default-renderer/summary-grid-default-renderer.component';
import { DateformatPipe } from '@shared/pipes/dateformat.pipe';
import { FormatDateType } from '@shared/utils/constants';

@Injectable()
export class ChargeabilityGridService {

  constructor() { }

  public getGridOptions(): GridOptions<AnnualChargeabilityRow> {
    return {
      rowHeight: 30,
      headerHeight: 35,
      suppressMovableColumns: true,
      suppressAnimationFrame: true,
      suppressLoadingOverlay: true,
      suppressHorizontalScroll: true,
      ensureDomOrder: true,
      domLayout: 'autoHeight',
      defaultColDef: this.getDefaultColumnDefinitions(),
      columnDefs: this.getColumnDefinitions(),
      components: { agColumnHeader: ExpenseGridHeaderComponent },
      getRowClass: (params: RowClassParams) => params.node.isRowPinned() ? ['readonly-row', 'bold'] : []
    };
  }

  private getDefaultColumnDefinitions(): ColDef<AnnualChargeabilityRow> {
    return {
      suppressHeaderKeyboardEvent: (event: SuppressHeaderKeyboardEventParams<AnnualChargeabilityRow>) => true,
      suppressNavigable: true,
      sortable: false,
      resizable: false,
      filter: false,
      minWidth: 100
    };
  }

  private getColumnDefinitions(): ColDef<AnnualChargeabilityRow>[] {
    return [
      {
        colId: 'timeReportPeriod',
        headerName: 'Time Report Period',
        minWidth: 170,
        suppressNavigable: (params: SuppressNavigableCallbackParams<AnnualChargeabilityRow | AnnualChargeabilityParentRow>) =>  params.node.isRowPinned() ? true : false,
        valueGetter: (params: ValueGetterParams<AnnualChargeabilityRow | AnnualChargeabilityParentRow>) => params.node.isRowPinned() ? 'Total' : params.data instanceof AnnualChargeabilityParentRow ? new DateformatPipe().transform((params.data as AnnualChargeabilityParentRow).periodEnd, FormatDateType.FormatDate) : '',
        cellRenderer: ChargeabilityCellRendererComponent
      },
      {
        colId: 'chargeCode',
        headerName: 'Charge Code',
        hide: true,
        minWidth: 270,
        autoHeight: true,
        cellClass: ['d-grid'],
        cellRenderer: SummaryGridDefaultRendererComponent,
        valueGetter: (params: ValueGetterParams<AnnualChargeabilityRow | AnnualChargeabilityChildRow>) => {
          if(params.data instanceof AnnualChargeabilityChildRow)
            return params.column.getColDef().headerName === 'Charge Code' && params.node.isRowPinned() && !params.data.chargeCode ? '' : params.data.chargeCode;
        }
      },
      {
        headerName: 'Chargeable',
        field: 'chargeableHours',
        cellRenderer: SummaryGridDefaultRendererComponent
      },
      {
        headerName: 'Non Chargeable',
        field: 'nonChargeableHours',
        cellRenderer: SummaryGridDefaultRendererComponent
      },
      {
        headerName: 'Chargeability',
        field: 'chargeabilityPercent',
        cellRenderer: SummaryGridDefaultRendererComponent
      },
    ];
  }
}
