import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { GlobalCacheService } from '../../../shared/services/cache/global-cache.service';
import { SubordinatesMode } from '../../../shared/models/subordinates/subordinate-mode';
import { GlobalEventsService } from '../../../shared/services/events/global-events.service';
import { AppConfigService } from '../../../auth/services/app-config.service';
import { PeoplePicker } from '../../../shared/models/controls/people-picker/people-picker';
import { SubordinatesServiceMapper } from '../../../shared/services/subordinates/subordinates.service.mapper';
import { UserService } from '../../../shared/services/user/user.service';
import { SubordinatesService } from '../../../shared/services/subordinates/subordinates.service';

@Injectable()
export class SubordinateModeResolver  {
    private appConfigData: any;
    constructor(
        private cacheService: GlobalCacheService,
        private eventsService: GlobalEventsService,
        private appConfigService: AppConfigService,
        private userService: UserService,
        private subordinatesService:SubordinatesService) {
            this.appConfigData = this.appConfigService.getAppConfig;
        }
    
    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<SubordinatesMode> {  
        if (!this.appConfigData.AdditionalConfig.enableSubordinateModes) return of(SubordinatesMode.None);
        
        let subordinateMode,subordinate:any
        try {
            subordinateMode = SubordinatesMode[atob(decodeURIComponent(route.queryParamMap.get('viewMode')))];
        } catch (error) {
            subordinateMode = undefined;
        }
        try {
            subordinate = atob(decodeURIComponent(route.queryParams.subordinate));
        }
        catch (error) {
            subordinate = null;
        }
        /**
         * Verify if there's a subordinate selected in the URL, this help us to update
         * subordinate cache from redirect (from Legacy)
         */
        if (subordinate !== null && this.userService.getUser().enterpriseId !== subordinate && subordinate !== undefined) {
            subordinate = SubordinatesServiceMapper.mapPeoplePickerToSubrodinate(new PeoplePicker(null, subordinate));
            this.cacheService.setCurrentSubordinate(subordinate);
        }
        if(subordinateMode !== undefined && subordinateMode === SubordinatesMode.Supervisor)
        {
            const currentSubordinate = subordinate?.enterpriseId ?? this.cacheService.getCurrentSubordinate()?.enterpriseId;
            this.cacheService.setSubordinateMode(subordinateMode);
            if (currentSubordinate) {
                    this.subordinatesService.getSubordinateTabPermissionsObservable(currentSubordinate, subordinateMode)
                    .subscribe(userTabs =>{
                        let route = state.url.split('?')[0].split('/')[1];
                        this.subordinatesService.resolveLegacyRedirection(userTabs, subordinateMode, subordinate?.enterpriseId,route);
                });
            }
        }
        if (subordinateMode != undefined && subordinateMode != SubordinatesMode.None) {
            this.cacheService.setSubordinateMode(subordinateMode);
        } else if(subordinateMode != undefined && subordinateMode == SubordinatesMode.None) {
            this.setRecordMode();
            this.cacheService.setSubordinateMode(subordinateMode);
        } else {
            this.cacheService.setSubordinateMode(SubordinatesMode.None);
            subordinateMode = SubordinatesMode.None;

            if (this.cacheService.getCurrentSubordinate() != null) {
                this.setRecordMode();
            }            
        }

        return of(subordinateMode);
    }

    private setRecordMode(): void {
        this.cacheService.setCurrentSubordinate(null);
        this.eventsService.dispatchSubordinateSelectedEvent({ mode: SubordinatesMode.None, subordinate: null });
        this.cacheService.clearBufferCache();
    }
}