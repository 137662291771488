<div class="form-group has-search">
  <ng-template #rt let-r="result" let-t="term">
    <div class="result-container">
      <div *ngIf="!isSimplify" class="profile-picture">
        <div class="spinner-grow" role="status" *ngIf="!r.profilePicture">
          <span class="visually-hidden">Loading Profile Picture...</span>
        </div>
        <img
          *ngIf="r.profilePicture"
          [src]="r.profilePicture.data"
          class="img-thumbnail"
          alt="Profile Picture"
        />
      </div>
      <div class="profile-detail">
        <ngb-highlight [result]="r.enterpriseId" [term]="t"></ngb-highlight>
        <p *ngIf="!isSimplify" class="mb-0">{{ r.firstName }} {{ r.lastName }}</p>
        <footer *ngIf="!isSimplify" class="blockquote-footer">
          <!-- <span *ngIf="r.peopleKey"> ({{ r.peopleKey }}) </span> -->
          <cite title="Source Title">{{
            r.xWorkforceCareerLevelDescription
          }}</cite>
        </footer>
      </div>
    </div>
  </ng-template>
  <div class="input-container" [ngClass]="{multiple: isMultipleSelection}">
    <input
      #input
      #typeaheadInstance="ngbTypeahead"
      (keydown)="typeaheadKeydown($event)"
      size="{{ input.value && input.value.length > 10 ? input.value.length : 10 }}"
      id="people-picker"
      type="text"
      class="form-control ps-2"
      [class.is-invalid]="searchFailed"
      [(ngModel)]="model"
      [ngbTypeahead]="search"
      [placement]="placement"
      placeholder="People search"
      [attr.aria-label]="isAttendees?'Attendees People search':'People search'"
      [resultTemplate]="rt"
      [inputFormatter]="formatter"
      (selectItem)="selectPeople($event, input)"
      [disabled]="readOnly"
      [attr.aria-required]="required"      
    />
    <div class="check" *ngIf="isMultipleSelection" (click)="selectMultiple(input)">
      <mat-icon aria-hidden="false" aria-label="Check All" fontIcon="done_all" ngbTooltip="Check all"></mat-icon>
    </div>
  </div>
  <div class="invalid-feedback" *ngIf="searchFailed">
    Sorry, suggestions could not be loaded.
  </div>
</div>
