<div 
(drop)="dropHandler($event)"
(dragenter)="dragEnterHandler($event)"
(dragover)="dragOverHandler($event)"
(dragleave)="dragLeaveHandler($event)"
class="typecode-container" *ngIf="params.value"
[ngClass]="{'total-align': icon == ''}"
  ngbTooltip="{{ params.value?.validationErrors }}" placement="bottom-start" container="body">
  <mat-icon aria-hidden="true" [fontIcon]="icon"></mat-icon>
  <span>{{params.value.value}}</span>
</div>
