import { Component } from '@angular/core';
import ExpenseGridItem from '@shared/models/expense/expense-grid-item';
import { TimeReportStatusModel } from '@shared/models/time-report/time-report';
import { TimeReportStoreService } from '@shared/services/store/time-report-store.service';
import { Option } from '@sharedClients/expense-api-client';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { GlobalCacheService } from '../../../../shared/services/cache/global-cache.service';
import { TimeReportService } from '../../../../shared/services/time-report/time-report.service';
import { ExpenseGridEventsService } from '../../../shared/services/events/expense-grid-events.service';
import { ExpenseGridService } from '../../../shared/services/grids/expense-grid.service';
import { ExpenseGridDefaultRendererComponent } from '../expense-grid-default-renderer/expense-grid-default-renderer.component';
import { ActionStoreService } from '@shared/services/store/action-store.service';
import { ActionType } from '@shared/services/store/shared/action-type';
import { ReceiptsService } from '@shared/services/receipts/receipts.service';

@Component({
    selector: 'myte-expense-grid-assignment-renderer',
    templateUrl: './expense-grid-assignment-renderer.component.html',
    styleUrls: ['./expense-grid-assignment-renderer.component.sass']
})
export class ExpenseGridAssignmentRendererComponent extends ExpenseGridDefaultRendererComponent implements ICellRendererAngularComp {
    public wbsDescribtion = '';
    public hasNetWork = false;
    public isSubmitted = false;
    public isGroupByTrip: boolean;
    public editable: boolean;
    public periodEnd: Date;
    public toolTip: string;
    public timeReportStatus: string;
    public trStatusValid: string[] = [TimeReportStatusModel.Submitted, TimeReportStatusModel.Processed, TimeReportStatusModel.ProcessedAdjustment, TimeReportStatusModel.SubmittedAdjustment, TimeReportStatusModel.SubmitPendingAdjustment];
    public trStatusEditable: string[] = [TimeReportStatusModel.New, TimeReportStatusModel.Draft];
    public isValidTimeReport: boolean;
    public isNewHeight: boolean;
    public showRelatedChargeCode: boolean;


    constructor(
        public receiptsService: ReceiptsService,
        public timeReportStoreService: TimeReportStoreService,
        protected expenseGridService: ExpenseGridService,
        protected expenseGridEventService: ExpenseGridEventsService,
        public cacheService: GlobalCacheService,
        public actionService: ActionStoreService) {
        super(null, expenseGridService, expenseGridEventService, cacheService, actionService, timeReportStoreService);

    }

    public agInit(params: ICellRendererParams<ExpenseGridItem, Option>): void {
        this.params = params;
        this.timeReportStatus = this.params.context?.expenseGridParamsService?.timeReportStatus;
        this.isSubmitted = this.timeReportStatus == TimeReportStatusModel.Submitted;
        this.isValidTimeReport = this.trStatusValid.includes(this.timeReportStatus);
        this.periodEnd = this.params.context?.expenseGridParamsService?.periodEnd;
        if (this.params.value) {
            this.wbsDescribtion = this.params.value.value ? this.params.value.value : this.params.value.key;
            this.hasNetWork = params.value.hasDismissCheckboxChecked ? false : this.params.value.hasNetWorks;
            this.editable = this.params.context?.expenseGridParamsService?.isEditable;
            this.isGroupByTrip = this.params.context?.expenseGridParamsService?.isGroupByTrip;
            this.isNewHeight = (this.hasNetWork || params.value?.hasInvalidStaticProject || params.value?.hasCostCollectors) && (this.hasNetWork && this.trStatusEditable.includes(this.timeReportStatus));
            this.showRelatedChargeCode = this.isNewHeight && params.value.key != '' && !this.isSubmitted && !params.value.isLeaf;

            if (this.isGroupByTrip) {
                if (this.hasNetWork && this.params.value.treeNetworkHierarchy == 0) {
                    this.hasNetWork = false;
                }
            }
        }
        if (params.data.assignment && params.data.assignment['saved'] === true) {
            this.actionService.dispatchAction(ActionType.UPDATE_TRIP, { tripId: params.data.frequentTrip.id, tripName: params.data.assignment.value });
        }
        if (this.params.data) {
            if (((this.hasNetWork && this.params.value.treeNetworkHierarchy == 1) || !this.params.value.isValid && !this.isValidTimeReport || this.params.data.assignment.hasSuspenseExpenseProject) || (this.hasNetWork && this.trStatusEditable.includes(this.timeReportStatus))) {
                this.toolTip = this.params.data.assignment.tooltip;
            }
            else {
                this.toolTip = this.isValidTimeReport || (this.hasNetWork && !this.trStatusEditable.includes(this.timeReportStatus)) || this.timeReportStatus == 'DraftAdjustment' ? this.params.data.expenseProjectChargeHistoryToolTipText : this.params.data.assignment.tooltip;
            }
        }
    }

    public invokeParentMethod(): void {
        this.params.context.componentParent.methodFromParent(`Row: ${this.params.node.rowIndex}, Col: ${this.params.colDef.headerName}`);
    }
    public showRelated(): void {
        this.expenseGridEventService.dispatchShowRelatedChargeCodePopupEvent(this.params.value.key);
    }
}
