import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ApplicationRef, ErrorHandler, inject } from '@angular/core';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutes } from './app.routing';
import { environment } from '@environments/environment';
import { AuthModule } from './auth/auth.module';
import { MyteControlsModule } from './myte-controls/myte-controls.module';
import { MyteSubordinatesModule } from './myte-subordinates/myte-subordinates.module';
import { MytePopupsModule } from './myte-popups/myte-popups.module';
import { AppComponent } from './myte-root/components/root/app.component';
import { HeaderComponent } from './myte-root/components/page-header/header.component';
import { PageNotFoundComponent } from './myte-root/components/page-not-found/page-not-found.component';
import { fakeBackendProvider } from '@sharedInterceptors/fake-backend.interceptor';
import { TimePeriodPipe } from '@sharedPipes/time-period.pipe';
import { ToastrModule } from 'ngx-toastr';
import { MatIconModule } from '@angular/material/icon';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PageFooterComponent } from './myte-root/components/page-footer/page-footer.component';
import { TermsOfUseComponent } from './myte-root/components/terms-of-use/terms-of-use.component';
import { PageLogoutComponent } from './myte-root/components/page-logout/page-logout.component';
import { PageContentComponent } from './myte-root/components/page-content/page-content.component';
import { AppConfigService } from '@authServices/app-config.service';
import { APP_INITIALIZER } from '@angular/core';
import { MyteAutomationTestDataComponent } from './myte-automation-test-data/myte-automation-test-data.component';
import { MyteToast } from './myte-controls/components/toast/myte-toast';
import { CustomerHandler} from '@sharedServices/error-handler';
import { PageNotAuthorizedComponent } from './myte-root/components/page-not-authorized/page-not-authorized.component';
import { ClientModule } from '@shared/client.module';
import { MyteAuditeeSidebarComponent } from './myte-auditee-details/components/myte-auditee-sidebar/myte-auditee-sidebar.component';
import { MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalInterceptor, MsalInterceptorConfiguration, MsalModule, MsalRedirectComponent, MsalService } from '@azure/msal-angular';
import { LogLevel, IPublicClientApplication, Configuration, PublicClientApplication } from '@azure/msal-browser';
import { RebarInterceptor } from './auth/interceptors/rebar.interceptor.service';
import './overrideToISOStringMyTE'; 
import { PowerBIEmbedModule } from 'powerbi-client-angular';
import { GenerateReportComponent } from './myte-reports/components/generate-report/generate-report.component';

const appInitializerFn = (appConfig: AppConfigService) => {
  return () => {
    return appConfig.loadAppConfig();
  };
};

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  const isIE: boolean = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
  const appConfigMSAL: any = inject(AppConfigService).getAppConfig.AADv2Eso;
  const msalInstanceFactory: Configuration = appConfigMSAL.MSALInstanceFactory;
  msalInstanceFactory.cache.storeAuthStateInCookie = isIE;
  msalInstanceFactory.system.loggerOptions = { 
    loggerCallback,
    logLevel: LogLevel.Error,
    piiLoggingEnabled: false
  }
  return new PublicClientApplication(msalInstanceFactory);
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  const appConfigMSAL: any = inject(AppConfigService).getAppConfig.AADv2Eso;
  const msalGuardConfiguration: MsalGuardConfiguration = appConfigMSAL.MSALGuardConfigFactory;
  return msalGuardConfiguration;
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const appConfigMSAL: any = inject(AppConfigService).getAppConfig.AADv2Eso;
  const msalInterceptorConfiguration: MsalInterceptorConfiguration = appConfigMSAL.MSALInterceptorConfigFactory;
  const protectedResourceMap = new Map<string, Array<string>>();
  appConfigMSAL.protectedResourceMap.forEach((resource: { url: string, scopes: [] }) => {
    protectedResourceMap.set(resource.url, resource.scopes);
  });
  msalInterceptorConfiguration.protectedResourceMap = protectedResourceMap;
  return msalInterceptorConfiguration;
}

@NgModule({
    declarations: [
      AppComponent,
      HeaderComponent,
      PageNotFoundComponent,
      TimePeriodPipe,
      PageFooterComponent,
      TermsOfUseComponent,
      PageLogoutComponent,
      PageContentComponent,
      MyteAutomationTestDataComponent,
      MyteToast,
      PageNotAuthorizedComponent,
      MyteAuditeeSidebarComponent,
      GenerateReportComponent
    ],
    exports: [NgbModule],
    bootstrap: [AppComponent, MsalRedirectComponent],
    imports: [
      AppRoutes,
      BrowserModule,
      FormsModule,
      ReactiveFormsModule,
      MyteControlsModule,
      MatIconModule,
      BrowserAnimationsModule,
      NgbModule,
      ToastrModule.forRoot({
          toastComponent: MyteToast
      }),
      MytePopupsModule,
      MyteControlsModule,
      MyteSubordinatesModule,
      MsalModule,
      ClientModule,
      AuthModule,
      PowerBIEmbedModule
    ],
    providers: [
      MsalService,
      MsalGuard,
      MsalBroadcastService,
      AppConfigService,
      { provide: APP_INITIALIZER, useFactory: appInitializerFn, multi: true, deps: [AppConfigService] },
      { provide: ErrorHandler, useClass: CustomerHandler },
      { provide: HTTP_INTERCEPTORS, useClass: environment.production ? MsalInterceptor : RebarInterceptor, multi: true },
      { provide: MSAL_INSTANCE, useFactory: MSALInstanceFactory },
      { provide: MSAL_GUARD_CONFIG, useFactory: MSALGuardConfigFactory },
      { provide: MSAL_INTERCEPTOR_CONFIG, useFactory: MSALInterceptorConfigFactory },
      environment.name == 'Mock' ? fakeBackendProvider : [],
      provideHttpClient(withInterceptorsFromDi())
    ]
})
export class AppModule {
  constructor() {}

  /*  ngDoBootstrap(ref: ApplicationRef) {
    console.log("Bootstrap: App");
    ref.bootstrap(AppComponent);
  } */
}