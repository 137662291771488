import User from '@sharedModels/user/user';
import { UserService } from './user/user.service';
import { GlobalCacheService } from './cache/global-cache.service';
import { GlobalEventsService } from './events/global-events.service';
import { LogService } from './log/log.service';
import { SubordinatesMode } from '@sharedModels/subordinates/subordinate-mode';
import Subordinate from '@sharedModels/subordinates/subordinate';

/**
 * This abstract class implements the behavior of diferents view modes (or subrodinate modes).
 * @remarks
 * When a subordinate is selected in the aplication this code will change the `userEid` for the
 * subordinate enterprise id and the `supervisorEid` for the current login enterprise id.
 * It also set the current view mode in `viewMode` property.
 */
export abstract class MyteBaseService {
    private user: User;
    private supervisorUser: User;
    private mode: SubordinatesMode;

    constructor(protected userService: UserService,
                protected globalCacheService: GlobalCacheService,
                protected globalEventsService: GlobalEventsService,
                protected logService: LogService) {
        const currentSubordinate = this.globalCacheService.getCurrentSubordinate();
        const currentMode = this.globalCacheService.getSubordinateMode();

        this.user = currentSubordinate == null
            ? this.userService.getUser()
            : this.subordinateToUser(currentSubordinate);

        this.supervisorUser = currentSubordinate == null ? undefined : this.userService.getUser();
        this.mode = currentMode == null ? SubordinatesMode.None : currentMode;

        this.globalEventsService.selectSubordinateSelectedEvent().subscribe(p => {
            this.mode = p.mode;
            this.user = this.isRecordMode ? this.userService.getUser() : this.subordinateToUser(p.subordinate);
            this.supervisorUser = this.isRecordMode ? undefined : this.userService.getUser();
            this.globalCacheService.resetDataStorage();
            this.globalCacheService.resetChargeCodes();
            this.globalCacheService.resetCarInformation();
            this.globalCacheService.resetTaxReimbursementRate();
            this.globalCacheService.resetLocationOnes();
            this.globalCacheService.resetLocationTwo();
            this.globalCacheService.resetTimeReportSummary();
            this.globalCacheService.resetAnnualChargeabilitySummary();
        });
    }

    /** @returns `true` if the view mode is in record mode, the view normal mode of MyTE. */
    protected get isRecordMode() : boolean { return this.mode == SubordinatesMode.None }
    /** @returns `true` if the view mode is in a special view mode. i.e: delegate mode. */
    protected get isSubordinatedMode() : boolean { return this.mode != SubordinatesMode.None }
    /**
     * @returns The currently showing user EID in the aplication
     * @remarks If the view mode of MyTE is record mode, this propery will allways return the logged in user EID.
     * As soon as the user select a subordinate in a diferent mode, i.e: delegate mode, this property
     * is going to return the subordinate enterprise id, and the logged in user EID will be reflected
     * in the property `supervisorEid`.
     */
    protected get userEid() : string { 
        var currentSubordinate = this.globalCacheService.getCurrentSubordinate();
        currentSubordinate != null ? this.mode = this.globalCacheService.getSubordinateMode() : SubordinatesMode.None;
        return this.isSubordinatedMode && currentSubordinate && this.user.enterpriseId?.toLowerCase() != currentSubordinate.enterpriseId
        ?currentSubordinate.enterpriseId
        :this.user.enterpriseId?.toLowerCase()};

    protected get loginEid() : string { 
        return this.userService.getUser().enterpriseId; 
    }    
    /** @returns The currently showing user key in the aplication */
    protected get userKey() : number { 
        var currentSubordinate = this.globalCacheService.getCurrentSubordinate();
        currentSubordinate != null ? this.mode = this.globalCacheService.getSubordinateMode() : SubordinatesMode.None;
        return this.isSubordinatedMode && currentSubordinate && this.user.enterpriseId.toLowerCase() != currentSubordinate.enterpriseId
        ?currentSubordinate.peopleKey
        :this.user.peopleKey };
    protected get supervisorEid(): string { 
        var currentSubordinate = this.globalCacheService.getCurrentSubordinate();
        currentSubordinate != null ? this.mode = this.globalCacheService.getSubordinateMode() : SubordinatesMode.None;
        const currentMode = this.globalCacheService.getSubordinateMode();
        return this.supervisorUser 
        ?this.supervisorUser.enterpriseId.toLowerCase() 
        :(currentMode == 'ReviewEmail' || (this.isSubordinatedMode && currentSubordinate && this.user.enterpriseId.toLowerCase() != currentSubordinate.enterpriseId)
          ?this.user.enterpriseId.toLowerCase()
          :undefined)};
    protected get viewMode(): string { return this.mode == SubordinatesMode.None ? '' : this.mode }
    protected get getUser(): User {return this.user};

    private subordinateToUser(s: Subordinate): User {
        return {
            enterpriseId: s.enterpriseId,
            countryKey: s.countryKey,
            firstName: s.firstName,
            lastName: s.lastName,
            peopleKey: s.peopleKey
        } as User;
    }
}