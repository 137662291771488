import { Component, ViewChild } from '@angular/core';
import { ExpenseMultipleTaxiGrid } from '@shared/models/expense/multipleTaxi/expense-multiple-grid';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { IAfterGuiAttachedParams, ICellEditorParams } from 'ag-grid-community';
import { TextboxComponent } from '../../../../../myte-controls/components/textbox/textbox.component';

@Component({
  selector: 'myte-taxi-amount-editor',
  templateUrl: './taxi-amount-editor.component.html',
  styleUrls: ['./taxi-amount-editor.component.sass']
})
export class TaxiAmountEditorComponent implements ICellEditorAngularComp {
  @ViewChild(TextboxComponent, { static: true }) textboxComponent: TextboxComponent;
  public amount: string;
  public validations: RegExp[] = [];
  public precision: string = '2-2';

  constructor() { }

  public agInit(params: ICellEditorParams<ExpenseMultipleTaxiGrid, string>): void {
    this.amount = params.value;

    let decimalSeparator = (1.1).toLocaleString(navigator.language).substring(1, 2);
    let regExpForDecimals = new RegExp(`^[0-9]{0,9}(\\${decimalSeparator}[0-9]{0,2})?$`);
    this.validations.push(regExpForDecimals);
  }
  
  public getValue() {
    let updatedAmount: string = (this.textboxComponent.textbox.nativeElement as HTMLInputElement).value;
    if (this.validations != null) {
      this.validations.forEach(expression => {
        if (expression && !expression.test(updatedAmount || '')) {
          updatedAmount = null;
        }
      });
    }
    if (!this.precision || updatedAmount === '' || updatedAmount == null) return updatedAmount;
    this.amount = this.textboxComponent.getValue(updatedAmount);
    return this.amount;
  }

  public updateValue(value): void {
    this.amount = value;
  }

  public isPopup?(): boolean {
    return false;
  }

  public afterGuiAttached(params?: IAfterGuiAttachedParams): void {
    setTimeout(() => {
      this.textboxComponent.textbox.nativeElement.focus();
      setTimeout(() => {
        (this.textboxComponent.textbox.nativeElement as HTMLInputElement).select();
      }, 100);
    }, 100);
  }
}
