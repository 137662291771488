import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'myte-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.sass']
})
export class TermsOfUseComponent implements OnInit {

  constructor(
    private location: Location
  ) { }

  ngOnInit() {
  }

  public closePopUp(): void {
    this.location.back();
  }

}
