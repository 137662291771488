export class OverTimeRequested {
    public overTimeLimitSummary: OverTimeLimitSummary;
    public overtimeWorkSummary: OvertimeWorkSummary;
}

export class OverTimeLimitSummary {
    public limit: OvertimeLimit;
    public hoursWorked: OvertimeLimit;
    public balance: OvertimeLimit;
    public hoursRequested: OvertimeLimit;
}

export class OvertimeLimit {
    public weeklyHours: string;
    public quarterlyHours: string;
    public yearlyHours: string;
}

export class OvertimeWorkSummary {
    public enterpriseId: string;
    public peopleKey: number;
    public fromDate: string;
    public toDate: string;
    public otRequested: string;
    public state: string;
    public requestedDaysDetail: RequestedDaysDetail[];
}

export class RequestedDaysDetail {
    public otDate: string;
    public workDayType: string;
    public dailyLimit: string;
    public workSchedule: string;
    public otHours: string;
    public otRequested: string;
    public statusCd: number;

    constructor(otDate: string, workDayType: string, workSchedule: string, dailyLimit: string, otHours: string, otRequested: string, statusCd: number) {
        this.otDate = otDate;
        this.workDayType = workDayType;
        this.dailyLimit = dailyLimit;
        this.workSchedule = workSchedule;
        this.otHours = otHours;
        this.otRequested = otRequested;
        this.statusCd = statusCd ? parseInt(statusCd as any) + 1 : 0;
    }
}