export class MyteTabControl {
    public timeTab: boolean;
    public expenseTab: boolean;
    public locationsTab: boolean;
    public adjustmentsTab: boolean;
    public summaryTab: boolean;
    public assignmentsTab: boolean;
    public auditTab: boolean;

    constructor(timeTab?: boolean, expenseTab?: boolean, locationsTab?: boolean, adjustmentsTab?: boolean, summaryTab?: boolean,
        assignmentsTab?: boolean, auditTab?: boolean) {

        this.timeTab = timeTab;
        this.expenseTab = expenseTab;
        this.locationsTab = locationsTab;
        this.adjustmentsTab = adjustmentsTab;
        this.summaryTab = summaryTab;
        this.assignmentsTab = assignmentsTab;
        this.auditTab = auditTab;
    }
}