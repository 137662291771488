import { OvertimeRequestEntry } from './overtime-request-entry';
import { AuditFiltersDataInput } from '@shared/clients/expense-api-client';

export class OvertimeRequest {
    title?:string
    isReadOnly?: boolean;
    displayChargeCodeColumn?: boolean;
    displayStartEndTimeFields?: boolean;
    minutesIncrement?: number;
    displayReasonForOvertimeField?: boolean;
    displayDescriptionField?: boolean;
    allowDailySplits?: boolean;
    displayPopMessageAU?: boolean;
    displayOTMessage?: string;
    entries?: OvertimeRequestEntry[] = [];

    public initializeGridConfiguration(isFromDeleteAction: boolean) {
        [...new Set(this.entries.map(entry => entry.date.getDate()))].forEach(date => {
            let entries = this.entries.filter(entry => entry.date.getDate() == date);
            const firstItem = Math.min.apply(Math, entries.map(entry => entry.daySequenceNbr));
            const currentLength = entries.length;
            entries.forEach(entry => {
                entry.gridConfiguration = {
                    isFirst: entry.daySequenceNbr == firstItem,
                    actions: [],
                    rowSpan: 1
                };

                if (entry.gridConfiguration.isFirst && currentLength == 1 && this.allowDailySplits && entry.hours > 0) {
                    entry.overtimeSplitHoursInfo.overtimeSplitHours = entry.hours;
                }

                if (!this.displayStartEndTimeFields && isFromDeleteAction && entry.gridConfiguration.isFirst && entries.filter(entry => entry.overtimeSplitHoursInfo.overtimeSplitHours > 0).length == 1 && entry.overtimeSplitHoursInfo.overtimeSplitHours != entry.hours) {
                    entry.overtimeSplitHoursInfo.overtimeSplitHours = entry.hours;
                }

                if (entry.overtimeSplitHoursInfo && entry.overtimeSplitHoursInfo.overtimeSplitHours === 0){
                    entry.isVisible = false;
                } else {
                    entry.isVisible = true;
                }
                
                if (entry.gridConfiguration.isFirst) {
                    entry.gridConfiguration.rowSpan = entries.filter(entry => entry.overtimeSplitHoursInfo?.overtimeSplitHours != 0).length;
                }

                if (entry.gridConfiguration.isFirst) {
                    entry.gridConfiguration.actions.push('add');
                    if (entry.overtimeSplitHoursInfo.overtimeSplitHours === null) {
                        entry.gridConfiguration.isfirstRowOTNull = true;
                    }
                }

                if (entry.daySequenceNbr != firstItem) {
                    entry.gridConfiguration.actions.push('remove');
                }

            });
        });
    }

    public getOvertimeHoursToDisplay(overtimeRequestEntry: OvertimeRequestEntry): number {
        let entries = this.entries.filter(entry => entry.date.getDate() == overtimeRequestEntry.date.getDate());
        if (entries.length === 1) {
            return overtimeRequestEntry.hours;
        } else {
            if (overtimeRequestEntry.gridConfiguration.isFirst && overtimeRequestEntry.overtimeSplitHoursInfo.overtimeSplitHours == null) {
                overtimeRequestEntry.overtimeSplitHoursInfo.overtimeSplitHours = overtimeRequestEntry.hours;
            }
            return overtimeRequestEntry.overtimeSplitHoursInfo.overtimeSplitHours;
        }
    }

    public getLastCharge(overtimeRequestEntry: OvertimeRequestEntry): OvertimeRequestEntry {
        let entries = this.entries.filter(entry => entry.date.getDate() ===  overtimeRequestEntry.date.getDate());
        return entries[entries.length - 1];
    }

    public multipleRows(overtimeRequestEntry: OvertimeRequestEntry) : boolean {
        let entries = this.entries.filter(entry => entry.date.getDate() ===  overtimeRequestEntry.date.getDate());
        return entries.length > 1
    }

    public isCompleteEntry(overtimeRequestEntry: OvertimeRequestEntry) {
        // Check Charge Code if applies.
        let isChargeCodeComplete = true;
        if (this.displayChargeCodeColumn) {
            isChargeCodeComplete = overtimeRequestEntry.chargeCodeInfo.selectedChargeCode && overtimeRequestEntry.chargeCodeInfo.selectedChargeCode.trim() !== '';
        }
        let isApproverComplete = overtimeRequestEntry.approverInfo &&  overtimeRequestEntry.approverInfo.selectedApprover != null && overtimeRequestEntry.approverInfo.selectedApprover.trim() !== ''; 
        // Check Reason for Overtime if applies.
        let isReasonForOvertimeComplete = true;
        if (this.displayReasonForOvertimeField) {
            isReasonForOvertimeComplete = overtimeRequestEntry.reasonForOvertimeInfo.selectedReasonForOvertime && overtimeRequestEntry.reasonForOvertimeInfo.selectedReasonForOvertime.id > 0;
        }
        let isOvertimeSplitHoursComplete = true;
        if (!this.displayStartEndTimeFields) {
            isOvertimeSplitHoursComplete = !(overtimeRequestEntry.isVisible) || !this.multipleRows(overtimeRequestEntry) || overtimeRequestEntry.overtimeSplitHoursInfo && overtimeRequestEntry.overtimeSplitHoursInfo.overtimeSplitHours != null && overtimeRequestEntry.overtimeSplitHoursInfo.overtimeSplitHours > 0; 
        }
        let areStartTimeAndEndTimeComplete = true;
        if (this.displayStartEndTimeFields) {
            areStartTimeAndEndTimeComplete = overtimeRequestEntry.startTimeInfo.hours !== null && overtimeRequestEntry.startTimeInfo.hours !== undefined && overtimeRequestEntry.startTimeInfo.minutes !== null && overtimeRequestEntry.startTimeInfo.minutes !== undefined && 
                                             overtimeRequestEntry.endTimeInfo.hours !== null && overtimeRequestEntry.endTimeInfo.hours !== undefined && overtimeRequestEntry.endTimeInfo.minutes !== null && overtimeRequestEntry.endTimeInfo.minutes !== undefined;
        }
        let isDescriptionComplete = true;
        if (this.displayDescriptionField) {
            isDescriptionComplete = overtimeRequestEntry.descriptionInfo?.description !== undefined && overtimeRequestEntry.descriptionInfo?.description !== null && overtimeRequestEntry.descriptionInfo?.description.trim() !== '';
        }
        return isChargeCodeComplete && isApproverComplete && isReasonForOvertimeComplete && isOvertimeSplitHoursComplete && areStartTimeAndEndTimeComplete && isDescriptionComplete
    }

    public shouldEnableActionButton(overtimeRequestEntry: OvertimeRequestEntry): boolean {
        if (overtimeRequestEntry.gridConfiguration.isFirst) {
            let entries = this.entries.filter(entry => entry.date.getDate() ===  overtimeRequestEntry.date.getDate());
            let invalidEntries = entries.filter(entry => {
                if (!this.isCompleteEntry(entry)) {
                    return true;
                }
            });
            
            if (invalidEntries && invalidEntries.length > 0) {
                return false;
            } else {
                return true;
            }
        } else {
            return this.isCompleteEntry(overtimeRequestEntry);
        }
    }

    public invalidSplitOvertimeHours() {
        let invalidEntries = false;
        this.entries.map(entry => entry.date.getDate()).forEach(date => {
            let entries = this.entries.filter(entry => entry.date.getDate() == date);
            let sum = 0;
            if (entries.length > 1) {
                entries.forEach(entry => {
                    if (!entry.overtimeSplitHoursInfo?.overtimeSplitHours) {
                        entry.overtimeSplitHoursInfo.hasError = true;
                        sum += 0;
                    } else {
                        sum += entry.overtimeSplitHoursInfo.overtimeSplitHours;
                    }
                });
                if (sum.toFixed(4) !== entries[0].hours.toFixed(4)) {
                    entries.forEach(entry => {
                        entry.overtimeSplitHoursInfo.hasError = true;
                    });
                    invalidEntries = true;
                }
            }
        });
        return invalidEntries;
    }

    public updateOvertimeSplitHoursIfNeeded() {
        this.entries.map(entry => entry.date.getDate()).forEach(date => {
            let entries = this.entries.filter(entry => entry.date.getDate() == date);
            if (this.displayStartEndTimeFields) {
                entries.filter(entry => entry.overtimeSplitHoursInfo?.overtimeSplitHours != 0).forEach(entry => {
                    const startTime = entry.startTimeInfo?.hours  + (entry.startTimeInfo?.minutes / 60);
                    let endTime = entry.endTimeInfo?.hours + (entry.endTimeInfo?.minutes / 60);
                    if (startTime != 0 && endTime == 0) {
                        endTime = 24.00;
                    }

                    if(endTime - startTime != 0){
                        entry.overtimeSplitHoursInfo.overtimeSplitHours = +(endTime - startTime).toFixed(2);
                    }
                });
            } else if (entries.length === 1) {
                let entry = entries[0];
                if (entry.overtimeSplitHoursInfo && entry.overtimeSplitHoursInfo.overtimeSplitHours !== null && entry.overtimeSplitHoursInfo.overtimeSplitHours > 0 && entry.hours !== entry.overtimeSplitHoursInfo.overtimeSplitHours) {
                    entry.overtimeSplitHoursInfo.overtimeSplitHours = entry.hours;
                }
            }
        });
    }
}

