import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { MyteEventHandler } from '../../../shared/services/events/myte-event-handler';
import {
  LocationGridRowSelectedEvent,
  LocationGridHeaderClickedEvent,
  LocationGridNewDataSourceEvent,
  LocationGridSetDefaultLocationEvent,
  MultipleLocationsChangeEvent
} from '../../../shared/services/events/locations-grid/locations-grid-events';
import { LocationEntry, LocationsByDay, LocationInformation, MultipleLocation } from '../../../shared/models/locations';
import { AppConfigService } from '../../../auth/services/app-config.service';

@Injectable()
export class LocationGridEventsService extends MyteEventHandler {

  constructor(appConfigService: AppConfigService) {
    super(appConfigService, [
      new LocationGridRowSelectedEvent(),
      new LocationGridHeaderClickedEvent(),
      new LocationGridNewDataSourceEvent(),
      new LocationGridSetDefaultLocationEvent(),
      new MultipleLocationsChangeEvent()
    ]);
  }

  public dispatchRowSelected(row: LocationEntry): void {
    this.dispatchEvent(LocationGridRowSelectedEvent.eventId, row);
  }

  public dispatchHeaderClicked(headerDate: Date): void {
    this.dispatchEvent(LocationGridHeaderClickedEvent.eventId, headerDate);
  }

  public dispatchNewDataSource(dataSource: LocationsByDay): void {
    this.dispatchEvent(LocationGridNewDataSourceEvent.eventId, dataSource);
  }

  public dispatchSetDefaultLocation(location: LocationInformation) {
    this.dispatchEvent(LocationGridSetDefaultLocationEvent.eventId, location);
  }

  public get selectRowSelected(): Subject<LocationEntry> {
    return this.selectEvent(LocationGridRowSelectedEvent.eventId);
  }

  public get selectGridHeaderClick(): Subject<Date> {
    return this.selectEvent(LocationGridHeaderClickedEvent.eventId);
  }

  public get selectDataSource(): Subject<LocationsByDay> {
    return this.selectEvent(LocationGridNewDataSourceEvent.eventId);
  }

  public get selectSetDefaultLocation(): Subject<LocationInformation> {
    return this.selectEvent(LocationGridSetDefaultLocationEvent.eventId);
  }

  public dispatchMultipleLocationsEvent(multipleLocation: MultipleLocation): void {
    this.dispatchEvent(MultipleLocationsChangeEvent.eventId, multipleLocation)
  }

  public selectMultipleLocationsEvent(): Subject<MultipleLocation> {
    return this.selectEvent(MultipleLocationsChangeEvent.eventId);
  }
}
