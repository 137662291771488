<div
    *ngIf="reasonCodes.length > 0"
    class="reason-code-container" 
    (drop)="dropHandler($event)"
    (dragenter)="dragEnterHandler($event)"
    (dragover)="dragOverHandler($event)"
    (dragleave)="dragLeaveHandler($event)">
        <span
            *ngFor="let reasonCode of reasonCodes; let i = index"
            class="reason-code-content"
            ngbTooltip="Reason Code: {{reasonCode.reasonCode}}   Description: {{ reasonCode.reasonCodeDescription }}   Additional Information: {{ reasonCode.auditAdditionalInformation }}"
            placement="auto"
            container="body">
                {{reasonCode.reasonCode}} {{i === reasonCodes.length -1 ? '' : ', ' }}
        </span>
</div>
<div>
    <span *ngIf="reasonCodes.length == 0"> - </span>
</div>