<myte-popup-template id="myte-remove-sb-popup" [title]="'STATEMENT OF ETHICAL STANDARDS'" (closeEvent)="closeModal()" >
    <div class="popup-main">
        <p>By submitting this Time and Expense Report, I confirm that I am aware of and in compliance with:</p>
        <ol>
            <li>Accenture Code of Business Ethics.</li>
            <li>All relevant policies found in Accenture Policies Database, including (but not limited to) the policies on insider trading, time and expense reporting, overtime and hours worked, location reporting, confidentiality, data privacy and intellectual property rights.</li>
            <li>Usage of American Express Corporate card for all business related expenses, for current cardholders, wherever accepted.</li>
        </ol>
        <p>I am aware that my information in myTimeandExpenses will be used to manage the overall company travel and expense patterns and expenditure as well as compliance with time reporting, location reporting, tax reporting, travel and expense policies and may be shared with my geographic and functional leadership.</p>
    </div>
    <div class="popup-footer">
        <div class="label-controls">
            <label>Do you agree?</label>
        </div>
        <div class="btn-controls">
            <button class="myte-button myte-button-cancel" (click)="closeModal()">Cancel</button>
            <button class="myte-button myte-button-submit" (click)="sureToSubmit()">Yes</button>
        </div>
    </div>
</myte-popup-template>