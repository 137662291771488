import { Injectable } from '@angular/core';
import { Observable, forkJoin, of } from 'rxjs';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { PeriodEndService } from '../../../shared/services/periodend/periodend.service';
import { TimeReportService } from '../../../shared/services/time-report/time-report.service';
import { GlobalCacheService } from '../../../shared/services/cache/global-cache.service';
import { map, switchMap } from 'rxjs/operators';
import { AppConfigService } from '../../../auth/services/app-config.service';
import { MyteTabControl } from '@shared/models/myte-tab-control.model';
import { ExpenseService } from '../../../myte-expenses/shared/services/expense/expense.service'
import { UserService } from '../../../shared/services/user/user.service';
import { ExpenseAuditService } from '../../../myte-expenses/shared/services/audit/expense-audit.service';
import { SubordinatesMode } from '@shared/models/subordinates/subordinate-mode';
import { DateFormatUtils } from '@shared/utils/dateFormater.utils';
import { ActionStoreService } from '@shared/services/store/action-store.service';
import { ActionType } from '@shared/services/store/shared/action-type';
import TimeReportSettings from '@shared/models/time-report/time-report-settings';
import moment from 'moment';
import { pilot_flag } from "./../../../shared/constant";

@Injectable({
  providedIn: 'root'
})
export class TimeReportResolver  {

  private storage: any;
  private OldMyteAppTabUrl: any;
  private appConfigData: any;

  constructor(
    private periodEndService: PeriodEndService,
    private timeReportService: TimeReportService,
    private cacheService: GlobalCacheService,
    private userService: UserService,
    private expenseService: ExpenseService,
    private appConfigService: AppConfigService,
    private auditService: ExpenseAuditService,
    public actionStoreService: ActionStoreService) {
    this.appConfigData = this.appConfigService.getAppConfig;
    this.getOldMyteAppUrl();  
  }

  resolve(activateRouter: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (window !== window.parent && !window.opener) {
      return of(null);
    }
    return !sessionStorage.getItem(pilot_flag) ? this.expenseService.checkIfPilot(this.userService.getUser().enterpriseId).pipe(switchMap(pilotResult => {
      sessionStorage.setItem(pilot_flag, JSON.stringify(pilotResult));
      return this.initResolverData(activateRouter, state);
    })) : this.initResolverData(activateRouter, state);
  }


  private initResolverData(activateRouter: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentRouter = state.url.split('?')[0].split('/')[1];
    const activeTabs = JSON.parse(sessionStorage.getItem(pilot_flag)) as MyteTabControl;
    const tabPemission = this.distinguishUrl(currentRouter, activeTabs);
    if (!tabPemission) {
      this.redirectToUrl(currentRouter);
      return of(null);
    }

    let periodEnd: Date;
    try {
      let date = activateRouter.queryParamMap.get('periodEnd');
      if (date) {
        periodEnd = isNaN(Date.parse(date)) 
          ? moment(atob(decodeURIComponent(activateRouter.queryParamMap.get('periodEnd'))), 'MM-DD-YYYY').toDate()
          : moment(date, 'MM-DD-YYYY').toDate();
        }
        else {
          periodEnd = null;
        }
      let reviewee = activateRouter.queryParamMap.get('reviewee');
      let viewMode = activateRouter.queryParamMap.get('viewMode');
     
      if(viewMode != null && reviewee != null && atob(decodeURIComponent(viewMode)) =='ReviewTimesheet'){
          periodEnd =  moment(atob(decodeURIComponent(date)), 'MM-DD-YYYY').toDate();
          this.cacheService.clearBufferCache();
          this.cacheService.setSubordinateMode(SubordinatesMode.ReviewEmail);
          this.cacheService.setRevieweeEid(atob(decodeURIComponent(reviewee)));
          this.cacheService.setEmailDate(DateFormatUtils.convertDateToStringByLanguage(periodEnd));
        
      }

      if((viewMode == null || (atob(decodeURIComponent(viewMode)) != 'ReviewTimesheet' && atob(decodeURIComponent(viewMode)) != 'ReviewEmail')) && this.cacheService.getSubordinateMode() == 'ReviewEmail'){
        this.cacheService.clearBufferCache();
        this.cacheService.clearBufferCacheForEmail();
        if(viewMode != null){
          switch (atob(decodeURIComponent(viewMode))){
            case "Approver":
              this.cacheService.setSubordinateMode(SubordinatesMode.Approver);
              break;
            case "Supervisor":
              this.cacheService.setSubordinateMode(SubordinatesMode.Supervisor);
              break;
            case "Delegate":
              this.cacheService.setSubordinateMode(SubordinatesMode.Delegate);
                break;

            default:
              this.cacheService.setSubordinateMode(SubordinatesMode.None);
              break;
          }  
        }
      }
      
    } catch (error) {
      periodEnd = null;
    }

    if (periodEnd) {
      this.cacheService.setPeriodEnd(periodEnd);
    } else {
      periodEnd = this.cacheService.getPeriodEnd();
      if (!periodEnd) {
        periodEnd = this.periodEndService.getActualPeriod();
        this.cacheService.setPeriodEnd(periodEnd);
      }
    }
      return forkJoin([
        this.actionStoreService.dispatchAction(ActionType.LOAD_TIME_REPORT, { periodEnd: periodEnd, triggerSpinner: false }) as Observable<TimeReportSettings>,
        this.auditService.getIssuesWithAdjustments()
      ]).pipe(map(data => {
        return {
          timeReportSettings: data[0][0],
          userIssues: data[1]
        }
      }));
  }

  private distinguishUrl(url: string, myteTab: MyteTabControl): boolean {
    switch (url) {
      case 'time':
        return myteTab.timeTab;
      case 'expenses':
        return myteTab.expenseTab;
      case 'locations':
        return myteTab.locationsTab;
      case 'adjustments':
        return myteTab.adjustmentsTab;
      case 'summary':
        return myteTab.summaryTab;
      case 'chargecodes':
        return myteTab.assignmentsTab;
      default: return myteTab.expenseTab || myteTab.timeTab ||
        myteTab.locationsTab || myteTab.adjustmentsTab || myteTab.summaryTab
        || myteTab.assignmentsTab;
    }
  }

  private redirectToUrl(url: string) {
    let redirecturl = this.OldMyteAppTabUrl[url];
    if (redirecturl) {
      let currentPeriodEnd = '';
      if (this.cacheService.getPeriodEnd()) {
        currentPeriodEnd = this.cacheService.getPeriodEnd().toISOStringMyTE();
      }
      redirecturl = currentPeriodEnd == '' ? redirecturl : redirecturl + `?periodEnd=${currentPeriodEnd}`;
    }
    window.location.href = redirecturl == undefined ? `${this.appConfigData.OLD_MYTE_APP}/OGTE/Home.aspx` : redirecturl;
  }

  public getOldMyteAppUrl(): void {
    this.OldMyteAppTabUrl = {
      time: this.appConfigData.OLD_MYTE_APP + `/OGTE/secure/TimesheetPage.aspx`,
      expenses: this.appConfigData.OLD_MYTE_APP + `/OGTE/secure/ExpenseSheetPage.aspx`,
      locations: this.appConfigData.OLD_MYTE_APP + `/OGTE/secure/LocationByDayTab.aspx`,
      adjustments: this.appConfigData.OLD_MYTE_APP + `/OGTE/secure/AdjustmentsPage.aspx`,
      summary: this.appConfigData.OLD_MYTE_APP + `/OGTE/secure/SummaryPage.aspx`,
      chargecodes: this.appConfigData.OLD_MYTE_APP + `/OGTE/secure/AssignmentsPage.aspx`
    }
  }

}