import DropdownItem from '../../controls/dropdown/dropdown-item';

export class ShiftScheduleOption {
  key: number;
  description: string;
  startTime: string;
  endTime: string;
  breakLength: string;
  total: string;

  public toDropdownItem(): DropdownItem {
    return new DropdownItem(this.key, this.description, true);
  }
}
