import { ReceiptRequirement } from './receipt/expense-receipt-requirement';
import { Option, ExpenseFieldWithValidationResult, AnalyticsAuditReason } from '@sharedClients/expense-api-client';
import { ExpenseAmount } from './expense-amount';
import ExpenseTripDetail from './trip/expense-trip-detail';
import { ExpenseDate } from './expense-date';
export default class ExpenseGridItem {
    public id: string;
    public sequence: ExpenseFieldWithValidationResult;
    public source: ExpenseFieldWithValidationResult;
    public status: ExpenseFieldWithValidationResult;
    public from: ExpenseFieldWithValidationResult;
    public to?: ExpenseFieldWithValidationResult;
    public assignment: Option;
    public code: string;
    public type: ExpenseFieldWithValidationResult;
    public amount: any;
    public additionalInformation: ExpenseFieldWithValidationResult;
    public receipt: ReceiptRequirement;
    public isAdjusted: boolean;
    public isReversed: boolean;
    public isEditable: boolean;
    public expenseAmount: ExpenseAmount;
    public frequentTrip: ExpenseTripDetail;
    public currency: string;
    public hasFederalWBSInd: boolean;
    public hasExpenseTotalErrorInd: boolean;
    public expenseTotalErrors: string;
    public hasissues: boolean;
    public decimalPlaces: number;
    public expenseProjectChargeHistoryToolTipText: string;
    public expenseChargeHistoryToolTipText: string;
    public expenseDate: ExpenseDate;
    public taxReimbursableBool:boolean
    public taxTrueUpValue: any;
    public exchangeRate? :number
    public gSTAmount? :number
    public amountWithoutTax ?:number
    public selfContainedDays ?:number
    public hotelDays?:number
    public foreignAmount ?:number
    public analyticsAuditReasons ?: AnalyticsAuditReason[];
    public selfCertification ?: any;
    public escalated ?: any;
    public analyticsAudit ?: any;
    public postPayEscalatedInd?: boolean | null;
}
