export const approvalStatusTooltip = {
    'ReceiptRequired': {
        status: 'Receipt Required',
        toolTip: 'This expense requires a receipt attachment before it can be sent for approval or submitted. Click here to add receipt.'
    },
    'RejectedByAuditor': {
        status: 'Follow-Up Required',
        toolTip: 'This expense has open issue(s). Click the Follow-Up Required status hyperlink for additional information.'
    },
    'RejectedByProject': {
        status: 'Follow-Up Required',
        tooltTip: 'Please click on the status hyperlink to view the action required to follow up on the expense.'
    },
    'RejectedByPayables': {
        status: 'Follow-Up Required',
        tooltTip: 'Please click on the status hyperlink to view the action required to follow up on the expense.'
    },
    'PendingAuditorApproval': {
        status: 'Pending Review',
        toolTip: 'This expense is being reviewed by the audit team.'
    },
    'PendingPayablesApproval': {
        status: 'Pending Review',
        toolTip: 'The expense has been sent for review.'
    },
    'PendingProjectApproval': {
        status: 'Pending Review',
        toolTip: 'The expense has been sent for review.'
    },
    'PendingReview': {
        status: 'Pending Review',
        toolTip: null
    },
    'AuditClosed': {
        status : 'Audit Closed',
        toolTip : 'Open issue(s) for this expense have been closed or the expense has been reversed.'
    },
    'Incomplete': {
        status : 'Incomplete',
        toolTip : 'This expense requires additional information to be entered before it can be sent for approval or submitted.'
    },
    'Complete': {
        status : 'Complete',
        toolTip : 'This expense does not require approval and is ready for submission.' 
    },
    'Approved': {
        status : 'Approved',
        toolTip : 'This expense has been approved. The expense will be submitted with the time report..'
    },
    'Escalated': {
        status : 'Escalated',
        toolTip : 'Open issue(s) for this expense have been closed or the expense has been reversed.'
    }
}


export const elementColorByStatus = {
    'ReceiptRequired': {
        status: 'ReceiptRequired',
        colorByStatus: 'red bold underline-hover cursor-pointer'
    },
    'Incomplete': {
        status: 'Incomplete',
        colorByStatus: 'red bold underline-hover cursor-pointer'
    },
    'RejectedByProject': {
        status: 'RejectedByProject',
        colorByStatus: 'red bold underline-hover cursor-pointer'
    },
    'RejectedByPayables': {
        status: 'RejectedByPayables',
        colorByStatus: 'red bold underline-hover cursor-pointer'
    },
    'RejectedByAuditor': {
        status: 'RejectedByAuditor',
        colorByStatus: 'red bold underline-hover cursor-pointer'
    }, 
    'PendingAuditorApproval': {
        status: 'PendingAuditorApproval',
        colorByStatus: 'blue bold underline-hover cursor-pointer'
      },
      'PendingPayablesApproval': {
        status: 'PendingPayablesApproval',
        colorByStatus: 'blue bold underline-hover cursor-pointer'
      },
      'PendingReview': {
        status: 'Pending Review',
        colorByStatus: 'blue bold underline-hover cursor-pointer'
      },
      'Escalated':{
        status: 'Escalated',
        colorByStatus: 'red bold underline-hover cursor-pointer'
      },
      'Default':{
        status: 'Default',
        colorByStatus: 'green bold underline-hover cursor-pointer'
      }
}