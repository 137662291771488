import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'myte-page-not-authorized',
  templateUrl: './page-not-authorized.component.html',
  styleUrls: ['./page-not-authorized.component.sass']
})
export class PageNotAuthorizedComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
