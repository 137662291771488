import { Validation } from "@shared/clients/time-api-client";

export default class Gift {
    enterpriseId: string;
    amount: number;
    IsAmountAdjustment: boolean;
    IsRecipientAdjustment: boolean;
    isError: boolean;
    validations: Validation[] = [];
}
