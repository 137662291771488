import { Component, OnInit, Output, EventEmitter,HostListener, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { UserService } from '../../../shared/services/user/user.service';
import { GlobalEventsService } from '../../../shared/services/events/global-events.service';
import { GlobalCacheService } from '../../../shared/services/cache/global-cache.service';
import { Router, NavigationExtras } from '@angular/router';
import { SubordinatesMode } from '../../../shared/models/subordinates/subordinate-mode';
import ActionMenu from '../../../shared/models/action-menu';
import { UserRole } from '../../../shared/models/user/user-roles';
import { AppConfigService } from '../../../auth/services/app-config.service';
import { TimeReportUtils } from '../../../shared/utils/timeReport.utils';
import { of, Subject, takeUntil, Subscription} from 'rxjs';
import { QuerySelector } from 'ag-grid-community';
@Component({
  selector: 'myte-represent-dropdown',
  templateUrl: './represent-dropdown.component.html',
  styleUrls: ['./represent-dropdown.component.sass']
})
export class RepresentDropdownComponent implements OnInit, OnDestroy {
  @Output() onLoadingEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  public userRole: any;
  private appConfigData: any;
  public isDropdownOptionsOpen = false;
  private componentDestroyed: Subject<void> = new Subject();
  private userActionMenuSubscription: Subscription;
  private urlApprovalPages = ['/requestovertime','/approvalallowanceandovertime', '/approveovertimeandallowance', '/approvalovertimeandallowance'];
  constructor(private userService: UserService,
              private router: Router,
              private globalEventsService: GlobalEventsService,
              private globalCacheService: GlobalCacheService,
              private appConfigService: AppConfigService) {
                this.appConfigData = this.appConfigService.getAppConfig;
              }
  public ngOnInit(): void {
    const intervalId = setInterval(() => {
        this.overwriteUH();
        clearInterval(intervalId);
    }, 50); 
    setTimeout(() => {
      clearInterval(intervalId);
    }, 60000);
  }
  public ngOnDestroy(): void {
    this.componentDestroyed.next();
    this.componentDestroyed.complete();
    if (this.userActionMenuSubscription) {
      this.userActionMenuSubscription.unsubscribe();
    } 
  }
  public overwriteUH() {
    let personElements = document.getElementsByClassName('acn-header-profile');

    if (this.isElementExist(personElements)) {
      this.setProfileEvent(personElements);
    }
    let actionMenuCache = null;
    this.userActionMenuSubscription = this.globalCacheService
      .getActionMenu()
      .subscribe((res) => {
        actionMenuCache = res;
        if (!actionMenuCache) {
          this.userService.getUserActionMenu()
          .pipe(takeUntil(this.componentDestroyed))
          .subscribe(res => {
            if (typeof res == 'undefined') return;
      
            if (res?.admin || res?.review || res?.approve || res?.represent) {
              this.generateSubrodinateMenu(res);
            }
            this.globalCacheService.setActionMenu(of(res));
            this.globalCacheService.setUserRole(UserRole.getValueByString(res.userRole));
          });
          }
          else {
          if (actionMenuCache?.admin || actionMenuCache?.review || actionMenuCache?.approve || actionMenuCache?.represent) {
            this.generateSubrodinateMenu(actionMenuCache);
          }
            this.globalCacheService.setUserRole(UserRole.getValueByString(actionMenuCache.userRole));
          }
      });

  }

  public showSubordinatesList(viewMode: SubordinatesMode): void {
    const currentViewMode = this.globalCacheService.getSubordinateMode();
    if (((viewMode != SubordinatesMode.None) || (currentViewMode != SubordinatesMode.None)) && (viewMode != currentViewMode)) {
      this.globalCacheService.clearBufferCache();
      this.globalCacheService.resetSubordinatesList();
      this.globalCacheService.clearCurrentFilterStatusSubmitGroup();
      let periodEnd = this.globalCacheService.getPeriodEnd();
      let timeReport = TimeReportUtils.createTimeReport(periodEnd, "", viewMode);
      this.globalCacheService.handleResetTimeReport(periodEnd);
      this.globalCacheService.handleTimeReport(periodEnd, of(timeReport));
    }
    this.globalCacheService.setCurrentSubordinate(null);
    this.globalEventsService.dispatchSubordinateSelectedEvent({ subordinate: null, mode: SubordinatesMode.None });
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    if(!this.router.url.includes("/expenses/view/"))
    this.router.navigate([this.router.url.split('?').shift()], { queryParams: { periodEnd: encodeURIComponent(btoa(this.globalCacheService.getPeriodEnd().toISOStringMyTE())), viewMode: encodeURIComponent(btoa(viewMode)) } } as NavigationExtras);
    else
    this.router.navigate(["/expenses"], { queryParams: { periodEnd: encodeURIComponent(btoa(this.globalCacheService.getPeriodEnd().toISOStringMyTE())), viewMode: encodeURIComponent(btoa(viewMode)) } } as NavigationExtras);
    this.updateSubordinateTitleMenu(viewMode);
  }



  private generateSubrodinateMenu(am: ActionMenu): void {
    if (!this.appConfigData.AdditionalConfig.enableSubordinateModes) return;
    let HasMenu = document.getElementsByClassName('_myte-uh-links-container').length;
    if(HasMenu > 0) return;
    try {
      const isApprovalPage = this.urlApprovalPages.some(url => this.router.url.includes(url));
      const header = document.getElementsByClassName('represent-class')[0];
      header.classList.add("_myte-uh");
      const linkContainer = document.createElement('div');
      linkContainer.classList.add("_myte-uh-links-container");
      const linkContainerTitle = document.createElement('span');
      linkContainerTitle.id = "_myte-uh-links-title";
      linkContainerTitle.innerHTML = isApprovalPage? "My View" : "Record my Time";
      linkContainer.tabIndex=0;
      linkContainer.setAttribute("role","combobox");
      linkContainer.setAttribute("aria-haspopup","listbox");
      linkContainer.setAttribute("aria-label","Represent dropdown press enter to expand options");
      linkContainer.onclick = () => {
          const e = document.getElementById('_myte-options-container');
          if(e != undefined || (e != null)){
              if (e.style.display == 'none' ||  e.style.display == "") {
                e.style.display = 'flex';
                linkContainer.style.backgroundColor = "white";
                linkContainer.style.color = "black";
            } else {
                e.style.display = 'none';
                linkContainer.style.backgroundColor = "";
                linkContainer.style.color = "";
            } 
          }
      }
      document.addEventListener('mouseup', function(event) {
        const e = document.getElementById('_myte-options-container');
        if(e != undefined || (e != null)){
          if (e.style.display == 'flex'){
            setTimeout(() => {
              e.style.display = 'none';
              linkContainer.style.color = "#808080";
              event.stopPropagation();
            }, 50);
          }
        }
      });

      linkContainer.addEventListener("keydown",function(event){
        const e = document.getElementById('_myte-options-container');
        if(e != undefined || (e != null)){
          switch(event.key){
            case 'Enter' :
              if (e.style.display == 'none' ||  e.style.display == "" ) {
                e.style.display = 'flex'; 
                linkContainer.style.backgroundColor = "white";
                linkContainer.style.color = "black";
                let element = document.getElementById("recordMyTime")
                if(element)
                  (element as HTMLElement).focus(); 
                break;
              }
            case 'Escape' :
              e.style.display = 'none';
              linkContainer.style.backgroundColor = "";
              linkContainer.style.color = "";
              break;
            case 'Tab':
              if(document.activeElement.classList.contains("_myte-option")){
                const e = document.getElementById('_myte-options-container');
                e.style.display = 'none';
                linkContainer.style.backgroundColor = "";
                linkContainer.style.color = "";
              }
              break;
            case 'ArrowDown':
              event.stopPropagation();
              event.preventDefault();
              var next = document.activeElement.nextElementSibling;
              if(next != null && next != undefined){
                  (next as HTMLElement).focus();
              } else {
                var first = document.querySelector("._myte-option");
                if (first != null && first != undefined) {
                  (first as HTMLElement).focus();
                }
              }
              
              break;
            case 'ArrowUp':
              event.stopPropagation();
              event.preventDefault();
              var previous = document.activeElement.previousElementSibling;
              if(previous != null && previous != undefined){
                  (previous as HTMLElement).focus();
              };
              break;
            default:
                break;
          }
        }
      });  
      const linkContainerArrow = document.createElement('span');
      linkContainerArrow.classList.add("material-icons");
      linkContainerArrow.setAttribute("aria-hidden","true");
      linkContainerArrow.innerHTML = "keyboard_arrow_down";

      const optionsContainer = document.createElement('div');
      optionsContainer.id = '_myte-options-container'
      optionsContainer.classList.add('_myte-options-list');
      optionsContainer.setAttribute("aria-hidden","true");

      var elements = QuerySelector("._myte-option");
      var i = 1;

      const optionRecordTime = document.createElement('div');
      optionRecordTime.innerHTML = isApprovalPage? "My View" : "Record my Time";
      optionRecordTime.classList.add('_myte-option');
      optionRecordTime.setAttribute("tabindex","0");
      optionRecordTime.setAttribute("id","recordMyTime");
      optionRecordTime.setAttribute("role","option");
      optionRecordTime.setAttribute("aria-label", isApprovalPage? "My View" : "Record my Time" + (i++) + " of " + elements.length);
      
      optionRecordTime.onclick = () => this.showSubordinatesList(SubordinatesMode.None);
      
      optionRecordTime.onkeydown = (event) => {
        if(event.key == 'Enter'){
          this.showSubordinatesList(SubordinatesMode.None);
          this.setAriaHidden('recordMyTime');
        }
      }
      optionsContainer.appendChild(optionRecordTime);

      if (am.review && !isApprovalPage) {
        const optionReview = document.createElement('div');
        optionReview.innerHTML = "Review Another Person";
        optionReview.classList.add('_myte-option');
        optionReview.setAttribute("tabindex","0");
        optionReview.setAttribute("id","reviewerAnotherPerson");
        optionReview.setAttribute("role","option");
        optionReview.setAttribute("aria-label","Review Another Person"+ (i++) + " of " + elements.length);
        optionReview.onclick = () => this.showSubordinatesList(SubordinatesMode.Supervisor);
      
        optionReview.onkeydown = (event) => {
          if(event.key == 'Enter'){
            this.showSubordinatesList(SubordinatesMode.Supervisor);
            this.setAriaHidden('reviewerAnotherPerson');
          }

        }
        optionsContainer.appendChild(optionReview);
      }

      if (am.approve && !isApprovalPage) {
        const optionApprove = document.createElement('div');
        optionApprove.innerHTML = "Approve Another Person";
        optionApprove.classList.add('_myte-option');
        optionApprove.setAttribute("tabindex","0");
        optionApprove.setAttribute("role","option");
        optionApprove.setAttribute("id","approveAnotherPerson");
        optionApprove.setAttribute("aria-label","Approve Another Person"+ (i++) + " of " + elements.length);
        optionApprove.onclick = () => this.showSubordinatesList(SubordinatesMode.Approver);
        
        optionApprove.onkeydown = (event) => {
          if(event.key == 'Enter'){
            this.showSubordinatesList(SubordinatesMode.Approver);
            this.setAriaHidden('approveAnotherPerson');
          }
        }
        optionsContainer.appendChild(optionApprove);
      }

      if (am.represent) {
        const optionDelegate = document.createElement('div');
        optionDelegate.innerHTML = "Represent Another Person";
        optionDelegate.classList.add('_myte-option');
        optionDelegate.setAttribute("tabindex","0");
        optionDelegate.setAttribute("role","option");
        optionDelegate.setAttribute("id","representAnotherPerson");
        optionDelegate.setAttribute("aria-label","Represent Another Person"+ (i++) + " of " + elements.length);
        optionDelegate.onclick = () => this.showSubordinatesList(SubordinatesMode.Delegate);
        
        optionDelegate.onkeydown = (event) => {
          if(event.key == 'Enter'){
            this.showSubordinatesList(SubordinatesMode.Delegate);
            this.setAriaHidden('representAnotherPerson');
          }
        }
        optionsContainer.appendChild(optionDelegate);
      }

      linkContainer.appendChild(optionsContainer);
      linkContainer.appendChild(linkContainerTitle);
      linkContainer.appendChild(linkContainerArrow);
      header.insertBefore(linkContainer, header.children[2]);

      const menuContainter = header.children[header.childElementCount - 1];
      menuContainter.classList.add("_myte-uh-menu-container");

      this.updateSubordinateTitleMenu(this.globalCacheService.getSubordinateMode());
    } catch (error) {
      console.error("Unable to create subordinate Menu.");
      console.error(error);
    }
  }

  public setAriaHidden(idOption) {
    const elementsIdArray = ['alertrecordMyTime', 'alert-reviewer-mode','alert-review-another','alert-review-person', 'alertapproveAnotherPerson', 'alertrepresentAnotherPerson'];
    elementsIdArray.forEach(element => {
        if (element === 'alert'+idOption) {
          setTimeout(() => {
            document.getElementById(element).setAttribute('aria-hidden', 'false'); 
            document.getElementById(element).setAttribute('aria-live', 'assertive');             
          }, 5000);
        }
        else{
          document.getElementById(element).setAttribute('aria-hidden', 'true');
        }
    });
  }
  private updateSubordinateTitleMenu(mode: SubordinatesMode) {
    switch (mode) {
      case SubordinatesMode.Approver:
        document.getElementById("_myte-uh-links-title").innerHTML = "Approve Another Person";
        break;

      case SubordinatesMode.Delegate:
        document.getElementById("_myte-uh-links-title").innerHTML = "Represent Another Person";
        break;

      case SubordinatesMode.Supervisor:
        document.getElementById("_myte-uh-links-title").innerHTML = "Review Another Person";
        break;

      default:
        const isApprovalPage = this.urlApprovalPages.some(url => this.router.url.includes(url));
        document.getElementById("_myte-uh-links-title").innerHTML = isApprovalPage? "My View" : "Record my Time";
        break;
    }

  }

  private setProfileEvent(personElements) {
    (personElements[0] as any).removeAllListeners();
    personElements[0].addEventListener('click', function() {
      var url = (this).getAttribute("data-url");
      window.open(url);
    });
  }

  private isElementExist(ele) {
    return typeof ele !== 'undefined' &&
      ele &&
      ele.length > 0;
  } 
}
