import { Component, OnInit, Input, EventEmitter, Output, ChangeDetectorRef, OnDestroy, ChangeDetectionStrategy, ViewChild, HostListener, OnChanges, SimpleChanges } from '@angular/core';
import { SubordinatesMode } from '../../../shared/models/subordinates/subordinate-mode';
import { SubordinatesService } from '../../../shared/services/subordinates/subordinates.service';
import { PeriodEndService } from '../../../shared/services/periodend/periodend.service';
import { of, Subject,forkJoin, combineLatest } from 'rxjs';
import Subordinate from '../../../shared/models/subordinates/subordinate';
import { tap, takeUntil, switchMap, mergeAll, map, concatMap, take } from 'rxjs/operators';
import { GlobalEventsService } from '../../../shared/services/events/global-events.service';
import { GlobalCacheService } from '../../../shared/services/cache/global-cache.service';
import { ReportService } from '../../../shared/services/report/report.service';
import { TimeReportService } from '../../../shared/services/time-report/time-report.service';
import { TimeReportStatusCode, TimeReportStatusCodeDescription } from '../../../shared/models/time-report/time-report-status-code';
import DropdownItem from '../../../shared/models/controls/dropdown/dropdown-item';
import { TimeReportStatusToUiStatusPipe } from '../../../shared/pipes/time-report-status-to-ui-status.pipe';
import { DropdownComponent } from '../../../myte-controls/components/dropdown/dropdown.component';
import { ActionWithMaticon } from '../../../shared/constant';
import { Router } from '@angular/router';
import { TimeSheetService } from '../../../myte-time/shared/services/timesheet/timesheet.service';
import { LocationsService } from '../../../myte-locations/shared/services/locations/locations.service';
import { ActionStoreService } from '@shared/services/store/action-store.service';
import { ActionType } from '@shared/services/store/shared/action-type';
import { TimeReportStoreService } from '@shared/services/store/time-report-store.service';
import TimeReportSettings from '@shared/models/time-report/time-report-settings';

@Component({
    selector: 'myte-subordinated-category',
    templateUrl: './subordinated-category.component.html',
    styleUrls: ['./subordinated-category.component.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubordinatedCategoryComponent implements OnInit, OnDestroy {
    @Input() public subordinateMode: SubordinatesMode;
    @Input() public isCounseleeList: boolean;
    @Input() public isSearchEnabled: boolean;
    @Input() public isReview = true;
    @Input() public counselees: Subordinate[];
    @Output() public onReady = new EventEmitter<boolean>();
    @Output() public onEmitCounselees = new EventEmitter<Subordinate[]>();
    public subordinates: Subordinate[] = null;
    public subordinatesFilter: Subordinate[] = null;
    public subordinateSelected: Subordinate = new Subordinate();
    public disableSubmitAll: boolean = false;
    private componentDestroyed: Subject<void> = new Subject();
    public showStatusCodeFilter = false;
    public showSelectAllButton = false;
    public selectedFilter: DropdownItem;
    public subordinatesToRemove: Subordinate[] = [];
    public removeButtonIsDisabled: boolean = true;
    public toolTipApproverRemoveButton = 'Remove the selected employee from list of approvees. You will no longer be listed as an approver for this employee.';
    public toolTipReviwerRemoveButton = 'Remove the selected employee from list of reviewees. You will no longer be listed as a reviewer for this employee.';
    public selectionToolAlertId = 'selection-tools-alert';
    public textToolTipRemoveButton = '';
    public height = 200;
    public isReviewer: boolean;
    public numberOfSelectedSubordinates: number = 0;
    @ViewChild('selectAllButton') public selectAllButton: any;
    @ViewChild('deselectAllButton') public deselectAllButton: any;
    @ViewChild('submitGroupButton') public submitGroupButton: any;
    @ViewChild('dropdownComponent') dropdownComponent: DropdownComponent;
    @ViewChild('subTitle') public subTitle: any;
    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.needRecalculateHeightLists();
    }

    public dropDownStatusCodes: DropdownItem[] = [
        { key: '', value: 'All', isValid: true },
        { key: TimeReportStatusCode.SubmittedPendingAdjustment, value: this.getStatusFormatted(TimeReportStatusCode.SubmittedPendingAdjustment), isValid: true },
        { key: TimeReportStatusCode.DraftPendingUpdates, value: this.getStatusFormatted(TimeReportStatusCode.DraftPendingUpdates), isValid: true },
        { key: TimeReportStatusCode.Draft, value: this.getStatusFormatted(TimeReportStatusCode.Draft), isValid: true },
        { key: TimeReportStatusCode.DraftAdjustment, value: this.getStatusFormatted(TimeReportStatusCode.DraftAdjustment), isValid: true },
        { key: TimeReportStatusCode.New, value: this.getStatusFormatted(TimeReportStatusCode.New), isValid: true },
        { key: TimeReportStatusCode.Processed, value: this.getStatusFormatted(TimeReportStatusCode.Processed), isValid: true },
        { key: TimeReportStatusCode.ProcessedAdjustment, value: this.getStatusFormatted(TimeReportStatusCode.ProcessedAdjustment), isValid: true },
        { key: TimeReportStatusCode.Submitted, value: this.getStatusFormatted(TimeReportStatusCode.Submitted), isValid: true },
        { key: TimeReportStatusCode.SubmittedAdjustment, value: this.getStatusFormatted(TimeReportStatusCode.SubmittedAdjustment), isValid: true },
        { key: TimeReportStatusCode.SubmittedPendingApproval, value: this.getStatusFormatted(TimeReportStatusCode.SubmittedPendingApproval), isValid: true }
    ];

    constructor(
        private router: Router,
        private changeDetectorRef: ChangeDetectorRef,
        private globalEventsService: GlobalEventsService,
        private globalCacheService: GlobalCacheService,
        private subordinatesService: SubordinatesService,
        private periodEndService: PeriodEndService,
        private reportService: ReportService,
        private timeReportService: TimeReportService,
        private timeSheetService: TimeSheetService,
        private cacheService: GlobalCacheService,
        private locationsService: LocationsService,
        private actionService: ActionStoreService
    ) { }

    public ngAfterViewInit() {
        this.needRecalculateHeightLists();
        this.changeDetectorRef.detectChanges();
    }

    public ngOnInit(): void {
        this.onReady.emit(false);
        this.removeButtonIsDisabled = true;
        if (this.subordinateMode !== SubordinatesMode.Supervisor) {
            this.showStatusCodeFilter = true;
        }
        if (this.counselees && this.counselees.length > 0) {
            this.onReady.emit(true);
            this.subordinates = this.counselees;
            this.subordinatesFilter = this.subordinates;
            this.removeButtonIsDisabled = !this.subordinates.some(sub => { return sub.checked === true});
            const subCache = this.globalCacheService.getCurrentSubordinate();
            if (subCache?.peopleKey != null && this.subordinates?.length > 0) {
                const actualSub = this.subordinates.find(subs => subs.enterpriseId === subCache.enterpriseId);
                if (actualSub?.peopleKey != null) {
                    this.globalCacheService.setCurrentSubordinate(actualSub);
                    this.selectSubordinate(actualSub);
                }
            }
            this.setSelectedFilter();
            this.needRecalculateHeightLists();
            this.changeDetectorRef.markForCheck();
        } else {
            this.subordinatesService
            .getSubordinatesList(this.subordinateMode, this.periodEndService.getActivePeriod(), this.isReview)
            .pipe(takeUntil(this.componentDestroyed))
            .pipe(tap(() => this.onReady.emit(true)))
            .subscribe(subordinates => {
                this.subordinates = subordinates;
                if (subordinates && subordinates.length > 0) {
                    const isRevieweeLink: boolean = !(this.subordinateMode == SubordinatesMode.Supervisor && false);
                    const counselees: Subordinate[] = subordinates.filter(subordinate => subordinate.isCounselee === isRevieweeLink || subordinate.isReviewee !== isRevieweeLink);
                    this.onEmitCounselees.emit(counselees);
                }
                this.subordinatesFilter = this.subordinates;
                this.removeButtonIsDisabled = !this.subordinates.some(sub => { return sub.checked === true});
                const subCache = this.globalCacheService.getCurrentSubordinate();
                if (subCache?.peopleKey != null && this.subordinates?.length > 0) {
                    const actualSub = this.subordinates.find(subs => subs.enterpriseId === subCache.enterpriseId);
                    if (actualSub?.peopleKey != null) {
                        this.globalCacheService.setCurrentSubordinate(actualSub);
                        this.selectSubordinate(actualSub);
                    }
                }
                this.setSelectedFilter();
                this.needRecalculateHeightLists();
                this.changeDetectorRef.markForCheck();
            });
        }

        this.globalEventsService.selectUpdateSubordinateList()
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe(subordiante => {
                this.globalCacheService.resetSubordinatesList();
                this.globalCacheService.clearCurrentFilterStatusSubmitGroup();
                this.subordinates = this.subordinates?.filter(sub1 => {
                    return !subordiante?.some(sub2 => {
                        return sub1.peopleKey == sub2.peopleKey;
                    });
                });
                this.subordinatesToRemove = [];
                this.removeButtonIsDisabled = true;
                this.subordinatesFilter = this.subordinates;
                this.dropdownComponent?.restoreDefaulItem();
                this.needRecalculateHeightLists();
                this.changeDetectorRef.markForCheck();
            });

        this.globalEventsService.selectSubmitAllEvent()
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe(subordiante => {
                if (!subordiante.shouldRefreshSubordinateList) {
                    return;
                } else {
                    this.removeButtonIsDisabled = true;
                    this.subordinatesToRemove = [];
                }
                this.globalCacheService.resetSubordinatesList();
                this.subordinates = subordiante.subordinates;
                this.subordinatesFilter = this.subordinates;
                this.setSelectedFilter();
                this.globalCacheService.handleSubordinatesList(this.subordinateMode, this.isReview, this.periodEndService.getActivePeriod(), of(this.subordinates));
                this.needRecalculateHeightLists();
                this.changeDetectorRef.markForCheck();
            });

        this.globalEventsService.selectPeriodChangedEvent()
            .pipe(takeUntil(this.componentDestroyed))
            .pipe(switchMap(currentDate =>
                this.subordinatesService
                    .getSubordinatesList(this.subordinateMode, currentDate, this.isReview)
                    .pipe(takeUntil(this.componentDestroyed))
                    .pipe(tap(() => this.onReady.emit(true)))
            ))
            .subscribe(subordinates => {
                this.subordinates = subordinates;
                this.subordinatesFilter = this.subordinates;
                this.needRecalculateHeightLists();
            });

        this.globalEventsService.selectSubordinateSelectedEvent()
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe(payload => {
                this.subordinateSelected = payload?.subordinate;
                this.needRecalculateHeightLists();
                this.changeDetectorRef.markForCheck();
            });

        this.globalEventsService.selectUpdateTimeReportSubordinateSelected()
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe(timeReportInfo => {
                this.subordinates.find(sub => {
                    if (sub.enterpriseId === timeReportInfo.enterpriseId) {
                        sub.statusCode = TimeReportStatusCode[timeReportInfo.timeReportStatus];
                        sub.statusCodeDescription = TimeReportStatusCodeDescription[sub.statusCode];
                    }
                });
                this.setSelectedFilter();
                this.needRecalculateHeightLists();
                this.changeDetectorRef.markForCheck();
            });

        this.globalEventsService.selectActionAtSubordinateTimeReport()
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe(action => {
                this.subordinates.find(sub => {
                    if (sub.peopleKey === action.subordinatePeopleKey && this.isReview) {
                        sub.actionFromSupervisor = ActionWithMaticon[action.action];
                    }
                });
                this.changeDetectorRef.markForCheck();
            });

        this.globalEventsService.selectSubordinateListNeedCalculateHeightEvent()
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe(hasChanges => {
                if (hasChanges) {
                    this.subordinatesService.saveTotalSubs(this.isReview, this.subordinatesFilter?.length);
                    this.height = this.subordinatesService.calculateHeightLists(this.isReview, this.subordinateSelected?.enterpriseId != null, this.subordinateMode, this.isSearchEnabled);
                    this.changeDetectorRef.markForCheck();
                }
            });

        const subordinateInCache = this.globalCacheService.getCurrentSubordinate();
        if (subordinateInCache != null) this.subordinateSelected = subordinateInCache;

        this.showSelectAllButton = ((this.subordinateMode == SubordinatesMode.Approver || this.subordinateMode == SubordinatesMode.Delegate || this.subordinateMode == SubordinatesMode.Supervisor) && this.isReview);
        this.textToolTipRemoveButton = this.subordinateMode == SubordinatesMode.Approver ? this.toolTipApproverRemoveButton : this.toolTipReviwerRemoveButton;
        this.setSelectedFilter();

        this.isReviewer = this.subordinateMode === SubordinatesMode.Supervisor;
    }

    public setSelectedFilter() {
        this.selectedFilter = this.dropDownStatusCodes.find(status => { return status.key === this.globalCacheService.getCurrentFilterStatusSubmitGroup() });
        if (this.selectedFilter && this.subordinates?.length > 0 && this.selectedFilter.value !== 'All') {
            this.applyFilterToSubordinates(this.selectedFilter?.key);
        } else {
            if (!this.selectedFilter) {
                this.globalCacheService.clearCurrentFilterStatusSubmitGroup();
                // Assign default option to selected filter.
                this.selectedFilter = this.dropDownStatusCodes[0];
            }
        }
    }

    public onStatusCodeChange(statusCode: string) {
        this.subordinatesToRemove = [];
        this.removeButtonIsDisabled = this.hasSelectedSubordinates();
        this.selectedFilter = this.dropDownStatusCodes.find(status => { return status.key === statusCode });
        this.globalCacheService.setCurrentFilterStatusSubmitGroup(this.selectedFilter?.key);
        if (statusCode === "") {
            this.subordinatesFilter = this.subordinates;
            this.subordinatesFilter.forEach(sub => sub.checked = false);
            this.needRecalculateHeightLists();
        }
        else {
            this.applyFilterToSubordinates(statusCode);
        }
    }

    public applyFilterToSubordinates(statusCode: string) {
        if (!this.isReview) return;
        this.subordinatesFilter = this.subordinates.filter(sub => {
            sub.checked = false;
            if (sub.statusCode === statusCode) {
                return sub;
            }
        });
        this.needRecalculateHeightLists();
    }

    public getStatusFormatted(statusCode: string): string {
        return '(' + statusCode + ')' + ' - ' + new TimeReportStatusToUiStatusPipe().transform(TimeReportStatusCode.getKeyByValue(statusCode));
    }

    public trackSubordinates(index: number, subordinate: Subordinate): number {
        return subordinate.peopleKey;
    }

    public selectSubordinate(subordinate: Subordinate, isClickEvent: boolean = false): void {
        this.actionService.dispatchAction(ActionType.SELECT_SUBORDINATE, { subordiante: subordinate, subordinateMode: this.subordinateMode })
        .pipe(takeUntil(this.componentDestroyed))
        .subscribe(([timeReportSettings, needRedirect]: [TimeReportSettings, boolean]) => {
            if (subordinate?.actionFromSupervisor == null)
            this.subordinatesService.shouldSetActionIconFromSupervisor(subordinate);
            this.subordinateSelected = subordinate;
            this.changeDetectorRef.markForCheck();
            this.needRecalculateHeightLists();

            var route = this.router.url.split('?')[0].split('/')[1];
            var subordinateUrlValue: string = this.router.url.substr(this.router.url.lastIndexOf("subordinate"))?.split('=')[1];
            var newSubordinate: string = encodeURIComponent(btoa(subordinate.enterpriseId));
            let newUrl = subordinateUrlValue && subordinateUrlValue !=newSubordinate ? this.router.url.replace(subordinateUrlValue, newSubordinate) : this.router.url;
            if (needRedirect) {
                if((route == "compensatory" && !timeReportSettings.showCompensatoryTab) ||
                    (route == "questionnaire" && !timeReportSettings.showLocationQuestionnaireTab) ||
                    (route == "carplan" && !timeReportSettings.showCarPlan)) {
                        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
                        this.router.navigateByUrl(newUrl.replace(route,'time'));
                } else {
                    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
                    this.router.navigateByUrl(newUrl);
                }
            }
        });         
    }

    public setSubordinateTabs(countryKey: string, enterpriseId: string) {
        this.actionService.dispatchAction(ActionType.LOAD_TIME_REPORT, { periodEnd: this.globalCacheService.getPeriodEnd() })
        .subscribe(response => {
            var route = this.router.url.split('?')[0].split('/')[1];
            var subordinateUrlValue: string = this.router.url.substr(this.router.url.lastIndexOf("subordinate"))?.split('=')[1];
            var newSubordinate: string = encodeURIComponent(btoa(enterpriseId));
            let newUrl = subordinateUrlValue && subordinateUrlValue !=newSubordinate ? this.router.url.replace(subordinateUrlValue, newSubordinate) : this.router.url;
            if((route == "compensatory" && !response[0]?.showCompensatoryTab) ||
                (route == "questionnaire" && !response[0]?.showLocationQuestionnaireTab) ||
                (route == "carplan" && !response[0]?.showCarPlan))
                this.router.navigateByUrl(newUrl.replace(route,'time'));
            else
                this.router.navigateByUrl(newUrl);
          });
    }

    public onShowPdfViewer(): void {
        if (this.subordinates.some(sub => sub.checked)) {
            this.reportService.printPDF(true, this.subordinates.filter(sub => sub.checked), this.globalCacheService.getPeriodEnd().toISOStringMyTE());
        }
    }

    public removeChecked(): void {
        this.globalEventsService.dispatchShowSubordinateDeletePopup(this.subordinatesToRemove);
    }

    public onSubmit(trigger: string): void {
        this.subordinates.forEach(sub=>{
            sub.errorMessage = null
        })
        const isSubmitAll = (trigger === 'All');
        let periodEnd = this.cacheService.getPeriodEnd();
        this.setButtonsAttributes(true, isSubmitAll);
        combineLatest({
            timeSheet: this.timeSheetService.getTimeSheet(periodEnd),
            locationByDay: this.cacheService.getLocationsByDay(periodEnd)
        })
        .pipe(map(({timeSheet, locationByDay}) => {
            return forkJoin({
                timeSheet: timeSheet.isSaved && !timeSheet.modified && !timeSheet.shouldResave ? of(timeSheet) : (locationByDay != null ? of(timeSheet) : this.timeSheetService.saveTimeSheet(timeSheet, periodEnd)),
                locationByDay: locationByDay ? this.locationsService.saveLocations(locationByDay, true) : of(null)
            })
        }), mergeAll())
        .pipe(concatMap((data) => {
            return this.timeReportService.validateTimeReportGroup(
                isSubmitAll ? this.subordinates : this.subordinates.filter(sub => this.canBeSubmitted(sub)),
                this.subordinateMode,
                this.periodEndService.getActivePeriod(),
                isSubmitAll
            )
        }))
        .subscribe(data => {
            if (data?.submissionErrorMessages) {
                this.subordinates.forEach(sub => {
                    sub.errorMessage = data.submissionErrorMessages.find(err => err.enterpriseId === sub.enterpriseId)?.errorMessage;
                });
            }
            if (data?.shouldContinueSubmit) {
                this.globalEventsService.dispatchSubmitAllEvent({
                    subordinates: isSubmitAll ? this.subordinates : this.subordinates.filter(sub => this.canBeSubmitted(sub)),
                    isReview: this.isReview,
                    shouldRefreshSubordinateList: false
                });
            }
            this.setButtonsAttributes(false, isSubmitAll);
            this.changeDetectorRef.markForCheck();
        });
    }

    public setButtonsAttributes(status: boolean, isSubmitAll: boolean): void {
        this.submitGroupButton.nativeElement.disabled = status;
    }

    public ngOnDestroy(): void {
        this.componentDestroyed.next();
        this.componentDestroyed.complete();
    }

    public checkedSelectedSubordinate(subordinateWithCheckedValue: any): void {
        this.addToOrRemoveFromSubordinatesListToRemove(subordinateWithCheckedValue);
        this.addToOrRemoveFromSubordinatesListToSubmit(subordinateWithCheckedValue);
    }

    public hasSelectedSubordinates = () => this.numberOfSelectedSubordinates > 0;
    
    public addToOrRemoveFromSubordinatesListToRemove(subordinateWithCheckedValue: any):  void {
        if (subordinateWithCheckedValue.checked) {
            this.subordinatesToRemove.push(subordinateWithCheckedValue.subordinate)
        } else {
            this.subordinatesToRemove = this.subordinatesToRemove.filter(sub => sub.peopleKey !== subordinateWithCheckedValue.subordinate.peopleKey);
        }
        this.removeButtonIsDisabled = !(this.subordinatesToRemove.length > 0);
    }

    public addToOrRemoveFromSubordinatesListToSubmit(subordinateWithCheckedValue: any): void {
        this.subordinates.find(sub => sub.peopleKey === subordinateWithCheckedValue.subordinate.peopleKey).checked = subordinateWithCheckedValue.checked;
    }

    public canBeSubmitted(subordinateWithCheckedValue: Subordinate): boolean {
        let isDelegateAndChecked = this.subordinateMode == SubordinatesMode.Delegate && subordinateWithCheckedValue.checked;
        let isApproverAndChecked = this.subordinateMode == SubordinatesMode.Approver && subordinateWithCheckedValue.checked;
        let subordinateStatusCode = subordinateWithCheckedValue.statusCode;
        return ((subordinateStatusCode == TimeReportStatusCode.SubmittedPendingApproval && isApproverAndChecked)  ||
                ((subordinateStatusCode == TimeReportStatusCode.New  ||
                    subordinateStatusCode == TimeReportStatusCode.Draft  ||
                    subordinateStatusCode == TimeReportStatusCode.DraftAdjustment ||
                    subordinateStatusCode == TimeReportStatusCode.DraftPendingUpdates)
                    && isDelegateAndChecked)
                );
    }

    public onSelectAll(): void {
        this.subordinatesToRemove = [];
        const maxUsersAbleToSelect = 500;
        const subordinatesAffectedAmount = this.subordinatesFilter?.length > maxUsersAbleToSelect ? maxUsersAbleToSelect : this.subordinatesFilter?.length;
        for (let index = 0; index < subordinatesAffectedAmount; index++) {
            this.subordinatesFilter[index].checked ? this.numberOfSelectedSubordinates : this.numberOfSelectedSubordinates++;
            this.subordinatesFilter[index].checked = true;
            this.subordinatesToRemove.push(this.subordinatesFilter[index]);
        }
        this.removeButtonIsDisabled = !(subordinatesAffectedAmount > 0);
        this.alertSelection(false);
    }

    public onDeselectAll(): void {
        const subordinatesAffectedAmount = this.subordinatesFilter?.length;
        this.removeButtonIsDisabled = this.hasSelectedSubordinates();
        for (let index = 0; index < subordinatesAffectedAmount; index++) {
            this.subordinatesFilter[index].checked = false;
        }
        this.numberOfSelectedSubordinates = 0;
        this.subordinatesToRemove = [];
        this.alertSelection(true);
        this.selectAllButton.nativeElement.focus();
    }

    public alertSelection(isDeselectAll) {
        let alertElement = document.getElementById(this.selectionToolAlertId);
        
        alertElement?.setAttribute('aria-hidden', 'false');
        alertElement?.setAttribute('role', 'alert');
        alertElement?.setAttribute('aria-label', `You have ${isDeselectAll ? 'deselected all users' : 'selected all users'}`);
        setTimeout(() => {
            let alertElement = document.getElementById(this.selectionToolAlertId);
            alertElement?.setAttribute('aria-label', '')
            alertElement?.setAttribute('aria-hidden', 'true');
            alertElement?.setAttribute('role', 'none');
        }, 2000);
    }

    public needRecalculateHeightLists(): void {
        if ((this.subordinates != null) && (this.subordinates != undefined))
            this.globalEventsService.dispatchSubordinateListNeedCalculateHeightEvent(true);
    }

    public calculateHeight(): number {
        if ((this.subordinates != null) && (this.subordinates != undefined)) {
            this.subordinatesService.saveTotalSubs(this.isReview, this.subordinatesFilter?.length);
            this.height = this.subordinatesService.calculateHeightLists(this.isReview, this.subordinateSelected?.enterpriseId != null, this.subordinateMode, this.isSearchEnabled);
            this.changeDetectorRef.markForCheck();
            return this.height;
        }
    }

    public selectSubordinateUser(subordinate: Subordinate, value: any): void{
        !value ? this.numberOfSelectedSubordinates++ : this.numberOfSelectedSubordinates--;
        subordinate.checked = !value;
        this.checkedSelectedSubordinate({ subordinate, checked: subordinate.checked });
    }

    public subordinateTrackBy(index: number, subordinate: Subordinate): number {
        return subordinate.peopleKey;
    }
}