export class commuteMetaDataList {
    public id: number;
    public simpleSettingName: string;
    public possibleValues: string[];
    public required: boolean;
    public dataType: string;
    constructor(
        id?: number,
        simpleSettingName?: string,
        possibleValues?: string[],
        required?: boolean,
        dataType?: string
    ) {
        this.id = id;
        this.simpleSettingName = simpleSettingName;
        this.possibleValues = possibleValues;
        this.required = required;
        this.dataType = dataType;
    }
}