export class ChargeCode {
  public externalNumber: string;
  public description: string;

  constructor(externalNumber?: string, description?: string) {
    this.externalNumber = externalNumber;
    this.description = description;
  }

}
