import { PeriodDate } from './period-date';
import { LocationEntry } from './location-entry';
import { MultipleLocation } from '../multiple-locations/multiple-location';
import { LocationInformation } from './location-information';
import {LBDChangeReason} from './lbdchangereason';
import MyteApiMessage from '../../myte-api-message';
import * as moment from 'moment';

export class LocationsByDay {
  
  public periodDates: Array<PeriodDate>;
  public locationEntries: Array<LocationEntry>;
  public defaultLocation: LocationInformation;
  public LBDChangeReason: LBDChangeReason;
  public readOnly: boolean;
  public messages: MyteApiMessage[];
  public isMultipleLocationsEnabled: boolean;
  public isNon8100: boolean;
  public isAutoSave: boolean;
  public isSubmit: boolean;
  public message: string;
  public isEditable: boolean;
  public timeReportStatus: string;
  public modified: boolean;
  public isESTravelPunchClock: boolean;
  private validations = {
    onSave: () => {
      if (!this.isAutoSave) {
        this.hasEmptyDays();
        if (this.isSubmit) {
          this.hasEmptyMultipleLocations();
        }
        if (this.message) { throw new Error(this.message); }
      }
    },
    hasMultipleLocations: (date: Date) => this.hasMultipleLocations(date)
  };

  /**
   *
   */
  constructor() {
    this.periodDates = new Array<PeriodDate>();
    this.locationEntries = new Array<LocationEntry>();
    this.readOnly = false;
    this.messages = new Array<MyteApiMessage>();
    this.isMultipleLocationsEnabled = false;
    this.isNon8100 = false;
    this.isEditable = false;
    this.modified = false;
  }

  public get validate() {
    return this.validations;
  }

  public updateMultipleLocations(date: Date, charge?: MultipleLocation) {
    if (this.evaluateMultipleLocations(date)) {
      this.updateMultipleLocation(date, true);
    } else {
      this.updateMultipleLocation(date, false);
    }
  }

  public updateDefaultLocation(location?: LocationInformation) {    
    if (location) {            
      this.defaultLocation = location;
    }
    var defaultLocationEntry = this.locationEntries.find(x => x.isDefault == true);
    if (defaultLocationEntry == undefined) {
      this.locationEntries = this.locationEntries.filter(x=> x.isDefault = x.location.equals(this.defaultLocation))   
    } 
    else {
      var newDefaultLocation = this.locationEntries.find(x=> x.location.equals(this.defaultLocation));
      newDefaultLocation.isDefault = true;
      let defaultLocationsCount = this.locationEntries.filter(x=> x.isDefault).length;
      var newDefaultLocationIndex = this.locationEntries.indexOf(newDefaultLocation);
      if(newDefaultLocationIndex !== 0) {
        if(defaultLocationsCount === 1){
          this.locationEntries.splice(newDefaultLocationIndex,1);
          this.locationEntries.unshift(newDefaultLocation);
        } else {
          this.locationEntries.splice(newDefaultLocationIndex,1);
          this.locationEntries.splice(0,1,newDefaultLocation);
        }
      }
    }
    this.locationEntries.find(x => x.isDefault == true).dayEntries.forEach(dayEntry => {
      dayEntry.checked = true;       
      });
  }

  private hasMultipleLocations(date: Date): boolean {
    return this.periodDates.find(periodDate => periodDate.dateTime.getTime() === date.getTime()).hasMultipleLocation;
  }

  private evaluateMultipleLocations(date: Date): boolean {
    return this.locationEntries.map(l => l.dayEntries)
        .reduce((prev, curr) => prev.concat(curr))
        .filter(d => d.entryDate.getTime() === date.getTime() && d.checked)
        .length > 1;
  }

  private updateMultipleLocation(date: Date, value: boolean) {
    this.periodDates.find(periodDate => periodDate.dateTime.getTime() === date.getTime()).hasMultipleLocation = value;
  }

  // Validations
  private hasEmptyDays(): void {
    let error = false;
    let daysCount = 0;

    daysCount = this.locationEntries.length !== 0 ? this.locationEntries[0].dayEntries.length : 0;

    for (let d = 0; d < daysCount; d++) {
      let rowChecked = 0;
      for (let i = 0; i < this.locationEntries.length; i++) {
        let errorDay = true;

        if (this.locationEntries[i].dayEntries[d] != undefined) {
          if (this.locationEntries[i].dayEntries[d].checked)
            rowChecked++;
        }
      }
      let date = this.setDate(d);
      if (rowChecked == 0) {
        error = true;
        if (this.isSubmit) {
          date.errorMessage = 'Add your location for each day to be able to submit your time report';
          date.hasError = true;
        } else {
          break;
        }
      } else {
        if (this.isSubmit) {
          date.errorMessage = null;
          date.hasError = false;
        }
      }
    }
    if (error) {
      this.message = this.isSubmit ?
        'Add your location for each day to be able to submit your time report' :
        'Location by Day must be completed. Please select a location for all dates';
    } else {
      this.message = null;
    }
  }

  private setDate(d: number): PeriodDate {
    let day = this.locationEntries[0].dayEntries[d].entryDate.getTime();
    return this.periodDates.find(p => p.dateTime.getTime() == day);
  }

  private hasEmptyMultipleLocations(): void {
    let dates = this.periodDates.filter(periodDate => periodDate.hasMultipleLocation);
    let hasEmptyDays = false;
    dates.forEach(date => {
      hasEmptyDays = this.locationEntries.map(locationEntry => locationEntry.dayEntries).reduce((prev, curr) => prev.concat(curr))
        .filter(dayEntry => dayEntry.entryDate.getTime() === date.dateTime.getTime() && dayEntry.checked)
        .some(dayEntry => dayEntry.hours === undefined || dayEntry.hours === null);
        if (hasEmptyDays) {
          date.errorMessage = 'You have selected multiple locations for one or more dates. Click the date on the Locations tab to set the corresponding hours for each charge code.'
          date.hasError = true;
      }
    });
    if (dates.length != 0 && hasEmptyDays) {
      this.message = 'You have selected multiple locations for one or more dates. Click the date on the Locations tab to set the corresponding hours for each charge code.';
    }
  }

  public TranslateDayFmtMsg(text:string):string{
    var splitString = text.split(":");
    if(splitString.length == 1) return text;
    var translatedErrMsg = splitString[0] + ':';
    var SplitDates = splitString[1].split(","),i=0;
    SplitDates.forEach(errDate => {
          var SplitDay = SplitDates[i++].split(" ");
          if(!moment(SplitDay[1],"dddd").isValid()) return text;
          translatedErrMsg += " " + moment(SplitDay[1],"dddd").locale(navigator.language).format("dddd") + " " + SplitDay[2];
          if(SplitDates[SplitDates.length - 1] != errDate){ translatedErrMsg+=","; }
        });
        return translatedErrMsg;
  }

}
