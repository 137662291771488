<button *ngIf="options?.closeButton" (click)="remove()" class="toast-close-button" aria-label="Close">
    <span aria-hidden="true">&times;</span>
</button>
<div *ngIf="title" [class]="options?.titleClass" [attr.aria-label]="title">
    {{ title }} <ng-container *ngIf="duplicatesCount">[{{ duplicatesCount + 1 }}]</ng-container>
</div>
<div *ngIf="message && options?.enableHtml" role="alert"
    [class]="options.messageClass" [innerHTML]="message" [attr.aria-label]="title">
</div>
<div *ngIf="message && !options?.enableHtml" role="alert"
    [class]="options?.messageClass" [attr.aria-label]="title">
    {{ message }}
</div>
<div *ngIf="options?.progressBar">
    <div class="toast-progress" [style.width]="width + '%'"></div>
</div>