import { MyAudit } from './myte-audit';

export class MyteAuditOutput {
    public currentBrowserLanguage?: string;
    public myAudit?: Array<MyAudit>;

    constructor(currentBrowserLanguage?: string, myAudit?: Array<MyAudit>) {
        this.currentBrowserLanguage = currentBrowserLanguage
        this.myAudit = myAudit
    }
}