export class ExpenseHistory {
    public approver: string;
    public date: Date;
    public time: string;
    public comments: string;
    public status: string;

    constructor(approver?: string, date?: Date, time?: string, comments?: string, status?: string) {

        this.approver = approver;
        this.date = date;
        this.time = time;
        this.comments = comments;
        this.status = status;
    }
}