
import { Component, EventEmitter, HostListener, Input, Output, ElementRef, ViewChild} from '@angular/core';

@Component({
    selector: 'myte-idle-session-popup',
    templateUrl: './idle-session-popup.component.html',
    styleUrls: ['./idle-session-popup.component.sass']
})

export class idleSessionComponent {

    
    @Input() public time : string;
    @Output() public closeEvent = new EventEmitter<void>();
    @ViewChild('time', { static: true }) timeElement: ElementRef;


    constructor(){}

    @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
        if (event.key == 'Enter' || event.code == 'Enter') {
          var tabMenu = document.getElementById('time');
          if (tabMenu != null && tabMenu != undefined) {
            tabMenu.focus();
            this.closeModal();
          }
        }
    }

    public closeModal() {
        this.closeEvent.emit();
    }

}