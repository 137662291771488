import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, catchError, finalize } from 'rxjs/operators';
import { LogService } from '../../../../shared/services/log/log.service';
import { UserService } from '../../../../shared/services/user/user.service';
import { GlobalCacheService } from '../../../../shared/services/cache/global-cache.service';
import { OTBalanceRequestOutput, TimeReportApiClient, TimeReportSummaryOutput as APITimeReportSummary, TimeReportChargeabilityHoursSummaryDto as APIAnnualChargeability } from '../../../../shared/clients/timereport-api-client';
import { PeriodEndService } from '@shared/services/periodend/periodend.service';
import { SummaryServiceMapper } from './summary.mapper';
import { TimeReportSummary } from '../../../../shared/models/summary/time-report-summary/time-report-summary.model';
import { AnnualChargeability } from '../../../../shared/models/summary/annual-chargeability/annual-chargeability.model';
import { GlobalEventsService } from '../../../../shared/services/events/global-events.service';
import { MyteBaseService } from '../../../../shared/services/myte-base.service';
import { DataStore } from '../../../../app-shell/data-store';
import { CashOutInvalidDate, OTBalanceRequest } from '@shared/models/summary/ot-balance-request/ot-balance-request.model';
import { OTBalanceRequestInput } from '@shared/models/summary/ot-balance-request/ot-balance-request-input.model';

@Injectable()
export class SummaryService extends MyteBaseService {
  public shouldUseTwoDecimal: boolean;
  constructor(
    public userService: UserService,
    public eventService: GlobalEventsService,
    public logService: LogService,
    public globalCacheService: GlobalCacheService,
    private timeReportApiClientService: TimeReportApiClient,
    private periodEndService: PeriodEndService) {
    super(userService, globalCacheService, eventService, logService);
  }

  public saveOTBalanceRequestObservable(otBalanceRequestInput: OTBalanceRequestInput): Observable<OTBalanceRequest> {
    if (!otBalanceRequestInput || (otBalanceRequestInput && !otBalanceRequestInput.requestedHours && otBalanceRequestInput.requestedHours !== 0)) return of(undefined);
    otBalanceRequestInput.enterpriseId = this.userEid;
    this.globalEventsService.dispatchShowGlobalSpinnerEvent(true);
    return this.timeReportApiClientService.saveOTBalanceRequest(this.userEid, this.viewMode, SummaryServiceMapper.mapOTBalanceRequestInput(otBalanceRequestInput))
      .pipe(map((otBalanceRequestOutput: OTBalanceRequestOutput) => {
        if (otBalanceRequestOutput) {
          if (!otBalanceRequestOutput.passed && otBalanceRequestOutput.errorMessage !== CashOutInvalidDate.message) {
            this.logService.logError(otBalanceRequestOutput.errorMessage, true);
          } else {
            this.logService.showSuccessMessage('Success', 'Successfully');
          }
          return SummaryServiceMapper.mapAPIOTBalanceRequestOutput(otBalanceRequestOutput);
        }
      }))
      .pipe(catchError((error: any) => {
        this.logService.logError(`Unable to save your OT Balance Request. If the problem persists please escalate the issue at myTimeandExpenses Help.`, true);
        return of(undefined);
      }))
      .pipe(finalize(() => {
        this.globalEventsService.dispatchShowGlobalSpinnerEvent(false);
      }));
  }

  public getTimeReportSummaryObservable(): Observable<TimeReportSummary> {
    const getTimeReportSummaryObservable = this.timeReportApiClientService.getTimeReportSummary(this.userEid, this.periodEndService.getActivePeriod(), this.supervisorEid, this.viewMode)
      .pipe(map((timeReportSummary: APITimeReportSummary) => {
        const countryKey: string = this.globalCacheService.getUserCountry();
          let periodEnd = this.globalCacheService.getPeriodEnd();
          this.globalCacheService.getTimeReportSettings(periodEnd, this.globalCacheService.getUserCountry()).subscribe(usersetting => 
             this.shouldUseTwoDecimal = usersetting?.shouldUseTwoDecimal )      
          return SummaryServiceMapper.mapAPITimeReportSummaryOutput(timeReportSummary, this.shouldUseTwoDecimal);
      }))
      .pipe(catchError((error: any) => {
        this.logService.logError(`Unable to get your Summary Information. If the problem persists please escalate the issue at myTimeandExpenses Help.`, true);
        return of(new TimeReportSummary());
      }));
    return this.globalCacheService.handleTimeReportSummary(getTimeReportSummaryObservable, this.periodEndService.getActivePeriod(), this.userEid);
  }

  public getTimeReportSummaryShell(timeReportSummaryObservable: Observable<TimeReportSummary>): DataStore<TimeReportSummary> {
    if (!this.globalCacheService.timeReportSummaryStorage || (this.globalCacheService.timeReportSummaryStorage && this.globalCacheService.timeReportSummaryStorage.refresh)) {
      const timeReportSummaryShell: TimeReportSummary = new TimeReportSummary();
      this.globalCacheService.timeReportSummaryStorage = new DataStore(timeReportSummaryShell);
      this.globalCacheService.timeReportSummaryStorage.load(timeReportSummaryObservable);
    }
    return this.globalCacheService.timeReportSummaryStorage;
  }

  public getAnnualChargeabilitySummaryObservable(): Observable<AnnualChargeability> {
    const getAnnualChargeabilitySummaryObservable = this.timeReportApiClientService.getAnnualChargeabilitySummary(this.userEid, this.periodEndService.getActivePeriod(), this.supervisorEid, this.viewMode)
      .pipe(map((timeReportChargeabilityHoursSummaryDto: APIAnnualChargeability) => {
        return SummaryServiceMapper.mapAPIAnnualChargeability(timeReportChargeabilityHoursSummaryDto,this.shouldUseTwoDecimal);
      }))
      .pipe(catchError((error: any) => {
        this.logService.logError(`Unable to get your Annual Chargeability Information. If the problem persists please escalate the issue at myTimeandExpenses Help.`, true);
        return of(new AnnualChargeability());
      }));
    return this.globalCacheService.handleAnnualChargeabilitySummary(getAnnualChargeabilitySummaryObservable, this.periodEndService.getActivePeriod(), this.userEid);
  }
}
