export default class MealClaimed {
    dateClaimed: Date;
    breakfast: boolean;
    dinner: boolean;
    launch: boolean;
    amount: string;
    launchAdjusted: boolean;
    breakfastAdjusted: boolean;
    dinnerAdjusted: boolean;
    IsNewDateAdjustment: boolean;
}
