import { Injectable, OnDestroy } from "@angular/core";
import { environmentsUrls } from "../../../../assets/data/app-config/environmentsUrls.constants";
import { UserService } from "../user/user.service";
import { GlobalCacheService } from '../../../shared/services/cache/global-cache.service';
import { PageGuardEvaluationService } from "@shared/services/page-guard-evaluation/page-guard-evaluation.service";
import { UniversalHeader } from "@shared/constant";
import { of, Subscription } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class OverwriteUniversalHeaderHyperlinksService implements OnDestroy{
  private targetElement: HTMLElement;
  private currentHost: string;
  private currentEnvironmentUrls: any;
  private isEvaluating: boolean = false;
  private userActionMenuSubscription: Subscription;

  constructor(private userService: UserService,
    private evaluationService: PageGuardEvaluationService,
    private globalCacheService: GlobalCacheService) {
    this.currentHost = window.location.host;
    this.currentEnvironmentUrls = environmentsUrls.stages.find(stage => stage.nextUri == this.currentHost);
  }

  public loadScript(url: string, isStage: boolean, id?: string) {
    return new Promise((resolve, reject) => {
      let script = document.createElement('script');
      script.type = 'text/javascript';
      if (id != null && id != undefined) script.id = id;
      script.src = url;
      script.async = false;
      script.defer = true;
      script.onload = () => {
        resolve({ script: name, loaded: true, status: 'Loaded' });
        var universalHeader = document.getElementById('acn-universal-header');
        if (universalHeader != null) {
          universalHeader.addEventListener('acn_header_loaded', (event) => {
            if (isStage) {
              this.replaceAHrefsForElement(UniversalHeader.Selectors.HEADER);
            }
            this.hideActionButtonsForElement();
          });
        }
      };
      script.onerror = (error: any) => resolve({ script: name, loaded: false, status: 'Loaded' });
      document.getElementsByTagName('script')[0].appendChild(script);
    });
  }

  private replaceAHrefsForElement(targetElementSelector: string) {
    setTimeout(() => {
      this.replaceAHrefs(targetElementSelector);
    }, 4000);
  }

  public hideActionButtonsForElement() {
    setTimeout(() => {
      this.hideActionButtons(UniversalHeader.Selectors.ACTION_BUTTONS);
    }, 4000);
  }

  private replaceAHrefs(targetElementSelector: string) {
    this.targetElement = document.querySelector(targetElementSelector);
    if (this.targetElement) {
      if (this.currentEnvironmentUrls) {
        const index = environmentsUrls.stages.indexOf(this.currentEnvironmentUrls);
        environmentsUrls.stages.splice(index, 1);
        if (this.targetElement == null) return;
        let anchors = this.targetElement.querySelectorAll('a');
        anchors.forEach(anchor => {
          environmentsUrls.stages.forEach((stage) => {
            if (anchor.host === stage.nextUri) {
              anchor.host = this.currentEnvironmentUrls.nextUri;
            } else if (anchor.host === stage.legacyUri) {
              anchor.host = this.currentEnvironmentUrls.legacyUri;
            }
          });
        });
      }
    }
  }

  private hideActionButtons(targetElementSelector: string) {
    if (!this.isEvaluating) {
      this.targetElement = document.querySelector(targetElementSelector);
      if (this.targetElement) {
        this.isEvaluating = true;
        let actionMenuCache = null;
        this.userActionMenuSubscription = this.globalCacheService
          .getActionMenu()
          .subscribe((res) => {
            actionMenuCache = res;
            if (!actionMenuCache) {
              this.userService.getUserActionMenu().subscribe({
                next: (response) => {
                  this.getActionMenu(response);
                  this.globalCacheService.setActionMenu(of(response));
                },
                error: () => {
                  this.isEvaluating = false;
                }
              });
              }
              else {
                this.getActionMenu(actionMenuCache);
              }
          });
      }
    }
  }

  private getActionMenu(actionMenuCache : any){
    let anchors = this.targetElement.querySelectorAll('a');
    anchors.forEach(anchor => {
      for (let key in UniversalHeader.GridButtons) {
        if (anchor.href.includes(UniversalHeader.GridButtons[key])) {
          if (this.evaluationService.shouldDenyNavigation(UniversalHeader.GridButtons[key], actionMenuCache)) {
            anchor.style.setProperty('display', 'none', 'important')
          } else {
            anchor.style.setProperty('display', '')
          }
        }
      }
    });
    this.isEvaluating = false;
  }

  ngOnDestroy(): void {
    if(this.userActionMenuSubscription){
      this.userActionMenuSubscription.unsubscribe();
    } 
  }
}