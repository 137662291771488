import DropdownItem from "@shared/models/controls/dropdown/dropdown-item";

export class LocationTwo {
  public locationsTwo: LocationValue[];
  public locationTwoDropdown: DropdownItem[];
  public isReasonForTravelLocationEnable: boolean;

  constructor(){
    
  }
}

export class LocationValue {
  public key: string;
  public description: string;

  constructor(key?: string, description?: string) {
    this.key = key;
    this.description = description;
  }
}
