import { Directive, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { Subject, Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
@Directive({
    selector: '[myteDebounceClick]'
})
export class DebounceClickDirective implements OnInit, OnDestroy {
    @Input() debounceTime: number;
    @Output() debounceClick = new EventEmitter();
    private clicks = new Subject<any>();
    private componentDestroyed: Subject<void> = new Subject();
    constructor() { }

    ngOnInit() {
       this.clicks
            .pipe(
                debounceTime(this.debounceTime))
                .pipe(takeUntil(this.componentDestroyed),
                distinctUntilChanged())
            .subscribe(e =>
                this.debounceClick.emit(e)
            );
    }

    ngOnDestroy() {
        this.componentDestroyed.next();
        this.componentDestroyed.complete();
    }

    @HostListener('click', ['$event'])
    clickEvent(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();
        this.clicks.next(event);
    }
}
