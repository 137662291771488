import { Component } from '@angular/core';
import { TimeEntryTemplateTask } from '@shared/models/time-sheet/time-entry/time-entry-template';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { FocusService } from '../../../../../shared/services/focus/focus.service';

@Component({
  selector: 'myte-time-entry-template-popup-grid-total-render',
  templateUrl: './time-entry-template-popup-grid-total-render.component.html',
  styleUrls: ['./time-entry-template-popup-grid-total-render.component.sass']
})
export class TimeEntryTemplatePopupGridTotalRender implements ICellRendererAngularComp {
  public params: ICellRendererParams<TimeEntryTemplateTask, string> & { value: string, ariaLabel: string, id: string, cellId: string };

  constructor() { }

  public agInit(params: ICellRendererParams<TimeEntryTemplateTask, string> & { value: string, ariaLabel: string, id: string, cellId: string }): void {
    this.params = params;
    FocusService.redirectFocus(params?.eGridCell, params?.id);
  }

  public refresh(params: ICellRendererParams<TimeEntryTemplateTask, string>): boolean {
    return false;
  }
}
