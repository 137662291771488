
import { MyteEvent } from '../myte-event';
import { SubordinatesMode } from '@sharedModels/subordinates/subordinate-mode';
import Subordinate from '@sharedModels/subordinates/subordinate';

export interface SubordinateSelectedEventPayload { 
    subordinate: Subordinate, 
    mode: SubordinatesMode 
}

export class SubordinateSelectedEvent extends MyteEvent<SubordinateSelectedEventPayload> {
    public static eventId = "subordinateSelectedEvent";
    constructor() { super(SubordinateSelectedEvent.eventId); }
}

export class ShowSubordinateDeletePopup extends MyteEvent<Subordinate> {
    public static eventId = "showSubordinateDeletePopup";
    constructor() { super(ShowSubordinateDeletePopup.eventId); }
}

export class UpdateSubordinateList extends MyteEvent<Subordinate> {
    public static eventId = "updateSubordinateList";
    constructor() { super(UpdateSubordinateList.eventId); }
}

export class PeriodChangedEvent extends MyteEvent<Date> {
    public static eventId = "periodChanged";
    constructor() { super(PeriodChangedEvent.eventId); }
}

export class ShowSubmitAllPopup extends MyteEvent<{subordinates:Subordinate[],isReview:boolean}> {
    public static eventId = "showSubmitAllPopup";
    constructor() { super(ShowSubmitAllPopup.eventId); }
}

export class SubordinateListNeedCalculateHeight extends MyteEvent<boolean>{
    public static eventId = "SubordinateListNeedCalculateHeight";
    constructor() { super(SubordinateListNeedCalculateHeight.eventId) }
  }
  