import { ValidationErrorOutPut, ValidationErrorsOutPut } from '@sharedClients/timereport-api-client';
import { LBDChangeReason } from '../../../shared/models/locations';
import { PPAReason } from '@sharedModels/adjustments/ppareason'
export class TimeReportValidationErrors {
    totalHoursValidationErrorOutPut?: ValidationErrorOutPut[];
    ispassed: boolean;
    expenseHasFederalWBSInd: boolean
    federalWBSList: string[];
    warningMessage: Array<ValidationErrorOutPut> = [];
    messagesAfterSubmission: Array<string> = [];
    validWarningMessage: Array<any> = [];
    activityErrors: Array<ValidationErrorsOutPut> = [];
    locationByDayReason: LBDChangeReason;
    priorPeriodAdjustmentReason: PPAReason;
    federalErrorSources: string[] = [];
}