import { Component } from '@angular/core';
import { ExpenseFieldWithValidationResult } from '@shared/clients/expense-api-client';
import ExpenseGridItem from '@shared/models/expense/expense-grid-item';
import { GlobalCacheService } from '@shared/services/cache/global-cache.service';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import moment from 'moment';
import { ExpenseDate } from '../../../../shared/models/expense/expense-date';
import { ActionType } from '@shared/services/store/shared/action-type';
import { ActionStoreService } from '@shared/services/store/action-store.service';
import { ReceiptsService } from '@shared/services/receipts/receipts.service';
import { ReceiptRequirementTypes } from '@shared/models/expense/receipt/expense-receipt-requirement';
import { ExpenseGridEventsService } from '../../../shared/services/events/expense-grid-events.service';
import { ExpenseGridService } from '../../../shared/services/grids/expense-grid.service';
import { TimeReportStoreService } from '@shared/services/store/time-report-store.service';

@Component({
  selector: 'myte-expense-grid-date-renderer',
  templateUrl: './expense-grid-date-renderer.component.html',
  styleUrls: ['./expense-grid-date-renderer.component.sass']
})

export class ExpenseGridDateRendererComponent implements ICellRendererAngularComp {
  public params: ICellRendererParams<ExpenseGridItem, ExpenseFieldWithValidationResult>;
  public value: ExpenseDate;
  public isValid: boolean;
  public toolTip: string;
  public displayValue: string;
  public isGroupByTrip: boolean;
  public editable: boolean;
  public periodEnd: Date;
  public isGCUser: boolean;
  public timeReportStatus: string;

  constructor(
    public cacheService: GlobalCacheService,
    public actionService: ActionStoreService,
    protected receiptsService: ReceiptsService,
    protected expenseGridService: ExpenseGridService,
    protected expenseGridEventService: ExpenseGridEventsService,
    public timeReportStoreService: TimeReportStoreService
  ) {
  }

  public agInit(params: ICellRendererParams<ExpenseGridItem, ExpenseFieldWithValidationResult>): void {
    this.params = params;
    this.editable = this.params.context?.expenseGridParamsService?.isEditable;
    this.isGroupByTrip = this.params.context?.expenseGridParamsService?.isGroupByTrip;
    this.timeReportStatus = this.params.context?.expenseGridParamsService?.timeReportStatus;
    var isFrequentTrip = params.data.frequentTrip?.id != null;
    var isExpense = params.data.id != null;
    let expenseDate: ExpenseFieldWithValidationResult;

    if (params.colDef.colId == 'toDate') {
      expenseDate = !isExpense && isFrequentTrip ? (params.data.to.value != null && new Date(params.data.to.value) > new Date(params.data.from.value) ? params.data.to : params.data.from) : params.data.to;
    }
    else {
      expenseDate = params.data.from;
    }
    this.value = new ExpenseDate(expenseDate.value, expenseDate.isValid, expenseDate.validationErrors);
    this.displayValue = this.value && this.value.value ? moment(new Date(this.value.value)).format('DD MMM') : '-';
    if (params.context?.expenseGridParamsService?.isGroupByTrip && (params.colDef.colId == 'toDate' || params.colDef.colId == "fromDate") && !isFrequentTrip && !isExpense) {
      this.displayValue = '';
    }
    this.isValid = this.value.isValid ?? this.value.isValid;

    if(this.timeReportStatus != undefined) {
      this.isGCUser = this.params.context?.expenseGridParamsService?.shouldShowReceiptType;
    }
  }

  public refresh(): boolean {
    return false;
  }

  public invokeParentMethod(): void {
    this.params.context.componentParent.methodFromParent(`Row: ${this.params.node.rowIndex}, Col: ${this.params.colDef.headerName}`);
  }

  public dropHandler(event): void {
    if (!this.isGCUser) {
        if (!this.editable || this.isGroupByTrip) return;

        event.preventDefault();
        event.stopPropagation();
        this.removeRowClass(event, 'row-file-dragged');
        event.dataTransfer.effectAllowed = 'copyMove';

        this.expenseGridEventService.dispatchShowLoadingOverlayEvent(true);
        if (this.params?.data?.type.value === 'Salary Supplement' && this.params.data.receipt.type != ReceiptRequirementTypes.Both) {
            this.actionService.dispatchAction(ActionType.LOAD_EXPENSES, {
                shouldInitialize: true,
                periodEnd: this.periodEnd
            });
            return;
        }
        this.receiptsService.attachReceipts(event.dataTransfer.files, this.params.data.id, '')
            .subscribe(() => {
                this.actionService.dispatchAction(ActionType.LOAD_EXPENSES, {
                    shouldInitialize: true,
                    periodEnd: this.periodEnd
                });
            });
    }
}

public dragEnterHandler(event): boolean {
    if (!this.editable || this.isGroupByTrip) return;
    event.preventDefault();
    event.stopPropagation();
    return true;
}

public dragOverHandler(event): boolean {
    if (!this.editable || this.isGroupByTrip) return;
    event.preventDefault();
    event.stopPropagation();
    this.setRowClass(event, 'row-file-dragged');
    return true;
}

public dragLeaveHandler(event): boolean {
    if (!this.editable || this.isGroupByTrip) return;
    event.preventDefault();
    event.stopPropagation();
    this.removeRowClass(event, 'row-file-dragged');
    return true;
}

private setRowClass(event, className: string): void {
    const row = this.getRow(event);
    if (row && !row.classList.contains(className)) {
        row.classList.add(className);
    }
}

private removeRowClass(event, className: string): void {
    const row = this.getRow(event);
    if (row)
        row.classList.remove(className);
}

private getRow = (event) => {
    const path = event.path || (event.composedPath && event.composedPath()) || this.customComposedPath(event);
    if (path) {
        return path.find(p =>
            p.attributes && p.attributes.role && p.attributes.role.value == 'row' && p.attributes['row-index']);
    }
    return false;
}

private customComposedPath(event): any[] {
    let path = [];
    let currentElement = event.target;

    while (currentElement) {
        path.push(currentElement);
        if (currentElement.tagName === 'HTML') {
            path.push(document);
            path.push(window);
            return path;
        }
        currentElement = currentElement.parentElement;
    }
    return path;
}

}