import { Component } from '@angular/core';
import { ExpenseMultipleTaxiGrid, ExpenseMultipleTaxiGridAssignments } from '@shared/models/expense/multipleTaxi/expense-multiple-grid';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'myte-taxi-assignment-render',
  templateUrl: './taxi-assignment-render.component.html',
  styleUrls: ['./taxi-assignment-render.component.sass']
})
export class TaxiAssignmentRenderComponent implements ICellRendererAngularComp {
  public value: string;
  public selectedAssignments: ExpenseMultipleTaxiGridAssignments;

  constructor() { }

  public agInit(params: ICellRendererParams<ExpenseMultipleTaxiGrid, ExpenseMultipleTaxiGridAssignments>): void {
    this.selectedAssignments = params.value;
    this.value = params.value.selectedExpenseCodeDescription ? params.value.selectedExpenseCodeDescription : '--Select one--';
  }

  public refresh(params: ICellRendererParams<ExpenseMultipleTaxiGrid, ExpenseMultipleTaxiGridAssignments>): boolean {
    return false;
  }
}