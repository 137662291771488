import { Component, OnDestroy, OnInit } from "@angular/core";
import { UserRole } from "@shared/models/user/user-roles";
import { Subscription } from "rxjs";
import { auditeeSideBar } from "./../../../shared/constant";
import { GlobalCacheService } from "@shared/services/cache/global-cache.service";
import { UserService } from "@shared/services/user/user.service";
import { AuditService } from "./../../../myte-audit/shared/services/audit/myte-audit.service";
import { SubordinatesMode } from "@shared/models/subordinates/subordinate-mode";

@Component({
  selector: "myte-auditee-sidebar",
  templateUrl: "./myte-auditee-sidebar.component.html",
  styleUrls: ["./myte-auditee-sidebar.component.sass"],
})
export class MyteAuditeeSidebarComponent implements OnInit, OnDestroy {
  public userRole: UserRole;
  public showAuditeeDetails = false;
  public auditeeSideBar = auditeeSideBar;
  public auditeeDetails: any;
  public currentPeriod: Date;
  public showErr: boolean = false
  public refreshStatusSubscription: Subscription;
  
  constructor(
    public globalCacheService: GlobalCacheService,
    public userService: UserService,
    public auditService: AuditService
  ) { }

  public ngOnInit(): void {
    if (this.globalCacheService.getInfoFromSessionStorage(this.globalCacheService.auditInfoKey)) {
      this.currentPeriod = new Date(
        this.globalCacheService.getInfoFromSessionStorage(this.globalCacheService.auditInfoKey)?.trPeriod
      );
      this.getData();
    } else {
      this.showErr = true;
    }
    this.globalCacheService.setRefreshAuditStatus(false);
    this.refreshAuditStatus();
  }
  getData() {
    if (
      this.globalCacheService.getCurrentSubordinate()?.enterpriseId !=
      this.globalCacheService.getInfoFromSessionStorage(
        this.globalCacheService.auditeeInfoKey
      )?.enterpriseId
    ) {
      const data = {
        auditId: this.globalCacheService.getInfoFromSessionStorage(
          this.globalCacheService.auditInfoKey
        )?.auditID,
        enterpriseId:
          this.globalCacheService.getCurrentSubordinate()?.enterpriseId,
        date: this.currentPeriod,
        loggedInUser: this.userService.getUser()?.enterpriseId,
        viewMode: SubordinatesMode.Audit,
      };
      this.auditService
        .getAuditeeInfo(
          data.auditId,
          data.enterpriseId,
          data.date,
          data.loggedInUser,
          data.viewMode
        )
        .subscribe((res) => {
          this.auditeeDetails = res;
          this.globalCacheService.setDataToSessionStorage(
            this.globalCacheService.auditeeInfoKey,
            res
          );
          this.auditService.setAuditStatus(true);
        });
    } else {
      this.auditeeDetails = this.globalCacheService.getInfoFromSessionStorage(
        this.globalCacheService.auditeeInfoKey
      );
    }
  }

  /**
   * Refresh the audit status
   */
  refreshAuditStatus() {
    this.refreshStatusSubscription = this.globalCacheService.getRefreshAuditStatus().subscribe(status => {
      if (status) {
        this.globalCacheService.resetSessionStorage(this.globalCacheService.auditeeInfoKey);
        this.getData();
      }
      return
    })
  }
  ngOnDestroy(): void {
    this.refreshStatusSubscription.unsubscribe();
  }
}
