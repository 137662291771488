import { LocalDateFormatPipe } from '../pipes/dateformated-local.pipe';
import { NumberFormatPipe } from '../pipes/number-format.pipe';

export abstract class LocalizeFormatUtils {
    public static localizeTooltip(tooltip: string,hasdatevalue=true,hasnumbervalue:boolean=false, isThousandSeparatorWithComma?: boolean): string {
      if(!tooltip || tooltip=="" || tooltip==" ")
        return tooltip;
        let spliceTooltip =  new Array();
        let splitedTooltips = !isThousandSeparatorWithComma && tooltip.split(",").length > 1 ? tooltip.split(","):new Array(tooltip);
        splitedTooltips.forEach(splitedTooitip => {
          if(hasdatevalue && splitedTooitip)
          splitedTooitip=this.replaceDate(splitedTooitip);
          if(hasnumbervalue && splitedTooitip)
          splitedTooitip=this.replaceNumber(splitedTooitip);
          spliceTooltip.push(splitedTooitip);
        });    
        return spliceTooltip.join(",");
      }

      private static replaceDate(tooltip: string):string
      {
            let datetolocalize=this.getDateForTooltip(tooltip);
            let dateformated=new LocalDateFormatPipe().transform(datetolocalize,"shortnoseconds");
            tooltip=tooltip.replace(datetolocalize,dateformated);
            return tooltip;
      }
      private static replaceNumber(tooltip: string):string
      {
        let numbertolocalize=this.getNumberForTooltip(tooltip);
        if(tooltip==numbertolocalize)
        return tooltip;
        let numberformated=new NumberFormatPipe().transform(numbertolocalize.replace(",",""),2,2);
        if ((numberformated != undefined) && (numberformated != null) && (numberformated != 'NaN'))
          tooltip=tooltip.replace(numbertolocalize," "+numberformated);
        return tooltip;
      }

      private static getNumberForTooltip(tooltip: string):string
      {
        let lastindexnumber= tooltip.lastIndexOf(".");
        if(lastindexnumber==-1)
            return tooltip;
        
        if(lastindexnumber==tooltip.length-1)
        {
          let auxfinalpoint=tooltip.substring(0,lastindexnumber);
          if(auxfinalpoint.lastIndexOf(".")==-1)
              return tooltip;
              tooltip=auxfinalpoint;
              lastindexnumber= tooltip.lastIndexOf(".");
        }
        let str=tooltip.substring(0,lastindexnumber);
        let chararray=str.split("");
        let validindex=0;
        for(let i=(lastindexnumber-1); i!=0; i--)
        {   
            if(chararray[i]==" ")
                break;
            if(!isNaN(Number(chararray[i])) || chararray[i]==",")
                validindex++;
                else
                break;
        }
        if(validindex==0)
        return tooltip;
        return tooltip.substring(lastindexnumber-validindex,lastindexnumber+3);
      }

      private static getDateForTooltip(tooltip: string):string
      {
        let indexofhour12=Math.max(tooltip.indexOf("AM"),tooltip.indexOf("PM"));
        if(indexofhour12!=-1)
          return tooltip.trim().substring(0,indexofhour12+2).trim();
        return "";
     }

     public static formatDate(date:Date) : string
     {
        let dateformated=new LocalDateFormatPipe().transform(date,"full");
        return dateformated;
     }


     public static getDecimalSeparator = () => (1.1).toLocaleString(navigator.language).substring(1, 2);
  
     public static getGroupSeparator() {
    let separator = (1000).toLocaleString(navigator.language).substring(1, 2);
    if (isNaN(Number(separator))) return separator;
    separator = (10000).toLocaleString(navigator.language).substring(2, 3);
    if (isNaN(Number(separator))) return separator;
    return '';
  }

  public static parseToLocalString(precision: string, value: string) {
    if(isNaN(Number(value)) || value === null || value === undefined || value === '') return value;
    let fraction = precision.split('-');
    value = Number(value).toLocaleString(navigator.language, { minimumFractionDigits: Number(fraction[0]), maximumFractionDigits: Number(fraction[1])});
    return value;
  }

}