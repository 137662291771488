export class ResidentLocationList {
    public value: string;
    public name: string;
    constructor(
        value?: string,
        name?: string
        ) {
       this.value = value;
       this.name = name;
    }
}