import { AddedProjectOutput, ClientSearchDto } from '@shared/clients/chargecode-api-client';
import { ShellModel } from '../../../app-shell/data-store';
import { ChargeCodeInfo } from './charge-code-info';
import { ChargeCodeRelated } from './charge-code-related';
import DropdownItem from '../controls/dropdown/dropdown-item';

export class ChargeCodes extends ShellModel {
  public isEditable: boolean;
  public projectInfo: Array<ChargeCodeInfo>;
  public filteredProjectInfo: Array<ChargeCodeInfo>;
  public addedProjectOutput: AddedProjectOutput;
  public selectedChargeCodeRelated: ChargeCodeRelated;
  public isAddEnabled: boolean;
  public isDeleteEnabled: boolean;
  public clientList: Array<ClientSearchDto>;
  public selectedClient: ClientSearchDto;
  public opportunityIdSearch: string;
  public isBDAddEnabled: boolean;
  public isBDSearchEnabled: boolean;
  public chargeCodeRelated: Array<ChargeCodeRelated>;
  public isRelatedAddEnabled: boolean;
  public chargeCodeViewType: Array<DropdownItem> = [
    { key: 'all', value: 'All', isValid: true, tooltip: 'This view will display all assignments' },
    { key: 'chargeable', value: 'Chargeable', isValid: true, tooltip: 'This view will display all chargeable assignments' },
    { key: 'business', value: 'Business Development', isValid: true, tooltip: 'This view will display all business development assignments' },
    { key: 'training', value: 'Training/Recruiting/Absence', isValid: true, tooltip: 'This view will display all assignments related to training, recruiting, and approved absence' },
    { key: 'other', value: 'Other', isValid: true, tooltip: 'This view will display all other assignments' }
  ];
  public bdLookupSearchType: Array<DropdownItem> = [
    { key: 'opportunityPursuit', value: 'Opportunity Pursuit', isValid: true },
    { key: 'clientRelationshipDevelopment', value: 'Client Relationship Development', isValid: true }
  ];
  public chargeCodeDetailsField = [
    { name: 'Client Name', key: 'clientName', value: '' },
    { name: 'Contract Name', key: 'contractName', value: '' },
    { name: 'Description', key: 'description', value: '' },
    { name: 'Charge Code', key: 'chargeCode', value: '' },
    { name: 'Type', key: 'type', value: '' },
    { name: 'Parent WBS', key: 'parentWbs', value: '', tooltip: 'Cost Collectors exist as a way of breaking down a WBS into lower levels. Each Cost Collector is linked to a single Parent WBS; however, that Parent WBS can have multiple Cost Collectors. If a Cost Collector exists for a WBS, that WBS cannot be used to charge time or expenses.' },
    { name: 'Business Activity Level 1', key: 'businessLevel1', value: '' },
    { name: 'Business Activity Level 2', key: 'businessLevel2', value: '' },
    { name: 'Business Activity Level 3', key: 'businessLevel3', value: '' },
    { name: 'Status', key: 'status', value: '' },
    { name: 'Can Charge Time', key: 'canChargeTime', value: '' },
    { name: 'Can Charge Expense', key: 'canChargeExpenses', value: '' },
    { name: 'Display', key: 'display', value: '', tooltip: 'This field indicates whether this Assignment will display in the Assignment dropdown list for time, expenses, time entry template, and expense splits.' },
    { name: 'Country/Region', key: 'country', value: '' }
  ];

  constructor(isEditable?: boolean, projectInfo?: Array<ChargeCodeInfo>, filteredProjectInfo?: Array<ChargeCodeInfo>, addedProjectOutput?: AddedProjectOutput,
    selectedChargeCodeRelated?: ChargeCodeRelated, isAddEnabled?: boolean, isDeleteEnabled?: boolean, clientList?: Array<ClientSearchDto>, 
    selectedClient?: ClientSearchDto, opportunityIdSearch?: string, isBDAddEnabled?: boolean, isBDSearchEnabled?: boolean, isRelatedAddEnabled?: boolean,
    chargeCodeRelated?: Array<ChargeCodeRelated>) {
    super();

    this.isEditable = isEditable;
    this.projectInfo = projectInfo ?? new Array(20).fill(new ChargeCodeInfo());
    this.filteredProjectInfo = filteredProjectInfo ?? new Array(20).fill(new ChargeCodeInfo());
    this.addedProjectOutput = addedProjectOutput;
    this.selectedChargeCodeRelated = selectedChargeCodeRelated;
    this.isAddEnabled = isAddEnabled ?? false;
    this.isDeleteEnabled = isDeleteEnabled ?? false;
    this.clientList = clientList ?? new Array(0).fill(new ClientSearchDto());
    this.selectedClient = selectedClient ?? new ClientSearchDto();
    this.opportunityIdSearch = opportunityIdSearch;
    this.isBDAddEnabled = isBDAddEnabled ?? false;
    this.isBDSearchEnabled = isBDSearchEnabled ?? false;
    this.chargeCodeRelated = chargeCodeRelated ?? new Array(0).fill(new ChargeCodeRelated());
    this.isRelatedAddEnabled = isRelatedAddEnabled ?? false;
  }
}

export enum ChargeCodeUsedFor {
  Time = 0,
  Expense = 1,
  Both = 2,
  None = 3
}

