import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class RebarInterceptor implements HttpInterceptor {
  private storage: any;

  constructor() {
    this.storage = sessionStorage;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (environment.name === 'Local') {
      let authReq = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + this.getToken())
      });
      return next.handle(authReq);
    }
    return next.handle(req);
  }

  private getToken(): string {
    const access_token = this.storage.getItem('access_token');
    return access_token && typeof access_token !== 'undefined'
      ? access_token
      : null;
  }
}
