import { LocationInformation } from '../../locations/locations-by-day/location-information';

export class TimeEntryTemplate {
    public tasks: TimeEntryTemplateTask[] = [];
    public locationsByWeek: LocationInformation[] = new Array(7);
    public assignements: TimeEntryTemplateAssignment[] = [];
    public totals = new Array();
    public enterpriseId : string;
    public useTimesheetTemplate: boolean;

    public recalculateTotals() {
        if (this.tasks.length == 0) return;
        this.totals = new Array();
        this.tasks[0].charges.forEach(() => this.totals.push(0));

        for (let i = 0; i < this.tasks.length; i++) {
            for (let j = 0; j < this.tasks[i].charges.length; j++) {
                this.totals[j] += this.tasks[i].charges[j];
            }
        }
    }

    public createEmptyTask(): void {
        let task = new TimeEntryTemplateTask();
        task.assignment = new TimeEntryTemplateAssignment();
        task.assignment.isEmpty = true;
        this.tasks.push(task);
    }
}

export class TimeEntryTemplateAssignment {
    public code: string;
    public description: string;
    public isEmpty: boolean;
    public masterServicesAgreement: string;
    public isValid: boolean;
    public selected: boolean;
    public hasError: boolean = false;
    public tooltip: string;
    public get displayName(): string {
        return this.code ? `${this.description} (${this.code})` : '';
    }
}

export class TimeEntryTemplateTask {
    public assignment: TimeEntryTemplateAssignment;
    public charges: number[] = Object.seal([0, 0, 0, 0, 0, 0, 0]);
}