export class TimeReportHistoryLog {
    public status: string;
    public action: string;
    public date: Date;
    public eid: string;
    public comments: string;

    constructor(status?: string, action?: string, date?: Date, eid?: string, comments?: string) {
        this.status = status;
        this.action = action;
        this.date = date;
        this.eid = eid;
        this.comments = comments;
    }
}

export enum TimeReportHistoryLogEID {
    AuditTeam = 'Audit Team',
    AutomaticReverse = 'Automatic Reversed',
    SystemGenerated = 'System Generated',
    MyTEAdmin = 'myTE Admin',
    PMTIUser = 'PMTI user'
}