import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UserService } from '../../../shared/services/user/user.service';
import { GlobalEventsService } from '../../../shared/services/events/global-events.service';
import { GlobalCacheService } from '../../../shared/services/cache/global-cache.service';
import { Router, NavigationExtras } from '@angular/router';
import { SubordinatesMode } from '../../../shared/models/subordinates/subordinate-mode';
import ActionMenu from '../../../shared/models/action-menu';
import { UserRole } from '../../../shared/models/user/user-roles';
import { AppConfigService } from '../../../auth/services/app-config.service';
import { TimeReportUtils } from '../../../shared/utils/timeReport.utils';
import { of } from 'rxjs';

@Component({
  selector: 'myte-page-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass']
})
export class HeaderComponent implements OnInit {
  @Output() onLoadingEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  //public isShowPersonalModal: boolean;
  public userRole: any;
  private appConfigData: any;
  constructor(private userService: UserService,
              private router: Router,
              private globalEventsService: GlobalEventsService,
              private globalCacheService: GlobalCacheService,
              private appConfigService: AppConfigService) {
                this.appConfigData = this.appConfigService.getAppConfig;
              }

  public ngOnInit(): void {
    const intervalId = setInterval(() => {
      let personElements = document.getElementsByClassName('acn-header-profile');
      if (this.isElementExist(personElements)) {
        //this.overwriteUH();
        clearInterval(intervalId);
      }
    }, 3000);

    setTimeout(() => {
      clearInterval(intervalId);
    }, 60000);
  }

  public overwriteUH() {
    let personElements = document.getElementsByClassName('acn-header-profile');

    if (this.isElementExist(personElements)) {
      this.setProfileEvent(personElements);
    }

    this.userService.getUserActionMenu().subscribe(res => {
      if (typeof res == 'undefined') return;

      // if(res?.admin || res?.review || res?.approve || res?.represent){
      //   this.generateSubrodinateMenu(res);
      // }
      this.globalCacheService.setUserRole(UserRole.getValueByString(res.userRole));
      this.overwriteActionMenu(res);
    });
  }

  // public onShowPersonalPopup(event): void {
  //   this.isShowPersonalModal = event;
  // }

  public showSubordinatesList(viewMode: SubordinatesMode): void {
    const currentViewMode = this.globalCacheService.getSubordinateMode();
    if (((viewMode != SubordinatesMode.None) || (currentViewMode != SubordinatesMode.None)) && (viewMode != currentViewMode)) {
      this.globalCacheService.clearBufferCache();
      this.globalCacheService.resetSubordinatesList();
      this.globalCacheService.clearCurrentFilterStatusSubmitGroup();
      let periodEnd = this.globalCacheService.getPeriodEnd();
      let timeReport = TimeReportUtils.createTimeReport(periodEnd, "", viewMode);
      this.globalCacheService.handleResetTimeReport(periodEnd);
      this.globalCacheService.handleTimeReport(periodEnd, of(timeReport));
    }
    this.globalCacheService.setCurrentSubordinate(null);
    this.globalEventsService.dispatchSubordinateSelectedEvent({ subordinate: null, mode: SubordinatesMode.None });
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    if(!this.router.url.includes("/expenses/view/"))
    this.router.navigate([this.router.url.split('?').shift()], { queryParams: { periodEnd: encodeURIComponent(btoa(this.globalCacheService.getPeriodEnd().toISOStringMyTE())), viewMode: encodeURIComponent(btoa(viewMode)) } } as NavigationExtras);
    else
    this.router.navigate(["/expenses"], { queryParams: { periodEnd: encodeURIComponent(btoa(this.globalCacheService.getPeriodEnd().toISOStringMyTE())), viewMode: encodeURIComponent(btoa(viewMode)) } } as NavigationExtras);
    this.updateSubordinateTitleMenu(viewMode);
  }

  private overwriteActionMenu(res: ActionMenu): void {
    try {
      let menuElements = document.getElementsByClassName('acn-header-settings-dd')[0].getElementsByTagName('A');
      let arrayElements: [] = Array.prototype.slice.call(menuElements).filter(x => !x.innerHTML.includes('Expense Api Version'));

      if (
        typeof arrayElements !== 'undefined' &&
        arrayElements &&
        arrayElements.length > 0
      ) {
        arrayElements.forEach(element => {
          const actionDom = element as HTMLElement;

          if (res[actionDom.innerHTML.toLowerCase()] === false) {
            actionDom.setAttribute('hidden', 'hidden');
          }

          if(this.appConfigData.AdditionalConfig.enableSubordinateModes && (
            actionDom.innerHTML === "Review"
            || actionDom.innerHTML === "Approve"
            || actionDom.innerHTML === "Represent"
          )) {
            actionDom.setAttribute('hidden', 'hidden');
          }

          if (actionDom.innerHTML === 'Audit') {
            if (res.userRole === 'AuditLead') {
              let auditUrl = actionDom.getAttribute('href');
              actionDom.setAttribute('href', auditUrl.replace('PostPayAudit', 'MyEscalatedExpenses'));
            };
          }

          switch(actionDom.innerHTML)
          {
            case 'Record': actionDom.setAttribute('title', 'Record your Time and Expenses');break;
            case 'Review': actionDom.setAttribute('title', 'Review time and expenses reports');break;
            case 'Approve': actionDom.setAttribute('title', 'Approve time and expenses reports');break;
            case 'Report': actionDom.setAttribute('title', 'Access Reports');break;
            case 'Represent': actionDom.setAttribute('title', 'Record time and expenses for another person');break;
            case 'Audit': actionDom.setAttribute('title', 'Audit Time and Expense Reports');break;
            case 'Authorize': actionDom.setAttribute('title', 'Authorize employees to use a charge code');break;
            case 'Admin': actionDom.setAttribute('title', 'Perform system administration');break;
            case 'Log Out': actionDom.setAttribute('title', 'Log Out');break;
          }
        });
      }
    } catch (error) {
      console.error("Unable to overwrite Action Menu.");
      console.error(error);
    }
  }

  private generateSubrodinateMenu(am: ActionMenu): void {
    if (!this.appConfigData.AdditionalConfig.enableSubordinateModes) return;

    try {
      const header = document.getElementsByClassName('acn-header acn-greedy-nav')[0];
      header.classList.add("myte-uh");
      const linkContainer = document.createElement('div');
      linkContainer.classList.add("myte-uh-links-container");
      const linkContainerTitle = document.createElement('span');
      linkContainerTitle.id = "myte-uh-links-title";
      linkContainerTitle.innerHTML = "Record my Time";
      linkContainer.tabIndex=0;
      linkContainer.onclick = () => {
          const e = document.getElementById('myte-options-container');
          if (e.style.display == 'none' ||  e.style.display == "") {
              e.style.display = 'flex';
              linkContainer.style.backgroundColor = "white";
              linkContainer.style.color = "black";
          } else {
              e.style.display = 'none';
              linkContainer.style.backgroundColor = "";
              linkContainer.style.color = "";
          }
      }
      linkContainer.onblur=()=>{
        const e = document.getElementById('myte-options-container');
        e.style.display = 'none';
        linkContainer.style.backgroundColor = "";
        linkContainer.style.color = "";
      }
      const linkContainerArrow = document.createElement('span');
      linkContainerArrow.classList.add("material-icons");
      linkContainerArrow.innerHTML = "keyboard_arrow_down";

      const optionsContainer = document.createElement('div');
      optionsContainer.id = 'myte-options-container'
      optionsContainer.classList.add('myte-options-list');
      const optionRecordTime = document.createElement('div');
      optionRecordTime.innerHTML = "Record my Time";
      optionRecordTime.classList.add('myte-option');
      optionRecordTime.onclick = () => this.showSubordinatesList(SubordinatesMode.None);
      optionsContainer.appendChild(optionRecordTime);

      if (am.review) {
        const optionReview = document.createElement('div');
        optionReview.innerHTML = "Review Another Person";
        optionReview.classList.add('myte-option');
        optionReview.onclick = () => this.showSubordinatesList(SubordinatesMode.Supervisor);
        optionsContainer.appendChild(optionReview);
      }

      if (am.approve) {
        const optionApprove = document.createElement('div');
        optionApprove.innerHTML = "Approve Another Person";
        optionApprove.classList.add('myte-option');
        optionApprove.onclick = () => this.showSubordinatesList(SubordinatesMode.Approver);
        optionsContainer.appendChild(optionApprove);
      }

      if (am.represent) {
        const optionDelegate = document.createElement('div');
        optionDelegate.innerHTML = "Represent Another Person";
        optionDelegate.classList.add('myte-option');
        optionDelegate.onclick = () => this.showSubordinatesList(SubordinatesMode.Delegate);
        optionsContainer.appendChild(optionDelegate);
      }

      linkContainer.appendChild(optionsContainer);
      linkContainer.appendChild(linkContainerTitle);
      linkContainer.appendChild(linkContainerArrow);
      header.insertBefore(linkContainer, header.children[2]);

      const menuContainter = header.children[header.childElementCount - 1];
      menuContainter.classList.add("myte-uh-menu-container");

      this.updateSubordinateTitleMenu(this.globalCacheService.getSubordinateMode());
    } catch (error) {
      console.error("Unable to create subordinate Menu.");
      console.error(error);
    }
  }

  private updateSubordinateTitleMenu(mode: SubordinatesMode) {
    switch (mode) {
      case SubordinatesMode.Approver:
        document.getElementById("myte-uh-links-title").innerHTML = "Approve Another Person";
        break;

      case SubordinatesMode.Delegate:
        document.getElementById("myte-uh-links-title").innerHTML = "Represent Another Person";
        break;

      case SubordinatesMode.Supervisor:
        document.getElementById("myte-uh-links-title").innerHTML = "Review Another Person";
        break;

      default:
        document.getElementById("myte-uh-links-title").innerHTML = "Record my Time";
        break;
    }

  }

  private setProfileEvent(personElements) {
    (personElements[0] as any).removeAllListeners();
    personElements[0].addEventListener('click', function() {
      var url = (this).getAttribute("data-url");
      window.open(url);
    });
  }

  private isElementExist(ele) {
    return typeof ele !== 'undefined' &&
      ele &&
      ele.length > 0;
  }
}
