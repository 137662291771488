import { GlobalCacheService } from '../../../shared/services/cache/global-cache.service';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { AppConfigService } from '../../../auth/services/app-config.service';
import { ReportService } from '../../../shared/services/report/report.service';
import { MyteAutoSaveService } from '../../../shared/services/autosave/myte-auto-save.service';
import { of, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GlobalEventsService } from '../../../shared/services/events/global-events.service';
import { SubordinatesMode } from '../../../shared/models/subordinates/subordinate-mode';
import { SubordinatesService } from '../../../shared/services/subordinates/subordinates.service';
import Subordinate from '../../../shared/models/subordinates/subordinate';
import { AuthService } from '../../../auth/services/auth.service';
import { environment } from '../../../../environments/environment';
import { TimeReportStatusCode } from '../../../shared/models/time-report/time-report-status-code';
import { UserProfileService } from '../../../shared/services/user/user-profile.service';
import UserPreference from '../../../shared/models/userPreference';
import { AuditService } from './../../../myte-audit/shared/services/audit/myte-audit.service';
import { TimeReportStoreService } from '@shared/services/store/time-report-store.service';

@Component({
    selector: 'myte-tab-menu',
    templateUrl: './tab-menu.component.html',
    styleUrls: ['./tab-menu.component.sass']
})
export class TabMenuComponent implements OnInit, OnDestroy {
    @Input() public timeReportStatus: string;
    public inRootPage: boolean;
    public timeUrl: string;
    public locationUrl: string;
    public assignmentUrl: string;
    public adjustmentUrl: string;
    public summaryUrl: string;
    public compensatoryUrl: string;
    public locationQuestionnaireUrl: string;
    public inpatExpenseUrl: string;
    public periodEnd: string;
    public showSpinner = false;
    public showCarInformationTab: boolean;
    public showCompensatoryTab: boolean;
    public showLocationQuestionnaireTab: boolean;
    public isApprovalRequested: boolean;
    public hasApprovers: boolean;
    public dictionaryTabs = new Map<string, boolean>();
    public periodEndDate: Date;
    public periodEndBase64: string;
    public viewMode: SubordinatesMode;
    public viewModeBase64: string;
    public subordinate: Subordinate;
    public expenseTabSupplementText: string;
    private appConfigData: any;
    public islocal = false;
    private componentDestroyed: Subject<void> = new Subject();
    public hasQuestionnaireError = false;
    public shouldPreventAutoSave = false;  
    public emailUserName= this.cacheService.getRevieweeName();
    public emailDate = this.cacheService.getEmailDate();
    public isTimeTab = false;
    public userActiveDate: Date;
    public isExpensesTab = false;
    public showActivityTab: boolean = false;
    public isAuditmode: boolean=false;
    public auditStatus: string='';
    public auditStatusSubscription : Subscription;
    constructor(public router: Router,
        public route: ActivatedRoute,
        public cacheService: GlobalCacheService,
        private reportService: ReportService,
        private globalEventsService: GlobalEventsService,
        private autoSaveService: MyteAutoSaveService,
        private subordinatesService: SubordinatesService,
        private autherService: AuthService,
        private appConfigService: AppConfigService,
        private userProfileService: UserProfileService,
        private auditService: AuditService,
        private timeReportStoreService: TimeReportStoreService
        ) {
            this.isExpensesTab = this.router.url.split('?')[0].split('/')[1] == 'expenses';
            this.inRootPage = router.url.split('/').length == 2 && router.url.split('/')[1].split('?')[0] != 'preferences';
            this.appConfigData = this.appConfigService.getAppConfig;
        }

    public ngOnInit(): void {
        this.timeReportStoreService.timeReport$
        .pipe(takeUntil(this.componentDestroyed))
        .subscribe(timeReport => {
            this.timeReportStatus = timeReport.status;
            this.showCarInformationTab = timeReport.timeReportSettings.showCarPlan;
            this.showCompensatoryTab = timeReport.timeReportSettings.showCompensatoryTab;
            this.showLocationQuestionnaireTab = timeReport.timeReportSettings.showLocationQuestionnaireTab;
            this.isApprovalRequested = timeReport.timeReportSettings.isApprovalRequested;
            this.hasApprovers = timeReport.timeReportSettings.hasApprovers;
            this.expenseTabSupplementText = timeReport.timeReportSettings.salarySupplements != null && timeReport.timeReportSettings.salarySupplements.length != 0 ? 'Expenses and Supplements' : 'Expenses';
            this.userActiveDate = timeReport.timeReportSettings.userActiveDate;
            this.showActivityTab = timeReport.timeReportSettings.shouldEnableActivityBool;
        });

        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.inRootPage = event.url.split('/').length == 2 && event.url.split('/')[1].split('?')[0] != 'preferences';
            }
        });

        this.subordinatesService.getAuditMode().pipe(takeUntil(this.componentDestroyed)).subscribe(res=>{
            if (res || this.cacheService.getInfoFromSessionStorage(this.cacheService.auditeeInfoKey)) {
                this.isAuditmode = true;
              } else {
                this.isAuditmode = false;
              }
        });
        this.islocal = environment.name === 'Local';
        this.periodEndDate = this.cacheService.getPeriodEnd();


        const currentPeriodEnd = this.periodEndDate.toISOStringMyTE();

        this.periodEndBase64 = encodeURIComponent(window.btoa(currentPeriodEnd));

        this.periodEnd = currentPeriodEnd;

        this.timeUrl = this.appConfigData.OLD_MYTE_APP + `/OGTE/secure/TimesheetPage.aspx?periodEnd=${currentPeriodEnd}`;
        this.locationUrl = this.appConfigData.OLD_MYTE_APP + `/OGTE/secure/LocationByDayTab.aspx?periodEnd=${currentPeriodEnd}`;
        this.assignmentUrl = this.appConfigData.OLD_MYTE_APP + `/OGTE/secure/AssignmentsPage.aspx?periodEnd=${currentPeriodEnd}`;
        this.adjustmentUrl = this.appConfigData.OLD_MYTE_APP + `/OGTE/secure/AdjustmentsPage.aspx?periodEnd=${currentPeriodEnd}`;
        this.summaryUrl = this.appConfigData.OLD_MYTE_APP + `/OGTE/secure/SummaryPage.aspx?periodEnd=${currentPeriodEnd}`;
        this.compensatoryUrl = this.appConfigData.OLD_MYTE_APP + `/OGTE/secure/CompensatoryPage.aspx?periodEnd=${currentPeriodEnd}`;
        this.locationQuestionnaireUrl = this.appConfigData.OLD_MYTE_APP + `/OGTE/secure/LocationQuestionnairePage.aspx?periodEnd=${currentPeriodEnd}`;
        if(this.cacheService.getSubordinateMode()=="ReviewEmail")
        this.globalEventsService.selectTimeTabEvent().subscribe(t=>this.isTimeTab=t);
        this.globalEventsService.selectSubordinateSelectedEvent()
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe(payload => {
                if (payload.subordinate == null) return;
                this.timeReportStatus = TimeReportStatusCode.getKeyByValue(payload.subordinate.statusCode);
            });

        this.globalEventsService.selectCatchQuestionaireErrorEvent()
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe(error => {
                this.hasQuestionnaireError = error;
            });
        
        let isEditStatus = this.timeReportStatus === 'Processed' ||
        this.timeReportStatus === 'ProcessedAdjustment' ||
        this.timeReportStatus === 'SubmitPendingAdjustment' ||
        this.timeReportStatus=== 'DraftAdjustment' ||
        this.timeReportStatus=== 'SubmittedAdjustment' || 
        this.timeReportStatus == 'Draft'

        if (isEditStatus) {
            this.globalEventsService.saveTimeReportEvent()
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe(value => this.shouldPreventAutoSave = value); 
            }
        let newTabs = new Map<string, boolean>();
        this.cacheService.getTabs(this.periodEndDate).subscribe((tabs) => newTabs = tabs);

        if (newTabs) {
            this.dictionaryTabs = newTabs;
        } else {
            this.dictionaryTabs.set('Time', false);
            this.dictionaryTabs.set('Locations', false);
            this.dictionaryTabs.set('Expenses', false);
            this.dictionaryTabs.set('Preferences', false);
            this.dictionaryTabs.set('Activity', false);
            this.cacheService.setTab(of(this.dictionaryTabs), this.periodEndDate);
        }

        this.viewMode = this.cacheService.getSubordinateMode();
        this.viewModeBase64 = encodeURIComponent(window.btoa(this.viewMode.toString()))
        this.subordinate = this.cacheService.getCurrentSubordinate();

        this.globalEventsService.selectTimeReportStatusChangedEvent()
        .pipe(takeUntil(this.componentDestroyed))
        .subscribe(status => {
            this.timeReportStatus = status
        });
        this.auditStatusSubscription = this.auditService.getAuditStatus().subscribe((res: boolean) => {
            this.getAuditStatus();
        })
    }

    public getAuditStatus(){
        if(this.cacheService.getInfoFromSessionStorage(this.cacheService.auditeeInfoKey)?.auditStatus){
            this.auditStatus=  this.cacheService.getInfoFromSessionStorage(this.cacheService.auditeeInfoKey)?.auditStatus
        }
    }
    public logout() {
        this.cacheService.resetSessionStorage(this.cacheService.currentPeriodEnd);
        this.autherService.logout();
    }

    public onShowPdfViewer(event): void {
        if (this.timeReportStatus == 'New' || this.timeReportStatus == 'Draft' || this.timeReportStatus == 'SubmittedPendingApproval') return;
        this.reportService.printPDF(false, [], this.periodEnd.toString());
    }

    public autoSave() { 
        if(this.viewMode != 'Supervisor' && this.viewMode != 'Approver')      
            this.autoSaveService.Save(this.dictionaryTabs, this.timeReportStatus, this.periodEndDate);     
        if (this.viewMode != "Record" && this.subordinate != null) {
            this.subordinatesService.getSubordinateTabPermissionsObservable(this.subordinate.enterpriseId, this.viewMode)
                .subscribe(userTabs => this.subordinatesService.resolveLegacyRedirection(userTabs, this.viewMode, this.subordinate.enterpriseId));
        }
        this.cacheService.setTab(of(this.dictionaryTabs), this.periodEndDate);
    }

    public redirect = (event: KeyboardEvent = null) => {
        let urlToRedirect:string = (event.target as HTMLElement).getAttribute('routerLink');
        this.router.navigate([urlToRedirect], { queryParams:{periodEnd: this.periodEndBase64, viewMode: this.viewModeBase64 }});
    }

    redirectTo(urlToRedirect) {
        window.location.href = urlToRedirect;
    }

    public refresh() {
        this.changeTab();
        this.router.navigateByUrl(this.router.url);
    }

    public isLinkActive(url: string): boolean {
        const queryParamsIndex = this.router.url.indexOf('?');
        const baseUrl = queryParamsIndex === -1 ? this.router.url :
            this.router.url.slice(0, queryParamsIndex);
        return baseUrl === url;
    }

    private changeTab() {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    }
    public ngOnDestroy(): void {
        this.componentDestroyed.next();
        this.componentDestroyed.complete();
        if (this.auditStatusSubscription) {
            this.auditStatusSubscription.unsubscribe();
        }
    }

    public shouldPrintButtonBeDisabled(): boolean {
        return (this.inRootPage &&
                (this.timeReportStatus != '' &&
                    this.timeReportStatus != undefined &&
                    this.timeReportStatus != 'New' &&
                    this.timeReportStatus != 'Draft' &&
                    this.timeReportStatus != 'SubmittedPendingApproval' &&
                    this.timeReportStatus != 'DraftPendingUpdates'))
    }
}
