import { AfterViewInit, Component, ViewChild, ViewContainerRef } from '@angular/core';
import ExpenseGridItem from '@shared/models/expense/expense-grid-item';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';
import { Option } from '../../../../shared/clients/expense-api-client';

@Component({
  selector: 'myte-expense-grid-assignment-editor',
  templateUrl: './expense-grid-assignment-editor.component.html',
  styleUrls: ['./expense-grid-assignment-editor.component.sass']
})
export class ExpenseGridAssignmentEditorComponent implements ICellEditorAngularComp, AfterViewInit {
  public params: ICellEditorParams<ExpenseGridItem, Option>;
  public assignment: Option;
  public originValue: string;
  public disabled = false;
  @ViewChild('input', { read: ViewContainerRef }) public input;

  constructor() { }

  public agInit(params: ICellEditorParams<ExpenseGridItem, Option>): void {
    this.params = params;
    this.assignment = this.params.value;
    this.originValue = this.params.value.value;
    // this.setValue();
  }

  public ngAfterViewInit(): void {
    // window.setTimeout(() => {
    //   this.input.element.nativeElement.focus();
    // });
  }

  public getValue(): Option {
    let res: Option = new Option();
    res = this.assignment;
    res['saved'] = false;
    if (this.originValue != this.assignment.value) {
      res['saved'] = true;
    }
    return res;
  }

  public setValue(): Option {
    return this.assignment;
  }
}
