import { AnnualChargeabilityRow, AnnualChargeabilityParentRow } from './annual-chargeability-row.model';

export class AnnualChargeabilityGrid {
  public rowData: Array<AnnualChargeabilityRow>;
  public pinnedBottomRowData: Array<AnnualChargeabilityRow>;

  constructor(rowData?: Array<AnnualChargeabilityRow>, pinnedBottomRowData?: Array<AnnualChargeabilityRow>) {
  
      this.rowData = rowData ?? new Array(2).fill(new AnnualChargeabilityParentRow());
      this.pinnedBottomRowData = pinnedBottomRowData ?? new Array(1).fill(new AnnualChargeabilityRow());
  }
}
