import { environment } from '@environments/environment';

export default class User {
  public peopleKey: number;
  public enterpriseId: string;
  public firstName: string;
  public lastName: string;
  public internetMail: string;
  public personnelNumber: string;
  public countryKey: string;

  constructor(userProfile: any) {
    if (environment.name === 'Mock') {
      this.enterpriseId = 'emiliano.gonzalez';
      this.peopleKey = 4095;
    } else if (environment.name === 'Local') {
      if (userProfile) {
        this.enterpriseId = userProfile.eid;
      } else {
        throw new Error('Oauth Error: No claims for user');
      }
    } else {
      if (userProfile) {
        if (environment.production) {
          let userNameClaim = String(userProfile.username);
          this.enterpriseId = userNameClaim.split('@')[ 0 ].toLowerCase();
        } else if (environment.name === 'Local') {
          let subject = String(userProfile.sub);
          this.enterpriseId = subject.split('@')[ 0 ].toLowerCase();
        }
      } else {
        throw new Error('Oauth Error: No claims for user');
      }
    }
  }
}
