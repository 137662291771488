export class TimeReportLogAction {
    public timeReportLogActionId: number;
    public actionDec: string;
    public comment: string;
    public addActionDate: Date;
    public addActionEID: string;
    public sourceSystem: string;
    
    constructor(timeReportLogActionId?: number, actionDec?: string, comment?: string, addActionDate?: Date, addActionEID?: string, sourceSystem?: string) {
        this.timeReportLogActionId = timeReportLogActionId;
        this.actionDec = actionDec;
        this.comment = comment;
        this.addActionDate = addActionDate;
        this.addActionEID = addActionEID;
        this.sourceSystem = sourceSystem;
    }
}

export enum ActionDescription {
    ExpenseReverse = 'Expense Reversed',
    AutomaticReverse = 'Automatic Reversed',
    SystemGenerated = 'System Generated Status Change',
    AuditTeam = 'Audit Team'
}