
import { ShellModel } from '../../../../app-shell/data-store';
import { LocationsGridData } from './locations-grid-data';
import { SummaryGridData } from './summary-grid-data';
import { TimeReportSummaryOvertime } from './time-report-summary-overtime';
import { TimeReportSummaryRow } from './time-report-summary-row';
import { SAPBalanceData } from './sap-balace-data';

export class TimeReportSummary extends ShellModel {
    public timeReportStatus: string;
    public ptosBalance: number;
    public ptosPeriodEnd: Date;
    public sapBalance: SAPBalanceData;
    public summaryGridData: SummaryGridData;
    public locationsGridData: LocationsGridData;
    public standardAvailableHours: TimeReportSummaryRow;
    public percentageOfStandardAvailableHours: TimeReportSummaryRow;
    public workSchedule: TimeReportSummaryRow;
    public annualChargeabilityHoursPercentage: string;
    public annualChargeabilityValue: number;
    public otCashout: TimeReportSummaryOvertime;
    public isSaveEnabled: boolean;
    public showPTOBalance: boolean;

    constructor(timeReportStatus?: string, ptosBalance?: number, ptosPeriodEnd?: Date, summaryGridData?: SummaryGridData, locationsGridData?: LocationsGridData,
        standardAvailableHours?: TimeReportSummaryRow, percentageOfStandardAvailableHours?: TimeReportSummaryRow, workSchedule?: TimeReportSummaryRow,
        annualChargeabilityHoursPercentage?: string, annualChargeabilityValue?: number, otCashout?: TimeReportSummaryOvertime, isSaveEnabled?: boolean,
        showPTOBalance?: boolean, sapBalance?: SAPBalanceData) {
        super();
    
        this.timeReportStatus = timeReportStatus;
        this.ptosBalance = ptosBalance;
        this.ptosPeriodEnd = ptosPeriodEnd;
        this.summaryGridData = summaryGridData ?? new SummaryGridData();
        this.locationsGridData = locationsGridData ?? new LocationsGridData();
        this.standardAvailableHours = standardAvailableHours ?? new TimeReportSummaryRow();
        this.percentageOfStandardAvailableHours = percentageOfStandardAvailableHours ?? new TimeReportSummaryRow();
        this.workSchedule = workSchedule ?? new TimeReportSummaryRow();
        this.annualChargeabilityHoursPercentage = annualChargeabilityHoursPercentage;
        this.annualChargeabilityValue = annualChargeabilityValue;
        this.otCashout = otCashout ?? new TimeReportSummaryOvertime();
        this.isSaveEnabled = isSaveEnabled ?? false;
        this.showPTOBalance = showPTOBalance ?? true;
        this.sapBalance = sapBalance ?? new SAPBalanceData();
    }
}

export enum SummaryGridDataType {
    Total = 'Total',
    WorkSchedule = 'Work Schedule',
    Overtime = 'Overtime',
    StandardHours = 'Standard Available Hours',
    StandardPercentageHours = 'Percentage of Standard Available Hours'
}