import { Component } from '@angular/core';
import ExpenseGridItem from '@shared/models/expense/expense-grid-item';
import { SubordinatesMode } from '@shared/models/subordinates/subordinate-mode';
import { GlobalCacheService } from '@shared/services/cache/global-cache.service';
import { LogService } from '@shared/services/log/log.service';
import { ActionStoreService } from '@shared/services/store/action-store.service';
import { ActionType } from '@shared/services/store/shared/action-type';
import { UserService } from '@shared/services/user/user.service';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { EscalateExpenseService } from './shared/expense-grid-checkbox-escalate.service';
import { AuditorMessages } from '../../../constants/ExpenseAuditorTooltips';
@Component({
  selector: "myte-expense-grid-checkbox-renderer",
  templateUrl: "./expense-grid-checkbox-renderer.component.html",
  styleUrls: ["./expense-grid-checkbox-renderer.component.sass"],
})
export class ExpenseGridCheckboxRendererComponent
  implements ICellRendererAngularComp
{
  public params: ICellRendererParams<ExpenseGridItem>;
  public notEditable: boolean = false;
  public auditor=  AuditorMessages;
  

  constructor(
    private globalCacheService: GlobalCacheService,
    private userService: UserService,
    private logService: LogService,
    private actionService: ActionStoreService,
    private escalateExpenseService: EscalateExpenseService
  ) {}

  public agInit(params: ICellRendererParams<ExpenseGridItem>): void {
    this.params = params;
    if(this.globalCacheService.getUserRole()=== this.auditor.auditSupport){
        this.notEditable=true;
    }
  }

  public refresh(): boolean {
    return true;
  }

  public onChange(event: any): void {
    this.globalCacheService.ExpenseId = this.params.data.id;
    const data = {
      auditId: this.globalCacheService.getInfoFromSessionStorage(
        this.globalCacheService.auditInfoKey
      )?.auditID,
      enterpriseId:
        this.globalCacheService.getCurrentSubordinate()?.enterpriseId,
      date: this.globalCacheService.getPeriodEnd(),
      loggedInUser: this.userService.getUser()?.enterpriseId,
      viewMode: SubordinatesMode.Audit,
    };
    let col = this.params.column.getColId();
    col == "selfCert"
      ? (this.params.data.selfCertification = event.target.checked)
      : (this.params.data.escalated = event.target.checked);
    if (col == "selfCert") {
      this.escalateExpenseService
        .updateSelfCertification(
          this.params.data.id,
          data.enterpriseId,
          data.date,
          data.loggedInUser,
          data.viewMode
        )
        .subscribe(() => {
          this.refreshGrid();
        });
    }
    if (col == "escalated") {
      if (this.params.data.escalated) {
        this.escalateExpenseService
          .checkExpenseHasOpenIssues(
            this.params.data.id,
            this.globalCacheService.getPeriodEnd(),
            data.enterpriseId,
            data.loggedInUser,
            data.viewMode
          )
          .subscribe((res) => {
            if (res) {
              let errorMsg: any = {
                msg: this.auditor.errormessage,
                showToast: true,
                title:' '
              };
                event.target.checked = false;
                this.params.data.escalated = event.target.checked;
              this.logService.logErrorWithMsgInfo(errorMsg);
            } else {
              this.globalCacheService.setIssueStatus(true);
            }
          });
      } else {
        this.escalateExpenseService
          .updateEscalated(
            this.params.data.id,
            data.auditId,
            data.date,
            data.enterpriseId,
            data.loggedInUser,
            data.viewMode
          )
          .subscribe(() => {
            this.refreshGrid();
          });
      }
    }
  }

  public refreshGrid() {
    this.actionService.dispatchAction(ActionType.LOAD_EXPENSES, {
      shouldInitialize: true,
      periodEnd: this.globalCacheService.getPeriodEnd(),
    });
  }

  public onClickCheckbox(): void {
    this.params.node.selectable = false;
    setTimeout(() => {
      this.params.node.selectable = true;
    }, 10);
  }
}
