import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { AgGridModule } from 'ag-grid-angular';
import { MyteControlsModule } from '../myte-controls/myte-controls.module';
import { ExpenseAuditInquiryPopupComponent } from '../myte-expenses/components/myte-expense-popups/expense-audit-inquiry-popup/expense-audit-inquiry-popup.component';
import { ExpenseUploadReceiptsPopupComponent } from '../myte-expenses/components/myte-expense-popups/expense-upload-receipts-popup/expense-upload-receipts-popup.component';
import { ExpenseAuditService } from '../myte-expenses/shared/services/audit/expense-audit.service';
import { ExpenseGridEventsService } from '../myte-expenses/shared/services/events/expense-grid-events.service';
import { SummaryService } from '../myte-summary/shared/services/summary/summary.service';
import { LocalDateFormatPipe } from '../shared/pipes/dateformated-local.pipe';
import { NumberFormatPipe } from '../shared/pipes/number-format.pipe';
import { FileViewerComponent } from './components/file-viewer/file-viewer.component';
import { RemoveSubordinatePopUpComponent } from './components/remove-subordinate-popup/remove-subordinate-popup.component';
import { TimeEntryTemplatePopupGridCellEditor } from './components/time-entry-template-popup/time-entry-template-popup-grid/time-entry-template-popup-grid-cell-editor/time-entry-template-popup-grid-cell-editor';
import { TimeEntryTemplatePopupGridCellRender } from './components/time-entry-template-popup/time-entry-template-popup-grid/time-entry-template-popup-grid-cell-render/time-entry-template-popup-grid-cell-render.component';
import { TimeEntryTemplatePopupGridChargecodeEditorComponent } from './components/time-entry-template-popup/time-entry-template-popup-grid/time-entry-template-popup-grid-chargecode-editor/time-entry-template-popup-grid-chargecode-editor.component';
import { TimeEntryTemplatePopupGridChargecodeRenderComponent } from './components/time-entry-template-popup/time-entry-template-popup-grid/time-entry-template-popup-grid-chargecode-render/time-entry-template-popup-grid-chargecode-render.component';
import { TimeEntryTemplatePopupGridHeaderComponent } from './components/time-entry-template-popup/time-entry-template-popup-grid/time-entry-template-popup-grid-header/time-entry-template-popup-grid-header.component';
import { TimeEntryTemplatePopupGridTotalRender } from './components/time-entry-template-popup/time-entry-template-popup-grid/time-entry-template-popup-grid-total-render/time-entry-template-popup-grid-total-render.component';
import { TimeEntryTemplatePopupGridComponent } from './components/time-entry-template-popup/time-entry-template-popup-grid/time-entry-template-popup-grid.component';
import { TimeEntryTemplatePopupLocationsComponent } from './components/time-entry-template-popup/time-entry-template-popup-locations/time-entry-template-popup-locations.component';
import { TimeEntryTemplatePopupTotalGridCellRenderComponent } from './components/time-entry-template-popup/time-entry-template-popup-total-grid/time-entry-template-popup-total-grid-cell-render.component';
import { TimeEntryTemplatePopupComponent } from './components/time-entry-template-popup/time-entry-template-popup.component';
import { TimeSubmitAllPopupComponent } from './components/time-submitAll-popup/time-submitAll-popup.component';
import { TraveldiaryCellEditorComponent } from './components/traveldiary-popup/traveldiary-cell-editor/traveldiary-cell-editor.component';
import { TravelDiaryDefaultRendererComponent } from './components/traveldiary-popup/traveldiary-default-renderer/traveldiary-default-renderer.component';
import { TraveldiaryPopupComponent } from './components/traveldiary-popup/traveldiary-popup.component';
import { ChargeabilityImpactReviewService } from './shared/services/chargeability-impact-review.service';
import { TimeEntryTemplateEventsService } from './shared/services/time-entry-template-events.service';
import { TimeEntryTemplateGridService } from './shared/services/time-entry-template-grid.service';
import { TimeEntryTemplateService } from './shared/services/time-entry-template.service';
import { DeletepopupComponent } from './components/ActivityDeleteWarning/deletepopup/deletepopup.component';
import { idleSessionComponent } from './components/idle-session-popup/idle-session-popup.component';
import { OvertimeLimitDetailsPopupComponent } from './components/overtime-limit-details-popup/overtime-limit-details-popup.component';
import { RequestOvertimePopupInformationComponent } from './components/overtime-limit-details-popup/request-overtime-popup-information/request-overtime-popup-information.component';
import { OvertimeLimitDetailService } from './shared/services/overtime-limit-details-service';
import { OvertimeLimitsComponent } from './components/overtime-limit-details-popup/overtime-limits/overtime-limits.component';
import { OvertimeWorkComponent } from './components/overtime-limit-details-popup/overtime-work/overtime-work.component';
import { ExpenseAuditRaiseIssuePopupComponent } from './components/expense-audit-raise-issue-popup/expense-audit-raise-issue-popup.component';
import { ExpenseAuditRaiseIssueService } from './shared/services/expense-audit-raise-Issue.service';
import { AuditlogpopupComponent } from './components/audit-log-popup/auditlogpopup.component';
import { AuditLogGridService } from './shared/services/audit-log-grid.service';
import { AllowanceRequestConfirmationPopupComponent } from './components/allowance-request-confirmation-popup/allowance-request-confirmation-popup.component';
import { ReportsAddMultiplePopupComponent } from './components/reports-add-multiple-popup/reports-add-multiple-popup.component';

@NgModule({
  declarations: [
    FileViewerComponent,
    TimeEntryTemplatePopupComponent,
    TimeEntryTemplatePopupLocationsComponent,
    TimeEntryTemplatePopupGridComponent,
    TimeEntryTemplatePopupGridChargecodeEditorComponent,
    TimeEntryTemplatePopupGridHeaderComponent,
    TimeEntryTemplatePopupGridCellEditor,
    TraveldiaryPopupComponent,
    TraveldiaryCellEditorComponent,
    TravelDiaryDefaultRendererComponent,
    RemoveSubordinatePopUpComponent,
    TimeSubmitAllPopupComponent,
    TimeEntryTemplatePopupGridChargecodeRenderComponent,
    TimeEntryTemplatePopupGridCellRender,
    LocalDateFormatPipe,
    NumberFormatPipe,
    ExpenseAuditInquiryPopupComponent,
    ExpenseUploadReceiptsPopupComponent,
    TimeEntryTemplatePopupGridTotalRender,
    TimeEntryTemplatePopupTotalGridCellRenderComponent,
    DeletepopupComponent,
    idleSessionComponent,
    OvertimeLimitDetailsPopupComponent,
    RequestOvertimePopupInformationComponent,
    OvertimeLimitsComponent,
    OvertimeWorkComponent,
    ExpenseAuditRaiseIssuePopupComponent,
    AuditlogpopupComponent,
    AllowanceRequestConfirmationPopupComponent,
    ReportsAddMultiplePopupComponent
  ],
  imports: [
    MatIconModule,
    CommonModule,
    MyteControlsModule,
    FormsModule,
    NgbTooltipModule,
    A11yModule,
    AgGridModule
  ],
  exports: [
    FileViewerComponent,
    TimeEntryTemplatePopupComponent,
    TraveldiaryPopupComponent,
    RemoveSubordinatePopUpComponent,
    ExpenseAuditInquiryPopupComponent,
    ExpenseUploadReceiptsPopupComponent,
    TimeSubmitAllPopupComponent,
    LocalDateFormatPipe,
    NumberFormatPipe,
    DeletepopupComponent,
    idleSessionComponent,
    OvertimeLimitDetailsPopupComponent,
    ExpenseAuditRaiseIssuePopupComponent,
    AuditlogpopupComponent,
    AllowanceRequestConfirmationPopupComponent,
    ReportsAddMultiplePopupComponent
  ],
  providers: [
    TimeEntryTemplateEventsService,
    TimeEntryTemplateGridService,
    TimeEntryTemplateService,
    FileViewerComponent,
    ExpenseAuditService,
    ExpenseGridEventsService,
    ChargeabilityImpactReviewService,
    SummaryService,
    OvertimeLimitDetailService,
    ExpenseAuditRaiseIssueService,
    AuditLogGridService
  ]
})
export class MytePopupsModule { }
