import { ExpenseViewFieldOption } from '../expense-view-field-option';
import { ExpenseViewFieldValidation } from '../expense-view-field-validation';
import ExpenseViewRowElement from './expense-view-row-element';
import { ExpenseViewFieldValidationError } from '../expense-view-field-validation-error';

export class ExpenseViewField implements ExpenseViewRowElement {
  public visible: boolean;
  public category: number;
  public row: number;
  public key: string;
  public label: string;
  public tooltip: string;
  public uiType: string;
  public dataType?: string;
  public order?: number;
  public readOnly: boolean;
  public hasAdjustment: boolean;
  public PreviousPPAValue: string;
  public required: boolean;
  public size?: number;
  public value: any;
  public options?: ExpenseViewFieldOption[] = [];
  public validations?: ExpenseViewFieldValidation[] = [];
  public styles?: any;
  public currency?: string = null;
  public errors: ExpenseViewFieldValidationError[] = [];
  public elementType: string;
  public isRounded: boolean = false;
  public childrenLabel: string;

  constructor(key: string, value?: any, label?: string, tooltip?: string, required?: boolean, readOnly?: boolean, isAdjustment?: boolean, previousPPAValue?: string,
    uiType?: string, dataType?: string, size?: number, order?: number, options?: ExpenseViewFieldOption[], validations?: ExpenseViewFieldValidation[],
    styles?: any, elementType?: string, category?: number, row?: number, visible?: boolean, isRounded?: boolean, childrenLabel?: string) {
    this.category = category;
    this.row = row;
    this.key = key;
    this.value = value;
    this.label = label;
    this.tooltip = tooltip;
    this.required = required;
    this.readOnly = readOnly;
    this.hasAdjustment = isAdjustment;
    this.PreviousPPAValue = previousPPAValue;
    this.uiType = uiType ? uiType.toLowerCase() : '';
    this.dataType = dataType ? dataType.toLowerCase() : null;
    this.order = order;
    this.size = size;
    this.options = options;
    this.validations = validations;
    this.styles = styles;
    this.elementType = elementType;
    this.visible = visible;
    this.isRounded = isRounded ? isRounded : false;
    this.childrenLabel=childrenLabel;
  }

  public validate(value: string, isTriggeredBySave?:boolean): ExpenseViewFieldValidationError[] {
    this.errors = [];
    if (this.uiType.toLowerCase() == 'error' && this.label && this.required){
      this.errors.push(new ExpenseViewFieldValidationError('BlockSaveExpenseError', this.label));}
    if (this.key == 'T3_RecipientsPicker' && value?.split("|").length>10){
      this.errors.push(new ExpenseViewFieldValidationError('NonBlockError', "If you’ve purchased gifts for more than 10 recipients, please create multiple expense line items."));}
    if(this.uiType == 'date' && value =='Invalid Date'){
    this.errors.push(new ExpenseViewFieldValidationError('Invalid Date', 'Date is invalid'));   
    this.value = '';
     } 
    if(this.key == 'ExchangeRate' && (this.value == null || this.value == ''))
     this.errors.push(new ExpenseViewFieldValidationError('NonBlockError', ""));
    let uberAmountError = this.validations.filter(validation => validation.errorMessage == "You cannot increase the amount of an Uber expense.");
    if ((!this.readOnly && this.validations && this.validations.length > 0) || uberAmountError.length > 0) { // && this.required == true) {
      this.validations.forEach(validation => {
        if (validation.value != null && !validation.value.test(value || '') && this.key != 'T2_DeductNormalCommute'|| value == ' -- Select one -- ') {
          if(this.childrenLabel==null){
            this.errors.push(new ExpenseViewFieldValidationError(validation.type,  this.label + validation.errorMessage, this.label));
          }
          else{
            this.errors.push(new ExpenseViewFieldValidationError(validation.type,  validation.errorMessage, this.childrenLabel));
          }
        }
        if(validation.type == 'BlockSaveExpenseError' && isTriggeredBySave){
          this.errors.push(new ExpenseViewFieldValidationError(validation.type, validation.errorMessage, this.label));
        }
        if (validation.type == 'BlockError' && isTriggeredBySave) {
          this.errors.push(new ExpenseViewFieldValidationError(validation.type, validation.errorMessage, this.label));
        }​​​​​​​​
      });
    }
    return this.errors;
  }


  public validateFieldErrors() {
    if (this.validations && this.validations.length > 0) {
      this.validations.forEach(validation => {
        if ((validation.type == 'BlockError' || validation.type == 'NonBlockError' ) && !this.errors.find(err => err.errorMessage == validation.errorMessage)) {
          this.errors.push(new ExpenseViewFieldValidationError(validation.type, validation.errorMessage));
        }
      });
    }
  }

  public isValid(): boolean {
    return !(this.errors.length > 0);
  }

  public hasRequiredError(): boolean {
    return this.errors.some(err => err.type == 'required');
  }

  public getErrorMessages(): string[] {
    return this.errors.slice(-1).filter(err => err.errorMessage).map(function(err) {
      return err['errorMessage'];
    });
  }

  public isNumericField(): Boolean {
    return this.elementType === 'Decimal' || this.elementType === 'Integer' || this.elementType === 'Money'
      || this.elementType === 'ExchangeRate' || this.elementType === 'ExpressionReturnMoney' || this.elementType === 'ExpressionReturnInteger'
      || this.elementType === 'ExpressionReturnDecimal';
  }
  
}
