<div  *ngIf="!isFilter" class="input-group"
  [ngClass]="{isAdjustment : isAdjustment, invalid: isValid == null? false: !isValid }">
  <input
    tabindex="0"
    [id]="id"
    class="date-picker form-control"
    placeholder="{{ placeholder }}"
    name="dp"
    [(ngModel)]="selectedDate"
    (ngModelChange)="OnCalendarDateChange()"
    container="body"
    [firstDayOfWeek]="firstDayOfWeek"
    ngbDatepicker #d="ngbDatepicker"
    (clickOutside)="hideOptions($event)"
    [ngModelOptions]="{updateOn: 'blur'}"
    [disabled]="disable"
    [footerTemplate] ="footerTemplate"
    [dayTemplate]="customDay"
    [attr.aria-label]="ariaLabel"
    [attr.aria-required]="required"
    (blur)="onBlur($event)"
    [myteValidateInput]="validationExpressions"
    [ngClass]="{'hideInput':isHidden,'readOnlyInput':readOnly}"
    [positionTarget]="positionTarget"
    [readonly]="readOnly"
    (input)="onInputChange($event)"
    >

  <div class="input-group-append">
    <button [id]="calendarButtonId" class="btn btn-sm btn-outline-secondary calendar" matTooltip= "{{isDisplayTooltip ? 'click to select a calendar': null }}" [matTooltipPosition]="'below'"
      (click)="d.toggle()" type="button" aria-label="Select Date" [disabled]="disable">
      <mat-icon class="material-icons" aria-hidden="true" fontIcon="today"></mat-icon>
    </button>
  </div>
  <ng-template  #footerTemplate>
    <hr class="my-0">
    <button class="btn btn-sm m-1 float-start footer-style" (click)="onClickToday()">{{stringToday}}</button>
  </ng-template>
  <ng-template  #customDay let-date let-currentMonth="currentMonth" let-selected="selected" let-disabled="disabled" let-focused="focused" >
    <div class="custom-day-datePicker" [class.focused]="focused && !isHidden" [class.custom-day-datePicker-disabled]="isDisabled(date, currentMonth)" [class.custom-day-datePicker-selected]="selected && !isHidden" ngbTooltip= "{{date | dateformat:'GetDateForTooltip'}}">{{date.day}}</div>    
  </ng-template>
</div>

<div class="ag-filter-select customBodyDatePicker" *ngIf="isFilter" >
  <div class="ag-filter-filter">
    <input
      #datePickerInput
      tabindex="0"
      class="ag-input-field-input ag-text-field-input customInputDatePicker" placeholder="{{ placeholder }}"
      name="dp"
      [(ngModel)]="selectedDate"
      [ngModelOptions]="{updateOn: 'blur'}"
      [firstDayOfWeek]="firstDayOfWeek"
      (ngModelChange)="OnCalendarDateChange()"
      ngbDatepicker
      #d="ngbDatepicker"
      [attr.aria-label]="ariaLabel"
      maxlength="10" 
      onkeypress='return (event.charCode >= 48 && event.charCode <= 57) || event.charCode === 45 || event.charCode === 47'
    >
      <div  class=" customDatePickerBodyButton">
        <button #datePickerButton class="btn btn-sm btn-outline-secondary calendar customDatePickerButton"
          (click)="d.toggle()" type="button" aria-label="Select Date" id="customDatePickerButton">
        <mat-icon class="material-icons customDatePickerIcon" aria-hidden="true" fontIcon="today"></mat-icon>
      </button>
      </div>
  </div>
</div>
  

