export class AmexExpenseTypeModel {
    public code: string;
    public description: string;
    public threshold: number;
    public canBeImportedFromAMEXMobile: boolean;
    public isAmexExpenseType: boolean;
    public amexExpenseType: string;
    public isExpenseDisplayingVATModelB: boolean;
    public preSelectedValue: string;

    constructor(
        code?: string,
        description?: string,
        threshold?: number,
        canBeImportedFromAMEXMobile?: boolean,
        isAmexExpenseType?: boolean,
        amexExpenseType?: string,
        isExpenseDisplayingVATModelB?: boolean,
        preSelectedValue?: string
    ) {
        this.code = code;
        this.description = description;
        this.threshold = threshold;
        this.canBeImportedFromAMEXMobile = canBeImportedFromAMEXMobile;
        this.isAmexExpenseType = isAmexExpenseType;
        this.amexExpenseType = amexExpenseType;
        this.isExpenseDisplayingVATModelB = isExpenseDisplayingVATModelB;
        this.preSelectedValue = preSelectedValue;
    }
}
