import { NgModule, ModuleWithProviders } from '@angular/core';
import { UrlPipe } from './pipes/url.pipe';
import { SearchPipe } from './pipes/search.pipe';
import { ChargecodesDetailPipe } from './pipes/chargecodes-detail.pipe';

@NgModule({
  declarations: [UrlPipe, SearchPipe, ChargecodesDetailPipe],
  imports: [
  ],
  exports: [
    UrlPipe,
    SearchPipe,
    ChargecodesDetailPipe
  ]
})
export class PipeModule {
  static forRoot(): ModuleWithProviders<PipeModule> {
    return {
        ngModule: PipeModule,
        providers: [],
    };
}
}
