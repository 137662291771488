import { ErrorHandler, Inject, Injectable, Injector } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { LogService } from "./log/log.service"
import { GlobalEventsService } from "./events/global-events.service";

@Injectable()

export class CustomerHandler extends ErrorHandler {
  constructor(@Inject(Injector) private injector: Injector) {
    super();
  }
  public handleError(error: Error | HttpErrorResponse): void {
    const globalEventsService = this.injector.get(GlobalEventsService);
    const logService = this.injector.get(LogService);
    // logService.logError(
    //   "You encountered an unexpected error. Please refresh the page and retry you action. If the problem persists, please contact CIO Technology Support.",
    //   true,
    //   "Error on Front End");
    globalEventsService.dispatchUpateshowSpinnerForErrorHandler(false);
    super.handleError(error);
  }
}