import ExpenseViewRowElement from './expense-view-row-element';
import MealClaimed from '../expense-meal-claimed';
import { ExpenseViewFieldValidationError } from '../expense-view-field-validation-error';

export default class MealsClaimedField implements ExpenseViewRowElement {
    visible: boolean = true;
    category: number = 1;
    row: number = 9;
    uiType = 'MealsClaimed';
    key = 'MealsClaimed';
    order ? = 0;
    size ? = 100;
    value: MealClaimed[];
    currency: string;
    elementType: 'text';

    validate(value: string): ExpenseViewFieldValidationError[] {
        return [];
    }


    validateFieldErrors() {

    }

    isValid(): boolean {
        return true;
    }

    hasRequiredError(): boolean {
        return false;
    }

    getErrorMessages(): string[]{
        return [];
    }
}
