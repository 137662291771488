import { ShiftScheduleOption } from './shift-schedule-option';
import { ShiftScheduleRowData } from './shift-schedule-row-data';

export class ShiftSchedule {
  dates: Date[] = [];
  daysOfWeek: string[] = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  shiftType: string;
  isByPeriod: boolean;
  shiftScheduleData: ShiftScheduleRowData[] = [];
  shiftScheduleOptions: ShiftScheduleOption[] = [];
  error: string;
  isEditable: boolean;
  timeReportStatus?: string;
}