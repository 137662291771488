import { 
  ProjectInfoResult as APIChargeCodes,
  ProjectInfo as APIChargeCodeInfo,
  AddedProjectOutput as APIAddedProjectOutput,
  ProjectDescendantTreeNode as APIProjectDescendantTreeNode,
  NetworkHierarchy as APINetworkHierarchy,
  ProjectDisplayList as APIProjectDisplayList,
  ProjectListInput as APIProjectListInput
} from '../../../shared/clients/chargecode-api-client';
import { ChargeCodes } from '../../../shared/models/charge-code/charge-codes.model';
import { ChargeCodeInfo } from '../../../shared/models/charge-code/charge-code-info';
import { ChargeCodeRelated } from '@shared/models/charge-code/charge-code-related';

export class ChargeCodesMapper {

  public static mapAPIChargeCodes(apiChargeCodes: APIChargeCodes, apiAddedProjectOutput: APIAddedProjectOutput): ChargeCodes {
    const chargeCodes: ChargeCodes = new ChargeCodes();
    if (apiChargeCodes && Object.keys(apiChargeCodes).length > 0) {
      chargeCodes.isEditable = apiChargeCodes.isEditable;
      chargeCodes.projectInfo = this.mapAPIChargeCodeInfoArray(apiChargeCodes.projectInfo);
      chargeCodes.addedProjectOutput = apiAddedProjectOutput;
    }
    return chargeCodes;
  }

  private static mapAPIChargeCodeInfoArray(apiChargeCodeInfoArray: Array<APIChargeCodeInfo>): Array<ChargeCodeInfo> {
    const chargeCodeInfos: Array<ChargeCodeInfo> = new Array<ChargeCodeInfo>();
    if (apiChargeCodeInfoArray && apiChargeCodeInfoArray.length > 0) {
      apiChargeCodeInfoArray.map((apiChargeCodeInfo: APIChargeCodeInfo) => {
        chargeCodeInfos.push(this.mapAPIChargeCodeInfo(apiChargeCodeInfo));
      });
    }
    return chargeCodeInfos;
  }

  private static mapAPIChargeCodeInfo(apiChargeCodeInfo: APIChargeCodeInfo): ChargeCodeInfo {
    const chargeCodeInfo: ChargeCodeInfo = new ChargeCodeInfo();
    if (apiChargeCodeInfo && Object.keys(apiChargeCodeInfo).length > 0) {
      Object.assign(chargeCodeInfo, apiChargeCodeInfo);
      chargeCodeInfo.isDisplay = apiChargeCodeInfo.isDisplay;
      chargeCodeInfo.projectType = apiChargeCodeInfo.projectType;
      chargeCodeInfo.contractName = apiChargeCodeInfo.contractName;
      chargeCodeInfo.opportunityID = apiChargeCodeInfo.opportunityId;
      chargeCodeInfo.toolTip = apiChargeCodeInfo.toolTip;
      chargeCodeInfo.hasNetworks = apiChargeCodeInfo.hasNetworks;
      chargeCodeInfo.wbsDescription = apiChargeCodeInfo.wbsDescription;
      chargeCodeInfo.hasDismissCheckboxChecked = apiChargeCodeInfo.hasDismissCheckboxChecked;
      chargeCodeInfo.hasCostCollectors = apiChargeCodeInfo.hasCostCollectors;
      chargeCodeInfo.projectTypeCode = apiChargeCodeInfo.projectTypeCode;
      chargeCodeInfo.treeValidationError = apiChargeCodeInfo.treeValidationError;
      chargeCodeInfo.treeNetworkHierarchy = apiChargeCodeInfo.treeNetworkHierarchy;
      chargeCodeInfo.isLeaf = apiChargeCodeInfo.isLeaf;
      chargeCodeInfo.errorType = apiChargeCodeInfo.errorType;
      chargeCodeInfo.description = apiChargeCodeInfo.description === '' ? '-' : apiChargeCodeInfo.description;
      chargeCodeInfo.clientId = apiChargeCodeInfo.clientId;
      chargeCodeInfo.clientName = apiChargeCodeInfo.clientName;
      chargeCodeInfo.masterServicesAgreement = apiChargeCodeInfo.masterServicesAgreement;
      chargeCodeInfo.owner = apiChargeCodeInfo.owner;
      chargeCodeInfo.country = apiChargeCodeInfo.country;
      chargeCodeInfo.externalNumber = apiChargeCodeInfo.externalNumber;
      chargeCodeInfo.businessActivityLevel = apiChargeCodeInfo.businessActivityLevel;
      chargeCodeInfo.valid = apiChargeCodeInfo.valid;
      chargeCodeInfo.validForUser = apiChargeCodeInfo.validForUser;
      chargeCodeInfo.productivityCategory = apiChargeCodeInfo.productivityCategory;
      chargeCodeInfo.projectDetailInfo = apiChargeCodeInfo.projectDetailInfo;
    }
    return chargeCodeInfo;
  }

  public static mapAPIChargeCodeRelated(apiProjectDescendantTreeNode: APIProjectDescendantTreeNode): Array<ChargeCodeRelated> {
    let chargeCodeRelateds: Array<ChargeCodeRelated> = new Array<ChargeCodeRelated>();
    chargeCodeRelateds = this.getAPIChild(apiProjectDescendantTreeNode, chargeCodeRelateds, apiProjectDescendantTreeNode.networkHierarchyToUse, apiProjectDescendantTreeNode.externalNbr);
    return chargeCodeRelateds;
  }

  private static getAPIChild(apiProjectDescendantTreeNode: APIProjectDescendantTreeNode, chargeCodeRelateds: Array<ChargeCodeRelated>, networkHierarchyToUse: APINetworkHierarchy, externalNbr: string): Array<ChargeCodeRelated> {
    apiProjectDescendantTreeNode.children.forEach(code => {
      if (networkHierarchyToUse === 0)
        chargeCodeRelateds.push(new ChargeCodeRelated(code.type, code.externalNbr, code.description, apiProjectDescendantTreeNode.externalNbr, false));
      else
        chargeCodeRelateds.push(new ChargeCodeRelated(code.type, code.externalNbr, code.description, apiProjectDescendantTreeNode.externalNbr, this.getAPINewHierarchyValidationError(code, apiProjectDescendantTreeNode, externalNbr)));
      if (code.children.length !== 0)
        chargeCodeRelateds = this.getAPIChild(code, chargeCodeRelateds, networkHierarchyToUse, externalNbr);
    });
    return chargeCodeRelateds;
  }

  private static getAPINewHierarchyValidationError(node: APIProjectDescendantTreeNode, parentNode: APIProjectDescendantTreeNode, externalNbr: string): boolean {
    let leaves: Array<APIProjectDescendantTreeNode> = new Array<APIProjectDescendantTreeNode>();
    if (node.children.length != 0) {
      if (this.getAPIAllLeaves(node, leaves).find(leaf => leaf.type === 'Cost Collector'))
        return true;
      if (this.getAPIAllDescendants(node, leaves).find(descendant => descendant.type === 'Network'))
        return true;
    }
    if (parentNode.children.find(child => child.externalNbr !== node.externalNbr && child.type === 'Network' && node.type === 'Cost Collector'))
      return true;
    if (parentNode.children.find(child => child.externalNbr !== node.externalNbr && child.type === 'Network' && node.type === 'Network' && parentNode.externalNbr != externalNbr))
      return true;
    return false;
  }

  private static getAPIAllLeaves(node: APIProjectDescendantTreeNode, leaves: Array<APIProjectDescendantTreeNode>): Array<APIProjectDescendantTreeNode> {
    node.children.forEach(child => {
      if (child.isLeaf)
        leaves.push(child);
      if (child.children.length !== 0)
        leaves = this.getAPIAllLeaves(child, leaves);
    });
    return leaves;
  }

  private static getAPIAllDescendants(node: APIProjectDescendantTreeNode, descendants: Array<APIProjectDescendantTreeNode>): Array<APIProjectDescendantTreeNode> {
    node.children.forEach(child => {
      if (!descendants.find(descendant => descendant.externalNbr === node.externalNbr))
        descendants.push(child);
      if (child.children.length !== 0)
        descendants = this.getAPIAllLeaves(child, descendants);
    });
    return descendants;
  }

  public static mapProjectListInput(chargeCodeInfo: ChargeCodeInfo): APIProjectListInput {
    const apiProjectListInput: APIProjectListInput = new APIProjectListInput();
    const apiProjectDisplayLists: Array<APIProjectDisplayList> = new Array<APIProjectDisplayList>();
    const apiProjectDisplayList: APIProjectDisplayList = new APIProjectDisplayList();
    if (chargeCodeInfo && Object.keys(chargeCodeInfo).length > 0) {
      apiProjectDisplayList.wbsExternalNbr = chargeCodeInfo.externalNumber;
      apiProjectDisplayList.projectType = chargeCodeInfo.projectType;
      apiProjectDisplayList.isDisplay = chargeCodeInfo.isDisplay;
      apiProjectDisplayLists.push(apiProjectDisplayList);
    }
    apiProjectListInput.projects = apiProjectDisplayLists;
    return apiProjectListInput;
  }
}