import AuditCommunicationsLog from './expense-audit-communication-log';
export default class AuditInquiry {
    issueId?: number | null;
    expenseType?: string | null;
    expenseSequenceNbr?: number | null;
    issueStatus?: string | null;
    expenseFromDate?: string | null;
    expenseToDate?: string | null;
    timeReportPeriod?: Date | null;
    originalReimbursementAmount?: string | null;
    originalReimbursementCurrency?: string | null;
    finalReimbursmentAmount?: string | null;
    finalReimbursmentCurrency?: string | null;
    amountToBeAdjusted?: string | null;
    amountToBeAdjustedCurrency?: string | null;
    auditTeamComments?: string | null;
    auditRaisedIssueId?: number | null;
    expenseId?: string | null;
    toBeDisplayedinAuditAdjustment?: boolean | null;
    auditActionRequestShort?: string | null;
    isAgreeInd?: boolean | null;
    isNotificationSent?: boolean | null;
    isCloseIssueInd?: boolean | null;
    closureComments?: string | null;
    auditCommunicationsLogs?: AuditCommunicationsLog[] | null;
    isReversal?: boolean | null;
    shouldDisplayAdditionalDocButton?: boolean | null;
    isAuditDelegate?: boolean | null;

    constructor(
        issueId?: number | null,
        expenseType?: string | null,
        expenseSequenceNbr?: number | null,
        issueStatus?: string | null,
        expenseFromDate?: string | null,
        expenseToDate?: string | null,
        timeReportPeriod?: Date | null,
        originalReimbursementAmount?: string | null,
        originalReimbursementCurrency?: string | null,
        finalReimbursmentAmount?: string | null,
        finalReimbursmentCurrency?: string | null,
        amountToBeAdjusted?: string | null,
        amountToBeAdjustedCurrency?: string | null,
        auditTeamComments?: string | null,
        auditRaisedIssueId?: number | null,
        expenseId?: string | null,
        toBeDisplayedinAuditAdjustment?: boolean | null,
        auditActionRequestShort?: string | null,
        isAgreeInd?: boolean | null,
        isNotificationSent?: boolean | null,
        isCloseIssueInd?: boolean | null,
        closureComments?: string | null,
        auditCommunicationsLogs?: AuditCommunicationsLog[] | null,
        isReversal?: boolean | null,
        shouldDisplayAdditionalDocButton?: boolean | null,
        isAuditDelegate?: boolean | null
    ) {
        this.issueId = issueId;
        this.expenseType = expenseType;
        this.expenseSequenceNbr = expenseSequenceNbr;
        this.issueStatus = issueStatus;
        this.expenseFromDate = expenseFromDate;
        this.expenseToDate = expenseToDate;
        this.timeReportPeriod = timeReportPeriod;
        this.originalReimbursementAmount = originalReimbursementAmount;
        this.originalReimbursementCurrency = originalReimbursementCurrency;
        this.finalReimbursmentAmount = finalReimbursmentAmount;
        this.finalReimbursmentCurrency = finalReimbursmentCurrency;
        this.amountToBeAdjusted = amountToBeAdjusted;
        this.amountToBeAdjustedCurrency = amountToBeAdjustedCurrency;
        this.auditTeamComments = auditTeamComments;
        this.auditRaisedIssueId = auditRaisedIssueId;
        this.expenseId = expenseId;
        this.toBeDisplayedinAuditAdjustment = toBeDisplayedinAuditAdjustment;
        this.auditActionRequestShort = auditActionRequestShort;
        this.isAgreeInd = isAgreeInd;
        this.isNotificationSent = isNotificationSent;
        this.isCloseIssueInd = isCloseIssueInd;
        this.closureComments = closureComments;
        this.auditCommunicationsLogs = auditCommunicationsLogs;
        this.isReversal = isReversal;
        this.shouldDisplayAdditionalDocButton = shouldDisplayAdditionalDocButton;
        this.isAuditDelegate = isAuditDelegate;
    }
}