// Dependeces
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OAuthModule } from 'angular-oauth2-oidc';

// Guards
import { AuthGuard } from './guards/auth.guard';

// Services
import { OAuth2Service } from './services/oauth2.service';
import { AuthService } from './services/auth.service';

@NgModule({
  imports: [
    CommonModule,
    OAuthModule.forRoot()
  ],
  providers: [
    OAuth2Service,
    AuthGuard,
    AuthService
  ]
})

export class AuthModule {}