
import { LocationsByDay } from "@shared/models/locations";
import { TimeReportValidationErrors } from "@shared/models/time-report/time-report-validation-errors";

export class SubmitResponse {
    isTimeSheetSaved: boolean;
    isActivitySaved: boolean;
    isLocationByDaySaved: boolean;
    activityValidationError: boolean;
    activityValidationSubmitError: boolean;
    timeSheetWorkScheduleIncomplete: boolean;
    ignoreSave: boolean;
    timeSheetSaveError: boolean;
    timeSheetSubmitError: boolean;
    locationByDaySaveError: boolean;
    isESTravelPunchClock: boolean;
    shouldSubmit: boolean;
    canViewTravelDiaryLink: boolean;
    locationByDayUpdated: LocationsByDay;
    timeReportValidationErrors: TimeReportValidationErrors
    constructor() {
        this.isTimeSheetSaved = false;
        this.isActivitySaved = false;
        this.isLocationByDaySaved = false;
        this.activityValidationError = false;
        this.activityValidationSubmitError = false;
        this.timeSheetWorkScheduleIncomplete = false;
        this.ignoreSave = false;
        this.timeSheetSaveError = false;
        this.timeSheetSubmitError = false;
        this.locationByDaySaveError = false;
        this.isESTravelPunchClock = false;
        this.shouldSubmit = false;
        this.canViewTravelDiaryLink = false;
        this.locationByDayUpdated = null;
        this.timeReportValidationErrors = new TimeReportValidationErrors();
    }
}