import { ActivityViewOption } from "./activity-view-options"
import { ActivityViewValidation } from "./activity-view-validations"


export class ActivityOutputField {
    public key: string
    public label: string
    public visible: boolean       
    public editable: boolean       
    public required: boolean      
    public type: string
    public value: string
    public options: ActivityViewOption[] = []  
    public validations: ActivityViewValidation[] = []       
    public toolTip: string
    public isAdjustment: boolean  
    public previousPPAValue: string
    public isProcessedData: boolean
    constructor(
        key?: string,
        label?: string,
        visible?:boolean,
        editable?:boolean,
        required?:boolean,
        type? :string,
        value? : string,
        options :ActivityViewOption[] = [],
        validations:ActivityViewValidation[] = [],
        toolTip?:string,
        isAdjustment?:boolean,
        previousPPAValue?:string,
        isProcessedData?:boolean
    ) {
        this.key = key;
        this.label = label;
        this.visible = visible;
        this.editable = editable;
        this.required = required;
        this.type = type;
        this.options = options;
        this.validations = validations;
        this.toolTip = toolTip;
        this.isAdjustment = isAdjustment;
        this.previousPPAValue = previousPPAValue;
        this.value = value;
        this.isProcessedData = isProcessedData;
    }

    public validate(value: string, isTriggeredBySave?:boolean): ActivityViewValidation[] {
        this.validations = [];
        if (this.type == 'Date' && (value =='Invalid Date' || value == undefined))
        {
            this.validations.push(new ActivityViewValidation('Invalid Date', 'Date is invalid'));  
        }
        else if(isTriggeredBySave && this.type == 'TimeList' && value && (value.split(':').includes('0')))
        {
            this.validations.push(new ActivityViewValidation('required', this.label + ' is required'));
        }
        else if (isTriggeredBySave && this.required && (value == "" || value == undefined || value == 'blank'))
        {
          this.validations.push(new ActivityViewValidation('required', this.label + ' is required'));
        }
        
        return this.validations;
      }

      public isValid(): boolean {
        return !(this.validations.length > 0);
      }
    
      public hasRequiredError(): boolean {
        return this.validations.some(err => err.type == 'required');
      }

      public getErrorMessages(): string | string[] {
        return this.toolTip != '' ? this.toolTip : this.validations.slice(-1).filter(err => err.value).map(function(err) {
          return err['value'];
        });
      }

}