import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { TimePeriodPipe } from '../../../shared/pipes/time-period.pipe';
import DropdownItem from '../../../shared/models/controls/dropdown/dropdown-item';
import { DateformatPipe } from '@shared/pipes/dateformat.pipe';
import { FormatDateType } from '@shared/utils/constants';
export default class PeriodChangerDate implements NgbDateStruct {
    year: number;
    month: number;
    day: number;

    constructor(date: Date) {
        this.day = date.getDate();
        this.month = date.getMonth();
        this.year = date.getFullYear();
    }

    public nextPeriod(): PeriodChangerDate {
        let date = this.getDate();
        date.setDate(date.getDate() + 1);
        return new PeriodChangerDate(new TimePeriodPipe().transform(date));
    }

    public previousPeriod(): PeriodChangerDate {
        let date = this.getDate();
        date.setDate(date.getDate() - 16);
        return new PeriodChangerDate(new TimePeriodPipe().transform(date));
    }

    public toString(): string {
        return this.getDate().toLocaleDateString();
    }

    public getDate(): Date {
        return new Date(this.year, this.month, this.day);
    }

    public toDropdownItem(): DropdownItem {
        let date = this.getDate();
        return new DropdownItem(date, new DateformatPipe().transform(date, FormatDateType.FormatDate), true);
    }
}
