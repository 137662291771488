import { Injectable } from '@angular/core';
import { ReplyIssueInput, AgreeDisagreeIssueInput, ExpenseApiClient, AuditInquiryOutput, CloseAuditInquiry } from '../../../../shared/clients/expense-api-client';
import { Observable, of } from 'rxjs';
import AuditInquiry from '../../../../shared/models/expense/audit/expense-audit-inquiry';
import ReplyIssue from '../../../../shared/models/expense/audit/expense-audit-reply-issue';
import { map, catchError } from 'rxjs/operators';
import { LogService } from '../../../../shared/services/log/log.service';
import { GlobalCacheService } from '../../../../shared/services/cache/global-cache.service';
import AgreeIssue from '../../../../shared/models/expense/audit/expense-audit-agree-issue';
import { MyteBaseService } from '../../../../shared/services/myte-base.service';
import { GlobalEventsService } from '../../../../shared/services/events/global-events.service';
import { UserService } from '../../../../shared/services/user/user.service';
import { PeriodEndService } from '../../../../shared/services/periodend/periodend.service';
import { decode } from 'html-entities';
@Injectable()
export class ExpenseAuditService extends MyteBaseService {

  constructor(private api: ExpenseApiClient,
              logService: LogService,
              userService: UserService,
              globalEventsService: GlobalEventsService,
              globalCacheService: GlobalCacheService,
              private periodEndService: PeriodEndService) {
    super(userService, globalCacheService, globalEventsService, logService);
  }

  public mapToAuditInquiries(auditInquiryOutputs: AuditInquiryOutput[]): AuditInquiry[] {
    let result: AuditInquiry[] = [];
    auditInquiryOutputs.forEach(auditInquiryOutput => {
      let auditInquiry = new AuditInquiry();
      auditInquiry.issueId = auditInquiryOutput.issueId;
      auditInquiry.expenseType = auditInquiryOutput.expenseType;
      auditInquiry.expenseSequenceNbr = auditInquiryOutput.expenseSequenceNbr;
      auditInquiry.issueStatus = auditInquiryOutput.issueStatus;
      if (auditInquiryOutput.expenseFromDate.includes('-')) {
        let expenseDates = auditInquiryOutput.expenseFromDate.split('-');
        auditInquiry.expenseFromDate = expenseDates[0].trim();
        auditInquiry.expenseToDate = expenseDates[1].trim();
      } else {
        auditInquiry.expenseFromDate = auditInquiryOutput.expenseFromDate;
        auditInquiry.expenseToDate = null;
      }
      auditInquiry.timeReportPeriod = auditInquiryOutput.timeReportPeriod;
      auditInquiry.originalReimbursementAmount = auditInquiryOutput.originalReimbursementAmount;
      auditInquiry.originalReimbursementCurrency = auditInquiryOutput.originalReimbursementCurrency;
      auditInquiry.finalReimbursmentAmount = auditInquiryOutput.finalReimbursmentAmount;
      auditInquiry.finalReimbursmentCurrency = auditInquiryOutput.finalReimbursmentCurrency;
      auditInquiry.amountToBeAdjusted = auditInquiryOutput.amountToBeAdjusted;
      auditInquiry.amountToBeAdjustedCurrency = auditInquiryOutput.amountToBeAdjustedCurrency;
      auditInquiry.auditTeamComments = decode(auditInquiryOutput.auditTeamComments);
      auditInquiry.auditRaisedIssueId = auditInquiryOutput.auditRaisedIssueId;
      auditInquiry.expenseId = auditInquiryOutput.expenseId;
      auditInquiry.toBeDisplayedinAuditAdjustment = auditInquiryOutput.toBeDisplayedinAuditAdjustment;
      auditInquiry.auditActionRequestShort = auditInquiryOutput.auditActionRequestShort;
      auditInquiry.isAgreeInd = auditInquiryOutput.isAgreeInd;
      auditInquiry.isNotificationSent = auditInquiryOutput.isNotificationSent;
      auditInquiry.closureComments = auditInquiryOutput.issueStatus=='Closed'? decode(auditInquiryOutput.closureComments): '';
      auditInquiry.isCloseIssueInd = auditInquiryOutput.issueStatus=='Closed'?true:false;
      auditInquiry.auditCommunicationsLogs = auditInquiryOutput.auditCommunicationsLogs;
      auditInquiry.isReversal = auditInquiryOutput.isReversal;
      auditInquiry.shouldDisplayAdditionalDocButton = auditInquiryOutput.shouldDisplayAdditionalDocButton;
      auditInquiry.isAuditDelegate=auditInquiryOutput.isAuditDelegate
      result.push(auditInquiry);
    });
    return result;
  }

  public getIssuesByExpense(expenseId: string): Observable<AuditInquiry[]> {
    const loginEidValue = this.userEid?.toLowerCase() == this.loginEid?.toLowerCase() ? undefined : this.loginEid;
    return this.api.getIssuesByExpense(expenseId, this.userEid, this.periodEndService.getActivePeriod(), loginEidValue, this.viewMode)
      .pipe(map(result => this.mapToAuditInquiries(result)))
      .pipe(catchError(err => {
        this.logService.logError(err, true);
        return of(null);
      }))
    ;
  }

  public checkAutoReverseOnClose(expenseId: string): Observable<any> {

    let checkReversed = this.api.checkAutoReverse(expenseId, this.userEid, this.periodEndService.getActivePeriod(), this.supervisorEid, this.viewMode)
    .pipe(map( checkReverse => checkReverse ))
    .pipe(catchError(err => {
        this.logService.logError('Unable to reverse issue', true);
        return of(null);
    }))
  ;    
    return this.globalCacheService.handleAuditInquiry(this.globalCacheService.getPeriodEnd(), checkReversed);
  }

  public replyIssue(replyIssue: ReplyIssue): Observable<any> {
    let replyIssueInput = new ReplyIssueInput();
    replyIssueInput.auditRaiseIssueId = replyIssue.auditRaiseIssueId;
    replyIssueInput.comments = replyIssue.comments;
    replyIssueInput.currentTimePeriodPeriodEnd = replyIssue.currentTimePeriodPeriodEnd;
    replyIssueInput.expenseId = replyIssue.expenseId;
    replyIssueInput.expenseSequenceNbr = replyIssue.expenseSequenceNbr;
    replyIssueInput.isAgree = replyIssue.isAgree;
    replyIssueInput.enterpriseId = this.userEid;
    replyIssueInput.issueStatus = replyIssue.issueStatus;

    let replyIssueRequest = this.api.replyIssue(this.periodEndService.getActivePeriod(), this.supervisorEid, this.viewMode, replyIssueInput)
      .pipe(map( isReplyAccomplished => isReplyAccomplished ))
      .pipe(catchError(err => {
          this.logService.logError('Unable to reply issue', true);
          return of(null);
      }))
    ;

    return this.globalCacheService.handleAuditInquiry(replyIssue.currentTimePeriodPeriodEnd, replyIssueRequest);
  }

  public agreeIssue(agreeIssue: AgreeIssue): Observable<any> {
    let agreeIssueInput = new AgreeDisagreeIssueInput();
    agreeIssueInput.auditRaiseIssueId = agreeIssue.auditRaiseIssueId;4
    agreeIssueInput.comments = agreeIssue.comments;
    agreeIssueInput.currentTimePeriodPeriodEnd = agreeIssue.currentTimePeriodPeriodEnd;
    agreeIssueInput.issueStatus = agreeIssue.issueStatus;
    agreeIssueInput.isAgree = agreeIssue.isAgree;
    agreeIssueInput.enterpriseId = this.userEid;

    let agreeIssueRequest = this.api.agreeOrDisagreeIssue(this.periodEndService.getActivePeriod(), this.supervisorEid, this.viewMode, agreeIssueInput)
    .pipe(map( isAgreeAccomplished => isAgreeAccomplished ))
      .pipe(catchError(err => {
          this.logService.logError('Unable to agree issue', true);
          return of(null);
      }))
    ;

    return this.globalCacheService.handleAuditInquiry(agreeIssue.currentTimePeriodPeriodEnd, agreeIssueRequest);
  }
  
  public getIssuesWithAdjustments(): Observable<AuditInquiry[]> {
    return this.globalCacheService.handleExpenseIssues(this.userEid,
      this.api.getIssuesWithAdjustmentsByEnterpriseId(this.userEid, this.periodEndService.getActivePeriod(), this.supervisorEid, this.viewMode)
        .pipe(map(auditInquiries => {
          return this.mapToAuditInquiries(auditInquiries);
        }))
        .pipe(catchError(err => {
          this.logService.logError(err, true);
          return of(null);
        }))
    );
  }

  public closeIssuesinInquiryPopup(periodEnd: Date, postPayAuditId: number, auditeeEnterpriseId: string, closeAuditInquiries: CloseAuditInquiry[]): Observable<boolean> {
    return this.api
      .closeIssuesinInquiryPopup(
        periodEnd,
        postPayAuditId,
        auditeeEnterpriseId,
        this.loginEid,
        this.viewMode,
        closeAuditInquiries
      )
      .pipe(
        catchError((err) => {
          this.logService.logError(err, true);
          return of(null);
        })
      );
  }
}