<div class="my-app-div-style" *ngIf="!isIframe">
    <!-- Spinner -->
    <div *ngIf="isGlobalSpinnerVisibleStore || isGlobalSpinnerVisible" class="spiner-container">
        <myte-spinner [hasLoadingLabel]="true"></myte-spinner>
        <p class="loading-container">Loading</p>
    </div>
    <span #loadingStatusFinish role="alert" aria-live="polite" aria-hidden="true" aria-label="Loading complete"></span>
    <!-- Universal header and personal Pop-up -->
    <myte-page-header *ngIf="loggedIn && !isLocal"></myte-page-header>
    <!-- Main Content -->
    <router-outlet *ngIf="loggedIn"></router-outlet>
   
    <myte-idle-session-popup *ngIf="showIdlePopup" [time]="timeSessionLeft()" (closeEvent)="closeIdlePopup()"></myte-idle-session-popup>
</div>