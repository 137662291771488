import { Component } from '@angular/core';
import { TimeReportStoreService } from '@shared/services/store/time-report-store.service';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { GlobalCacheService } from '../../../../shared/services/cache/global-cache.service';
import { ExpenseGridEventsService } from '../../../shared/services/events/expense-grid-events.service';
import { ExpenseGridService } from '../../../shared/services/grids/expense-grid.service';
import { ExpenseGridDefaultRendererComponent } from '../expense-grid-default-renderer/expense-grid-default-renderer.component';
import { ActionStoreService } from '@shared/services/store/action-store.service';

@Component({
    selector: 'myte-expense-grid-reason-code',
    templateUrl: './expense-grid-reason-code.component.html',
    styleUrls: ['./expense-grid-reason-code.component.sass']
})
export class ExpenseGridReasonCodeRendererComponent extends ExpenseGridDefaultRendererComponent implements ICellRendererAngularComp {
    public reasonCodes = [];
    constructor(
        timeReportStoreService: TimeReportStoreService,
        protected expenseGridService: ExpenseGridService,
        protected expenseGridEventService: ExpenseGridEventsService,
        public cacheService: GlobalCacheService,
        public actionService: ActionStoreService) {
            super(null, expenseGridService, expenseGridEventService, cacheService, actionService, timeReportStoreService);
    }

    public agInit(params: any): void {
        params?.reasonCodes?.forEach(code => {
            if(code.reasonCode != '')
                this.reasonCodes.push(code);            
        });;
    }

    public invokeParentMethod(): void {
        this.params.context.componentParent.methodFromParent(`Row: ${this.params.node.rowIndex}, Col: ${this.params.colDef.headerName}`);
    }
}
