import { TimeEntryTemplate, TimeEntryTemplateTask } from "@shared/models/time-sheet/time-entry/time-entry-template";
import { DateformatPipe } from "@shared/pipes/dateformat.pipe";
import { FormatDateType } from "@shared/utils/constants";
import { ICellEditorParams, ICellRendererParams } from "ag-grid-community";

export class TimeEntryTemplateGridHelper {
    public static getCellAriaLabel(params: ICellRendererParams<TimeEntryTemplateTask> | ICellEditorParams<TimeEntryTemplateTask>): string {
        if (params?.colDef?.colId === 'Assignment')
            return this.getAssignmentAria(params);

        if (params?.colDef?.colId === 'Total')
            return this.getTotalAria(params);

        return this.getDateAria(params);
    }

    public static getAssignmentAria(params: ICellRendererParams<TimeEntryTemplateTask> | ICellEditorParams<TimeEntryTemplateTask>): string {
        let ariaLabel = '';
        ariaLabel += `${params?.colDef?.headerName} `;
        ariaLabel += `Assignment ${params?.rowIndex + 1} `;

        if (!params?.data?.assignment?.isEmpty) {
            ariaLabel += `WBS ${params?.data?.assignment?.code} `;
            ariaLabel += `${params?.data?.assignment?.description} `;
        } else {
            ariaLabel += `Empty `;
        }

        ariaLabel += params?.data?.assignment?.hasError ? 'with error' : '';
        return ariaLabel;
    }

    public static getTotalAria(params: ICellRendererParams<TimeEntryTemplateTask> | ICellEditorParams<TimeEntryTemplateTask>) {
        let ariaLabel = '';
        ariaLabel += params?.value != '' && params?.value != undefined ? params?.value + ' total hours ' : 'empty total hours ';
        return ariaLabel;
    }

    public static getTotalFooterAria(params: ICellRendererParams<TimeEntryTemplate>, date: any = null): string {
        let ariaLabel = '';

        if (params?.colDef?.colId === 'Assignment')
            return 'Total hours side header';

        ariaLabel += `${params?.value != 0 && params?.value != null ? params?.value : 'empty'} `;
        ariaLabel += 'total hours ';
        ariaLabel += date != null ? `on ${new DateformatPipe().transform(date, FormatDateType.GetFullDay)}` : '';
        return ariaLabel;
    }

    public static getDateAria(params: ICellRendererParams<TimeEntryTemplateTask> | ICellEditorParams<TimeEntryTemplateTask>): string {
        let ariaLabel = '';
        ariaLabel += params?.value != '' && params?.value != undefined ? params?.value + ' hours ' : '';
        ariaLabel += `${new DateformatPipe().transform(params?.colDef?.headerName, FormatDateType.GetFullDay)} `;
        ariaLabel += params?.colDef?.headerComponentParams?.description ? `${params?.colDef?.headerComponentParams?.description} ` : '';
        ariaLabel += !params?.data.assignment.isEmpty ? '' : 'editable';
        return ariaLabel;
    }

    public static getDeleteAlertAria(deletedRowIndex): string {
        let ariaLabel = `Row ${deletedRowIndex + 1} deleted`;
        return ariaLabel;
    }

    public static getChargeCodeSelectAria(chargeCodeDescription): string {
        let ariaLabel = `Charge Code ${chargeCodeDescription} selected`;
        return ariaLabel;
    }

    public static getId(params: ICellRendererParams<TimeEntryTemplateTask>): string {
        if (params?.colDef?.colId === 'Assignment')
            return `assignment-${params?.rowIndex}`

        if (params?.colDef?.colId === 'Total')
            return `template-total-${params?.rowIndex}`
        return `date-${new DateformatPipe().transform(params?.colDef?.headerName, FormatDateType.GetFullDay).toLowerCase()}-${params?.rowIndex}`;
    }

    public static getTotalFooterId(params: ICellRendererParams<TimeEntryTemplate>, date: any = null): string {
        if (params?.colDef?.colId === 'Total')
            return `template-total-grid-total`;

        if (params?.colDef?.colId === "Assignment")
            return 'template-total-grid-side-header';

        return `template-total-grid-${date?.toLowerCase()}`;
    }

    public static setCellId(params: ICellRendererParams<TimeEntryTemplateTask>): string {
        let id = `entryTemplateGridHoursCell-${new DateformatPipe().transform(params?.colDef?.headerName, FormatDateType.GetFullDay).toLowerCase()}-${params?.rowIndex}`;
        params?.eGridCell?.setAttribute('id', id);

        if (params?.colDef?.colId === 'Assignment') {
            id = `entryTemplateGridAssignmentCell-${params?.rowIndex}`;
            params?.eGridCell?.setAttribute('id', id);
            return id;
        }

        if (params?.colDef?.colId === 'Total') {
            id = `entryTemplateGridTotalCell-${params?.rowIndex}`;
            params?.eGridCell?.setAttribute('id', id);
            return id;
        }

        return id;
    }
}