import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DateformatPipe } from '@shared/pipes/dateformat.pipe';
import { AgGridModule } from 'ag-grid-angular';
import { ChargecodesDetailPopupComponent } from '../myte-chargecodes/components/myte-chargecodes-popups/chargecodes-detail-popup/chargecodes-detail-popup.component';
import { ChargecodesRelatedGridRendererComponent } from '../myte-chargecodes/components/myte-chargecodes-popups/chargecodes-related-grid-renderer/chargecodes-related-grid-renderer.component';
import { ChargecodesRelatedComponent } from '../myte-chargecodes/components/myte-chargecodes-popups/chargecodes-related/chargecodes-related.component';
import { LocationGridEventsService } from '../myte-locations/shared/services/locations-events.service';
import { LocationsService } from '../myte-locations/shared/services/locations/locations.service';
import { ChargeabilityImpactReviewPopupComponent } from '../myte-popups/components/chargeability-impact-review-popup/chargeability-impact-review-popup.component';
import { TimePPAReminderPopupComponent } from '../myte-popups/components/time-ppareminder-popup/time-ppareminder-popup.component';
import { TimeRemoveadjustmentPopupComponent } from '../myte-popups/components/time-removeadjustment-popup/time-removeadjustment-popup.component';
import { TimeSubmitPopupComponent } from '../myte-popups/components/time-submit-popup/time-submit-popup.component';
import { SummryCardsComponent } from '../myte-popups/components/time-submit-popup/time-submit-summry-card/summry-cards/summry-cards.component';
import { ChargeabilityCellRendererComponent } from '../myte-summary/components/myte-summary-popups/chargeability-popup/chargeability-cell-renderer/chargeability-cell-renderer.component';
import { ChargeabilityPopupComponent } from '../myte-summary/components/myte-summary-popups/chargeability-popup/chargeability-popup.component';
import { ChargeabilityGridService } from '../myte-summary/shared/services/grids/chargeability-grid.service';
import { TimeSheetService } from '../myte-time/shared/services/timesheet/timesheet.service';
import { ClickOutsideDirective } from '../shared/directives/click-outside/click-outside.directive';
import { InputFilterDirective } from '../shared/directives/input-outside/input-filter.directive';
import { InputLableFilterDirective } from '../shared/directives/input-outside/input-lable-filter.directive';
import { ValidateInputDirective } from '../shared/directives/validate-input/validate-input.directive';
import { PipeModule } from '../shared/pipe.module';
import { SplitPascalCasePipe } from '../shared/pipes/split-pascal-case.pipe';
import { TimeReportStatusToUiStatusPipe } from '../shared/pipes/time-report-status-to-ui-status.pipe';
import { DebounceClickDirective } from './../shared/directives/debounce-click/debounce-click.directive';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { DatePickerDropdownComponent } from './components/date-picker-dropdown/date-picker-dropdown.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { HyperlinkComponent } from './components/hyperlink/hyperlink.component';
import { InOutComponent } from './components/in-out/in-out.component';
import { LabelComponent } from './components/label/label.component';
import { LBDreasonPopupComponent } from './components/lbdreason-popup/lbdreason-popup.component';
import { LocationCapturePopupComponent } from './components/location-capture-popup/location-capture-popup.component';
import { PeoplePickerListComponent } from './components/people-picker/people-picker-list/people-picker-list.component';
import { PeoplePickerMultipleComponent } from './components/people-picker/people-picker-multiple/people-picker-multiple.component';
import { PeoplePickerComponent } from './components/people-picker/people-picker/people-picker.component';
import { PeriodChangerComponent } from './components/period-changer/period-changer.component';
import { TimeReportStatusComponent } from './components/period-changer/timeReport-status/timeReport-status.component';
import { PopUpTemplateComponent } from './components/popup-template/popup-template.component';
import { PpareasonPopupComponent } from './components/ppareason-popup/ppareason-popup.component';
import { RadioButtonComponent } from './components/radio-button/radio-button.component';
import { RepresentDropdownComponent } from './components/represent-dropdown/represent-dropdown.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { TabMenuComponent } from './components/tab-menu/tab-menu.component';
import { TextareaComponent } from './components/textarea/textarea.component';
import { TextboxComponent } from './components/textbox/textbox.component';
import { ToggleComponent } from './components/toggle/toggle.component';
import { focusElementDirective } from './shared/directives/focus-element.directive';
import { HoursInputDirective } from './shared/directives/hours-input.directive';
import { PeoplePickerService } from './shared/services/people-picker/people-picker.service';
import { PrivacyStatementPopupComponent } from './components/privacy-statement-popup/privacy-statement-popup.component';
import { TimeReportHistoryPopupComponent } from '../myte-popups/components/time-report-history-popup/time-report-history-popup.component';
import { TimeReportHistoryDefaultRendererComponent } from '../myte-popups/components/time-report-history-popup/time-report-history-default-renderer/time-report-history-default-renderer.component';
import { TimeReportHistoryStatusRendererComponent } from '../myte-popups/components/time-report-history-popup/time-report-history-status-renderer/time-report-history-status-renderer.component';
import { DebounceChangeDirective } from '@shared/directives/debounce-change/debounce-change.directive';
import { PeriodChangerStoreComponent } from './components/period-changer/period-changer-store.component';
import { DropdownSearchComponent } from './components/dropdown-search/dropdown-search.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ShellModule } from '../app-shell/shell.module';
import { MultipleInOutComponent } from './components/multiple-in-out/multiple-in-out.component';
import { LeaveEncashmentRequestPopupComponent } from '../myte-popups/components/leave-encashment-request-popup/leave-encashment-request-popup.component';
import { MyteAuditCommonButtonsComponent } from './components/myte-audit-common-buttons/myte-audit-common-buttons.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ExpenseGridService } from '../myte-expenses/shared/services/grids/expense-grid.service';
import { ToilRequestPopupComponent } from '../myte-popups/components/toil-request-popup/toil-request-popup.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    MatIconModule,
    PipeModule,
    RouterModule,
    A11yModule,
    AgGridModule,
    ScrollingModule,
    ShellModule,
    MatTooltipModule
  ],
  declarations: [
    DatePickerComponent,
    DatePickerDropdownComponent,
    DropdownComponent,
    TextboxComponent,
    ClickOutsideDirective,
    DebounceClickDirective,
    DebounceChangeDirective,
    CheckboxComponent,
    TextareaComponent,
    RadioButtonComponent,
    ToggleComponent,
    LabelComponent,
    HyperlinkComponent,
    PeriodChangerComponent,
    PeriodChangerStoreComponent,
    TimeSubmitPopupComponent,
    TimeRemoveadjustmentPopupComponent,
    TimePPAReminderPopupComponent,
    TimeReportHistoryPopupComponent,
    TimeReportHistoryDefaultRendererComponent,
    TimeReportHistoryStatusRendererComponent,
    InputLableFilterDirective,
    TabMenuComponent,
    SplitPascalCasePipe,
    TimeReportStatusToUiStatusPipe,
    InOutComponent,
    PeoplePickerComponent,
    PeoplePickerListComponent,
    PeoplePickerMultipleComponent,
    PopUpTemplateComponent,
    ValidateInputDirective,
    InputFilterDirective,
    HoursInputDirective,
    focusElementDirective,
    ChargecodesDetailPopupComponent,
    ChargecodesRelatedComponent,
    ChargecodesRelatedGridRendererComponent,
    SpinnerComponent,
    TimeReportStatusComponent,
    LBDreasonPopupComponent,
    PpareasonPopupComponent,
    LocationCapturePopupComponent,
    ChargeabilityImpactReviewPopupComponent,
    ChargeabilityPopupComponent,
    ChargeabilityCellRendererComponent,
    SummryCardsComponent,
    RepresentDropdownComponent,
    DateformatPipe,
    PrivacyStatementPopupComponent,
    DropdownSearchComponent,
    MultipleInOutComponent,
    LeaveEncashmentRequestPopupComponent,
    MyteAuditCommonButtonsComponent,
    ToilRequestPopupComponent
  ],
  exports: [
    DatePickerComponent,
    DatePickerDropdownComponent,
    DropdownComponent,
    TextboxComponent,
    ClickOutsideDirective,
    DebounceClickDirective,
    DebounceChangeDirective,
    CheckboxComponent,
    TextareaComponent,
    RadioButtonComponent,
    ToggleComponent,
    PeriodChangerComponent,
    LabelComponent,
    HyperlinkComponent,
    TimeSubmitPopupComponent,
    TimeRemoveadjustmentPopupComponent,
    TimePPAReminderPopupComponent,
    TimeReportHistoryPopupComponent,
    TimeReportHistoryDefaultRendererComponent,
    TimeReportHistoryStatusRendererComponent,
    InputLableFilterDirective,
    TabMenuComponent,
    SplitPascalCasePipe,
    TimeReportStatusToUiStatusPipe,
    InOutComponent,
    PeoplePickerComponent,
    PeoplePickerListComponent,
    PeoplePickerMultipleComponent,
    PopUpTemplateComponent,
    ValidateInputDirective,
    InputFilterDirective,
    HoursInputDirective,
    focusElementDirective,
    ChargecodesDetailPopupComponent,
    ChargecodesRelatedComponent,
    ChargecodesRelatedGridRendererComponent,
    SpinnerComponent,
    TimeReportStatusComponent,
    ChargeabilityImpactReviewPopupComponent,
    ChargeabilityPopupComponent,
    ChargeabilityCellRendererComponent,
    RepresentDropdownComponent,
    DateformatPipe,
    PrivacyStatementPopupComponent,
    DropdownSearchComponent,
    ShellModule,
    MultipleInOutComponent,
    LeaveEncashmentRequestPopupComponent,
    MyteAuditCommonButtonsComponent,
    ToilRequestPopupComponent
  ],
  providers: [
    PeoplePickerService,
    InputFilterDirective,
    TimeSheetService,
    LocationsService,
    LocationGridEventsService,
    ChargeabilityGridService,
    ExpenseGridService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class MyteControlsModule { }
