import { DatePipe } from '@angular/common';
import * as moment from 'moment';

export abstract class DateFormatUtils {
    public static browserLanguage: string = navigator.userAgent.search(/Trident/i) > 0 ? navigator['browserLanguage'] : navigator['language'];
    public static formatDate(date: any, language: string): any {
        if (!date) { return date; }

        switch (language) {
            case 'en-IN':
                return date.replace(/\//g, '-');
            case 'ja':
                return this.formatIEPolyfillsDate(date);
            case 'zh-CN':
            case 'zh-SG':
            case 'zh-TW':
            case 'zh':
                // This replace should be checked with the method formatIEPolyfillsDate used in dropdown.component, we should use
                // one universal replace but check the scenarios when the refactor is in progress.
                return this.formatIEPolyfillsDate(date);
            case 'bn':
            case 'bn-IN':
            case 'mr':
                return date;
            default:
                return date.toLocaleString(language);
        }
    }

    /**
     * This method will return a string mask for each language (like for ja will be yyyy/mm/dd)
     * Cases:
     * ja - Japanese
     * zh-hk - Chinese (Hong Kong)
     * zh-cn - Chinese (PRC)
     * zh-sg - Chinese (Singapore)
     * zh-tw - Chinese (Taiwan)
     */
    public static getDateMaskByLanguage(): string {
        const dateToFormat = new Date('December 31, 1991');
        const language = this.browserLanguage;
        switch (language) {
            case 'ja':
                return this.formatIEPolyfillsDate(this.getMaskDate(dateToFormat, language));
            case 'zh-CN':
            case 'zh-SG':
            case 'zh-TW':
            case 'zh':
                // This replace should be checked with the method formatIEPolyfillsDate used in dropdown.component, we should use
                // one universal replace but check the scenarios when the refactor is in progress.
                return this.formatIEPolyfillsDate(this.getMaskDate(dateToFormat, language));
            case 'bn':
                return new DatePipe('en-US').transform(dateToFormat, 'dd/MM/yyyy');
            case 'bn-IN':
            case 'mr':
                return new DatePipe('en-US').transform(dateToFormat, 'dd-MM-yyyy');
            case 'en-IN':
                const dateIndiaEn = dateToFormat.toLocaleString(language, {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit'
                }).replace(/\//g, '-');
                return this.getMaskDate(dateToFormat, language, dateIndiaEn);
            default:
                return this.getMaskDate(dateToFormat, language);
        }
    }

    public static getIndexOfFirstDayOfWeekByLanguage(): number {
        return moment().locale(navigator.language).localeData().firstDayOfWeek();
    }

    public static getFullFormattedDateByLanguage(date: Date): string {
        if (!date) { return; }
        return moment(date).locale(navigator.language).format('dddd, MMMM DD, YYYY');
    }

    public static getDayNameByLanguage(date: Date): string {
        if (!date) { return; }
        return moment(date).locale('en-US').format('ddd');
    }

    public static getFullMonth(date: Date): string {
        if (!date) { return; }
        return moment(date).format('MMMM');
    }

    public static getShortMonth(date: Date): string {
        if (!date) { return; }
        return moment(date).format('MMM');
    }

    public static getShortDayNameByLanguage(date: Date): string {
        if (!date) { return; }
        const arrayList = ['de', 'de-LI', 'de-AT', 'de-DE', 'da', 'nl'];
        return arrayList.filter(a => a == navigator.language).length > 0 ?
            moment(date).locale(navigator.language).format('dd')
            : moment(date).locale(navigator.language).format('ddd');
    }

    public static getFullMonthByLanguage(date: Date): string {
        if (!date) { return; }
        return moment(date).locale(navigator.language).format('MMMM');
    }

    /**
     * Will return an array with day's names by language, with short format sorted by locale.
     */
    public static getWeekDaysShortByLanguage(): string[] {
        moment.locale(navigator.language);
        return moment.weekdaysShort(true);
    }


     /**
     * Will return an array with day's names by language, with short format sorted by locale.
     */
    public static getWeekDaysByLanguage(): string[] {
        moment.locale('en-US');
        return moment.weekdays();
    }
    /**
     * Will return an array with day's names by language, always starting with Monday with long format.
     */
    public static getWeekDaysByLanguageStartingMonday(): string[] {
        let days: string[] = [];
        for (let index = 1; index < 8; index++) {
            days.push(moment().locale(navigator.language).isoWeekday(index).format('dddd'));
        }
        return days;
    }

    public static formatIEPolyfillsDate(date: any): string {
        if (!date) { return date; }
        switch (navigator.language) {
            case 'zh-CN':
            case 'zh-SG':
            case 'zh-TW':
            case 'zh':
                return date.replace('年', '/').replace('月', '/').replace('日', '');
            case 'ja':
                return date.replace('年', '/').replace('月', '/').replace('日', '');
            default:
                return date;
        }
    }

    public static getDateTwoDigit(date: Date, isAppOvertime: boolean = false, isExpHistory: boolean = false): any {
        const language = this.browserLanguage;
        let twoDigit = new DatePipe('en-US').transform(date, 'dd-MM-yyyy');

        switch (language) {
            case 'bn':
                return twoDigit + (isExpHistory ? ' ' + moment(date).format('LTS') : '');
            case 'bn-IN':
            case 'mr':
                return  twoDigit +  (isExpHistory ? ' ' + moment(date).format('LTS') : '');
            case 'en-IN':
                if (isAppOvertime)
                    return twoDigit + (isExpHistory ? ' ' + moment(date).format('LTS') : '');
            default:
                return this.formatIEPolyfillsDate(date.toLocaleString(language, {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit'}));
        }
    }

    public static getDateByLanguage(date: string): any {
        if (!date) { return date; }
        const language = this.browserLanguage;
        switch (language) {
            case 'en-IN':
                return moment(date, 'DD-MM-YYYY').toDate();
            case 'mr':
                return new DatePipe('en-US').transform(date, 'dd-MM-yyyy');
            case 'bn':
                return new DatePipe('en-US').transform(date, 'dd/MM/yyyy');
            default:
                return moment(date, 'L', language).toDate();
        }
    }
    public static convertDateToStringByLanguage(date: Date): any {
        if (!date) { return date; }
        const language = this.browserLanguage;
        let dateStr = date.toLocaleString(language, {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        });
        switch (language) {
            case 'bn':
                return new DatePipe('en-US').transform(date, 'dd/MM/yyyy');
            case 'bn-IN':
            case 'mr':
                return new DatePipe('en-US').transform(date, 'dd-MM-yyyy');
            case 'en-IN':
                return dateStr.replace(/\//g, '-');
            default:
                return this.formatIEPolyfillsDate(dateStr);
        }
    }

    public static convertDateToStringWithTime(data: any): any {
        const language = this.browserLanguage;
        switch (language) {
            case 'DE':
            case 'de':
                return moment(data.value , language).format('MM.DD.YYYY hh:mm:ss A');
            case 'zh-CN':
            case 'zh-SG':
            case 'zh-TW':
            case 'zh':
                return moment(data.value , language).format('MM/DD/YYYY hh:mm:ss A');
            case 'bn':
            case 'bn-IN':
            case 'mr':
                return this.getDateTwoDigit(data) + ' ' + moment(data.value).format('LTS');
            default:
                return this.formatDate(new Date(data.value).toLocaleString(), language).replace(',', '');
        }
    }

    public static convertToLocaleDateString(date: Date): any {
        if (!date) { return date; }
        const language = this.browserLanguage;
        switch (language) {
            case 'bn':
                return new DatePipe('en-US').transform(date, 'dd/MM/yyyy');
            case 'bn-IN':
            case 'mr':
                return new DatePipe('en-US').transform(date, 'dd-MM-yyyy');
            default:
                return date.toLocaleDateString(language);
        }
    }

    public static getBrowserDateFormat(): string {
        switch (this.browserLanguage) {
          case 'bn':
            return 'DD/MM/YYYY';
          case 'bn-IN':
          case 'mr':
            return 'DD-MM-YYYY';
          default:
            return new Date('2019/12/31').toLocaleDateString(this.browserLanguage)
            .replace('2019', 'YYYY').replace('12', 'MM').replace('31', 'DD');
        }
      }

    private static getMaskDate(date: Date, language: string, formatedDate?: string): string {
        let localDate = formatedDate ? formatedDate : date.toLocaleString(language, {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        });

        const year = date.toLocaleString(language, { year: 'numeric' }).replace(/\D/g, '');
        const month = date.toLocaleString(language, { month: '2-digit' }).replace(/\D/g, '');
        const day = date.toLocaleString(language, { day: '2-digit' }).replace(/\D/g, '');

        localDate = localDate.replace(year, 'yyyy' );
        localDate = localDate.replace(month, 'mm' );
        localDate = localDate.replace(day, 'dd' );
        return localDate;
    }

    public static getFullNameDay(shortDayName: string) {
    switch (shortDayName) {
      case 'Sun':
      return 'Sunday';
      case 'Mon':
      return 'Monday';
      case 'Tue':
      return 'Tuesday';
      case 'Wed':
      return 'Wednesday';
      case 'Thur':
      return 'Thursday';
      case 'Thu':
      return 'Thursday';
      case 'Fri':
      return 'Friday';
      case 'Sat':
      return 'Saturday';
      default:
        return '';
    }
  }
}