import { Observable, of } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

/** Buffer Size for ShareReplay */
const CACHE_SIZE = 1;
/** Expiration Time in Minutes */
const DEFAULT_EXPIRATION = 5;

/**
 * @summary This type of cache only exists during the life cicle of the Angular application
 * or until the expiration `time === now`.
 * @param T Key Type
 * @param I DataType
 */
export interface CacheItem<T, I> {
    key: T;
    data: Observable<I>;
    rawData: I;
    expirationDate: Date;
}

/**
 * @summary This type of cache save data in the local storage of the browser.
 * Will be destroyed only if the user clears the browser cache or until the
 * expiration `time === now`.
 * @param T DataType
*/
export interface LocalStorageCacheItem<T> {
    data: T;
    expirationDate: Date;
}

/**
 * @summary Base Abstract Class for Cache Service Implements the basic operations for Memory Cache and Local Storage Cache
 * @remarks This service use `CacheItem` interface as a buffer in the Memory Cache and `LocalStorageCacheItem`
 * interface as a buffer in the Local Storage.
 * @author m.fernandez.bortolas
 */
export abstract class BaseCacheService {
    public abstract clearLocalStorage(): void;
    public abstract clearSessionStorage(): void;
    public abstract clearBufferCache(): void;

    //#region Local Storage
    /**
     * @summary Use this method to save a buffer in the Browser Local Storage, the life time of this buffer
     * will be equal to the property `exprationDate` of the interface `LocalStorageCacheItem`.
     * @param key This is the key to identify the buffer in the Local Storage
     * @param buffer This is the buffer that will be saved
     * @param T The type of the data inside the buffer
     */
    protected saveInLocalStorage<T>(key, buffer: LocalStorageCacheItem<T>): void {
        let btoaData = btoa(escape(encodeURIComponent(JSON.stringify(buffer))));
        localStorage.setItem(key, btoaData);
    }

    /**
     * @summary Check if the buffer with the key provided in the parameter exist, if so,
     * then return it, otherwise return null.
     * @param key This is the key to identify the buffer in the Local Storage
     * @param T The type of the data inside the buffer
     * @returns A item of type `LocalStorageCacheItem<T>` if exist or null if not.
     */
    protected isInLocalStorage<T>(key: string): LocalStorageCacheItem<T> {
        let item = localStorage.getItem(key);
        if (item != null) {
            let cacheData = JSON.parse(decodeURIComponent(unescape(atob(item)))) as LocalStorageCacheItem<T>;
            if (cacheData.expirationDate.valueOf() <= new Date().valueOf()) {
                this.resetLocalStorageByKey(key);
                return null;
            } else {
                return cacheData;
            }
        } else {
            return null;
        }
    }

    /**
     * @summary Remove a item from a local storage buffer
     * @param key Key that represent the item that will be deleted.
     */
    protected resetLocalStorageByKey(key): void {
      localStorage.removeItem(key);
    }
    //#endregion

    //#region Session Storage
    /**
     * @summary Use this method to save a data in the Browser Session Storage.
     * The data is cleared when the page session ends.
     * @param key This is the key to identify the buffer in the Session Storage
     * @param data This is the data that will be saved
     * @param T The type of the data
     */
    protected saveInSessionStorage<T>(key: string, data: T): void {
        let btoaData;
        if (data instanceof Date) {
            btoaData = btoa(escape(encodeURIComponent(JSON.stringify(data.toISOStringMyTE()))));
        } else {
            btoaData = btoa(escape(encodeURIComponent(JSON.stringify(data))));
        }
        sessionStorage.setItem(key, btoaData);
    }

    /**
     * @summary Check if the data with the key provided in the parameter exist, if so,
     * then return it, otherwise return null.
     * @param key This is the key to identify the data in the Session Storage
     * @param T The type of the data
     * @returns A item of type `T` if exist or null if not.
     */
    protected isInSessionStorage<T>(key: string): T {
        let item = sessionStorage.getItem(key);
        if (!item) return null;
        return JSON.parse(decodeURIComponent(unescape(atob(item)))) as T;
    }

    /**
     * @summary Remove a item from a Session Storage
     * @param key Key that represent the item that will be removed.
     */
    protected resetSessionStorageByKey(key: string): void {
      sessionStorage.removeItem(key);
    }

    //#endregion

    //#region Buffer Memory
    /**
     * @summary Use this method to save data in a buffer memory, the life time of the data will be
     * equal to the property `exprationDate` of the interface `CacheItem` or until the Angular life
     * time ends.
     * @param buffer This is the buffer in which it will be save the data returned in the `request` param.
     * This buffer needs to be initialized
     * @param key This is the buffer that will be saved
     * @param request This is an Observable that represent the response of the API.
     * @param hasExpirationDate This value is used to store data in buffer without expiration.
     * @param rawData This is the data ready to be saved.
     * @returns The item saved in buffer memory of type `CacheItem<T,I>`
     */
    protected saveInBuffer<T, I>(buffer: CacheItem<T, I>[], key: any, request?: Observable<any>, hasExpirationDate: boolean = true, rawData?: I): CacheItem<T, I> {
        if (buffer === null || buffer === undefined) throw new Error('Buffer is not Initialized');

        if (key instanceof Date) {
            key = key.toLocaleDateString();
        }

        let cache: CacheItem<T, I> = {
            key: key,
            data: request ? request.pipe(shareReplay(CACHE_SIZE)) : of(null),
            rawData: rawData,
            expirationDate: hasExpirationDate ? new Date(new Date().getTime() + DEFAULT_EXPIRATION * 60000) : null
        };

        buffer.push(cache);
        
        return cache;
    }

    /**
     * @summary Check if the buffer with the key provided in the parameter exist, if so,
     * then return it, otherwise return null.
     * @param buffer The buffer in which it will done the operation
     * @param key Search key
     * @returns A item of type `CacheItem<T,I>` if exist or null if not.
     */
    protected isInBuffer<T, I>(buffer: CacheItem<T, I>[], key: any): CacheItem<T, I> {
        if (key instanceof Date) {
            key = key.toLocaleDateString();
        }

        let cache = buffer.find(c => c.key.valueOf() == key.valueOf());
        if (cache && cache.expirationDate && cache.expirationDate.valueOf() <= new Date().valueOf()) {
            this.resetBufferByKey(buffer, key);
            cache = null;
        }

        return cache;
    }

    /**
     * @summary Remove a item from a buffer
     * @param buffer The buffer in which it will done the operation
     * @param key Key that represent the item that will be deleted.
     */
    protected resetBufferByKey<T, I>(buffer: CacheItem<T, I>[], key: any): void {
        if (key instanceof Date) {
            key = key.toLocaleDateString();
        }

        let index = buffer.findIndex(x => x.key.valueOf() == key.valueOf());
        if (index >= 0) {
            buffer.splice(index, 1);
        }
    }

    //#endregion
}
