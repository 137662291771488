import { AuthService } from './../../../auth/services/auth.service';
import { Component, OnInit } from '@angular/core';
import { GlobalCacheService } from '../../../shared/services/cache/global-cache.service';


@Component({
  selector: 'myte-page-logout',
  templateUrl: './page-logout.component.html',
  styleUrls: ['./page-logout.component.scss']
})
export class PageLogoutComponent implements OnInit {
  constructor(private authService: AuthService,
    private cacheService: GlobalCacheService) {}

  ngOnInit() {
    this.cacheService.clearSessionStorage();
    this.cacheService.clearLocalStorage();
    this.authService.logout();
  }
}
