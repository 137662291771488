import { ValidationErrorOutPut } from '@sharedClients/timereport-api-client';
import TimeEntryTask from './time-entry-task';
import TimeEntryTotals from './time-entry-totals';

export default class TimeEntry {
    public tasks: TimeEntryTask[] = [];
    public totals: TimeEntryTotals[];
    public validationErrorOutPut: ValidationErrorOutPut[];
    parserDate(date) {
        var t = Date.parse(date);
        if (!isNaN(t)) {
          return new Date(Date.parse(date));
        }
    }
    public recalculateTotals() {
        this.totals = []
        if (this.tasks.length == 0) return;
        this.tasks[0].charges.forEach(() => this.totals.push(new TimeEntryTotals));

        for (let i = 0; i < this.tasks.length; i++) {            
            let isNotSummableRow: boolean = this.tasks[i].assignment.code == "Assigned Location" || this.tasks[i].assignment.code == "Company Code/Cost Center" || this.tasks[i].assignment.code == "Work Location";
            if( isNotSummableRow)
                 continue;            
            for (let j = 0; j < this.tasks[i].charges.length; j++) {
                this.totals[j].date = this.tasks[i].charges[j].date;
                this.totals[j].totals += this.tasks[i].charges[j].value;
                this.totals[j].hasAdjustment = this.tasks[i].charges[j].hasAdjustment ? true : this.totals[j].hasAdjustment;
                this.totals[j].hasError = (this.tasks[i].charges[j].displayErrorInTotalHour ? true : this.totals[j].hasError);                 

                if (this.tasks[i].charges[j].displayErrorInTotalHour) {
                    let validationErrorForDay = this.validationErrorOutPut ? this.validationErrorOutPut.filter(v => typeof v.errorSource === "string" && this.parserDate(v.errorSource)?.getTime() == this.tasks[i].charges[j].date.getTime()) : null ;
                    this.totals[j].tooltip = validationErrorForDay ? validationErrorForDay[0].errorMsg : this.tasks[i].charges[j].tooltip;
                    this.totals[j].errorType = validationErrorForDay ? validationErrorForDay[0].errorType : this.tasks[i].charges[j].errorType;
                } 
                else {
                    this.totals[j].tooltip = this.totals[j].tooltip;
                }
            }                   
        }
    }
}