<div class="popup-mask" id="myte-popup" aria-labelledby="title" role="dialog" aria-modal="true" cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
    <div *ngIf="!loading"
            [ngClass]="{
                'popup-container': !isPersonalPopup,
                'popup-container-personal': isPersonalPopup,
                'popup-container-summary-cards': hasSummaryCard,
                'popup-container-punchclock-US': isPunchClock,
                'popup-container-restHours': isRestHours,
                'popup-container-idleSessionPopup': isIdleSessionPopup
            }" [@enterAnimation]>
        <div class="header"
                [ngClass]="{ 'popup-mask-summary-cards-container-header': hasSummaryCard, 'popup-header-punchclock-US': isPunchClock}">
            <div *ngIf="hasSummaryCard" class="title">
                <ng-content select=".popup-header-title"></ng-content>
            </div>
 
            <div *ngIf="!hasSummaryCard" class="title">
                <h1 class="title" attr.aria-label="title" id="title"
                    [ngClass]="{'title-punchclock-US': isPunchClock}"
                >{{ title }}</h1>
            </div>

            <div class="controls">
                <ng-content select=".popup-header-controls"></ng-content>
            </div>

            <button *ngIf="displayCloseButton" id="close-popup" type="button" class="btn-close" [attr.aria-label]="hasSummaryCard ? 'Time Report Summary Close Popup' : 'Close Popup'" (click)="closePopup()" tabindex="0" focus="true" [disabled]="isNeedCloseDisable" (focus)="onFocusCloseButton()" (blur)="onBlurCloseButton()"></button>
        </div>
        
        <div *ngIf="hasSummaryCard" 
            [ngClass]="{'summary-cards-section':hasSummaryCard}">
            <ng-content select=".summry-cards-container"></ng-content>
        </div>

        <div *ngIf="hasSummaryCard" class="summary-cards-title-container">
            <h1 class="title" [attr.aria-label]="title" id="title" tabindex="0" aria-describedby="popupmain">{{ title }}</h1>
        </div>

        <div class="main" role="main" [ngStyle]="{'padding': '0px', 'margin': '0px'}">
            <ng-content select=".popup-main"></ng-content>
        </div>
        <div class="footer" role="contentinfo">
            <ng-content select=".popup-footer"></ng-content>
        </div>
    </div>
    <div class="spinner-container" *ngIf="loading">
        <myte-spinner></myte-spinner>
    </div>
</div>
