import TimeReport from '@sharedModels/time-report/time-report';

export abstract class TimeReportUtils {
    public static mapAvailableAction(tr: TimeReport, viewMode: string): string {
        switch (tr.status) {
            case 'Submitted':
            return 'Update Time Report';

            case 'SubmittedPendingApproval':
            if (viewMode == 'Approver')
                return 'Submit'
            else
                return 'Update Time Report';

            case 'Processed':
            return null;

            default:
            return 'Submit';
        }
    }

    public static createTimeReport(period: Date, status: string, viewMode: string): TimeReport {
        let tr = new TimeReport();
        tr.periodEnd = period;
        tr.status = status;
        tr.sourceSystem = '';
        tr.availableAction = TimeReportUtils.mapAvailableAction(tr, viewMode);
        return tr;
    }
}