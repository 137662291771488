import { NumberFormatUtils } from "../../utils/numberFormater.utils";


export class ExpensesCard {
    public dictionaryExpenses = new Map<string,{ expenseType: string, amount: number, tooltip: string}>();
    public totalAmount = 0;

    constructor () {
    }
}

export class LocationCard {
    public fullLocation: string;
    public days: string;
    public hours: number;
    public lastDayAdded: number;
    public reasonForTravelLocation: string;

    constructor (fullLocation: string, days: string, hours: number, shouldUseTwoDecimal: boolean, reasonForTravelLocation: string) {
        this.fullLocation = fullLocation;
        this.days = days;
        this.hours = NumberFormatUtils.formatNumberToLocaleString(hours, shouldUseTwoDecimal ? 2 : 1, shouldUseTwoDecimal ? 2 : 1);;
        this.lastDayAdded = null;
        this.reasonForTravelLocation = reasonForTravelLocation;
    }
    public getFormatedDay(): string {
        return this.days.charAt(0).toUpperCase() + this.days.slice(1);
    }
}

export class AssignmentSummary {
    public description: string;
    public code: string;
    public hours: number;

    constructor(description: string, code: string, hours: number, shouldUseTwoDecimal: boolean) {
        this.description = description;
        this.code = code;
        this.hours = NumberFormatUtils.formatNumberToLocaleString(hours, shouldUseTwoDecimal ? 2 : 1, shouldUseTwoDecimal ? 2 : 1);
    }
}
