export class ExpenseDate {
    public value: any;
    public isValid: boolean;
    public validationErrors : string;

    constructor(date?: any, isValid?: boolean, validationErrors?: string) {
        this.value = date;
        this.isValid = isValid;
        this.validationErrors = validationErrors;
    }
    
}