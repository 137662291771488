export class CompleteAdjustmentResponse {
    activityValidationError: boolean;
    activityValidationSubmitError: boolean;
    timeSheetSaveError: boolean;
    timeSheetSubmitError: boolean;
    locationByDaySaveError: boolean;
    isESTravelPunchClock: boolean;
    shouldSubmit: boolean;
    constructor() {
        this.activityValidationError = false;
        this.activityValidationSubmitError = false;
        this.timeSheetSaveError = false;
        this.timeSheetSubmitError = false;
        this.locationByDaySaveError = false;
        this.isESTravelPunchClock = false;
        this.shouldSubmit = true;
    }
}