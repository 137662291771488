export class TimeReportSummaryRow {
    public chargeCode: string;
    public currentHours: string;
    public expensesAmount: string;
    public chargeableHours: string;
    public clientFacingHours: string;
    public marketFacingHours: string;
    public recoveryHours: string;
    public otherHours: string;
    public absencesHours: string;
    public adjustedHours: string;
    public adjustedExpensesAmount: string;
    public totalHours: string;
    public totalExpensesAmount: string;

    constructor(chargeCode?: string, currentHours?: string, expensesAmount?: string, chargeableHours?: string, clientFacingHours?: string,
        marketFacingHours?: string, recoveryHours?: string, otherHours?: string, absencesHours?: string, adjustedHours?: string,
        adjustedExpensesAmount?: string, totalHours?: string, totalExpensesAmount?: string) {
    
        this.chargeCode = chargeCode;
        this.currentHours = currentHours;
        this.expensesAmount = expensesAmount;
        this.chargeableHours = chargeableHours;
        this.clientFacingHours = clientFacingHours;
        this.marketFacingHours = marketFacingHours;
        this.recoveryHours = recoveryHours;
        this.otherHours = otherHours;
        this.absencesHours = absencesHours;
        this.adjustedHours = adjustedHours;
        this.adjustedExpensesAmount = adjustedExpensesAmount;
        this.totalHours = totalHours;
        this.totalExpensesAmount = totalExpensesAmount;
    }
}