import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { TimeEntryTemplateTask } from '@shared/models/time-sheet/time-entry/time-entry-template';
import { GlobalCacheService } from '@sharedServices/cache/global-cache.service';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { IAfterGuiAttachedParams, ICellEditorParams } from 'ag-grid-community';
import { NumberFormatUtils } from '../../../../../shared/utils/numberFormater.utils';

@Component({
    selector: 'myte-time-entry-template-popup-grid-cell-editor',
    templateUrl: './time-entry-template-popup-grid-cell-editor.html',
    styleUrls: ['./time-entry-template-popup-grid-cell-editor.sass']
})
export class TimeEntryTemplatePopupGridCellEditor implements ICellEditorAngularComp {
  public params: ICellEditorParams<TimeEntryTemplateTask, number> & {ariaLabel: string};
  public value: any;
  private shouldUseTwoDecimal: boolean;
  @ViewChild('hoursInput') hoursInput: ElementRef;

  constructor(private globalCacheService: GlobalCacheService,) { }

  @HostListener('keydown', ['$event']) public onKeyDown(event) {
    if (event.code === 'Space') {
      event.preventDefault();
      this.params?.node?.setSelected(true);
    }
  }

  public agInit(params: ICellEditorParams<TimeEntryTemplateTask, number> & {ariaLabel: string}): void {
    this.params = params;
    this.value = params.value ? NumberFormatUtils.formatNumberToLocaleString(params.value) : "";
    let periodEnd = this.globalCacheService.getPeriodEnd();
      this.globalCacheService.getTimeReportSettings(periodEnd, this.globalCacheService.getUserCountry()).subscribe(usersetting => 
        this.shouldUseTwoDecimal = usersetting?.shouldUseTwoDecimal )
    }

  public getValue(): number {
    this.value = this.value ? (this.value as string).replace(",", ".") : "0";
    const parsedNumber = Number(this.value);
    return isNaN(parsedNumber) || parsedNumber <= 0 || parsedNumber > 24
      ? null
      : this.toFixedTrunc(parsedNumber, this.shouldUseTwoDecimal ? 2 : 1);
  }

  public isCancelBeforeStart?(): boolean {
    return false;
  }

  public isCancelAfterEnd?(): boolean {
    return false;
  }

  public textEntryBlur(): void {
    this.params.api.stopEditing();
    this.params.api.clearFocusedCell();
  }

  public afterGuiAttached?(params?: IAfterGuiAttachedParams): void {
    this.hoursInput.nativeElement.focus();
    this.hoursInput.nativeElement.select();
  }

  private toFixedTrunc(x: number, n: number): number {
    const reg = new RegExp("^-?\\d+(?:\\.\\d{0," + n + "})?", "g")
    const a = x.toString().match(reg)[0];
    const dot = a.indexOf(".");
    if (dot === -1) {
      return Number(a + "." + "0".repeat(n));
    }
    const b = n - (a.length - dot) + 1;
    return Number(b > 0 ? (a + "0".repeat(b)) : a);
  }
}