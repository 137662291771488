import { Injectable } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { BehaviorSubject, Observable, forkJoin, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { PeoplePicker, defaultProfilePicture, ProfilePicture } from '../../../../shared/models/controls/people-picker/people-picker';
import { LogService } from '../../../../shared/services/log/log.service';
import { PeoplePickerSelected } from '../../../../shared/models/controls/people-picker/people-picker-selected';
import { TimeReportApiClient, PeoplePickerResult } from  '../../../../shared/clients/timereport-api-client';
import { GlobalCacheService } from '../../../../shared/services/cache/global-cache.service';
import { UserService } from '../../../../shared/services/user/user.service';
import { PeriodEndService } from '../../../../shared/services/periodend/periodend.service';
import { SubordinatesMode } from '@shared/models/subordinates/subordinate-mode';

@Injectable()
export class PeoplePickerService {

  private _peopleList: BehaviorSubject<PeoplePicker[]>;

  private dataStore: {
    selectedPeople: PeoplePicker[];
  };

  constructor(
    private api: TimeReportApiClient,
    private logService: LogService,
    private domSanitizer: DomSanitizer,
    private globalCacheService: GlobalCacheService,
    private userService: UserService,
    private periodEndService:PeriodEndService
  ) {
    this.dataStore = { selectedPeople: [] };
    this._peopleList = new BehaviorSubject<PeoplePicker[]>([]);
  }

  get selectedPeople(): Observable<PeoplePicker[]> {
    return this._peopleList.asObservable();
  }

  initializeList(value: string) {
    let enterpriseIds = value.split('|').filter(item => item);
    let peopleSearched = enterpriseIds.map(id => new PeoplePicker(null, id));
    this.getPeoplePickerList(enterpriseIds, peopleSearched)
      .subscribe(result => {
        this.dataStore.selectedPeople = result.map(peopleSelected => peopleSelected.people);
        this._peopleList.next(Object.assign({}, this.dataStore).selectedPeople);
      });
  }

  addPeople(people: PeoplePicker) {
    this.dataStore.selectedPeople.push(people);
    this._peopleList.next(Object.assign({}, this.dataStore).selectedPeople);
    return of(null);
  }

  checkList(peopleList: PeoplePicker[]) {
    return peopleList.map(peopleSearched => {
      return this.searchPeopleFromService(peopleSearched.enterpriseId)
            .pipe(map(result => this.mapToPeoplePickerSelected(result, peopleSearched)))
            .pipe(catchError(err => {
              this.logService.logError(err, true);
              return of(new PeoplePickerSelected(peopleSearched, false));
            }));
    });
  }

  getPeoplePickerList(enterpriseIdList: string[], peopleList: PeoplePicker[]) {
    return this.searchPeopleListFromService(enterpriseIdList)
      .pipe(map(result => {
         return peopleList.map( peopleSearched => this.mapToPeoplePickerSelected(result, peopleSearched)) 
      }))
        .pipe(catchError(err => {
          this.logService.logError(err, true);
          let result : PeoplePickerSelected[] = []
          peopleList.map( peopleSearched => result.push(new PeoplePickerSelected(peopleSearched, false)))
          return of(result);
        }))
  }

  addMultiple(peoplelist: PeoplePicker[]) {
    peoplelist = peoplelist.filter(p => !this.dataStore.selectedPeople.map(sp => sp.enterpriseId).includes(p.enterpriseId));
    this.dataStore.selectedPeople.push(...peoplelist);
    this._peopleList.next(Object.assign({}, this.dataStore).selectedPeople);
    return of(null);
  }

  removePeople(people: PeoplePicker) {
    this.dataStore.selectedPeople = this.dataStore.selectedPeople.filter(
      p => p.enterpriseId != people.enterpriseId
    );
    this._peopleList.next(Object.assign({}, this.dataStore).selectedPeople);
    return of(null);
  }

  getSuggestions(term: string): Observable<PeoplePicker[]> {
    return this.searchPeopleFromService(term,this.selectedPeopleList());
  }

  getSuggestionsForSubordinates(term: string): Observable<PeoplePicker[]> {
    const viewMode = this.globalCacheService.getSubordinateMode();
    const userEid = this.userService.getUser().enterpriseId;
    const periodEnd = this.globalCacheService.getPeriodEnd();
    const isRepresent = true;
    return this.searchPeopleFromService(term, this.selectedPeopleList(),userEid, viewMode, periodEnd,isRepresent);
  }

  getSuggestionsForDelegateSubordinates(term: string, subordinatesMode: string, userEid: string): Observable<PeoplePicker[]> {
    const periodEnd = this.globalCacheService.getPeriodEnd();
    return this.searchPeopleFromService(term, this.selectedPeopleList(), userEid, subordinatesMode, periodEnd);
  }

  getSuggestionsForApprovee(term: string, expenseSubornidatesMode: string = null, specialApprover: string = null): Observable<PeoplePicker[]> {
    const viewMode = expenseSubornidatesMode != null ?  expenseSubornidatesMode : specialApprover !== '' ? specialApprover : this.globalCacheService.getSubordinateMode();
    const userEid = this.userService.getUser().enterpriseId;
    const periodEnd = this.globalCacheService.getPeriodEnd();
    return this.searchPeopleFromService(term, this.selectedPeopleList(),userEid, viewMode, periodEnd);
  }

  //To get people list for OvertimeApprover or AllowanceApprover
  getSuggestionsForApprover(term: string, viewMode: SubordinatesMode): Observable<PeoplePicker[]> {
    const userEid = this.userService.getUser().enterpriseId;
    const periodEnd = this.globalCacheService.getPeriodEnd();
    return this.searchPeopleFromService(term, this.selectedPeopleList(),userEid, viewMode, periodEnd);
  }

  getSuggestionsForServiceDeliveryLead(term: string): Observable<PeoplePicker[]> {
    const viewMode = this.globalCacheService.getSubordinateModeForSDL();
    const userEid = this.userService.getUser().enterpriseId;
    const periodEnd = this.globalCacheService.getPeriodEnd();
    return this.searchPeopleFromService(term, this.selectedPeopleList(),userEid, viewMode, periodEnd);
  }

  getProfilePicture(enterpriseId: string): Observable<ProfilePicture> {
    var secret = ""
    var i = 0,
    oJson = {},
    sKey;
    for (; sKey = window.localStorage.key(i); i++) {
        if(sKey.includes("login.windows.net-idtoken"))
        oJson[sKey] = window.localStorage.getItem(sKey);
    }
    
    if(oJson[Object.keys(oJson)[0]] != undefined) {
      var a = JSON.parse(oJson[Object.keys(oJson)[0]])
      secret = a.secret
    }

    return this.api.getProfilePicture(enterpriseId, this.periodEndService.getActivePeriod(), secret, "Next")
          .pipe(map(profilePicture => this.mapProfilePicture(profilePicture, enterpriseId)))
          .pipe(catchError(err => {
            this.logService.logError(err, true);
            return of(err);
          }));
  }

  getProfilePictureList(enterpriseIds: string[]): Observable<ProfilePicture[]> {
    var secret = "";
    var i = 0,
    oJson = {},
    sKey;
    for (; sKey = window.localStorage.key(i); i++) {
        if(sKey.includes("login.windows.net-idtoken"))
        oJson[sKey] = window.localStorage.getItem(sKey);
    }

    if(oJson[Object.keys(oJson)[0]] != undefined) {
      var a = JSON.parse(oJson[Object.keys(oJson)[0]])
      secret = a.secret
    }

    return this.api.getProfilePictureList(secret, "Next", enterpriseIds)
    .pipe( map(
      profilePictures => this.mapProfilePictureList(profilePictures, enterpriseIds)
    )).pipe(catchError(err => {
        this.logService.logError(err, true);
        return of(err);
      }));
  }

  public getSubSearchResult(term: string, enterpriseId?: string, viewModel?: string, periodEnd?,peopleKeyList:number[]=[]) {
    if(!periodEnd){
      periodEnd = this.periodEndService.getActivePeriod()
    }
    return this.api.getPeoplePicker(term, enterpriseId, viewModel, periodEnd, false, peopleKeyList).pipe(
      map(peoplePickerResults => this.mapPeoplePickerResultToPeoplePicker(peoplePickerResults)));
  }

  private searchPeopleFromService(term: string, peopleKeyList:number[] = [],enterpriseId?: string, viewModel?: string, periodEnd?,isRepresent:boolean = false): Observable<PeoplePicker[]> {
    if(!periodEnd){
      periodEnd = this.periodEndService.getActivePeriod()
    }
    return this.api.getPeoplePicker(term, enterpriseId, viewModel, periodEnd, isRepresent, peopleKeyList).pipe(
      map(peoplePickerResults => this.mapPeoplePickerResultToPeoplePicker(peoplePickerResults)))
      .pipe(catchError(err => {
        this.logService.logError(err, true);
        return of(err);
      }));
  }

  private searchPeopleListFromService(enterpriseIdList: string[]): Observable<PeoplePicker[]> {
    return this.api.getPeoplePickerList(enterpriseIdList).pipe(
      map(peoplePickerResults => this.mapPeoplePickerResultToPeoplePicker(peoplePickerResults)))
      .pipe(catchError(err => {
        this.logService.logError(err, true);
        return of(err);
      }));
  }

  private mapPeoplePickerResultToPeoplePicker(peoplePickerResults: PeoplePickerResult[]) {
    return peoplePickerResults && peoplePickerResults.length > 0 ? peoplePickerResults.map(
      peoplePickerResult =>
        new PeoplePicker(
          peoplePickerResult.peopleKey,
          peoplePickerResult.enterpriseId,
          peoplePickerResult.lastName,
          peoplePickerResult.firstName,
          peoplePickerResult.xWorkforceCareerLevelDescription,
          peoplePickerResult.isValid,
          null
        )
    ) : [];
  }

  private sanitize(data: string): SafeUrl {
    if (!data) data = defaultProfilePicture.data;
    return this.domSanitizer.bypassSecurityTrustUrl('data:image/jpeg;base64,'.concat(data));
  }

  private mapProfilePicture(profilePicture, enterpriseId) {
    if (!profilePicture) return new ProfilePicture(null, enterpriseId, this.sanitize(null));
    return new ProfilePicture(profilePicture.peopleKey, profilePicture.entId, this.sanitize(profilePicture.data));
  }

  private mapProfilePictureList(profilePictures, enterpriseIds) {
    let result : ProfilePicture[] = [];
    enterpriseIds.forEach(eid => {
      let profilePicture = profilePictures.find(p => p.entId == eid)
      if(profilePicture != undefined){
        result.push(new ProfilePicture(profilePicture.peopleKey, profilePicture.entId, this.sanitize(profilePicture.data)));
      }else{
        result.push(new ProfilePicture(null, eid, this.sanitize(null)));
      }
    });
    return result;
  }


  private mapToPeoplePickerSelected(result: PeoplePicker[], peopleSearched: PeoplePicker) {
    let isValid = false;   
    let people = peopleSearched;
    people.firstName = people.firstName ?? people.enterpriseId;
    if(result && result.length == 1)
    {
      people = result[0];
      isValid = true;
    }
    else if(result && result.length > 1)
    {
      for(let i = 0; i < result.length; i++)
      {
        if(result[i].enterpriseId == peopleSearched.enterpriseId)
        {
          people = result[i];
          isValid = true;
          break;
        }
      }
    } 
    return new PeoplePickerSelected(people, isValid);
  }
  private selectedPeopleList(){
    let peopleKeyList = [];
    if(this.dataStore.selectedPeople != null || undefined){
      this.dataStore.selectedPeople.forEach(people => {
        if(people.peopleKey != null)
          peopleKeyList.push(people.peopleKey);
      });
    }
   
    return peopleKeyList;
  }
}
