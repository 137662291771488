<div (drop)="dropHandler($event)" (dragenter)="dragEnterHandler($event)" (dragover)="dragOverHandler($event)"
  (dragleave)="dragLeaveHandler($event)" class="amount-container"
  ngbTooltip="{{ value?.validationErrors }}" placement="bottom-start" container="body">
  <div *ngIf="value.message; else blockNotEx10">
    <div *ngIf="value.taxTrueUpValue; else blockString"data-html="true"
      ngbTooltip="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Expense: N/A Comp Memo &#013;&#010; &nbsp;&nbsp;Compensatory: {{ value.taxTrueUpValue }} &#013;&#010; Reimbursement: {{ value.taxTrueUpValue }}"
      tooltipClass="amount-tooltip" placement="end-top" container="body">
      <span> {{ value.taxTrueUpValue }} </span>
      <span class="HasCompensatoryBtn"></span>
    </div>
    <ng-template #blockString>
      <div ngbTooltip="Compensatory Expenses are not reimbursed to the employee"
        placement="end-top" container="body">
        <span> {{ value.message }} </span>
        <span class="HasCompensatoryBtn"></span>
      </div>
    </ng-template>
  </div>
  <ng-template #blockNotEx10>
    <div *ngIf="value.taxTrueUpValue; else normalBlock" data-html="true"
      ngbTooltip="Expense: {{ expenseAmount }} &#013;&#010; Compensatory: {{ taxTrueUpValue }} &#013;&#010; Reimbursement: {{ getReimbursement() }}"
      tooltipClass="amount-tooltip" placement="end-top" container="body">
      <span> {{ getReimbursement(true) }} </span>
      <span  class="HasCompensatoryBtn" ></span>
    </div>
    <ng-template #normalBlock>
      <div ngbTooltip="{{ toolTip }}" placement="end-top" container="body">
        <span> {{ value.amountToDisplay }} </span>
        <span *ngIf = "this.value.code == 'EX05' && this.value.isCarPlan" ngClass = "HasCompensatoryBtn" ></span>
      </div>
    </ng-template>    
  </ng-template>
</div>