import { TimeSheetCharge } from '../time-sheet-charge';

export default class TimeEntryCharge implements TimeSheetCharge {
    public value: number;  
    public date: Date;  
    public tooltip: string;
    public hasError: boolean;
    public hasAdjustment: boolean;
    public displayErrorInTotalHour: boolean;
    public isPolicy818: boolean;
    public isMalaysiaValidation: boolean;
    public isGDNValidation: boolean;
    public errorType: string;
}