export default class ExpenseTripName {
    public tripId?: number;
    public tripName?: string;

    constructor(
        tripId?: number,
        tripName?: string
    ) {
        this.tripId = tripId;
        this.tripName = tripName;
    }
}