<ng-template #toolTipSelectAll>
    <div id="selectAllTooltip" style= "font-size: 12px;">
        The first 500 users in the list will be selected
    </div>
</ng-template>

<ng-template #toolTipRemoveButton>
    <div style= "font-size: 12px;">
        {{textToolTipRemoveButton}}
    </div>
</ng-template>

<ng-container *ngIf="subordinates">
    <div id="fullContainerSubordinates" #fullContainerSubordinates>
    <div *ngIf="isReview" id="subordinatesCategory" #subordinatesCategory [ngStyle]="{'display':this.globalCacheService.getCurrentSubordinate()? 'block' : 'none'}"
        class="category-container">
        <span class="currently-selected-title">Currently selected:</span>
        <span class="currently-selected">{{subordinateSelected?.enterpriseId}}</span>
    </div>

    <div [ngClass]="{ 'category-container': subordinateMode != 'Supervisor', 'category-container-double': subordinateMode == 'Supervisor' }"
        [ngSwitch]="subordinateMode">

        <div *ngSwitchCase="'Supervisor'" class="title" #title id="title">
            <span *ngIf="isReview" id="subTitle" #subTitle>Reviewees</span>
            <span *ngIf="!isReview && subordinates?.length > 0" id="subTitle" #subTitle>Counselees</span>
        </div>

        <div *ngSwitchCase="'Approver'" class="title">
            <span id="subTitle" #subTitle>Approvees</span>
        </div>

        <div *ngSwitchCase="'Delegate'" class="title">
            <span id="subTitle" #subTitle>Delegators</span>
        </div>

        <div class="dropdownFilterStatus" id="dropdownFilterStatus" #dropdownFilterStatus>
            <myte-dropdown *ngIf="isReview"
                id="subordinatesList"
                #dropdownComponent
                [label]="subTitle?.nativeElement?.innerHTML"
                [elementsList]="dropDownStatusCodes"
                [placeholder]="'Status Filter'"
                [selectedItem]="selectedFilter"
                [dropdownId]="'SelectFilterStatusCodes'"
                isSearchable=true
                (updateValueEvent)="onStatusCodeChange($event)">
            </myte-dropdown>
        </div>

        <div class="subordinates-container" #subordinatesList id="subordinatesList" [ngStyle]="{'max-height': calculateHeight() + 'px' }">
            <div *ngFor="let subordinate of subordinatesFilter; trackBy: subordinateTrackBy">
                <ng-template #toolTip id="toolTip" role="tooltip">
                    <b>{{subordinate.enterpriseId}}</b><br>
                    TR Status: <i>{{subordinate?.statusCodeDescription}}</i>
                    <div style="font-size: 10px;" *ngIf="subordinate?.errorMessage">
                        TR Error: <i>{{ subordinate.errorMessage }}</i>
                    </div>
                
                    <div style="font-size: 10px;" *ngIf="subordinate?.actionFromSupervisor?.toolTip && isReviewer">
                        Last Action: <i>{{ subordinate?.actionFromSupervisor?.toolTip }}</i>
                    </div>
                </ng-template>
                <div id="subordinate"
                    [ngbTooltip]="toolTip"
                    placement="end"
                    container="body"
                    aria-describedby="toolTip"
                    aria-live="polite"
                    [ngClass]="{'subordinate-container-selected': subordinate?.enterpriseId == subordinateSelected?.enterpriseId, 'subordinate-container': true}">

                    <input class="form-check-input"
                            type="checkbox"
                            id="checkDelegate"
                            value=""
                            [checked]="subordinate.checked"
                            (click)="selectSubordinateUser(subordinate, subordinate.checked)"
                            (keyup.Enter)="selectSubordinateUser(subordinate, subordinate.checked)">

                    <span class="status-code" aria-hidden="true">{{subordinate.statusCode}}</span>

                    <div class="subordinate" (click)="selectSubordinate(subordinate, true)">
                        <span>{{subordinate.enterpriseId}}</span>
                    </div>

                    <mat-icon *ngIf="subordinate.errorMessage" fontIcon="error" placement="top" class="icon-align"></mat-icon>

                    <mat-icon *ngIf="subordinate?.actionFromSupervisor?.icon && isReviewer"
                        id="check"
                        class="icon-code-action"
                        aria-hidden="true"
                        [fontIcon]="subordinate?.actionFromSupervisor?.icon">
                    </mat-icon>

                    <span *ngIf="subordinate?.actionFromSupervisor?.text && isReviewer" placement="top" class="icon-align icon-letter">
                        {{ subordinate?.actionFromSupervisor?.text }}
                    </span>
                </div>
            </div>
        </div>

        <div class="delegate-buttons" #buttonElements>
            <div *ngIf="showSelectAllButton" class="select-buttons-container">
                <!-- The aria label and role of this span will change temporarily once the user presses one of the two buttons -->
                <span [id]="selectionToolAlertId" class="screenReaderReadable"></span>
                <button [ngStyle]="{'width': '48%'}"
                        [ngbTooltip]="toolTipSelectAll"
                        container="body"
                        placement="end"
                        aria-describedby="selectAllTooltip"
                        #selectAllButton
                        (click)="onSelectAll()">
                        Select All
                </button>
                <button
                        container="body"
                        [ngClass]="{ 'disabled-button': !hasSelectedSubordinates() }"
                        [attr.tabindex]="(!hasSelectedSubordinates()) ? '-1' : '0'"
                        [attr.aria-disabled]="!hasSelectedSubordinates()"
                        (click)="this.hasSelectedSubordinates() ? onDeselectAll() : ''"
                        ngbTooltip="This action will deselect all previously selected users"
                        aria-label="Deselect all, click this button to deselect all users."
                        placement="end"
                        #deselectAllButton
                        triggers="focus | hover">
                        Deselect All
                </button>
            </div>
            <button
                [ngbTooltip]="toolTipRemoveButton"
                container="body"
                placement="end"
                *ngIf="!showStatusCodeFilter && isReview || subordinateMode=='Approver'"
                (click)="removeChecked()"
                [disabled]="removeButtonIsDisabled"
                [ngClass]="subordinateMode=='Supervisor'? 'reviewer-button': ''">Remove</button>
            <button (click)="onShowPdfViewer(true)" ngbToolTip="Print Time and Expense Report" triggers="hover"
                *ngIf="showStatusCodeFilter">Print All</button>

            <button (click)="onSubmit('Group')" *ngIf="showStatusCodeFilter" #submitGroupButton>Submit Group</button>
        </div>

    </div>
</div>
</ng-container>