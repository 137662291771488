import { Component, EventEmitter, OnInit, Output } from '@angular/core';
@Component({
  selector: 'myte-privacy-statement-popup',
  templateUrl: './privacy-statement-popup.component.html',
  styleUrls: ['./privacy-statement-popup.component.sass']
})
export class PrivacyStatementPopupComponent implements OnInit {
  @Output() closeEvent = new EventEmitter<void>();
  constructor() { }
  ngOnInit(): void {
    
  }
  public closePopUp(): void {
      this.closeEvent.emit();
    
  }
}