import { HomeOfficeLocationOfUserPre, HomeOfficeLocationDto, PersonalCommuteDto, ResidentLocationOfUserPre, UserPreferencesOutput } from "../../clients/timereport-api-client";
import { commuteMetaDataList } from "@shared/models/commuteMetaDataList";
import { InOutForShow } from "@shared/models/inOutForShow";
import { LocationList } from "@shared/models/locationList";
import { OtherInformationList } from "@shared/models/otherInformationList";
import { PersonalCommuteList } from "@shared/models/personalCommuteList";
import { ResidentLocationList } from "@shared/models/residentLocationList";
import { HomeOfficeLocationList } from "@shared/models/homeOfficeLocationList";
import UserPreference from "@shared/models/userPreference";

export class UserProfileServiceMapper {
    public static mapUserPreferences(userPreferenceInfo: UserPreferencesOutput): UserPreference {
        let userPreference = new UserPreference();
        userPreference.reviewer = this.mapInOutValues(
          userPreferenceInfo.supervisorsList
        );
        userPreference.approver = this.mapInOutValues(
          userPreferenceInfo.approversList
        );
        userPreference.delegate = this.mapInOutValues(
          userPreferenceInfo.delegatesList
        );
        userPreference.submission = this.mapInOutValues(
          userPreferenceInfo.copyToEmailList
        );
        userPreference.backupApprovers = this.mapInOutValues(
          userPreferenceInfo.backupApprovers
        );
        userPreference.overtimeApprovers = this.mapInOutValues(
          userPreferenceInfo.overtimeApproversList
        );
        userPreference.allowanceApprover = this.mapInOutValues(
          userPreferenceInfo.allowanceApproversList
        );
        userPreference.workshiftApprover = this.mapInOutValues(
          userPreferenceInfo.workShiftApproversList
        );
        userPreference.onCallAndSWTimeApprover = this.mapInOutValues(
          userPreferenceInfo.onCallAndSWTimeApproversList 
        );
        userPreference.isApprovalRequested = userPreferenceInfo.isApprovalRequested;
        userPreference.compensatoryTabIndicator = userPreferenceInfo.compensatoryTabIndicator;
        userPreference.isAuditDelegateRequested =
        userPreferenceInfo.isAuditDelegateRequested;
        userPreference.isBackupApprover = userPreferenceInfo.isBackupApprover;
        userPreference.uberAcceptanceIndicator =
        userPreferenceInfo.uberAcceptanceIndicator;
        userPreference.lyftAcceptanceIndicator =
        userPreferenceInfo.lyftAcceptanceIndicator;
        userPreference.homeOfficeLocation = this.maphomeOfficeLocation(
          userPreferenceInfo.homeOfficeLocation
        );
        userPreference.personalCommuteData = this.mapPersonCommuteData(
          userPreferenceInfo.personalCommuteData
        );
        userPreference.isAvanadeUser = userPreferenceInfo.isAvanadeUser;
        userPreference.countryKey = userPreferenceInfo.countryKey;
        userPreference.isCarPlan = userPreferenceInfo.isCarPlan;
        userPreference.timeReportStatus = userPreferenceInfo.timeReportStatus;
        return userPreference;
      }

    private static mapInOutValues(inOutList: string[]): InOutForShow {
        let inOutValue = new InOutForShow();
        inOutValue.isVisible = inOutList ? true : false;
        inOutValue.value = inOutList ? inOutList : null;
        return inOutValue;
    }

    
  private static maphomeOfficeLocation(homeOfficeLocations: HomeOfficeLocationOfUserPre): HomeOfficeLocationList {
    if (homeOfficeLocations.homeOfficeLocationsLevel1 || homeOfficeLocations.homeOfficeLocationsLevel2 || homeOfficeLocations.residentLocationList) {
      let homeOfficeLocation = new HomeOfficeLocationList();
      homeOfficeLocation.isVisible = homeOfficeLocations ? true : false;
      homeOfficeLocation.homeLocation1 = this.mapLocation(
        homeOfficeLocations.homeOfficeLocationsLevel1
      );
      homeOfficeLocation.homeLocation2 = this.mapLocation(
        homeOfficeLocations.homeOfficeLocationsLevel2
      );
      homeOfficeLocation.currentHomeOfficeLocationCode =
        homeOfficeLocations.currentHomeOfficeLocationCode;
      homeOfficeLocation.currentHomeOfficeLocationStateCode =
        homeOfficeLocations.currentHomeOfficeLocationStateCode;
      homeOfficeLocation.currentResidentLocationCode =
        homeOfficeLocations.currentResidentLocationCode;
      homeOfficeLocation.residentLocationList = this.mapResidentLocation(
        homeOfficeLocations.residentLocationList
      );
      homeOfficeLocation.currentHomeOfficeLocationReasonCode = homeOfficeLocations.currentHomeOfficeLocationReasonCode;
      homeOfficeLocation.currentHomeOfficeLocationTypeCode = homeOfficeLocations.currentHomeOfficeLocationTypeCode;
      homeOfficeLocation.locationReasons = this.mapLocationReason(homeOfficeLocations.homeOfficeLocationsReason);
      homeOfficeLocation.locationTypes = this.mapLocationReason(homeOfficeLocations.homeOfficeLocationsType);
      homeOfficeLocation.selectedHomeOfficeLocationCodeForCompensatory =
        homeOfficeLocations.selectedHomeOfficeLocationCodeForCompensatory;
      homeOfficeLocation.selectedHomeOfficeLocationStateCodeForCompensatory =
        homeOfficeLocations.selectedHomeOfficeLocationStateCodeForCompensatory;
      return homeOfficeLocation;
    }
    return null;
  }

  

  private static mapLocation(homeOfficeLocation: HomeOfficeLocationDto[]): LocationList[] {
    let locations: LocationList[] = [];
    if (!homeOfficeLocation) return null;
    homeOfficeLocation.forEach(e => {
      let location = new LocationList();
      location.name = e.name;
      location.value = e.value;
      location.isDefault = e.isDefault;
      locations.push(location);
    });
    return locations;
  }

  private static mapResidentLocation(residentLocationList: ResidentLocationOfUserPre[]): ResidentLocationList[] {
    let residentLocationValues: ResidentLocationList[] = [];
    if (!residentLocationList) return null;
    residentLocationList.forEach(e => {
      let residentLocationValue = new ResidentLocationList();
      residentLocationValue.value = e.code;
      residentLocationValue.name = e.description;
      residentLocationValues.push(residentLocationValue);
    });
    return residentLocationValues;
  }

  private static mapLocationReason(homeOfficeLocationReason: HomeOfficeLocationDto[]): LocationList[] {
    let locationsReason: LocationList[] = [];
    if (!homeOfficeLocationReason) return null;
        homeOfficeLocationReason.forEach(e => {
          let locationReason = new LocationList();
          locationReason.name = e.name;
          locationReason.value = e.value;
          locationReason.isDefault = e.isDefault;
          locationsReason.push(locationReason);
    });
    return locationsReason;
  }

  private static mapLocationType(homeOfficeLocationType: HomeOfficeLocationDto[]): LocationList[] {
    let locationsType: LocationList[] = [];
    if (!homeOfficeLocationType) return null;
    homeOfficeLocationType.forEach(e => {
      let locationType = new LocationList();
      locationType.name = e.name;
      locationType.value = e.value;
      locationType.isDefault = e.isDefault;
      locationsType.push(locationType);
    });
    return locationsType;
  }

  private static mapPersonCommuteData(personalCommuteData: PersonalCommuteDto[]): PersonalCommuteList[] {
    let personalCommuteList: PersonalCommuteList[] = [];
    if (!personalCommuteData) return personalCommuteList;
    personalCommuteData.forEach(e => {
      let personalCommute = new PersonalCommuteList();
      personalCommute.currentValue = e.currentValue;
      personalCommute.otherInformation = new OtherInformationList();
      personalCommute.otherInformation.currencyCode = e.otherInformation.CurrencyCode;
      personalCommute.commuteMetaData = new commuteMetaDataList();
      personalCommute.commuteMetaData.id = e.commuteMetaData.id;
      personalCommute.commuteMetaData.simpleSettingName =
        e.commuteMetaData.simpleSettingName;
      personalCommute.commuteMetaData.possibleValues =
        e.commuteMetaData.possibleValues;
      personalCommute.commuteMetaData.required = e.commuteMetaData.required;
      personalCommute.commuteMetaData.dataType = e.commuteMetaData.dataType;      
      personalCommuteList.push(personalCommute);
    });
    return personalCommuteList;
  }
    
}