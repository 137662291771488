import { ExpenseViewField } from './field/expense-view-field';
import ExpenseViewRow from './expense-view-row';

export class ExpenseViewFieldRow implements ExpenseViewRow {
  public elements: ExpenseViewField[];

  constructor(fields?: ExpenseViewField[]) {
    this.elements = fields;
  }

  public getVisibleElements(): ExpenseViewField[] {
    return this.elements.filter(x => x.visible);
  }

  public hasVisibleElements(): boolean {
    return this.elements.find(x => x.visible) != null;
  }

}
