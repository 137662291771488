import { GlobalCacheService } from '../../../shared/services/cache/global-cache.service';
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { UserService } from '../../../shared/services/user/user.service';
import { NgbDateStruct, NgbDate, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import PeriodChangerDate from './period-changer-date';
import { TimePeriodPipe } from '../../../shared/pipes/time-period.pipe';
import DropdownItem from '../../../shared/models/controls/dropdown/dropdown-item';
import { FocusElement } from '../../../shared/models/controls/focus-element/focus-element';
import { ExpenseService } from '../../../myte-expenses/shared/services/expense/expense.service';
import { DatePickerUtils } from '../../../shared/utils/datePicker.utils';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TimePeriodStoreService } from '@shared/services/store/time-period-store.service';
import { ActionStoreService } from '@shared/services/store/action-store.service';
import { ActionType } from '@shared/services/store/shared/action-type';
@Component({
    selector: 'myte-period-changer-store',
    templateUrl: './period-changer-store.component.html',
    styleUrls: ['./period-changer-store.component.sass']
})
export class PeriodChangerStoreComponent implements OnInit, OnDestroy {
    @Input() userActiveDate: Date;
    @Output() isAutoSave = new EventEmitter();
    public currentDate: PeriodChangerDate;
    public DateDropDown: any;
    public dropdownDates: DropdownItem[] = [];
    public stringToday: string;
    public focusPreviousPeriod: boolean = false;
    public focusNextPeriod: boolean = false;
    public focusCalendar: boolean = false;
    public focusDropdown: boolean = false;
    public arialabel = "Press Enter to select a different period";
    public isLoading: boolean = true;
    private componentDestroy = new Subject<void>();
    constructor(
        public userService: UserService,
        private cacheService: GlobalCacheService,
        private expenseService: ExpenseService,
        private i18n: NgbDatepickerI18n,
        public timePeriodStoreService: TimePeriodStoreService,
        public actionStoreService: ActionStoreService
    ) {
        this.isLoading = true;
    }
    public ngOnInit(): void {
        this.timePeriodStoreService.periodEnd$
            .pipe(takeUntil(this.componentDestroy))
            .subscribe(periodEnd => {
                this.isLoading = true;
                this.currentDate = new PeriodChangerDate(periodEnd);
                this.DateDropDown = {
                    year: this.currentDate.year, month: this.currentDate.month + 1, day: this.currentDate.day
                };
                this.isLoading = false;
            });

        this.timePeriodStoreService.dropdownDates$
            .pipe(takeUntil(this.componentDestroy))
            .subscribe(dropdownDates => {
                this.isLoading = true;
                this.dropdownDates = dropdownDates;
                this.isLoading = false;
            });

        this.timePeriodStoreService.stringDateToday$
            .pipe(takeUntil(this.componentDestroy))
            .subscribe(stringDateToday => {
                this.stringToday = stringDateToday;
            });

        this.i18n.getWeekdayLabel = function (weekday) {
            return DatePickerUtils.getWeekDayShortNameByLanguage(weekday);
        };

        this.i18n.getMonthShortName = function (month) {
            return DatePickerUtils.getMonthShortNameByLanguage(month);
        };

        let user = this.userService.getUser();
        this.focusPreviousPeriod = this.cacheService.getFocusElement(user.enterpriseId + FocusElement.FOCUS_PREVIOUS_PERIOD);
        this.focusNextPeriod = this.cacheService.getFocusElement(user.enterpriseId + FocusElement.FOCUS_NEXT_PERIOD);
        this.focusCalendar = this.cacheService.getFocusElement(user.enterpriseId + FocusElement.FOCUS_CALENDAR);
        this.focusDropdown = this.cacheService.getFocusElement(user.enterpriseId + FocusElement.FOCUS_DROPDOWN);
        this.cacheService.clearFocusElement(user.enterpriseId + FocusElement.FOCUS_PREVIOUS_PERIOD);
        this.cacheService.clearFocusElement(user.enterpriseId + FocusElement.FOCUS_NEXT_PERIOD);
        this.cacheService.clearFocusElement(user.enterpriseId + FocusElement.FOCUS_CALENDAR);
        this.cacheService.clearFocusElement(user.enterpriseId + FocusElement.FOCUS_DROPDOWN);
        this.isLoading = false;
    }
    public ngOnDestroy(): void {
        this.componentDestroy.next();
        this.componentDestroy.complete();
    }

    isDisabled = (date: NgbDate, currentMonth: number) => date.month !== currentMonth;

    public previousPeriod(): void {
        this.isLoading = true;
        this.setFocusElement(FocusElement.FOCUS_PREVIOUS_PERIOD);
        this.isAutoSave.emit();
        this.cacheService.saveExpenseListShowStatus(false);
        this.actionStoreService.dispatchAction(ActionType.TIME_PERIOD_CHANGED, {
            periodEnd: this.adjustActiveDate(this.timePeriodStoreService.getPeriodEnd(), false, true).getDate(),
            needRedirect: true
        });
        this.expenseService.setIsFromExpensePopupCloseWithoutAmex(false);
        this.cacheService.resetDataStorage();
        this.isLoading = false;
    }

    public nextPeriod(): void {
        this.isLoading = true;
        this.setFocusElement(FocusElement.FOCUS_NEXT_PERIOD);
        this.isAutoSave.emit();
        this.cacheService.saveExpenseListShowStatus(false);
        this.actionStoreService.dispatchAction(ActionType.TIME_PERIOD_CHANGED, {
            periodEnd: this.currentDate.nextPeriod().getDate(),
            needRedirect: true
        });
        this.expenseService.setIsFromExpensePopupCloseWithoutAmex(false);
        this.cacheService.resetDataStorage();
        this.isLoading = false;
    }

    public onDateSelectFromCalendar(date: NgbDateStruct): void {
        this.isLoading = true;
        let newCurrentDate = new PeriodChangerDate(
            new TimePeriodPipe().transform(this.adjustActiveDate(date, true, false))
        );
        if (this.currentDate.toString() != newCurrentDate.toString()) {
            this.setFocusElement(FocusElement.FOCUS_CALENDAR);
            this.isAutoSave.emit();
            this.actionStoreService.dispatchAction(ActionType.TIME_PERIOD_CHANGED, {
                periodEnd: newCurrentDate.getDate(),
                needRedirect: true
            });
        }
        this.cacheService.resetDataStorage();
        this.isLoading = false;
    }

    public onDateSelectedFromDropdown(date: any): void {
        this.isLoading = true;
        let newCurrentDate = new PeriodChangerDate(
            new TimePeriodPipe().transform(this.adjustActiveDate(date, false, false) as Date)
        );
        if (this.currentDate.toString() != newCurrentDate.toString()) {
            this.setFocusElement(FocusElement.FOCUS_DROPDOWN);
            this.isAutoSave.emit();
            this.actionStoreService.dispatchAction(ActionType.TIME_PERIOD_CHANGED, {
                periodEnd: newCurrentDate.getDate(),
                needRedirect: true
            });
        }
        this.cacheService.resetDataStorage();
        this.isLoading = false;
    }
    
    public hideOptions(event?) { }

    public onClickToday(): void {
        this.isLoading = true;
        let date: NgbDateStruct = {
            year: new Date().getFullYear(),
            month: new Date().getMonth() + 1,
            day: new Date().getDate()
        }
        this.onDateSelectFromCalendar(date);
    }

    private setFocusElement(element: string): void {
        var user = this.userService.getUser();
        this.cacheService.setFocusElement(user.enterpriseId + FocusElement.FOCUS_PREVIOUS_PERIOD, false);
        this.cacheService.setFocusElement(user.enterpriseId + FocusElement.FOCUS_NEXT_PERIOD, false);
        this.cacheService.setFocusElement(user.enterpriseId + FocusElement.FOCUS_CALENDAR, false);
        this.cacheService.setFocusElement(user.enterpriseId + FocusElement.FOCUS_DROPDOWN, false);
        this.cacheService.setFocusElement(user.enterpriseId + element, true);
    }

    private adjustActiveDate(current: any, isCalender: boolean, isPrevious: boolean): any {
        let formatDate = new Date(current.year, current.month - 1, current.day);
        current = isCalender ? formatDate : current;
        let currentPeriodEnd = new TimePeriodPipe().transform(current);
        let startDate = currentPeriodEnd.getDate() > 15 ?
            new Date(currentPeriodEnd.getFullYear(), currentPeriodEnd.getMonth(), 16) :
            new Date(currentPeriodEnd.getFullYear(), currentPeriodEnd.getMonth(), 1);
        let checkActiveDate = this.userActiveDate < startDate;
        if (isPrevious)
            return checkActiveDate ? this.currentDate.previousPeriod() : this.currentDate;
        return checkActiveDate ? current : this.userActiveDate;
    }
}