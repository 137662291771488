export const TimesheetSave_SuccessfullySavedMessage = {
  msg: 'Timesheet saved Successfully',
  showToast: true,
  title: 'Saved Successfully!'
};

export const SetTemplate_SavedLocationMessage = {
  msg: 'The location has been saved successfully',
  showToast: true,
  title: 'Saved Successfully!'
};

export const TimesheetSingleDeletedRow_SuccessfullyDeletedMessage = {
  msg: 'Row deleted Successfully',
  showToast: true,
  title: 'Deleted Successfully!'
};

export const TimesheetMultipleDeletedRows_SuccessfullyDeletedMessage = {
  msg: 'Rows deleted Successfully',
  showToast: true,
  title: 'Deleted Successfully!'
};

export const SetTemplatePopupSave_SuccessfullySavedMessage = {
  msg: 'Template saved Successfully',
  showToast: true,
  title: 'Saved Successfully!'
}

export const WorkSchedulePopupSave_SuccessfullySavedMessage = {
  msg: 'Work schedule saved Successfully',
  showToast: true,
  title: 'Saved Successfully!'
}

export const PunchClockPopupSave_SuccessfullySavedMessage = {
  msg: 'Working hours saved Successfully',
  showToast: true,
  title: 'Saved Successfully!'
}

export const RestHoursPunchClockPopupSave_SuccessfullySavedMessage = {
  msg: 'Rest hours saved Successfully',
  showToast: true,
  title: 'Saved Successfully!'
}

export const SplitExpense_InputErrorMessage = {
  msg: 'Please select a Charge Code before split.',
  showToast: true,
  title: 'Invalid Input!'
};

export const SplitExpense_TotalAmountLessMessage = {
  msg: 'The amount must be at least 0.01.',
  showToast: true,
  title: 'Invalid Input!'
};

export const SplitExpense_TotalAmountExceedMessage = {
  msg: 'The amount cannot be over the Original Expense Amount.',
  showToast: true,
  title: 'Invalid Input!'
};

export const SplitExpense_RemainingAmountExceedMessage = {
  msg: 'The input amount should not be greater than the remaining expense charge.',
  showToast: true,
  title: 'Invalid Input!'
};

export const SplitExpense_RemainingPercentageExceedMessage = {
  msg: 'The input percentage should not be greater than the remaining expense charge.',
  showToast: true,
  title: 'Invalid Input!'
};


export const SplitExpense_InputPercentageLessMessage = {
  msg: 'The percentage must be at least 1.',
  showToast: true,
  title: 'Invalid Input!'
};

export const SplitExpense_InputPercentageExceedMessage = {
  msg: 'The percentage must not exceed 99.',
  showToast: true,
  title: 'Invalid Input!'
};

export const SplitExpense_RemainderPercentageExceedMessage = {
  msg: 'Total must equal 100%.',
  showToast: true,
  title: 'Invalid Input!'
};

export const SplitExpense_SaveExceedAmountMessage = {
  msg: 'Please distribute the exact amount of the expense across your desired WBSes.',
  showToast: true,
  title: 'Invalid Input!'
};

export const User_Profile_ErrorMessage = {
  msg: 'An error has occurred while getting user preferences information.',
  showToast: true,
  title: 'Error!'
};

export const Trip_Apply_ErrorMessage = {
  msg: 'An error has occurred while applying trip.',
  showToast: true,
  title: 'Error!'
};

export const Trip_Apply_SussessMessage = {
  msg: 'Trip deleted Successfully',
  showToast: true,
  title: 'Success!'
};

export const Trip_Delete_ErrorMessage = {
  msg: 'An error has occurred while deleting trip.',
  showToast: true,
  title: 'Error!'
};

export const Taxi_Save_SussessMessage = {
  msg: 'Multiple travel taxi saved Successfully',
  showToast: true,
  title: 'Success!'
};

export const Taxi_InputDate_ErrorMessage = {
  msg: 'Values entered in this column must be entered in Browser Setting format.',
  showToast: true,
  title: 'Error!'
};

export const Taxi_InputDate_ErrorYearValue = {
  msg: 'Values entered in this column must be between 1/1/1753 12:00:00 AM and 12/31/9999 11:59:59 PM.',
  showToast: true,
  title: 'Error!'
};

export const Taxi_SelectedAssignments_ErrorMessage = {
  msg: 'The Assignment does not exist.  Please ensure that the value has been entered correctly, and if it has, follow up with the person who provided the Assignment.',
  showToast: true,
  title: 'Error!'
};

export const Charge_Code_Add_SuccessMessage = {
  msg: 'Charge Code added Successfully.',
  showToast: true,
  title: 'Success!'
};

export const Charge_Code_Add_ErrorMessage = {
  msg: 'An error has occurred while add charge code.',
  showToast: true,
  title: 'Error!'
};

export const Charge_Code_Delete_SuccessMessage = {
  msg: 'Charge Code deleted Successfully.',
  showToast: true,
  title: 'Success!'
};

export const Charge_Code_Delete_ErrorMessage = {
  msg: 'An error has occurred while delete charge code.',
  showToast: true,
  title: 'Error!'
};

export const BD_Add_SuccessMessage = {
  msg: 'Business Development project added Successfully.',
  showToast: true,
  title: 'Success!'
};

export const BD_Add_ErrorMessage = {
  msg: 'An error has occurred while add business development project.',
  showToast: true,
  title: 'Error!'
};

export const Compensatory_Add_SuccessMessage = {
  msg: 'Tax Reimbursement Rate added Successfully.',
  showToast: true,
  title: 'Success!'
};

export const Compensatory_Add_ErrorMessage = {
  msg: 'An error has occurred while add Tax Reimbursement Rate.',
  showToast: true,
  title: 'Error!'
};

export const CarInformationChargeableGreater = {
  msg: 'Chargeable Kilometers cannot exceed Kilometers Driven.',
  showToast: true,
  title: 'Error!'
};

export const CarInformationInvalidStartEndKmMiles = {
  msg: 'End Kilometers value should be bigger than Start Kilometers value.',
  showToast: true,
  title: 'Error!'
};

export const NoCarPlan = {
  msg: 'Please fill out Car Plan for the time period before save.',
  showToast: true,
  title: 'Error!'
};

export const Car_Information_Add_SuccessMessage = {
  msg: 'Car Information Add Successfully.',
  showToast: true,
  title: 'Success!'
};

export const Car_Information_Add_ErrorMessage = {
  msg: 'An error has occurred while add car information.',
  showToast: true,
  title: 'Error!'
};

export const InvalidKmMilesTotal = {
  msg: 'Within the Car Plan tab the Chargeable Kilometers cannot exceed the Total Kilometers driven for the time period.',
  showToast: true,
  title: 'Error!'
};

export const ESTravelPunchClockInfo = {
  msg: 'Per Spanish Labor regulations, employees working in Spain are expected to record work start and end times and break duration daily. Working Hours fields must be completed to submit your time report. Go to this <a href=\'https://in.accenture.com/mytimeandexpenses/country-specific-information/iberia/\' target=\'_blank\'>page</a> for more information about Working Hours.',
  showToast: true
};

export const Reminder_Email_SuccessMessage = {
  msg: 'Reminder email has been sent',
  showToast: true,
};
export const Reversal_Email_SuccessMessage = {
  msg: 'Reversal email has been sent',
  showToast: true,
};
export const Escalation_Email_SuccessMessage = {
  msg: 'Escalation email has been sent',
  showToast: true,
};
export const Issue_Email_SuccessMessage = {
  msg: 'Issue email has been sent',
  showToast: true,
};