import { PeoplePicker } from './people-picker';

export class PeoplePickerSelected {
  public people: PeoplePicker;
  public valid: boolean;

  constructor(people: PeoplePicker, valid: boolean) {
    this.people = people;
    this.valid = valid;
  }
}
