export enum SubordinatesMode {
    None = "Record",
    Approver = "Approver",
    Supervisor = "Supervisor",
    Delegate = "Delegate",
    ProjectApprover = "ProjectApprover",
    BackupApprover = "BackupApprover",
    RequestOvertimeApprover = "RequestOvertimeApprover",
    ServiceDeliveryLead = "ServiceDeliveryLead",
    OvertimeApprover = "OvertimeApprover",
    OvertimeApprovee = "OvertimeApprovee",
    ConsentReviewer = "ConsentReviewer",
    AllowanceApprover = "AllowanceApprover",
    AllowanceApprovee = "AllowanceApprovee",
    ReviewEmail = "ReviewEmail",
    Audit = "Audit",
    AllowanceReviewer = "AllowanceReviewer",
    TimeCategoryRequestApprover = "TimeCategoryRequestApprover",
    WorkShiftApprover = "WorkShiftApprover",
    AssignedExpenseApprover = "AssignedExpenseApprover",
    OnCallAndSWTimeApprover = "OnCallAndSWTimeApprover",
    HolidayHoursRequestor = "HolidayHoursRequestor",
    Blank = ""
}

/**
 * AssignedRoleDescription
 * Has the same name and number as backend use it, this help us to send the correct
 * number to the backend from getTimeSheet
 */
export enum AssignedRoleDescription {
    None = 0,
    Approver = 1,
    Supervisor = 2,
    Delegate = 3,
    ProjectApprover = 4,
    BackupApprover = 5,
    OvertimeApprover = 6,
    OnCallAndSWTimeApprover = 7
}
