export enum InOutForShowElement {
    Approver,
    Reviewer,
    Submission,
    Delegate,
    BackupApprovers,
    OvertimeApprover,
    AllowanceApprover,
    WorkShiftApprover,
    OnCallAndSWTimeApprover,
}

export class InOutForShow {
    public id: InOutForShowElement;
    public value: string[];
    public isVisible: boolean;
    public title1: string;
    public title2: string;
    constructor(
        value?: string[],
        isVisible?: boolean,
        title1?: string,
        title2?: string
    ) {
        this.value = value;
        this.isVisible = isVisible;
        this.title1 = title1;
        this.title2 = title2;
    }
}