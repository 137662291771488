import { ActivityOutputField } from "./activity-output-field";

export class ActivityOutputItem {
    public activityId: number;
    public triggeredBy: string
    public needValidateRequired: boolean;
    public activityOutputField: ActivityOutputField[] = [];
    constructor(
        activityId?:number,
        needValidateRequired?: boolean,
        activityOutputField:ActivityOutputField[] = []
    ) {
        this.activityId = activityId;
        this.needValidateRequired = needValidateRequired;
        this.activityOutputField = activityOutputField;
    }
}