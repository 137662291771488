import { Injectable } from '@angular/core';
import { ChargeabilityImpactReviewDisplayDto, ChargeabilityImpactReviewInfo } from '@shared/clients/timereport-api-client';
import { ColDef, GridOptions, RowClassParams, RowDataUpdatedEvent, ValueFormatterParams } from 'ag-grid-community';
import { AnnualChargeabilityGrid } from '../../../shared/models/summary/annual-chargeability/annual-chargeability-grid.model';

@Injectable({
  providedIn: 'root'
})
export class ChargeabilityImpactReviewService {

  constructor() { }

  public getGridOptions(dataSource: ChargeabilityImpactReviewInfo, annualChargeability?: AnnualChargeabilityGrid): GridOptions<ChargeabilityImpactReviewDisplayDto> {
    return {
      rowHeight: 30,
      headerHeight: 35,
      suppressMovableColumns: true,
      suppressHorizontalScroll: true,
      domLayout: 'autoHeight',
      popupParent: document.querySelector('#myte-popup'),
      defaultColDef: this.getDefaultColumnDefinitions(),
      columnDefs: this.getColumnDefinitions(),
      rowData: dataSource.chargeabilityImpactReviewDisplayList,
      getRowClass: (params: RowClassParams<ChargeabilityImpactReviewDisplayDto>) => params.node.isRowPinned() ? ['readonly-row', 'bold'] : null,
      onRowDataUpdated: (event: RowDataUpdatedEvent<ChargeabilityImpactReviewDisplayDto>) => {
      }
    };
  }

  private getDefaultColumnDefinitions(): ColDef<ChargeabilityImpactReviewDisplayDto> {
    return {
      cellStyle: { 'line-height': '30px', 'text-align': 'right' },
      sortable: false,
      resizable: false,
      filter: false,
      minWidth: 100,
      width: 150,
    };
  }

  private getColumnDefinitions(): ColDef<ChargeabilityImpactReviewDisplayDto>[] {
    function valueFormatter(params: ValueFormatterParams<ChargeabilityImpactReviewDisplayDto, number>): string {
      params.data.chargeable = params.data.chargeable == 0 ? null : params.data.chargeable
      params.data.nonChargeable = params.data.nonChargeable == 0 ? null : params.data.nonChargeable
      return params.value == null ? null : Number(params.value).toFixed(1);
    }
    return [
      { colId: 'chargeCode', headerName: 'Charge Code', field: 'chargeCode', minWidth: 350, cellClass: 'text-start', autoHeight: true },
      { colId: 'chargeable', headerName: 'Chargeable', field: 'chargeable', minWidth: 150, cellClass: 'text-end', autoHeight: true, valueFormatter: valueFormatter },
      { colId: 'nonChargeable', headerName: 'Non Chargeable', field: 'nonChargeable', minWidth: 150, cellClass: 'text-end', autoHeight: true, valueFormatter: valueFormatter }
    ];
  }
}