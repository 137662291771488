
import TimeEntry from './time-entry/time-entry';
import TimeSheetDate from './time-sheet-date';
import TimeCategory from './time-category/time-category';
import TimeEntryAssignment from './time-entry/time-entry-assignment';
import MyteApiMessage from '../myte-api-message';
import { AssignedLocation, CompanyAndCostCenterHistoryDto } from '@sharedClients/time-api-client';
import { WorkShiftDropdownItem } from './work-shift/work-shift';

export default class TimeSheet {
    public timeEntry: TimeEntry;
    public timeCategory: TimeCategory;
    public timeSheetDates: TimeSheetDate[] = [];
    public assignments: TimeEntryAssignment[] = [];
    public messages: MyteApiMessage[];
    public isEditable: boolean;
    public modified: boolean = false;
    public isSaved: boolean = true;
    public ownerEid: string;
    public companyHistoryList: CompanyAndCostCenterHistoryDto[] = [];
    public assignedLocation: AssignedLocation[] = [];
    public shouldResave: boolean;
    public timeEntryTemplateAutomaticMessage?: string | undefined;
    public timeReportStatus: string;
    public userFullName: string;
    public isESTravelPunchClock: boolean;
    public timeCategoryForWorkShift?: Array<WorkShiftDropdownItem>;
}