import { Injectable } from "@angular/core";
import {
  AuditCommunicationLogTrackingInput,
  AuditFiltersDataInput,
  CloseMultipleAuditsInput,
  ExpenseApiClient,
} from "@shared/clients/expense-api-client";
import { MyteAuditOutput } from "@shared/models/audit/myte-audit-output";
import { GlobalCacheService } from "@shared/services/cache/global-cache.service";
import { GlobalEventsService } from "@shared/services/events/global-events.service";
import { LogService } from "@shared/services/log/log.service";
import { MyteBaseService } from "@shared/services/myte-base.service";
import { UserService } from "@shared/services/user/user.service";
import { BehaviorSubject, catchError, map, Observable, of } from "rxjs";
import AuditServiceMapper from "./myte-audit.service.mapper";
import { HttpErrorResponse } from "@angular/common/http";
import { SubordinatesMode } from "@shared/models/subordinates/subordinate-mode";

@Injectable({
  providedIn: "root",
})
export class AuditService extends MyteBaseService {
  private auditStatusBeSubject = new BehaviorSubject(false);
  constructor(
    private api: ExpenseApiClient,
    logService: LogService,
    userService: UserService,
    eventService: GlobalEventsService,
    private cacheService: GlobalCacheService
  ) {
    super(userService, cacheService, eventService, logService);
  }
  public getMyteAuditOutput(
    loginEid: string,
    auditFliterInput: AuditFiltersDataInput
  ): Observable<MyteAuditOutput> {
    return this.api
      .getPostPayAuditList(loginEid, this.viewMode, auditFliterInput)
      .pipe(
        map((myteAuditOutPut) =>
          AuditServiceMapper.mapMyteAuditOutput(myteAuditOutPut)
        )
      )
      .pipe(
        catchError(() => {
          return of(null);
        })
      );
  }

  /**
   * API to get selected auditee information
   * @param postPayAuditListId also called Audit Id
   * @param enterpriseId Auditee enterprise id
   * @param periodEnd TR period
   * @param loginEnterpriseId logged in auditors enterprise id
   * @param viewMode 
   * @returns 
   */
  public getAuditeeInfo(
    postPayAuditListId: number | undefined,
    enterpriseId: string | undefined,
    periodEnd: Date | undefined,
    loginEnterpriseId: string | undefined,
    viewMode: string | undefined
  ) {
    return this.api
      .getAuditeeInfo(
        postPayAuditListId,
        enterpriseId,
        periodEnd,
        loginEnterpriseId,
        viewMode
      )
      .pipe(
        catchError((err) => {
          this.logService.logError(err, true);
          return of(null);
        })
      );
  }

  public AssignTOAuditor(
    enterpriseId: string,
    personnelNumber: string,
    complaincePeriod: string,
    trPeriod: string,
    auditorId: string
  ): Observable<string> {
    return this.api
      .assignToAuditor(
        enterpriseId,
        personnelNumber,
        trPeriod,
        complaincePeriod,
        auditorId,
        this.loginEid,
        SubordinatesMode.Audit
      )
      .pipe(
        catchError((err) => {
          this.logService.logError(err, true);
          return of(null);
        })
      );
  }
  public AssignTOMe(
    enterpriseId: string,
    personnelNumber: string,
    complaincePeriod: string,
    trPeriod: string
  ): Observable<string> {
    return this.api
      .assignToMe(
        enterpriseId,
        personnelNumber,
        trPeriod,
        complaincePeriod,
        this.loginEid,
        SubordinatesMode.Audit
      )
      .pipe(
        catchError((err) => {
          this.logService.logError(err, true);
          return of(null);
        })
      );
  }
  public ViewEdit(
    enterpriseId: string,
    personnelNumber: string,
    complaincePeriod: string,
    trPeriod: string
  ): Observable<MyteAuditOutput> {
    return this.api
      .viewEdit(
        enterpriseId,
        personnelNumber,
        trPeriod,
        complaincePeriod,
        this.loginEid,
        SubordinatesMode.Audit
      ) .pipe(
        map((myaudit) =>
          AuditServiceMapper.mapMyteAuditOutputforViewEdit(myaudit)
        )
      )
      .pipe(
        catchError((err) => {
          this.logService.logError(err, true);
          return of(null);
        })
      );
  }
  public Refer(
    enterpriseId: string,
    personnelNumber: string,
    period: string
  ): Observable<string[]> {
    return this.api
      .auditRefer(
        enterpriseId,
        personnelNumber,
        period,
        this.loginEid,
        SubordinatesMode.Audit
      )
      .pipe(
        catchError((err) => {
          this.logService.logError(err, true);
          return of(null);
        })
      );
  }
  public RemoveClosedAudits(
    auditid: AuditCommunicationLogTrackingInput[]
  ): Observable<boolean> {
    return this.api
      .removeClosedAudit(this.loginEid, SubordinatesMode.Audit, auditid)
      .pipe(
        catchError((ex: HttpErrorResponse) => {
          this.logService.logError(ex.message, true);
          return of(null);
        })
      );
  }
  public CloseMultipleAudits(
    enterpriseId: string,
    closeMultipleAuditsInput: CloseMultipleAuditsInput
  ): Observable<string> {
    return this.api
      .closeMultipleAudits(
        this.loginEid,
        this.loginEid,
        SubordinatesMode.Audit,
        closeMultipleAuditsInput
      )
      .pipe(
        catchError((err) => {
          this.logService.logError(err, true);
          return of(null);
        })
      );
  }
  public getAuditStatus(): Observable<boolean> {
    return this.auditStatusBeSubject.asObservable();
  }
  public setAuditStatus(value: boolean) {
    return this.auditStatusBeSubject.next(value);
  }
}
