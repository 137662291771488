export class ExpenseViewFieldValidation {
  type: string;
  value: RegExp;
  errorMessage?: string;

  constructor(type: string, value: RegExp, errorMessage: string = null) {
    this.type = type;
    this.value = value;
    this.errorMessage = errorMessage;
  }
}
