import { Injectable } from "@angular/core";
import { AuditAction, AuditIssueDetailDtoForSave, AuditIssueDetailsForDisplay, ExpenseApiClient, ValidationErrors } from "@shared/clients/expense-api-client";
import { UserRole } from "@shared/models/user/user-roles";
import { GlobalCacheService } from "@shared/services/cache/global-cache.service";
import { GlobalEventsService } from "@shared/services/events/global-events.service";
import { LogService } from "@shared/services/log/log.service";
import { MyteBaseService } from "@shared/services/myte-base.service";
import { UserService } from "@shared/services/user/user.service";
import { Observable, catchError, of } from 'rxjs';


@Injectable()
export class ExpenseAuditRaiseIssueService extends MyteBaseService {

    constructor(private api: ExpenseApiClient,
        logService: LogService,
        userService: UserService,
        globalEventsService: GlobalEventsService,
        globalCacheService: GlobalCacheService,) {
        super(userService, globalCacheService, globalEventsService, logService);
    }

    // Get Audit Raise Issue Details
    public raiseIssueDetails(expenseId: string, Id?: number, enterpriseId?: string): Observable<AuditIssueDetailsForDisplay> {
        return this.api.raiseIssueDetails(expenseId, Id, enterpriseId, this.globalCacheService.getPeriodEnd(), this.loginEid, this.viewMode)
            .pipe(IssueDetails => IssueDetails)
            .pipe(catchError(() => {
                return of(null);
            })
            );
    }

    // Get Audit Actions based on selected Issue
    public getAuditActions(issueId: number, enterpriseId?: string): Observable<AuditAction[]> {
        return this.api.getAuditActions(issueId, enterpriseId, this.loginEid, this.viewMode)
            .pipe(auditActionoutput => auditActionoutput)
            .pipe(catchError(err => {
                this.logService.logError(err, true);
                return of(null);
            }
            ));
    }

    //Save new Issue or update existing issues
    public saveOrUpdateAuditIssue(isUpdate: boolean, saveAuditIssue: AuditIssueDetailDtoForSave): Observable<ValidationErrors> {
        saveAuditIssue.auditUser = this.loginEid;
        let isAuditLeadSession = this.globalCacheService.getInfoFromSessionStorage(this.globalCacheService.auditLeadSession)
        let auditeeEnterpriseId = this.globalCacheService.getInfoFromSessionStorage(this.globalCacheService.auditInfoKey).enterpriseId
        return this.api.saveOrUpdateAuditIssue(this.globalCacheService.getPeriodEnd(), isUpdate, isAuditLeadSession, auditeeEnterpriseId, this.loginEid, this.viewMode, saveAuditIssue)
            .pipe(catchError(err => {
                this.logService.logError(err, true);
                return of(null);
            }
            ));
    }

}