import { MyteEvent } from '../myte-event';
import { LocationsByDay, LocationEntry, LocationInformation, MultipleLocation } from '@sharedModels/locations';
import { LBDChangeReason } from '@sharedClients/timereport-api-client';
export class LocationGridRowSelectedEvent extends MyteEvent<LocationEntry> {
    public static eventId = "rowSelected";
    constructor() { super(LocationGridRowSelectedEvent.eventId) }
}

export class LocationGridHeaderClickedEvent extends MyteEvent<Date> {
    public static eventId = "headerClicked";
    constructor() { super(LocationGridHeaderClickedEvent.eventId) }
}

export class LocationGridNewDataSourceEvent extends MyteEvent<LocationsByDay> {
    public static eventId = "newDataSource";
    constructor() { super(LocationGridNewDataSourceEvent.eventId) }
}

export class LocationGridSetDefaultLocationEvent extends MyteEvent<LocationInformation> {
    public static eventId = "setDefaultLocation";
    constructor() { super(LocationGridSetDefaultLocationEvent.eventId) }
}
export class LocationLBDChangeReasonEvent extends MyteEvent<LBDChangeReason>{
    public static eventId = "saveLBDChangeReason";
    constructor() { super(LocationLBDChangeReasonEvent.eventId) }
}

export class MultipleLocationsChangeEvent extends MyteEvent<MultipleLocation>{
    public static eventId = "multipleLocationsChangeEvent";
    constructor() { super(MultipleLocationsChangeEvent.eventId) }
}

