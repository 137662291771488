import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  // tslint:disable-next-line:pipe-naming
  name: 'timePeriod'
})
export class TimePeriodPipe implements PipeTransform {

  public transform(value: Date): Date {
    if (value.getDate() <= 15) 
      return new Date(value.getFullYear(), value.getMonth(), 15);
    else 
      return new Date(value.getFullYear(), value.getMonth(), new Date(value.getFullYear(), value.getMonth() + 1, 0).getDate());
  }
}
