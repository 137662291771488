import { TimesheetTemplateOutput, TimesheetTemplateInput, TaskTemplateInput, LocationByDayTemplateInput, TimeEntryTemplateAssignmentInput } from '../../../shared/clients/time-api-client';
import { TimeEntryTemplate, TimeEntryTemplateTask, TimeEntryTemplateAssignment } from '../../../shared/models/time-sheet/time-entry/time-entry-template';
import { LocationInformation } from '../../../shared/models/locations';

export default class TimeEntryTemplateMapper {
    public static mapTimeEntryTemplate(tetOutput: TimesheetTemplateOutput): TimeEntryTemplate {
        var timeEntryTemplate = new TimeEntryTemplate();

        tetOutput.tasks.forEach(task => {
            let tetTask = new TimeEntryTemplateTask();
            tetTask.assignment = new TimeEntryTemplateAssignment();
            tetTask.assignment.code = task.assignment.projectNumber;
            tetTask.assignment.description = task.assignment.projectDescription;
            tetTask.assignment.masterServicesAgreement = task.assignment.masterServicesAgreement;
            
            if (task.assignment.hasError) {
                tetTask.assignment.hasError = true;
                tetTask.assignment.tooltip = task.assignment.tooltip;
            }

            task.timeEntries.forEach(timeEntry => {
                switch (timeEntry.dayOfWeek) {
                    case "Sunday":
                        tetTask.charges[0] = timeEntry.hours;
                        break;
                    case "Monday":
                        tetTask.charges[1] = timeEntry.hours;
                        break;
                    case "Tuesday":
                        tetTask.charges[2] = timeEntry.hours;
                        break;
                    case "Wednesday":
                        tetTask.charges[3] = timeEntry.hours;
                        break;
                    case "Thursday":
                        tetTask.charges[4] = timeEntry.hours;
                        break;
                    case "Friday":
                        tetTask.charges[5] = timeEntry.hours;
                        break;
                    case "Saturday":
                        tetTask.charges[6] = timeEntry.hours;
                        break;

                    default:
                        break;
                }
            });
            timeEntryTemplate.tasks.push(tetTask);
        });
        
        tetOutput.locationByDay.forEach(location => {
            switch (location.dayOfWeek) {                                                                                                                                                                                                                     
                case "Sunday":
                    timeEntryTemplate.locationsByWeek[0] = new LocationInformation(location.countryCode, location.locationOneCode, location.locationTwoCode, location.description, location.locationReasonCode ? parseInt(location.locationReasonCode) : null);
                    break;
                case "Monday":
                    timeEntryTemplate.locationsByWeek[1] = new LocationInformation(location.countryCode, location.locationOneCode, location.locationTwoCode, location.description, location.locationReasonCode ? parseInt(location.locationReasonCode) : null);
                    break;
                case "Tuesday":
                    timeEntryTemplate.locationsByWeek[2] = new LocationInformation(location.countryCode, location.locationOneCode, location.locationTwoCode, location.description, location.locationReasonCode ? parseInt(location.locationReasonCode) : null);
                    break;
                case "Wednesday":
                    timeEntryTemplate.locationsByWeek[3] = new LocationInformation(location.countryCode, location.locationOneCode, location.locationTwoCode, location.description, location.locationReasonCode ? parseInt(location.locationReasonCode) : null);
                    break;
                case "Thursday":
                    timeEntryTemplate.locationsByWeek[4] = new LocationInformation(location.countryCode, location.locationOneCode, location.locationTwoCode, location.description, location.locationReasonCode ? parseInt(location.locationReasonCode) : null);
                    break;
                case "Friday":
                    timeEntryTemplate.locationsByWeek[5] = new LocationInformation(location.countryCode, location.locationOneCode, location.locationTwoCode, location.description, location.locationReasonCode ? parseInt(location.locationReasonCode) : null);
                    break;
                case "Saturday":
                    timeEntryTemplate.locationsByWeek[6] = new LocationInformation(location.countryCode, location.locationOneCode, location.locationTwoCode, location.description, location.locationReasonCode ? parseInt(location.locationReasonCode) : null);
                    break;
                default:
                    break;
            }
        });

        timeEntryTemplate.tasks.push(this.createEmptyTask());
        timeEntryTemplate.useTimesheetTemplate = tetOutput.shouldUseTimeEntryTemplate;

        return timeEntryTemplate;
    }

    private static createEmptyTask(): TimeEntryTemplateTask {
        let task = new TimeEntryTemplateTask();
        task.assignment = new TimeEntryTemplateAssignment();
        task.assignment.isEmpty = true;
        return task;
    }

    public static mapTimeEntryInput(timeEntryTemplate: TimeEntryTemplate): TimesheetTemplateInput {
        var timeEntryTemplateInput = new TimesheetTemplateInput();
        timeEntryTemplateInput.tasks = new Array<TaskTemplateInput>();
        timeEntryTemplateInput.locationsByDay = new Array<LocationByDayTemplateInput>();
        timeEntryTemplateInput.enterpriseId = timeEntryTemplate.enterpriseId;
        timeEntryTemplateInput.useTimesheetTemplate = timeEntryTemplate.useTimesheetTemplate;
        let locationsByWeek = this.MapValueStartwithMonday(timeEntryTemplate.locationsByWeek);
        locationsByWeek.forEach(x => {
            let timeLocationEntry = new LocationByDayTemplateInput();
            timeLocationEntry.countryCode = x.countryCode;
            timeLocationEntry.locationOneCode = x.locationOneCode;
            timeLocationEntry.locationTwoCode = x.locationTwoCode;
            timeLocationEntry.locationReasonId = x.locationReasonCode;
            timeLocationEntry.locationTypeId = x.locationTypeCode;
            timeEntryTemplateInput.locationsByDay.push(timeLocationEntry);
        });
        timeEntryTemplate.tasks.forEach(task => {
            if (task.assignment.code) {
                let newTask = new TaskTemplateInput();
                newTask.hours = this.MapValueStartwithMonday(task.charges);
                newTask.assignment = new TimeEntryTemplateAssignmentInput();
                newTask.assignment.code = task.assignment.code;
                newTask.assignment.description = task.assignment.description;
                newTask.assignment.isValid = task.assignment.isValid;
                timeEntryTemplateInput.tasks.push(newTask);
            }
        });

        return timeEntryTemplateInput;
    }

    private static MapValueStartwithMonday(values: any[]) : any[]{
        let mondayHourArray = values.slice(1);
        let sundayHourArray = values.slice(0, 1);
        return mondayHourArray.concat(sundayHourArray);
    }

    public static mapTimeEntryTemplateAfterSave(timeEntryTemplate: TimeEntryTemplate, timeEntryTempleteSaved: TimeEntryTemplate): TimeEntryTemplate{
        timeEntryTempleteSaved.locationsByWeek = timeEntryTemplate.locationsByWeek;
        timeEntryTempleteSaved.enterpriseId = timeEntryTemplate.enterpriseId;
        timeEntryTempleteSaved.assignements = timeEntryTemplate.assignements;
        timeEntryTempleteSaved.totals = timeEntryTemplate.totals;
        timeEntryTempleteSaved.useTimesheetTemplate = timeEntryTemplate.useTimesheetTemplate;
        timeEntryTemplate.tasks.forEach(taskTemp => {
          var taskFiltered = timeEntryTempleteSaved.tasks.find(task => task.assignment.code == taskTemp.assignment.code && taskTemp.assignment.code != undefined);
          if (taskFiltered)
            taskFiltered.assignment.description = taskTemp.assignment.description;
        });
        return timeEntryTempleteSaved;
    }
}