<div class="represent-class" onblur="handleBlurEvent($event)">
</div>

<div id="alertrecordMyTime" role="alert" aria-live="polite" aria-hidden="true" class="screenReaderReadable" >
    <span>The selected mode is Record my Time. You are entering information in your own time report</span>
</div>
<div id="alert-reviewer-mode" role="alert" aria-live="polite" aria-hidden="true" class="screenReaderReadable" >
    <span>The selected mode is Review Another Person. You are reviewing a time report for a person who designated you as a myTE reviewer</span>
</div>
<div id="alert-review-another" role="alert" aria-live="polite" aria-hidden="true" class="screenReaderReadable" >
    <span>The selected mode is Approve Another Person. You are approving a time report for a person who designated you as a myTE approver</span>
</div>
<div id="alert-review-person" role="alert" aria-live="polite" aria-hidden="true" class="screenReaderReadable" >
    <span>The selected mode is Represent Another Person. You are entering information in a time report for a person who authorized you as a myTE delegate</span>
</div>


