import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeReportStatusToUiStatus'
})
export class TimeReportStatusToUiStatusPipe implements PipeTransform {

  transform(value: string): any {
    let timeReportStatusForUiDisplay = value;
    switch (value) {
      case 'New':
        timeReportStatusForUiDisplay = 'New';
        break;

      case 'Draft':
        timeReportStatusForUiDisplay = 'Draft';
        break;

      case 'Submitted':
        timeReportStatusForUiDisplay = 'Submitted';
        break;

      case 'Processed':
        timeReportStatusForUiDisplay = 'Processed';
        break;

      case 'SubmitPendingAdjustment':
        timeReportStatusForUiDisplay = 'Completed Adjustment';
        break;
      
      case 'SubmittedPendingAdjustment':
        timeReportStatusForUiDisplay = 'Completed Adjustment';
        break;
        

      case 'SubmittedAdjustment':
        timeReportStatusForUiDisplay = 'Submitted Adjustment';
        break;

      case 'DraftAdjustment':
        timeReportStatusForUiDisplay = 'Draft Adjustment';
        break;

      case 'SubmittedPendingApproval':
        timeReportStatusForUiDisplay = 'Sent for Approval';
        break;

      case 'DraftPendingUpdates':
        timeReportStatusForUiDisplay = 'Approver Requires Updates';
        break;

      case 'ProcessedAdjustment':
        timeReportStatusForUiDisplay = 'Processed Adjustment';
        break;
    }
    return timeReportStatusForUiDisplay;
  }

}
