<div
  (drop)="dropHandler($event)"
  (dragenter)="dragEnterHandler($event)"
  (dragover)="dragOverHandler($event)"
  (dragleave)="dragLeaveHandler($event)"
  (keyup.Enter)="check($event)"
  (keyup.Space)="check($event)"
  class="renderer-container"
  [ngClass]="{invalid: !isValid}"
  [ngClass]="{customfocuscell: customFocus}"
  ngbTooltip="{{ validationErrors }}" placement="bottom-start" 
  attr.role="{{isCheckBox && customFocus ? 'checkbox' : null}}" 
  container="body" tabindex="0"
  id="expGridDefRender" aria-checked="false"
>
  {{ value }}
  <div *ngIf="isCheckBox && customFocus" id="usab">



  </div>
</div>
