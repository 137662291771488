// Inspired in: https://devblogs.microsoft.com/premier-developer/angular-how-to-editable-config-files/

import { Injectable, isDevMode } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';

import { catchError, take, tap } from 'rxjs/operators';
import { IAppShellConfig } from './config.interfaces';
import { environment } from '../../../environments/environment';
import { throwError } from 'rxjs';
import jsonFile from '../../../../package.json';

@Injectable({
  providedIn: 'root'
})
export class AppShellConfig {
  static settings: IAppShellConfig;
  private static readonly appConfigPath = 'assets/config/app-shell.config' + ((environment.production) ? '.prod' : '') + '.json';
  private static readonly isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
  // Key to search the config into the sessionStorage
  private static readonly keyConfig = 'myteShellConfig';
  // Actual version, from package.json
  private static readonly versionConfig = jsonFile.version;

  constructor(private httpClient: HttpClient, private handler: HttpBackend) { }

  // Simplified version from: https://stackoverflow.com/a/49707898/1116959
  async load(): Promise<void | IAppShellConfig> {
    const configFile = 'assets/config/app-shell.config' + ((environment.production) ? '.prod' : '') + '.json';
    
    return new Promise((resolve, reject) => {
      const configParsed = JSON.parse(sessionStorage.getItem(AppShellConfig.keyConfig));
      if (configParsed) {
        AppShellConfig.settings = configParsed;
        resolve();
        return;
      }
      this.httpClient = new HttpClient(this.handler);
      let versionConfig = AppShellConfig.appConfigPath +`?v=${AppShellConfig.versionConfig}`;
      this.httpClient
        .get(versionConfig)
        .pipe(take(1), catchError(err => throwError(() => new Error(err || 'Server error'))))
        .subscribe((configResponse: any) => {
            sessionStorage.setItem(AppShellConfig.keyConfig, JSON.stringify(configResponse));
            AppShellConfig.settings = configResponse;
            resolve();
        });
    });
  }
}
