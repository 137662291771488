import {
    errorMonthMessage,
    languageMasks,
    formatExceptions,
    daysNamesLanguagesExceptions,
    gridAmexLanguagesExceptions,
    browserDateFormatExceptions,
    shortDates,
    TwentyFourHoursTimeFormat
} from './constants';
import * as moment from 'moment';

export abstract class GlobalDateFormater {
    public static errorMonthMessage: string = errorMonthMessage;
    public static language: string = navigator.language;
    public static languageMasks = languageMasks;
    public static isFormatException: boolean = formatExceptions.includes(this.language);
    public static isBulgarianLanguage: boolean = this.language.includes('bg');
    public static isGermanLanguage: boolean = daysNamesLanguagesExceptions.includes(this.language);
    public static isChineseLanguage: boolean = gridAmexLanguagesExceptions.includes(this.language);
    public static isDashMask: boolean = browserDateFormatExceptions.dashExceptions.includes(this.language);
    public static isSlashMask: boolean = browserDateFormatExceptions.slashExceptions.includes(this.language);
    public static shortDates = shortDates;
    public static dateMask: string = !this.languageMasks[this.language] ? this.languageMasks['en-US'] : this.languageMasks[this.language];


    public static formatDate(date: any) {
        if (!date) return date;

        if (typeof date == "string")
            date = new Date(date);

        if (this.isFormatException) {
            if (this.language == "th")
                return this.toThaiYear(date);

            return this.toIslamicYear(date);
        }

        // We add toUpperCase since moment take some mask like a different format and display with month or day name insted of number
        const outputDate = moment(date).format(this.dateMask.toUpperCase());
        return this.isBulgarianLanguage ? outputDate + ' r.' : outputDate;
    }

    public static getFullFormattedDateByLanguage(date: any): string {
        if (!date) return;

        if (date.month < 0 || date.month > 12) throw new Error(this.errorMonthMessage);
        const newDate = new Date(date.year, date.month - 1, date.day);

        return moment(newDate).locale(this.language).format('dddd, MMMM DD, YYYY');
    }

    public static convertDateToStringWithTime(data: any, formatSeconds: boolean = false): any {
        if (typeof data == "string") {
            return this.convertStringToDate(data);
        }

        if (formatSeconds) {
            return moment(data).format('DD-MM-YYYY') + ' ' + moment(data).format('LT');
        }

        return this.formatDate(data) + " " + data.toLocaleTimeString();
    }

    public static convertDateToStringWithTwentyFourHourTime(data: any): string {
        //parsing date and time to twentyfourhours
        return moment(data).format(this.dateMask + ' '+ TwentyFourHoursTimeFormat);
    }

    public static toIslamicYear(date: Date): string {
        //parsing of date to islamic
        let myFormat = 'en-u-ca-islamic-umalqura-nu-latn'; // use islamic-umalqura calendar (most modern)
        let myDateIslamic = new Intl.DateTimeFormat(myFormat).format(date);
        let dateArray = myDateIslamic.split('/');

        //corrects the digit format
        let month = dateArray[0].padStart(2, "0");
        let day = dateArray[1].padStart(2, "0");
        let year = dateArray[2].substring(2, 4);

        return day + "/" + month + "/" + year;
    }

    public static toThaiYear(date: Date): string {
        let myThaiDate = Intl.DateTimeFormat('en-US-u-ca-buddhist').format(date);
        let dateArray = myThaiDate.split('/');
        let month = dateArray[0];
        let day = dateArray[1];
        let year = dateArray[2].substring(0, 4);

        return `${day}/${month}/${year}`;
    }

    public static getFullMonthByLanguage(date: Date): string {
        if (!date) return;

        return moment(date).locale(this.language).format('MMMM');
    }

    private static stringToDate(date: string, format: string, delimiter: string): Date {
        let formatLowerCase = format.toLowerCase();
        let formatItems = formatLowerCase.split(delimiter);
        let dateItems = date.split(delimiter);

        //get index of the components of the date
        let monthIndex = formatItems.indexOf("mm");
        let dayIndex = formatItems.indexOf("dd");
        let yearIndex = formatItems.indexOf("yyyy");


        var month = parseInt(dateItems[monthIndex]);

        if (month < 0 || month > 12) throw new Error(this.errorMonthMessage);
        //decrease one month because in the parsing theres one month of diference
        month -= 1;

        var formatedDate = new Date(parseInt(dateItems[yearIndex]), month, parseInt(dateItems[dayIndex]));
        return formatedDate;
    }

    public static convertStringToDate(data: string, withTime = true): string {
        let dateString = data.split(' ');

        //regular exprression to get the delimiter
        let delimeter = dateString[0].match("([-/.])")

        //gets format date for the string date that comes from backend
        let dateformat = moment(data).creationData().format;
        let format = dateformat == undefined ? "mm/dd/yyyy" : dateformat.toString();

        var date = this.stringToDate(dateString[0], format, delimeter[0])

        if (withTime) {
            let time = dateString[1].split(':');
            var hour;

            //check if the time is pm and is 00 add 12 hours
            if (dateString[2] == "PM" && time[0] != "12")
                hour = parseInt(time[0]) + 12;
            else
                hour = parseInt(time[0])
            date.setHours(hour, parseInt(time[1]), parseInt(time[2]));

            return this.formatDate(date) + " " + date.toLocaleTimeString();

        }

        return this.formatDate(date);
    }

    public static getDayNameByLanguage(date: Date, short: boolean = false): string {
        if (!date) return;

        if (short == false)
            return moment(date).locale('en-US').format('ddd');

        if (this.isGermanLanguage)
            return moment(date).locale(this.language).format('dd');
        return moment(date).locale(this.language).format('ddd');
    }

    public static formatDateToGridAmexImport(date: Date): string {
        if (this.isChineseLanguage)
            return moment(date, this.language).format('YYYY/M/D  ddd');
        return this.formatDate(date) + ' ' + this.getDayNameByLanguage(new Date(date));
    }

    public static getMaskDate(): string {
        return this.isBulgarianLanguage ? this.dateMask.toLowerCase() + " r." : this.dateMask.toLowerCase();
    }

    public static getIndexOfFirstDayOfWeekByLanguage(): number {
        return moment().locale(this.language).localeData().firstDayOfWeek();
    }

    public static getBrowserDateFormat(): string {
        if (this.isSlashMask)
            return 'DD/MM/YYYY';
        if (this.isDashMask)
            return 'DD-MM-YYYY';
        return this.dateMask;
    }

    public static getDateNumber(date: Date): string {
        return moment(date).format('DD');
    }

    public static getFullDay(shortDay: string): string {
        return !this.shortDates[shortDay] ? '' : this.shortDates[shortDay];
    }

    public static getWeekDaysByLanguage(): string[] {
        moment.locale('en-US');
        return moment.weekdays();
    }

    public static getDateForFilter(date): string {
        return moment(date).format("DD/MM/YYYY");
    }

    public static getDateForMultipleLocations(date: Date): string {
        return moment(date).format('ddd DD');
    }

    public static getDateForLocationQuestionnaire(date: Date): string {
        return moment(date).format('MM/DD');
    }

    /**
 * Checks if the given date is a business day (Monday to Friday).
 * @param date The date to check.
 * @returns Returns true if the date is a business day, otherwise returns false.
 */
    public static validateBusinessDays(date: Date) {
        let isBusinessDay: boolean = false;

        // Define an array of business days (Monday to Friday)
        const businessDaysOfWeek = [1, 2, 3, 4, 5];

        // Check if the day of the week of the given date is in the array of business days
        isBusinessDay = businessDaysOfWeek.includes(date.getDay());
        return isBusinessDay;
    }

    /**
 * Checks if the given date falls within the first fortnight of the month.
 * @param date The date to check.
 * @returns Returns true if the date is in the first fortnight, otherwise returns false.
 */
    public static isFirstFortnight(date: Date): boolean {
        return date?.getDate() <= 15;
    }
    /**
     * 
     * @param date Date: verify if the language is getting formatted correctlly
     * @returns 
     * Method to format dates for backend
     */
    public static formatDateForBackend(date: Date): string {
        return moment(date).format("MM-DD-YYYY");
    }

}

