import { ActivityOutputItem } from "./activity-output-item";


export class ActivityOutPut {
    public activityDisplayName?: string;
    public shouldDisplayTextMessage?: boolean;
    public textDisplayed?: string;
    public timeReportStatus?: string;
    public activityOutputItem?: ActivityOutputItem[] = [];
    public modified: boolean = false;
    public isCacheFirst: boolean = false;
    constructor(
        activityDisplayName?: string,
        shouldDisplayTextMessage?: boolean,
        textDisplayed?:string,
        timeReportStatus?: string,
        activityOutputItem:ActivityOutputItem[] = []
    ) {
        this.activityDisplayName = activityDisplayName;
        this.shouldDisplayTextMessage = shouldDisplayTextMessage;
        this.textDisplayed = textDisplayed;
        this.timeReportStatus = timeReportStatus;
        this.activityOutputItem = activityOutputItem;
    }
}