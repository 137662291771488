export const ReceiptRequirementIcons = [
  {
    name: 'camera',
    url: '/assets/icons/receipt-requirement/Camera.svg'
  },
  {
    name: 'camera-checked',
    url: '/assets/icons/receipt-requirement/CameraChecked.svg'
  },
  {
    name: 'camera-required',
    url: '/assets/icons/receipt-requirement/CameraRequired.svg'
  },
  {
    name: 'envelope',
    url: '/assets/icons/receipt-requirement/Envelope.svg'
  },
  {
    name: 'envelope-checked',
    url: '/assets/icons/receipt-requirement/EnvelopeChecked.svg'
  },
  {
    name: 'envelope-required',
    url: '/assets/icons/receipt-requirement/EnvelopeRequired.svg'
  },
  {
    name: 'no-envelope',
    url: '/assets/icons/receipt-requirement/NoEnvelope.svg'
  },
  {
    name: 'or',
    url: '/assets/icons/receipt-requirement/Or.svg'
  }
];
