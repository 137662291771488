export default class ExpenseTripDetail {
    public id?: number;    
    public name?: string;
    public reason?: string;

    constructor(id?: number, name?: string, reason?: string) {
        this.id = id? id:null;
        this.name = name? name:'Undefined Trip Purpose/Reason';
        this.reason = reason? reason :'' ;
      }
}