import { RequestMocked, RequestTypes } from '../request-mocked';

export abstract class TimeRequestMock {

    public static viewTime: RequestMocked = {
        description: 'View Time Request',
        requestBody: '',
        requestType: RequestTypes.GET,
        url: 'viewTime?',
        urlJson: '../../../assets/data/time-tab/view-time-mock.json'
    };

    public static getTimeReportHistory: RequestMocked = {
        description: 'Get Time Report History',
        requestBody: '',
        requestType: RequestTypes.GET,
        url: 'getTimeReportHistory',
        urlJson: '../../../assets/data/time-tab/timeRepot-history-log-mock.json'
    };

    public static getTimeSheet: RequestMocked = {
        description: 'Get TimeSheet with all task and entries',
        requestBody: '',
        requestType: RequestTypes.GET,
        url: 'GetTimeSheet',
        urlJson: '../../../assets/data/time-tab/get-timesheet-mock.json'
    };

    public static saveTimeSheet: RequestMocked = {
        description: 'Save TimeSheet with entries and Task',
        requestBody: '',
        requestType: RequestTypes.POST,
        url: 'SaveTimeSheet',
        urlJson: '../../../assets/data/time-tab/save-timesheet-mock.json'
    };

    public static getTimeReportStatus: RequestMocked = {
        description: 'Get TimeReport Status',
        requestBody: '',
        requestType: RequestTypes.GET,
        url: 'GetTimeReportStatus',
        urlJson: '../../../assets/data/time-tab/timereport-status.json'
    };
    
}


