import { NgModule, APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AspectRatioComponent } from './aspect-ratio/aspect-ratio.component';
import { ImageShellComponent } from './image-shell/image-shell.component';
import { TextShellComponent } from './text-shell/text-shell.component';
import { AppShellConfig } from './config/app-shell.config';
import { IconShellComponent } from './icon-shell/icon-shell.component';
import { FormsModule } from '@angular/forms';

@NgModule({
    declarations: [
        AspectRatioComponent,
        ImageShellComponent,
        TextShellComponent,
        IconShellComponent
    ],
    exports: [
        AspectRatioComponent,
        ImageShellComponent,
        TextShellComponent,
        IconShellComponent
    ],
    imports: [
        CommonModule,
        FormsModule
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: (appShellConfig: AppShellConfig) => {
                return () => appShellConfig.load();
            },
            deps: [AppShellConfig],
            multi: true
        },
        provideHttpClient(withInterceptorsFromDi())
    ]
})
export class ShellModule { }
