export enum DropdownsId {
    AddTrip = 'add-trip-dropdown',
    SelectExpense = 'select-expense-dropdown',
}

export enum ButtonsId {
    AmexButton = 'Expenses_Grid_Controls_AMEX_Button',
    ThreeDotMenu = 'three-dots-menu',
    SplitButton = 'Expenses_Grid_Controls_Split_Button',
    CreateTripButton = 'Expenses_Grid_Controls_Trip_Button'
}

export enum PopupsId {
    AmexPopup = 'myte-amex-popup',
    SplitPopup = 'myte-popup-split',
    CreateTripPopup = 'myte-popup-create-trip',
    ReviewTripPopup = 'myte-popup-reviewTrip',
    TaxiPopup = 'myte-popup-taxi',
    UploadReceiptsPopup = 'myte-popup-upload-receipts',
}