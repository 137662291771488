import { Component } from '@angular/core';
import { GlobalCacheService } from '@shared/services/cache/global-cache.service';
import { TimeReportService } from '@shared/services/time-report/time-report.service';
import { UserService } from '@shared/services/user/user.service';
@Component({
  selector: 'myte-page-footer',
  templateUrl: './page-footer.component.html',
  styleUrls: ['./page-footer.component.sass']
})
export class PageFooterComponent {
  public showPopUp:boolean = false;
  public isClickedByLink:boolean=false;
  public actualYear = new Date().getFullYear();
  public myteVersion = '0.0.4';

  constructor(private timeReportService: TimeReportService,
    private userService: UserService,
    private cacheService: GlobalCacheService) { }
  
    ngOnInit(): void {
    if( this.cacheService.getPrivacyStatement() != null ){
      this.showPopUp = this.cacheService.getPrivacyStatement();
    }
    else{
      
      this.timeReportService.getPrivacyConfiguration("Next",this.userService.getUser().enterpriseId.toLowerCase()).subscribe( hasNotBeenShowed =>{
        if(hasNotBeenShowed){
          this.cacheService.setPrivacyStatement(!hasNotBeenShowed)
        }

        this.showPopUp = !hasNotBeenShowed;
    })
     }
    
  }
  public changePrivacyStatement(){
    this.isClickedByLink = true;
    this.showPopUp = true;
  }
  public onClose(){
    if(this.isClickedByLink){
       this.showPopUp = false;
    }
    else{
        this.timeReportService.savePrivacyConfiguration("Next",this.userService.getUser().enterpriseId.toLowerCase()).subscribe( hasNotBeenShowed =>{
        this.cacheService.setPrivacyStatement(!hasNotBeenShowed)
        this.showPopUp = false;
      })
    }
  }
}
