import { Component, HostListener } from '@angular/core';
import { ExpenseMultipleTaxiGrid, ExpenseMultipleTaxiGridReasons } from '@shared/models/expense/multipleTaxi/expense-multiple-grid';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { GridApi, ICellEditorParams } from 'ag-grid-community';
import { Option } from '../../../../../shared/clients/expense-api-client';
import DropdownItem from '../../../../../shared/models/controls/dropdown/dropdown-item';

@Component({
  selector: 'myte-taxi-reason-editor',
  templateUrl: './taxi-reason-editor.component.html',
  styleUrls: ['./taxi-reason-editor.component.sass']
})
export class TaxiReasonEditorComponent implements ICellEditorAngularComp {
  public expenseReasonLists: DropdownItem[] = [];
  public selectedReasons: ExpenseMultipleTaxiGridReasons;
  private api: GridApi<ExpenseMultipleTaxiGrid>;

  constructor() { }

  public getValue(): ExpenseMultipleTaxiGridReasons {
    return this.selectedReasons;
  }

  public agInit(params: ICellEditorParams<ExpenseMultipleTaxiGrid, ExpenseMultipleTaxiGridReasons> & { assignments: Option[] }): void {
    this.api = params.api;
    this.selectedReasons = params.value;
    Object.assign(this.expenseReasonLists, params.value.reasonsList);
  }

  public isPopup?(): boolean {
    return true;
  }

  public ngAfterViewInit() {
    this.expenseReasonLists.unshift({ key: -1, value: "--Select one--", isValid: true })
  }

  public updateReasonValue(reason: DropdownItem): void {
    this.expenseReasonLists.forEach(as => {
      if (as.value === reason.value) {
        this.selectedReasons.selectedReason = as.key;
        this.selectedReasons.selectedReasonDescription = as.value;
      }
    });
    this.api.stopEditing();
  }

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    if (event.key == 'Tab' || event.code == 'Tab') {
      event.stopPropagation();
      event.preventDefault();
    }
    else if (event.key == 'Escape' || event.code == 'Escape') {
      event.stopPropagation();
      this.api.stopEditing();
      var focusedCell = this.api.getFocusedCell();
      if (focusedCell != null && focusedCell != undefined) {
        this.api.setFocusedCell(focusedCell.rowIndex, focusedCell.column.getColId());
      }
    }
    else if (event.key == 'ArrowDown' || event.code == 'ArrowDown') {
      event.stopPropagation();
      event.preventDefault();
      var next = document.activeElement.nextElementSibling;
      if (next != null && next != undefined) {
        (next as HTMLElement).focus()
      }
    }
    else if (event.key == 'ArrowUp' || event.code == 'ArrowUp') {
      event.stopPropagation();
      event.preventDefault();
      var previous = document.activeElement.previousElementSibling;
      if (previous != null && previous != undefined) {
        (previous as HTMLElement).focus()
      }
    }
  }
}