import { Component } from '@angular/core';
import ExpenseGridItem from '@shared/models/expense/expense-grid-item';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ExpenseGridEventsService } from '../../../../myte-expenses/shared/services/events/expense-grid-events.service';
import ExpenseServiceMapper from '../../../../myte-expenses/shared/services/expense/expense.service.mapper';
import { GlobalCacheService } from '../../../../shared/services/cache/global-cache.service';
import { TimeReportService } from '../../../../shared/services/time-report/time-report.service';
import { NumberFormatUtils } from '../../../../shared/utils/numberFormater.utils';

@Component({
  selector: 'myte-expense-grid-total-reimbursement-renderer',
  templateUrl: './expense-grid-total-reimbursement-renderer.component.html',
  styleUrls: ['./expense-grid-total-reimbursement-renderer.component.sass']
})
export class ExpenseGridTotalReimbursementRendererComponent implements ICellRendererAngularComp {
  public param: ICellRendererParams<ExpenseGridItem>;
  public tooltip = '';
  public amount: any;
  public totalAmount: string;
  public compensatory: any;
  public showCompensatory = false;
  public errMessage : string;
  public isFirstColumn: boolean = false;
  constructor(
    public expenseGridEventService: ExpenseGridEventsService,
    public cacheService: GlobalCacheService,
    public timeReportService: TimeReportService,
    public globalCacheService: GlobalCacheService) {
  }

  public agInit(param: ICellRendererParams<ExpenseGridItem>): void {
    this.param = param;
    this.isFirstColumn = this.param.column.getColId() == '0';
    this.totalAmount = String(param.data.additionalInformation).slice(0, String(param.data.additionalInformation).indexOf(ExpenseServiceMapper.decimalSeparator()) + 3).trim();
    this.compensatory = NumberFormatUtils.formatNumberToLocaleString(Number((+(param.data.taxTrueUpValue))), 2, 2);
    this.amount = NumberFormatUtils.formatNumberToLocaleString(Number((+(ExpenseServiceMapper.parseMoney(this.totalAmount)) - Number(param.data.taxTrueUpValue))), 2, 2);
    this.showCompensatory = Number(param.data.taxTrueUpValue) != 0 && param.colDef.field == 'additionalInformation';
    this.errMessage = this.param.context.reimbursementErrorMsg
    if (this.errMessage != null) {
      this.tooltip = this.errMessage;
    }
  }

  public refresh(): boolean {
    return false;
  }
}
