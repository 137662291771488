import { TimeReportStatusCode } from '@sharedModels/time-report/time-report-status-code';

export default class Subordinate {
    public peopleKey: number;
    public enterpriseId: string;
    public firstName: string;
    public lastName: string;
    public countryKey: string;
    public profilePictureUrl: string;
    public statusCode: TimeReportStatusCode;
    public statusCodeDescription?: string;
    public errorMessage: string;
    public checked?: boolean;
    public timeReportStatus?: string;
    public isCounselee: boolean;
    public isReviewee: boolean;
    public actionFromSupervisor?: {
        icon: string,
        text: string,
        toolTip: string
    }
}