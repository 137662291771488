import { Injectable } from '@angular/core';
import { AppConfigService } from '@authServices/app-config.service';
import { UniversalHeader } from '@shared/constant';
import { Countries_For_Anz_Approval, CountryKey } from "@shared/utils/constants";
import { ApprovalOvertimeAndAllowanceConstantService } from '../../../myte-approval-overtime-and-allowance/constants/myte-approval-overtime-and-allowance-constant.service';
import { UserRole } from '@shared/models/user/user-roles';


@Injectable({
    providedIn: 'root'
})
export class PageGuardEvaluationService {

    private enableAudit: boolean;

    constructor(
        private appConfigService: AppConfigService,
        private constantService: ApprovalOvertimeAndAllowanceConstantService) {
        this.enableAudit = this.appConfigService?.getAppConfig?.AdditionalConfig?.enableAuditNext ? true : false;
    }

    shouldDenyNavigation(keyButton: string, userActionMenu: any) {
        switch (keyButton) {
            case UniversalHeader.GridButtons.REQUESTOVERTIME:
                return !(userActionMenu?.userCountry == 'IN') && !userActionMenu?.request
            case UniversalHeader.GridButtons.APPROVEOVERTIMEANDALLOWANCE:
                return !userActionMenu?.sdl && (!userActionMenu?.isATCIUser || !userActionMenu?.isAllowanceApprover) && !(userActionMenu.userCountry == 'IN')
            case UniversalHeader.GridButtons.APPROVALALLOWANCEANDOVERTIME:
                return !Countries_For_Anz_Approval.includes(userActionMenu?.userCountry) || (!userActionMenu?.isAllowanceApprover && !userActionMenu?.isOvertimeApprover)
            case UniversalHeader.GridButtons.APPROVALOVERTIMEANDALLOWANCE:
                return this.constantService.countryKeyForCJ != userActionMenu?.userCountry || !userActionMenu?.isOvertimeApprover
            case UniversalHeader.ActionButtons.POSTPAYAUDIT:
                return userActionMenu?.isAuditPilotUser && !userActionMenu?.audit && !this.enableAudit
            case UniversalHeader.ActionButtons.AUTHORIZE:
                return !userActionMenu?.authorize
            case UniversalHeader.ActionButtons.PERDIEMS:
                return !userActionMenu?.admin
            case UniversalHeader.GridButtons.TIMECATEGORYREQUESTAPPROVAL:
                return (
                    Countries_For_Anz_Approval.includes(userActionMenu?.userCountry) ||
                    this.constantService.countryKeyForCJ === userActionMenu?.userCountry ||
                    CountryKey.IN === userActionMenu?.userCountry ||
                    !(
                        userActionMenu?.isOvertimeApprover ||
                        userActionMenu?.isAllowanceApprover ||
                        userActionMenu?.isWorkshiftApprover ||
                        userActionMenu?.isOnCallAndSWTimeApprover
                    )
                );
        }
    }

    private isUserAdminOrSupport(userActionMenu: any) {
        return userActionMenu?.userRole === UserRole.Support || userActionMenu?.userRole === UserRole.Administrator;
    }
}