export enum DescriptionTypes {
    CustomSchedule = 'Custom Schedule',
    RestDay = 'Rest Day',
    TotalHours = 'Total Hours',
    BreakLength = 'Break Length'
}

export enum ShiftType {
    CustomSchedule = 'Custom Schedule'
}

export const JP_AT_RO_Country = ['JP', 'AT', 'RO', 'DE'];

export const COUNTRIES_WITH_ZERO_BREAKDURATION = ['MY', 'PL', 'TH', 'CZ'];