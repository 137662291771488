export default class MyteApiMessage {
  public Text: string;
  public Type: string;
  public Category: string;
  public Order: number;

  constructor(text: string, category?: string) {
    this.Text= text; 
    category ? this.Category = category : this.Category = '';
  }
}

