import { AmexExpenseTypeModel } from './expense-amex-type-list';

export default class AmexForAgGrid {
    public transactionId: string;
    public amexHotelImportDataId: string;
    public date: Date;
    public dateFrom: string;
    public expenseTypeCode: string;
    public detail: string;
    public expenseTitle: string;
    public amount: number;
    public localCurrency: string;
    public localCountry: string;
    public amexExpenseType: string;
    public dateDisplayToDateWithDayOfWeek: string;
    public expensePreSelectedValue: string;
    public expensePreSelectedKey: string;
    public stateProvinceCd: string;
    public expenseType: AmexExpenseTypeModel[];
    public isAmexHotelFolioExpense: boolean;
    constructor(
        transactionId?: string,
        amexHotelImportDataId?: string,
        date?: Date,
        dateFrom?: string,
        expenseTypeCode?: string,
        detail?: string,
        expenseTitle?: string,
        amount?: number,
        localCurrency?: string,
        localCountry?: string,
        amexExpenseType?: string,
        dateDisplayToDateWithDayOfWeek?: string,
        expensePreSelectedValue?: string,
        expensePreSelectedKey?: string,
        stateProvinceCd?: string,
        expenseType?: AmexExpenseTypeModel[],
        currentBrowserLanguage?: string,
        isAmexHotelFolioExpense?: boolean
    ) {
        this.transactionId = transactionId;
        this.amexHotelImportDataId = amexHotelImportDataId;
        this.date = date;
        this.dateFrom = dateFrom;
        this.expenseTypeCode = expenseTypeCode;
        this.detail = detail;
        this.expenseTitle = expenseTitle;
        this.amount = amount;
        this.localCurrency = localCurrency;
        this.localCountry = localCountry;
        this.amexExpenseType = amexExpenseType;
        this.dateDisplayToDateWithDayOfWeek = dateDisplayToDateWithDayOfWeek;
        this.expensePreSelectedValue = expensePreSelectedValue;
        this.expensePreSelectedKey = expensePreSelectedKey;
        this.stateProvinceCd = stateProvinceCd;
        this.expenseType = expenseType;
        this.isAmexHotelFolioExpense = isAmexHotelFolioExpense;
    }
}