import { OvertimeSummaryInput } from "@shared/clients/time-api-client";
import { ApproveAndHoldOvertime } from "@shared/models/approve-hold-overtime/approve-hold-overtime";
import { RequestedOvertime } from "@shared/models/request-overtime/requested-overtime";

export default class OvertimeLimitDetaisServiceMapper {

    public static mapGetOvertimePopUpInput( requestedOvertimeInput: RequestedOvertime | ApproveAndHoldOvertime, isOvertimeApprover:boolean) : OvertimeSummaryInput{
        let overtimeSummaryInput = new OvertimeSummaryInput();
        if(isOvertimeApprover){
            let approveOvertimeInput = <ApproveAndHoldOvertime>requestedOvertimeInput;
            overtimeSummaryInput.enterpriseId = approveOvertimeInput.requesteeEnterpriseId;
            overtimeSummaryInput.peopleKey = 0;
            overtimeSummaryInput.fromDate = approveOvertimeInput.otDate;
            overtimeSummaryInput.toDate =  approveOvertimeInput.otDate;
        } else {
            let requestOvertimeInput = <RequestedOvertime>requestedOvertimeInput;
            overtimeSummaryInput.enterpriseId = requestOvertimeInput.enterpriseId;
            overtimeSummaryInput.peopleKey = requestOvertimeInput.peopleKey;
            overtimeSummaryInput.fromDate = requestOvertimeInput.fromDate;
            overtimeSummaryInput.toDate =  requestOvertimeInput.toDate;
        }

        return overtimeSummaryInput;
    }
}
