
import { RequestMocked , RequestTypes } from '../request-mocked';
export abstract class AdjustmentRequestMock {
    public static getAdjustments: RequestMocked = {
        description: 'Get Adjustments',
        requestBody: '',
        requestType: RequestTypes.GET,
        url: '/GetAdjustments',
        urlJson: '../../../assets/data/adjustments/adjustments-mock.json'
    };
}