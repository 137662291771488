<div class="preview-container">
  <div class="file-header-container">
    <p>{{ files[showingIndexFile].fileName }}</p>
    <button class="ms-auto btn-close" tabindex="0" (click)="close()"></button>
  </div>
  <div class="display-container">
    <div class="image-container" *ngIf="files[showingIndexFile].isImage()">
      <span class="helper"></span>
      <img alt="" #image class="img-responsive" [style.transform]="getRotation()" (click)="close()"
        [src]="files[showingIndexFile].getFileData()" />
    </div>
    <iframe title="iframeFile" *ngIf="files[showingIndexFile].isPdf() && !isLocalFile()" [src]="getPdfUrl()"></iframe>
   -- <div id="embedcontainer" *ngIf="files[showingIndexFile].isPdf() && isLocalFile() && !isIE"  [ngStyle]="{'width': '100%'}" >
      <embed id="embedpdf" [src]="getPdfUrl()" type="application/pdf" />

    </div>

     <!-- Simulate Change navigator Browser -->
      
      <!-- <div [ngStyle]="{'width': '120px'}">
    <button type="button" class="angularToolTip" (click)="changeIE()"> Change Browser Mechanism. </button>
    <div>IsIE ?  + {{isIE}}</div>
    </div> -->


    <div *ngIf="!files[showingIndexFile].isPdf() && !files[showingIndexFile].isImage()" class="receipt-nopreview">
      <mat-icon aria-hidden="true" fontIcon="error"></mat-icon>
      <label>Preview not available</label>
    </div>
  </div>
  <div class="file-controls-container">
    <div (click)="previousFile()" class="tooltipCustomContainer">
      <span class="tooltiptextTop">Previous Receipt</span>
      <mat-icon [ngClass]="{'disabled' : showingIndexFile == 0}" aria-hidden="false" aria-label="Previous Receipt" fontIcon="navigate_before"></mat-icon>
    </div>
    
    <div *ngIf="files[showingIndexFile].isImage()" [placement]="['bottom', 'top']" ngbTooltip="Rotate to Right" (click)="rotateRight()">
      <mat-icon aria-hidden="false" aria-label="Rotate Right" fontIcon="rotate_right"></mat-icon>
    </div>
    <div *ngIf="files[showingIndexFile].isImage()" [placement]="['bottom', 'top']" ngbTooltip="Zoom out" (click)="zoomOut()">
      <mat-icon aria-hidden="false" aria-label="Zoom Out" fontIcon="zoom_out"></mat-icon>
    </div>
    <div *ngIf="!files[showingIndexFile].isLocalFile()" class="tooltipCustomContainer" (click)="download()">
      <span class="tooltiptextTop">Download Receipt</span>
      <mat-icon aria-hidden="false" aria-label="Download Receipt" fontIcon="cloud_download"></mat-icon>
    </div>
    <div *ngIf="files[showingIndexFile].isImage()" [placement]="['bottom', 'top']" ngbTooltip="Zoom In" (click)="zoomIn()">
      <mat-icon aria-hidden="false" aria-label="Zoom In" fontIcon="zoom_in"></mat-icon>
    </div>
    <div *ngIf="files[showingIndexFile].isImage()" [placement]="['bottom', 'top']" ngbTooltip="Rotate to Left" (click)="rotateLeft()">
      <mat-icon aria-hidden="false" aria-label="Rotate Left" fontIcon="rotate_left"></mat-icon>
    </div>
    <div (click)="nextFile()" class="tooltipCustomContainer">
      <span class="tooltiptextTop">Next Receipt</span>
      <mat-icon [ngClass]="{'disabled' : files.length - 1 == showingIndexFile}" aria-hidden="false" aria-label="Next Receipt" fontIcon="navigate_next"></mat-icon>
    </div>
  </div>
</div>
