import { inject, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChargeCodeApiClient, CHARGECODES_API_BASE_URL } from './clients/chargecode-api-client';
import { ExpenseApiClient, EXPENSES_API_BASE_URL } from './clients/expense-api-client';
import { LocationsApiClient, LOCATIONS_API_BASE_URL } from './clients/locations-api-client';
import { TimeApiClient, TIME_API_BASE_URL } from './clients/time-api-client';
import { TimeReportApiClient, TIMEREPORT_API_BASE_URL } from './clients/timereport-api-client';
import { AppConfigService } from '@authServices/app-config.service';
import { PDFPrintApiClient, PRINT_PDF_BASE_URL, PRINT_PDF_GROUP_BASE_URL } from './clients/print-pdf-api-client';
import { ReportApiClient,REPORT_API_BASE_URL } from './clients/report-api-client';


@NgModule({
  imports: [
    CommonModule,
  ],
  providers: [
    ChargeCodeApiClient,
    ExpenseApiClient,
    LocationsApiClient,
    TimeApiClient,
    TimeReportApiClient,
    PDFPrintApiClient,
    ReportApiClient,
    { provide: CHARGECODES_API_BASE_URL, useFactory: () => inject(AppConfigService).getAppConfig.CHARGECODES_URL },
    { provide: EXPENSES_API_BASE_URL, useFactory: () => inject(AppConfigService).getAppConfig.EXPENSES_URL },
    { provide: LOCATIONS_API_BASE_URL, useFactory: () => inject(AppConfigService).getAppConfig.LOCATIONS_URL },
    { provide: TIME_API_BASE_URL, useFactory: () => inject(AppConfigService).getAppConfig.TIME_URL },
    { provide: TIMEREPORT_API_BASE_URL, useFactory: () => inject(AppConfigService).getAppConfig.TIMEREPORT_URL },
    { provide: PRINT_PDF_BASE_URL, useFactory: () => inject(AppConfigService).getAppConfig.PRINT_PDF_URL },
    { provide: PRINT_PDF_GROUP_BASE_URL, useFactory: () => inject(AppConfigService).getAppConfig.PRINT_PDF_GROUP_URL },
    { provide: REPORT_API_BASE_URL, useFactory: () => inject(AppConfigService).getAppConfig.REPORTS_URL }

  ]
})
export class ClientModule {}
