import { ExpenseViewFieldOption } from './expense-view-field-option';

export class ExpenseViewFieldChargeCodeOption extends ExpenseViewFieldOption {
  public client: string
  public countryRegion: string
  public type: string;
  public subtype: string;
  public projectType: string;
  public masterServicesAgreement: string;
  public tooltip: string;
  public hasSuspenseExpenseProject: boolean;

  constructor(key: string, value: string = "", client: string = "", countryRegion: string = "", type: string = "", subtype: string = "", isValid: boolean = true, tooltip: string = "", masterServicesAgreement: string="",projectType: string ="", hasSuspenseExpenseProject: boolean = false) {
      super(key, value, isValid);
      this.client = client;
      this.countryRegion = countryRegion;
      this.type = type;
      this.subtype = subtype;
      this.projectType = projectType;
      this.masterServicesAgreement = masterServicesAgreement;
      this.tooltip = tooltip;
      this.hasSuspenseExpenseProject = hasSuspenseExpenseProject;
  }

  public get displayName() {
    return this.key ? `${this.value??""} (${this.key})` : this.value;
  }
}
