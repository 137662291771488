import { Component, ElementRef, ViewChild } from '@angular/core';
import { ExpenseFieldWithValidationResult } from '@shared/clients/expense-api-client';
import ExpenseGridItem from '@shared/models/expense/expense-grid-item';
import { ReceiptRequirementTypes } from '@shared/models/expense/receipt/expense-receipt-requirement';
import TimeReportStore from '@shared/models/time-report/time-report-store.model';
import { TimeReportStoreService } from '@shared/services/store/time-report-store.service';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ExpenseGridEventsService } from '../../../../myte-expenses/shared/services/events/expense-grid-events.service';
import { ExpenseGridService } from '../../../../myte-expenses/shared/services/grids/expense-grid.service';
import ApprovalStatus from '../../../../shared/models/expense/expense-approval-status';
import { GlobalCacheService } from '../../../../shared/services/cache/global-cache.service';
import { FocusService } from '../../../../shared/services/focus/focus.service';
import { ReceiptsService } from '../../../../shared/services/receipts/receipts.service';
import { ActionStoreService } from '@shared/services/store/action-store.service';
import { ActionType } from '@shared/services/store/shared/action-type';
import { Observable, from } from 'rxjs';
import { SubordinatesMode } from '@shared/models/subordinates/subordinate-mode';
import { approvalStatusTooltip, elementColorByStatus } from './expense-grid-status-constants';

@Component({
  selector: 'myte-expense-grid-status-renderer',
  templateUrl: './expense-grid-status-renderer.component.html',
  styleUrls: ['./expense-grid-status-renderer.component.sass']
})
export class ExpenseGridStatusRendererComponent implements ICellRendererAngularComp {
  public params: ICellRendererParams<ExpenseGridItem, ExpenseFieldWithValidationResult>;
  public isGroupByTrip: boolean;
  public editable: boolean = true;
  public status: string;
  public toolTip: string;
  public colorStatusElement: string;
  public isGCUser: boolean;
  public countryKey: string;
  private expenseId: string;
  @ViewChild('inputfile')
  private fileInput: ElementRef<HTMLElement>;
  private periodEnd: Date;
  public olderThanAYear: boolean;
  public viewMode: string;
  public approvalStatusName: string;

  constructor(
    private gridService: ExpenseGridService,
    private receiptsService: ReceiptsService,
    public expenseGridEventService: ExpenseGridEventsService,
    public focusService: FocusService,
    public cacheService: GlobalCacheService,
    public timeReportStoreService: TimeReportStoreService,
    public actionService: ActionStoreService) {
  }

  public agInit(params: ICellRendererParams<ExpenseGridItem, ExpenseFieldWithValidationResult>): void {
    this.params = params;
    this.expenseId = this.params.data.id;
    this.isGroupByTrip = this.params.context.expenseGridParamsService.isGroupByTrip
    this.olderThanAYear=  this.params.context.expenseGridParamsService.isOlderThanAyear
    this.viewMode = this.params.context.expenseGridParamsService.viewMode;
    this.countryKey = this.params.context.expenseGridParamsService.countryUser
    this.isGCUser = this.params.context.expenseGridParamsService.shouldShowReceiptType;
    this.periodEnd = this.params.context.expenseGridParamsService.periodEnd;
    this.editable = !(this.olderThanAYear || this.viewMode == SubordinatesMode.Supervisor || this.viewMode == SubordinatesMode.Approver || this.viewMode == SubordinatesMode.Audit);
    if(params.value.value == 'Pending Review' && this.viewMode == SubordinatesMode.Audit)      {
      params.value.value = 'Escalated';
    }
    else if(params.value.value == 'Pending Review' && this.viewMode != SubordinatesMode.Audit) 
      params.value.value = 'PendingReview'
    this.approvalStatusName = ApprovalStatus[params.value.value];
    if(ApprovalStatus[params.value.value]){
      this.getStatusTooltip(this.approvalStatusName);
    }
    if (params.value && params.value.validationErrors) {
      this.toolTip = params.value.validationErrors;
    }
    this.colorStatus(this.approvalStatusName);
  }

  public refresh(): boolean {
    return false;
  }

  public getStatusTooltip(approvalStatusName: any): void {
    if(approvalStatusTooltip.hasOwnProperty(approvalStatusName)){
      this.status = approvalStatusTooltip[approvalStatusName].status;
      this.toolTip = approvalStatusTooltip[approvalStatusName].toolTip;
    }
    else{
      this.status = approvalStatusName;
      this.toolTip = null;
    }
  }

  public colorStatus(approvalStatusName: any): void {
    if(elementColorByStatus.hasOwnProperty(approvalStatusName)){
      this.colorStatusElement = elementColorByStatus[approvalStatusName].colorByStatus;
    }
    else{
      this.colorStatusElement = elementColorByStatus['Default'].colorByStatus;
    }
  }

  public handleClick(): void {
    let columnIndex = FocusService.getColumnIndexByColId(this.params?.api, 'status');
    let rowIndex = FocusService.getRowIndexByExpenseId(this.params?.api, this.expenseId);
    this.focusService.updateLastSelectedCell(this.params, columnIndex, rowIndex);
    if (this.params.value.value == 'ReceiptRequired' && this.editable) {
      if (!this.isGCUser) {
        this.fileInput.nativeElement.click();
      }
      else {
        this.onCancelFileUpload();
      }
    }
  }

  public onCancelFileUpload(): void {
    this.expenseGridEventService.dispatchShowUploadReceiptPopupEvent();
  }

  public onFileChange(files): void {
    this.expenseGridEventService.dispatchShowLoadingOverlayEvent(true);
    this.receiptsService.attachReceipts(files, this.expenseId, '')
      .subscribe(() => {
        this.receiptsService.getReceipts(this.expenseId, true)
          .subscribe((res) => {
            this.expenseGridEventService.dispatchExpenseFileReceiptUpdated(res.warningMessages);
            this.actionService.dispatchAction(ActionType.LOAD_EXPENSES,{shouldInitialize:true});
            this.focusService.focusLastSelectedCell();
          });
      })
  }

  public dropHandler(event): void {

    if (!["CN","TW","HK"].includes(this.countryKey)) {
      if (!this.editable) return;
      if (this.isGroupByTrip) return;
      event.preventDefault();
      event.stopPropagation();
      this.removeRowClass(event, 'row-file-dragged');
      event.dataTransfer.effectAllowed = 'copyMove';

      this.expenseGridEventService.dispatchShowLoadingOverlayEvent(true);
      if (this.params?.data?.type.value === 'Salary Supplement' && this.params.data.receipt.type != ReceiptRequirementTypes.Both) {
        this.actionService.dispatchAction(ActionType.LOAD_EXPENSES, {
          shouldInitialize: true,
          periodEnd: this.periodEnd
        });
        return;
      }
      this.receiptsService.attachReceipts(event.dataTransfer.files, this.params.data.id, '')
        .subscribe(() =>{
          this.actionService.dispatchAction(ActionType.LOAD_EXPENSES, {
            shouldInitialize: true,
            periodEnd: this.periodEnd
          });
        });
    }
  }

  public dragEnterHandler(event): boolean {
    if (!this.editable) return;
    if (this.isGroupByTrip) return;
    event.preventDefault();
    event.stopPropagation();
    return true;
  }

  public dragOverHandler(event): boolean {
    if (!this.editable) return;
    if (this.isGroupByTrip) return;
    event.preventDefault();
    event.stopPropagation();
    this.setRowClass(event, 'row-file-dragged');
    return true;
  }

  public dragLeaveHandler(event): boolean {
    if (!this.editable) return;
    if (this.isGroupByTrip) return;
    event.preventDefault();
    event.stopPropagation();
    this.removeRowClass(event, 'row-file-dragged');
    return true;
  }

  private setRowClass(event, className: string): void {
    const row = this.getRow(event);
    if (row && !row.classList.contains(className)) {
      row.classList.add(className);
    }
  }

  private removeRowClass(event, className: string): void {
    const row = this.getRow(event);
    if (row)
      row.classList.remove(className);
  }

  private getRow = (event) => {
    const path = event.path || (event.composedPath && event.composedPath()) || this.customComposedPath(event);
    if (path) {
      return path.find(p =>
        p.attributes && p.attributes.role && p.attributes.role.value == 'row' && p.attributes['row-index']);
    }
    return false;
  }

  private customComposedPath(event): any[] {
    let path = [];
    let currentElement = event.target;

    while (currentElement) {
      path.push(currentElement);
      if (currentElement.tagName === 'HTML') {
        path.push(document);
        path.push(window);
        return path;
      }
      currentElement = currentElement.parentElement;
    }
    return path;
  }
}
