export class LocationsSummaryRow {
    public country: string;
    public location: string;
    public hours: string;

    constructor(country?: string, location?: string, hours?: string) {
    
        this.country = country;
        this.location = location;
        this.hours = hours;
    }
}