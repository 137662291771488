import { Pipe, PipeTransform } from '@angular/core';
import { NumberFormatUtils } from '@sharedUtils/numberFormater.utils';

@Pipe({
  name: 'localNumber'
})
export class NumberFormatPipe implements PipeTransform {
  

  transform(value: any, minDigits: number,maxDigits:number) {
    if (!value) 
      return '';
    return NumberFormatUtils.formatNumberToLocaleString(Number(value), minDigits, maxDigits);
  }
}