export class AnnualChargeabilityRow {
  public id: number;
  public chargeableHours: string;
  public nonChargeableHours: string;
  public chargeabilityPercent: string;

  constructor(id?: number, chargeableHours?: string, nonChargeableHours?: string, chargeabilityPercent?: string) {
  
    this.id = id;
    this.chargeableHours = chargeableHours;
    this.nonChargeableHours = nonChargeableHours;
    this.chargeabilityPercent = chargeabilityPercent;
  }
}

export class AnnualChargeabilityChildRow extends AnnualChargeabilityRow {
  public chargeCode: string;

  constructor(chargeCode?: string, periodEnd?: Date) {
    super();
  
    this.chargeCode = chargeCode;
  }
}

export class AnnualChargeabilityParentRow extends AnnualChargeabilityChildRow {
  public periodEnd: Date;
  public tasks: Array<AnnualChargeabilityChildRow>;
  public timeReportId: number;
  public open: boolean;

  constructor(periodEnd?: Date, tasks?: Array<AnnualChargeabilityChildRow>, timeReportId?: number, open?: boolean) {
    super();
  
    this.periodEnd = periodEnd;
    this.tasks = tasks ?? new Array(0).fill(new AnnualChargeabilityChildRow());
    this.timeReportId = timeReportId;
    this.open = open;
  }
}

