export class ExpenseViewFieldOption {
    public key: string;
    public value: string;
    public isValid: boolean;

    constructor(key: string, value: string, isValid : boolean = true) {
        this.key = key;
        this.value = value;  
        this.isValid = isValid;      
    }
}
