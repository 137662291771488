import { Component } from '@angular/core';
import { TimeEntryTemplate } from '@shared/clients/time-api-client';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { NumberFormatUtils } from '../../../../shared/utils/numberFormater.utils';

@Component({
  selector: 'myte-time-entry-template-popup-total-grid-cell-render',
  templateUrl: './time-entry-template-popup-total-grid-cell-render.component.html',
  styleUrls: ['./time-entry-template-popup-total-grid-cell-render.component.sass']
})

export class TimeEntryTemplatePopupTotalGridCellRenderComponent implements ICellRendererAngularComp {
  public params: ICellRendererParams<TimeEntryTemplate, number> & { ariaLabel: string, id: string };
  public value: string;

  constructor() { }

  public agInit(params: ICellRendererParams<TimeEntryTemplate, number> & { ariaLabel: string, id: string }): void {
    this.params = params;
    this.value = params.value == null ? '0' : NumberFormatUtils.formatNumberToLocaleString(params.value, 1, 1);
  }

  public refresh(params: ICellRendererParams<TimeEntryTemplate, number>): boolean {
    return false;
  }
}