<div [id]="params.column.getColId()" class="header-cell" [ngClass]="headerClass" role="columnheader" aria-label=" ">
    <div *ngIf="params.displayName != '' " #displayName id="sort" class="customHeaderLabel" [attr.role] ="(params.enableSorting) ? 'button' : null" [attr.tabIndex]="(params.enableSorting) ? 0 : -1" [attr.aria-label]="params.displayName + ' sort filter'"  
    (click)="onSortRequested(toggleOrder(), $event)"  (keyup.Space)="onSortRequested(toggleOrder(), $event)" (keyup.Enter)="onSortRequested(toggleOrder(), $event)">{{params.displayName}}</div>
    <div *ngIf="params.enableSorting" role="button" aria-label="sort ascending" #arrowUpGrid (click)="onSortRequested('', $event)" (keyup.Space)="onSortRequested(toggleOrder(), $event)" (keyup.Enter)="onSortRequested(toggleOrder(), $event)" [ngClass]="ascSort" class="customSortDownLabel" tabindex="0"><mat-icon fontIcon="north"></mat-icon></div>
    <div *ngIf="params.enableSorting" role="button" aria-label="sort descending" #arrowDownGrid (click)="onSortRequested('', $event)" (keyup.Space)="onSortRequested(toggleOrder(), $event)" (keyup.Enter)="onSortRequested(toggleOrder(), $event)" [ngClass]="descSort" class="customSortUpLabel" tabindex="0"><mat-icon fontIcon="south"></mat-icon></div>
    <div *ngIf="params.enableMenu && !params.useMenuIcon" id="menu" role="search" #menuButton class="customHeaderMenuButton" (click)="onMenuClicked($event)" (keyup.Space)="onMenuClicked($event)" (keyup.Enter)="onMenuClicked($event)" tabindex="0" [attr.aria-label]="params.displayName + ' filter'"><mat-icon fontIcon="filter_alt"></mat-icon></div>
    <div *ngIf="params.enableMenu && params.useMenuIcon" id="menu" role="search" #menuButton class="customHeaderMenuButton menu-icon" (click)="onMenuClicked($event)" (keyup.Space)="onMenuClicked($event)" (keyup.Enter)="onMenuClicked($event)" tabindex="0" [attr.aria-label]="params.displayName + ' filter'"><mat-icon fontIcon="menu"></mat-icon></div>
    <div *ngIf="params.displayName == 'Receipt'" class="tooltip-info" ><mat-icon role="img" aria-label="Click camera or drag and drop up to 5 receipts per expense line here" fontIcon="info" placement="bottom" ngbTooltip="Click camera or drag and drop up to 5 receipts per expense line here" container="body" placement="start" tabindex="0"></mat-icon></div>
    <div *ngIf="params.displayName == setDateDisplayName()" class="tooltip-info" ><mat-icon fontIcon="info" placement="bottom" ngbTooltip="{{dateToolTip}}" container="body" ></mat-icon></div>  
    <div *ngIf="params.displayName == amount" class="tooltip-info" ><mat-icon fontIcon="info" placement="bottom" ngbTooltip="The value entered must be in the currency shown on field header." container="body" ></mat-icon></div>  
    <div *ngIf="params.displayName == 'Display'" class="tooltip-info" ><mat-icon fontIcon="info" placement="bottom" ngbTooltip="Display on Charge Code dropdowns within myTE such as entering time or expenses" container="body" placement="end"></mat-icon></div>
    <div *ngIf="params.displayName == 'Quarterly Overtime Hours'" class="tooltip-info" ><mat-icon fontIcon="info" placement="bottom" ngbTooltip="The sum of overtime hours processed in myTE and requested for the employee in the current fiscal year quarter. Please consider the quarterly limit of  50 hours when requesting overtime." container="body" placement="bottom"></mat-icon></div>
    <div *ngIf="params.displayName == 'Daily Overtime Requested'" class="tooltip-info" ><mat-icon fontIcon="info" placement="bottom" ngbTooltip="Please ensure overtime requests are not more than the overtime limits for a standard work day and a standard day off." container="body" placement="bottom"></mat-icon></div>
    <div *ngIf="params.displayName == 'Status' && params.context == 'requestovertime'" class="tooltip-info" ><mat-icon fontIcon="info" ngbTooltip="{{columnHeaderTooltipText.statusTooltip}}" container="body" placement="bottom"></mat-icon></div>
    <div *ngIf="params.tooltip" class="tooltip-info"><mat-icon fontIcon="info" [ngbTooltip]="params.tooltip" container="body" placement="top bottom"></mat-icon></div>
    <div *ngIf="params.displayName == 'Self Cert'" class="tooltip-info" ><mat-icon role="img" fontIcon="info" placement="bottom" ngbTooltip="{{columnHeaderTooltipText.selfCertTooltip}}" container="body" placement="start" tabindex="0"></mat-icon></div>
    <div *ngIf="params.displayName == 'Escalated'" class="tooltip-info" ><mat-icon role="img" fontIcon="info" placement="bottom" ngbTooltip="{{columnHeaderTooltipText.escalateTooltip}}" container="body" placement="start" tabindex="0"></mat-icon></div>
    <div *ngIf="params.column.getColId() == 'massPpaAvailableHours'" class="tooltip-info" ><mat-icon role="img" fontIcon="info" placement="bottom" ngbTooltip="{{massPpatooltip.availableHours}}" container="body" placement="end" tabindex="0"></mat-icon></div>

</div>
