import { RequestMocked, RequestTypes } from '../request-mocked';


export abstract class SummaryRequestMock {

    public static getTimeReportSummary: RequestMocked = {
        description: 'Get TimeReport Summary',
        requestBody: '',
        requestType: RequestTypes.GET,
        url: 'GetTimeReportSummary',
        urlJson: '../../../assets/data/time-tab/time-report-summary-mock.json'
    }


}