<div class="content-represent" id="generalContainerElement">
  <ng-container *ngIf="!showErr; else auditeeInfoNotAvailable">
  <div class="auditee-user-info">
    <div class="auditee-user-label">
      <label>{{ auditeeSideBar.auditedUser }}</label>
      <p>{{ auditeeDetails?.enterpriseId }}</p>
    </div>
    <div>
      <label>{{ auditeeSideBar.compliancePeriod }}</label>
      <p>{{auditeeDetails?.compliancePeriod}}</p>
    </div>
    <div>
      <label>{{ auditeeSideBar.trPeriod }}</label>
      <p>{{auditeeDetails?.timeReportPeriod}}</p>
    </div>
    <div>
      <label>{{ auditeeSideBar.employeePersonnelNum }}:</label>
      <p>{{auditeeDetails?.employeePersonalNumber}}</p>
    </div>
    <div>
      <label>{{ auditeeSideBar.countryRegion }}:</label>
      <p>{{auditeeDetails?.country}}</p>
    </div>
    <div>
      <label>{{ auditeeSideBar.companyCode }}:</label>
      <p>{{auditeeDetails?.companyCode}}</p>
    </div>
    <div>
      <label>{{ auditeeSideBar.costCenter }}:</label>
      <p>{{auditeeDetails?.costCenter}}</p>
    </div>
    <div>
      <label>{{ auditeeSideBar.managementLevel }}:</label>
      <p>{{auditeeDetails?.carrerLevelGroup}}</p>
    </div>
    <div>
      <label>{{ auditeeSideBar.currentEmploymentStatus }}:</label>
      <p>{{auditeeDetails?.userActiveStatus}}</p>
    </div>
    <div>
      <label>{{ auditeeSideBar.usFederal }}:</label>
      <p>{{auditeeDetails?.usFederal}}</p>
    </div>
    <div>
      <label>{{ auditeeSideBar.terminationDate }}:</label>
      <p>{{auditeeDetails?.terminationDate}} </p>
    </div>
  </div>
</ng-container>
<ng-template #auditeeInfoNotAvailable>
  <div class="auditee-user-label">
    <label>Auditee info not available.</label>
  </div>
</ng-template>
</div>
