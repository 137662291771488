import TimeEntryAssignment from './time-entry-assignment';

export default class TimeEntryTask {
    public assignment: TimeEntryAssignment;
    public charges: any[] = [];
    public isDeletable: boolean;
    public isEditable: boolean;
    public sequenceNumber: number;
    public status: string;
    public tooltip: string;

    public get total(): number {
        
        let total = 0;
        let isNotSummableRow: boolean = this.assignment.code == "Assigned Location" || this.assignment.code == "Company Code/Cost Center" || this.assignment.code == "Work Location";
        if( isNotSummableRow ){
            return 0;
        }
        this.charges.map(x => total += x.value);
        return total;
    }
}