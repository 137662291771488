import { PeoplePicker } from '@sharedModels/controls/people-picker/people-picker';
import { DelegateeSelectorDto, AssignedRoleDescription } from "@sharedClients/timereport-api-client";
import Subordinate from "@sharedModels/subordinates/subordinate";
import { SubordinatesMode } from '@sharedModels/subordinates/subordinate-mode';
import { TimeReportStatusCode, TimeReportStatusCodeDescription } from '@sharedModels/time-report/time-report-status-code';
import { ActionWithMaticon } from '../../../shared/constant';
import { MyteTabControl } from '@shared/models/myte-tab-control.model';
import { MyteTabControlOutput as APIMyteTabControlOutput } from '@shared/clients/expense-api-client';

export class SubordinatesServiceMapper {

    public static mapAPIMyteTabControlOutput(apiMyteTabControlOutput: APIMyteTabControlOutput): MyteTabControl {
        const myteTabControl: MyteTabControl = new MyteTabControl();
        if (apiMyteTabControlOutput && Object.keys(apiMyteTabControlOutput).length > 0) {
            Object.assign(myteTabControl, apiMyteTabControlOutput);
            myteTabControl.timeTab = apiMyteTabControlOutput.timeTab;
            myteTabControl.expenseTab = apiMyteTabControlOutput.expenseTab;
            myteTabControl.locationsTab = apiMyteTabControlOutput.locationsTab;
            myteTabControl.adjustmentsTab = apiMyteTabControlOutput.adjustmentsTab;
            myteTabControl.summaryTab = apiMyteTabControlOutput.summaryTab;
            myteTabControl.assignmentsTab = apiMyteTabControlOutput.assignmentsTab;
            myteTabControl.auditTab = apiMyteTabControlOutput.auditTab;

        }
        return myteTabControl;
    }

    public static mapDtotoSubordinate(dto: DelegateeSelectorDto): Subordinate {
        let s = new Subordinate();
        s.countryKey = dto.countryCd;
        s.enterpriseId = dto.enterpriseId;
        s.firstName = dto.firstNm;
        s.lastName = dto.lastNm;
        s.peopleKey = dto.peopleKey;
        s.profilePictureUrl = dto.image;
        s.timeReportStatus = dto.timeReportStatus;
        s.statusCode = TimeReportStatusCode.getValueByString(dto.statusCode);
        s.statusCodeDescription = TimeReportStatusCodeDescription[dto.statusCode];
        s.actionFromSupervisor = this.searchActionByString(dto.actionCodeTooltip);
        s.isCounselee = dto.isCounselee;
        s.isReviewee = dto.isReviewee;
        return s;
    }

    public static searchActionByString(value: string) {
        return (ActionWithMaticon as any)[Object.keys(ActionWithMaticon).filter(k => (ActionWithMaticon as any)[k].actionFromHistory === value)[0]];
    }

    public static mapPeoplePickerToSubrodinate(pp: PeoplePicker): Subordinate {
        let s = new Subordinate();
        s.enterpriseId = pp.enterpriseId;
        s.peopleKey = pp.peopleKey;
        s.firstName = pp.firstName;
        s.lastName = pp.lastName;
        return s;
    }

    public static mapSubordinateModeToAssignedRole(mode: SubordinatesMode): AssignedRoleDescription {
        switch (mode) {
            case SubordinatesMode.None:
                return AssignedRoleDescription._0;

            case SubordinatesMode.Approver:
                return AssignedRoleDescription._1;

            case SubordinatesMode.Supervisor:
                return AssignedRoleDescription._2;
                
            case SubordinatesMode.Delegate:
                return AssignedRoleDescription._3;

            case SubordinatesMode.ProjectApprover:
                return AssignedRoleDescription._4;

            case SubordinatesMode.BackupApprover:
                return AssignedRoleDescription._5;

            case SubordinatesMode.OvertimeApprover:
                return AssignedRoleDescription._6;
        }
    }
}