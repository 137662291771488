import { LocationInformation } from './location-information';
import { DayEntry } from './day-entry';

export class LocationEntry {
  public location: LocationInformation;
  public dayEntries: Array<DayEntry>;
  public isDefault: boolean;
  public isDeletable: boolean;
  public hasAdjustment: boolean;
  public status: LocationEntryStatus;
  public isInValid: boolean;
  public errorMessage: string;

  /**
   *
   */
  constructor() {
    this.location = new LocationInformation();
    this.dayEntries = new Array<DayEntry>();
    this.isDefault = false;
    this.hasAdjustment = false;
    this.isDeletable = true;
  }
}

export enum LocationEntryStatus {
  added,
  removed,
  saved
}
