export class PersonalCommuteInput {
    public numberOfKmMiles: number;
    public otherTransportationExpenses: number;
    public methodOfTravel: string;
    public vehicleType: string;
    public carConsumption: number;
    public carRegistrationNo: string;
    public carPlan: boolean;
    public carPlanChargeNumber: string;
    public companyCar: boolean;
    public carType: string;
    constructor(
        numberOfKmMiles?: number,
        otherTransportationExpenses?: number,
        methodOfTravel?: string,
        vehicleType?: string,
        carConsumption?: number,
        carRegistrationNo?: string,
        carPlan?: boolean,
        carPlanChargeNumber?: string,
        companyCar?: boolean,
        carType?: string
    ) {
        this.numberOfKmMiles = numberOfKmMiles;
        this.otherTransportationExpenses = otherTransportationExpenses;
        this.methodOfTravel = methodOfTravel;
        this.vehicleType = vehicleType;
        this.carConsumption = carConsumption;
        this.carRegistrationNo = carRegistrationNo;
        this.carPlan = carPlan;
        this.carPlanChargeNumber = carPlanChargeNumber;
        this.companyCar = companyCar;
        this.carType = carType;
    }
}