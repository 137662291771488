import ExpenseTripReviewGrid from '../trip/expense-trip-review-grid';
export default class ExpenseTripInfo {
    public tripId: number;
    public tripGridData: ExpenseTripReviewGrid[];

    constructor(
        tripId: number,
        tripGridData: ExpenseTripReviewGrid[]
    ) {
        this.tripId = tripId;
        this.tripGridData = tripGridData;
    }
}