import { inject } from '@angular/core';
import { InjectionToken } from '@angular/core';
import { AppConfigService } from '@authServices/app-config.service';

export class MyteApiInjector {

    private static appConfigData: any = null;
    static getAppConfigData(appConfigService: AppConfigService) {
        if (this.appConfigData === null)
        this.appConfigData = appConfigService.getAppConfig;
        return this.appConfigData;
    }
}

export const EXPENSES_API_BASE_URL = new InjectionToken<string>('EXPENSES_API_BASE_URL', {
    providedIn: 'root',
    factory: () => MyteApiInjector.getAppConfigData(inject(AppConfigService)).EXPENSES_URL
});

export const TIME_API_BASE_URL = new InjectionToken<string>('TIME_API_BASE_URL', {
    providedIn: 'root',
    factory: () => MyteApiInjector.getAppConfigData(inject(AppConfigService)).TIME_URL
});

export const LOCATIONS_API_BASE_URL = new InjectionToken<string>('LOCATIONS_API_BASE_URL', {
    providedIn: 'root',
    factory: () => MyteApiInjector.getAppConfigData(inject(AppConfigService)).LOCATIONS_URL
});

export const TIME_LEGACY_API_BASE_URL = new InjectionToken<string>('TIME_LEGACY_API_BASE_URL', {
    providedIn: 'root',
    factory: () => MyteApiInjector.getAppConfigData(inject(AppConfigService)).TIME_LEGACY_URL
});

export const TECOMMON_API_BASE_URL = new InjectionToken<string>('TECOMMON_API_BASE_URL', {
    providedIn: 'root',
    factory: () => MyteApiInjector.getAppConfigData(inject(AppConfigService)).TECOMMON_URL
});

export const CHARGECODES_API_BASE_URL = new InjectionToken<string>('CHARGECODES_API_BASE_URL', {
    providedIn: 'root',
    factory: () => MyteApiInjector.getAppConfigData(inject(AppConfigService)).CHARGECODES_URL
});

export const TIMEREPORT_API_BASE_URL = new InjectionToken<string>('TIMEREPORT_API_BASE_URL', {
    providedIn: 'root',
    factory: () => MyteApiInjector.getAppConfigData(inject(AppConfigService)).TIMEREPORT_URL
});

export const PRINT_PDF_BASE_URL = new InjectionToken<string>('PRINT_PDF_BASE_URL', {
    providedIn: 'root',
    factory: () => MyteApiInjector.getAppConfigData(inject(AppConfigService)).PRINT_PDF_URL
});

export const PRINT_PDF_GROUP_BASE_URL = new InjectionToken<string>('PRINT_PDF_GROUP_BASE_URL', {
    providedIn: 'root',
    factory: () => MyteApiInjector.getAppConfigData(inject(AppConfigService)).PRINT_PDF_GROUP_URL
});

export const REPORT_API_BASE_URL = new InjectionToken<string>('REPORT_API_BASE_URL', {
    providedIn: 'root',
    factory: () => MyteApiInjector.getAppConfigData(inject(AppConfigService)).REPORTS_URL
});

