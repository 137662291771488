import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[myteInputFilter]'
})
export class InputFilterDirective {

  constructor(
    private elementRef: ElementRef
  ) { }
  @Input('myteInputFilter') isNumber: boolean;

  @HostListener('keyup', ['$event.target'])
  keyupFun(event) {
    
    
    if (event.value) {
      if (this.isNumber) {
        event.value = !isNaN(event.value) && event.value.indexOf('.') === -1 ? event.value : '';
      } else {
        event.value=this.IsValid(event.value, navigator.language) ? (event.value) : ''
      }
    }
  }
  
  IsValid(value: any, language: string) :boolean {
    let groupSeparator = this.groupSeparator();
    if(value.indexOf(groupSeparator)!=-1)
      return false;
    let decimalSeparator = this.decimalSeparator();
    
    let output = value.replace(new RegExp("\\"+groupSeparator, "g"), '');
    output = output.replace(new RegExp("\\"+decimalSeparator, "g"), '.');
    let parsedNumber = output.split('.');
    if (parsedNumber.length > 1) {
      let intPart = parsedNumber.slice(0, -1).join('');
      let decpart = parsedNumber.slice(-1)[0];
     return !isNaN(Number(intPart + '.' + decpart));
    }
    return !isNaN(Number(output));
  }

  private decimalSeparator = () => (1.1).toLocaleString(navigator.language).substring(1, 2);

  private groupSeparator() {
    let separator = (1000).toLocaleString(navigator.language).substring(1, 2);
    if (isNaN(Number(separator)) || separator.charCodeAt(0) == 160) return separator;
    separator = (10000).toLocaleString(navigator.language).substring(2, 3);
    if (isNaN(Number(separator)) || separator.charCodeAt(0) == 160) return separator;
    return '';
  }
  validateWithAnotherFormat(value: any, language: string) :boolean {
    value=parseFloat(value).toLocaleString(language, { minimumFractionDigits: 2 });
            let valueparsing=this.formatValueToUS(value);
            return !isNaN(Number(valueparsing));

  }
  
 

  public formatValueToUS(value: string): string {   
    return parseFloat(value).toLocaleString('en-US', { minimumFractionDigits: 2 });
}

public getDecimalLocale(val): string {
    const value = parseFloat(val).toLocaleString(navigator.language, { minimumFractionDigits: 2 });
    return value.replace(/\d+/g, '');
}
public formatValueToUSWithComma(value: string): string {
  // const decimalSeparator = this.getDecimalLocale(value);
  // const groupSeparator = decimalSeparator === ',' ? '.' : ',';
  return parseFloat(value).toLocaleString('en-US', { minimumFractionDigits: 2 }).replace('.','');
}

}
