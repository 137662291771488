import { ChangeDetectorRef, Component } from '@angular/core';
import { TimeEntryTemplateTask } from '@shared/models/time-sheet/time-entry/time-entry-template';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { GridApi, IHeaderParams } from 'ag-grid-community';
import { TimeEntryTemplateColumnIdentifier } from '../../../../../myte-popups/shared/services/time-entry-template-column-identifier';
import { TimeEntryTemplateEventsService } from '../../../../../myte-popups/shared/services/time-entry-template-events.service';
import { LocationTemplate } from '../../../../../shared/models/locations';

@Component({
  selector: 'myte-time-entry-template-popup-grid-header',
  templateUrl: './time-entry-template-popup-grid-header.component.html',
  styleUrls: ['./time-entry-template-popup-grid-header.component.sass']
})
export class TimeEntryTemplatePopupGridHeaderComponent implements IHeaderAngularComp {
  public displayName: string;
  public columnCssClass: string;
  public locationsHeader: string = null;
  private columnId: string;
  private gridApi: GridApi<TimeEntryTemplateTask>;

  constructor(
    private timeEntryTemplateEventsService: TimeEntryTemplateEventsService,
    private changeDetector: ChangeDetectorRef) { }

  public agInit(params: IHeaderParams<TimeEntryTemplateTask>): void {
    this.gridApi = params.api;
    this.displayName = params.displayName;
    this.columnId = params.column.getColId();

    if (params.column.getColId() == TimeEntryTemplateColumnIdentifier.Assignment) {
      this.columnCssClass = 'header-cell';
    } else {
      this.columnCssClass = 'header-cell center';
    }

    if (params.column.getColId().includes(TimeEntryTemplateColumnIdentifier.Date)) {
      this.locationsHeader = (params as any).description;
      this.changeDetector.markForCheck();
      this.timeEntryTemplateEventsService.selectLocationsSetEvent.subscribe(res => this.processLocationTemplate(res));
    }
  }

  public refresh(params: IHeaderParams<TimeEntryTemplateTask>): boolean {
    return false;
  }

  private processLocationTemplate(template: LocationTemplate): void {
    let dayIndex = parseInt(this.columnId.replace(TimeEntryTemplateColumnIdentifier.Date.toString(), ''));
    if (!isNaN(dayIndex) && template.locationsDayOfWeek[dayIndex]) {
      this.locationsHeader = template.toString();
      this.changeDetector.markForCheck();
    }
  }
}