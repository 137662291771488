import { Injectable } from "@angular/core";
import { MyteEventHandler } from '../../../../shared/services/events/myte-event-handler';
import { ShowLoadingOverlayEvent,
        ShowUploadReceiptPopupEvent,
        ShowRelatedChargeCodesPopupEvent,
        RefreshGridEvent,
        UploadReceiptStartedEvent,
        UploadReceiptFinishedEvent,
        ExpenseFileReceipt
    } from '../../../../shared/services/events/expense-grid/expense-grid-events';
import { Subject } from 'rxjs';
import { AppConfigService } from '../../../../auth/services/app-config.service';

@Injectable()
export class ExpenseGridEventsService extends MyteEventHandler {
    constructor(appConfigService: AppConfigService) {
        super(appConfigService, [
            new ShowLoadingOverlayEvent(),
            new ShowUploadReceiptPopupEvent(),
            new ShowRelatedChargeCodesPopupEvent(),
            new RefreshGridEvent(),
            new UploadReceiptStartedEvent(),
            new UploadReceiptFinishedEvent(),
            new ExpenseFileReceipt()
        ]);
    }

    public dispatchShowLoadingOverlayEvent(show: boolean) {
        this.dispatchEvent(ShowLoadingOverlayEvent.eventId, show);
    }

    public dispatchShowUploadReceiptPopupEvent(triggeredBy:string="default") {
        this.dispatchEvent(ShowUploadReceiptPopupEvent.eventId, triggeredBy);
    }

    public dispatchShowRelatedChargeCodePopupEvent(chargeCode: string) {
        this.dispatchEvent(ShowRelatedChargeCodesPopupEvent.eventId, chargeCode);
    }

    public dispatchRefreshGridEvent() {
        this.dispatchEvent(RefreshGridEvent.eventId, null);
    }

    public dispatchExpenseFileReceiptUpdated(warningMsgs: string[]) {
        this.dispatchEvent(ExpenseFileReceipt.eventId, warningMsgs);
    }

    public dispatchUploadReceiptStartedEvent() {
        this.dispatchEvent(UploadReceiptStartedEvent.eventId, null);
    }

    public dispatchUploadReceiptFinishedEvent() {
        this.dispatchEvent(UploadReceiptFinishedEvent.eventId, null);
    }

    public selectShowLoadingOverlayEvent(): Subject<boolean> {
        return this.selectEvent(ShowLoadingOverlayEvent.eventId);
    }

    // public selectShowUploadReceiptPopupEvent(): Subject<void> {
    //     return this.selectEvent(ShowUploadReceiptPopupEvent.eventId);
    // }

    public selectShowUploadReceiptPopupEvent(): Subject<string> {
        return this.selectEvent(ShowUploadReceiptPopupEvent.eventId);
    }

    public eventExpenseFileReceiptUpdated(): Subject<string[]> {
        return this.selectEvent(ExpenseFileReceipt.eventId);
    }

    public selectShowRelatedChargeCodesPopupEvent(): Subject<string> {
        return this.selectEvent(ShowRelatedChargeCodesPopupEvent.eventId);
    }

    public selectRefreshGridEvent(): Subject<void> {
        return this.selectEvent(RefreshGridEvent.eventId);
    }

    public selectUploadReceiptStaredEvent(): Subject<boolean> {
        return this.selectEvent(UploadReceiptStartedEvent.eventId);
    }

    public selectUploadReceiptFinishedEvent(): Subject<boolean> {
        return this.selectEvent(UploadReceiptFinishedEvent.eventId);
    }
}