export class SAPBalanceData {
    isUserValid?: boolean;
    earnedLeaveRemainingPTOHours?: string;
    sickLeavePTOHours?: string;
    casualLeavePTOHours?: string;
    combinedSLCLPTOHours?: string;
    periodEndSAPBalance?: Date;
    earnedLeaveRemainingPTODays?: string;
    sickLeavePTODays?: string;
    casualLeavePTODays?: string;
    combinedSLCLPTODays?: string;

    constructor(isUserValid?: boolean, earnedLeaveRemainingPTOHours?: string, sickLeavePTOHours?: string, casualLeavePTOHours?: string, combinedSLCLPTOHours?: string, periodEndSAPBalance?: Date,
        earnedLeaveRemainingPTODays?: string, sickLeavePTODays?: string, casualLeavePTODays?: string, combinedSLCLPTODays?: string,) {
        this.isUserValid = isUserValid;
        this.earnedLeaveRemainingPTOHours = earnedLeaveRemainingPTOHours;
        this.sickLeavePTOHours = sickLeavePTOHours;
        this.casualLeavePTOHours = casualLeavePTOHours;
        this.combinedSLCLPTOHours = combinedSLCLPTOHours;
        this.periodEndSAPBalance = periodEndSAPBalance;
        this.earnedLeaveRemainingPTODays = earnedLeaveRemainingPTODays;
        this.sickLeavePTODays = sickLeavePTODays;
        this.casualLeavePTODays = casualLeavePTODays;
        this.combinedSLCLPTODays = combinedSLCLPTODays;
    }
}