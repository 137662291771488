import { Component, ElementRef, ViewChild, ViewContainerRef } from '@angular/core';
import AmexForAgGrid from '@shared/models/expense/amex/expense-amex-view';
import { IFilterAngularComp } from 'ag-grid-angular';
import { GridApi, IAfterGuiAttachedParams, IDoesFilterPassParams, IFilterParams } from 'ag-grid-community';

@Component({
    selector: 'myte-expense-amex-filter',
    templateUrl: './expense-amex-filter.component.html',
    styleUrls: ['./expense-amex-filter.component.sass']
})
export class ExpenseAmexFilterComponent implements IFilterAngularComp {
    private params: IFilterParams;
    public gridApi: GridApi;
    public text: string = '';
    public ariaLabel: string;
    @ViewChild('inputText', { static: false }) ngbInputTextBox: ElementRef<HTMLElement>;

    constructor() { }

    public agInit(params: IFilterParams): void {
        this.params = params;
        this.gridApi = params?.api;
    }

    public isFilterActive(): boolean {
        return this.text !== null && this.text !== undefined && this.text !== '';
    }

    public doesFilterPass(params: IDoesFilterPassParams<AmexForAgGrid>): boolean {
        switch (this.params.colDef.field) {
            case 'expenseType':
                return this.columnFilter(params.node.data.expensePreSelectedValue);
            default:
                break;
        }
    }

    public columnFilter(column: any): boolean {
        return this.text.toLowerCase().split(" ").every(filterWord => {
            return column.toString().toLowerCase().indexOf(filterWord) >= 0;
        })
    };

    public getModel(): any {
        return { value: this.text };
    }

    public setModel(model: any): void {
        this.text = model ? model.value : '';
    }

    public ngAfterViewInit(params: IAfterGuiAttachedParams): void {
    }

    public onChange(newValue): void {
        if (this.text !== newValue) {
            this.text = newValue;
            this.params.filterChangedCallback();
        }
    }
}