import { MyteEventHandler } from './myte-event-handler';
import { Injectable } from '@angular/core';
import { ChargeCodeDismisedEvent } from './chargecodes/chargecode-events';
import { Subject } from 'rxjs';
import { TimeReportStatusChangedEvent,
    TimeSheetUpdatedEvent,
    TimeReportSubmittedEvent,
    UpdateTimeReportEvent,
    TimeTabEvent,
    TimeReportStatusChangedEventBySubmitAll,
    SaveTimeReportEvent,
    ChangesInTimeReportEvent,
    TimeReportSettingsChangeEvent, 
    UpdateTimeReportSubordinateSelected,
    ActionAtSubordinateTimeReport,
    ShowTimeSpinnerEvent,
    PPAReasonEvent} from './time-report/time-report-events';
import TimeSheet from '../../models/time-sheet/time-sheet';
import { ShowGlobalSpinnerEvent,
        DisableLocationSaveButtonEvent,
        ShowSpinnerForErrorHandler,
        CatchQuestionaireErrorEvent,
        SubmitInQuestionaireErrorEvent,
        GiftComponentChangedEvent,
        AddRelatedChargeCodeEvent,
        ShowGlobalSpinnerAutoSaveEvent,
        ESTravelPunchClockEvent
    } from './global/global-events';
import { SubordinateSelectedEvent, SubordinateSelectedEventPayload, ShowSubordinateDeletePopup, UpdateSubordinateList, PeriodChangedEvent, ShowSubmitAllPopup, SubordinateListNeedCalculateHeight } from './subordinates/subordinates-events'
import TimeReport from '../../models/time-report/time-report';
import Subordinate from '../../models/subordinates/subordinate';
import { AppConfigService } from '../../../auth/services/app-config.service';
import { LocationLBDChangeReasonEvent } from './locations-grid/locations-grid-events'
import { LBDChangeReason } from '../../../shared/models/locations';
import TimeReportSettings from '../../../shared/models/time-report/time-report-settings';
import { PPAReason } from '../../../shared/models/adjustments/ppareason';
import { WorkLocationUpdateEvent } from './work-location/work-localtion-event'
import { Params } from '@angular/router';
import { ActivityErrorEvent } from './activity/activity-events';

@Injectable({
    providedIn: 'root'
})
export class GlobalEventsService extends MyteEventHandler {
    constructor(appConfigService: AppConfigService) {
        super(appConfigService, [
            new ChargeCodeDismisedEvent(),
            new TimeReportStatusChangedEvent(),
            new TimeSheetUpdatedEvent(),
            new TimeReportSubmittedEvent(),
            new ShowGlobalSpinnerEvent(),
            new UpdateTimeReportEvent(),
            new SubordinateSelectedEvent(),
            new ShowSubordinateDeletePopup(),
            new UpdateSubordinateList(),
            new PeriodChangedEvent(),
            new ShowSubmitAllPopup(),
            new TimeReportStatusChangedEventBySubmitAll(),
            new SaveTimeReportEvent(),
            new ChangesInTimeReportEvent(),
            new LocationLBDChangeReasonEvent(),
            new TimeReportSettingsChangeEvent(),
            new UpdateTimeReportSubordinateSelected(),
            new ActionAtSubordinateTimeReport(),
            new DisableLocationSaveButtonEvent(),
            new ShowTimeSpinnerEvent(),
            new PPAReasonEvent(),
            new WorkLocationUpdateEvent(),
            new ShowSpinnerForErrorHandler(),
            new CatchQuestionaireErrorEvent(),
            new SubmitInQuestionaireErrorEvent(),
            new SubordinateListNeedCalculateHeight(),
            new TimeTabEvent(),
            new GiftComponentChangedEvent(),
            new AddRelatedChargeCodeEvent(),
            new ActivityErrorEvent(),
            new ShowGlobalSpinnerAutoSaveEvent(),
            new ESTravelPunchClockEvent()
        ]);
    }

    public dispatchChargeCodeDismisedEvent(res: boolean): void {
        this.dispatchEvent(ChargeCodeDismisedEvent.eventId, res);
    }

    public dispatchTimeReportStatusChangedEvent(newStatus: string): void {
        this.dispatchEvent(TimeReportStatusChangedEvent.eventId, newStatus);
    }

    public dispatchTimeSheetUpdatedEvent(ts: TimeSheet): void {
        this.dispatchEvent(TimeSheetUpdatedEvent.eventId, ts);
    }

    public dispatchSubmitTimeEntryReportEvent(isSubmit: boolean = false): void {
        this.dispatchEvent(TimeReportSubmittedEvent.eventId, isSubmit);
    }

    public dispatchShowGlobalSpinnerEvent(show: boolean): void {
        this.dispatchEvent(ShowGlobalSpinnerEvent.eventId, show);
    }

    public dispatchShowGlobalSpinnerAutoSaveEvent(show: boolean): void {
        this.dispatchEvent(ShowGlobalSpinnerAutoSaveEvent.eventId, show);
    }

    public selectShowGlobalSpinnerAutoSaveEvent(): Subject<boolean> {
        return this.selectEvent(ShowGlobalSpinnerAutoSaveEvent.eventId);
    }

    public dispatchUpdateSettingsChangeEvent(hasChanges: boolean): void {
        this.dispatchEvent(TimeReportSettingsChangeEvent.eventId, hasChanges);
    }

    public dispatchActivityErrorEvent(hasChanges: boolean): void {
        this.dispatchEvent(ActivityErrorEvent.eventId, hasChanges);
    }
    public selectActivityErrorEvent(): Subject<boolean> {
        return this.selectEvent(ActivityErrorEvent.eventId);
    }
    
    public dispatchSubordinateListNeedCalculateHeightEvent(hasChanges: boolean): void {
        this.dispatchEvent(SubordinateListNeedCalculateHeight.eventId, hasChanges);
    }

    public selectUpdateSettingsChangeEvent(): Subject<boolean> {
        return this.selectEvent(TimeReportSettingsChangeEvent.eventId);
    }

    public selectSubordinateListNeedCalculateHeightEvent(): Subject<boolean> {
        return this.selectEvent(SubordinateListNeedCalculateHeight.eventId);
    }

    public selectChargeCodeDismisedEvent(): Subject<boolean> {
        return this.selectEvent(ChargeCodeDismisedEvent.eventId);
    }

    public selectTimeReportStatusChangedEvent(): Subject<string> {
        return this.selectEvent(TimeReportStatusChangedEvent.eventId);
    }

    public selectTimeSheetUpdatedEvent(): Subject<TimeSheet> {
        return this.selectEvent(TimeSheetUpdatedEvent.eventId);
    }

    public selectSubmitTimeEntryReportEvent(): Subject<boolean> {
        return this.selectEvent(TimeReportSubmittedEvent.eventId);
    }

    public selectShowGlobalSpinnerEvent(): Subject<boolean> {
        return this.selectEvent(ShowGlobalSpinnerEvent.eventId);
    }

    public updateTimeReportEvent(): Subject<TimeReport> {
        return this.selectEvent(UpdateTimeReportEvent.eventId);
    }

    public dispatchUpdateTimeReportEvent(timeReport: TimeReport): void {
        this.dispatchEvent(UpdateTimeReportEvent.eventId, timeReport);
    }

    public changesInTimeReportEvent(): Subject<TimeReport> {
        return this.selectEvent(ChangesInTimeReportEvent.eventId);
    }

    public dispatchUpdateTimeReportSubordinateSelected(timeReportStatus: string, enterpriseId: string): void {
        this.dispatchEvent(UpdateTimeReportSubordinateSelected.eventId, { timeReportStatus, enterpriseId });
    }

    public selectUpdateTimeReportSubordinateSelected(): Subject<{ timeReportStatus: string, enterpriseId: string }> {
        return this.selectEvent(UpdateTimeReportSubordinateSelected.eventId);
    }

    public dispatchActionAtSubordinateTimeReport(subordinatePeopleKey: number, action: string): void {
        this.dispatchEvent(ActionAtSubordinateTimeReport.eventId, { subordinatePeopleKey, action });
    }

    public selectActionAtSubordinateTimeReport(): Subject<{ subordinatePeopleKey: number, action: string }> {
        return this.selectEvent(ActionAtSubordinateTimeReport.eventId);
    }

    public dispatchChangesInTimeReportEvent(hasChanges: boolean): void {
        this.dispatchEvent(ChangesInTimeReportEvent.eventId, hasChanges);
    }

    public saveTimeReportEvent(): Subject<boolean> {
        return this.selectEvent(SaveTimeReportEvent.eventId);
    }

    public dispatchSaveTimeReportEvent(isInProgress: boolean): void {
        this.dispatchEvent(SaveTimeReportEvent.eventId, isInProgress);
    }

    public dispatchSubordinateSelectedEvent(payload: SubordinateSelectedEventPayload): void {
        this.dispatchEvent(SubordinateSelectedEvent.eventId, payload);
    }
    public selectTimeTabEvent(): Subject<boolean> {
        return this.selectEvent(TimeTabEvent.eventId);
    }

    public dispatchTimeTabEvent(isTimeTab: boolean): void {
        this.dispatchEvent(TimeTabEvent.eventId, isTimeTab);
    }
    public selectSubordinateSelectedEvent(): Subject<SubordinateSelectedEventPayload> {
        return this.selectEvent(SubordinateSelectedEvent.eventId);
    }

    public dispatchShowSubordinateDeletePopup(payload: Subordinate[]): void {
        this.dispatchEvent(ShowSubordinateDeletePopup.eventId, payload);
    }

    public selectShowSubordinateDeletePopup(): Subject<Subordinate[]> {
        return this.selectEvent(ShowSubordinateDeletePopup.eventId);
    }

    public dispatchUpdateSubordinateList(payload: Subordinate[]): void {
        this.dispatchEvent(UpdateSubordinateList.eventId, payload);
    }

    public selectUpdateSubordinateList(): Subject<Subordinate[]> {
        return this.selectEvent(UpdateSubordinateList.eventId);
    }

    public dispatchPeriodChangedEvent(payload: Date): void {
        this.dispatchEvent(PeriodChangedEvent.eventId, payload);
    }

    public selectPeriodChangedEvent(): Subject<Date> {
        return this.selectEvent(PeriodChangedEvent.eventId);
    }

    public dispatchSubmitAllEvent(payload: { subordinates: Subordinate[], isReview: boolean, shouldRefreshSubordinateList: boolean }): void {
        this.dispatchEvent(ShowSubmitAllPopup.eventId, payload);
    }

    public selectSubmitAllEvent(): Subject<{ subordinates: Subordinate[], isReview: boolean, shouldRefreshSubordinateList: boolean }> {
        return this.selectEvent(ShowSubmitAllPopup.eventId);
    }

    public dispatchTimeReportStatusChangedEventBySubmitAll(timeReport: TimeReport): void {
        this.dispatchEvent(TimeReportStatusChangedEventBySubmitAll.eventId, timeReport);
    }

    public selectTimeReportStatusChangedEventBySubmitAll(): Subject<TimeReport> {
        return this.selectEvent(TimeReportStatusChangedEventBySubmitAll.eventId);
    }
    public dispatchLBDchangeReasonEvent(lBDChangeReason: LBDChangeReason): void {
        this.dispatchEvent(LocationLBDChangeReasonEvent.eventId, lBDChangeReason)
    }
    public selectLBDchangeReasonEvent(): Subject<LBDChangeReason> {
        return this.selectEvent(LocationLBDChangeReasonEvent.eventId);
    }

    public dispatchDisableLocationSaveButtonEvent(show: boolean): void {
        this.dispatchEvent(DisableLocationSaveButtonEvent.eventId, show);
    }

    public selectDisableLocationSaveButtonEvent(): Subject<boolean> {
        return this.selectEvent(DisableLocationSaveButtonEvent.eventId);
    }

    public dispatchShowTimeSpinnerEvent(show: boolean): void {
        this.dispatchEvent(ShowTimeSpinnerEvent.eventId, show);
    }

    public selectShowTimeSpinnerEvent(): Subject<boolean> {
        return this.selectEvent(ShowTimeSpinnerEvent.eventId);
    }
    
    public dispatchPPAReasonEvent(ppaReason: PPAReason): void {
        this.dispatchEvent(PPAReasonEvent.eventId, ppaReason);
    }

    public selectPPAReasonEvent(): Subject<PPAReason> {
        return this.selectEvent(PPAReasonEvent.eventId);
    }

    public dispatchWorkLocationUpdateEvent(updated: boolean): void{
        this.dispatchEvent(WorkLocationUpdateEvent.eventId, updated);
    }

    public selectWorkLocationUpdateEvent() : Subject<boolean> {
        return this.selectEvent(WorkLocationUpdateEvent.eventId)
    }

    public dispatchUpateshowSpinnerForErrorHandler(showSpinner: boolean): void{
        this.dispatchEvent(ShowSpinnerForErrorHandler.eventId, showSpinner)
    }

    public selectUpateshowSpinnerForErrorHandler(): Subject<boolean>{
        return this.selectEvent(ShowSpinnerForErrorHandler.eventId)
    }

    public dispatchCatchQuestionaireErrorEvent(hasError: boolean = false): void {
        this.dispatchEvent(CatchQuestionaireErrorEvent.eventId, hasError);
    }
    public selectCatchQuestionaireErrorEvent(): Subject<boolean> {
        return this.selectEvent(CatchQuestionaireErrorEvent.eventId);
    }

    public dispatchSubmitInQuestionaireErrorEvent(isSubmit: boolean = false): void {
        this.dispatchEvent(SubmitInQuestionaireErrorEvent.eventId, isSubmit);
    }

    public selectSubmitInQuestionaireErrorEvent(): Subject<boolean> {
        return this.selectEvent(SubmitInQuestionaireErrorEvent.eventId);
    }
    public updateGiftComponentChangedEvent(): Subject<boolean> {
        return this.selectEvent(GiftComponentChangedEvent.eventId);
    }

    public dispatchGiftComponentChangedEvent(result: string): void {
        this.dispatchEvent(GiftComponentChangedEvent.eventId, result);
    }
    
    public dispatchAddRelatedChargeCodeEvent(value: Params): void {
        this.dispatchEvent(AddRelatedChargeCodeEvent.eventId, value);
    }

    public selectAddRelatedChargeCodeEvent(): Subject<Params> {
        return this.selectEvent(AddRelatedChargeCodeEvent.eventId);
    }

    public dispatchESTravelPunchClockEvent<T>(): void {
        this.dispatchEvent(ESTravelPunchClockEvent.eventId, true)
    }

    public selectESTravelPunchClockEvent(): Subject<Params> {
        return this.selectEvent(ESTravelPunchClockEvent.eventId)
    }
}