import { destroyPlatform } from "@angular/core";

export class LocationInformation {

  public countryCode: string = '';
  public locationOneCode: string = '';
  public locationTwoCode: string = '';
  public locationReasonCode: number = null;
  public locationReasonNm: string = '';
  public description: string = '';  
  public countryLocationDescription: string = '';
                                                                                                                                                                                                       
  constructor(countryCode?: string, locationOneCode?: string, locationTwoCode?: string, description?: string, locationReasonCode?: number, locationReasonNm?: string, countryLocationDescription?: string) {
    this.countryCode = countryCode;
    this.countryLocationDescription = countryLocationDescription;
    this.locationOneCode = locationOneCode;
    this.locationTwoCode = locationTwoCode;
    this.locationReasonCode = locationReasonCode;
    this.locationReasonNm = locationReasonNm;
    this.description = countryCode == 'IN' && locationTwoCode != null ? locationTwoCode + ' - ' + description : description;
  }

  equals(locationInformation: LocationInformation): boolean {
    return (this.countryCode === locationInformation.countryCode &&
      this.locationOneCode === locationInformation.locationOneCode &&
      this.locationTwoCode === locationInformation.locationTwoCode &&
      this.locationReasonCode === locationInformation.locationReasonCode);
  }
}
