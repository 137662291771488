export const COUNTRIES_WITH_APPROVERS_FROM_CAREER_LEVEL_8 = [
  "CJ","MY"
];

export const SUSTAINABILITY_DROPDOWN_NAMES: string[] = [
  "T2_From",
  "T2_To",
  "T2_From1",
  "T2_From2",
  "T2_From3",
];
export const PDL_VALIDATION_COUNTRIES: string[] = ["CN"];
export const TRAVEL_HOURS_EXCESS_OF_WORKSCHEDULE_KEY = "C14";
export const ACTIVITY_LIST_KEY: string[] = ["C2", "C10", "C13"];

/** actionFromHistory: string from backend, used to identify the action and set the tooltip and icon */
export const ActionWithMaticon = {
  AddComments: {
    icon: null,
    text: null,
    toolTip: "Add Comments",
  },
  ApproveTR: {
    icon: "check_circle",
    text: null,
    toolTip: "Approve Time Report",
    actionFromHistory:
      "Time Report Approved (Reviewer has approved employee’s time report in myTE)",
  },
  RequestUpdatesTR: {
    icon: null,
    text: "U",
    toolTip: "Request Updates to Time Report",
    actionFromHistory:
      "Time Report Updates Requested (Reviewer has requested updates to employee’s time report in myTE; pending employee updates)",
  },
  ViewedTR: {
    icon: null,
    text: "V",
    toolTip: "Time Report Viewed",
    actionFromHistory: "Time Report Viewed",
  },
};
export const auditeeSideBar = {
  auditedUser: "Audited User",
  compliancePeriod: "Compliance Period",
  trPeriod: "TR Period",
  employeePersonnelNum: "Employee Personnel #",
  countryRegion: "Country/Region",
  companyCode: "Company Code",
  costCenter: "Cost Center",
  managementLevel: "Management Level",
  currentEmploymentStatus: "Current Employment Status",
  usFederal: "US Federal",
  terminationDate: "Termination Date",
};

export const pilot_flag = "pilot_flag";
export const commentsTab = {
  generalAuditCommunicationHeader: "General Audit Communication",
  commentTooltip: 'To Enter a Comment, fill out the textbox and click "Add"',
  addButtonText: 'Add'
};

export const myAuditsGrid = {
  vip: 'VIP',
  auditID: 'Audit ID',
  status: 'Status',
  compliancePeriod: 'Compliance Period',
  trPeriod: 'TR Period',
  personnelNumber: 'Personnel Number',
  enterpriseId: 'Enterprise ID',
  country: 'Country/Region',
  companyCode: 'Co. Code',
  source: 'Source',
  envelopeBarcode: 'Bar code',
  box: 'Box',
  ees: 'EES',
}

export const escalatedExpensesGrid = {
  ApprovalStatus: 'Expense Status',
  EscalatedDate: 'Escalated Date',
  auditID: 'Audit ID',
  compliancePeriod: 'Compliance Period',
  trPeriod: 'TR Period',
  enterpriseId: 'Enterprise ID',
  personnelNumber: 'Personnel Number',
  country: 'Country/Region',
  ExpenseType: 'Expense Type',
  ExpenseAmount: 'Expense Amount',
  AssignedTo: 'Assigned To',
  EscalatedBy: 'Escalated By',
  AuditLead: 'AuditLead',
  companyCode: 'Co. Code',
  EscalatedReason: 'Escalated Reason',
  source: 'Source',

}

export const authorizeTab = {
  infoTooltip: 'To manage the authorization list for an assignment, please select the assignment from the list above.',
  select_btn: 'Select',
  search_btn: 'Search',
  summary: 'Summary',
  authorization: 'Authorization',
  assignmentSelection: 'Assignment Selection',
  mmeChargeCodeLookup: 'MME Charge Code Lookup',
  mmeTooltip: 'You can search for an assignment to maintain an authorization list only if you have the Delivery and Financial Mgmt/SDO PMO role in Manage myEngagements.',
  yes_btn: 'Yes',
  cancel_btn: 'Cancel',
  add_btn: 'Add',
  delete_btn: 'Delete',
  delete_all_btn: 'Delete All',
  add_multiple_btn: 'Add Multiple',
  placeholder_text: 'Add Authorized Employee',
  delete_popup: 'Are you sure you want to delete all employees from this charge code?',
  add_multiple_popup: 'Enterprise IDs (eg. John.A.Smith; Bill.A.Wilson; Mike.A.Smith)',
  mark_admin: 'Mark All as Admin',
  selectedAssignment: 'Selected Assignment',
  authorizedEmployee: 'Authorized Employee',
  confirm_btn: 'Confirm'
}

export const authorizationSummaryDropdown = {
  all: 'All',
  business: 'Business Development',
  training: 'Training/Recruiting/Absence',
  chargeable: 'Chargeable',
  other: 'Other'
}

export const AuthorizationSidebar = {
  TYPE: 'Type',
  SUBTYPE: 'Subtype',
  CLIENT: 'Client',
  CONTRACT: 'Contract',
  OPPID: 'Opportunity ID',
  DESC: 'Description',
  CHARGECODE: 'Charge Code',
  AUTHLIST: 'Authorization List',
  NUMBERAUTHORIZED: 'Number Authorized',
  OWNER: 'Owner',
  SELECTASSIGNMENT: 'Selected Assignment',
  AUTHLISTINFOTOOLTIP: 'Please remember to maintain your Authorization Lists to ensure employees can properly charge their time.',
  LASTMODIFIEDBY: 'Last Modified by:',
  LASTMODIFIEDDATETIME: 'Last Modified Date/Time:',
  ON_TOOLTIP: 'On',
  OFF_TOOLTIP: 'Off'
}

export const ChargeCodeType = [
  'business',
  'training'
]

export const AuditCommunicationLog = {
  ADD: 'Add',
  CLOSE: 'Close',
  AUDITLOG: 'Audit Log'
}

export const msgConstant = {
  SUMMARY_LIST_ERR: 'periodend or enterprise Id cannot be null',
  DELETE_ERR: 'Unexpected error occured',
  ADD_AUTH_ERR: 'Please specify a valid Enterprise ID.',
  ADD_MULTIPLE_ERR: 'Please enter valid Enterprise IDs when adding employees to the authorization list.',
  AUTH_LIST_ERR: 'Relative project number details are not available',
  AUTH_TOGGLE_ERR: 'Project details are not available to update the Authorization Activation',
  MME_LOOKUP_ERR: 'You need higher level security access in MME to add this Charge Code.  Please check your access level for this Charge Code in MME.'
}
export const TimeReport_Status = {
  PROCESSED: 'Processed',
  PROCESSEDADJUSTMENT: 'ProcessedAdjustment'
}
export const MassPPAHeaders={
  PAGE_TITLE:'Mass Time Adjustments',
  STATIC_TEXT:'This screen is for Mass Time Adjustments by authorized users in accordance with',
  DATES: 'Dates',
  IMPACTED_EMPLOYEES: 'Impacted Employees',
  LINK:"  Policy 29 -Time and Expense Corrections",
  FROM:"From",
  TO:"To",
  MANDATORY:"*"
}
export const MassPPAReasonDropdown = {
  TRANSFER_BTWN_SAME_CONTRACT: 'Transfer between same contract',
  TRANSFER_BTWN_DIFF_CONTRACT: 'Transfer between different contract',
  CHARGED_DIFF_WBS: 'Charged different WBS and need correction',
  WBS_OVER_BUDGET: 'WBS is over budget',
  CROSS_FISCAL_YR_ADJ: 'Cross fiscal year adjustment',
  OTHER: 'Other reason - Please add details in "Comments" field'
}
export const MassPPAButtons = {
  SEARCH: 'Search',
  BROWSE: 'Browse',
  EXPORT_REQUEST: 'Export Request',
  SUBMIT: 'Submit'
}
export const MassPPAChargeCodeSection={
  CLIENT:'Client',
  CONTRACT:'Contract',
  DESCRIPTION:'Description',
  BUSINESS_ACTIVITY_LVL:'Business Activity Level',
  INDUSTRY:'Industry',
  STATUTORY_CO_CODE:'Statutory Co Code',
  STATUTORY_ENTITY:'Statutory Entity',
  FRM_CHARGE_CODE:'From Charge Code',
  TO_CHARGE_CODE:'To Charge Code',
  ENTER_CHARGE_CODE:'Enter Charge Code'
}
export const MassPPATooltips = {
  FRM_CODE: 'Enter the WBS or Cost Collector from which hours need to be removed',
  TO_CODE: 'Enter the WBS or Cost Collector where you need hours to be moved. Ability to move hours to a Charge Code depends on your MME edit rights',
  availableHours: "Hours in any Time Reports the employee is currently adjusting are not available for Mass Time Adjustment"
}

export const MassPPAConstants = {
  COST_IMPACT_TOOLTIP: 'Enter estimated total cost impact of this adjustment in $US',
  ATTACH_APPROVALS_FILE_SIZE_ERR: 'Please ensure your attachment does not exceed the 10 MB limit',
  NO_FILE_CHOSEN: 'No file chosen',
  ATTACH_APPROVALS: 'Attach Approvals',
  REASON: 'Reason',
  COST_IMPACT: 'Cost Impact (USD)',
  COMMENTS: 'Comments (400 char limit)',
}

export const MassPpaEmployeeStatus = ["Active","LOA"];

export const MassPpaEmployeeActiveStatus = "Active";

export const MassPpaInvalidDate = "Invalid Date";


export const MassPpaTags = {
  EmployeesSelected: "Employees Selected",
  HoursSelected: "Hours Selected",
  DaysSelected:"Days Selected"
}

export const MassPPAErrorMessages = {
  MRDR_Error: "Due to a technical issue the Charge Code details cannot be displayed. We apologise for the inconvenience. Please try again later or consider alternative adjustment methods."
}
export const MassPPAGrid = {
  selected: 'Selected',
  employee: 'Employee',
  personnelNo: 'Personnel No.',
  totalHours: 'Total Hours',
  availableHours: 'Available Hours',
  timeReportStatus: 'Time Report Status',
  coCode: 'Co.Code',
  country: 'Country/Region',
  status: 'Status'
}

export const MassPPAGridValues = {
  Checkbox_checked: 'Yes',
  Checkbox_unchecked: 'No',
  massPPAExportSheetName: 'download'
}

export const MassPpaMMEerroMessage = "You need higher level security access in MME to move hours to this Charge Code. Please check your access level for this Charge Code in MME. See <a href='https://policies.accenture.com/Pages/0001-0100/0029.aspx'> Policy 29 - Time and Expense Corrections </a> for more detail.";
export const MassPpaToChargeCDErrorMessage = "Adjustments <b>To</b> Charge Codes with this Business Activity Level are not supported. You need higher level security access in MME to move hours to this Charge Code. Please check your access level for this Charge Code in MME. See <a href='https://policies.accenture.com/Pages/0001-0100/0029.aspx'> Policy 29 - Time and Expense Corrections </a> for more detail.";

export const MsgApproverInactive = 'The selected approver is not an active employee. Please select another approver.';
export const Approver = 'Approver';
export const MsgBlankValueValidationForTHAllowance = 'Identify a charge code, description and approver for on-call allowance.';
export const MsgBlankValueValidationForTHWorkShift = 'You must select one shift with the approver for every day with hours charged.';
export const MsgBlankValueValidationForMYWorkShift = 'You must select one shift with the approver for every day with hours charged.';
export const WorkShiftCode = "R88";
export const WorkShiftCodeForMY = "R119";
export const ShiftAllowanceCode = "R81";
export const TimeCategoryLogErrorMsg = `Unable to validate Active approver. If the problem persists please escalate the issue at myTimeandExpenses Help.`;
export const OvertimeCode = "O1";

export const UniversalHeader = {
  Selectors: {
    HEADER: '.acn-header',
    ACTION_BUTTONS: 'div.acn-header-actions',
    APPS_GRID: 'div.acn-header-action-dropdown.acn-header-grid-dd',
    SETTINGS: 'div.acn-header-action-dropdown.acn-header-settings-dd'
  },
  ActionButtons: {
    POSTPAYAUDIT: 'postpayaudit',
    AUTHORIZE: 'authorize',
    PERDIEMS: 'PerDiems',
  },
  GridButtons: {
    REQUESTOVERTIME: 'requestovertime',
    APPROVEOVERTIMEANDALLOWANCE: 'approveovertimeandallowance',
    APPROVALALLOWANCEANDOVERTIME: 'approvalallowanceandovertime',
    APPROVALOVERTIMEANDALLOWANCE: 'approvalovertimeandallowance',
    TIMECATEGORYREQUESTAPPROVAL: 'timecategoryrequestapproval'
  }
}
export const ReasonNotSelected = 'Please select a reason before you submit'

export enum MassPPASubmitValidations {
  WARNING_FOR_MASS_PPA_VALIDATION_FOR_DAYS_AND_TRS = 'ValidateDaysAndTRs',
  WARNING_FOR_MASS_PPA_VALIDATION_EXCEEDS_1000_HOURS = 'AdjustmentCrossesFiscalYear',
  WARNING_FOR_MASS_PPA_VALIDATION_EXCEEDS_250_HOURS = 'AdjustmentMovesTimeBetweenEntities',
  WARNING_FOR_MASS_PPA_VALIDATION_EXCEEDS_2500_HOURS = 'AdjustmentExceeds2500Hours',
  PolicyMessage='AdjustmentFailedValidationsPolicy29'
}

export const ApproverFieldsToValidateForTH = ["authorizedExceptionOnly","utilities"]
export const T2TypeKey = "T2_Type"
export const BlockErrorKey = "BlockError";

export const ReportButtons = {
  ADD: 'Add',
  CANCEL: 'Cancel'
}

export const RegExPatternForDateValidation = '([0-9]{1,2}(\/|-)[0-9]{1,2}(\/|-)[0-9]{2,4})|([0-9]{2,4}(\/|-)[0-9]{1,2}(\/|-)[0-9]{1,2})';
export const RegExPatternForDateInput = '[0-9]{1}|(\/|-)';
