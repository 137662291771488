<!-- Button previous period -->
<button type="button"
    class="icon-container accessibility-div" id="Previous_Period_Button" aria-label="Navigate to Previous Period"
    [ngClass]="{ disabled: isLoading }"
    [attr.tabindex]="isLoading ? '-1' : '0'"
    [attr.aria-disabled]="isLoading"
    [disabled]="isLoading"
    [focus]=focusPreviousPeriod
    (click)="!isLoading ? previousPeriod() : false">
    <mat-icon aria-hidden="true" fontIcon="keyboard_arrow_left"></mat-icon>
</button>
<div class="input-group periodDropdown">
    <!-- Dropdown to display periods -->
    <myte-dropdown
        dropdownId="select-period-dropdown"
        [elementsList]="dropdownDates"
        [selectedItem]="currentDate?.toDropdownItem()"
        [focusDropdown]="focusDropdown"
        [height]="27"
        [hasdatevalue]="true"
        (updateValueEvent)="onDateSelectedFromDropdown($event)"
        label="{{ 'Selected Period ' + currentDate?.toDropdownItem().value }}"
        [ariaLabel]="arialabel"
    ></myte-dropdown>
    <!-- Datepicker -->
    <div class="input-group">
        <input class="form-control"
                style="display: none"
                ngbDatepicker
                #d="ngbDatepicker"
                type="hidden"
                placeholder="yyyy-mm-dd"
                name="dp"
                aria-label="Hidden Calendar Date"
                [(ngModel)]='DateDropDown'
                [footerTemplate]="footerTemplate"
                [positionTarget]="buttonEl"
                [firstDayOfWeek]="7"
                (dateSelect)="onDateSelectFromCalendar($event)"
                [dayTemplate]="customDay" />
        <!-- Button with calendar icon to show datepicker -->
        <button #buttonEl
                class="btn btn-sm btn-submit calendar"
                (click)="d.toggle()"
                type="button"
                aria-label="Change Time-Period Date Calendar"
                [focus]="focusCalendar">
                    <mat-icon class="material-icons" aria-hidden="true" fontIcon="today"></mat-icon>
        </button>
    </div>
</div>
<!-- Footer -->
<ng-template #footerTemplate>
    <hr class="my-0" />
    <button class="accessibility-div m-1 float-start footer-style" (click)="onClickToday()">{{stringToday}}</button>
</ng-template>
<!-- Days -->
<ng-template #customDay let-date let-currentMonth="currentMonth" let-selected="selected" let-disabled="disabled"
    let-focused="focused">
    <div class="custom-day-datePicker"
        [class.focused]="focused"
        [class.custom-day-datePicker-disabled]="isDisabled(date, currentMonth)"
        [class.custom-day-datePicker-selected]="selected"
        ngbTooltip="{{date | dateformat:'GetDateForTooltip'}}">
        {{date.day}}</div>
</ng-template>
<!-- Button next period -->
<button type="button"
    class="icon-container accessibility-div" id="Next_Period_Button" aria-label="Navigate to Next Period"
    [ngClass]="{ disabled: isLoading }"
    [attr.tabindex]="isLoading ? '-1' : '0'"
    [attr.aria-disabled]="isLoading"
    [disabled]="isLoading"
    [focus]=focusNextPeriod
    (click)="!isLoading ? nextPeriod() : false">
    <mat-icon aria-hidden="true" fontIcon="keyboard_arrow_right"></mat-icon>
</button>