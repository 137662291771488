export class ExpenseViewFieldValidationError {
    type: string;
    errorMessage?: string;
    label?:string;

    constructor(type: string, errorMessage: string = null, label:string = null) {
      this.type = type;
      this.errorMessage = errorMessage;
      this.label = label;
    }
  }
