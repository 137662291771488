<div>
    <div class="form-group">
        <div class="input-group">
            <myte-dropdown class="ag-filter-select"
                #filterOptions
                id="dateFilter-dropdown"
                [elementsList]="dropdownOptions"
                [value]="selectedOption"
                [height]="16"
                (updateValueEvent)="onDateSelectedFromDropdown($event)"
                [isFilter]="true">
            </myte-dropdown>

            <!-- <button (click)="clearFilter()" class="btn btn-sm btn-submit btn-resetFilter" type="button">
                <i class="material-icons" style="transform: scale(1); margin-top: -2px;">delete_sweep</i>
            </button> -->

        </div>

        <myte-date-picker
            #startDate
            [value]="date"
            [isFilter]="true"
            (onDateChange)="onChange($event)">
        </myte-date-picker>
    </div>
</div>