<div class="form-inline">
  <div *ngIf="showCurrency && currency" class="input-group-text input-group-prepend divCurrency">
    <span>{{ currency }}</span>
  </div>
  <div #select class="myte-custom-select" attr.id="{{ 'combobox' + dropdownId}}" [ngClass]="{'with-currency': showCurrency && showCurrency != '', isAdjustment : isAdjustment, invalid: isValid == null ? false: !isValid, 'multipleselect': isMultipleSelect}" [ngStyle]="{'height': height + 'px', 'width': width ? width + 'px' : ''}" (clickOutside)="hideOptions($event)"
    tabindex="{{readOnly && !isLoading ? '-1': '0'}}" (focus)="onFocusSelect()"  [attr.aria-required]="required" (blur)="onBlurSelect()" (keydown)="onKeySelect($event)" [focus]=focusDropdown role="combobox" 
    [attr.aria-controls]="'listBox' + dropdownId" aria-haspopup="listbox" [attr.aria-label]="label ? label:'select Expenses to Add'" [attr.aria-expanded]="isDropdownOptionsOpen" [attr.aria-disabled]="readOnly && !isLoading ? true: false">
    <div class="select-selected" ngbTooltip= "{{this.hasdatevalue && !isMultipleSelect ?'Date: ' + selectedItem?.value :null}}" placement="bottom" [ngClass]="{
        'select-arrow-active': isDropdownOptionsOpen,
        disabled: readOnly || isLoading,
        isSelectedOptionHighlight: isSelectedOptionHighlight, 
        isSelectedOptionHighlightText: isSelectedOptionHighlight
      }"
      (click)="!isDropdownOptionsOpen
      ? !readOnly && !isLoading
       ? showOptions() : ''
       : hideOptions()">
      <div #search class="search">
        <mat-icon fontIcon="search"></mat-icon>
        <span [attr.id]="'resultsFeedback' + dropdownId" aria-live="polite" class="screenReaderReadable">{{filteredListCount === undefined ? '' : filteredListCount + ' results found'}}</span>
        <input  type="type" 
                class="form-control" 
                [ngStyle]="{'max-height': height + 'px' }" 
                (ngModelChange)="update($event)" 
                [(ngModel)]="searchValue" 
                role="searchbox" 
                aria-multiline="false"
                placeholder="Search" 
                (click)="$event.stopPropagation();" 
                (blur)="onBlurSearch()" 
                tabIndex="-1" 
                aria-label="searchValue" 
                aria-autocomplete="both"
                id="{{idToLinkLabelAndInput}}" 
                [attr.aria-labelledby]="'resultsFeedback' + dropdownId">
      </div>
      <div #item class="item active" [ngStyle]="{'height': height + 'px', 'line-height': height + 'px' }" [attr.aria-label]="ariaLabel == undefined && !readOnly ? ' Press enter to expand options' : ariaLabel">
        {{ isLoading ? 'Loading...' : (showWbs?(selectedItem.value?selectedItem.value:selectedItem.key):(isExpenseEX08_EX09 && selectedItem.key != "") ? selectedItem.key:selectedItem.value) }}
      </div>
      <span class="select-arrow" *ngIf="!isLoading" [ngStyle]="{'top': calculateSelectArrow()}"></span>
      <div class="spinner-border spinner-border-sm select-spinner" role="status" *ngIf="isLoading"></div>

    </div>
    <ul #selectContent role="combobox" class="select-items" *ngIf="!readOnly" 
      [ngStyle]="{'min-height': minHeight + 'px', 'max-height': heightContent + 'px' }"
      [attr.id]="'listBox' + dropdownId"
      [ngClass]="{ 
        'select-hide': !isDropdownOptionsOpen,
        'activity-min-width' : isActivityDropdown
      }"> 
      <li *ngFor="let item of elementsList | search:'key,value':searchValue" 
          [ngClass]="{
            'selected': !isMultipleSelect && item.key == this.selectedItem.key,
            'invalid': !item.isValid, 'filterOptions': isFilter,
            'selectedMultiple': handleSelectedItem(item)
          }" 
          tabindex="0" 
          role="option"
          [ngStyle]="{'line-height': heightItems + 'px', 'min-height': minHeight + 'px'}"
          [attr.aria-label]="item.key == 'LINE' ? 'Empty' : showWbs?(item.value?item.value:item.key):!hasnumbervalue ? (!hasdatevalue? (!expenseText ? item.value : item.value + expenseText): transformToLocalizeDate(item)): parseNumberToLocalString(item.value)"
          [attr.aria-selected]="item.key == selectedItem.key"  
          (click)="selectItem(item); isMultipleSelect ? '': hideOptions($event)" 
          [hidden]="item.isShow == false" 
          [matTooltip]="item.tooltip"
          [matTooltipPosition]="'left'"
          placement="start bottom" 
          container="body">
        {{ showWbs?(item.value?item.value:item.key):!hasnumbervalue ? (!hasdatevalue? item.value: transformToLocalizeDate(item)): parseNumberToLocalString(item.value)}}
      </li>
      <li class="no-results" *ngIf="elementsList?.itemscount == 0 && !isMultipleSelect">
        {{ isExpenseEX08_EX09 ? "No results found" :"No results" }}
      </li>
    </ul>
  </div>
</div>
