import { ReceiptRequirement } from './receipt/expense-receipt-requirement';
import { Option, ExpenseFieldWithValidationResult, AnalyticsAuditReason } from '@sharedClients/expense-api-client';
import { ExpenseAmount } from './expense-amount';
import ExpenseTripDetail from './trip/expense-trip-detail';
import { ExpenseDate } from './expense-date';

export default class Expense {
    public id: string;
    public sequence: ExpenseFieldWithValidationResult;
    public source: ExpenseFieldWithValidationResult;
    public status: ExpenseFieldWithValidationResult;
    public from: ExpenseFieldWithValidationResult;
    public to?: ExpenseFieldWithValidationResult;
    public assignment: Option;
    public expenseTypeCd: string;
    public type: ExpenseFieldWithValidationResult;
    public additionalInformation: ExpenseFieldWithValidationResult;
    public isAdjusted: boolean;
    public receiptRequirement: ReceiptRequirement;
    public expenseAmount: ExpenseAmount;
    public expenseDate: ExpenseDate;
    public frequentTrip: ExpenseTripDetail;
    public canBeEdited: boolean;
    public currency: string;
    public hasFederalWBSInd: boolean;
    public hasExpenseTotalErrorInd: boolean;
    public expenseTotalErrors: string;
    public hasissues: boolean;
    public decimalPlaces: number;
    public expenseProjectChargeHistoryToolTipText: string;
    public expenseChargeHistoryToolTipText: string;
    public expenseCountry: string;
    public isSalarySupplementExpense: boolean;
    public isEscalatedExpense: boolean;
    public taxReimbursableBool: boolean;
    public isReversed: boolean;
    public exchangeRate? :number
    public gSTAmount? :number
    public amountWithoutTax ?:number
    public selfContainedDays ?:number
    public hotelDays?:number
    public foreignAmount ?:number
    public timeReportStatus?: string;
    public parentExpenseTotalError?: string;
    public selfCertification?: boolean;
    public postPayEscalatedInd?: boolean|null;
    public analyticsAudit?: string | null;
    public analyticsAuditReasons?: AnalyticsAuditReason[] | null;
    public isEditableForAuditor?: boolean | null;
}
