<div class="content-wrap">
  <myte-subordinates-bar *ngIf="!isAuditMode && subordinateMode !=='Audit'"></myte-subordinates-bar>
  <myte-auditee-sidebar *ngIf="isAuditMode"></myte-auditee-sidebar>
  <div class="content-outlet">
    <myte-tab-menu [timeReportStatus]="timeReport?.status"></myte-tab-menu>
    <router-outlet (activate)="onRouterOutletActivate($event)"></router-outlet>
    <myte-page-footer
      [ngClass]="{
        'width-narrow': isSubordinateMode,
        'width-wide': !isSubordinateMode
      }"
    ></myte-page-footer>
  </div>
</div>

<myte-time-remove-subordinate-popup
  *ngIf="isShowRemoveModal"
  [subordinateMode]="subordinateMode"
  [subordinatesToRemove]="subordinateToRemove"
  (close)="closeRemovePopup()"
></myte-time-remove-subordinate-popup>
<myte-time-submitAll-popup
  *ngIf="isShowSubmitAllModal"
  [subordinateMode]="subordinateMode"
  [isReview]="isReview"
  [subordinatesToSubmit]="subordinatesToSubmit"
  (close)="closeSubmitAllPopup()"
></myte-time-submitAll-popup>
