import { Injectable } from '@angular/core';
import { WeekDay } from '@angular/common';
import { Observable, of } from 'rxjs';
import * as moment from 'moment';
import { catchError, map } from 'rxjs/operators';
import { ExpenseApiClient, SaveTripInput, TripInputForApply, TripDto } from '../../../../shared/clients/expense-api-client';
import ExpenseTripCreateInput from '../../../../shared/models/expense/trip/expense-trip-create';
import ExpenseTripReviewView from '../../../../shared/models/expense/trip/expense-trip-review-view';
import ExpenseTripName from '../../../../shared/models/expense/trip/expense-trip-name';
import ExpenseTripInfo from '../../../../shared/models/expense/trip/expense-trip-info';
import { LogService } from '../../../../shared/services/log/log.service';
import ExpenseTripReviewGrid from '../../../../shared/models/expense/trip/expense-trip-review-grid';
import {GlobalCacheService} from '../../../../shared/services/cache/global-cache.service';
import DropdownItem from '../../../../shared/models/controls/dropdown/dropdown-item';
import { MyteBaseService } from '../../../../shared/services/myte-base.service';
import { GlobalEventsService } from '../../../../shared/services/events/global-events.service';
import { UserService } from '../../../../shared/services/user/user.service';
import { PeriodEndService } from '../../../../shared/services/periodend/periodend.service';

@Injectable({
  providedIn: 'root'
})
export class ExpenseTripService extends MyteBaseService {

  constructor(private api: ExpenseApiClient,
              globalCacheService: GlobalCacheService,
              globalEventsService: GlobalEventsService,
              userService: UserService,
              logService: LogService,
              private periodEndService: PeriodEndService) {
    super(userService, globalCacheService, globalEventsService, logService);
  }

  public getWeeksList(periodEnd: Date): Array<DropdownItem> {
    let weeksList = new Array<DropdownItem>();
    let firstSundayAvailable = new Date(periodEnd);
    while (firstSundayAvailable.getDay() != WeekDay.Saturday) {
      firstSundayAvailable.setDate(firstSundayAvailable.getDate() - 1);
    }
    firstSundayAvailable.setDate(firstSundayAvailable.getDate() - 6);

    let firstSunday = new DropdownItem();
    firstSunday.value = moment(firstSundayAvailable).format('MM/DD/YYYY');
    firstSunday.key = new Date(firstSundayAvailable);
    firstSunday.isValid = true;
    firstSunday.isShow = true;
    weeksList.push(firstSunday);

    for (let i = 1; i < 10; i++) {
      firstSundayAvailable.setDate(firstSundayAvailable.getDate() - 7);
      let sunday = new DropdownItem();
      sunday.value = moment(firstSundayAvailable).format('MM/DD/YYYY');
      sunday.key = new Date(firstSundayAvailable);
      sunday.isValid = true;
      sunday.isShow = true;
      weeksList.push(sunday);
    }
    return weeksList;
  }

  public deleteTrip(tripId: string): Observable<boolean | null> {
    return this.api.deleteTrip(this.userEid, this.periodEndService.getActivePeriod(), this.supervisorEid, this.viewMode, tripId).pipe(catchError(err => {
      this.logService.logError(err, true);
      return of(null);
  }));
  }

  public applyTrip( periodEnd: Date, gridData: ExpenseTripReviewView, countryKey: string): Observable<any> {
    return this.api.applyTrip(this.supervisorEid, this.viewMode, this.mapDtoToInput(this.userEid, periodEnd, gridData))
    .pipe(map((result) => {
      this.globalCacheService.handleResetExpenses(periodEnd, countryKey);
      return result;
    }))
    .pipe(catchError(err => {
      this.logService.logError(err, true);
      return of(null);
    }));
  }

  public saveTrip(createTripInput: ExpenseTripCreateInput): Observable<any> {
    let saveTripInput = new SaveTripInput();
    saveTripInput.enterpriseId = this.userEid;
    saveTripInput.expenseIds = createTripInput.expenseIds;
    saveTripInput.createTripName = createTripInput.createTripName;
    saveTripInput.createTripReason = createTripInput.createTripReason;
    saveTripInput.currentExpenseCountryCode = createTripInput.currentExpenseCountryCode;
    saveTripInput.isOverWritingTrip = createTripInput.isOverWritingTrip;

    return this.api.saveTrip(this.periodEndService.getActivePeriod(), this.supervisorEid, this.viewMode, saveTripInput).pipe(catchError(err => {
      this.logService.logError(err, true);
      return of(null);
    }));
  }

  public updateTripInfo(tripId: number, newName: string): Observable<boolean | null> {
    return this.api.updateTripName(tripId, newName, this.userEid, this.periodEndService.getActivePeriod(), this.supervisorEid,  this.viewMode).pipe(catchError(err => {
      this.logService.logError(err, true);
      return of(null);
    }));
  }

  public getTripNameList(countryKey: string): Observable<ExpenseTripName[]> {
    let tripBuffer = this.globalCacheService.getTripListBuffer(this.userEid, countryKey);
    if (tripBuffer) {
      return tripBuffer;
    } else {
      let requestTripNameList = this.api.getTripNames(this.userEid, countryKey, this.periodEndService.getActivePeriod(), this.supervisorEid, this.viewMode)
    .pipe(map(tripItem =>
      this.mapExpenseTripNameList(tripItem)
    ))
    .pipe(catchError(err => {
      this.logService.logError(err, true);
      return of(null);
    }));
    return this.globalCacheService.handleTripList(this.userEid, countryKey, requestTripNameList);
    }
  }

  public getTripInfoBytripId(tripId: number, periodEnd: Date): Observable<ExpenseTripInfo> {
    return this.api.getTrip(this.userEid, tripId, periodEnd, this.supervisorEid, this.viewMode).pipe(
      map(tripInfo => this.mapExpenseTripReviewData(tripInfo))
    ).pipe(catchError(err => {
      this.logService.logError(err, true);
      return of(null);
    }));
  }

  private mapExpenseTripNameList(expenseTripReivewData): ExpenseTripName[] {
    let responseData: ExpenseTripName[] = [];
    expenseTripReivewData.map(tripViewItem => {
      let tripNameItem = new ExpenseTripName();
      tripNameItem.tripId = tripViewItem.frequentTripId;
      tripNameItem.tripName = tripViewItem.frequentTripName;
      responseData.push(tripNameItem);
    });
    return responseData;
  }

  private mapExpenseTripReviewData(tripInfo): ExpenseTripInfo {
    return new ExpenseTripInfo(
      tripInfo.frequentTripId,
      this.mapAGridData(tripInfo.tripGridData)
    );
  }

  private mapAGridData(tripGridData): ExpenseTripReviewGrid[] {
    let tripGridList: ExpenseTripReviewGrid[] = [];
    if (tripGridData) {
      tripGridData.map(tripGridItem => {
        tripGridList.push(
          new ExpenseTripReviewGrid(
            tripGridItem.numOfDays,
            tripGridItem.assignment,
            tripGridItem.expenseType,
            tripGridItem.amount,
            tripGridItem.expenseId,
            tripGridItem.fromDate,
            tripGridItem.toDate
          )
        );
      });
    }
    return tripGridList;
  }

  private mapDtoToInput(enterpriseId: string, periodEnd: Date, gridData: ExpenseTripReviewView): TripInputForApply {
    let tripInputForApply = new TripInputForApply();
    tripInputForApply.enterpriseId = enterpriseId;
    tripInputForApply.periodEnd = periodEnd;
    tripInputForApply.tripid = gridData.tripId;
    tripInputForApply.tripDtos = [];
    gridData.tripGridData.forEach(data => {
      const dto = new TripDto();
      dto.expenseId= parseFloat(data.expenseId),
      dto.fromDate= moment(data.fromDate, 'MM/DD/YYYY').toDate(),
      dto.toDate= (data.toDate === null || data.toDate.toString() == '-') ? null : moment(data.toDate, 'MM/DD/YYYY').toDate()

      tripInputForApply.tripDtos.push(dto);
    });

    return tripInputForApply;
  }
}
