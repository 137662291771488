import { MultipleLocationsCharge } from './multiple-locations-charge';
import { LocationInformation } from '../locations-by-day/location-information';
import { ShellModel } from '../../../../app-shell/data-store';

export class MultipleLocation extends ShellModel {
  public periodDate: Date;
  public locations: Array<LocationInformation>;
  public charges: Array<MultipleLocationsCharge>;
  public readOnly: boolean;

  constructor(periodDate?: Date, locations?: Array<LocationInformation>, charges?: Array<MultipleLocationsCharge>, readOnly?: boolean) {
    super();

    this.periodDate = periodDate;
    this.locations = locations ?? new Array(2).fill(new LocationInformation());
    this.charges = charges ?? new Array(2).fill(new MultipleLocationsCharge());
    this.readOnly = readOnly ?? true;
  }
}
