<div *ngIf="showSubordinatesMenu" [ngSwitch]="subordinateMode" class="content-represent" [style.width.px]='280' id="generalContainerElement">

    <div *ngIf="!subordinatesReady" class="spinner">
        <myte-spinner></myte-spinner>
    </div>

    <ng-container>
        <div  id="peoplePickerElement" #peoplePickerElement>
            <myte-people-picker *ngIf="isSearchEnabled" [forSubordinates]="true" [isSimplify]="true"
                (selectedPeopleEvent)="selectSubordinateFromPeoplePicker($event, false, true)">
            </myte-people-picker>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="'Supervisor'" id="containerElement">
        <myte-subordinated-category
            id="upListElement"
            (onReady)="subordinatesReady = $event"
            (onEmitCounselees)="counselees = $event"
            [subordinateMode]="subordinateMode"
            [isCounseleeList]="true"
            [isSearchEnabled]="isSearchEnabled">
        </myte-subordinated-category>

        <myte-subordinated-category 
            *ngIf="counselees && counselees.length > 0"
            id="downListElement"
            (onReady)="subordinatesReady = $event"
            [subordinateMode]="subordinateMode"
            [counselees]="counselees"
            [isReview]="false"
            [isCounseleeList]="false"
            [isSearchEnabled]="isSearchEnabled">
        </myte-subordinated-category>
    </ng-container>

    <ng-container *ngSwitchCase="'Delegate'">
        <myte-subordinated-category
            (onReady)="subordinatesReady = $event"
            [subordinateMode]="subordinateMode"
            [isSearchEnabled]="isSearchEnabled">
        </myte-subordinated-category>
    </ng-container>

    <myte-subordinated-category
        *ngSwitchDefault
        (onReady)="subordinatesReady = $event"
        [subordinateMode]="subordinateMode"
        [isCounseleeList]="false"
        [isSearchEnabled]="isSearchEnabled">
    </myte-subordinated-category>
</div>