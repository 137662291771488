
import Subordinate from '@sharedModels/subordinates/subordinate';
import { TimeReportStatusCode } from '@sharedModels/time-report/time-report-status-code';;

export abstract class ReportServiceController  {

    public static getUsersToPrint(subordinates: Subordinate[]): string[] {
        const allowedStates = [
            TimeReportStatusCode.Submitted,
            TimeReportStatusCode.SubmittedAdjustment,
            TimeReportStatusCode.Processed,
            TimeReportStatusCode.ProcessedAdjustment
        ];
        return subordinates.filter(sub =>
                    allowedStates.includes(sub.statusCode)
                ).map(sub => sub.enterpriseId);
    }

    public static generateURLforPrintAll(TIMEREPORT_URL: any, endPeriod: string, loginEid: string, viewMode: string) {
        return `${TIMEREPORT_URL}/TimeReport/GetTimeReportsPDFForPrintAll?periodEnd=${endPeriod}&loginEnterpriseId=${loginEid}&viewMode=${viewMode}`
    }

    public static generateURLforGetTimeReport(TIMEREPORT_URL: any, userEid: string, endPeriod: string, isSubordinatedMode: boolean, supervisorEid: string, viewMode: string) {
        let url = `${TIMEREPORT_URL}/TimeReport/GetTimeReportPDF?enterpriseId=${userEid}&periodEnd=${endPeriod}`;
        if (isSubordinatedMode) {
            url = url + `&loginEnterpriseId=${supervisorEid}&viewMode=${viewMode}`;
        }
        return url;
    }
}
