import { MyteEvent } from '../myte-event';
import TimeReport from '@sharedModels/time-report/time-report';
import TimeSheet from '@sharedModels/time-sheet/time-sheet';
import { PPAReason } from '@sharedModels/adjustments/ppareason'

export class TimeReportSubmittedEvent extends MyteEvent<void> {
    public static eventId = 'timeReportSubmitted';
    constructor() { super(TimeReportSubmittedEvent.eventId); }
}

export class TimeTabEvent extends MyteEvent<void> {
  public static eventId = 'timeTab';
  constructor() { super(TimeTabEvent.eventId); }
}

export class SaveTimeReportEvent extends MyteEvent<TimeReport> {
  public static eventId = 'saveTimeReportInProgress';
  constructor() { super(SaveTimeReportEvent.eventId); }
}

export class ChangesInTimeReportEvent extends MyteEvent<TimeReport> {
  public static eventId = 'changesInTimeReportEvent';
  constructor() { super(ChangesInTimeReportEvent.eventId); }
}

export class UpdateTimeReportSubordinateSelected extends MyteEvent<[string, string]> {
  public static eventId = 'UpdateTimeReportSubordinateSelected';
  constructor() { super(UpdateTimeReportSubordinateSelected.eventId); }
}

export class ActionAtSubordinateTimeReport extends MyteEvent<[string, string]> {
  public static eventId = 'ActionAtSubordinateTimeReport';
  constructor() { super(ActionAtSubordinateTimeReport.eventId); }
}

export class UpdateTimeReportEvent extends MyteEvent<TimeReport> {
  public static eventId = 'updateTimeReport';
  constructor() { super(UpdateTimeReportEvent.eventId); }
}

export class TimeReportStatusChangedEvent extends MyteEvent<string> {
  public static eventId = "timeReportStatusChanged"
  constructor() { super(TimeReportStatusChangedEvent.eventId); }
}

export class TimeSheetUpdatedEvent extends MyteEvent<TimeSheet> {
  public static eventId = "timeSheetUpdated";
  constructor() { super(TimeSheetUpdatedEvent.eventId); }
}

export class TimeReportStatusChangedEventBySubmitAll extends MyteEvent<string> {
  public static eventId = "timeReportStatusChangedBySubmitAll"
  constructor() { super(TimeReportStatusChangedEventBySubmitAll.eventId); }
}

export class TimeReportSettingsChangeEvent extends MyteEvent<boolean> {
  public static eventId = 'timeReportSettingsChange';
  constructor() { super(TimeReportSettingsChangeEvent.eventId); }
}

export class CellClickedRelatedEvent extends MyteEvent<boolean> {
  public static eventId = 'cellClickedRelated';
  constructor() { super(CellClickedRelatedEvent.eventId); }
}

export class ShowTimeSpinnerEvent extends MyteEvent<boolean> {
  public static eventId = 'showTimeSpinner';
  constructor() { super(ShowTimeSpinnerEvent.eventId); }
}

export class PPAReasonEvent extends MyteEvent<PPAReason>{
  public static eventId = "showPPAReason";
  constructor() { super(PPAReasonEvent.eventId) }
}
