import { Component, EventEmitter, Input, Output, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NumberFormatUtils } from '../../../shared/utils/numberFormater.utils';

@Component({
  selector: 'myte-textbox',
  templateUrl: './textbox.component.html',
  styleUrls: ['./textbox.component.sass']
})
export class TextboxComponent implements OnInit {
  @Input() public value: string;
  @Input() public isValid: boolean;
  @Input() public readOnly: boolean;
  @Input() public prependText: string = null;
  @Input() public isAdjustment: boolean;
  @Input() public precision: string = null;
  @Input() validationExpressions: RegExp[] = [];
  @Input() public id: string;
  @Input() public ariaLabel: string;
  @Input() public maxlength: number;
  @Input() public elementType: string;
  @Input() public required: boolean = false;
  @Input() public textCenter: boolean = false;
  @Output() public updateValueEvent = new EventEmitter();
  @ViewChild('textbox', { static: false }) textbox: ElementRef<HTMLElement>;
  public localValue: string;
  public textAlignByPrecision: string = 'left';
  constructor() { }

  ngOnInit() {
    if (this.precision) {
      this.textAlignByPrecision = 'right';
      this.localValue = this.parseToLocalString(this.precision, this.value);
    }
    if(this.textCenter){
      this.textAlignByPrecision = 'center';
    }
  }

  public parseToLocalString(precision: string, value: string) {
    if(isNaN(Number(value)) || value === null || value === undefined || value === '') return value;
    let fraction = precision.split('-');
    value = NumberFormatUtils.formatNumberToLocaleString(Number(value), Number(fraction[0]), Number(fraction[1]));
    return value;
  }

  public getValue(value) {
    let parsedNumber = this.parseMoney(value);
    return parsedNumber;
  }

  public onBlur(event) {
    let value = event.target.value;
    if(this.validationExpressions != null) {
      this.validationExpressions.forEach(expression => {
        if(expression && !expression.test(value || '')) {
          value = null;
          event.target.value = null;
        }
      });
    }
    if(!this.precision || value === '' || value == null) return this.updateValueEvent.emit(value);
    value = this.getValue(value);
    this.updateValueEvent.emit(value);
    this.localValue = this.parseToLocalString(this.precision, value);
  }

  public deleteGroupSeparator(event) {
    if(this.elementType === 'Money' && (event.target.value === '0.00' || event.target.value === '0,00')){
      this.localValue = ''
      return;
    }
    if(!this.precision || event.target.value === '') return;
    let groupSeparator = this.groupSeparator();
    if (groupSeparator)
      this.localValue = event.target.value.replace(new RegExp("\\"+groupSeparator, "g"), '');
    if(this.elementType=='Decimal' && event.type =="focus"){
      this.localValue=parseFloat(this.localValue).toString();
    }
  }
  public hideOptions(event?) { }
  public parseMoney(value: string) : string {
    let decimalSeparator = this.decimalSeparator();
    let groupSeparator = this.groupSeparator();
    let output = value;
    if (groupSeparator) {
      output = output.replace(new RegExp("\\"+groupSeparator, "g"), '');
    }
    output = output.replace(new RegExp("\\"+decimalSeparator, "g"), '.');
    let parsedNumber = output.split('.');
    if (parsedNumber.length > 1) {
      let intPart = parsedNumber.slice(0, -1).join('');
      let decPart = parsedNumber.slice(-1)[0];
      return  intPart + '.' + decPart;
    }
    return output;
  }

  public resetValues() {
    this.localValue = null;
    this.value = null;
    this.updateValueEvent.emit(this.value);
  }

  /**
   * [Will be refactor with internationalization approach]
   */ 
  private decimalSeparator = () => (1.1).toLocaleString(navigator.language).substring(1, 2);
  
  private groupSeparator() {
    let separator = (1000).toLocaleString(navigator.language).substring(1, 2);
    if (separator.charCodeAt(0) == 160) return separator;
    if (isNaN(Number(separator))) return separator;
    separator = (10000).toLocaleString(navigator.language).substring(2, 3);
    if (isNaN(Number(separator))) return separator;

    return '';
  }
}
