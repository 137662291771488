import { Injectable } from '@angular/core';
import { Observable, catchError, of } from 'rxjs';
import { AuditCommunicationLogOutput, ExpenseApiClient } from "@shared/clients/expense-api-client";
import { LogService } from "@shared/services/log/log.service";
@Injectable({
    providedIn: 'root'
})
export class AuditlogService {
    constructor(
        private api: ExpenseApiClient,
        private logService: LogService) { }

    /**
  * @param enterpriseId 
  * @param currentPeriodEndDate 
  * @param loginEnterpriseId (optional) 
  * @param viewMode (optional) 
  * @return Success
  */
    public loadAuditLog(
        enterpriseId: string | undefined,
        periodEndDate: Date | undefined,
        loggedInUser: string | undefined,
        viewMode: string | undefined
    ): Observable<AuditCommunicationLogOutput> {
        return this.api.getAuditCommunicationLog(enterpriseId, periodEndDate, loggedInUser, viewMode)
            .pipe(catchError((err) => {
                this.logService.logError(err, true);
                return of(null);
            }));
    }

    /**
  * @param data 
  * @return Success
  */
    public addAuditlogComment(
        data: any
    ): Observable<boolean> {
        return this.api.saveAuditCommunicationLogComment(data.enterpriseId, data.periodEndDate, data.loggedInUser, data.viewMode, data.comments)
            .pipe(catchError((err) => {
                this.logService.logError(err, true);
                return of(null);
            }));
    }

    /**
    * @param data 
    * @return Success
    */
    public showAuditCommunicationLog(
        data: any
    ): Observable<boolean> {
        return this.api.shouldShowAuditCommunicationLogButton(data.enterpriseId, data.periodEndDate, data.loggedInUser, data.viewMode)
            .pipe(catchError((err) => {
                this.logService.logError(err, true);
                return of(null);
            }));
    }
}

