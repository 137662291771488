import { Component } from '@angular/core';
import { ExpenseMultipleTaxiGrid, ExpenseMultipleTaxiGridReasons } from '@shared/models/expense/multipleTaxi/expense-multiple-grid';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'myte-taxi-reason-render',
  templateUrl: './taxi-reason-render.component.html',
  styleUrls: ['./taxi-reason-render.component.sass']
})
export class TaxiReasonRenderComponent implements ICellRendererAngularComp {
  public value: string;

  constructor() { }

  public agInit(params: ICellRendererParams<ExpenseMultipleTaxiGrid, ExpenseMultipleTaxiGridReasons>): void {
    this.value = params.value.selectedReasonDescription ? params.value.selectedReasonDescription : '--Select one--';
  }

  public refresh(params: ICellRendererParams<ExpenseMultipleTaxiGrid, ExpenseMultipleTaxiGridReasons>): boolean {
    return false;
  }
}