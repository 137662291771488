import { Component, OnInit } from '@angular/core';
import DropdownItem from 'app/shared/models/controls/dropdown/dropdown-item';
import { TimeReportService } from 'app/shared/services/time-report/time-report.service';
import * as moment from 'moment';

@Component({
  selector: 'myte-automation-test-data',
  templateUrl: './myte-automation-test-data.component.html',
  styleUrls: ['./myte-automation-test-data.component.sass']
})
export class MyteAutomationTestDataComponent implements OnInit {
  public endPointList = ['SAP2', 'LATAM'];
  public endPointListDisplay = ['SAP XML', 'LATAM XML'];
  public myteVersions = ['Classic Data', 'Next Data'];
  public endPointDropdownElements: DropdownItem[] = [];
  public myteVersionDropdownElements: DropdownItem[] = [];
  public endPointId: number = 1;
  public myteVersionId: number = 1;
  public eid: string = '';
  public date: Date = new Date();
  public text: string = '';

  constructor(
    private timeReportService: TimeReportService
  ) { }

  ngOnInit(): void {
    this.endPointListDisplay.forEach((xmlType, index) => {
      this.endPointDropdownElements.push(new DropdownItem(index + 1, xmlType));
    })
    this.myteVersions.forEach((version, index) => {
      this.myteVersionDropdownElements.push(new DropdownItem(index + 1, version));
    })
  }

  onEndPointChange(event) {
    this.endPointId = event;
  }

  onDatePickerChange(event) {
    this.date = new Date(Date.parse(event));
  }

  onTextChange(event) {
    this.eid = event;
  }

  onMyteVersionChange(event) {
    this.myteVersionId = event;
  }

  onDownloadClick() {
    let filename = this.endPointListDisplay[this.endPointId - 1].replace(' ', '') + '_' +
                      this.eid + '_' + this.getPeriodEnd(this.date).toJSON() + '_' +
                      this.myteVersions[this.myteVersionId - 1].split(' ')[0] + '.txt'
    this.download(filename, this.text);
  }

  onGetDataClick() {
    let periodEnd = this.getPeriodEnd(this.date);
    this.timeReportService.getAutomationTestData(this.eid, periodEnd).subscribe(results => {
      let textShouldBeBlank = true;
      if (results.length > 0) {
        results.forEach(r => {
          if (r.endPoint == this.endPointList[this.endPointId - 1]) {
            this.text = this.formatXml(r.xmlToSend);
            textShouldBeBlank = false;
          }
        })
      }
      if (textShouldBeBlank) this.text = '';
    });
  }

  getPeriodEnd(d: Date): Date {
    if (d.getDate() > 15) {
      return moment(d).endOf('month').toDate();
    } else {
      d.setDate(15);
      return d;
    }
  }

  download(filename, text) {
    var pom = document.createElement('a');
    pom.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    pom.setAttribute('download', filename);
    if (document.createEvent) {
        var event = document.createEvent('MouseEvents');
        event.initEvent('click', true, true);
        pom.dispatchEvent(event);
    } else {
        pom.click();
    }
  }

  formatXml(text: string) {
    text = '\n' + text.replace(/(<\w+)(\s.*?>)/g, function ($0, name, props) {
                return name + ' ' + props.replace(/\s+(\w+=)/g, " $1");
            }).replace(/>\s*?</g, ">\n<");

    text = text.replace(/\n/g, '\r').replace(/<!--(.+?)-->/g, function ($0, text) {
      var ret = '<!--' + escape(text) + '-->';
      //alert(ret);
      return ret;
    }).replace(/\r/g, '\n');

    var rgx = /\n(<(([^\?]).+?)(?:\s|\s*?>|\s*?(\/)>)(?:.*?(?:(?:(\/)>)|(?:<(\/)\2>)))?)/mg;
    var nodeStack = [];
    var _this = this;
    var output = text.replace(rgx, function ($0, all, name, isBegin, isCloseFull1, isCloseFull2, isFull1, isFull2) {
      var isClosed = (isCloseFull1 == '/') || (isCloseFull2 == '/' ) || (isFull1 == '/') || (isFull2 == '/');
      //alert([all,isClosed].join('='));
      var prefix = '';
      if (isBegin == '!') {
        prefix = _this.getPrefix(nodeStack.length);
      }
      else {
        if (isBegin != '/') {
          prefix = _this.getPrefix(nodeStack.length);
          if (!isClosed) {
            nodeStack.push(name);
          }
        }
        else {
          nodeStack.pop();
          prefix = _this.getPrefix(nodeStack.length);
        }
      }
      var ret = '\n' + prefix + all;
      return ret;
    });

    var prefixSpace = -1;
    var outputText = output.substring(1);
    //alert(outputText);

    outputText = outputText.replace(/\n/g, '\r').replace(/(\s*)<!--(.+?)-->/g, function ($0, prefix, text) {
      //alert(['[',prefix,']=',prefix.length].join(''));
      if (prefix.charAt(0) == '\r')
          prefix = prefix.substring(1);
      text = unescape(text).replace(/\r/g, '\n');
      var ret = '\n' + prefix + '<!--' + text.replace(/^\s*/mg, prefix) + '-->';
      //alert(ret);
      return ret;
    });

    return outputText.replace(/\s+$/g, '').replace(/\r/g, '\r\n');
  }
  getPrefix(prefixIndex) {
    var span = '    ';
    var output = [];
    for (var i = 0; i < prefixIndex; ++i) {
      output.push(span);
    }

    return output.join('');
  }
}
