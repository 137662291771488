import { Injectable } from '@angular/core';
import { ExpenseApiClient } from '../../../../shared/clients/expense-api-client';
import { LogService } from '../../../../shared/services/log/log.service';
import { UserService } from '../../../../shared/services/user/user.service';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import AmexInput from '../../../../shared/models/expense/amex/expense-amex-import';
import CorporateCardInput from '../../../../shared/models/expense/amex/expense-corporate-card-import';
import { MyteBaseService } from '../../../../shared/services/myte-base.service';
import { GlobalEventsService } from '../../../../shared/services/events/global-events.service';
import { GlobalCacheService } from '../../../../shared/services/cache/global-cache.service';

@Injectable({
  providedIn: 'root'
})
export class ExpenseAmexService extends MyteBaseService {
  private AmexData: AmexInput[];
  private corporateCardData: CorporateCardInput[];
  private isFromAmexPopUpImport: boolean = false;

  constructor(private api: ExpenseApiClient,
              logService: LogService,
              userService: UserService,
              eventService: GlobalEventsService,
              globalCacheService: GlobalCacheService) {
    super(userService, globalCacheService, eventService, logService);
  }

  public findAmexExpensesForAny(periodEnd: Date, countryKey: string, isAutoShowAmexExpense: boolean = false): Observable<any> {
    return this.api.getAmexExpenses(this.userEid, periodEnd, countryKey, isAutoShowAmexExpense, this.supervisorEid, this.viewMode, "Next")
      .pipe(res => {
        return res;
      })
      .pipe(catchError(err => {
        this.logService.logError(err, true);
        return of(null);
      }));
  }

  public findCorporateCardExpenses(periodEnd: Date, countryKey: string, isAutoShowAmexExpense: boolean = false): Observable<any> {
    return this.api.getCorporateCardExpense(this.userEid, periodEnd, countryKey, isAutoShowAmexExpense, this.supervisorEid, this.viewMode)
      .pipe(res => {
        return res;
      })
      .pipe(catchError(err => {
        this.logService.logError(err, true);
        return of(null);
      }));
  }

  public setAmexExpenses(importAmexData: AmexInput[]) {
    this.AmexData = importAmexData;
  }

  public setCorporateCardExpenses(importExpenseData: CorporateCardInput[]) {
    this.corporateCardData = importExpenseData;
  }

  public getAmexExpenses(): AmexInput[] {
    return this.AmexData;
  }

  public getCorporateCardExpenses(): CorporateCardInput[] {
    return this.corporateCardData;
  }

  public resetAmexExpenses(): void {
    this.AmexData = [];
  }

  public resetCoporateCardExpenses(): void {
    this.corporateCardData = [];
  }

  public getIsFromAmexPopUpImport(): boolean{
    return this.isFromAmexPopUpImport;
  }

  public setIsFromAmexPopUpImport(flag: boolean): void{
    this.isFromAmexPopUpImport = flag;
  }

}
