import { TimeEntryTemplate } from '../../../../shared/models/time-sheet/time-entry/time-entry-template';

export class TimeEntryTemplateResponse {
    timeEntryTemplate: TimeEntryTemplate;
    hasErrorSave: boolean;
    shouldUseTimeEntryTemplate: boolean;
    constructor() {
        this.shouldUseTimeEntryTemplate = false;
        this.timeEntryTemplate = null;
        this.hasErrorSave = false;
    }
}