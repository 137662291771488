import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[myteHoursInput]'
})
export class HoursInputDirective {
  constructor(private el: ElementRef) { }

  @HostListener('keyup', ['$event']) onKeyDown(event) {
    let key = event.which || event.keyCode;
    if (this.InvalidNumber(key) || this.HasMultipleDecimalSeparator(event.target.value, key)) {
      event.stopPropagation();
      return false;
    }
    return true;
  }

  public InvalidNumber(n: number): boolean {
    const t = this.DecimalSeparator() == "," ? 188 : 190;
    return n >= 48 && n <= 57 || n >= 96 && n <= 105 || n >= 37 && n <= 40 || n == t || n == 8 || n == 127 || n == 46 || (t !== 188 && n == 110) || n == 9 || n == 13 || n == 27 ? !1 : !0
  }

  public HasMultipleDecimalSeparator(value: string, keycode: number): boolean {
    const t = this.DecimalSeparator() == "," ? 188 : 190;
    return value.indexOf(this.DecimalSeparator()) >= 0 && (keycode == t || keycode == 110);
  }

  public DecimalSeparator() {
    const n: any = 1.1;
    return n.toLocaleString().substring(1, 2);
  }
}
