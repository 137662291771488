import { Component, HostListener } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { GridApi, ICellEditorParams } from 'ag-grid-community';
import { TimeEntryTemplateAssignment, TimeEntryTemplateTask } from '../../../../../shared/models/time-sheet/time-entry/time-entry-template';
import { LogService } from '../../../../../shared/services/log/log.service';

@Component({
  selector: 'myte-time-entry-template-popup-grid-chargecode-editor',
  templateUrl: './time-entry-template-popup-grid-chargecode-editor.component.html',
  styleUrls: ['./time-entry-template-popup-grid-chargecode-editor.component.sass']
})
export class TimeEntryTemplatePopupGridChargecodeEditorComponent implements ICellEditorAngularComp {
  public value: TimeEntryTemplateAssignment;
  public chargeCodes: TimeEntryTemplateAssignment[];
  private api: GridApi<TimeEntryTemplateTask>;
  public showTooltip: boolean = true;


  constructor(private logService: LogService) { }

  public agInit(params: ICellEditorParams<TimeEntryTemplateTask, TimeEntryTemplateAssignment> & { assignments: TimeEntryTemplateAssignment[] }): void {
    this.api = params.api;
    this.chargeCodes = params.assignments;
    this.value = params.value;
  }

  public getValue(): TimeEntryTemplateAssignment {
    var chCode = this.chargeCodes.find(x => x.code == this.value.code);
    if (chCode) {
      if (chCode.selected) {
        this.logService.logInfo(chCode.description + ' has already been added to your time sheet.', true, '');
        return null;
      }
      chCode.selected = true
    };
    return this.value;
  }

  public isPopup?(): boolean {
    return true;
  }

  public select(chargecode: TimeEntryTemplateAssignment): void {
    this.value = chargecode;
    this.api.stopEditing();
  }

  @HostListener('wheel', ['$event']) onMouseWheel(event: WheelEvent): void {
    this.showTooltip = false;
  }
  @HostListener('mouseenter', ['$event'])
  onMouseEnter(event: MouseEvent): void {
    if (document.activeElement) {
      (document.activeElement as HTMLElement).blur();
    }
  }
  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    if (event.key == 'Tab' || event.code == 'Tab') {
      event.stopPropagation();
      event.preventDefault();
    }
    else if (event.key == 'Escape' || event.code == 'Escape') {
      event.stopPropagation();
      this.api.stopEditing();
      var focusedCell = this.api.getFocusedCell();
      if (focusedCell != null && focusedCell != undefined) {
        this.api.setFocusedCell(focusedCell.rowIndex, focusedCell.column.getColId());
      }
    }
    else if (event.key == 'ArrowDown' || event.code == 'ArrowDown') {
      event.stopPropagation();
      event.preventDefault();
      var next = document.activeElement.nextElementSibling;
      if (next != null && next != undefined) {
        (next as HTMLElement).focus()
        this.showTooltip = true;
      }
    }
    else if (event.key == 'ArrowUp' || event.code == 'ArrowUp') {
      event.stopPropagation();
      event.preventDefault();
      var previous = document.activeElement.previousElementSibling;
      if (previous != null && previous != undefined) {
        (previous as HTMLElement).focus()
        this.showTooltip = true;
      }
    }
  }
}