import { LocationList } from '@shared/models/locationList';
import { ResidentLocationList } from '@shared/models/residentLocationList';

export class HomeOfficeLocationList {
    public homeLocation1: LocationList[];
    public homeLocation2: LocationList[];
    public currentHomeOfficeLocationCode: string;
    public currentHomeOfficeLocationStateCode: string;
    public currentResidentLocationCode: string;
    public residentLocationList: ResidentLocationList[];
    public selectedHomeOfficeLocationCodeForCompensatory: string;
    public selectedHomeOfficeLocationStateCodeForCompensatory: string;
    public isVisible: boolean;
    public locationReasons: LocationList[];
    public locationTypes: LocationList[];
    public currentHomeOfficeLocationReasonCode: string;
    public currentHomeOfficeLocationTypeCode: string;
    constructor(
        homeLocation1?: LocationList[],
        homeLocation2?: LocationList[],
        currentHomeOfficeLocationCode?: string,
        currentHomeOfficeLocationStateCode?: string,
        currentResidentLocationCode?: string,
        residentLocationList?: ResidentLocationList[],
        selectedHomeOfficeLocationCodeForCompensatory?: string,
        selectedHomeOfficeLocationStateCodeForCompensatory?: string,
        isVisible?: boolean,
        locationReasons?: LocationList[],
        locationTypes?: LocationList[],
        currentHomeOfficeLocationReasonCode?: string,
        currentHomeOfficeLocationTypeCode?: string
    ) {
        this.homeLocation1 = homeLocation1;
        this.homeLocation2 = homeLocation2;
        this.currentHomeOfficeLocationCode = currentHomeOfficeLocationCode;
        this.currentHomeOfficeLocationStateCode = currentHomeOfficeLocationStateCode;
        this.currentResidentLocationCode = currentResidentLocationCode;
        this.residentLocationList = residentLocationList;
        this.selectedHomeOfficeLocationCodeForCompensatory = selectedHomeOfficeLocationCodeForCompensatory;
        this.selectedHomeOfficeLocationStateCodeForCompensatory = selectedHomeOfficeLocationStateCodeForCompensatory;
        this.isVisible = isVisible;
        this.locationReasons = locationReasons;
        this.locationTypes = locationTypes;
        this.currentHomeOfficeLocationReasonCode = currentHomeOfficeLocationReasonCode;
        this.currentHomeOfficeLocationTypeCode = currentHomeOfficeLocationTypeCode;
    }
}