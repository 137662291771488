export class DayEntry {
  public entryDate: Date;
  public checked: boolean;
  public hours: number;
  public chargeCode: string;
  public hasAdjustment: boolean;
  public hasError;

  constructor(date?: any, checked?: boolean, hasAdjustment?: boolean, hasError?: boolean, hours?: number) {
    this.entryDate = date;
    this.checked = checked;
    this.hasAdjustment = hasAdjustment;
    this.hours = hours;
    this.chargeCode = null;
    this.hasError = hasError;
  }
}
