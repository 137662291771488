import { LocationInformation } from '../locations-by-day/location-information';

export class LocationCharge {
  public location: LocationInformation;
  public hours: number;
  public hasAdjustment: boolean;

  constructor(location?: LocationInformation, hours?: number, hasAdjustment?: boolean) {
    this.location = location ?? new LocationInformation();
    this.hours = hours;
    this.hasAdjustment = hasAdjustment;
  }
}
