import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NavigatorBrowserUtils } from '@sharedUtils/navigatorBrowser.utils';

@Directive({
  selector: '[myteValidateInput]'
})
export class ValidateInputDirective {

  @Input() myteValidateInput: RegExp[];
  private specialKeys: Array<string> = ['Backspace', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Clear', 'Delete', 'Tab'];

  constructor(private el: ElementRef) {}

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    if(NavigatorBrowserUtils.isIEPolyfilSeparator())
    {
      if(NavigatorBrowserUtils.ValidInputKeyDownIE(event))
        return;
    }
    if (this.isSpecialKey(event)) {
      return;
    }
    this.validate(event, this.myteValidateInput);
  }

  /* @HostListener('paste', ['$event']) onPaste(event: ClipboardEvent) {
    event.preventDefault();
    const pastedInput: string = event.clipboardData
      .getData('text/plain')
      .replace(/\D/g, '');
    document.execCommand('insertText', false, pastedInput);
  }

  @HostListener('drop', ['$event']) onDrop(event: DragEvent) {
    event.preventDefault();
    const textData = event.dataTransfer
      .getData('text').replace(/\D/g, '');
    this.el.nativeElement.focus();
    document.execCommand('insertText', false, textData);
  }

   // Delete commas
   @HostListener('focus', ['$event.target'])
   keyOnFocus(event) {
     let elRef = this.elementRef.nativeElement.dataType;
     if (event.value != '') {
       if (elRef == 'Decimal' || elRef == 'Money') {
         event.value = parseFloat(event.value.replace(/,/g, ''));
       }
     }
   } */

  private validate(event, validationExpressions) {
    if (!validationExpressions) return;
    let selectedText = window.getSelection().toString();
    let current: string = this.el.nativeElement.value.replace(selectedText, '');
    let next: string = current.concat(event.key);
    validationExpressions.forEach(expression => {
      if (expression && !expression.test(next || '')) {
        event.preventDefault();
      }
    });
  }

  private isSpecialKey(e): boolean {
    return (
      // Cmd+X (Mac)
      // Cmd+V (Mac)
      ([46, 8, 9, 27, 13].indexOf(e.keyCode) !== -1 || // Allow: Delete, Backspace, Tab, Escape, Enter
      (e.keyCode >= 35 && e.keyCode <= 39) || // Home, End, Left, Right
      (e.keyCode === 65 && e.ctrlKey === true) || // Allow: Ctrl+A
      (e.keyCode === 67 && e.ctrlKey === true) || // Allow: Ctrl+C
      (e.keyCode === 86 && e.ctrlKey === true) || // Allow: Ctrl+V
      (e.keyCode === 88 && e.ctrlKey === true) || // Allow: Ctrl+X
      (e.keyCode === 65 && e.metaKey === true) || // Cmd+A (Mac)
      (e.keyCode === 67 && e.metaKey === true) || // Cmd+C (Mac)
      (e.keyCode === 86 && e.metaKey === true) || (e.keyCode === 88 && e.metaKey === true))
    );
  }
}
