import { Injectable } from "@angular/core";
import { RequestedOvertime } from "@shared/models/request-overtime/requested-overtime";
import { OverTimeRequested, OverTimeLimitSummary, OvertimeLimit, OvertimeWorkSummary, RequestedDaysDetail } from "@shared/models/overtime-popup/overtime-popup-detail";
import { INDIA_OVERTIME } from "@shared/utils/constants";
import { OvertimeLimitSummary, OvertimeSummary, TimeApiClient } from "@shared/clients/time-api-client";
import { GlobalCacheService } from "@shared/services/cache/global-cache.service";
import { GlobalEventsService } from "@shared/services/events/global-events.service";
import { LogService } from "@shared/services/log/log.service";
import { PeriodEndService } from "@shared/services/periodend/periodend.service";
import { UserService } from "@shared/services/user/user.service";
import { catchError, map, Observable, of } from "rxjs";
import { MyteBaseService } from "@shared/services/myte-base.service";
import OvertimeLimitDetaisServiceMapper from "./overtime-limit-detais-service.mapper";
import { ApproveAndHoldOvertime } from "@shared/models/approve-hold-overtime/approve-hold-overtime";

@Injectable()
export class OvertimeLimitDetailService extends MyteBaseService {
  constructor(
    private api: TimeApiClient,
    logService: LogService,
    userService: UserService,
    eventService: GlobalEventsService,
    private periodEndService: PeriodEndService,
    private cacheService: GlobalCacheService) {
      super(userService, cacheService, eventService, logService)
    }

  public getOvertimeLimitDetails(requestedOvertimeOutputFromRequestor: RequestedOvertime | ApproveAndHoldOvertime, isOvertimeApprover: boolean): Observable<OverTimeRequested> {
    let reqBody : any;
    reqBody = OvertimeLimitDetaisServiceMapper.mapGetOvertimePopUpInput(requestedOvertimeOutputFromRequestor, isOvertimeApprover);
    reqBody.state = requestedOvertimeOutputFromRequestor.state;
    let otRequestor: string;
    if(isOvertimeApprover){
      const  otRequestorTemp = <ApproveAndHoldOvertime>requestedOvertimeOutputFromRequestor;
      otRequestor = otRequestorTemp.requestorEnterpriseId;
      reqBody.otRequested = otRequestorTemp.otRequested;
    } else{
      const  otRequestorTemp = <RequestedOvertime>requestedOvertimeOutputFromRequestor;
      otRequestor = otRequestorTemp.otRequestor;
      reqBody.status =  otRequestorTemp.status;
      reqBody.otRequested = otRequestorTemp.otRequestedOld ? otRequestorTemp.otRequestedOld : otRequestorTemp.otRequested;
    }
    return this.api.getOvertimeSummary(this.userEid, reqBody)
        .pipe(map((overtimeResult) => this.mapDataToOvertime(overtimeResult, otRequestor , isOvertimeApprover, reqBody)))
        .pipe(catchError((err) => {
          this.logService.logError(err, true);
          return of(null);
        })); 
  }

  mapDataToOvertime(data: OvertimeSummary, otRequestor:string, isOvertimeApprover: boolean, reqBody: any): OverTimeRequested {
    let result = new OverTimeRequested();
    result.overTimeLimitSummary = this.mapDataToOverTimeLimitSummary(data["overtimeLimitSummary"], otRequestor, isOvertimeApprover);
    result.overtimeWorkSummary = this.mapDataToOvertimeRequest(data["overTimeWorkSummary"], otRequestor, reqBody);
    return result;
  }
  mapDataToOverTimeLimitSummary(data: OvertimeSummary, otRequestor:string, isOvertimeApprover:boolean): OverTimeLimitSummary {
    let result = new OverTimeLimitSummary();
    result.balance = this.mapDataToOvertimeTimeSpan(data["balance"]);
    result.hoursRequested = isOvertimeApprover ? this.mapDataToOvertimeTimeSpan(data["hoursRequested"]) : this.mapDataToOvertimeTimeHoursRequested(data["hoursRequested"], otRequestor);
    result.hoursWorked = this.mapDataToOvertimeTimeSpan(data["hoursWorked"]);;
    result.limit = this.mapDataToOvertimeTimeSpan(data["limit"]);
    return result;
  }

  mapDataToOvertimeTimeHoursRequested(data: OvertimeLimitSummary, otRequestor: string): OvertimeLimit {
    let result = new OvertimeLimit();
    result.weeklyHours = otRequestor ? this.formatValue(data["weeklyHours"]) : '';
    result.quarterlyHours = otRequestor ? this.formatValue(data["quarterlyHours"]) : '';
    result.yearlyHours = otRequestor ? this.formatValue(data["yearlyHours"]) : '';
    return result;
  }

  mapDataToOvertimeTimeSpan(data: OvertimeLimitSummary): OvertimeLimit {
    let result = new OvertimeLimit();
    result.weeklyHours = data["weeklyHours"] ? this.formatValue(data["weeklyHours"]) : '';
    result.quarterlyHours = data["quarterlyHours"] ? this.formatValue(data["quarterlyHours"]) : '';
    result.yearlyHours = data["yearlyHours"] ? this.formatValue(data["yearlyHours"]) : '';
    return result;
  }
  mapDataToOvertimeRequest(data: OvertimeSummary, otRequestor: string, reqBody: any): OvertimeWorkSummary {
    let result = new OvertimeWorkSummary();
    result.enterpriseId = data["enterpriseId"];
    result.fromDate = reqBody.fromDate;
    result.toDate = reqBody.toDate;
    result.otRequested = this.getOTRequested(otRequestor, reqBody);
    result.state = reqBody.state;
    result.requestedDaysDetail = this.mapDataToOvertimeRequestEntry(data["requestedDaysDetails"], otRequestor, reqBody);
    return result;
  }

  mapDataToOvertimeRequestEntry(data: RequestedDaysDetail, otRequestor: string, reqBody: any): RequestedDaysDetail[] {
    if (Array.isArray(data)) {
      let result = new Array<RequestedDaysDetail>();
      for (let item of data) {
        result.push(new RequestedDaysDetail(item["otdate"], item["workdayType"], item["workSchedule"], item["dailyLimit"], (item["otHours"] != 0 ? item["otHours"] : null), this.getOTRequested(otRequestor, reqBody), item["statusCd"]))
      }
      return result;
    }
  }

/**
 * if otRequestor is blank and status is not fail then return null
 * @returns otRequestor
 */
  getOTRequested(otRequestor: string, reqBody: any): string{
    return otRequestor ? reqBody.otRequested : (reqBody.status == INDIA_OVERTIME.requestOTStatus ? reqBody.otRequested : null);
  }

/**
 * @param value any type of string,number or null
 * @returns NA, null, formated number to one decimal place, if negative value in param then 0.0
 */
  formatValue(value: any) : string{
    if(value === INDIA_OVERTIME.exportApproveOTValues.NaA){
      return value;
    } else {
      if(value == null || value == '') return value;
      if(Math.sign(value) === -1) return '0.0';
      return typeof value == 'string' ? parseFloat(value).toFixed(1).toString() : value.toFixed(1);
    }
  }
}