import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import TabsRelationsService from '../../../shared/services/tabsrelations/tabs-relations.service';


@Injectable({
    providedIn: 'root'
  })
  export class TabRelationsResolver  
  {
      /**
       *
       */
      constructor(private tabRelationService:TabsRelationsService) {
          
      }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Observable<Observable<any>> | Promise<Observable<any>> {
        if(!this.tabRelationService.hasAnyRelation())
            return of(null);
        if(!this.tabRelationService.getRelation().persistToGoal)    
        return of(this.tabRelationService.getRelationAndClear());
        return of(this.tabRelationService.getRelation());
    }




  }