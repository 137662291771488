import { Injectable } from '@angular/core';
import { Option, ExpenseFieldWithValidationResult } from '@shared/clients/expense-api-client';
import AuditInquiry from '@shared/models/expense/audit/expense-audit-inquiry';
import Expense from '@shared/models/expense/expense';
import { ExpenseAmount } from '@shared/models/expense/expense-amount';
import ExpenseGridItem from '@shared/models/expense/expense-grid-item';
import ExpenseView from '@shared/models/expense/expense-view';
import ReceiptViewField from '@shared/models/expense/field/expense-receipt-view-field';
import { ReceiptRequirement } from '@shared/models/expense/receipt/expense-receipt-requirement';
import UserPreference from '@shared/models/userPreference';
import User from '@shared/models/user/user';
import { GlobalCacheService } from '@shared/services/cache/global-cache.service';
import { UserProfileService } from '@shared/services/user/user-profile.service';
import { UserService } from '@shared/services/user/user.service';
import { BehaviorSubject, forkJoin, map, Observable, of, tap } from 'rxjs';
import { ExpenseViewSoftWarning } from '@shared/models/expense/expense-view-soft-warning';
import ExpenseViewRowElement from '@shared/models/expense/field/expense-view-row-element';
import CorporateCardExpenseInput from '@shared/models/expense/amex/expense-corporate-card-import';
import AmexInput from '@shared/models/expense/amex/expense-amex-import';
import { NumberFormatUtils } from '@shared/utils/numberFormater.utils';
import { LogService } from '@shared/services/log/log.service';
import { ExpenseViewFieldValidationError } from '@shared/models/expense/expense-view-field-validation-error';
import { ExpenseViewSource } from '@shared/models/expense/expense-view-source';
import { ExpenseService } from '../../../myte-expenses/shared/services/expense/expense.service';
import { ExpenseAuditService } from '../../../myte-expenses/shared/services/audit/expense-audit.service';
import { ExpenseTripService } from '../../../myte-expenses/shared/services/trip/expense-trip.service';
import ExpenseTripCreateInput from '@shared/models/expense/trip/expense-trip-create';
import ExpenseTripReviewView from '@shared/models/expense/trip/expense-trip-review-view';
import { Trip_Apply_ErrorMessage, Trip_Apply_SussessMessage, Trip_Delete_ErrorMessage } from '@shared/myte-static-message';
import ExpenseTripName from '@shared/models/expense/trip/expense-trip-name';
import { AppConfigService } from '@authServices/app-config.service';
import { ExpenseAmexService } from '../../../myte-expenses/shared/services/amex/expense-amex.service';
import { PeriodEndService } from '../periodend/periodend.service';

export class SaveExpenseResponse {
    passed: boolean;
    hasValidationsErrors: boolean;
    needRedirect: boolean;
    softWarning: ExpenseViewSoftWarning;
    errors: string[];
    expenseView: ExpenseView;
    timeReportStatus: string;
    needUpdateTimeReportStatus: boolean;
    constructor(expenseView?: ExpenseView) {
        this.passed = false;
        this.hasValidationsErrors = false;
        this.needRedirect = false;
        this.softWarning = expenseView?.softWarning == undefined ? new ExpenseViewSoftWarning('', false) : expenseView?.softWarning;
        this.errors = [];
        this.expenseView = expenseView;
    }
}

export class ExpensesResponse {
    expenses: Expense[];
    timeReportStatus: string;
}
export class ExpenseState {
    expenseGridData: ExpenseGridItem[];
    expenses: Expense[];
    userIssues: AuditInquiry[];
    userReversalIssues: AuditInquiry[];
}

export interface ExpenseInformation {
    expenseGridData: ExpenseGridItem[];
    totalAmountGrid: number;
    totalTaxTrueUpValue: number;
    currency: string;
    isGroupByTrip: boolean;
    countryKey: string;
    hasPPA: boolean;
    expenseTotalErrors: string;
    isOlderThanAYear : boolean;
    parentExpenseTotalErrors: string;
}


@Injectable({
    providedIn: 'root'
})
export class ExpenseStoreService {
    private expensesGrid: BehaviorSubject<Array<ExpenseGridItem>> = new BehaviorSubject(new Array<ExpenseGridItem>());
    private expenses: BehaviorSubject<Array<Expense>> = new BehaviorSubject(new Array<Expense>());
    private expenseView: BehaviorSubject<ExpenseView> = new BehaviorSubject(new ExpenseView());
    private userIssues: BehaviorSubject<Array<AuditInquiry>> = new BehaviorSubject(new Array<AuditInquiry>());
    private userReversalIssues: BehaviorSubject<Array<AuditInquiry>> = new BehaviorSubject(new Array<AuditInquiry>());
    private isLoading: BehaviorSubject<boolean> = new BehaviorSubject(true);
    private isCarPlan: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private userPreferences: BehaviorSubject<UserPreference> = new BehaviorSubject(new UserPreference());
    private totalAmountGrid: BehaviorSubject<number> = new BehaviorSubject(0);
    private totalTaxTrueUpValue: BehaviorSubject<number> = new BehaviorSubject(0);
    private currency: BehaviorSubject<string> = new BehaviorSubject('');
    private expenseGridInitializationData: BehaviorSubject<ExpenseInformation> = new BehaviorSubject(null);
    private tripList: BehaviorSubject<ExpenseTripName[]> = new BehaviorSubject(new Array<ExpenseTripName>);
    private amexNotificationNumber: BehaviorSubject<number> = new BehaviorSubject(0);

    private expenseTotalErrors: string = '';
    private hasPPA: boolean = false;
    private totalAmountGridInternal: number = 0;
    private totalTaxTrueUpValueInternal: number = 0;

    public readonly expensesGridData$: Observable<Array<ExpenseGridItem>> = this.expensesGrid.asObservable();
    public readonly isLoading$: Observable<boolean> = this.isLoading.asObservable();
    public readonly userIssues$: Observable<Array<AuditInquiry>> = this.userIssues.asObservable();
    public readonly userReversalIssues$: Observable<Array<AuditInquiry>> = this.userReversalIssues.asObservable();
    public readonly isCarPlan$: Observable<boolean> = this.isCarPlan.asObservable();
    public readonly userPreferences$: Observable<UserPreference> = this.userPreferences.asObservable();
    public readonly expenseView$: Observable<ExpenseView> = this.expenseView.asObservable();
    public readonly expenses$: Observable<Expense[]> = this.expenses.asObservable();
    public readonly totalAmountGrid$: Observable<number> = this.totalAmountGrid.asObservable();
    public readonly totalTaxTrueUpValue$: Observable<number> = this.totalTaxTrueUpValue.asObservable();
    public readonly currency$: Observable<string> = this.currency.asObservable();
    public readonly expenseGridInitializationData$: Observable<ExpenseInformation> = this.expenseGridInitializationData.asObservable();
    public readonly tripList$: Observable<ExpenseTripName[]> = this.tripList.asObservable();
    public readonly amexNotificationNumber$: Observable<any> = this.amexNotificationNumber.asObservable();



    private actualPeriodEnd: Date;
    private actualUser: User;
    private parentExpenseTotalErorrs: string;
    private isUsingAmex: boolean;
    constructor(public expenseService: ExpenseService,
                public userProfileService: UserProfileService,
                public globalCacheService: GlobalCacheService,
                public userService: UserService,
                public auditService: ExpenseAuditService,
                private logService: LogService,
                public amexService: ExpenseAmexService,
                public appConfigService: AppConfigService,
                public periodEndService: PeriodEndService,
                private expenseTripService: ExpenseTripService) {
                    this.actualPeriodEnd = this.globalCacheService.getPeriodEnd();
                    if(!this.actualPeriodEnd)
                        this.actualPeriodEnd= this.periodEndService.getActualPeriod();
                    this.actualUser = this.userService.getUser();
                    this.isUsingAmex = this.appConfigService.getAppConfig.AdditionalConfig.useCorporateCard;
                }

    public loadExpenses(clearCache: boolean = true, periodEnd?: Date, clearTripBuffer: boolean = false, isFromUpdateTimeReportToDraft : boolean = false): Observable<[ExpensesResponse, UserPreference, AuditInquiry[], ExpenseTripName[]]> {
        if (clearCache) {
            this.globalCacheService.clearExpenseList(this.actualPeriodEnd, this.globalCacheService.getUserCountry());
            this.globalCacheService.resetTripBuffer(this.actualUser.enterpriseId, this.globalCacheService.getUserCountry());
        }
        if (periodEnd)
            this.actualPeriodEnd = periodEnd;
        if(!isFromUpdateTimeReportToDraft)
            this.isLoading.next(true);
        this.findAmexExpenseCount();
        return forkJoin([
            this.expenseService.getExpensesStore(this.actualPeriodEnd, this.globalCacheService.getUserCountry()),
            this.userProfileService.getProfileByPeriodEnd(this.actualPeriodEnd),
            this.auditService.getIssuesWithAdjustments(),
            this.expenseTripService.getTripNameList(this.globalCacheService.getUserCountry())
        ])
        .pipe(tap(([expenses, userPreferences, issuesWithAdjustments, tripsList]: [ExpensesResponse, UserPreference, AuditInquiry[], ExpenseTripName[]]) => {
            this.formatFromDateForEX17(expenses);            
            // also this.modifyFederalExpenseWBSStatus();
            this.expenseGridInitializationData.next({
                expenseGridData: this.mapExpenses(expenses.expenses, userPreferences.isCarPlan),
                totalAmountGrid: NumberFormatUtils.formatNumberToLocaleString(parseFloat(this.totalAmountGridInternal.toString()), expenses.expenses[0]?.decimalPlaces),
                totalTaxTrueUpValue: this.totalTaxTrueUpValueInternal,
                currency: expenses.expenses[0]?.currency,
                isGroupByTrip: this.globalCacheService.showExpenseGroupByTrip()?.data,
                countryKey: userPreferences.countryKey,
                hasPPA: this.hasPPA,
                expenseTotalErrors: this.expenseTotalErrors,
                isOlderThanAYear : this.isOlderThanAYear(this.actualPeriodEnd),
                parentExpenseTotalErrors: this.parentExpenseTotalErorrs
            });
            this.tripList.next(tripsList);
            this.currency.next(expenses.expenses[0]?.currency);
            this.totalAmountGrid.next(NumberFormatUtils.formatNumberToLocaleString(parseFloat(this.totalAmountGridInternal.toString()), expenses.expenses[0]?.decimalPlaces));
            this.totalTaxTrueUpValue.next(this.totalTaxTrueUpValueInternal);
            this.isCarPlan.next(userPreferences.isCarPlan);
            this.expenses.next(expenses.expenses);
            this.userIssues.next(issuesWithAdjustments);
            this.userPreferences.next(userPreferences);
            this.userReversalIssues.next(
                issuesWithAdjustments.filter(issue => {
                    return issue.toBeDisplayedinAuditAdjustment == true && issue.isNotificationSent == true;
                })
            );
            this.isLoading.next(false);
        }))
    }

    public handleExpensesCache(periodEnd: Date = null): void {
        this.globalCacheService.clearExpenseList(periodEnd ?? this.actualPeriodEnd, this.globalCacheService.getUserCountry());
        this.globalCacheService.resetTripBuffer(this.actualUser.enterpriseId, this.globalCacheService.getUserCountry());
    }

    public resetBufferExpensesByDates(dates: Array<Date>): void {
        const countryKey = this.globalCacheService.getUserCountry();
        dates.forEach(date => {
            this.globalCacheService.clearExpenseList(date, countryKey);
        });
    }

    public getAmexRequest(periodEnd): Observable<any> {
        if (this.isUsingAmex) {
            return this.globalCacheService.handleAmexExpenses(this.amexService.findCorporateCardExpenses(periodEnd, this.userPreferences.getValue().countryKey));
        } else {
            return this.globalCacheService.handleAmexExpenses(this.amexService.findAmexExpensesForAny(periodEnd, this.userPreferences.getValue().countryKey));
        }
    }

    public findAmexExpenseCount(): void {
        let amexNumberNotification = 0;
        this.getAmexRequest(this.actualPeriodEnd)
            .subscribe(amexCountResponse => {
                if (this.isUsingAmex ? amexCountResponse.expenseImportOutputList : amexCountResponse.amexExpenseForDisplayList) {
                    amexNumberNotification = this.isUsingAmex ? amexCountResponse.expenseImportOutputList.length : amexCountResponse.amexExpenseForDisplayList.length;
                }
                this.amexNotificationNumber.next(amexNumberNotification);
            });
    }

    public switchGridBetweenModes(): void {
        this.isLoading.next(true);
        this.expenseGridInitializationData.next({
            expenseGridData: this.mapExpenses(this.expenses.value, this.isCarPlan.value),
            totalAmountGrid: NumberFormatUtils.formatNumberToLocaleString(parseFloat(this.totalAmountGridInternal.toString()), this.expenses.value[0]?.decimalPlaces),
            totalTaxTrueUpValue: this.totalTaxTrueUpValueInternal,
            currency: this.currency.value,
            isGroupByTrip: this.globalCacheService.showExpenseGroupByTrip()?.data,
            countryKey: this.userPreferences.value.countryKey,
            hasPPA: this.hasPPA,
            expenseTotalErrors: this.expenseTotalErrors,
            isOlderThanAYear : this.isOlderThanAYear(this.globalCacheService.getPeriodEnd()),
            parentExpenseTotalErrors: this.parentExpenseTotalErorrs
        });
        this.isLoading.next(false);
    }

    private mapExpenses(expenses: Expense[], isCarPlan: boolean): ExpenseGridItem[] {
        return this.globalCacheService.showExpenseGroupByTrip()?.data ?
                    this.groupByTrip(this.mapExpensesForGrid(expenses, isCarPlan)) :
                    this.mapExpensesForGrid(expenses, isCarPlan);
    }

    public saveExpense(shouldInitialize: boolean = true, amexInfo: CorporateCardExpenseInput | AmexInput = null, shouldImportWithTransaction: boolean = false): Observable<SaveExpenseResponse> {
        this.isLoading.next(true);
        let expenseView = this.expenseView.value;
        expenseView.amexInfo = amexInfo;
        if (expenseView.amexInfo != null)
            expenseView.amexInfo.shouldImportWithTransaction = shouldImportWithTransaction;
        let saveExpenseResponse = new SaveExpenseResponse(expenseView);

        const receiptView = this.getReceipt(expenseView);

        if (!this.expenseService.validationSaveExpense(expenseView) || receiptView.isMissingReceiptInvalid()) {
            saveExpenseResponse.passed = false;
            this.isLoading.next(false);
            return of(saveExpenseResponse);
        }

        return this.expenseService.saveExpenseStore(expenseView, this.userPreferences.getValue().countryKey, receiptView)
        .pipe(map((response: SaveExpenseResponse) => {
            response.expenseView.validateExpense(true);
            saveExpenseResponse.timeReportStatus = response.timeReportStatus;
            saveExpenseResponse.hasValidationsErrors = response.expenseView.hasValidationsErrors();
            saveExpenseResponse.errors = response.expenseView.errors;
            saveExpenseResponse.softWarning = response.softWarning;
            saveExpenseResponse.expenseView.softWarning = response.softWarning;
            saveExpenseResponse.needRedirect = saveExpenseResponse.errors.some(error => error.includes("You encountered an unexpected error. Please refresh the page and retry you action. If the problem persists, please contact CIO Technology Support."));
            saveExpenseResponse.expenseView = response.expenseView;
            saveExpenseResponse.passed = saveExpenseResponse.errors.length == 0 && response.passed && this.expenseService.validationSaveExpense(response.expenseView) && !saveExpenseResponse.hasValidationsErrors && response.softWarning?.message == '';
            if (saveExpenseResponse.needRedirect) {
                this.logService.showValidationMessage('Validation Error', saveExpenseResponse.errors[0], true);
            }
            if (saveExpenseResponse.hasValidationsErrors && saveExpenseResponse.errors.length != 0) {
                this.logService.showValidationMessage('Validation Error', saveExpenseResponse.errors[0]);
            }
            if (saveExpenseResponse.passed) {
                this.expensesGrid.next(new Array<ExpenseGridItem>());
                this.expenseView.next(new ExpenseView());
                shouldInitialize ? this.loadExpenses() : this.isLoading.next(false);
            } else {
                this.expenseView.next(saveExpenseResponse.expenseView);
                this.isLoading.next(false);
            }
            return saveExpenseResponse;
        }))
    }

    public deleteExpense(expensesIds: string[]): Observable<boolean> {
        this.isLoading.next(true);
        return this.expenseService.deleteExpenseStore(expensesIds, this.actualPeriodEnd, this.userPreferences.getValue().countryKey)
        .pipe(map((result) => {
            if (result) {
                this.logService.showSuccessMessage('Success!', 'The expense was deleted successfully.');
            } else {
                this.logService.logError('Failed to delete expense.', true);
                this.isLoading.next(false);
            }
            return result;
        }));
    }

    public saveExpenseAsCopy(): Observable<SaveExpenseResponse> {
        this.isLoading.next(true);
        let expenseView = this.expenseView.value;
        if (!this.validateExpenseViewForCopy(expenseView)) {
            let response = new SaveExpenseResponse(expenseView);
            response.passed = false;
            this.isLoading.next(false);
            return of(response)
        }

        let receiptView = this.getReceipt(expenseView);

        return this.expenseService.copyExpenseStore(expenseView, receiptView, this.userPreferences.value.countryKey)
        .pipe(map((responseCopy: SaveExpenseResponse) => {
            responseCopy.expenseView.validateExpense(true);
            responseCopy.hasValidationsErrors = responseCopy.expenseView.hasValidationsErrors();
            responseCopy.expenseView.softWarning = responseCopy.softWarning;
            responseCopy.passed = responseCopy.passed && !responseCopy.hasValidationsErrors && this.expenseService.validationSaveExpense(responseCopy.expenseView) && responseCopy.softWarning?.message == '';
            // ToDo: need insert copy?
            responseCopy.expenseView.expenseSource = ExpenseViewSource.Copy;
            if (responseCopy.errors.length > 0)
                this.logService.showValidationMessage('Validation Error', responseCopy.errors[0], true);
            if (responseCopy.passed) {
                if (+responseCopy.expenseView.periodEnd != +this.actualPeriodEnd) {
                    this.globalCacheService.clearExpenseList(responseCopy.expenseView.periodEnd, this.globalCacheService.getUserCountry());
                    this.globalCacheService.resetTripBuffer(this.actualUser.enterpriseId, this.globalCacheService.getUserCountry());
                    responseCopy.needUpdateTimeReportStatus = false;
                }
                this.loadExpenses(true, expenseView.periodEnd);
                this.expenseView.next(new ExpenseView());
            } else {
                this.isLoading.next(false);
                this.expenseView.next(responseCopy.expenseView);
            }
            return responseCopy;
        }))
    }

    public updateFieldExpense(field: ExpenseViewRowElement): Observable<SaveExpenseResponse> {
        // this.isLoading.next(true);
        let expenseView = this.expenseView.value;
        let saveExpenseResponse = new SaveExpenseResponse(expenseView);
        // validation in expense-view-field.ts
        field.validate(field.value);
        if (!field.isValid()) {
            saveExpenseResponse.passed = false;
            // this.isLoading.next(false);
            return of(saveExpenseResponse);
        }
        let receiptView = this.getReceipt(expenseView);

        return this.expenseService.recalculateExpenseStore(expenseView, receiptView, field, this.userPreferences.getValue().countryKey)
        .pipe(map((response: SaveExpenseResponse) => {
            this.expenseView.next(response.expenseView);
            response.expenseView.validateExpense(true);
            this.expenseService.expenseFileChange.next('finished');
            // this.isLoading.next(false);
            return response;
        }));
    }

    public setExpenseView(expenseId: string, isCopy: boolean) {
        this.isLoading.next(true);
        let request = isCopy ? this.expenseService.getExpenseForCopy(expenseId, this.userPreferences.getValue().countryKey) :
                               this.expenseService.getExpense(expenseId, this.userPreferences.getValue().countryKey);
        request.subscribe(expenseViewResponse => {
            this.expenseView.next(expenseViewResponse);
            this.isLoading.next(false);
        });
    }

    public setNewExpenseView(useCorporateCard: boolean, isAmexImportSource: boolean, expenseTypeCode: string, corporateCardExpense: CorporateCardExpenseInput, amexInput: AmexInput) {
        this.isLoading.next(true);
        let request = !isAmexImportSource ?
                        this.expenseService.newExpense(this.actualPeriodEnd, expenseTypeCode, this.userPreferences.getValue().countryKey) :
                    useCorporateCard ?
                        this.expenseService.importCorporateCardExpense(corporateCardExpense, this.userPreferences.getValue().countryKey) :
                        this.expenseService.importAmexExpense(amexInput, this.userPreferences.getValue().countryKey);

        request.subscribe(expenseViewResponse => {
            this.expenseView.next(expenseViewResponse);
            this.isLoading.next(false);
        });
    }

    public resetExpenseView() {
        this.expenseView.next(new ExpenseView());
    }

    public importNextAmexExpense(amexInput: AmexInput): Observable<ExpenseView> {
        this.isLoading.next(true);
        return this.expenseService.importAmexExpense(amexInput, this.userPreferences.getValue().countryKey)
        .pipe(tap(expense => {
            this.expenseView.next(expense);
            this.isLoading.next(false);
        }))
    }

    public updateTrip(tripId: number, tripName: string): Observable<boolean> {
        return this.expenseTripService.updateTripInfo(tripId, tripName)
                .pipe(tap(updateTripResponse => this.globalCacheService.resetTripBuffer(this.actualUser.enterpriseId.toLowerCase(), this.globalCacheService.getUserCountry())))
                .pipe(tap(updateTripResponse => {
                    updateTripResponse ? this.logService.showSuccessMessage('Success', 'Update Success') :
                                         this.logService.showValidationMessage('Error Message', `${updateTripResponse}`);
                }));
    }

    public saveTrip(expenseTripCreateInput: ExpenseTripCreateInput): Observable<boolean> {
        return this.expenseTripService.saveTrip(expenseTripCreateInput)
                .pipe(tap(updateTripResponse => this.globalCacheService.resetTripBuffer(this.actualUser.enterpriseId.toLowerCase(), this.globalCacheService.getUserCountry())))
                .pipe(tap(saveTripResponse => {
                    if (saveTripResponse)
                        this.logService.showSuccessMessage('Success', 'Trip saved Successfully');
                }));
    }

    public applyTrip(expenseTripReviewView: ExpenseTripReviewView): Observable<any> {
        return this.expenseTripService.applyTrip(this.actualPeriodEnd, expenseTripReviewView, this.globalCacheService.getUserCountry())
                .pipe(tap(applyTripResponse => {
                    applyTripResponse ? this.logService.showSuccessMessage('Success', 'Trip saved Successfully') : this.logService.logErrorWithMsgInfo(Trip_Apply_ErrorMessage);
                }));
    }

    public deleteTrip(tripId: string): Observable<any> {
        return this.expenseTripService.deleteTrip(tripId)
                .pipe(tap(deleteTripResponse => this.globalCacheService.resetTripBuffer(this.actualUser.enterpriseId.toLowerCase(), this.globalCacheService.getUserCountry())))
                .pipe(tap(deleteTripResponse => {
                    deleteTripResponse ? this.logService.showSuccessWithMsgInfo(Trip_Apply_SussessMessage) : this.logService.logErrorWithMsgInfo(Trip_Delete_ErrorMessage);
                }));
    }

    public updatePeriodEnd(periodEnd: Date): Observable<Date> {
        this.isLoading.next(true);
        this.actualPeriodEnd = periodEnd;
        return of(this.actualPeriodEnd);
    }

    // ToDo: move to mapper
    private mapExpensesForGrid(expenses: Expense[], isCarPlan: boolean) {
        // Set totals in 0
        this.totalAmountGridInternal = 0;
        this.totalTaxTrueUpValueInternal = 0;
        this.hasPPA = undefined;
        this.expenseTotalErrors = expenses[0]?.expenseTotalErrors;
        this.parentExpenseTotalErorrs = expenses[0]?.parentExpenseTotalError
        this.expenseService.expenses = expenses; 
        return expenses.map(expense => {
                    if (this.hasPPA == undefined && expense.isAdjusted)
                        this.hasPPA = expense.isAdjusted;

                    if (expense.source.value !== 'IQN' || (isCarPlan && expense.type.value !== 'EX05'))
                        this.totalAmountGridInternal += expense.expenseAmount.getAmount();

                    if (expense.expenseAmount.taxTrueUpValue)
                        this.totalTaxTrueUpValueInternal += Number(expense.expenseAmount.taxTrueUpValue);

                    let expenseGridItem = new ExpenseGridItem();
                    expenseGridItem.id = expense.id;
                    expenseGridItem.sequence = expense.sequence;
                    expenseGridItem.source = expense.source;
                    expenseGridItem.status = expense.status;
                    expenseGridItem.from = expense.from;
                    expenseGridItem.to = expense.to;
                    expenseGridItem.assignment = expense.assignment;
                    expenseGridItem.code = expense.expenseTypeCd;
                    expenseGridItem.type = expense.type;
                    expenseGridItem.additionalInformation = expense.additionalInformation;
                    expenseGridItem.receipt = expense.receiptRequirement;
                    expenseGridItem.isAdjusted = expense.isAdjusted;
                    expenseGridItem.expenseAmount = expense.expenseAmount;
                    expenseGridItem.expenseAmount.code = expense.expenseTypeCd;
                    expenseGridItem.expenseAmount.isCarPlan = isCarPlan;
                    expenseGridItem.isReversed = expense.expenseAmount.amount == 0 ? true : false;
                    expenseGridItem.frequentTrip = expense.frequentTrip;
                    expenseGridItem.isEditable = expense.canBeEdited;
                    expenseGridItem.currency = expense.currency;
                    expenseGridItem.hasFederalWBSInd = expense.hasFederalWBSInd;
                    expenseGridItem.hasExpenseTotalErrorInd = expense.hasExpenseTotalErrorInd;
                    expenseGridItem.expenseTotalErrors = expense.expenseTotalErrors;
                    expenseGridItem.hasissues = expense.hasissues;
                    expenseGridItem.decimalPlaces = expense.decimalPlaces;
                    expenseGridItem.expenseProjectChargeHistoryToolTipText = expense.expenseProjectChargeHistoryToolTipText;
                    expenseGridItem.expenseChargeHistoryToolTipText = expense.expenseChargeHistoryToolTipText;
                    expenseGridItem.taxReimbursableBool = expense.taxReimbursableBool;
                    expenseGridItem.exchangeRate = expense.exchangeRate;
                    expenseGridItem.gSTAmount = expense.gSTAmount;
                    expenseGridItem.amountWithoutTax = expense.amountWithoutTax;
                    expenseGridItem.selfContainedDays = expense.selfContainedDays;
                    expenseGridItem.hotelDays = expense.hotelDays;
                    expenseGridItem.foreignAmount = expense.foreignAmount;
                    expenseGridItem.escalated = expense.isEscalatedExpense;
                    expenseGridItem.selfCertification = expense.selfCertification;
                    expenseGridItem.postPayEscalatedInd=expense.postPayEscalatedInd;
                    expenseGridItem.analyticsAudit=expense.analyticsAudit;
                    expenseGridItem.analyticsAuditReasons = expense.analyticsAuditReasons;
                    return expenseGridItem;
                }).sort(function (a, b) {
                    return parseInt(a.sequence.value, 10) - parseInt(b.sequence.value, 10);
                })
    }

    public groupByTrip(dataSource) {
        let groups = this.groupBy(dataSource, item => item.frequentTrip);
        groups.forEach(group => group.sort(function (a, b) {
            return a.from - b.from;
        }));
        groups.sort(function (a, b) {
            if (a[0].frequentTrip.id == null) {
                return 1;
            }
            if (b[0].frequentTrip.id == null) {
                return -1;
            }
            return a[0].from - b[0].from;
        });

        let dataSourceGroupByTrip = new Array<ExpenseGridItem>();
        groups.forEach(group => {
            dataSourceGroupByTrip.push(this.creatExpenseTripInfo(group));
            dataSourceGroupByTrip.push.apply(dataSourceGroupByTrip, group);
        });
        return dataSourceGroupByTrip;
    }

    public groupBy(array, fn) {
        let groups = {};
        array.forEach(o => {
            let group = JSON.stringify(fn(o));
            groups[group] = groups[group] || [];
            groups[group].push(o);
        });
        return Object.keys(groups).map(group => groups[group]);
    }

    public creatExpenseTripInfo(group) {
        let expenseTrip = new ExpenseGridItem();
        const emptyField = new ExpenseFieldWithValidationResult();

        emptyField.value = '',
        emptyField.isValid = true

        expenseTrip.sequence = emptyField;
        expenseTrip.source = emptyField;
        expenseTrip.status = emptyField;
        expenseTrip.additionalInformation = new ExpenseFieldWithValidationResult();
        expenseTrip.additionalInformation.value = '',
        expenseTrip.additionalInformation.isValid = true

        let totalAmount = 0;
        group.forEach(expense => { totalAmount = totalAmount + expense.expenseAmount.amount; });
        var tripFromDateGroup = JSON.parse(JSON.stringify(group));
        var tripToDateGroup = JSON.parse(JSON.stringify(group));
        expenseTrip.from = tripFromDateGroup.sort(function (a, b) {
            return new Date(a.from.value).getTime() - new Date(b.from.value).getTime();
        })[0].from;
        tripToDateGroup.sort(function (a, b) {
            return new Date(b.to.value).getTime() - new Date(a.to.value).getTime();
        })
        expenseTrip.to = tripToDateGroup[0].to.value == null ? tripFromDateGroup[tripFromDateGroup.length - 1].from : tripToDateGroup[0].to;
        if (!group[0].assignment) group[0].assignment = { key: '', value: '', isValid: false, tooltip: '' } as Option;
        expenseTrip.assignment = JSON.parse(JSON.stringify(group[0].assignment));
        expenseTrip.assignment.value = group[0].frequentTrip.name;
        expenseTrip.assignment.key = '';
        expenseTrip.assignment.isValid = true;
        expenseTrip.expenseAmount = new ExpenseAmount();
        expenseTrip.expenseAmount.amount = totalAmount;
        expenseTrip.expenseAmount.decimalPlaces = group[0].decimalPlaces;
        expenseTrip.expenseAmount.isValid = true;
        expenseTrip.additionalInformation.value = group[0].frequentTrip.reason;
        expenseTrip.receipt = new ReceiptRequirement();
        expenseTrip.frequentTrip = group[0].frequentTrip;
        expenseTrip.hasFederalWBSInd = group[0].hasFederalWBSInd;
        return expenseTrip;
    }

    // Move this into a validator and refactor
    private validateExpenseViewForCopy(expenseView: ExpenseView): boolean {
        let isValid = true;
        let errorDisplayName = new Array<any>();
        expenseView.categories
            .forEach(category =>
                category.forEach(row =>
                    row.elements.forEach(f => {
                        this.getErrorDisplayName(f.validate(f.value, true) ? f.validate(f.value, true) : null, errorDisplayName);
                        if (!f.isValid())
                            isValid = false;
                    })
                )
            );
        if (!isValid && errorDisplayName.length > 0) {
            let fields = new Array<any>();
            if (errorDisplayName.length === 1) {
                this.logService.logWarning('Please complete: ' + errorDisplayName[0].label + ' field.', true, 'The Expense is not ready to save.');
            }
            if (errorDisplayName.length >= 2) {
                errorDisplayName.forEach(element => {
                    fields.push(element.label);
                });
                this.logService.logWarning('Please complete: ' + fields.join(", ").replace(/, ([^,]*)$/, ' and $1') + ' fields.', true, 'The Expense is not ready to save.');
            }
        } else if (!isValid) {
            this.logService.logWarning('Please complete all the required fields', true, 'The Expense is not valid.');
        }
        return isValid;
    }

    // Move this into a validator and refactor
    private getErrorDisplayName(error: ExpenseViewFieldValidationError[], errorDisplayName: Array<any>) {
        if (error != null) {
            error.forEach(e => {
                if ((e.type == 'BlockSaveExpenseError' && e.errorMessage != null && e.label != null && e.label != '')
                    || (e.type == 'required' && e.errorMessage != null && e.label != null && e.label != '')
                    || (e.type == 'BlockError' && e.errorMessage != null && e.label != null && e.label != '')) {
                    if (!errorDisplayName.some(err => err.label == e.label)) {
                        errorDisplayName.push({
                            label: e.label,
                            type: e.type
                        });
                    }
                }
            });
        }
    }

    private getReceipt(expenseView: ExpenseView): ReceiptViewField {
        return expenseView.categories.map(expenseRow => expenseRow)
                .reduce((prev, curr) => prev.concat(curr))
                .map(expenseViewRow => expenseViewRow.elements)
                .reduce((prev, curr) => prev.concat(curr))
                .find(expenseViewFieldRow => expenseViewFieldRow instanceof ReceiptViewField) as ReceiptViewField;
    }

    private isOlderThanAYear(periodEnd: Date):boolean {
      let currentDate = new Date();
        let limitDate = new Date();
        limitDate.setFullYear(currentDate.getFullYear()-1)
        return  this.getShortDate(periodEnd) < this.getShortDate(limitDate);
    }

    private getShortDate(shortdate: Date): Number {
      return shortdate.setHours(0,0,0,0);
    }

    public reverseExpense(expenseIds: string[], countryKey: string){
      return this.expenseService.reverseExpenseStore(expenseIds, this.actualPeriodEnd)
    }

    //Method to handle default date while creating EX17(Multiple entry template).
    public formatFromDateForEX17(expenseResp: ExpensesResponse) {
        if (expenseResp && Array.isArray(expenseResp.expenses) && expenseResp.expenses.length > 0) {
            expenseResp.expenses.some(exp => {
                if (exp) {
                    let dateData = exp?.from?.value?.split(' ');
                    if (dateData && exp?.expenseTypeCd == 'EX01' && Date.parse(dateData[0]) == Date.parse('1/1/1900')) {
                        exp.from.value = null;
                    }
                }
            });
        }
    }

    /**
     * Resets the value(behaviourSubject) and avoids old values getting displayed in expense grid 
       before the new value is displayed
     */
    public resetExpenseGridInitializationData(): void{
        if(this.expenseGridInitializationData.value){
            this.expenseGridInitializationData.next(null);
        }
    }
}
