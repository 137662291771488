import { MyteFile } from '../myte-file';
import { MissingReceiptInfo } from '../../clients/expense-api-client';

export class AdditionalDocumentation extends MyteFile {
    public periodEnd: Date;
}

export class TimeReportDocumentation {
    public additionalDocuments: AdditionalDocumentation[] = [];
    public warningMessages: string[] = [];
    public missingReceiptInfo: MissingReceiptInfo;
}

