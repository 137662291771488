import ExpenseViewRowElement from './expense-view-row-element';
import Gift from '../expense-gift';
import { ExpenseViewFieldValidationError } from '../expense-view-field-validation-error';
import { BlockErrorKey } from '@shared/constant';

export default class GiftField implements ExpenseViewRowElement {
    visible: boolean = true;
    elementType: string;
    category: number = 3;
    row: number = 9;
    uiType = 'Gift';
    key = 'Gift';
    order ? = 0;
    size ? = 100;
    currency: string;
    value: Gift[];
    errors: ExpenseViewFieldValidationError[] = [];

    validate(value: any, isTriggeredBySave?: boolean): ExpenseViewFieldValidationError[] {
        this.errors = [];
        if (isTriggeredBySave) {
            value.forEach(entry => {
                if (entry.validations.length) {
                    entry.validations.forEach(validation => {
                        this.errors.push(new ExpenseViewFieldValidationError(validation.type, validation.value));
                    })
                }
            })
        }
        return this.errors;
    }


    validateFieldErrors() {

    }

    isValid(): boolean {
        return !this.errors.length;
    }

    hasRequiredError(): boolean{
        return false;
    }

    getErrorMessages(): string[] {
        return this.errors.filter(err => err.type === BlockErrorKey && err.errorMessage)?.slice(-1)?.map(err => {
            return err['errorMessage'];
        });
    }
}
