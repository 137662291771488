import ExpenseViewRowElement from './expense-view-row-element';
import { Receipt } from '../receipt/expense-receipt';
import { MissingReceiptInfo } from '@sharedClients/expense-api-client';
import { ExpenseViewFieldValidationError } from '../expense-view-field-validation-error';

export default class ReceiptViewField implements ExpenseViewRowElement {
    visible: boolean = false;
    category: number = 100;
    row: number = 0;
    key = 'Receipts';
    uiType = 'receipts';
    value: Receipt[] = [];
    order ? = 0;
    size ? = 100;
    warningMessages: string[] = [];
    elementType: 'text';
    missingReceiptInfo: MissingReceiptInfo;
    receiptUploadMessage: string;

    validate(value: string): ExpenseViewFieldValidationError[] {
        return [];
    }

    validateFieldErrors() {

    }

    isMissingReceiptInvalid(): boolean {
        return this.missingReceiptInfo.missingReceiptChecked && (this.missingReceiptInfo.receiptComments =='' || this.missingReceiptInfo.receiptComments == null) ;
    }

    isValid(): boolean {
        if (this.isMissingReceiptInvalid()){
            this.visible = true;
            return false;
        } 
        else {
            this.visible = false;
            return true;
        }
    }

    hasRequiredError(): boolean {
        return this.isMissingReceiptInvalid();
    }

    getErrorMessages(): string[]{
        return [];
    }
}
