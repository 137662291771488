import { Injectable, Optional, SkipSelf, Self } from '@angular/core';
import { OAuth2Service } from './oauth2.service';
import { environment } from '../../../environments/environment';
import { MsalService } from '@azure/msal-angular';

@Injectable(
  {providedIn: 'root'}
)
export class AuthService {
  private storage: any;
  private currentAccount: any;

  constructor(
    @Optional() private msalService: MsalService,
    private oauth2Service: OAuth2Service,
  ) {
    this.storage = sessionStorage;
  }

  public setup() {
    if (!environment.production) {
      this.oauth2Service.setup();
      if (!this.IsUserAuthenticated()) {
        this.storeRoute();
      }
    }
  }

  public IsUserAuthenticated(): boolean {
    if (environment.production) {
      const currentAccounts = this.msalService.instance.getAllAccounts();
      return currentAccounts.length === 1 ? this.msalService.instance.getAccountByHomeId(currentAccounts[0].homeAccountId) != null : false;
    } else if (environment.name === 'Local') {
      return this.oauth2Service.IsAuthenticated();
    }
  }

  public getUserProfile() {
    if (environment.production) {
      this.currentAccount = this.msalService.instance.getAllAccounts()[0];
      return this.currentAccount;
    } else if (environment.name === 'Local') {
      this.currentAccount = this.oauth2Service.getUserProfile();
      return this.currentAccount;
  }
}
  
  public logout(): void {
    if(environment.production) {
      this.msalService.logoutRedirect(
        {account: this.currentAccount}
      ) 
    }
    else{
      this.oauth2Service.logout();
    } 
  }

  private storeRoute() {
    if (location.pathname == '/') {
      this.storage.setItem('route', location.href + '#');
    } else {
      this.storage.setItem('route', location.href);
    }
  }
}
