import { LocationOne } from './location-one';
import { LocationValue } from './location-value';

export class Country extends LocationValue {
  public locationOne: Array<LocationOne>;

  /**
   *
   */
  constructor(key?: string, description?: string) {
    super(key, description);
    this.locationOne = new Array<LocationOne>();
  }
}
