import { Component, OnInit, Input, Output, EventEmitter, HostListener, ViewChild, ElementRef, AfterViewInit,Renderer2, ChangeDetectionStrategy, SecurityContext } from '@angular/core';
import { MyteFile, ReceiptFileType } from '../../../shared/models/myte-file';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NavigatorBrowserUtils } from '@shared/utils/navigatorBrowser.utils';
import { ReceiptsService } from '@shared/services/receipts/receipts.service';


@Component({
  selector: 'myte-file-viewer',
  templateUrl: './file-viewer.component.html',
  styleUrls: ['./file-viewer.component.sass'],
  changeDetection:ChangeDetectionStrategy.OnPush
})
export class FileViewerComponent implements OnInit {
  @Input() files: MyteFile[] = []
  @Input() showingIndexFile = 0;
  @Output() onClose = new EventEmitter<void>();
  @ViewChild('image') image: ElementRef;
  @ViewChild('ieID') ieID:ElementRef;
  public myrenderer: Renderer2;
  public rotationDeg = 0;
  public isIE = NavigatorBrowserUtils.getBrowserName() == "ie";

  constructor(public sanitizer: DomSanitizer,public receiptservice:ReceiptsService) {
   


   }

  public ngOnInit(): void {
    document.body.style.overflow = 'hidden';
  }

  @HostListener('document:keyup', ['$event'])
  public handleKeyboardEvent(event: KeyboardEvent) {
    switch (event.key) {
      case "Escape":
        this.close();
        break;
      case "Esc":
        this.close();
        break;
      case "Right":
        this.nextFile();
        break;
      case "ArrowRight":
        this.nextFile();
        break;
      case "Left":
        this.previousFile();
        break;
      case "ArrowLeft":
        this.previousFile();
        break;
    }
  }

  public getPdfUrl(): SafeResourceUrl {
    let source = this.files[this.showingIndexFile].isLocalFile() ? this.files[this.showingIndexFile].getFileData() :
    this.files[this.showingIndexFile].fileUrl;
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.sanitizer.sanitize(SecurityContext.URL,source));
  }
  public changeIE()
  {
    this.isIE=!this.isIE;
  }

  public isLocalFile(){
    if(this.files[this.showingIndexFile].isLocalFile())
      return true;
    return false;
  }

  public getPdfUrl1(): SafeResourceUrl {
    let source = this.files[this.showingIndexFile].isLocalFile() ? this.files[this.showingIndexFile].getFileData() :
    this.files[this.showingIndexFile].fileUrl;
    if(this.files[this.showingIndexFile].isLocalFile())
    {
      let findindex=source.lastIndexOf("base64");
      findindex=+6;
      let onlyb64=source.substring(12,source.length);
      let result= (this.sanitizer.bypassSecurityTrustResourceUrl(onlyb64) as any).changingThisBreaksApplicationSecurity;
      let urlfinal="application/pdf;base64 " + result;
      return urlfinal;
    }
    return this.sanitizer.bypassSecurityTrustResourceUrl(source);
  }

  public nextFile(): void {
    if (this.showingIndexFile == this.files.length - 1) return;
    this.clearImageStyle();
    this.showingIndexFile++;
  }

  public previousFile(): void {
    if (this.showingIndexFile == 0) return;
    this.clearImageStyle();
    this.showingIndexFile--;
  }

  public download(): void { 
    let urlParameter = this.files[this.showingIndexFile].fileUrl.split('&');
    let expires = parseInt (urlParameter[1].substring(8));
    let expireTime = new Date(expires*1000);
    let utcExpireTime = this.convertToUTCTime(expireTime);
    let nowTime = new Date();
    let utcNowTime = this.convertToUTCTime(nowTime);
    var intervalTime = (utcExpireTime.getTime()-utcNowTime.getTime())/1000/60;
    if(intervalTime < 1)
    {
      this.receiptservice.getReceiptUrl(this.files[this.showingIndexFile].id)
      .subscribe(result=>{
        open(result);
        this.files[this.showingIndexFile].fileUrl = result;
      });
    }
    else
    {
      open(this.files[this.showingIndexFile].fileUrl); 
    }
  }
  
  public convertToUTCTime(time:Date){
    let utcTimeString = (time.getUTCFullYear()) + "-"
    + (time.getUTCMonth() + 1) + "-"
    + (time.getUTCDate()) + " "
    + (time.getUTCHours()) + ":"
    + (time.getUTCMinutes()) + ":"
    + (time.getUTCSeconds());
    let utcTimeDate = new Date(utcTimeString);
    return utcTimeDate;    
  }

  public close(): void {
    document.body.style.overflow = 'auto';
    this.onClose.emit();
  }

  public rotateRight(): void {
    if (this.rotationDeg == 0) {
      this.rotationDeg = 90;
    } else {
      this.rotationDeg += 90;
    }
  }

  public rotateLeft(): void {
    if (this.rotationDeg == 0) {
      this.rotationDeg = 270;
    } else {
      this.rotationDeg -= 90;
    }
  }

  public getRotation(): string {
    return 'rotate(' + this.rotationDeg + 'deg)';
  }

  public zoomIn(): void {
    let actualWidth = this.image.nativeElement.clientWidth;
    this.image.nativeElement.style.width = (actualWidth + 100) + "px";
    this.image.nativeElement.style.maxWidth = "none";
    this.image.nativeElement.style.maxHeight = "none";
  }

  public zoomOut(): void {
    let actualWidth = this.image.nativeElement.clientWidth;
    if (actualWidth < 300)
      return;
      this.image.nativeElement.style.width = (actualWidth - 100) + "px";
  }

  private clearImageStyle(): void {
    if (this.files[this.showingIndexFile].isImage()) {
      this.image.nativeElement.style.width = "auto";
      this.image.nativeElement.style.maxWidth = "100%";
      this.image.nativeElement.style.maxHeight = "100%";
      this.rotationDeg = 0;
    }
  }
}
