import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationExtras, Router } from '@angular/router';
import { NgbDate, NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DateformatPipe } from '@shared/pipes/dateformat.pipe';
import { FormatDateType } from '@shared/utils/constants';
import moment from 'moment';
import { filter } from 'rxjs/operators';
import { ExpenseService } from '../../../myte-expenses/shared/services/expense/expense.service';
import { TimeSheetService } from '../../../myte-time/shared/services/timesheet/timesheet.service';
import DropdownItem from '../../../shared/models/controls/dropdown/dropdown-item';
import { FocusElement } from '../../../shared/models/controls/focus-element/focus-element';
import { TimePeriodPipe } from '../../../shared/pipes/time-period.pipe';
import { GlobalCacheService } from '../../../shared/services/cache/global-cache.service';
import { GlobalEventsService } from '../../../shared/services/events/global-events.service';
import { UserService } from '../../../shared/services/user/user.service';
import { DatePickerUtils } from '../../../shared/utils/datePicker.utils';
import PeriodChangerDate from './period-changer-date';
import { SubordinatesMode } from '@shared/models/subordinates/subordinate-mode';
@Component({
    selector: 'myte-period-changer',
    templateUrl: './period-changer.component.html',
    styleUrls: ['./period-changer.component.sass']
})
export class PeriodChangerComponent implements OnInit {
    @Input() userActiveDate: Date;
    @Output() isAutoSave = new EventEmitter();
    public currentDate: PeriodChangerDate;
    public DateDropDown: any;
    public dropdownDates: DropdownItem[] = [];
    public stringToday: string;
    public focusPreviousPeriod: boolean = false;
    public focusNextPeriod: boolean = false;
    public focusCalendar: boolean = false;
    public focusDropdown: boolean = false;
    public arialabel = "Press Enter to select a different period";

    constructor(
        public userService: UserService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private cacheService: GlobalCacheService,
        private timeSheetService: TimeSheetService,
        private globalEventsService: GlobalEventsService,
        private expenseService: ExpenseService,
        private i18n: NgbDatepickerI18n
    ) { }

    public ngOnInit(): void {
        this.cacheService.clearAuditeeJoiningDate();
        this.i18n.getWeekdayLabel = function (weekday) {
            return DatePickerUtils.getWeekDayShortNameByLanguage(weekday);
        };
        this.i18n.getMonthShortName = function (month) {
            return DatePickerUtils.getMonthShortNameByLanguage(month);
        };
        this.currentDate = new PeriodChangerDate(this.cacheService.getPeriodEnd());

        this.router.events
            .pipe(filter((event: any) => {
                return event instanceof NavigationEnd && this.currentDate.toString() != new PeriodChangerDate(this.cacheService.getPeriodEnd()).toString();
            }))
            .subscribe(() => {
                    this.onDateSelectedFromDropdown(this.cacheService.getPeriodEnd());
            });
        this.DateDropDown = {
            year: this.currentDate.year, month: this.currentDate.month + 1, day: this.currentDate.day
        };
        let user = this.userService.getUser();
        this.focusPreviousPeriod = this.cacheService.getFocusElement(user.enterpriseId + FocusElement.FOCUS_PREVIOUS_PERIOD);
        this.focusNextPeriod = this.cacheService.getFocusElement(user.enterpriseId + FocusElement.FOCUS_NEXT_PERIOD);
        this.focusCalendar = this.cacheService.getFocusElement(user.enterpriseId + FocusElement.FOCUS_CALENDAR);
        this.focusDropdown = this.cacheService.getFocusElement(user.enterpriseId + FocusElement.FOCUS_DROPDOWN);
        this.cacheService.clearFocusElement(user.enterpriseId + FocusElement.FOCUS_PREVIOUS_PERIOD);
        this.cacheService.clearFocusElement(user.enterpriseId + FocusElement.FOCUS_NEXT_PERIOD);
        this.cacheService.clearFocusElement(user.enterpriseId + FocusElement.FOCUS_CALENDAR);
        this.cacheService.clearFocusElement(user.enterpriseId + FocusElement.FOCUS_DROPDOWN);
        this.refreshDates();
        this.today();
    }

    isDisabled = (date: NgbDate, currentMonth: number) => date.month !== currentMonth;

    public previousPeriod(): void {
        this.timeSheetService.clearSoftWarningErrorsCacheTemp(this.cacheService.getPeriodEnd()).subscribe();
        this.currentDate = this.adjustActiveDate(this.cacheService.getPeriodEnd(), false, true);
        this.DateDropDown = {
            year: this.currentDate.year, month: this.currentDate.month + 1, day: this.currentDate.day
        };
        this.setFocusElement(FocusElement.FOCUS_PREVIOUS_PERIOD);
        this.isAutoSave.emit();
        this.cacheService.saveExpenseListShowStatus(false);
        this.refreshDates(true);
        this.expenseService.setIsFromExpensePopupCloseWithoutAmex(false);
        this.cacheService.resetDataStorage();
    }

    public nextPeriod(): void {
        this.timeSheetService.clearSoftWarningErrorsCacheTemp(this.cacheService.getPeriodEnd()).subscribe();
        this.currentDate = this.currentDate.nextPeriod();
        this.DateDropDown = {
            year: this.currentDate.year, month: this.currentDate.month + 1, day: this.currentDate.day
        };
        this.setFocusElement(FocusElement.FOCUS_NEXT_PERIOD);
        this.isAutoSave.emit();
        this.cacheService.saveExpenseListShowStatus(false);
        this.refreshDates(true);
        this.expenseService.setIsFromExpensePopupCloseWithoutAmex(false);
        this.cacheService.resetDataStorage();
    }

    public onDateSelectFromCalendar(date: NgbDateStruct): void {
        let calendarDate = this.adjustActiveDate(date, true, false);
        let newCurrentDate = new PeriodChangerDate(
            new TimePeriodPipe().transform(calendarDate)
        );
        if (this.currentDate.toString() != newCurrentDate.toString()) {
            this.currentDate = newCurrentDate;
            this.DateDropDown = {
                year: this.currentDate.year, month: this.currentDate.month + 1, day: this.currentDate.day
            };
            this.setFocusElement(FocusElement.FOCUS_CALENDAR);
            this.isAutoSave.emit();
            this.refreshDates(true);
        }
        this.cacheService.resetDataStorage();
    }

    public onDateSelectedFromDropdown(date: any): void {
        date = this.adjustActiveDate(date, false, false);
        let newCurrentDate = new PeriodChangerDate(
            new TimePeriodPipe().transform(date as Date)
        );
        let currentDate = new PeriodChangerDate(this.cacheService.getPeriodEnd());
        if (currentDate.toString() != newCurrentDate.toString()) {
            this.currentDate = newCurrentDate;
            this.DateDropDown = {
                year: this.currentDate.year, month: this.currentDate.month + 1, day: this.currentDate.day
            };
            this.setFocusElement(FocusElement.FOCUS_DROPDOWN);
            this.isAutoSave.emit();
            this.refreshDates(true);
        }
        this.cacheService.resetDataStorage();
    }

    public hideOptions(event?) { }

    public onClickToday(): void {
        let date: NgbDateStruct = {
            year: new Date().getFullYear(),
            month: new Date().getMonth() + 1,
            day: new Date().getDate()
        }
        this.onDateSelectFromCalendar(date);
    }

    public today(): void {
        let monthByLanguage = new DateformatPipe().transform(new Date(), FormatDateType.GetFullMonthByLanguage);
        let now = moment().format('MMMM DD, YYYY');
        let month = now.substring(0, now.indexOf(' '));
        this.stringToday = 'Today: ' + now.replace(month, monthByLanguage);
    }

    private refreshDates(updateCurrentPeriod?: boolean): void {
        this.calculatePeriodsForDropdown();
        if (updateCurrentPeriod) {
            this.globalEventsService.dispatchPeriodChangedEvent(this.currentDate.getDate());
            const currentPeriodEnd = this.currentDate.getDate();
            this.cacheService.setPeriodEnd(currentPeriodEnd);
            this.router.routeReuseStrategy.shouldReuseRoute = () => false;
            if (this.activatedRoute.snapshot.queryParamMap.get('countryKey')) {
                this.router.navigate(
                    [this.router.url.split('?').shift()],
                    {
                        queryParams: {
                            countryKey: this.activatedRoute.snapshot.queryParamMap.get('countryKey'),
                            periodEnd: encodeURIComponent(btoa(currentPeriodEnd.toISOStringMyTE()))
                        }
                    } as NavigationExtras
                );
            } else {
                this.router.navigate(
                    [this.router.url.split('?').shift()],
                    {
                        queryParams: {
                            periodEnd: encodeURIComponent(btoa(currentPeriodEnd.toISOStringMyTE())),
                            viewMode: encodeURIComponent(btoa(this.cacheService.getSubordinateMode().toString()))
                        }
                    } as NavigationExtras
                );
            }
        }
    }

    private calculatePeriodsForDropdown(): void {
        let current = new Date();
        for (let i = 1; i < 12; i++) {
            let aux = new TimePeriodPipe().transform(current);
            this.dropdownDates.push(new PeriodChangerDate(aux).toDropdownItem());
            current = this.backOnePeriod(current, aux);
        }
    }

    private backOnePeriod(current: Date, aux: Date): Date {
        if (current.getDate() <= 15)
            return new Date(aux.getFullYear(), aux.getMonth() - 1, 16);
        else
            return new Date(aux.getFullYear(), aux.getMonth(), 1);
    }

    private setFocusElement(element: string): void {
        var user = this.userService.getUser();
        this.cacheService.setFocusElement(user.enterpriseId + FocusElement.FOCUS_PREVIOUS_PERIOD, false);
        this.cacheService.setFocusElement(user.enterpriseId + FocusElement.FOCUS_NEXT_PERIOD, false);
        this.cacheService.setFocusElement(user.enterpriseId + FocusElement.FOCUS_CALENDAR, false);
        this.cacheService.setFocusElement(user.enterpriseId + FocusElement.FOCUS_DROPDOWN, false);
        this.cacheService.setFocusElement(user.enterpriseId + element, true);
    }

    private adjustActiveDate(current: any, isCalender: boolean, isPrevious: boolean): any {
        let formatDate = new Date(current.year, current.month - 1, current.day);
        current = isCalender ? formatDate : current;
        let currentPeriodEnd = new TimePeriodPipe().transform(current);
        let startDate = currentPeriodEnd.getDate() > 15 ?
            new Date(currentPeriodEnd.getFullYear(), currentPeriodEnd.getMonth(), 16) :
            new Date(currentPeriodEnd.getFullYear(), currentPeriodEnd.getMonth(), 1);

        if(this.cacheService.isSubordinateModeSupervisor() && this.cacheService.getAuditeeDate() === null){
            this.cacheService.setAuditeeDate(this.userActiveDate);
        }

        if (this.cacheService.isSubordinateModeSupervisor() && this.userActiveDate !== this.cacheService.getAuditeeDate()){
            this.userActiveDate = this.cacheService.getAuditeeDate();
        }

        let checkActiveDate = this.userActiveDate < startDate;
        if (isPrevious)
            return checkActiveDate ? this.currentDate.previousPeriod() : this.currentDate;
            
        return checkActiveDate ? current : this.userActiveDate;
    }
}
