import DropdownItem from '@shared/models/controls/dropdown/dropdown-item';
import { LocationValue } from './location-value';


export class LocationOnes {
  public locationsOne: LocationValue[];
  public locationDropdown: DropdownItem[];
  public isReasonForTravelLocationEnable: boolean;
}

export class LocationOne extends LocationValue {
  public LocationTwo: Array<LocationValue>;

  constructor() {
    super();
    this.LocationTwo = new Array<LocationValue>();
  }
}
