import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, catchError, mergeMap, finalize } from 'rxjs/operators';
import { LogService } from '../../../shared/services/log/log.service';
import { GlobalCacheService } from '../../../shared/services/cache/global-cache.service';
import { UserService } from '../../../shared/services/user/user.service';
import { ChargeCodeApiClient, ProjectInfoResult, ProjectDescendantTreeNode, NetworkHierarchy, AddedProjectOutput, ProjectBDLookupDtoForDisplay } from '../../../shared/clients/chargecode-api-client';
import { ChargeCodeRelated } from '../../../shared/models/charge-code/charge-code-related';
import { ProjectListInput, ProjectDisplayList, ClientSearchDto } from '../../../shared/clients/chargecode-api-client';
import { MyteBaseService } from '../../../shared/services/myte-base.service';
import { GlobalEventsService } from '../../../shared/services/events/global-events.service';
import { TimeReportUtils } from '../../../shared/utils/timeReport.utils';
import { PeriodEndService } from '../../../shared/services/periodend/periodend.service';
import { ChargeCodeUsedFor, ChargeCodes } from '@shared/models/charge-code/charge-codes.model';
import { ChargeCodesMapper } from './chargecodes.mapper';
import { DataStore } from '../../../app-shell/data-store';
import { Charge_Code_Add_SuccessMessage, Charge_Code_Add_ErrorMessage, Charge_Code_Delete_SuccessMessage, Charge_Code_Delete_ErrorMessage, BD_Add_ErrorMessage, BD_Add_SuccessMessage } from '@shared/myte-static-message';
import { ChargeCodeInfo } from '@shared/models/charge-code/charge-code-info';

@Injectable({
  providedIn: 'root'
})
export class ChargecodesService extends MyteBaseService {
  constructor(
    public logService: LogService,
    public userService: UserService,
    public eventService: GlobalEventsService,
    private cacheService: GlobalCacheService,
    private api: ChargeCodeApiClient,
    private periodEndService: PeriodEndService) {
      super(userService,cacheService,eventService,logService)
    }

  public getChargeCodesObservable(chargeCodeUsedFor: ChargeCodeUsedFor, addedProjectOutput: AddedProjectOutput = undefined): Observable<ChargeCodes> {
    const getChargeCodesObservable = this.api.getProjects(this.userEid, this.periodEndService.getActivePeriod(), chargeCodeUsedFor.toString(), this.supervisorEid, this.viewMode)
      .pipe(map((projectInfoResult: ProjectInfoResult) => {
        return ChargeCodesMapper.mapAPIChargeCodes(projectInfoResult, addedProjectOutput);
      }))
      .pipe(catchError((error: any) => {
        this.logService.logError(error, true);
        return of(new ChargeCodes());
      }));
    const getChargeCodesCache = this.cacheService.handleChargeCodes(getChargeCodesObservable, this.userEid, this.periodEndService.getActivePeriod());
    return chargeCodeUsedFor !== ChargeCodeUsedFor.Both ? getChargeCodesObservable : getChargeCodesCache;
  }

  public getChargeCodesShell(chargeCodes: Observable<ChargeCodes>): DataStore<ChargeCodes> {
    if (!this.cacheService.chargeCodesStorage || (this.cacheService.chargeCodesStorage && this.cacheService.chargeCodesStorage.refresh)) {
      const chargeCodesShell: ChargeCodes = new ChargeCodes();
      this.cacheService.chargeCodesStorage = new DataStore(chargeCodesShell);
      this.cacheService.chargeCodesStorage.load(chargeCodes);
    }
    return this.cacheService.chargeCodesStorage;
  }

  public addChargeCodeObservable(addChargeCode: string, chargeCodes: ChargeCodes): Observable<ChargeCodes> {
    if (!addChargeCode) return of(undefined);
    this.globalEventsService.dispatchShowGlobalSpinnerEvent(true);
    return this.api.addProject(this.userEid, this.periodEndService.getActivePeriod(), addChargeCode, this.supervisorEid, this.viewMode, '')
      .pipe(mergeMap((response: AddedProjectOutput) => {
        if (response) {
          if (response.status === 'NoChange')
            if (chargeCodes) return of(chargeCodes);
          if (response.status === 'Saved')
            this.logService.showSuccessWithMsgInfo(Charge_Code_Add_SuccessMessage);
          if (this.cacheService.chargeCodesStorage)
            this.cacheService.chargeCodesStorage.refresh = true;
          this.cacheService.resetChargeCodes();
          this.cacheService.resetTimeSheet();
          return this.getChargeCodesObservable(ChargeCodeUsedFor.Both, response);
        } else {
          this.logService.logErrorWithMsgInfo(Charge_Code_Add_ErrorMessage);
          return of(undefined);
        }
      }))
      .pipe(catchError((error: any) => {
        this.logService.logErrorWithMsgInfo(Charge_Code_Add_ErrorMessage);
        return of(undefined);
      }))
      .pipe(finalize(() => {
        this.globalEventsService.dispatchShowGlobalSpinnerEvent(false);
      }));
  }

  public deleteChargeCodeObservable(deleteChargeCodes: Array<string>): Observable<ChargeCodes> {
    if (!deleteChargeCodes || (deleteChargeCodes && deleteChargeCodes.length === 0)) return of(undefined);
    this.globalEventsService.dispatchShowGlobalSpinnerEvent(true);
    return this.api.deleteProjects(this.userEid, this.periodEndService.getActivePeriod(), this.supervisorEid, this.viewMode, deleteChargeCodes)
      .pipe(mergeMap((response: boolean) => {
        if (response) {
          this.logService.showSuccessWithMsgInfo(Charge_Code_Delete_SuccessMessage);
          if (this.cacheService.chargeCodesStorage)
            this.cacheService.chargeCodesStorage.refresh = true;
          this.cacheService.updateChargeCodeCacheForDelete(deleteChargeCodes);
          this.cacheService.resetTimeSheet();
          return this.getChargeCodesObservable(ChargeCodeUsedFor.Both);
        } else {
          this.logService.logErrorWithMsgInfo(Charge_Code_Delete_ErrorMessage);
          return of(undefined);
        }
      }))
      .pipe(catchError((error: any) => {
        this.logService.logErrorWithMsgInfo(Charge_Code_Delete_ErrorMessage);
        return of(undefined);
      }))
      .pipe(finalize(() => {
        this.globalEventsService.dispatchShowGlobalSpinnerEvent(false);
      }));
  }

    public deleteChargeCodeStore(deleteChargeCodes: Array<string>): Observable<ChargeCodes> {
        if (!deleteChargeCodes || (deleteChargeCodes && deleteChargeCodes.length === 0)) return of(undefined);
        return this.api.deleteProjects(this.userEid, this.periodEndService.getActivePeriod(), this.supervisorEid, this.viewMode, deleteChargeCodes)
                .pipe(mergeMap((response: boolean) => {
                    if (response) {
                        this.logService.showSuccessWithMsgInfo(Charge_Code_Delete_SuccessMessage);
                        if (this.cacheService.chargeCodesStorage)
                            this.cacheService.chargeCodesStorage.refresh = true;
                        this.cacheService.updateChargeCodeCacheForDelete(deleteChargeCodes);
                        return this.getChargeCodesObservable(ChargeCodeUsedFor.Both);
                    } else {
                        this.logService.logErrorWithMsgInfo(Charge_Code_Delete_ErrorMessage);
                        return of(undefined);
                    }
                }))
                .pipe(catchError((error: any) => {
                    this.logService.logErrorWithMsgInfo(Charge_Code_Delete_ErrorMessage);
                    return of(undefined);
                }));
    }

  public getChargeCodeAuthorizationsObservable(authorizedChargeCode: string): Observable<string[]> {
    if (!authorizedChargeCode) return of(undefined);
    return this.api.getProjectAuthorizations(authorizedChargeCode, this.periodEndService.getActivePeriod())
    .pipe(map((adminList: string[]) => {
      return adminList;
    }))
    .pipe(catchError((error: any) => {
      this.logService.logError(error, true);
      return of(undefined);
    }));
  }

  public getBDSearchResultsObservable(isOpportunityPursuit: boolean, clientId: string, opportunityId: string): Observable<ProjectBDLookupDtoForDisplay[]> {
    return this.api.getBDSearchResults(isOpportunityPursuit, clientId, opportunityId, this.periodEndService.getActivePeriod())
    .pipe(map((projectBDLookupDtoForDisplay: ProjectBDLookupDtoForDisplay[]) => {
      return projectBDLookupDtoForDisplay;
    }))
    .pipe(catchError((error: any) => {
      this.logService.logError(error, true);
      return of(undefined);
    }));
  }

  public getClientListObservable(prefixText: string): Observable<ClientSearchDto[]> {
    const getClientListObservable = this.api.getClientList(prefixText, this.periodEndService.getActivePeriod())
    .pipe(map((clientSearchDto: ClientSearchDto[]) => {
      return clientSearchDto;
    }))
    .pipe(catchError((error: any) => {
      this.logService.logError(error, true);
      this.globalCacheService.resetClientList(prefixText);
      return of(undefined);
    }));
    return this.cacheService.handleClientList(getClientListObservable, prefixText);
  }

  public addBusinessDevelopmentObservable(opportunityId: string, isOpportunityPursuit: boolean, timeReportStatus: string, isSaveAuthorized: boolean, clientSearchDto: ClientSearchDto, chargeCodes: ChargeCodes): Observable<ChargeCodes> {
    if (!opportunityId) return of(undefined);
    this.globalEventsService.dispatchShowGlobalSpinnerEvent(true);
    return this.api.addBusinessDevelopment(opportunityId, isOpportunityPursuit, this.userEid, timeReportStatus, isSaveAuthorized, this.periodEndService.getActivePeriod(), this.supervisorEid, this.viewMode, clientSearchDto)
      .pipe(mergeMap((response: AddedProjectOutput) => {
        if (response) {
          chargeCodes.addedProjectOutput = response;
          if (response.status === 'Exist')
            return of(chargeCodes)
          if (response.status === 'Success')
            this.logService.showSuccessWithMsgInfo(BD_Add_SuccessMessage);
          if (this.cacheService.chargeCodesStorage)
            this.cacheService.chargeCodesStorage.refresh = true;
          this.cacheService.resetChargeCodes();
          this.cacheService.resetTimeSheet();
          return this.getChargeCodesObservable(ChargeCodeUsedFor.Both, response);
        } else {
          this.logService.logErrorWithMsgInfo(BD_Add_ErrorMessage);
          return of(undefined);
        }
      }))
      .pipe(catchError((error: any) => {
        this.logService.logErrorWithMsgInfo(BD_Add_ErrorMessage);
        return of(undefined);
      }))
      .pipe(finalize(() => {
        this.globalEventsService.dispatchShowGlobalSpinnerEvent(false);
      }));
  }

  public getChargeCodeDescendantObservable(externalNumber: string, locationCountryKey: string, isTimeEntryGridCell: boolean, isMalaysiaValidation: boolean): Observable<Array<ChargeCodeRelated>> {
    const getProjectsDescendantObservable = this.api.getProjectsDescendant(externalNumber, locationCountryKey, this.periodEndService.getActivePeriod(), isTimeEntryGridCell, this.cacheService.getUserCompanyCode(), isMalaysiaValidation)
    .pipe(map((projectDescendantTreeNode: ProjectDescendantTreeNode) => {
      return ChargeCodesMapper.mapAPIChargeCodeRelated(projectDescendantTreeNode);
    }))
    .pipe(catchError((error: any) => {
      this.logService.logError(error, true);
      this.globalCacheService.clearProjectDescendantBuffer();
      return of(undefined);
    }));
    return this.cacheService.handleProjectDescendants(externalNumber, getProjectsDescendantObservable, locationCountryKey, isTimeEntryGridCell);
  }

  public updateIsDismissCheckedValueObservable(externalNumber: string, isChecked: boolean): Observable<boolean> {
    if (!externalNumber) return of(undefined);
    return this.api.updateIsDismissCheckedValue(externalNumber, isChecked, this.userEid, this.periodEndService.getActivePeriod(), this.supervisorEid, this.viewMode)
      .pipe(map((response: boolean) => {
        if (response) {
          if (this.cacheService.chargeCodesStorage)
            this.cacheService.chargeCodesStorage.refresh = true;
          this.cacheService.resetChargeCodes();
          this.cacheService.resetTimeSheet();
        }
        return response;
      }))
      .pipe(catchError((error: any) => {
        this.logService.logError(error, true);
        return of(undefined);
      }));
  }

  public updateIsDismissCheckedValueStore(externalNumber: string, isChecked: boolean, periodEnd: Date): Observable<boolean> {
    if (!externalNumber) return of(undefined);
    return this.api.updateIsDismissCheckedValue(externalNumber, isChecked, this.userEid, periodEnd, this.supervisorEid, this.viewMode)
      .pipe(map((response: boolean) => {
        if (response) {
          if (this.cacheService.chargeCodesStorage)
            this.cacheService.chargeCodesStorage.refresh = true;
        }
        return response;
      }))
      .pipe(catchError((error: any) => {
        this.logService.logError(error, true);
        return of(undefined);
      }));
  }

  public saveDisplayBooleanChargeCodeObservable(chargeCodeInfo: ChargeCodeInfo): Observable<boolean> {
    if (!chargeCodeInfo || (chargeCodeInfo && !chargeCodeInfo.externalNumber)) return of(undefined);
    return this.api.saveDisplayBooleanProjects(this.userEid, this.periodEndService.getActivePeriod(), this.supervisorEid, this.viewMode, ChargeCodesMapper.mapProjectListInput(chargeCodeInfo))
      .pipe(map((response: boolean) => {
        if (response) {
          this.cacheService.updateChargeCodeForCacheForDisplay(chargeCodeInfo.externalNumber, chargeCodeInfo.isDisplay);
          this.cacheService.resetTimeSheet();
        }
        return response;
      }))
      .pipe(catchError((error: any) => {
        this.logService.logError(error, true);
        return of(undefined);
      }));
  }

  public isProjectTypeCodeForPLC818(projectTypeCode: any): boolean {
    if(projectTypeCode == '10'|| projectTypeCode == '11'
    || projectTypeCode == '60'|| projectTypeCode == '61')
    {
      return true;
    }
    return false;
  }

  public DisplayBooleanProjects(projects: any): Observable<any> {
    let parameters: ProjectDisplayList = new ProjectDisplayList();
    parameters.wbsExternalNbr = projects.externalNumber;
    parameters.projectType = projects.projectType;
    parameters.isDisplay = projects.isDisplay;
    let parameter: ProjectListInput = new ProjectListInput();
    parameter.projects = [];
    parameter.projects.push(parameters);
    return this.api.saveDisplayBooleanProjects( this.userEid, this.periodEndService.getActivePeriod(), this.supervisorEid, this.viewMode, parameter)
      .pipe(map(projectInfo => { return projectInfo; }))
      .pipe(catchError(err => {
        this.logService.logError(err, true);
        return of(err.message);
      }));
  }

  public getAdminList(chargecode: string): Observable<any> {
    return this.api.getProjectAuthorizations(chargecode, this.periodEndService.getActivePeriod())
      .pipe(map(projectInfo => { return projectInfo; }))
      .pipe(catchError(err => {
        this.logService.logError(err, true);
        return of(null);
      }));
  }

  public deletePersonalProject(chargecode: string[]): Observable<boolean> {
    return this.api.deleteProjects(this.userEid, this.periodEndService.getActivePeriod(), this.supervisorEid, this.viewMode, chargecode)
      .pipe(map(projectInfo => { return projectInfo; }))
      .pipe(catchError(err => {
        this.logService.logError(err, true);
        return of(null);
      }));
  }

  public getRelatedChargeCodes(externalNumber: string, locationCountryKey: string = '', isTimeEntygridCell: boolean = false, isMalaysiaValidation: boolean = false ): Observable<ChargeCodeRelated[]> {
    let companyCd = this.globalCacheService.getUserCompanyCode();
    let relatedChargeds = this.api.getProjectsDescendant(externalNumber, locationCountryKey, this.periodEndService.getActivePeriod(), isTimeEntygridCell, companyCd, isMalaysiaValidation)
      .pipe(map(ProjectDescendantTreeNode => this.mapChargeCodeRelated(ProjectDescendantTreeNode)))
      .pipe(catchError(err => {
        this.logService.logError(err, true);
        this.globalCacheService.clearProjectDescendantBuffer();
        return of(null);
      }));
      return this.cacheService.handleProjectDescendants(externalNumber, relatedChargeds, locationCountryKey, isTimeEntygridCell);
  }

  public setDismissValue(externalNumber: string, isChecked: boolean): Observable<boolean> {
    return this.api.updateIsDismissCheckedValue(externalNumber, isChecked, this.userEid, this.periodEndService.getActivePeriod(), this.supervisorEid, this.viewMode)
      .pipe(map(res => { return res; }))
      .pipe(catchError(err => {
        this.logService.logError(err, true);
        return of(false);
      }));
  }

  public addPersonalProject(chargecode: string, periodEnd: Date): Observable<any> {
    return this.api.addProject(this.userEid, periodEnd, chargecode, this.supervisorEid, this.viewMode, "")
      .pipe(map(projectInfo => { return projectInfo; }))
      .pipe(catchError(err => {
        this.logService.logError(err, true);
        return of(null);
      }));
  }

  public getClientList(prefixText: string): Observable<any> {
    let requestClientList =  this.api.getClientList(prefixText, this.periodEndService.getActivePeriod())
      .pipe(map(projectInfo => {
        return projectInfo;
      }))
      .pipe(catchError(err => {
        this.logService.logError(err, true);
        return of(null);
      }));
      return this.cacheService.handleClientList(requestClientList, prefixText);
  }

  public getBDSearchResults(isOpportunityPursuit: boolean, clientId: string, opportunityId: string): Observable<any> {
    return this.api.getBDSearchResults(isOpportunityPursuit, clientId, opportunityId, this.periodEndService.getActivePeriod())
      .pipe(map(projectInfo => { return projectInfo; }))
      .pipe(catchError(err => {
        this.logService.logError(err, true);
        return of(null);
      }));
  }

  public addBusinessDevelopment(clientId: string, clientName: string, opportunityId: string, isOpportunityPursuit: boolean, timeReportStatus: string, isSaveAuthorized: boolean): Observable<any> {
    let client: ClientSearchDto = new ClientSearchDto();
    client.clientId = clientId;
    client.clientName = clientName;
    return this.api.addBusinessDevelopment( opportunityId, isOpportunityPursuit, this.userEid,
    timeReportStatus, isSaveAuthorized, this.periodEndService.getActivePeriod(), this.supervisorEid, this.viewMode, client)
      .pipe(map(projectInfo => { return projectInfo; }))
      .pipe(catchError(err => {
        this.logService.logError(err, true);
        return of(null);
      }));
  }

  public MessageForUnauthorizedPopUpConfirm(owner: string, externalNumber: string, delegate: string[]): string {
    let message = '';
    let delegateString = '';
    let unauthorizedTimeMassage = 'You are not authorized to use this WBS.  If you will not be able to get authorization from the WBS Owner or the WBS Admin(s) BEFORE the submission deadline, you may temporarily charge your time to the Unauthorized Time WBS.\n\n';
    let unauthorizedTimeMassageText = '\nIf you select OK, the Unauthorized Time WBS will be made available in your current Time Report only.  On your NEXT time report, you MUST move this time to the correct WBS by making a Prior Period Adjustment. \n\n Do you want to use the Unauthorized Time WBS?';

    let delegatesToDisplay = 3;
    message = (owner != null)
        ? message = `WBS Owner for  ${externalNumber}  -  ${owner}\n`
        : message = `WBS Owner for  ${externalNumber}  -\n`;

        if (delegate.length != 0) {
            for (let i = 0; i < delegatesToDisplay; i++) {
              if (i < delegate.length) {
                delegateString += '     -  ' + delegate[i] + '\r\n';
              }
            }
            message = `${message}\nWBS Admin(s) for ${externalNumber}\n${delegateString}`
        }
        else
        {
          message = `${message}\nWBS Admin(s) for ${externalNumber}\n`;
        }

    return unauthorizedTimeMassage + message + unauthorizedTimeMassageText;
  }

  private mapChargeCodeRelated(dto: ProjectDescendantTreeNode): ChargeCodeRelated[] {
    let chargeCodeList: ChargeCodeRelated[] = [];
    chargeCodeList = this.getChild(dto, chargeCodeList, dto.networkHierarchyToUse, dto.externalNbr);
    return chargeCodeList;
  }

  private getChild(node: ProjectDescendantTreeNode, chargeCodeList: ChargeCodeRelated[], networkHierarchyToUse: NetworkHierarchy, rootNode: string): ChargeCodeRelated[] {
    node.children.forEach(code => {
      if(networkHierarchyToUse == 0)
        chargeCodeList.push(new ChargeCodeRelated(code.type, code.externalNbr, code.description, node.externalNbr, false));
      else
        chargeCodeList.push(new ChargeCodeRelated(code.type, code.externalNbr, code.description, node.externalNbr, this.getNewHierarchyValidationError(code, node, rootNode)));
      if(code.children.length != 0)
        chargeCodeList = this.getChild(code, chargeCodeList, networkHierarchyToUse, rootNode);
    });
    return chargeCodeList;
  }

  private getNewHierarchyValidationError(node: ProjectDescendantTreeNode, parentNode: ProjectDescendantTreeNode, rootNode: string): boolean{
    let leaves: ProjectDescendantTreeNode[] = [];
    if(node.children.length != 0){
      if(this.getAllLeaves(node, leaves).find(leaf => leaf.type == "Cost Collector"))
        return true;
      if(this.GetAllDescendants(node, leaves).find(descendant => descendant.type == "Network"))
        return true;
    }
    if(parentNode.children.find(child => child.externalNbr != node.externalNbr
                                      && child.type == "Network"
                                      && node.type == "Cost Collector"))
      return true;
    if(parentNode.children.find(child => child.externalNbr != node.externalNbr
                                      && child.type == "Network"
                                      && node.type == "Network"
                                      && parentNode.externalNbr != rootNode))
      return true;
    return false;
  }

  private getAllLeaves(node: ProjectDescendantTreeNode, leaves: ProjectDescendantTreeNode[]): ProjectDescendantTreeNode[]{
    node.children.forEach(child => {
      if(child.isLeaf)
        leaves.push(child);
      if(child.children.length != 0)
        leaves = this.getAllLeaves(child, leaves);
    });
    return leaves;
  }
  private GetAllDescendants(node: ProjectDescendantTreeNode, descendants: ProjectDescendantTreeNode[]): ProjectDescendantTreeNode[]{
    node.children.forEach(child => {
      if(!descendants.find(descendant => descendant.externalNbr == node.externalNbr))
        descendants.push(child);
      if(child.children.length != 0)
      descendants = this.getAllLeaves(child, descendants);
    });
    return descendants;
  }
}
