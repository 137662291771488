import { Pipe, PipeTransform } from '@angular/core';
import { GlobalDateFormater } from '@shared/utils/globalDateFormater.utils';
import * as moment from 'moment';
import { FormatDateType } from '@shared/utils/constants';
@Pipe({
  name: 'dateformat'
})
export class DateformatPipe implements PipeTransform {
   /**
    * Transform method recives 2 parameters:
    * @param 'data': is one date, in string or Date format.
    * @param 'formatType': is the format that we want to be executed.
    * Each case comes from FormatDateType from constants dates file, is a enum.
    * @return return a value that come from correct method for each case of the static class 'GlobalDateFormater'.*/

  transform(data: any, formatType: string): any {
    switch (formatType){
      case FormatDateType.GetDateForTooltip:
       return GlobalDateFormater.getFullFormattedDateByLanguage(data);
      case FormatDateType.FormatDate:
        return GlobalDateFormater.formatDate(data);
      case FormatDateType.GetDateMaskByLanguage:
        return GlobalDateFormater.getMaskDate();
      case FormatDateType.GetFullMonthByLanguage:
        return GlobalDateFormater.getFullMonthByLanguage(data);
      case FormatDateType.FormatDateWithTime:
        return GlobalDateFormater.convertDateToStringWithTime(data);
      case FormatDateType.FormatDateWithTimeHoursMinutes:
        return GlobalDateFormater.convertDateToStringWithTime(data, true);
      case FormatDateType.ExpenseGridService:
        return GlobalDateFormater.formatDateToGridAmexImport(data);
      case FormatDateType.GetStandardDateForFilter:
        return GlobalDateFormater.getDateForFilter(data);
      case FormatDateType.FirstDayOfWeekByLanguage:
        return GlobalDateFormater.getIndexOfFirstDayOfWeekByLanguage();
      case FormatDateType.GetBrowserDateFormat:
        return GlobalDateFormater.getBrowserDateFormat();
      case FormatDateType.GetShortDayNameByLanguage:
        return GlobalDateFormater.getDayNameByLanguage(data, true)
      case FormatDateType.GetDateNumber:
        return GlobalDateFormater.getDateNumber(data);
      case FormatDateType.GetFullDay:
        return GlobalDateFormater.getFullDay(data);
      case FormatDateType.GetStringToDate:
        return GlobalDateFormater.convertStringToDate(data, false);
      case FormatDateType.GetDateForMultipleLocations:
        return GlobalDateFormater.getDateForMultipleLocations(data);
      case FormatDateType.GetDateForLocationQuestionnaire:
        return GlobalDateFormater.getDateForLocationQuestionnaire(data);
      case FormatDateType.isBusinessDay:
        return GlobalDateFormater.validateBusinessDays(data);
      case FormatDateType.isFirstFortnight:
        return GlobalDateFormater.isFirstFortnight(data);
      case FormatDateType.FormatDateForAPI:
        return GlobalDateFormater.formatDateForBackend(data);
      case FormatDateType.FormatDatewithTwentyFourHoursTime:
        return GlobalDateFormater.convertDateToStringWithTwentyFourHourTime(data);
      default:
        if (typeof data !== 'string') {
          const newData = this.transform(data, FormatDateType.FormatDateWithTime);
          return newData ? moment(new Date(newData)).format(formatType) + ' ' + newData.substring(11) : ' - ';
        }

        return data ? moment(new Date(data)).format(formatType) + ' ' + data.substring(11) : ' - ';


    }
  }


}
