import { SummaryGridDataType, TimeReportSummary } from '../../../../shared/models/summary/time-report-summary/time-report-summary.model';
import { SummaryGridData } from '../../../../shared/models/summary/time-report-summary/summary-grid-data';
import { TimeReportSummaryRow } from '../../../../shared/models/summary/time-report-summary/time-report-summary-row';
import { LocationsGridData } from '../../../../shared/models/summary/time-report-summary/locations-grid-data';
import { LocationsSummaryRow } from '../../../../shared/models/summary/time-report-summary/locations-summary-row';
import { AnnualChargeability } from '../../../../shared/models/summary/annual-chargeability/annual-chargeability.model';
import { AnnualChargeabilityRow, AnnualChargeabilityParentRow, AnnualChargeabilityChildRow } from '../../../../shared/models/summary/annual-chargeability/annual-chargeability-row.model';
import { AnnualChargeabilityGrid } from '../../../../shared/models/summary/annual-chargeability/annual-chargeability-grid.model';
import { TimeReportSummaryOutput as APITimeReportSummaryOutput, TimeReportSummary as APITimeReportSummary, TimeReportAssignmentSummary as APITimeReportAssignmentSummary, TimeReportSummaryData as APITimeReportSummaryData, LocationSummary as APILocationSummary, LocationSummaryEntry as APILocationSummaryEntry, 
    TimeReportChargeabilityHoursSummaryDto as APIAnnualChargeability, AnnualChargeabilityTimeReport as APIAnnualChargeabilityTimeReport, ChargeabilityAssignment as APIChargeabilityAssignment, OTBalanceRequestInput as APIOTBalanceRequestInput, OTBalanceRequestOutput as APIOTBalanceRequestOutput, SAPBalance as APISAPBalance } from '../../../../shared/clients/timereport-api-client';
import { TimeReportSummaryOvertime } from '@shared/models/summary/time-report-summary/time-report-summary-overtime';
import { NumberFormatUtils } from '../../../../shared/utils/numberFormater.utils';
import { DateformatPipe } from '@shared/pipes/dateformat.pipe';
import { FormatDateType } from '@shared/utils/constants';
import moment from 'moment';
import { OTBalanceRequestInput } from '@shared/models/summary/ot-balance-request/ot-balance-request-input.model';
import { OTBalanceRequest } from '@shared/models/summary/ot-balance-request/ot-balance-request.model';
import { SAPBalanceData } from '@shared/models/summary/time-report-summary/sap-balace-data';

export class SummaryServiceMapper {

    public static mapAPITimeReportSummaryOutput(apiTimeReportSummaryOutput: APITimeReportSummaryOutput, shouldUseTwoDecimal: boolean): TimeReportSummary {
        const timeReportSummary: TimeReportSummary = new TimeReportSummary();
        if (apiTimeReportSummaryOutput && Object.keys(apiTimeReportSummaryOutput).length > 0) {
            Object.assign(timeReportSummary, apiTimeReportSummaryOutput);
            timeReportSummary.timeReportStatus = apiTimeReportSummaryOutput.timeReportStatus;
            timeReportSummary.ptosBalance = apiTimeReportSummaryOutput.ptoBalance;
            timeReportSummary.ptosPeriodEnd = apiTimeReportSummaryOutput.ptoBalancePeriodEndDttm;
            timeReportSummary.summaryGridData = this.mapAPISummaryGridData(apiTimeReportSummaryOutput, shouldUseTwoDecimal);
            timeReportSummary.locationsGridData = this.mapAPILocationsGridData(apiTimeReportSummaryOutput);
            timeReportSummary.standardAvailableHours = undefined;
            timeReportSummary.percentageOfStandardAvailableHours = undefined;
            timeReportSummary.workSchedule = undefined;
            timeReportSummary.annualChargeabilityHoursPercentage = `${(shouldUseTwoDecimal ? apiTimeReportSummaryOutput.annualChargeabilityValue.toFixed(2) : apiTimeReportSummaryOutput.annualChargeabilityValue)}%`;
            timeReportSummary.annualChargeabilityValue = apiTimeReportSummaryOutput.annualChargeabilityValue;
            timeReportSummary.otCashout = this.mapAPITimeReportSummaryOvertime(apiTimeReportSummaryOutput);
            timeReportSummary.showPTOBalance = !moment(apiTimeReportSummaryOutput.ptoBalancePeriodEndDttm).isSame(moment(new Date('0001-01-01T00:00:00')), 'day');
            timeReportSummary.sapBalance = this.mapAPISAPBalanceData(apiTimeReportSummaryOutput?.sapBalance);
            if (timeReportSummary.showPTOBalance)
                timeReportSummary.ptosBalance = NumberFormatUtils.formatNumberToLocaleString(apiTimeReportSummaryOutput.ptoBalance, 2, 2);
        }
        return timeReportSummary;
    }

    private static mapAPISAPBalanceData( apiSAPBalance: APISAPBalance ): SAPBalanceData{
        const sapBalanceData: SAPBalanceData = new SAPBalanceData();
        if (apiSAPBalance && Object.keys(apiSAPBalance).length > 0) {
            sapBalanceData.isUserValid = apiSAPBalance.isUserValid;
            sapBalanceData.casualLeavePTOHours = this.parseTwoDecimal(apiSAPBalance.casualLeavePTOHours);
            sapBalanceData.earnedLeaveRemainingPTOHours = this.parseTwoDecimal(apiSAPBalance.earnedLeaveRemainingPTOHours);
            sapBalanceData.sickLeavePTOHours = this.parseTwoDecimal(apiSAPBalance.sickLeavePTOHours);
            sapBalanceData.combinedSLCLPTOHours = this.parseTwoDecimal(apiSAPBalance.combinedSLCLPTOHours);
            sapBalanceData.periodEndSAPBalance = apiSAPBalance.periodEndDttm;
            sapBalanceData.casualLeavePTODays = this.parseTwoDecimal(apiSAPBalance.casualLeavePTODays);
            sapBalanceData.earnedLeaveRemainingPTODays = this.parseTwoDecimal(apiSAPBalance.earnedLeaveRemainingPTODays);
            sapBalanceData.sickLeavePTODays = this.parseTwoDecimal(apiSAPBalance.sickLeavePTODays);
            sapBalanceData.combinedSLCLPTODays = this.parseTwoDecimal(apiSAPBalance.combinedSLCLPTODays);            
        }
        return sapBalanceData
    }
    
    private static mapAPISummaryGridData(apiTimeReportSummaryOutput: APITimeReportSummaryOutput, shouldUseTwoDecimal: boolean): SummaryGridData {
        const summaryGridData: SummaryGridData = new SummaryGridData();
        if (apiTimeReportSummaryOutput && Object.keys(apiTimeReportSummaryOutput).length > 0) {
            summaryGridData.rowData = this.mapAPITimeReportSummary(apiTimeReportSummaryOutput.timeReportSummary, shouldUseTwoDecimal);
            summaryGridData.pinnedBottomRowData = this.mapAPITimeReportSummaryPinned(apiTimeReportSummaryOutput, shouldUseTwoDecimal);
        }
        return summaryGridData;
    }

    private static mapAPITimeReportSummary(apiTimeReportSummary: APITimeReportSummary, shouldUseTwoDecimal: boolean): Array<TimeReportSummaryRow> {
        const timeReportSummaryRows: Array<TimeReportSummaryRow> = new Array<TimeReportSummaryRow>();
        if (apiTimeReportSummary && Object.keys(apiTimeReportSummary).length > 0) {
            if (apiTimeReportSummary.assignments && apiTimeReportSummary.assignments.length > 0) {
                apiTimeReportSummary.assignments.map((apiTimeReportAssignmentSummary: APITimeReportAssignmentSummary) => {
                    const timeReportSummaryRow: TimeReportSummaryRow = new TimeReportSummaryRow();
                    if (apiTimeReportAssignmentSummary && Object.keys(apiTimeReportAssignmentSummary).length > 0 && apiTimeReportAssignmentSummary.values && Object.keys(apiTimeReportAssignmentSummary.values).length > 0) {
                        const decimalPlaces: number = shouldUseTwoDecimal ? 2 : 1;
                        timeReportSummaryRow.chargeCode = apiTimeReportAssignmentSummary.assignment;
                        timeReportSummaryRow.currentHours = this.formatExpensesValue(apiTimeReportAssignmentSummary.values.currentHours, decimalPlaces);
                        timeReportSummaryRow.expensesAmount = this.formatExpensesValue(apiTimeReportAssignmentSummary.values.expensesAmount, 2);
                        timeReportSummaryRow.chargeableHours = this.formatExpensesValue(apiTimeReportAssignmentSummary.values.chargeableHours, decimalPlaces);
                        timeReportSummaryRow.clientFacingHours = this.formatExpensesValue(apiTimeReportAssignmentSummary.values.clientFacingHours, decimalPlaces);
                        timeReportSummaryRow.marketFacingHours = this.formatExpensesValue(apiTimeReportAssignmentSummary.values.marketFacingHours, decimalPlaces);
                        timeReportSummaryRow.recoveryHours = this.formatExpensesValue(apiTimeReportAssignmentSummary.values.recoveryHours, decimalPlaces);
                        timeReportSummaryRow.otherHours = this.formatExpensesValue(apiTimeReportAssignmentSummary.values.otherHours, decimalPlaces);
                        timeReportSummaryRow.absencesHours = this.formatExpensesValue(apiTimeReportAssignmentSummary.values.absencesHours, decimalPlaces);
                        timeReportSummaryRow.adjustedHours = this.formatExpensesValue(apiTimeReportAssignmentSummary.values.adjustedHours, decimalPlaces);
                        timeReportSummaryRow.adjustedExpensesAmount = this.formatExpensesValue(apiTimeReportAssignmentSummary.values.adjustedExpensesAmount, 2);
                        timeReportSummaryRow.totalHours = this.formatExpensesValue(apiTimeReportAssignmentSummary.values.totalHours, decimalPlaces);
                        timeReportSummaryRow.totalExpensesAmount = this.formatExpensesValue(apiTimeReportAssignmentSummary.values.totalExpensesAmount, 2);
                    }
                    timeReportSummaryRows.push(timeReportSummaryRow);
                });
            } else {
                timeReportSummaryRows.push(new TimeReportSummaryRow('','','','','','','','','','','','',''));
            }
        }
        return timeReportSummaryRows;
    }

    private static mapAPITimeReportSummaryPinned(apiTimeReportSummaryOutput: APITimeReportSummaryOutput, shouldUseTwoDecimal: boolean): Array<TimeReportSummaryRow> {
        const timeReportSummaryRows: Array<TimeReportSummaryRow> = new Array<TimeReportSummaryRow>();
        if (apiTimeReportSummaryOutput && Object.keys(apiTimeReportSummaryOutput).length > 0) {
            const timeReportSummaryTotal: TimeReportSummaryRow = this.totalRow(apiTimeReportSummaryOutput, shouldUseTwoDecimal);
            const timeReportSummaryWorkSchedule: TimeReportSummaryRow = this.mapGridData(SummaryGridDataType.WorkSchedule, apiTimeReportSummaryOutput.workSchedule, shouldUseTwoDecimal);
            const timeReportSummaryOvertime: TimeReportSummaryRow = this.createRow(SummaryGridDataType.Overtime, apiTimeReportSummaryOutput, shouldUseTwoDecimal);
            const timeReportSummaryStandardHours: TimeReportSummaryRow = this.mapGridData(SummaryGridDataType.StandardHours, apiTimeReportSummaryOutput.standardAvailableHours, shouldUseTwoDecimal);
            const timeReportSummaryPercentageStandardHours: TimeReportSummaryRow = this.mapGridData(SummaryGridDataType.StandardPercentageHours, apiTimeReportSummaryOutput.percentageOfStandardAvailableHours, shouldUseTwoDecimal);
            timeReportSummaryRows.push(timeReportSummaryTotal, timeReportSummaryWorkSchedule, timeReportSummaryOvertime, timeReportSummaryStandardHours, timeReportSummaryPercentageStandardHours);
        }
        return timeReportSummaryRows;
    }

    private static totalRow(apiTimeReportSummaryOutput: APITimeReportSummaryOutput, shouldUseTwoDecimal: boolean): TimeReportSummaryRow {
        const timeReportSummaryTotal: TimeReportSummaryRow = new TimeReportSummaryRow();
        if (apiTimeReportSummaryOutput && Object.keys(apiTimeReportSummaryOutput).length > 0) {
            const decimalPlaces: number = shouldUseTwoDecimal ? 2 : 1;
            timeReportSummaryTotal.chargeCode = 'Total';
            timeReportSummaryTotal.currentHours = this.formatExpensesValue(apiTimeReportSummaryOutput.totalCurrentHours, decimalPlaces);
            timeReportSummaryTotal.expensesAmount = this.formatExpensesValue(apiTimeReportSummaryOutput.totalExpensesAmount, 2);
            timeReportSummaryTotal.chargeableHours = this.formatExpensesValue(apiTimeReportSummaryOutput.totalChargeableHours, decimalPlaces);
            timeReportSummaryTotal.clientFacingHours = this.formatExpensesValue(apiTimeReportSummaryOutput.totalClientFacingDtoHours, decimalPlaces);
            timeReportSummaryTotal.marketFacingHours = this.formatExpensesValue(apiTimeReportSummaryOutput.totalMarketFacingHours, decimalPlaces);
            timeReportSummaryTotal.recoveryHours = this.formatExpensesValue(apiTimeReportSummaryOutput.totalRecoveryHours, decimalPlaces);
            timeReportSummaryTotal.otherHours = this.formatExpensesValue(apiTimeReportSummaryOutput.totalOtherHours, decimalPlaces);
            timeReportSummaryTotal.absencesHours = this.formatExpensesValue(apiTimeReportSummaryOutput.totalAbsencesHours, decimalPlaces);
            timeReportSummaryTotal.adjustedHours = this.formatExpensesValue(apiTimeReportSummaryOutput.totalAdjustedHours, decimalPlaces);
            timeReportSummaryTotal.adjustedExpensesAmount = this.formatExpensesValue(apiTimeReportSummaryOutput.totalAdjustedExpensesAmount, 2);
            timeReportSummaryTotal.totalHours = this.formatExpensesValue(apiTimeReportSummaryOutput.totalCurrentHours, decimalPlaces);
            timeReportSummaryTotal.totalExpensesAmount = this.formatExpensesValue(apiTimeReportSummaryOutput.totalExpensesAmount, 2);
        }
        return timeReportSummaryTotal;
    }

    private static mapGridData(title: string, apiTimeReportSummaryData: APITimeReportSummaryData, shouldUseTwoDecimal: boolean): TimeReportSummaryRow {
        const timeReportSummaryData: TimeReportSummaryRow = new TimeReportSummaryRow();
        if (apiTimeReportSummaryData && Object.keys(apiTimeReportSummaryData).length > 0) {
            const decimalPlaces: number = shouldUseTwoDecimal ? 2 : 1;
            timeReportSummaryData.chargeCode = title;
            timeReportSummaryData.currentHours = this.formatExpensesValue(apiTimeReportSummaryData.currentHours, decimalPlaces);
            timeReportSummaryData.expensesAmount = this.formatExpensesValue(apiTimeReportSummaryData.expensesAmount, 2);
            timeReportSummaryData.chargeableHours = this.formatExpensesValue(apiTimeReportSummaryData.chargeableHours, decimalPlaces);
            timeReportSummaryData.clientFacingHours = this.formatExpensesValue(apiTimeReportSummaryData.clientFacingHours, decimalPlaces);
            timeReportSummaryData.marketFacingHours = this.formatExpensesValue(apiTimeReportSummaryData.marketFacingHours, decimalPlaces);
            timeReportSummaryData.recoveryHours = this.formatExpensesValue(apiTimeReportSummaryData.recoveryHours, decimalPlaces);
            timeReportSummaryData.otherHours = this.formatExpensesValue(apiTimeReportSummaryData.otherHours, decimalPlaces);
            timeReportSummaryData.absencesHours = this.formatExpensesValue(apiTimeReportSummaryData.absencesHours, decimalPlaces);
            timeReportSummaryData.adjustedHours = this.formatExpensesValue(apiTimeReportSummaryData.adjustedHours, 2);
            timeReportSummaryData.adjustedExpensesAmount = this.formatExpensesValue(apiTimeReportSummaryData.adjustedExpensesAmount, 2);
            timeReportSummaryData.totalHours = this.formatExpensesValue(apiTimeReportSummaryData.totalHours, decimalPlaces);
            timeReportSummaryData.totalExpensesAmount = this.formatExpensesValue(apiTimeReportSummaryData.totalExpensesAmount, 2);
        }
        return timeReportSummaryData;
    }

    private static createRow(title: string, apiTimeReportSummaryOutput: APITimeReportSummaryOutput, shouldUseTwoDecimal: boolean): TimeReportSummaryRow {
        const timeReportSummaryEmpty: TimeReportSummaryRow = new TimeReportSummaryRow();
        if (apiTimeReportSummaryOutput && Object.keys(apiTimeReportSummaryOutput).length > 0) {
            const decimalPlaces: number = shouldUseTwoDecimal ? 2 : 1;
            timeReportSummaryEmpty.chargeCode = title;
            timeReportSummaryEmpty.currentHours = this.formatExpensesValue(String(apiTimeReportSummaryOutput.overtime), decimalPlaces);
            timeReportSummaryEmpty.expensesAmount = '';
            timeReportSummaryEmpty.chargeableHours = '';
            timeReportSummaryEmpty.clientFacingHours = '';
            timeReportSummaryEmpty.marketFacingHours = '';
            timeReportSummaryEmpty.recoveryHours = '';
            timeReportSummaryEmpty.otherHours = '';
            timeReportSummaryEmpty.absencesHours = '';
            timeReportSummaryEmpty.adjustedHours = '';
            timeReportSummaryEmpty.adjustedExpensesAmount = '';
            timeReportSummaryEmpty.totalHours = '';
            timeReportSummaryEmpty.totalExpensesAmount = '';
        }
        return timeReportSummaryEmpty;
    }

    private static mapAPILocationsGridData(apiTimeReportSummaryOutput: APITimeReportSummaryOutput): LocationsGridData {
        const locationsGridData: LocationsGridData = new LocationsGridData();
        if (apiTimeReportSummaryOutput && Object.keys(apiTimeReportSummaryOutput).length > 0) {
            locationsGridData.rowData = this.mapAPILocationSummary(apiTimeReportSummaryOutput.locationsSummary);
            locationsGridData.pinnedBottomRowData = this.mapAPILocationSummaryPinned(apiTimeReportSummaryOutput.locationsSummary);
        }
        return locationsGridData;
    }

    private static mapAPILocationSummaryPinned(apiLocationSummary: APILocationSummary): Array<LocationsSummaryRow> {
        const locationsSummaryTotalRows: Array<LocationsSummaryRow> = new Array<LocationsSummaryRow>();
        if (apiLocationSummary && Object.keys(apiLocationSummary).length > 0) {
            const locationsSummaryTotalRow: LocationsSummaryRow = new LocationsSummaryRow();
            locationsSummaryTotalRow.country = '';
            locationsSummaryTotalRow.location = 'Total';
            locationsSummaryTotalRow.hours = this.formatValueToLocale(apiLocationSummary.total) ?? '';
            locationsSummaryTotalRows.push(locationsSummaryTotalRow);
        }
        return locationsSummaryTotalRows;
    }

    private static mapAPILocationSummary(apiLocationSummary: APILocationSummary): Array<LocationsSummaryRow> {
        const locationsSummaryRows: Array<LocationsSummaryRow> = new Array<LocationsSummaryRow>();
        if (apiLocationSummary && Object.keys(apiLocationSummary).length > 0) {
            if (apiLocationSummary.entries && apiLocationSummary.entries.length > 0) {
                apiLocationSummary.entries.map((apiLocationSummaryEntry: APILocationSummaryEntry) => {
                    const locationsSummaryRow: LocationsSummaryRow = new LocationsSummaryRow();
                    if (apiLocationSummaryEntry && Object.keys(apiLocationSummaryEntry).length > 0) {
                        locationsSummaryRow.country = apiLocationSummaryEntry.country;
                        locationsSummaryRow.location = apiLocationSummaryEntry.location;
                        locationsSummaryRow.hours = this.formatValueToLocale(apiLocationSummaryEntry.hours);
                    }
                    locationsSummaryRows.push(locationsSummaryRow);
                });
            } else {
                locationsSummaryRows.push(new LocationsSummaryRow('', '', ''));
            }
        }
        return locationsSummaryRows;
    }

    private static mapAPITimeReportSummaryOvertime(apiTimeReportSummaryOutput: APITimeReportSummaryOutput): TimeReportSummaryOvertime {
        const timeReportSummaryOvertime: TimeReportSummaryOvertime = new TimeReportSummaryOvertime();
        if (apiTimeReportSummaryOutput && Object.keys(apiTimeReportSummaryOutput).length > 0) {
            timeReportSummaryOvertime.otBalance = this.validatedCashOutOvertime(String(apiTimeReportSummaryOutput.otBalance));
            timeReportSummaryOvertime.otBalanceRequestedHours = this.validatedCashOutOvertime(String(apiTimeReportSummaryOutput.otBalanceRequestedHours));
            timeReportSummaryOvertime.timeOffInLieuHours = this.validatedCashOutOvertime(String(apiTimeReportSummaryOutput.timeOffInLieuHours));
            timeReportSummaryOvertime.otBalancePeriodEndDttm = apiTimeReportSummaryOutput.otBalancePeriodEndDttm;
            timeReportSummaryOvertime.otCashoutTooltip = 'Enter the number of overtime hours to be paid in the field. Your request cannot exceed your available Overtime Balance.';
            timeReportSummaryOvertime.isOTBalanceInputEnabled = this.isValidCashOutOvertimeDate(new Date(new Date().toLocaleString('en-US', { timeZone: 'UTC' })));
            timeReportSummaryOvertime.otTimeOffInLieuTooltip = 'This field shows the number of hours taken as time-off in lieu (TOIL) this month, including the current period and prior period adjustments.';
        }
        return timeReportSummaryOvertime;
    }

    public static mapAPIAnnualChargeability(apiAnnualChargeability: APIAnnualChargeability, shouldUseTwoDecimal: boolean): AnnualChargeability {
        const annualChargeability: AnnualChargeability = new AnnualChargeability();
        const decimalPlaces: number = shouldUseTwoDecimal ? 2 : 1;
        if (apiAnnualChargeability && Object.keys(apiAnnualChargeability).length > 0) {
            Object.assign(annualChargeability, apiAnnualChargeability);
            annualChargeability.currentHours = this.formatToLocale(navigator.language, apiAnnualChargeability.totalAnnualChargeabilityHours.toFixed(decimalPlaces));
            annualChargeability.totalHours = this.formatToLocale(navigator.language, apiAnnualChargeability.totalAnnualStandardHours.toFixed(decimalPlaces));
            annualChargeability.hoursPercentage = (apiAnnualChargeability.annualChargeabilityPercentage * 100).toFixed(decimalPlaces);
            annualChargeability.gridData = this.mapAPIAnnualChargeabilityGrid(apiAnnualChargeability, shouldUseTwoDecimal);
        }
        return annualChargeability;
    }

    private static mapAPIAnnualChargeabilityGrid(apiAnnualChargeability: APIAnnualChargeability, shouldUseTwoDecimal: boolean): AnnualChargeabilityGrid {
        const annualChargeabilityGrid: AnnualChargeabilityGrid = new AnnualChargeabilityGrid();
        if (apiAnnualChargeability && Object.keys(apiAnnualChargeability).length > 0) {
            let sum: number = Number();
            annualChargeabilityGrid.rowData = this.mapAPIAnnualChargeabilityRow(apiAnnualChargeability.annualChargeabilityTimeReport, sum, shouldUseTwoDecimal);
            annualChargeabilityGrid.pinnedBottomRowData = this.mapAPIAnnualChargeabilityPinned(apiAnnualChargeability, sum, shouldUseTwoDecimal);
        }
        return annualChargeabilityGrid;
    }

    private static mapAPIAnnualChargeabilityRow(apiAnnualChargeabilityTimeReports: Array<APIAnnualChargeabilityTimeReport>, sum: number, shouldUseTwoDecimal: boolean): Array<AnnualChargeabilityRow> {
        const decimalPlaces: number = shouldUseTwoDecimal ? 2 : 1;
        const annualChargeabilityRows: Array<AnnualChargeabilityRow> = new Array<AnnualChargeabilityRow>();
        if (apiAnnualChargeabilityTimeReports && apiAnnualChargeabilityTimeReports.length > 0) {
            apiAnnualChargeabilityTimeReports.map((apiAnnualChargeabilityTimeReport: APIAnnualChargeabilityTimeReport, index: number) => {
                const annualChargeabilityParentRow: AnnualChargeabilityParentRow = new AnnualChargeabilityParentRow();
                if (apiAnnualChargeabilityTimeReport && Object.keys(apiAnnualChargeabilityTimeReport).length > 0) {
                    annualChargeabilityParentRow.id = index;
                    annualChargeabilityParentRow.open = false;
                    annualChargeabilityParentRow.chargeCode = '';
                    annualChargeabilityParentRow.periodEnd = moment(new Date(apiAnnualChargeabilityTimeReport.periodEnd)).isValid() ? moment(new Date(apiAnnualChargeabilityTimeReport.periodEnd)).toDate() : undefined;
                    annualChargeabilityParentRow.chargeableHours = this.validateValue(apiAnnualChargeabilityTimeReport.chargeableHours, shouldUseTwoDecimal);
                    annualChargeabilityParentRow.nonChargeableHours = this.validateValue(apiAnnualChargeabilityTimeReport.nonChargeableHours, shouldUseTwoDecimal);
                    annualChargeabilityParentRow.timeReportId = apiAnnualChargeabilityTimeReport.timeReportId;
                    annualChargeabilityParentRow.chargeabilityPercent = (apiAnnualChargeabilityTimeReport.chargebilityPercent ? this.formatValueToLocaleWithPresicion(`${apiAnnualChargeabilityTimeReport.chargebilityPercent.replace('%', '')}`, decimalPlaces, decimalPlaces) + '%' : undefined);
                    annualChargeabilityParentRow.tasks = this.mapAPIChargeabilityAssignments(apiAnnualChargeabilityTimeReport.assignments, index, shouldUseTwoDecimal);
                    if (apiAnnualChargeabilityTimeReport.chargebilityPercent && isNaN(Number(apiAnnualChargeabilityTimeReport.chargebilityPercent.replace('%', ''))))
                        sum += Number(apiAnnualChargeabilityTimeReport.chargebilityPercent.replace('%', ''));
                }
                annualChargeabilityRows.push(annualChargeabilityParentRow);
            });
        } else {
            const annualChargeabilityParentRow: AnnualChargeabilityRow = new AnnualChargeabilityRow();
            annualChargeabilityParentRow.chargeabilityPercent = '';
            annualChargeabilityParentRow.chargeableHours = '';
            annualChargeabilityParentRow.nonChargeableHours = '';
            annualChargeabilityRows.push(annualChargeabilityParentRow);
        }
        return annualChargeabilityRows;
    }

    private static mapAPIChargeabilityAssignments(apiChargeabilityAssignments: Array<APIChargeabilityAssignment>, index: number, shouldUseTwoDecimal: boolean): Array<AnnualChargeabilityChildRow> {
        const annualChargeabilityChildRows: Array<AnnualChargeabilityChildRow> = new Array<AnnualChargeabilityChildRow>();
        if (apiChargeabilityAssignments && apiChargeabilityAssignments.length > 0) {
            apiChargeabilityAssignments.map((apiChargeabilityAssignment: APIChargeabilityAssignment) => {
                const annualChargeabilityChildRow: AnnualChargeabilityChildRow = new AnnualChargeabilityChildRow();
                if (apiChargeabilityAssignment && Object.keys(apiChargeabilityAssignment).length > 0) {
                    annualChargeabilityChildRow.id = index
                    annualChargeabilityChildRow.nonChargeableHours = this.validateValue(apiChargeabilityAssignment.nonChargeableHours, shouldUseTwoDecimal);
                    annualChargeabilityChildRow.chargeableHours = this.validateValue(apiChargeabilityAssignment.chargeableHours, shouldUseTwoDecimal);
                    annualChargeabilityChildRow.chargeCode = apiChargeabilityAssignment.chargeCode;
                    annualChargeabilityChildRow.chargeabilityPercent = '';
                }
                annualChargeabilityChildRows.push(annualChargeabilityChildRow);
            });
        } else {
            annualChargeabilityChildRows.push(new AnnualChargeabilityChildRow());
        }
        return annualChargeabilityChildRows;
    }

    private static mapAPIAnnualChargeabilityPinned(apiAnnualChargeability: APIAnnualChargeability, sum: number, shouldUseTwoDecimal: boolean): Array<AnnualChargeabilityRow> {
        const annualChargeabilityRows: Array<AnnualChargeabilityRow> = new Array<AnnualChargeabilityRow>();
        const decimalPlaces: number = shouldUseTwoDecimal ? 2 : 1;
        const zerodecimalPlaces: string = shouldUseTwoDecimal ? '0.00' : '0.0';
        if (apiAnnualChargeability && Object.keys(apiAnnualChargeability).length > 0) {
            const annualChargeabilityRow: AnnualChargeabilityRow = new AnnualChargeabilityRow();
            annualChargeabilityRow.id = 0
            annualChargeabilityRow.chargeableHours = this.formatToLocale(navigator.language, apiAnnualChargeability.totalAnnualChargeabilityHours === 0 ? zerodecimalPlaces : apiAnnualChargeability.totalAnnualChargeabilityHours.toFixed(decimalPlaces));
            annualChargeabilityRow.nonChargeableHours = this.formatToLocale(navigator.language, apiAnnualChargeability.totalAnnualNonChargeabilityHours.toFixed(decimalPlaces));
            annualChargeabilityRow.chargeabilityPercent = `${apiAnnualChargeability.annualChargeabilityPercentage !== 0 ? (sum / apiAnnualChargeability.annualChargeabilityTimeReport.length).toFixed(decimalPlaces).toString() : this.formatValueToLocaleWithPresicion(zerodecimalPlaces, decimalPlaces, decimalPlaces)}%`;
            annualChargeabilityRows.push(annualChargeabilityRow);
        }
        return annualChargeabilityRows;
    }

    public static mapOTBalanceRequestInput(otBalanceRequestInput: OTBalanceRequestInput): APIOTBalanceRequestInput {
        const apiOTBalanceRequestInput: APIOTBalanceRequestInput = new APIOTBalanceRequestInput();
        if (otBalanceRequestInput && Object.keys(otBalanceRequestInput).length > 0) {
            apiOTBalanceRequestInput.enterpriseId = otBalanceRequestInput.enterpriseId;
            apiOTBalanceRequestInput.periodEnd = otBalanceRequestInput.periodEnd;
            apiOTBalanceRequestInput.requestedHours = otBalanceRequestInput.requestedHours;
        }
        return apiOTBalanceRequestInput;
    }

    public static mapAPIOTBalanceRequestOutput(apiOTBalanceRequestOutput: APIOTBalanceRequestOutput): OTBalanceRequest {
        const otBalanceRequest: OTBalanceRequest = new OTBalanceRequest();
        if (apiOTBalanceRequestOutput && Object.keys(apiOTBalanceRequestOutput).length > 0) {
            Object.assign(otBalanceRequest, apiOTBalanceRequestOutput);
            otBalanceRequest.passed = apiOTBalanceRequestOutput.passed;
            otBalanceRequest.availableHours = this.validatedCashOutOvertime(String(apiOTBalanceRequestOutput.availableHours));
            otBalanceRequest.requestedHours = this.validatedCashOutOvertime(String(apiOTBalanceRequestOutput.requestedHours));
            otBalanceRequest.errorMessage = apiOTBalanceRequestOutput.errorMessage;
        }
        return otBalanceRequest;
    }

    static mapAnnualChargeabilityInfo(annualChargeabilityDTO: any): AnnualChargeability {
        let annualChargeability: AnnualChargeability = new AnnualChargeability();
        let sum = Number();
        annualChargeability.currentHours = this.formatToLocale(navigator.language, annualChargeabilityDTO.totalAnnualChargeabilityHours.toFixed(1));
        annualChargeability.totalHours = this.formatToLocale(navigator.language, annualChargeabilityDTO.totalAnnualStandardHours.toFixed(1));
        annualChargeability.hoursPercentage = (annualChargeabilityDTO.annualChargeabilityPercentage * 100).toFixed(1);
        annualChargeability.gridData = new AnnualChargeabilityGrid();

        annualChargeability.gridData.rowData = annualChargeabilityDTO.annualChargeabilityTimeReport.map((entry, index) => {
            let row = new AnnualChargeabilityParentRow();
            let chargebilityPercent: string;
            row.id = index;
            row.open = false;
            row.periodEnd = moment(entry.periodEnd, 'MM/DD/YYYY').toDate();
            row.chargeableHours = this.validateValue(entry.chargeableHours, false);
            row.nonChargeableHours = this.validateValue(entry.nonChargeableHours, false);
            row.timeReportId = entry.timeReportedId;
            chargebilityPercent = entry.chargebilityPercent.replace('%', '');
            sum += Number(entry.chargebilityPercent.replace('%', ''));
            row.chargeabilityPercent = chargebilityPercent + '%';
            row.tasks = !entry.assignments ? [] : entry.assignments.map(t => {
                let task = new AnnualChargeabilityChildRow();
                task.id = index;
                task.nonChargeableHours = this.validateValue(t.nonChargeableHours, false);
                task.chargeableHours = this.validateValue(t.chargeableHours, false);
                task.chargeCode = t.chargeCode;
                return task;
            });
            return row;
        });

        let row = new AnnualChargeabilityRow();
        row.id = 0;
        row.chargeableHours = this.formatToLocale(navigator.language, annualChargeabilityDTO.totalAnnualChargeabilityHours == 0 ?
            '0.0' : annualChargeabilityDTO.totalAnnualChargeabilityHours.toFixed(1));

        row.nonChargeableHours = this.formatToLocale(navigator.language, annualChargeabilityDTO.totalAnnualNonChargeabilityHours.toFixed(1));
        if (annualChargeabilityDTO.annualChargeabilityPercentage != 0) {
            row.chargeabilityPercent = (sum / annualChargeabilityDTO.annualChargeabilityTimeReport.length).toFixed(1).toString() + '%';
        } else {
            row.chargeabilityPercent = this.formatValueToLocaleWithPresicion('0.0', 1, 1) + '%';
        }

        annualChargeability.gridData.pinnedBottomRowData = [row];
        return annualChargeability;
    }

    public static formatNegative(value: string) {
        if (parseFloat(value) < 0) {
            return value.replace('-', '(') + ')';
        } else {
            return value;
        }
    }

    public static validateValue(value: string, shouldUseTwoDecimal: boolean): string {
        const decimalPlaces: number = shouldUseTwoDecimal ? 2 : 1;
        return Number(value) === 0 || Number(value) === 0.00 ? '' : this.formatValueToLocaleWithPresicion(value, decimalPlaces, decimalPlaces);
    }

    public static formatValueToLocale(value: string): string {
        if (isNaN(Number(value)) || value === null || value === undefined || value === '' || value == '0') { return value; }
        return this.formatNegative(NumberFormatUtils.formatNumberToLocaleString(parseFloat(value), 2, 2));
    }

    public static formatValueToLocaleWithPresicion(value: string, mindigits: number, maxdigits: number): string {
        if (isNaN(Number(value)) || value === null || value === undefined || value === '') { return value; }
        return NumberFormatUtils.formatNumberToLocaleString(parseFloat(value), mindigits, maxdigits);
    }

    public static formatExpensesValue(value: string, decimalPlaces: number ): string {
        if (isNaN(Number(value)) && !isNaN(Number(value.replace(',', ''))))
        value = value.replace(',', '');
        return Number(value) === 0 || Number(value) === 0.00 ? '' : (isNaN(Number(value)) || value === null || value === undefined || value === '') ? value :
           this.formatValueToLocale(value).split('.')[1] == '00' ? NumberFormatUtils.formatNumberToLocaleString(parseFloat(value), decimalPlaces, decimalPlaces) :
           this.formatNegative(NumberFormatUtils.formatNumberToLocaleString(parseFloat(value), decimalPlaces, decimalPlaces));

    }

    private static validatedCashOutOvertime(value: any): string {
        if (value == null || value == undefined || isNaN(value) || (typeof value == "string" && value.trim() == ""))
          return '0';
        return this.parseTwoDecimal(value);
    }

    private static parseTwoDecimal(n: number | string): string {
        const num: string = (typeof n == 'number') ? n.toString() : n;
        return parseFloat(num).toFixed(2);
    }

    static groupSeparator() {
        let separator = (1000).toLocaleString(navigator.language).substring(1, 2);
        if (isNaN(Number(separator)) || separator.charCodeAt(0) == 160) return separator;
        separator = (10000).toLocaleString(navigator.language).substring(2, 3);
        if (isNaN(Number(separator)) || separator.charCodeAt(0) == 160) return separator;

        return '';
    }

    static decimalSeparator = () => (1.1).toLocaleString(navigator.language).substring(1, 2);

    private static formatToLocale(country: string, localeString: string) {
        if (country.includes('es') || country.includes('de')) {
            localeString = localeString.replace(/\./g, ',');
        }
        return localeString;
    }

    /**
     * Checks if the CashOut date is valid.
     * @param today The current date.
     * @returns Returns true if the CashOut date is valid, otherwise returns false.
     */
    public static isValidCashOutOvertimeDate(today: Date): boolean {
        let isBusinessDay: boolean = false;
        // Check if it's the first fortnight of the month
        let isFirstFortnight = new DateformatPipe().transform(today, FormatDateType.isFirstFortnight);
        if (isFirstFortnight) {
            // Calculate the start date of the previous month
            let previousStartDate = new Date(today.toDateString());
            previousStartDate.setDate(1);
            // Calculate the last date of the previous month
            let lastMonthDatePrevious = new Date(previousStartDate.getFullYear(), previousStartDate.getMonth(), 0);
            let previousEndDate = new Date(lastMonthDatePrevious.getTime());
            let remainingBusinessDays = 5;
            // Calculate the last business day prior to the current date
            while (remainingBusinessDays > 0) {
                previousEndDate.setDate(previousEndDate.getDate() + 1);
                isBusinessDay = new DateformatPipe().transform(previousEndDate, FormatDateType.isBusinessDay);
                if (isBusinessDay) {
                    remainingBusinessDays--;
                }
            }
            previousEndDate.setHours(5, 0, 0);
            return (today > previousEndDate);
        } else {
            // Get the current month
            let month = today.getMonth() + 1;
            // Calculate the last business day of the current month
            let lastMonthDate = new Date(today.getFullYear(), month, 0);
            lastMonthDate.setHours(23, 0, 0);
            // Find the last business day prior to the current date
            while (!new DateformatPipe().transform(lastMonthDate, FormatDateType.isBusinessDay)) {
                lastMonthDate.setDate(lastMonthDate.getDate() - 1);
            }
            return (today < lastMonthDate);
        }
    }
}
