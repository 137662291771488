import {
  LocationByDayOutput,
  CountryOutput,
  LocationOneOutput,
  LocationTwoOutput,
  SaveLocationByDayInput,
  LocationDateInfo,
  LocationData,
  LocationDate,
  MultipleLocationsOutput,
  DeleteLocationInput,
  SetDefaultLocationInput,
  SaveMultipleLocationInput,
  LocationsAndHours,
  LocationCode,
  MultipleLocationInfo,
  SaveSwipeCardReasonInput,
  LocationReasonOutput,
  DateEntry as APIDateEntry,
  ChargeCode as APIChargeCode,
  Assignment as APIAssignment
} from '../../../../shared/clients/locations-api-client';
import {
  LocationsByDay,
  PeriodDate,
  LocationEntry,
  LocationInformation,
  DayEntry,
  Country,
  LocationValue,
  MultipleLocation,
  MultipleLocationsCharge,
  ChargeCode,
  LocationCharge,
  LocationEntryStatus,
  LBDChangeReason,
  LocationTwo,
  LocationOne,
  LocationOnes
} from '../../../../shared/models/locations';
import MyteApiMessage from '../../../../shared/models/myte-api-message';

export default class LocationsServiceMapper {

  public static mapLocationsByDay(locationsByDayOutput: LocationByDayOutput): LocationsByDay {
    if (!locationsByDayOutput ||
        !locationsByDayOutput.datesDefinitions ||
        (locationsByDayOutput.datesDefinitions.length == 0)
      ) {
      throw new Error('Something went wrong. Please try again in a few minutes!');
    }
    let locationsByDay = new LocationsByDay();
    let defaultLocation = new LocationInformation(
      locationsByDayOutput.metadata.defaultLocation.locationCode.country,      
      locationsByDayOutput.metadata.defaultLocation.locationCode.locationOne,
      locationsByDayOutput.metadata.defaultLocation.locationCode.locationTwo,
      locationsByDayOutput.metadata.defaultLocation.description,
      locationsByDayOutput.metadata.defaultLocation.locationCode.locationReasonId,
      locationsByDayOutput.metadata.defaultLocation.locationCode.countryLocationDescription,);

    locationsByDay.timeReportStatus = locationsByDayOutput.metadata?.timeReportStatus;
    locationsByDay.defaultLocation = defaultLocation;
    locationsByDay.periodDates = locationsByDayOutput.datesDefinitions.map(date => {
      let periodDate = new PeriodDate();
      periodDate.dateTime = date.datetime;
      periodDate.activeDay = !date.isWeekend;
      periodDate.hasError = date.hasError;
      periodDate.errorMessage = date.errorMessage;
      return periodDate;
    });

    locationsByDay.locationEntries = locationsByDayOutput.locations.map(location => {
      let locationEntry = new LocationEntry();
      locationEntry.location = new LocationInformation(
         location.locationCode.country,         
         location.locationCode.locationOne, 
         location.locationCode.locationTwo, 
         location.description,
         location.locationCode.locationReasonId, 
         location.locationCode.locationReasonNm,
         location.locationCode.countryLocationDescription);

      locationEntry.dayEntries = location.dayEntries.map(date => new DayEntry(date.datetime, date.checked, date.hasAdjustment, date.hasError, date.hours));
      locationEntry.isDefault = locationEntry.location.equals(defaultLocation);
      locationEntry.hasAdjustment = location.hasAdjustment;
      locationEntry.isDeletable = location.isDeletable;
      locationEntry.isInValid = location.isInValid;
      locationEntry.errorMessage = location.errorMessage;
      if(!locationEntry.isDefault){
        locationEntry.status = LocationEntryStatus.saved;
      } 
      return locationEntry;
    });

    if (locationsByDay.locationEntries.length != 0 && locationsByDay.defaultLocation.equals(locationsByDay.locationEntries[0].location)){
      locationsByDay.locationEntries[0].isDefault = true;
    }

    if (locationsByDay.locationEntries && locationsByDay.locationEntries.length > 0)
      locationsByDay.periodDates.forEach(periodDate => locationsByDay.updateMultipleLocations(periodDate.dateTime));

    locationsByDay.messages = locationsByDayOutput.metadata && locationsByDayOutput.metadata.errors
      ? locationsByDayOutput.metadata.errors.map(msg => new MyteApiMessage(msg.message))
      : [];

    locationsByDay.isMultipleLocationsEnabled = locationsByDayOutput.isMultipleLocationsEnabled;
    locationsByDay.isNon8100 = locationsByDayOutput.isNon8100;
    locationsByDay.isEditable = locationsByDayOutput.isEditable;
    locationsByDay.isESTravelPunchClock = locationsByDayOutput.isESTravelPunchClock;

    let lBDChangeReason = new LBDChangeReason();
    if (locationsByDayOutput.lbdChangeReason != null) {
      lBDChangeReason.additionalInformation = locationsByDayOutput.lbdChangeReason.additionalInformation;
      lBDChangeReason.reason = locationsByDayOutput.lbdChangeReason.reason;
      lBDChangeReason.shouldShowLBDReasonPopup = locationsByDayOutput.lbdChangeReason.shouldShowLBDReasonPopup;
      lBDChangeReason.triggerBy = locationsByDayOutput.lbdChangeReason.triggerBy;
    }
    locationsByDay.LBDChangeReason = lBDChangeReason;

    return locationsByDay;
  }

  public static mapCountries(countryOutput: CountryOutput): Array<Country> {
    return Object.entries(countryOutput.countries).map(value => new Country(value[0], value[1]));
  }


  public static mapLocationsOne(locationOneOutput: LocationOneOutput): LocationOnes {
    let locationOne = new LocationOnes();
    locationOne.locationsOne = Object.entries(locationOneOutput.locationsOne).map(value => new LocationValue(value[0].split('/')[0], value[1]));
    locationOne.isReasonForTravelLocationEnable = locationOneOutput.isReasonForTravelLocationEnable;
    return locationOne;
  }

  public static mapLocationsTwo(locationTwoOutput: LocationTwoOutput): LocationTwo {
    let locationTwo = new LocationTwo();
    locationTwo.locationsTwo = Object.entries(locationTwoOutput.locationsTwo).map(value => new LocationValue(value[0].split('/').pop(), value[1]));
    locationTwo.isReasonForTravelLocationEnable = locationTwoOutput.isReasonForTravelLocationEnable;
    return locationTwo;
  }

  public static mapLocationReason(locationReasonOutput: LocationReasonOutput): LocationValue[] {
    return Object.entries(locationReasonOutput.locationReason).map(value => new LocationValue(value[0].split('/').pop(), value[1]));
  }


  public static mapLocationByDayInput(locationsByDay: LocationsByDay, enterpriseId: string, periodEnd: Date, isSubmit?: boolean): SaveLocationByDayInput {
    let saveLocationByDayInput = new SaveLocationByDayInput();
    saveLocationByDayInput.enterpriseId = enterpriseId;
    saveLocationByDayInput.periodEndDateTime = periodEnd;
    saveLocationByDayInput.isAutoSave = locationsByDay.isAutoSave;
    saveLocationByDayInput.locationDateInfo = locationsByDay.locationEntries
      .filter(locationEntry => !locationEntry.location['isNewRow']).map(locationEntry => {
        let locationDateInfo = new LocationDateInfo();

        let locationData = new LocationData();
        locationData.locationCode = new LocationCode();
        locationData.locationCode.country = locationEntry.location.countryCode;
        locationData.locationCode.locationOne = locationEntry.location.locationOneCode;
        locationData.locationCode.locationTwo = locationEntry.location.locationTwoCode;
        locationData.locationCode.locationReasonId = locationEntry.location.locationReasonCode;
        locationData.locationCode.locationReasonNm= locationEntry.location.locationReasonNm;
        locationData.hasAdjustment = false;
        locationData.isDeletable = true;
        locationDateInfo.locationInfo = locationData;

        locationDateInfo.dates = locationEntry.dayEntries
        .filter(dayEntry => dayEntry.checked)
        .map(dayEntry => {
          let locationDate = new LocationDate();
          locationDate.date = dayEntry.entryDate;
          locationDate.hours = dayEntry.hours;
          locationDate.projectChargeCode = dayEntry.chargeCode;
          locationDate.hasAdjustment = false;
          return locationDate;
        });

        return locationDateInfo;
      });

    if (isSubmit)
      saveLocationByDayInput.isSubmit = isSubmit;
      
    return saveLocationByDayInput;
  }

  public static mapMultipleLocations(multipleLocations: MultipleLocationsOutput, locations: LocationInformation[], date: Date): MultipleLocation {
    if (multipleLocations && !multipleLocations.dateEntry) {
      const apiDateEntry: APIDateEntry = new APIDateEntry();
      apiDateEntry.datetime = date;
      apiDateEntry.chargeCodes = new Array<APIChargeCode>();
      multipleLocations.dateEntry = apiDateEntry;
      multipleLocations.assignments = multipleLocations.assignments ? multipleLocations.assignments : new Array<APIAssignment>();
    }
    let result = new MultipleLocation(multipleLocations.dateEntry.datetime);
    result.locations = locations;
    result.charges = multipleLocations.assignments.map(assignment => new MultipleLocationsCharge(
      new ChargeCode(assignment.externalNbr, assignment.description),
      assignment.hours));

    result.charges.forEach(charge => {
      let locationsInfo = multipleLocations.dateEntry.chargeCodes.filter(chargeCode =>
          chargeCode.externalNbr === charge.chargeCode.externalNumber)
        .map(chargeCode => chargeCode.multipleLocationsInfo)
        .reduce((prev, curr) => prev.concat(curr), new Array<MultipleLocationInfo>());

      charge.locationsCharges = locations.map(location => {
        let locationChargeFromAPI = locationsInfo.find(li =>
          new LocationInformation(li.countryCode, li.locationOne, li.locationTwo, li.description, li.locationReason/*, li.locationType*/).equals(location));
        if (locationChargeFromAPI)
          return new LocationCharge(
            new LocationInformation(
              locationChargeFromAPI.countryCode,
              locationChargeFromAPI.locationOne,
              locationChargeFromAPI.locationTwo,
              locationChargeFromAPI.description,
              locationChargeFromAPI.locationReason
            ),
            locationChargeFromAPI.hours, locationChargeFromAPI.hasAdjustment);

        return new LocationCharge(location, 0);
      });
    });

    return result;
  }

  public static mapMultipleLocationsInput(locationsByDay: LocationsByDay, multipleLocations: MultipleLocation, enterpriseId: string, periodEnd: Date): SaveMultipleLocationInput {
    multipleLocations.charges.forEach(charge =>
      charge.locationsCharges = charge.locationsCharges.filter(locationCharge =>
        multipleLocations.locations.find(location => locationCharge.location.equals(location))));

    let saveMultipleLocationsInput = new SaveMultipleLocationInput();
    let locationsAndHours: LocationsAndHours[] = [];
    saveMultipleLocationsInput.enterpriseId = enterpriseId;
    saveMultipleLocationsInput.periodEndDateTime = periodEnd;
    saveMultipleLocationsInput.date = multipleLocations.periodDate;
    saveMultipleLocationsInput.locationDateInfo = locationsByDay.locationEntries
      .filter(locationEntry => !locationEntry.location['isNewRow']).map(locationEntry => {
        let locationDateInfo = new LocationDateInfo();

        let locationData = new LocationData();
        locationData.locationCode = new LocationCode();
        locationData.locationCode.country = locationEntry.location.countryCode;
        locationData.locationCode.locationOne = locationEntry.location.locationOneCode;
        locationData.locationCode.locationTwo = locationEntry.location.locationTwoCode;
        locationData.locationCode.locationReasonId = locationEntry.location.locationReasonCode;
        locationData.hasAdjustment = false;
        locationData.isDeletable = true;
        locationDateInfo.locationInfo = locationData;

        locationDateInfo.dates = locationEntry.dayEntries
        .filter(dayEntry => dayEntry.checked)
        .map(dayEntry => {
          let locationDate = new LocationDate();
          locationDate.date = dayEntry.entryDate;
          locationDate.hours = dayEntry.hours;
          locationDate.projectChargeCode = dayEntry.chargeCode;
          locationDate.hasAdjustment = false;
          return locationDate;
        });

        return locationDateInfo;
      });
    multipleLocations.charges.forEach(chargeEntry => {
      chargeEntry.locationsCharges.forEach( locationChargeEntry => {
            let locationEntry = new LocationsAndHours();
            locationEntry.countryCode = locationChargeEntry.location.countryCode;
            locationEntry.locationOneCode =  locationChargeEntry.location.locationOneCode;
            locationEntry.locationTwoCode = locationChargeEntry.location.locationTwoCode;
            locationEntry.locationReasonCode =  locationChargeEntry.location.locationReasonCode;
            locationEntry.externalNumber = chargeEntry.chargeCode.externalNumber;
            locationEntry.hours = locationChargeEntry.hours;
            locationsAndHours.push(locationEntry);
      });
    });
    saveMultipleLocationsInput.locationsAndHours = locationsAndHours;
    return saveMultipleLocationsInput;
  }


  public static mapDeleteLocationInput(enterpriseId: string, periodEnd: Date, location: LocationInformation): DeleteLocationInput {
    let deleteLocationInput = new DeleteLocationInput();
    deleteLocationInput.enterpriseId = enterpriseId,
      deleteLocationInput.periodEndDateTime = periodEnd;
    deleteLocationInput.countryCode = location.countryCode;
    deleteLocationInput.locationOneCode = location.locationOneCode;
    deleteLocationInput.locationTwoCode = location.locationTwoCode;
    return deleteLocationInput;
  }

  public static mapDefaultLocationInput(location: LocationInformation, enterpriseId: string): SetDefaultLocationInput {
    let defaultLocation: SetDefaultLocationInput = new SetDefaultLocationInput();
    defaultLocation.enterpriseId = enterpriseId;
    let locationInfo = new LocationData();
    locationInfo.locationCode = new LocationCode();
    locationInfo.locationCode.country = location.countryCode;
    locationInfo.locationCode.locationOne = location.locationOneCode;
    locationInfo.locationCode.locationTwo = location.locationTwoCode;
    locationInfo.locationCode.locationReasonId = location.locationReasonCode;
    locationInfo.hasAdjustment = false;
    locationInfo.isDeletable = true;
    defaultLocation.locationInfo = locationInfo;
    return defaultLocation;
  }
  public static mapSaveSwipeCardReasonInput(periodEnd: Date, userEid: string, value: any): SaveSwipeCardReasonInput {
    let saveSwipeCardReasonInput = new SaveSwipeCardReasonInput();
    saveSwipeCardReasonInput.additionalInformation = value.additionalInformation;
    saveSwipeCardReasonInput.enterpriseId = userEid;
    saveSwipeCardReasonInput.periodEndDateTime = periodEnd;
    saveSwipeCardReasonInput.reanson = value.selectItem;
    return saveSwipeCardReasonInput;
  }
}
