<myte-popup-template id="myte-remove-sb-popup" title="{{subordinateMode === 'Approver' ?'Remove Approvee' : 'Remove Reviewee'}}" (closeEvent)="closeModal()">
    <div class="popup-main">
        <p>You are about to remove the selected employee(s) from your list of {{subordinateMode === 'Approver' ?'approvees' : 'reviewees'}}. 
            You will no longer be listed as {{subordinateMode === 'Approver' ?'an' : 'a'}} {{subordinateMode === 'Approver' ?'approver' : 'reviewer'}} for the selected employee(s).
        </p>
    </div>
    <div class="popup-footer">
        <div class="label-controls">
            <label>Are you sure you want to do this?</label>
        </div>
        <div class="btn-controls">
            <button class="myte-button myte-button-cancel" (click)="closeModal()">No</button>
            <button class="myte-button myte-button-submit" myteDebounceClick (debounceClick)="sureToRemove()" [debounceTime]="1000">Yes</button>
        </div>
    </div>
</myte-popup-template>