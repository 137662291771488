import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'myte-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements AfterViewInit{
  @ViewChild('loadingStatus') loadingStatus: ElementRef;
  @Input() public hasLoadingLabel: boolean = false;
  
  ngAfterViewInit(): void {
    this.loadingStatus?.nativeElement?.setAttribute("aria-hidden", "false");
    this.loadingStatus?.nativeElement?.setAttribute("role", "alert");
    setTimeout(()=> {
      this.loadingStatus?.nativeElement?.setAttribute("aria-hidden", "true");
      this.loadingStatus?.nativeElement?.setAttribute("role", "none");

    }, 2000);
    
  }

}
