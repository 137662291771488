import { Component, ElementRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ExpenseFieldWithValidationResult, Option } from '@shared/clients/expense-api-client';
import ExpenseGridItem from '@shared/models/expense/expense-grid-item';
import { IFilterAngularComp } from 'ag-grid-angular';
import { IAfterGuiAttachedParams, IDoesFilterPassParams, IFilterParams } from 'ag-grid-community';

@Component({
  selector: 'myte-expense-grid-filter',
  templateUrl: './expense-grid-filter.component.html',
  styleUrls: ['./expense-grid-filter.component.sass']
})
export class ExpenseGridFilterComponent implements IFilterAngularComp {
  private params: IFilterParams<ExpenseGridItem>;
  public text: string = '';
  public ariaLabel: string;
  @ViewChild('inputText', { static: false }) ngbInputTextBox: ElementRef<HTMLElement>;

  constructor() { }

  public agInit(params: IFilterParams): void {
    this.params = params;
  }

  public isFilterActive(): boolean {
    return this.text !== null && this.text !== undefined && this.text !== '';
  }

  public doesFilterPass(params: IDoesFilterPassParams<ExpenseGridItem>): boolean {
    switch (this.params.colDef.field) {
      case 'sequence':
        return this.columnFilter(params.node.data.sequence);
      case 'source':
        return this.columnFilter(params.node.data.source);
      case 'status':
        return this.columnFilter(params.node.data.status);
      case 'assignment':
        return this.columnFilter(params.node.data.assignment);
      case 'type':
        return this.columnFilter(params.node.data.type);
      case 'additionalInformation':
        return this.columnFilter(params.node.data.additionalInformation);
      default:
        break;
    }
  }

  public columnFilter(column: ExpenseFieldWithValidationResult | Option): boolean {
    return this.text.toLowerCase().split(" ").every(filterWord => {
      return column.value.toString().toLowerCase().indexOf(filterWord) >= 0;
    })
  };

  public getModel(): any {
    return { value: this.text };
  }

  public setModel(model: any): void {
    this.text = model ? model.value : '';
  }

  public ngAfterViewInit(params: IAfterGuiAttachedParams): void {
  }

  public onChange(newValue): void {
    if (this.text !== newValue) {
      this.text = newValue;
      this.params.filterChangedCallback();
    }
  }
}