import { LocationByDay, ViewTaskDto } from '@sharedClients/time-legacy-api-client';
import TimeReportSettings from './time-report-settings';

export enum TimeReportStatusModel {
    New = 'New',
    Draft = 'Draft',
    DraftPendingUpdates = 'DraftPendingUpdates', // Approver Requires Updates
    DraftAdjustment = 'DraftAdjustment',
    Submitted = 'Submitted',
    SubmittedAdjustment = 'SubmittedAdjustment',
    SubmittedPendingApproval = 'SubmittedPendingApproval', // Sent for Approval
    SubmitPendingAdjustment = 'SubmitPendingAdjustment',
    Processed = 'Processed',
    ProcessedAdjustment = 'ProcessedAdjustment'
}


export function getTimeReportStatusModelByString(role: string): TimeReportStatusModel {
    return (TimeReportStatusModel as any)[Object.keys(TimeReportStatusModel).filter(k => (TimeReportStatusModel as any)[k] === role)[0]];
}

export default class TimeReport {
    periodEnd: Date;
    status: string;
    pendingAdjustments: boolean;
    availableAction: string;
    homeCountryKey: string;
    hostCountryKey?: string;
    isHostCountryPilotUser: boolean;
    tasks: ViewTaskDto[];
    sourceSystem: string;
    locationsByDay?: LocationByDay[];
    timeReportSettings: TimeReportSettings;
    constructor(periodEnd?: Date, status?: string, timeReportSettings?: TimeReportSettings) {
        this.periodEnd = periodEnd;
        this.status = status;
        this.timeReportSettings = timeReportSettings;
    }


    public allowExpenseToBeAddedAndDeleted()
    {
        return (this.status == TimeReportStatusModel.Draft || this.status == TimeReportStatusModel.New || this.status == TimeReportStatusModel.DraftPendingUpdates);
    }

    public isSubmittedAdjustmentOrPendingApproval()
    {
        return (this.status == TimeReportStatusModel.Submitted || this.status == TimeReportStatusModel.SubmittedAdjustment || this.status == TimeReportStatusModel.SubmittedPendingApproval);
    }

    public reverseCanBeDisplay()
    {
        return (this.status != TimeReportStatusModel.New && this.status != TimeReportStatusModel.Draft && this.status != TimeReportStatusModel.DraftPendingUpdates);
    }

    public receiptCanBeDeleted()        
    {
        return (this.status == TimeReportStatusModel.New || this.status == TimeReportStatusModel.Draft || this.status == TimeReportStatusModel.DraftPendingUpdates);
    }

    public isAdjusted(): boolean {
        return this.status == TimeReportStatusModel.DraftAdjustment|| this.status == TimeReportStatusModel.SubmitPendingAdjustment;
    }

    public isTravelDiaryModified(){
        return (this.status == TimeReportStatusModel.Draft || this.status == TimeReportStatusModel.New || this.status == TimeReportStatusModel.DraftPendingUpdates || this.status == TimeReportStatusModel.DraftAdjustment ); 
    }

    public isProcessed(){
        return (this.status == TimeReportStatusModel.Processed);
    }

    public isPorcessedAbove(){
        return (this.status == TimeReportStatusModel.DraftAdjustment || this.status == TimeReportStatusModel.SubmitPendingAdjustment || this.status == TimeReportStatusModel.SubmittedAdjustment || this.status == TimeReportStatusModel.ProcessedAdjustment);
    }

    public locationsByDayCanbeEdited(){
        if(this.status == TimeReportStatusModel.Processed || this.status == TimeReportStatusModel.DraftAdjustment || this.status == TimeReportStatusModel.SubmitPendingAdjustment || this.status == TimeReportStatusModel.ProcessedAdjustment){
            return !this.isReadOnlyOlderThanThreeMonths();
        }
        else {
            return (this.status != TimeReportStatusModel.Submitted && this.status != TimeReportStatusModel.SubmittedAdjustment && this.status != TimeReportStatusModel.SubmittedPendingApproval) && !this.isReadonlyOlderThanAYear();
        }    
    }

    private isReadonlyOlderThanAYear(): boolean {
        let currentDate = new Date();
        let limitDate = new Date();
        limitDate.setFullYear(currentDate.getFullYear() - 1);
        return this.getShortDate(this.periodEnd) < this.getShortDate(limitDate)
    }

    private isReadOnlyOlderThanThreeMonths(): boolean {
        let currentDate = new Date();
        let limitDate = new Date();
        limitDate.setMonth(currentDate.getMonth() - 3);
        return this.getShortDate(this.periodEnd) < this.getShortDate(limitDate)
    }

    private getShortDate(shortdate: Date): Number {
        return shortdate.setHours(0,0,0,0);
    }
}