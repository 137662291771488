import { Injectable } from '@angular/core';
import { CellClassParams, ColDef, EditableCallbackParams, GridOptions, ICellEditorParams, ICellRendererParams, RowNode, SuppressHeaderKeyboardEventParams, ValueFormatterParams, ValueGetterParams, ValueSetterParams } from "ag-grid-community";
import { TimeEntryTemplateGridHelper } from '../../../myte-time/shared/services/helpers/time-entry-template-grid.helper';
import { TimeEntryTemplate, TimeEntryTemplateAssignment, TimeEntryTemplateTask } from '../../../shared/models/time-sheet/time-entry/time-entry-template';
import { NumberFormatUtils } from '../../../shared/utils/numberFormater.utils';
import { TimeEntryTemplatePopupGridCellEditor } from '../../components/time-entry-template-popup/time-entry-template-popup-grid/time-entry-template-popup-grid-cell-editor/time-entry-template-popup-grid-cell-editor';
import { TimeEntryTemplatePopupGridCellRender } from '../../components/time-entry-template-popup/time-entry-template-popup-grid/time-entry-template-popup-grid-cell-render/time-entry-template-popup-grid-cell-render.component';
import { TimeEntryTemplatePopupGridChargecodeEditorComponent } from '../../components/time-entry-template-popup/time-entry-template-popup-grid/time-entry-template-popup-grid-chargecode-editor/time-entry-template-popup-grid-chargecode-editor.component';
import { TimeEntryTemplatePopupGridChargecodeRenderComponent } from '../../components/time-entry-template-popup/time-entry-template-popup-grid/time-entry-template-popup-grid-chargecode-render/time-entry-template-popup-grid-chargecode-render.component';
import { TimeEntryTemplatePopupGridHeaderComponent } from '../../components/time-entry-template-popup/time-entry-template-popup-grid/time-entry-template-popup-grid-header/time-entry-template-popup-grid-header.component';
import { TimeEntryTemplatePopupGridTotalRender } from '../../components/time-entry-template-popup/time-entry-template-popup-grid/time-entry-template-popup-grid-total-render/time-entry-template-popup-grid-total-render.component';
import { TimeEntryTemplatePopupTotalGridCellRenderComponent } from '../../components/time-entry-template-popup/time-entry-template-popup-total-grid/time-entry-template-popup-total-grid-cell-render.component';
import { TimeEntryTemplateColumnIdentifier } from './time-entry-template-column-identifier';

export interface TimeEntryTemplateGridOptions {
  bodyOptions: GridOptions<TimeEntryTemplate> | GridOptions<TimeEntryTemplateTask>,
  footerOptions: GridOptions<TimeEntryTemplate> | GridOptions<TimeEntryTemplateTask>
}

@Injectable()
export class TimeEntryTemplateGridService {
  private readonly weekDays: string[] = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  
  constructor() { }

  public getGridOptions(tet: TimeEntryTemplate, shouldUseTwoDecimal: boolean): TimeEntryTemplateGridOptions {
    return {
      bodyOptions: this.getBodyOptions(tet, shouldUseTwoDecimal),
      footerOptions: this.getFooterOptions(shouldUseTwoDecimal)
    }
  }

  private getBodyOptions(tet: TimeEntryTemplate, shouldUseTwoDecimal: boolean): GridOptions<TimeEntryTemplate> | GridOptions<TimeEntryTemplateTask> {
    return {
      rowHeight: 39,
      headerHeight: 44,
      rowSelection: 'single',
      domLayout: 'autoHeight',
      suppressContextMenu: true,
      suppressMenuHide: true,
      suppressNoRowsOverlay: true,
      suppressHorizontalScroll: true,
      suppressAnimationFrame: true,
      stopEditingWhenCellsLoseFocus: true,
      singleClickEdit: true,
      popupParent: document.querySelector('#myte-popup'),
      defaultColDef: this.getDefaultColumnDefinitions(),
      columnDefs: this.getBodyColumnDefinitions(tet, shouldUseTwoDecimal),
      rowData: [],
      components: { agColumnHeader: TimeEntryTemplatePopupGridHeaderComponent },
    } as GridOptions<TimeEntryTemplate> | GridOptions<TimeEntryTemplateTask>
  }

  private getFooterOptions(shouldUseTwoDecimal: boolean): GridOptions<TimeEntryTemplate> | GridOptions<TimeEntryTemplateTask> {
    return {
      suppressCellFocus: true,
      suppressContextMenu: true,
      suppressMenuHide: true,
      suppressNoRowsOverlay: true,
      suppressHorizontalScroll: true,
      stopEditingWhenCellsLoseFocus: true,
      isRowSelectable: (rowNode: RowNode<TimeEntryTemplate>) => false,
      domLayout: 'autoHeight',
      rowHeight: 39,
      headerHeight: 0,
      defaultColDef: this.getDefaultColumnDefinitions(),
      columnDefs: this.getFooterColumnDefinitions(shouldUseTwoDecimal),
      overlayLoadingTemplate: '<div></div>',
    } as GridOptions<TimeEntryTemplate> | GridOptions<TimeEntryTemplateTask>
  }

  private getDefaultColumnDefinitions(): ColDef<TimeEntryTemplateTask> | ColDef<TimeEntryTemplate> {
    return {
      suppressHeaderKeyboardEvent: (event: SuppressHeaderKeyboardEventParams<TimeEntryTemplateTask> | SuppressHeaderKeyboardEventParams<TimeEntryTemplate>) => true,
      sortable: false,
      resizable: false,
      filter: false
    };
  }

  private getBodyColumnDefinitions(tet: TimeEntryTemplate, shouldUseTwoDecimal: boolean): ColDef<TimeEntryTemplateTask>[] {
    const columnDefs: ColDef<TimeEntryTemplateTask>[] = [];
    tet.tasks.forEach(ta => {
      if (ta.assignment.code) {
        tet.assignements.find(a => a.code == ta.assignment.code).selected = true;
      }
    });
    columnDefs.push({
      colId: TimeEntryTemplateColumnIdentifier.Assignment,
      valueGetter: (params: ValueGetterParams<TimeEntryTemplateTask>) => params.data.assignment.displayName,
      valueSetter: (params: ValueSetterParams<TimeEntryTemplateTask>) => {
        if (params.newValue == null) return false;
        if (params.newValue == params.oldValue) return false;
        let oldAssinment = tet.assignements.filter(ta => ta.displayName.replace(/\s*/g, "") == params.oldValue.replace(/\s*/g, ""))[0];
        if (oldAssinment) oldAssinment.selected = false;
        let value = params.newValue as TimeEntryTemplateAssignment;
        params.data.assignment = value;
        return true;
      },
      cellEditor: TimeEntryTemplatePopupGridChargecodeEditorComponent,
      cellEditorParams: { assignments: tet.assignements },
      cellRenderer: TimeEntryTemplatePopupGridChargecodeRenderComponent,
      cellRendererParams: (params: ICellRendererParams<TimeEntryTemplateTask>) => {
        return {
          ariaLabel: TimeEntryTemplateGridHelper.getCellAriaLabel(params),
          id: TimeEntryTemplateGridHelper.getId(params),
          cellId: TimeEntryTemplateGridHelper.setCellId(params),
          assignments: tet.assignements,
        };
      },
      headerName: "Charge Codes",
      width: 200,
      minWidth: 200,
      cellClass: ['cursor-pointer'],
      cellClassRules: {
        'hasError-cell': (params: CellClassParams<TimeEntryTemplateTask, string>) => params.data.assignment.hasError
      },
      editable: true,
      lockPosition: true,
    });

    this.weekDays.forEach((d, index) => columnDefs.push({
      colId: TimeEntryTemplateColumnIdentifier.Date + index,
      cellEditor: TimeEntryTemplatePopupGridCellEditor,
      cellEditorParams: (params: ICellEditorParams<TimeEntryTemplateTask>) => {
        return {
          ariaLabel: TimeEntryTemplateGridHelper.getCellAriaLabel(params),
        }
      },
      cellRenderer: TimeEntryTemplatePopupGridCellRender,
      cellRendererParams: (params: ICellRendererParams<TimeEntryTemplateTask>) => {
        return {
          ariaLabel: TimeEntryTemplateGridHelper.getCellAriaLabel(params),
          id: TimeEntryTemplateGridHelper.getId(params),
          cellId: TimeEntryTemplateGridHelper.setCellId(params),
          isEditable: !params?.data?.assignment?.isEmpty,
        }
      },
      valueGetter: (params: ValueGetterParams<TimeEntryTemplateTask>) => params.data.charges[index],
      valueSetter: (params: ValueSetterParams<TimeEntryTemplateTask>) => {
        let value = Number(params.newValue);
        if (value < 0 || value > 24) return false;
        params.data.charges[index] = isNaN(value) ? 0 : value;
        return true;
      },
      valueFormatter: (params: ValueFormatterParams<TimeEntryTemplateTask>) => !params.value || params.value == 0 ? '' : NumberFormatUtils.formatNumberToLocaleString(params.value, shouldUseTwoDecimal ? 2 : 1, shouldUseTwoDecimal ? 2 : 1),
      editable: (parmas: EditableCallbackParams<TimeEntryTemplateTask>) => !parmas.data.assignment.isEmpty,
      headerName: d,
      headerComponentParams: tet.locationsByWeek[index],
      width: 60,
      minWidth: 60,
      lockPosition: true,
      cellClass: ['center'],
      cellClassRules: {
        'hasError-cell': (params: CellClassParams<TimeEntryTemplateTask, number>) => params.data.assignment.hasError
      }
    }));

    columnDefs.push({
      colId: TimeEntryTemplateColumnIdentifier.Total,
      valueGetter: (params: ValueGetterParams<TimeEntryTemplateTask>) => params.data.charges.reduce((a, b) => a + b, 0),
      valueFormatter: (params: ValueFormatterParams<TimeEntryTemplateTask>) => !params.value || params.value == 0 ? '' : NumberFormatUtils.formatNumberToLocaleString(params.value),
      cellRenderer: TimeEntryTemplatePopupGridTotalRender,
      cellRendererParams: (params: ICellRendererParams<TimeEntryTemplateTask>) => {
        return {
          value: (params.value == null || params.value == '') ? (shouldUseTwoDecimal ? '0.00' : '0') : NumberFormatUtils.formatNumberToLocaleString(params.value, shouldUseTwoDecimal ? 2 : 1, shouldUseTwoDecimal ? 2 : 1),
          ariaLabel: TimeEntryTemplateGridHelper.getCellAriaLabel(params),
          id: TimeEntryTemplateGridHelper.getId(params),
          cellId: TimeEntryTemplateGridHelper.setCellId(params),
        }
      },
      suppressNavigable: true,
      editable: false,
      headerName: "Total",
      width: 60,
      minWidth: 60,
      lockPosition: true,
      cellClass: ['readonly-cell', 'total', 'center'],
    });
    return columnDefs;
  }

  private getFooterColumnDefinitions(shouldUseTwoDecimal: boolean): ColDef<TimeEntryTemplate>[] {
    const columnDefs: ColDef<TimeEntryTemplate>[] = [];
    columnDefs.push({
      colId: TimeEntryTemplateColumnIdentifier.Assignment,
      valueGetter: (params: ValueGetterParams<TimeEntryTemplate>) => 'Total Hours',
      cellRenderer: TimeEntryTemplatePopupTotalGridCellRenderComponent,
      cellRendererParams: (params: ICellRendererParams<TimeEntryTemplate>) => {
        return {
          ariaLabel: TimeEntryTemplateGridHelper.getTotalFooterAria(params),
          id: TimeEntryTemplateGridHelper.getTotalFooterId(params),
        };
      },
      editable: false,
      width: 200,
      minWidth: 200,
      cellClass: ['readonly-cell', 'center'],
    });

    this.weekDays.forEach((d, index) => columnDefs.push({
      colId: TimeEntryTemplateColumnIdentifier.Date + index,
      valueGetter: (params: ValueGetterParams<TimeEntryTemplate>) => params.data.totals[index].toFixed(shouldUseTwoDecimal ? 2 : 1),
      cellRenderer: TimeEntryTemplatePopupTotalGridCellRenderComponent,
      cellRendererParams: (params: ICellRendererParams<TimeEntryTemplate>) => {
        return {
          ariaLabel: TimeEntryTemplateGridHelper.getTotalFooterAria(params, d),
          id: TimeEntryTemplateGridHelper.getTotalFooterId(params, d),
        };
      },
      editable: false,
      width: 60,
      minWidth: 60,
      cellClass: ['readonly-cell', 'center'],
      headerName: d,
    }));

    columnDefs.push({
      colId: TimeEntryTemplateColumnIdentifier.Total,
      valueGetter: (params: ValueGetterParams<TimeEntryTemplate>) => params.data.totals.reduce((a, b) => a + b, 0).toFixed(shouldUseTwoDecimal ? 2 : 1),
      cellRenderer: TimeEntryTemplatePopupTotalGridCellRenderComponent,
      cellRendererParams: (params: ICellRendererParams<TimeEntryTemplate>) => {
        return {
          ariaLabel: TimeEntryTemplateGridHelper.getTotalFooterAria(params),
          id: TimeEntryTemplateGridHelper.getTotalFooterId(params),
        };
      },
      editable: false,
      width: 60,
      minWidth: 60,
      cellClass: ['readonly-cell', 'center'],
      headerName: 'Total',
    });
    return columnDefs;
  }
}

