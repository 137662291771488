import { Injectable } from "@angular/core";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import AuditInquiry from "@shared/models/expense/audit/expense-audit-inquiry";
import Expense from "@shared/models/expense/expense";
import ExpenseView from "@shared/models/expense/expense-view";
import UserPreference from "@shared/models/userPreference";
import { BehaviorSubject, concatMap, forkJoin, map, mergeMap, Observable, of, Subscription, switchMap, tap } from "rxjs";
import { TimeSheetService } from "../../../myte-time/shared/services/timesheet/timesheet.service";
import { GlobalCacheService } from "../cache/global-cache.service";
import { GlobalEventsService } from "../events/global-events.service";
import { ExpenseStoreService, ExpensesResponse, SaveExpenseResponse } from "./expense-store.service";
import { TimePeriodStoreService } from "./time-period-store.service";
import { TimeReportStoreService } from "./time-report-store.service";
import { LogService } from "../log/log.service";
import ExpenseTripName from "@shared/models/expense/trip/expense-trip-name";
import { TimeSheetStoreService } from "./time-sheet-store.service";
import { LocationsService } from "../../../myte-locations/shared/services/locations/locations.service";
import TimeSheet from "@shared/models/time-sheet/time-sheet";
import { WorkLocationDayInfo } from "@shared/clients/locations-api-client";
import TimeReportSettings from "@shared/models/time-report/time-report-settings";
import { UserService } from "../user/user.service";
import { ActivityService } from "../../../myte-activity/shared/services/activity.service";
import { TimeReportStatusModel } from "@shared/models/time-report/time-report";
import { WorkScheduleGroup } from "@shared/models/time-sheet/work-schedule/time-sheet-work-schedule";
import { SubordinatesMode } from "@shared/models/subordinates/subordinate-mode";
import { SubordinatesService } from "../subordinates/subordinates.service";
import Subordinate from "@shared/models/subordinates/subordinate";
import { TimeEntryTemplate } from "@shared/models/time-sheet/time-entry/time-entry-template";
import { TimeEntryTemplateResponse } from "./shared/time-entry-template-response";
import { ShiftSchedule } from "@shared/models/time-sheet/shift-schedule/shift-schedule";
import { LocationEntry, LocationsByDay } from "@shared/models/locations";
import { TimeReportSubmitMode } from "@shared/clients/timereport-api-client";
import { ActionType, exceptionActionStoreLoading } from "./shared/action-type";
import { CompleteAdjustmentResponse } from "./shared/complete-adjustment-response";
import { SubmitResponse } from "./shared/submit-response";

@Injectable({
    providedIn: 'root'
})
export class ActionStoreService {
    private isLoading: BehaviorSubject<boolean> = new BehaviorSubject(true);
    public readonly isLoading$: Observable<boolean> = this.isLoading.asObservable();
    constructor(public router: Router,
        public logService: LogService,
        public activatedRoute: ActivatedRoute,
        public expenseStoreService: ExpenseStoreService,
        public timeSheetStoreService: TimeSheetStoreService,
        public locationsService: LocationsService,
        public globalEventsService: GlobalEventsService,
        public globalCacheService: GlobalCacheService,
        public timePeriodStoreService: TimePeriodStoreService,
        public timeReportStoreService: TimeReportStoreService,
        public timeSheetService: TimeSheetService,
        public userService: UserService,
        public activityService: ActivityService,
        public subordinatesService: SubordinatesService) { }

    public dispatchAction(actionType: ActionType, payload: any): any {
        if (!exceptionActionStoreLoading.includes(actionType)) {
            this.globalEventsService.dispatchShowGlobalSpinnerEvent(true);
        }
        if (actionType !== ActionType.TIME_PERIOD_CHANGED)
            this.isLoading.next(true);
        switch (actionType) {
            case ActionType.UPDATE_TIME_REPORT_STATUS:
                return this.updateTimeReportStatus(payload);
            case ActionType.UPDATE_TIME_REPORT_STATUS_REFRESH_EXPENSES:
                return this.updateTimeReportStatusLoadExpenses(payload);
            case ActionType.TIME_PERIOD_CHANGED:
                return this.updateTimePeriod(payload);
            case ActionType.LOAD_EXPENSES:
                return this.loadExpenses(payload);
            case ActionType.SAVE_EXPENSE:
                return this.saveExpense(payload);
            case ActionType.COPY_EXPENSE:
                return this.copyExpense(payload);
            case ActionType.IMPORT_EXPENSE_AMEX:
                return this.saveImportAmexExpense(payload);
            case ActionType.LOAD_NEXT_EXPENSE_IMPORT_AMEX:
                return this.loadNextForImportAmexExpense(payload);
            case ActionType.DELETE_EXPENSE:
                return this.deleteExpense(payload.expensesIds);
            case ActionType.LOAD_TIME_REPORT:
                return this.loadTimeReport(payload.periodEnd, payload.triggerSpinner);
            case ActionType.SUBMIT_TIME_REPORT:
                return this.submitTimeReport(payload.periodEnd, payload.ppaReason, payload.triggerSpinner);
            case ActionType.SEND_FOR_APPROVAL:
                return this.sendForApproval();
            case ActionType.VALIDATE_TIME_REPORT_AND_SUBMIT:
                return this.validateTimeReportSubmit(payload.periodEnd, payload.subordinateMode, payload.enterpriseId, payload.isSendForApproval, payload.isSubmitOnActivity, payload.submissionMode);
            case ActionType.RELOAD_TIME_REPORT:
                return this.reloadTimeReport(payload.periodEnd);
            case ActionType.REVERSE_EXPENSE:
                this.reverseExpense(payload);
                break;
            case ActionType.UPDATE_TRIP:
                return this.updateTrip(payload);
                break;
            case ActionType.CREATE_TRIP:
                return this.createTrip(payload);
                break;
            case ActionType.APPLY_TRIP:
                return this.applyTrip(payload);
                break;
            case ActionType.DELETE_TRIP:
                return this.deleteTrip(payload);
                break;
            case ActionType.LOAD_TIME_SHEET:
                return this.loadTimeSheet(payload.periodEnd, payload.triggerSpinner, payload.shouldInitialize);
                break;
            case ActionType.CLEAR_TIME_SHEET:
                return this.clearTimeSheetVerifyLoad(payload.periodEnd, payload.triggerSpinner);
                break;
            case ActionType.SAVE_TIME_SHEET:
                return this.saveTimeSheet(payload.periodEnd, payload.triggerSpinner, payload.toastMessage);
                break;
            case ActionType.UPDATE_TIME_REPORT_CLEAR_TIMESHEET_EXPENSES:
                return this.updateTimeReportToDraft(payload.periodEnd, payload.shouldInitialize, payload.triggerSpinner);
                break;
            case ActionType.COMPLETE_ADJUSTMENTS:
                return this.completeAdjustment(payload.countryKey, payload.periodEnd);
            case ActionType.REMOVE_ADJUSTMENTS:
                return this.removeAdjustments(payload.periodEnd, payload.viewMode);
            case ActionType.UPDATE_TIMESHEET:
                return this.updateTimeSheet(payload.periodEnd, payload.timeSheet, payload.triggerSpinner);
            case ActionType.UPDATE_AND_SAVE_TIMESHEET:
                return this.updateAndSaveTimeSheet(payload.periodEnd, payload.timeSheet, payload.triggerSpinner);
            case ActionType.SAVE_WORK_SCHEDULE:
                return this.saveWorkSchedule(payload.periodEnd, payload.workScheduleGroup, payload.triggerSpinner);
            case ActionType.SELECT_SUBORDINATE:
                return this.selectSubordinate(payload.subordiante, payload.subordinateMode);
            case ActionType.LOAD_PREFERENCES:
                return this.loadPreferences();
            case ActionType.SAVE_TIME_ENTRY_TEMPLATE:
                return this.saveTimeEntryTemplate(payload.timeEntryTemplate, payload.periodEnd, payload.isTimeTab, payload.triggerSpinner);
            case ActionType.SAVE_SHIFT_SCHEDULE:
                return this.saveShiftSchedule(payload.shiftSchedule, payload.periodEnd, payload.timePeriodDates, payload.triggerSpinner);
            case ActionType.SAVE_SHIFT_SCHEDULE_CUSTOM_DAY:
                return this.saveShiftScheduleCustomDay(payload.shiftType, payload.periodEnd, payload.date, payload.triggerSpinner);
            case ActionType.UPDATE_STORES_BY_TAB:
                return this.updateStoresByTab(payload.periodEnd, payload.shouldInitialize);
            case ActionType.SAVE_LOCATIONS:
                return this.saveLocations(payload.periodEnd, payload.locationsByDay);
            case ActionType.DELETE_LOCATIONS:
                return this.deleteLocations(payload.periodEnd, payload.locationsByDay, payload.locationEntry);
            default:
                break;
        }
    }

    private removeAdjustments(periodEnd: Date, viewMode: any) {
        const currentTab = this.router.url.split('?')[0].split('/')[1];
        // const isExpenseTab = this.router.url.split('?')[0].split('/')[1] == 'expenses';
        return this.timeReportStoreService.removeCurrentPPA(viewMode)
                .pipe(mergeMap(removePPAresponse => {
                    if (currentTab == 'time') {
                        return forkJoin([
                                of(removePPAresponse),
                                this.timeSheetStoreService.loadTimeSheet(periodEnd, true, false),
                                of(this.expenseStoreService.handleExpensesCache(periodEnd)),
                                of(this.globalCacheService.clearLocationsByDayFromBuffer(periodEnd)),
                                of(this.globalCacheService.clearTabsByDayFromBuffer(periodEnd))
                            ]);
                    }
                    if (currentTab == 'expenses') {
                        return forkJoin([
                                of(removePPAresponse),
                                this.expenseStoreService.loadExpenses(true, periodEnd, true),
                                of(this.timeSheetStoreService.resetTimeSheetCache()),
                                of(this.globalCacheService.clearLocationsByDayFromBuffer(periodEnd)),
                                of(this.globalCacheService.clearTabsByDayFromBuffer(periodEnd))
                            ]);
                    }
                    return forkJoin([
                            of(removePPAresponse),
                            of(this.expenseStoreService.handleExpensesCache(periodEnd)),
                            of(this.timeSheetStoreService.resetTimeSheetCache()),
                            of(this.globalCacheService.clearLocationsByDayFromBuffer(periodEnd)),
                            of(this.globalCacheService.clearTabsByDayFromBuffer(periodEnd))
                        ]);
                }))
                .pipe(switchMap(([timeReportSettingsResponse, clearResponse]) => {
                    if (this.globalCacheService.getCurrentSubordinate()?.enterpriseId)
                      this.globalEventsService.dispatchUpdateTimeReportSubordinateSelected(timeReportSettingsResponse.timeReportStatus, this.globalCacheService.getCurrentSubordinate().enterpriseId);
                    return of(timeReportSettingsResponse.timeReportStatus);
                }))
    }

    private completeAdjustment(countryKey: string, periodEnd: Date): Observable<CompleteAdjustmentResponse> {
        let completeAdjustmentResponse = new CompleteAdjustmentResponse();
        const activityObs$ = countryKey == 'DE' ? this.globalCacheService.getActivityGrid(periodEnd, this.userService.getUser().enterpriseId) : of(null);
        return activityObs$
                .pipe(mergeMap(activity => {
                    if (activity && !this.activityService.validationSaveActivity(activity)) {
                        completeAdjustmentResponse.activityValidationError = true;
                        return of(completeAdjustmentResponse);
                    }
                    return forkJoin([
                        this.timeSheetStoreService.saveTimeSheetIfNeeded(periodEnd),
                        this.timeSheetStoreService.saveLocationsIfNeeded(periodEnd, this.timeReportStoreService.getCurrentTimeReportStatus()),
                        activity ? this.activityService.SaveActivities(true, activity.activityOutputItem) : of(null)
                    ])
                    .pipe(switchMap(([timeSheetSaved, locationByDaySaved, activitySaved]) => {
                        return forkJoin([
                            of(timeSheetSaved),
                            of(locationByDaySaved),
                            of(activitySaved),
                            this.timeReportStoreService.loadTimeReport(periodEnd, [timeSheetSaved, locationByDaySaved, activitySaved].some(saved => saved != null))
                        ])
                    }))
                    .pipe(switchMap(([timeSheetSaved, locationByDaySaved, activitySaved, timeReportSettings]) => {
                        completeAdjustmentResponse.isESTravelPunchClock = timeSheetSaved?.isESTravelPunchClock || locationByDaySaved?.isESTravelPunchClock;
                        if (activitySaved && !this.activityService.validationSubmitActivity(activitySaved)) {
                            completeAdjustmentResponse.shouldSubmit = false;
                            completeAdjustmentResponse.activityValidationSubmitError = true
                            this.isLoading.next(false);
                            this.globalEventsService.dispatchShowGlobalSpinnerEvent(false);
                            return of(completeAdjustmentResponse);
                        }
                        if (timeReportSettings.timeReportStatus == TimeReportStatusModel.SubmitPendingAdjustment && (!timeSheetSaved?.modified) && !locationByDaySaved) {
                            completeAdjustmentResponse.timeSheetSaveError = true;
                            completeAdjustmentResponse.shouldSubmit = false;
                            this.isLoading.next(false);
                            this.globalEventsService.dispatchShowGlobalSpinnerEvent(false);
                            return of(completeAdjustmentResponse);
                        }
                        if (locationByDaySaved?.messages?.length > 0) {
                            completeAdjustmentResponse.locationByDaySaveError = true;
                            completeAdjustmentResponse.shouldSubmit = false;
                            this.logService.showValidationMessage("", locationByDaySaved.messages[0].Text);
                            this.isLoading.next(false);
                            this.globalEventsService.dispatchShowGlobalSpinnerEvent(false);
                            return of(completeAdjustmentResponse);
                        }

                        this.isLoading.next(false);
                        this.globalEventsService.dispatchShowGlobalSpinnerEvent(false);
                        return of(completeAdjustmentResponse);
                    }))
                }))
    }

    private validateTimeReportSubmit(periodEnd: Date, subordinateMode: SubordinatesMode, enterpriseId: string, isSendForApproval: boolean, isSubmitOnActivity: boolean, submissionMode: TimeReportSubmitMode): Observable<SubmitResponse> {
        let submitResponse = new SubmitResponse();
        const currentTab = (this.router.url.split('?')[0].split('/')[1]);
        const isTimeTab = currentTab == 'time';
        const isExpenseTab = currentTab == 'expense';
        return forkJoin([
                    this.globalCacheService.getActivityGrid(periodEnd, this.userService.getUser().enterpriseId),
                    !isTimeTab ? this.timeSheetStoreService.loadTimeSheet(periodEnd, false) : of(null)
                ])
                .pipe(concatMap(([activity, timeSheet]) => {
                    if (activity && !this.activityService.validationSaveActivity(activity)) {
                        submitResponse.activityValidationError = true;
                        return of(submitResponse);
                    }
                    if (!this.timeSheetStoreService.isWorkScheduleComplete()) {
                        submitResponse.timeSheetWorkScheduleIncomplete = true;
                        return of(submitResponse);
                    }
                     if (!this.timeSheetStoreService.shouldSaveTimeSheetAndLocations(subordinateMode)) {
                        submitResponse.ignoreSave = true;
                        // return of(submitResponse);
                    }
                    return forkJoin([
                        submitResponse.ignoreSave ? of(null) : this.timeSheetStoreService.saveTimeSheetIfNeededForSubmit(periodEnd),
                        submitResponse.ignoreSave ? of(null) : this.timeSheetStoreService.saveLocationsIfNeeded(periodEnd, this.timeReportStoreService.getCurrentTimeReportStatus(), true),
                        submitResponse.ignoreSave || !activity ? of(null) : this.activityService.SaveActivities(true, activity.activityOutputItem)
                    ])
                    .pipe(concatMap(([timeSheetSaved, locationByDaySaved, activitySaved]) => {
                        return forkJoin([
                            of(timeSheetSaved),
                            of(locationByDaySaved),
                            of(activitySaved),
                            locationByDaySaved != null ? this.timeReportStoreService.loadTimeReport(periodEnd, true) : of(null)
                        ])
                    }))
                    .pipe(concatMap(([timeSheetSaved, locationByDaySaved, activitySaved, timeReportSettings]) => {
                        submitResponse.shouldSubmit = true;
                        submitResponse.isESTravelPunchClock = timeSheetSaved?.isESTravelPunchClock || locationByDaySaved?.isESTravelPunchClock;
                        submitResponse.isTimeSheetSaved = timeSheetSaved != null;
                        submitResponse.isActivitySaved = activitySaved != null;
                        submitResponse.isLocationByDaySaved = locationByDaySaved != null;
                        if (activitySaved) {
                            const isValidActivity = this.activityService.validationSubmitActivity(activitySaved);
                            activitySaved.modified = !isValidActivity;
                            this.globalCacheService.setActivityGrid(periodEnd, of(activitySaved), enterpriseId);
                            if (!isValidActivity) {
                                submitResponse.shouldSubmit = false;
                                submitResponse.activityValidationSubmitError = true;
                                this.isLoading.next(false);
                                this.globalEventsService.dispatchShowGlobalSpinnerEvent(false);
                                return of(submitResponse);
                            }
                        }
                        if (submitResponse.isLocationByDaySaved) {
                            submitResponse.locationByDayUpdated = locationByDaySaved;
                            submitResponse.canViewTravelDiaryLink = timeReportSettings.travelDiaryOptions.canViewTravelDiaryLink;
                            if (locationByDaySaved?.messages?.length > 0) {
                                submitResponse.locationByDaySaveError = true;
                                submitResponse.shouldSubmit = false;
                                this.isLoading.next(false);
                                this.globalEventsService.dispatchShowGlobalSpinnerEvent(false);
                                return of(submitResponse);
                            }
                        }
                        return of(submitResponse);
                    }))
                    .pipe(concatMap(submitResponse => {
                        return forkJoin([
                                    of(submitResponse),
                                    submitResponse.shouldSubmit ? this.timeReportStoreService.validateTimeReport(periodEnd, isSendForApproval, isTimeTab, isSubmitOnActivity) : of(null)
                                ])
                    }))
                    .pipe(concatMap(([submitResponse, timeReportValidations]) => {
                        const shouldLoadTimesheetAndExpnses = submitResponse.shouldSubmit || timeReportValidations?.ispassed == false;
                        return forkJoin([
                                    of(submitResponse),
                                    of(timeReportValidations),
                                    this.timeReportStoreService.loadTimeReport(periodEnd, true),
                                    shouldLoadTimesheetAndExpnses ? this.timeSheetStoreService.loadTimeSheetForSubmission(periodEnd, submissionMode, timeReportValidations.totalHoursValidationErrorOutPut) : of(null),
                                    shouldLoadTimesheetAndExpnses && timeReportValidations.federalErrorSources.length > 0 && isExpenseTab ? this.expenseStoreService.loadExpenses(true, periodEnd, true) :
                                                                                                                                                of(this.expenseStoreService.handleExpensesCache(periodEnd))
                                ])
                    }))
                    .pipe(switchMap(([submitResponse, timeReportValidations, timeReport, timeSheetLoad, expensesLoad]) => {
                        submitResponse.timeReportValidationErrors = timeReportValidations;
                        if (!this.timeSheetStoreService.isWorkScheduleComplete()) {
                            submitResponse.timeSheetWorkScheduleIncomplete = true;
                            return of(submitResponse);
                        }
                        this.isLoading.next(false);
                        this.globalEventsService.dispatchShowGlobalSpinnerEvent(false);
                        return of(submitResponse);
                    }))
                }))
    }

    private submitTimeReport(periodEnd: Date, ppaReason: string, triggerSpinner: boolean) {
        const currentTab = (this.router.url.split('?')[0].split('/')[1]);
        return this.timeReportStoreService.submitTimeReport(ppaReason)
                .pipe(switchMap(submitTimeReportResponse => {
                    if (currentTab == 'time') {
                        return forkJoin([
                                of(submitTimeReportResponse),
                                this.timeSheetStoreService.loadTimeSheet(periodEnd, true, false),
                                of(this.expenseStoreService.handleExpensesCache(periodEnd)),
                                of(this.globalCacheService.clearLocationsByDayFromBuffer(periodEnd)),
                                of(this.globalCacheService.clearTabsByDayFromBuffer(periodEnd))
                            ]);
                    }
                    if (currentTab == 'expenses') {
                        return forkJoin([
                                of(submitTimeReportResponse),
                                this.expenseStoreService.loadExpenses(true, periodEnd, true),
                                of(this.timeSheetStoreService.resetTimeSheetCache()),
                                of(this.globalCacheService.clearLocationsByDayFromBuffer(periodEnd)),
                                of(this.globalCacheService.clearTabsByDayFromBuffer(periodEnd))
                            ]);
                    }
                    return forkJoin([
                        of(submitTimeReportResponse),
                        of(this.expenseStoreService.handleExpensesCache(periodEnd)),
                        of(this.timeSheetStoreService.resetTimeSheetCache()),
                        of(this.globalCacheService.clearLocationsByDayFromBuffer(periodEnd)),
                        of(this.globalCacheService.clearTabsByDayFromBuffer(periodEnd))
                    ]);
                }))
                .pipe(tap(submitTimeReportResponse => {
                    if (triggerSpinner) {
                        this.isLoading.next(false);
                        this.globalEventsService.dispatchShowGlobalSpinnerEvent(false);
                    }
                }));
    }

    private sendForApproval(): Observable<[TimeReportSettings, string[]]> {
        const currentTab = (this.router.url.split('?')[0].split('/')[1]);
        return this.timeReportStoreService.sendForApproval()
                .pipe(switchMap(([sendForApprovalResponse, periodEnd]) => {
                    if (currentTab == 'time') {
                        return forkJoin([
                                of(sendForApprovalResponse),
                                this.globalCacheService.getSWAfterSubmission(periodEnd),
                                this.timeSheetStoreService.loadTimeSheet(periodEnd, true, false),
                                of(this.expenseStoreService.handleExpensesCache(periodEnd)),
                                of(this.globalCacheService.clearLocationsByDayFromBuffer(periodEnd)),
                                of(this.globalCacheService.clearTabsByDayFromBuffer(periodEnd))
                            ]);
                    }
                    if (currentTab == 'expenses') {
                        return forkJoin([
                                of(sendForApprovalResponse),
                                this.globalCacheService.getSWAfterSubmission(periodEnd),
                                this.expenseStoreService.loadExpenses(true, periodEnd, true),
                                of(this.timeSheetStoreService.resetTimeSheetCache()),
                                of(this.globalCacheService.clearLocationsByDayFromBuffer(periodEnd)),
                                of(this.globalCacheService.clearTabsByDayFromBuffer(periodEnd))
                            ]);
                    }
                    return forkJoin([
                        of(sendForApprovalResponse),
                        this.globalCacheService.getSWAfterSubmission(periodEnd),
                        of(this.expenseStoreService.handleExpensesCache(periodEnd)),
                        of(this.timeSheetStoreService.resetTimeSheetCache()),
                        of(this.globalCacheService.clearLocationsByDayFromBuffer(periodEnd)),
                        of(this.globalCacheService.clearTabsByDayFromBuffer(periodEnd))
                    ]);
                }))
                .pipe(switchMap(([sendForApprovalResponse, softWarningSubmission]) => {
                    this.isLoading.next(false);
                    this.globalEventsService.dispatchShowGlobalSpinnerEvent(false);
                    return forkJoin([of(sendForApprovalResponse), of(softWarningSubmission)]);
                }));
    }

    private updateTimeReportToDraft(periodEnd: Date, shouldInitialize: boolean, triggerSpinner: boolean) {
        const currentTab = (this.router.url.split('?')[0].split('/')[1]);
        let isFromUpdateTimeReportToDraft : boolean = false;
        return this.timeReportStoreService.updateTimeReportToDraft(periodEnd)
                .pipe(tap(datesResponse => {
                    datesResponse.push(periodEnd);
                    this.expenseStoreService.resetBufferExpensesByDates(datesResponse);
                }))
                .pipe(switchMap(response => {
                    if (currentTab == 'time') {
                        return forkJoin([
                                this.timeSheetService.clearSoftWarningErrorsCacheTemp(periodEnd),
                                this.timeSheetStoreService.loadTimeSheet(periodEnd, shouldInitialize, false),
                                of(this.expenseStoreService.handleExpensesCache(periodEnd)),
                                of(this.globalCacheService.clearLocationsByDayFromBuffer(periodEnd))
                            ]);
                    }
                    if (currentTab == 'expenses') {
                        isFromUpdateTimeReportToDraft = true;
                        return forkJoin([
                                this.timeSheetService.clearSoftWarningErrorsCacheTemp(periodEnd),
                                this.expenseStoreService.loadExpenses(shouldInitialize, periodEnd, shouldInitialize, isFromUpdateTimeReportToDraft),
                                of(this.timeSheetStoreService.resetTimeSheetCache()),
                                of(this.globalCacheService.clearLocationsByDayFromBuffer(periodEnd))
                            ]);
                    }
                    return forkJoin([
                            this.timeSheetService.clearSoftWarningErrorsCacheTemp(periodEnd),
                            of(this.expenseStoreService.handleExpensesCache(periodEnd)),
                            of(this.timeSheetStoreService.resetTimeSheetCache()),
                            of(this.globalCacheService.clearLocationsByDayFromBuffer(periodEnd))
                        ]);
                }))
                .pipe(switchMap(response => {
                    if (triggerSpinner) {
                        this.isLoading.next(false);
                        this.globalEventsService.dispatchShowGlobalSpinnerEvent(false);
                    }
                    return of(response);
                }));
    }

    public updateChargeCodeDismiss(chargeCodeRelated: string, dismissCheckboxValue: boolean, periodEnd: Date): Observable<boolean> {
        const currentURL = (this.router.url.split('?')[0].split('/')[1]);
        return this.timeSheetStoreService.updateIsDismissChecked(chargeCodeRelated, dismissCheckboxValue, periodEnd)
        .pipe(mergeMap(dismissResponse => {
            if (!dismissResponse || !['time', 'expenses'].includes(currentURL)) {
                return forkJoin([
                    of(null),
                    of(dismissResponse)
                ]);
            }
            if (currentURL == 'time') {
                return forkJoin([
                        this.timeSheetStoreService.loadTimeSheet(periodEnd, false),
                        of(dismissResponse)
                    ]);
            }
            
            if (currentURL == 'expenses') {
                return forkJoin([
                        this.expenseStoreService.loadExpenses(true, periodEnd, true),
                        of(dismissResponse)
                    ]);
            }
        }))
        .pipe(mergeMap(([savedResponse, dismissResponse]) => {
            return of(dismissResponse);
        }))
    }

    public updateStoresByTab(periodEnd: Date, shouldInitialize: boolean): Observable<any> {
        const currentTab = (this.router.url.split('?')[0].split('/')[1]);
        if (currentTab == 'time') {
            return forkJoin([
                    this.timeSheetStoreService.loadTimeSheet(periodEnd, shouldInitialize, false),
                    of(this.expenseStoreService.handleExpensesCache(periodEnd))
                ])
                .pipe(tap(response => {
                    this.isLoading.next(false);
                    this.globalEventsService.dispatchShowGlobalSpinnerEvent(false);
                    return response;
                }));
        }
        if (currentTab == 'expenses') {
            return forkJoin([
                    this.expenseStoreService.loadExpenses(shouldInitialize, periodEnd, shouldInitialize),
                    of(this.timeSheetStoreService.resetTimeSheetCache())
                ])
                .pipe(tap(response => {
                    this.isLoading.next(false);
                    this.globalEventsService.dispatchShowGlobalSpinnerEvent(false);
                    return response;
                }));
        }
        return forkJoin([
                    of(this.expenseStoreService.handleExpensesCache(periodEnd)),
                    of(this.timeSheetStoreService.resetTimeSheetCache())
                ])
                .pipe(tap(response => {
                    this.isLoading.next(false);
                    this.globalEventsService.dispatchShowGlobalSpinnerEvent(false);
                    return response;
                }));
    }

    private loadPreferences(): Observable<UserPreference> {
        return this.timeReportStoreService.loadUserPreferences()
                .pipe(tap(preferencesResponse => {
                    this.globalEventsService.dispatchShowGlobalSpinnerEvent(false);
                    this.isLoading.next(false);
                }));
    }

    private updateTimeReportStatus(payload): void {
        of(this.timeReportStoreService.updateTimeReportStatus(payload.status, payload.periodEnd))
            .subscribe(response => {
                this.globalEventsService.dispatchShowGlobalSpinnerEvent(false);
                this.isLoading.next(false);
            });
    }

    private updateTimeReportStatusLoadExpenses(payload): void {
        this.expenseStoreService.loadExpenses(true)
            .pipe(switchMap(loadExpenseResponse => { return of(this.timeReportStoreService.updateTimeReportStatus(loadExpenseResponse[0]?.timeReportStatus)); }))
            .subscribe(loadExpenseResponse => {
                this.isLoading.next(false);
                this.globalEventsService.dispatchShowGlobalSpinnerEvent(false);
            })
    }

    private reverseExpense(payload: any) {
        this.expenseStoreService.reverseExpense(payload.expensesIds, payload.countryKey)
            .pipe(switchMap(res => {
                return this.expenseStoreService.loadExpenses(true)
            }))
            .pipe(switchMap(res => {
                return this.timeReportStoreService.loadTimeReport(payload.periodEnd, true);
            }))
            .subscribe(data => {
                this.isLoading.next(false);
                this.globalEventsService.dispatchShowGlobalSpinnerEvent(false);
            });
    }

    private loadTimeReport(periodEnd: Date, triggerSpinner: boolean = true) {
        return forkJoin([
                    this.timeReportStoreService.loadTimeReport(periodEnd),
                    this.timeReportStoreService.loadAuditInquiry(),
                    of(this.timePeriodStoreService.updatePeriodEnd(periodEnd))
                ])
                .pipe(tap(data => {
                    if (triggerSpinner) {
                        this.isLoading.next(false);
                        this.globalEventsService.dispatchShowGlobalSpinnerEvent(false);
                    }
                }));
    }

    private reloadTimeReport(periodEnd: Date) {
        const currentTab = (this.router.url.split('?')[0].split('/')[1]);
        return forkJoin([
                    this.timeReportStoreService.loadTimeReport(periodEnd, true),
                    of(this.timePeriodStoreService.updatePeriodEnd(periodEnd))
                ])
                .pipe(switchMap(response => {
                    if (currentTab == 'time') {
                        return forkJoin([
                                this.timeSheetStoreService.loadTimeSheet(periodEnd, true, false),
                                of(this.expenseStoreService.handleExpensesCache(periodEnd))
                            ]);
                    }
                    if (currentTab == 'expenses') {
                        return forkJoin([
                                this.expenseStoreService.loadExpenses(true, periodEnd, true),
                                of(this.timeSheetStoreService.resetTimeSheetCache())
                            ]);
                    }
                    return forkJoin([
                            of(this.expenseStoreService.handleExpensesCache(periodEnd)),
                            of(this.timeSheetStoreService.resetTimeSheetCache())
                        ]);
                }))
                .subscribe(data => {
                    this.isLoading.next(false);
                    this.globalEventsService.dispatchShowGlobalSpinnerEvent(false);
                });
    }

    // ToDo: Improve this method to be able to update other stores and once the redirection is deleted, make the calls needed to update those stores
    private updateTimePeriod(payload: any): Subscription {
        return forkJoin([
                    this.timeSheetService.clearSoftWarningErrorsCacheTemp(payload.periodEnd),
                    this.expenseStoreService.updatePeriodEnd(payload.periodEnd),
                    this.timeReportStoreService.loadTimeReport(payload.periodEnd),
                    of(this.timePeriodStoreService.updatePeriodEnd(payload.periodEnd))
                ])
                .subscribe(data => {
                    this.isLoading.next(false);
                    if (payload.needRedirect) {
                        this.redirect(payload.periodEnd);
                    }
                    this.globalEventsService.dispatchShowGlobalSpinnerEvent(false);
                });
    }

    private loadExpenses(payload: any): Subscription {
        return this.expenseStoreService.loadExpenses(payload.shouldInitialize, payload.periodEnd)
        .subscribe(data => {
            this.globalEventsService.dispatchShowGlobalSpinnerEvent(false);
            this.isLoading.next(false);
        });
    }

    private saveExpense(payload): Observable<SaveExpenseResponse> {
        return this.expenseStoreService.saveExpense()
            .pipe(tap(saveExpenseResponse => {
                this.timeReportStoreService.updateTimeReportStatus(saveExpenseResponse.expenseView.timeReportStatus);
                this.globalEventsService.dispatchShowGlobalSpinnerEvent(false);
                this.isLoading.next(false);
                return saveExpenseResponse;
            }));
    }

    private saveImportAmexExpense(payload): Observable<SaveExpenseResponse> {
        return this.expenseStoreService.saveExpense(payload.shouldInitialize, payload.amexInfo, payload.shouldImportWithTransaction)
            .pipe(tap(importAmexResponse => {
                this.timeReportStoreService.updateTimeReportStatus(importAmexResponse.expenseView.timeReportStatus);
                this.globalEventsService.dispatchShowGlobalSpinnerEvent(false);
                this.isLoading.next(false);
                return importAmexResponse;
            }));
    }

    private loadNextForImportAmexExpense(payload): Observable<ExpenseView> {
        return this.expenseStoreService.importNextAmexExpense(payload.amexInput)
            .pipe(tap(data => {
                this.isLoading.next(false);
                this.globalEventsService.dispatchShowGlobalSpinnerEvent(false);
                return data;
            }));
    }

    private deleteExpense(expensesIds: string[]): Observable<[ExpensesResponse, UserPreference, AuditInquiry[], ExpenseTripName[]]> {
        return this.expenseStoreService.deleteExpense(expensesIds)
            .pipe(switchMap(response => {
                return this.expenseStoreService.loadExpenses(response)
            }))
            .pipe(map(response => {
                this.isLoading.next(false);
                this.globalEventsService.dispatchShowGlobalSpinnerEvent(false);
                return response;
            }));
    }

    private copyExpense(payload: any): Observable<SaveExpenseResponse> {
        return this.expenseStoreService.saveExpenseAsCopy()
            .pipe(tap(saveResponse => {
                if (saveResponse.passed && saveResponse.needUpdateTimeReportStatus)
                    this.timeReportStoreService.updateTimeReportStatus(saveResponse.expenseView.timeReportStatus);
                this.isLoading.next(false);
                this.globalEventsService.dispatchShowGlobalSpinnerEvent(false);
            }));
    }

    public updateTrip(payload: any): void {
        this.expenseStoreService.updateTrip(payload.tripId, payload.tripName)
            .pipe(switchMap(updateTripResponse => this.expenseStoreService.loadExpenses(true)))
            .subscribe(data => {
                this.globalEventsService.dispatchShowGlobalSpinnerEvent(false);
                this.isLoading.next(false);
            });
    }

    public createTrip(payload): void {
        this.expenseStoreService.saveTrip(payload.expenseTripCreateInput)
            .pipe(switchMap(updateTripResponse => this.expenseStoreService.loadExpenses(true)))
            .subscribe(data => {
                this.globalEventsService.dispatchShowGlobalSpinnerEvent(false);
                this.isLoading.next(false);
            });
    }

    public applyTrip(payload): Observable<[ExpensesResponse, UserPreference, AuditInquiry[], ExpenseTripName[]]> {
        return this.expenseStoreService.applyTrip(payload.expenseTripReviewView)
            .pipe(switchMap(applyTripResponse => this.expenseStoreService.loadExpenses(true)))
            .pipe(tap(data => {
                this.globalEventsService.dispatchShowGlobalSpinnerEvent(false);
                this.isLoading.next(false);
                return of(data);
            }));
    }

    public deleteTrip(payload): Observable<[ExpensesResponse, UserPreference, AuditInquiry[], ExpenseTripName[]]> {
        return this.expenseStoreService.deleteTrip(payload.tripId)
            .pipe(switchMap(deleteTripResponse => this.expenseStoreService.loadExpenses(true)))
            .pipe(tap(data => {
                this.globalEventsService.dispatchShowGlobalSpinnerEvent(false);
                this.isLoading.next(false);
                return of(data);
            }));
    }

    private updateTimeSheet(periodEnd: Date, timeSheet: TimeSheet, triggerSpinner: boolean = false): void {
        this.timeSheetStoreService.updateTimeSheet(periodEnd, timeSheet);
        if (triggerSpinner) {
            this.isLoading.next(false);
            this.globalEventsService.dispatchShowGlobalSpinnerEvent(false);
        }
    }

    private updateAndSaveTimeSheet(periodEnd: Date, timeSheet: TimeSheet, triggerSpinner: boolean = false): Observable<TimeReportSettings> {
        return this.timeSheetStoreService.updateAndSaveTimeSheet(periodEnd, timeSheet)
                .pipe(switchMap(saveTimeSheetResponse => this.timeReportStoreService.loadTimeReport(periodEnd, true)))
                .pipe(tap(timeReportResponse => {
                    if (triggerSpinner) {
                        this.isLoading.next(false);
                        this.globalEventsService.dispatchShowGlobalSpinnerEvent(false);
                    }
                }));
    }

    private loadTimeSheet(periodEnd: Date, triggerSpinner: boolean = true, shouldInitialize: boolean = false): Observable<[TimeSheet, WorkLocationDayInfo[]]> {
        return this.timeSheetStoreService.loadTimeSheet(periodEnd, shouldInitialize)
                .pipe(tap(([timeSheet, workLocations]: [TimeSheet, WorkLocationDayInfo[]]) => {
                    if (triggerSpinner) {
                        this.isLoading.next(false);
                        this.globalEventsService.dispatchShowGlobalSpinnerEvent(false);
                    }
                }));
    }

    private clearTimeSheetVerifyLoad(periodEnd: Date, triggerSpinner: boolean = true) {
        const isTimeTab = (this.router.url.split('?')[0].split('/')[1]) == 'time';
        const observables: Observable<any> = !isTimeTab ? of(this.timeSheetStoreService.resetTimeSheetCache()) :
                                                          this.timeSheetStoreService.loadTimeSheet(periodEnd, true);
        return observables.subscribe(response => {
                if (triggerSpinner) {
                    this.isLoading.next(false);
                    this.globalEventsService.dispatchShowGlobalSpinnerEvent(false);
                }
             });
    }

    public saveTimeSheet(periodEnd: Date, triggerSpinner: boolean = true, toastMessage: string) {
        return this.timeSheetStoreService.saveTimeSheet(periodEnd, toastMessage)
                .pipe(tap(timeSheetSaveResponse => {
                    this.timeReportStoreService.updateTimeReportStatus(timeSheetSaveResponse.timeReportStatus);
                    if (triggerSpinner) {
                        this.isLoading.next(false);
                        this.globalEventsService.dispatchShowGlobalSpinnerEvent(false);
                    }
                    return of(timeSheetSaveResponse);
                }));
    }

    public updateTimeReportStatusLoadTimeSheet(periodEnd: Date, shouldInitialize: boolean, triggerSpinner: boolean = false): Observable< [TimeSheet, WorkLocationDayInfo[]]> {
        return this.timeSheetStoreService.loadTimeSheet(periodEnd, shouldInitialize)
            .pipe(switchMap(loadTimeSheetResponse => {
                this.timeReportStoreService.updateTimeReportStatus(loadTimeSheetResponse[0]?.timeReportStatus);
                return of(loadTimeSheetResponse);
            }))
            .pipe(tap(loadTimeSheetResponse => {
                if (triggerSpinner) {
                    this.isLoading.next(false);
                    this.globalEventsService.dispatchShowGlobalSpinnerEvent(false);
                }
                return of(loadTimeSheetResponse);
            }));
    }

    public saveWorkSchedule(periodEnd: Date, workScheduleGroup: WorkScheduleGroup, triggerSpinner: boolean): Observable<[[TimeSheet, WorkLocationDayInfo[]], TimeReportSettings]> {
        return this.timeSheetStoreService.saveTimeSheetIfNeeded(periodEnd)
                .pipe(switchMap(saveTimeSheetResponse => {
                    return this.timeSheetStoreService.saveWorkSchedule(periodEnd, workScheduleGroup)
                }))
                .pipe(switchMap(saveWorkScheduleResponse => {
                    return forkJoin([
                        this.timeSheetStoreService.loadTimeSheet(periodEnd, true),
                        this.timeReportStoreService.loadTimeReport(periodEnd, true)
                    ]);
                }))
                .pipe(mergeMap(savedResponses => {
                    if (triggerSpinner) {
                        this.isLoading.next(false);
                        this.globalEventsService.dispatchShowGlobalSpinnerEvent(false);
                    }
                    return of(savedResponses);
                }));
    }

    public saveTimeEntryTemplate(timeEntryTemplate: TimeEntryTemplate, periodEnd: Date, isTimeTab: boolean, triggerSpinner: boolean): Observable<TimeEntryTemplateResponse> {
        return this.timeSheetStoreService.saveTimeEntryTemplate(timeEntryTemplate, periodEnd, isTimeTab)
                .pipe(tap(timeEntryResponse => {
                    if (triggerSpinner) {
                        this.isLoading.next(false);
                        this.globalEventsService.dispatchShowGlobalSpinnerEvent(false);
                    }
                    return of(timeEntryResponse);
                }));
    }

    public saveShiftSchedule(shiftSchedule: ShiftSchedule, periodEnd: Date, timePeriodDates: Date[], triggerSpinner: boolean): Observable<ShiftSchedule> {
        return this.timeSheetStoreService.saveShiftSchedule(shiftSchedule, periodEnd, timePeriodDates)
                .pipe(switchMap(shiftScheduleResponse => {
                    return forkJoin([
                        of(shiftScheduleResponse),
                        shiftScheduleResponse.error ? of(null) : this.timeReportStoreService.loadTimeReport(periodEnd, true)
                    ])
                }))
                .pipe(switchMap(([shiftScheduleResponse, timeReportResponse]) => {
                    if (triggerSpinner) {
                        this.isLoading.next(false);
                        this.globalEventsService.dispatchShowGlobalSpinnerEvent(false);
                    }
                    return of(shiftScheduleResponse)
                }));
    }

    public saveShiftScheduleCustomDay(shiftType: string, periodEnd: Date, date: Date, triggerSpinner: boolean): Observable<ShiftSchedule> {
        return this.timeSheetStoreService.saveShiftScheduleCustomDay(shiftType, periodEnd, date)
                .pipe(switchMap(shiftScheduleResponse => {
                    return forkJoin([
                        of(shiftScheduleResponse),
                        shiftScheduleResponse.error ? of(null) : this.timeReportStoreService.loadTimeReport(periodEnd, true)
                    ])
                }))
                .pipe(switchMap(([shiftScheduleResponse, timeReportResponse]) => {
                    if (triggerSpinner) {
                        this.isLoading.next(false);
                        this.globalEventsService.dispatchShowGlobalSpinnerEvent(false);
                    }
                    return of(shiftScheduleResponse)
                }));
    }

    public selectSubordinate(subordinate: Subordinate, subordianteMode: SubordinatesMode): Observable<[TimeReportSettings, boolean]> {
        return this.subordinatesService.getSubordinateTabPermissionsObservable(subordinate.enterpriseId, subordianteMode)
                .pipe(switchMap(tabsPermissionsResponse => forkJoin([
                    of(this.subordinatesService.resolveLegacyRedirection(tabsPermissionsResponse, subordianteMode, subordinate.enterpriseId)),
                    of(this.globalCacheService.getCurrentSubordinate()),
                    of(this.globalCacheService.getPeriodEnd())
                ])))
                .pipe(switchMap(([resolveResponse, currentSubordinate, periodEnd]) => {
                    const isSameSubordinate = currentSubordinate?.peopleKey == subordinate.peopleKey;
                    if (!resolveResponse && !isSameSubordinate) {
                        this.globalCacheService.setCurrentSubordinate(subordinate);
                        return forkJoin([
                            this.getObservablesToSelectSubordinate(periodEnd),
                            isSameSubordinate ? of(null) : of(this.globalCacheService.clearBufferCache()),
                            of(periodEnd),
                            of(isSameSubordinate)]);
                    } else {
                        return forkJoin([of(null), of(null), of(periodEnd), of(isSameSubordinate)]);
                    }
                }))
                .pipe(mergeMap(([response, cacheClear, periodEnd, isSameSubordinate]) => {
                    this.isLoading.next(false);
                    this.globalEventsService.dispatchShowGlobalSpinnerEvent(false);
                    return forkJoin([this.timeReportStoreService.loadTimeReport(periodEnd, !isSameSubordinate), of(!isSameSubordinate)])
                }));
    }

    private getObservablesToSelectSubordinate(periodEnd: Date) {
        const url = (this.router.url.split('?')[0].split('/')[1]);
        if (url == 'time') {
            return this.timeSheetStoreService.loadTimeSheet(periodEnd, true);
        }
        if (url == 'expenses') {
            return this.expenseStoreService.loadExpenses(true, periodEnd, true);
        }
        if (url == 'preferences') {
            return this.timeReportStoreService.loadUserPreferences();
        }
        return of(null)
    }

    public saveLocations(periodEnd: Date, locationsByDay: LocationsByDay): Observable<[LocationsByDay, TimeReportSettings]> {
        return this.timeSheetStoreService.saveLocations(periodEnd, locationsByDay)
                .pipe(switchMap(locationSaveResponse => {
                    return forkJoin([
                        of(locationSaveResponse),
                        this.timeReportStoreService.loadTimeReport(periodEnd, true)
                    ])
                }))
                .pipe(tap(([locationSaveResponse, timeReportResponse]) => {
                    this.isLoading.next(false);
                    this.globalEventsService.dispatchShowGlobalSpinnerEvent(false);
                }));
    }

    public deleteLocations(periodEnd: Date, locationsByDay: LocationsByDay, locationEntry: LocationEntry): Observable<[LocationsByDay, TimeReportSettings]> {
        return this.timeSheetStoreService.deleteLocations(periodEnd, locationsByDay, locationEntry)
                .pipe(switchMap(deleteLocationResponse => {
                    return forkJoin([
                        of(deleteLocationResponse),
                        this.timeReportStoreService.loadTimeReport(periodEnd, true)
                    ])
                }))
                .pipe(tap(([deleteLocationResponse, timeReportResponse]) => {
                    this.isLoading.next(false);
                    this.globalEventsService.dispatchShowGlobalSpinnerEvent(false);
                }));
    }

    public redirect(periodEnd: Date) {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.globalEventsService.dispatchPeriodChangedEvent(periodEnd);
        if (this.activatedRoute.snapshot.queryParamMap.get('countryKey') && !this.activatedRoute.snapshot.queryParamMap.get('viewMode')) {
            this.router.navigate(
                [this.router.url.split('?').shift()],
                {
                    queryParams: {
                        countryKey: this.activatedRoute.snapshot.queryParamMap.get('countryKey'),
                        periodEnd: encodeURIComponent(btoa(periodEnd.toISOStringMyTE()))
                    }
                } as NavigationExtras
            );
        } else {
            this.router.navigate(
                [this.router.url.split('?').shift()],
                {
                    queryParams: {
                        periodEnd: encodeURIComponent(btoa(periodEnd.toISOStringMyTE())),
                        viewMode: encodeURIComponent(btoa(this.globalCacheService.getSubordinateMode().toString()))
                    }
                } as NavigationExtras
            );
        }
        this.isLoading.next(false);
        this.globalEventsService.dispatchShowGlobalSpinnerEvent(false);
    }
}
