<div class="total-reimbursement-container" *ngIf="showCompensatory; else normalBlock" data-html="true"
  ngbTooltip="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Expense: {{ amount }} &#013;&#010; &nbsp;&nbsp;Compensatory: {{ compensatory }} &#013;&#010; Reimbursement: {{ totalAmount }}"
  tooltipClass="amount-tooltip" placement="top-start" container="body">
  <span> {{ param.value }} </span>
  <span class="HasCompensatoryBtn" ></span>
</div>
<ng-template #normalBlock>
    <div class="total-reimbursement-container" ngbTooltip="{{param.colDef.field == 'additionalInformation' ? tooltip : null}}"
    placement="top-start" container="body" [class]="param.colDef.cellClass(param)">
    <span>{{ param.value }}</span>  
    <span *ngIf="isFirstColumn" aria-label="Total Reimbursment grid"></span>  
  </div>  
</ng-template>