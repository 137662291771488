import { Injectable } from '@angular/core';
import { GlobalCacheService } from '@sharedServices/cache/global-cache.service';
import { TimeSheetService } from '../../../myte-time/shared/services/timesheet/timesheet.service';
import TimeSheet from '@sharedModels/time-sheet/time-sheet';
import { LocationsService } from '../../../myte-locations/shared/services/locations/locations.service';
import { LocationsByDay } from '@sharedModels/locations/locations-by-day/locations-by-day';
import { LogService } from '@sharedServices/log/log.service';
import { GlobalEventsService } from '@sharedServices/events/global-events.service';
import { TimeReportService } from '../time-report/time-report.service';
import { Router } from '@angular/router';
import { UserProfileService } from '@sharedServices/user/user-profile.service';
import { UserService } from '@sharedServices/user/user.service';
import { PreferencesPopupList } from '@sharedModels/preferences-list';
import SaveUserPreferencesInput from '@shared/models/saveUserPreferencesInput';
import DropdownItem from '@sharedModels/controls/dropdown/dropdown-item';
import { PersonalCommuteInput } from '@shared/models/personalCommuteInput';
import { TimeReportUtils } from '@sharedUtils/timeReport.utils';
import { of } from 'rxjs';
import { ESTravelPunchClockInfo } from '@shared/myte-static-message';
import { ActivityService } from '../../../myte-activity/shared/services/activity.service';
import { ActionStoreService } from '../store/action-store.service';
import { ActionType } from '@shared/services/store/shared/action-type';

@Injectable({
  providedIn: 'root'
})
export class MyteAutoSaveService {

  isAustraliaOvertimeEmployee: boolean;
  isAllowanceRequestor: boolean;

  constructor(
    private cacheService: GlobalCacheService,
    private eventService: GlobalEventsService,
    private timeSheetService: TimeSheetService,
    private timeReportService: TimeReportService,
    private locationsService: LocationsService,
    private logService: LogService,
    private userService: UserService,
    private userProfileService: UserProfileService,
    private router: Router,
    private activityService:ActivityService,
    private actionService: ActionStoreService
  ) { }

  public Save(dictionaryTabs: any, timeReportStatus: string, periodEnd: Date) {
    let newTabs = new Map<string, boolean>();
    this.cacheService.getTabs(periodEnd).subscribe((tabs) => newTabs = tabs);
    dictionaryTabs = newTabs ? newTabs : dictionaryTabs;
    // time
    let timeSheet = new TimeSheet();
    this.cacheService.getTimeSheet(periodEnd).subscribe((ts) => timeSheet = ts);
    // locations
    let locationsByDay = new LocationsByDay();
    this.cacheService.getLocationsByDay(periodEnd).subscribe((l) => locationsByDay = l);
    this.cacheService.getTimeReportSettings(periodEnd, null).subscribe(trSettings => {
      this.isAustraliaOvertimeEmployee = trSettings?.shouldEnableOvertimeRequestEmployee;
      this.isAllowanceRequestor = trSettings?.shouldEnableAllowanceRequest;
    });
    // userPreference
    let userPreferencesInput = new PreferencesPopupList();
    let subordinate = this.cacheService.getCurrentSubordinate();
    let enterpriseId = subordinate ? subordinate.enterpriseId : this.userService.getUser().enterpriseId;
    this.cacheService.getPreferencesPopupList(enterpriseId).subscribe((up) => userPreferencesInput = up);
    if (userPreferencesInput) {
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      if (userPreferencesInput.inOutCheckboxElem.some(s => s.isVisible == true && s.preValue != true && (s.comments.match('agree') || s.comments.match('acknowledge')))) {
        let approverCheckbox = userPreferencesInput.inOutCheckboxElem.find(x => x.id == 'Approver');
        if (approverCheckbox) {
          if (approverCheckbox.preValue == false || approverCheckbox.preValue == null) {
            approverCheckbox.hasError = true;
          } else {
            approverCheckbox.hasError = false;
          }
        }
        let delegateCheckbox = userPreferencesInput.inOutCheckboxElem.find(x => x.id == 'Delegate');
        if (delegateCheckbox) {
          if (delegateCheckbox.preValue == false || delegateCheckbox.preValue == null) {
            delegateCheckbox.hasError = true;
          } else {
            delegateCheckbox.hasError = false;
          }
        }
        this.logService.logError('You must agree to the message.', true, 'Input Error!');
        this.router.navigateByUrl(this.router.url);
        this.router.routeReuseStrategy.shouldReuseRoute = () => true;
        dictionaryTabs.set('Preferences', true);
        return;
      }
      let isError = false;
      if (userPreferencesInput.personalElem) {
        userPreferencesInput.personalElem.forEach(item => {
          if (item.values != '' && item.values != null && !isError) {
            let title = item.title[0];
            switch (title) {
              case 'Number of KM/Miles(One Way):':
                isError = (item.values.match(/^[0-9]\d*$/) == undefined || item.values.match(/^[0-9]\d*$/) == null || item.values > 1000) ? true : false;
                break;
              case 'Other Transportation Expenses:':
                if (this.getDecimalLocale() == ',') {
                  let validationnumber = item.values.replaceAll('.', '').replaceAll(/\s/g, '').replaceAll('´', '').replaceAll(',', '.')
                  .toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 3 });
                  isError = (validationnumber.match(/^[0-9]+\.?[0-9]*$/) == undefined || validationnumber.match(/^[0-9]+\.?[0-9]*$/) == null
                  || validationnumber.length > 8 || validationnumber > 100000) ? true : false;
                }

                else
                  isError = (item.values.match(/^[0-9]+\.?[0-9]*$/) == undefined || item.values.match(/^[0-9]+\.?[0-9]*$/) == null
                  || item.values.length > 8 || item.values > 100000) ? true : false;                break;
            }
          }
        });
      }
      if (isError) {
        this.logService.logError('Please verify Personal Commute details.', true, 'Input Error!');
        this.router.navigateByUrl(this.router.url);
        this.router.routeReuseStrategy.shouldReuseRoute = () => true;
        dictionaryTabs.set('Preferences', true);
        return;
      }

      const userPreferencesToSave = this.mapInputValue(userPreferencesInput);
      if (userPreferencesToSave) {
        this.userProfileService.saveUserPreferences(userPreferencesToSave).subscribe(res => {
          res != 'UserPreferences saved.' ? dictionaryTabs.set('Preferences', true)
                                          : dictionaryTabs.set('Preferences', false);
          if (res === 'UserPreferences saved.') {
            this.cacheService.clearPreferencesPopupListFromBuffer(enterpriseId);
          } else if (res.includes('Invalid HomeOfficeLocations')) {
            this.logService.logError('Please select a Home Office Location.', true, 'Input Error!');
            this.router.navigateByUrl(this.router.url);
            return;
          } else if (res) {
            this.logService.showValidationMessage('Error Message', `${res}`);
            this.router.navigateByUrl(this.router.url);
            return;
          }
        });
      } else {
        this.router.navigateByUrl(this.router.url);
        return;
      }
    }

    if (timeSheet && timeSheet.modified) {
      this.eventService.dispatchShowGlobalSpinnerAutoSaveEvent(true);
      this.timeSheetService.saveTimeSheet(timeSheet, periodEnd)
      .subscribe(data => {
          let errorsByDay = data.timeEntry.tasks.find(periodDate => periodDate.charges.find(c => c.hasError === true));
          if (data.messages.length != 0 || errorsByDay) {
            dictionaryTabs.set('Time', true);
            this.initializeMessages(data.messages);
          } else {
            dictionaryTabs.set('Time', false);
          }
          this.eventService.dispatchDisableLocationSaveButtonEvent(false);
          // ToDo: delete all the events and add subscribe into the components
          let timeReport = TimeReportUtils.createTimeReport(periodEnd, data.timeReportStatus, this.cacheService.getSubordinateMode());
          this.eventService.dispatchUpdateTimeReportEvent(timeReport);
          this.actionService.dispatchAction(ActionType.UPDATE_TIME_REPORT_STATUS, { status: data.timeReportStatus, periodEnd: periodEnd });
          if (this.cacheService.getCurrentSubordinate()?.enterpriseId) {
            this.eventService.dispatchUpdateTimeReportSubordinateSelected(data.timeReportStatus, this.cacheService.getCurrentSubordinate().enterpriseId);
          }
          this.eventService.dispatchShowGlobalSpinnerAutoSaveEvent(false);
        });
        if (timeReportStatus == 'Draft' || timeReportStatus == 'DraftAdjustment') return;
    } else if (locationsByDay && locationsByDay.modified) {
      if (timeReportStatus == 'New' || timeReportStatus == 'Draft' || timeReportStatus == 'DraftAdjustment') {
        let locationMultiple = 0;
        locationsByDay.periodDates.forEach(periodDate => {
          if (locationsByDay.validate.hasMultipleLocations(periodDate.dateTime)) {
            locationMultiple = locationMultiple + 1;
          }
        });

        if (locationMultiple === 0) {
          locationsByDay.isAutoSave = true;
          locationsByDay.modified = false;
          this.eventService.dispatchShowGlobalSpinnerAutoSaveEvent(true);
          this.eventService.dispatchShowGlobalSpinnerEvent(true);
          let previousIsESTravelPunchClock = locationsByDay.isESTravelPunchClock;
          this.locationsService.saveLocations(locationsByDay)
          .subscribe(data => {
            locationsByDay = data;
            // ToDo: delete all the events and add subscribe into the components
            let errorsByDay = locationsByDay.periodDates.find(periodDate => periodDate.hasError === true);
            this.cacheService.clearLocationsByDayFromBuffer(periodEnd);
            console.log('locationsByDay?.isESTravelPunchClock: ', locationsByDay?.isESTravelPunchClock)
            this.actionService.dispatchAction(ActionType.CLEAR_TIME_SHEET, { periodEnd: periodEnd, triggerSpinner: false });
            if(locationsByDay?.isESTravelPunchClock != previousIsESTravelPunchClock) {
              // this.cacheService.clearTimeSheetFromBuffer(periodEnd);
              this.actionService.dispatchAction(ActionType.CLEAR_TIME_SHEET, { periodEnd: periodEnd, triggerSpinner: false });
              // this.eventService.dispatchESTravelPunchClockEvent();
            }
            if (locationsByDay?.isESTravelPunchClock && previousIsESTravelPunchClock === false){
              this.logService.logInfo(ESTravelPunchClockInfo.msg, ESTravelPunchClockInfo.showToast);
              this.cacheService.setESPunchClockSoftWarningBuffer(periodEnd, this.userService.getUser().enterpriseId, true);
            }
            if (!locationsByDay?.isESTravelPunchClock && previousIsESTravelPunchClock){
              this.cacheService.setESPunchClockSoftWarningBuffer(periodEnd, this.userService.getUser().enterpriseId, false);
            }
            if (data.messages.length != 0 || errorsByDay) {
              dictionaryTabs.set('Locations', true);
              this.initializeMessages(data.messages);
            } else {
              dictionaryTabs.set('Locations', false);
            }
            // ToDo: delete all the events and add subscribe into the components
            // ToDo: Delete this temporal time report also the event, it must be handle by the stores and components subscribe for changes
            let timeReport = TimeReportUtils.createTimeReport(periodEnd, data.timeReportStatus, this.cacheService.getSubordinateMode());
            this.eventService.dispatchUpdateTimeReportEvent(timeReport);
            this.eventService.dispatchShowGlobalSpinnerEvent(false);
            this.actionService.dispatchAction(ActionType.UPDATE_TIME_REPORT_STATUS, { status: data.timeReportStatus });
            this.eventService.dispatchUpdateSettingsChangeEvent(true);
            this.eventService.dispatchShowGlobalSpinnerAutoSaveEvent(false);
          });
        } else {
          this.cacheService.clearLocationsByDayFromBuffer(periodEnd);
          dictionaryTabs.set('Locations', false);
        }
      } else {
        this.cacheService.clearLocationsByDayFromBuffer(periodEnd);
      }
    }
    //activity
    let activityData:any;
    this.cacheService.getActivityGrid(periodEnd,enterpriseId).subscribe((data) => activityData = data);
    if(activityData && activityData.modified){
      this.router.routeReuseStrategy.shouldReuseRoute = () => true;
      let isValid = this.activityService.validationSaveActivity(activityData);
      if(!isValid) {
        this.router.navigateByUrl(this.router.url);
        dictionaryTabs.set('Activity', true);
        return
      };

      this.eventService.dispatchShowGlobalSpinnerAutoSaveEvent(true);
      this.activityService.SaveActivities(true, activityData.activityOutputItem)
      .subscribe((output)=>{
        if (output) {
          let periodEnd = this.cacheService.getPeriodEnd();
          let subordinate = this.cacheService.getCurrentSubordinate();
          let enterpriseId = subordinate ? subordinate.enterpriseId : this.userService.getUser().enterpriseId;
          let timeReport = TimeReportUtils.createTimeReport(periodEnd, output.timeReportStatus, this.cacheService.getSubordinateMode());
          this.cacheService.handleResetTimeReport(periodEnd);
          this.cacheService.handleTimeReport(periodEnd, of(timeReport));
          this.eventService.dispatchUpdateTimeReportEvent(timeReport);
          this.cacheService.clearTimeSheetFromBuffer(periodEnd);
          let isValid = this.activityService.validationSubmitActivity(output);
          output.modified = !isValid;
          this.cacheService.setActivityGrid(periodEnd, of(output), enterpriseId);
          this.actionService.dispatchAction(ActionType.UPDATE_TIME_REPORT_STATUS, { status: timeReport.status });
          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          this.eventService.dispatchShowGlobalSpinnerAutoSaveEvent(false);
          if (!isValid) {
            this.router.navigate(['/activity']);
            dictionaryTabs.set('Activity', true);
            this.eventService.dispatchActivityErrorEvent(true);
            return
          }
          else{
            this.router.navigateByUrl(this.router.url);
            dictionaryTabs.set('Activity', false);
          }
        }
      });
    }
  }

  private initializeMessages(messages): void {
    if (messages)
      messages.forEach(msg => this.logService.showValidationMessage(msg.Category, msg.Text));
  }

  private mapInputValue(value): SaveUserPreferencesInput {
    let inputValue = new SaveUserPreferencesInput();
    inputValue.reviewers = value.inOutElem[0].values;

    if (inputValue.reviewers.length < 1) {
      this.logService.logError(
        `Please specify at least one valid supervisor. You have entered an invalid Time Report Reviewer.
        Your reviewer must be an active employee at or above your level.
        For further assistance, please contact your supervisor.`,
        true,
        'Input Error!'
      );

      value.inOutElem[0].errorMes = `Please specify at least one valid supervisor. You have entered an invalid Time Report Reviewer.
      Your reviewer must be an active employee at or above your level.
      For further assistance, please contact your supervisor.`;
      return;
    }

    inputValue.submission = value.inOutElem[1].values;
    inputValue.delegates = value.inOutElem[2].values;
    inputValue.approvers = value.inOutElem[3].values;
    inputValue.backupApprovers = value.inOutElem[4].values;


    if (this.isAustraliaOvertimeEmployee) {
      const australiaOTApprover = value.inOutElem.find(element => element.title[0] === 'Overtime Approver:');
      if (australiaOTApprover) {
        inputValue.overtimeApprovers = australiaOTApprover.values;
        if (inputValue.overtimeApprovers?.length > 1) {
          australiaOTApprover.errorMes =  `You have entered more than one Overtime Approver. Please select only one Overtime Approver.`;
          this.logService.logError(australiaOTApprover.errorMes, true, 'Input Error!');
          return;
        }
      }
    }

    if (this.isAllowanceRequestor) {
      const allowanceApprover = value.inOutElem.find(element => element.title[0] === 'Allowance Approver:');
      if (allowanceApprover) {
        inputValue.allowanceApprovers = allowanceApprover.values;
        if (inputValue.allowanceApprovers?.length > 1) {
          allowanceApprover.errorMes = 'You have entered more than one Allowance Approver. Please select only one Allowance Approver.';
          this.logService.logError(allowanceApprover.errorMes, true, 'Input Error!');
          return;
        }
      }
    }

    if (value.locationElem) {
      inputValue.homeOffice1Code =
        value.locationElem[0].isVisible
          ? this.findLocationCode(value.locationElem[0].preValue, value.locationElem[0].values, 0)
          : null;

      inputValue.homeOffice2Code =
        value.locationElem[1].isVisible
          ? this.findLocationCode(value.locationElem[1].preValue, value.locationElem[1].values, 1)
          : null;

      inputValue.residentLocationCd = value.locationElem[2].isVisible
        ? value.locationElem[2].preValue
          ? value.locationElem[2].preValue.indexOf(' - ') != -1
            ? value.locationElem[2].preValue.split(' - ')[0]
            : value.locationElem[2].preValue
          : 'empty'
        : null;

      if (inputValue.residentLocationCd === 'empty') {
        this.logService.logError(
          'Please select a Resident Location.',
          true,
          'Input Error!'
        );
        return;
      }
    } else {
      inputValue.homeOffice1Code = inputValue.homeOffice2Code = inputValue.residentLocationCd = null;
    }

    inputValue.uberForBusiness = value.uberElem[0].isVisible ? value.uberElem[0].preValue : null;
    inputValue.lyftAcceptanceIndicator = value.uberElem[1].isVisible ? value.uberElem[1].preValue : null;
    inputValue.personalCommuteData = this.setPersonalCommuteData(value.personalElem);
    inputValue.isApprovalRequested = value.inOutCheckboxElem[2].preValue;
    inputValue.isAuditDelegateRequested = value.inOutCheckboxElem[1].preValue;
    inputValue.isBackupApprover = value.inOutCheckboxElem[3].preValue;
    inputValue.compensatoryTabIndicator = value.inOutCheckboxElem[0].preValue;
    return inputValue;
  }

  private findLocationCode(
    value: string,
    list: DropdownItem[],
    i?: number
  ): string {
    if (!value || value == '-') return '';
    let country = list.filter(f => {
      return f.value == value;
    })[0];

    let selectedCode = country ? country.key : value;
    return selectedCode.indexOf('/') == -1
      ? selectedCode
      : selectedCode.split('/')[i];
  }

  private setPersonalCommuteData(personalCommuteItem): PersonalCommuteInput {
    let personalCommuteData = new PersonalCommuteInput();

    personalCommuteItem.forEach(pci => {
      if (pci.title == 'Number of KM/Miles(One Way):')
        personalCommuteData.numberOfKmMiles = pci.values;
      else if (pci.title == 'Other Transportation Expenses:') {
        if (this.getDecimalLocale() == ',')
          personalCommuteData.otherTransportationExpenses = this.formatValueToUS(pci.values, 0, 3);
        else
          personalCommuteData.otherTransportationExpenses = pci.values;
      }
      else if (pci.title == 'Method of Travel:')
        personalCommuteData.methodOfTravel = pci.preValue;
      else if (pci.title == 'Vehicle Type:')
        personalCommuteData.vehicleType = pci.preValue;
      else if (pci.title == 'Car Consumption (l/100km):')
        personalCommuteData.carConsumption = pci.values;
      else if (pci.title == 'Car Registration Number:')
        personalCommuteData.carRegistrationNo = pci.values;
      else if (pci.title == 'Car Plan (Y/N):')
        personalCommuteData.carPlan = pci.preValue;
      else if (pci.title == 'Car Plan Charge Number:')
        personalCommuteData.carPlanChargeNumber = pci.values;
      else if (pci.title == 'Company Car (Y/N):')
        personalCommuteData.companyCar = pci.preValue;
      else if (pci.title == 'Car Type:')
        personalCommuteData.carType = pci.preValue;
    });

    return personalCommuteData;
  }

  private formatValueToUS(value: any, minfract: number, maxfract: number): any {
    const decimalSeparator = this.getDecimalLocale();
    const groupSeparator = this.groupSeparator();
    return value.replaceAll(groupSeparator, '').replaceAll(/\s/g, '').replaceAll('´', '').replaceAll(decimalSeparator, '.')
    .toLocaleString('en-US', {minimumFractionDigits: minfract, maximumFractionDigits: maxfract});
  }

  private getDecimalLocale(): string {
    const value = parseFloat('1').toLocaleString(navigator.language, { minimumFractionDigits: 2 });
    return value.replace(/\d+/g, '');
  }

  private groupSeparator() {
    let separator = (1000).toLocaleString(navigator.language).substring(1, 2);
    if (isNaN(Number(separator))) return separator;
    separator = (10000).toLocaleString(navigator.language).substring(2, 3);
    if (isNaN(Number(separator))) return separator;

    return '';
  }
}
