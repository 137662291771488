<myte-popup-template [title]="'Privacy Statement:'"(closeEvent)="closePopUp()">
  <div class="popup-main">
<nav class="navbar bar-title align-items-center px-5 py-2 border-bottom">
</nav>
<br>
<div>
  <p>
    Please be aware that any personal data that you and others provide through this tool may be <br> processed by Accenture.
  </p>
  <p>
    The protection of your personal data is very important to Accenture. Accenture is committed to <br> keeping your personal
    data secure, and processing it in accordance with, applicable data <br> protection laws and our internal policies,
    including <a href="https://policies.accenture.com/policy/0090?rd=1&Country=United%20Kingdom" rel='noopener noreferrer' target="_blank">Accenture’s Global Data Privacy Policy 0090.</a>
  </p>
  <p>
    Your decision to provide your personal data to Accenture is voluntary. However, given that this <br> tool requires
    personal
    data to function, you will not be able to use this tool if you do not <br> provide your personal data.
  </p>
  <p>
    Before providing any personal data through this tool, Accenture invites you to carefully read its <br>
     <a href="https://in.accenture.com/protectingaccenture/data-security/5422-2/" target="_blank">privacy statement</a>,
    which includes important information on why and how Accenture is <br> processing your personal data.
  </p>
</div>
</div>
</myte-popup-template>

