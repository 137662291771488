import { Component } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams, RowDataTransaction } from 'ag-grid-community';
import { GlobalCacheService } from '../../../../../shared/services/cache/global-cache.service';
import { AnnualChargeabilityParentRow, AnnualChargeabilityRow } from '@shared/models/summary/annual-chargeability/annual-chargeability-row.model';
import moment from 'moment';

@Component({
  selector: 'myte-chargeability-cell-renderer',
  templateUrl: './chargeability-cell-renderer.component.html',
  styleUrls: ['./chargeability-cell-renderer.component.sass']
})
export class ChargeabilityCellRendererComponent implements ICellRendererAngularComp {
  public params: ICellRendererParams<AnnualChargeabilityRow | AnnualChargeabilityParentRow, any>;
  public isAnnualChargeabilityParentRow: boolean;
  public appShellWidth: number;
  public titleColumn: boolean;

  constructor(
    private router: Router,
    private globalCacheService: GlobalCacheService) {
  }
  
  public agInit(params: ICellRendererParams<AnnualChargeabilityRow | AnnualChargeabilityParentRow, any>): void {
    this.params = params;
    this.isAnnualChargeabilityParentRow = params.data instanceof AnnualChargeabilityParentRow;
    this.appShellWidth = Math.floor(Math.random() * (90 - 40 + 1)) + 40; // for app-shell display

    const cell: HTMLElement = params.eGridCell;
    if (cell) {
      const elementAriaHidden: Element = cell.querySelector('[aria-hidden="true"]');
      if (elementAriaHidden)
        elementAriaHidden.setAttribute('aria-hidden', 'false');
    }
  }

  public refresh(): boolean {
    return false;
  }

  public onToggleDetails(event: any): void {
    const parentRow: AnnualChargeabilityParentRow = this.params.data as AnnualChargeabilityParentRow;
    if (parentRow.tasks && parentRow.tasks.length > 0) {
      const rowDataTransaction: RowDataTransaction<AnnualChargeabilityRow> = {};
      if (parentRow.open) {
        rowDataTransaction.remove = parentRow.tasks;
      } else {
        rowDataTransaction.addIndex = ++this.params.rowIndex;
        rowDataTransaction.add = parentRow.tasks;
      }
      parentRow.open = !parentRow.open;
      this.params.api.applyTransaction(rowDataTransaction);
    }
  }

  public onRedirectToPeriod(event: MouseEvent): void {
    if (!moment(new Date(this.params.value)).isValid()) return;
    event.preventDefault();
    const periodEnd: string = moment(new Date(this.params.value)).format('MM-DD-YYYY');
    const navigationOptions: NavigationExtras = {
      replaceUrl: true,
      queryParamsHandling: 'merge',
      queryParams: {
        countryKey: this.globalCacheService.getUserCountry(),
        periodEnd: encodeURIComponent(btoa(periodEnd))
      }
    };
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.navigate(['/time'], navigationOptions);
  }
}
