<myte-popup-template [displayCloseButton]="false" [isIdleSessionPopup]="true">
    <div class="popup-main"> 
        <h3 aria-hidden="true" id="idle-popup-tittle">You're about to be signed out in</h3>
        <h3 id="timer">{{time}}</h3>     
    </div>
    <div class="popup-footer">
        <h3 id="idle-popup-tittle-button">Do you want to continue working?</h3>
        <div aria-labelledby="idle-popup-tittle" class="btn-controls">
            <button type="button" class="myte-button myte-button-submit" (click)="closeModal()">Continue</button>
        </div>
    </div>
</myte-popup-template>