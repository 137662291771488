export default class ExpenseTripReviewGrid {
    public fromDate?: Date;
    public toDate?: Date;
    public numOfDays?: string;
    public assignment?: string;
    public expenseType?: string;
    public amount?: string;
    public expenseId: string;

    constructor(
        numOfDays: string,
        assignment: string,
        expenseType: string,
        amount: string,
        expenseId: string,
        fromDate?: Date,
        toDate?: Date,
    ) {
        this.numOfDays = numOfDays;
        this.assignment = assignment;
        this.expenseType = expenseType;
        this.amount = amount;
        this.expenseId = expenseId;
        this.fromDate = fromDate;
        this.toDate = toDate;
    }
}
