export default class DropdownItem {
    public key?: any;
    public value?: string;
    public isShow?: boolean;
    public isValid?: boolean;
    public tooltip?: string;
    public projectType?: string;
    public numberKey?: number;

    constructor(
        key?: any,
        value?: any,
        isShow?: boolean,
        isValid: boolean = true,
        tooltip?: any,
        projectType?: string,
        numberKey?: number
        ) {
            this.key = key;
            this.value = value;
            this.isShow = isShow;
            this.isValid = isValid;
            this.tooltip = tooltip;
            this.projectType = projectType;
            this.numberKey = numberKey;
    }
}
