import { Component, HostListener } from '@angular/core';
import { ReceiptRequirementTypes } from '@shared/models/expense/receipt/expense-receipt-requirement';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { GlobalCacheService } from '../../../../shared/services/cache/global-cache.service';
import { ReceiptsService } from '../../../../shared/services/receipts/receipts.service';
import { ExpenseGridEventsService } from '../../../shared/services/events/expense-grid-events.service';
import { ExpenseGridService } from '../../../shared/services/grids/expense-grid.service';
import { ActionStoreService } from '@shared/services/store/action-store.service';
import { ActionType } from '@shared/services/store/shared/action-type';
import { TimeReportStoreService } from '@shared/services/store/time-report-store.service';

@Component({
    selector: 'myte-expense-grid-default-renderer',
    templateUrl: './expense-grid-default-renderer.component.html',
    styleUrls: ['./expense-grid-default-renderer.component.sass']
})
export class ExpenseGridDefaultRendererComponent implements ICellRendererAngularComp {
    value: any;
    isValid: boolean;
    validationErrors: string;
    public params: ICellRendererParams<any>;
    public isGroupByTrip: boolean;
    public editable: boolean;
    public countryKey: string;
    public periodEnd: Date;
    customFocus: boolean = false;
    isCheckBox: boolean = false;
    Checked: string = "";
    public isGCUser: boolean;
    public timeReportStatus: string;

    constructor(
        protected receiptsService: ReceiptsService,
        protected expenseGridService: ExpenseGridService,
        protected expenseGridEventService: ExpenseGridEventsService,
        public cacheService: GlobalCacheService,
        public actionService: ActionStoreService,
        public timeReportStoreService: TimeReportStoreService) { }

    public agInit(params: ICellRendererParams<any>): void {
        this.params = params;
        this.value = this.params.valueFormatted != undefined ? this.params.valueFormatted : this.params.value;
        this.isValid = this.params.value && (this.params.value.isValid != undefined) ? this.params.value.isValid : this.value != '' ? true : false;
        this.validationErrors = this.params.value && this.params.value.validationErrors ? this.params.value.validationErrors : null;
        if (params.colDef.colId == "select" || params.colDef.colId == "sequence")
            this.configureCustomFocus(params);
        this.editable = this.params.context?.expenseGridParamsService?.isEditable;
        this.isGroupByTrip = this.params.context?.expenseGridParamsService?.isGroupByTrip;
        this.countryKey = this.params.context?.expenseGridParamsService?.countryUser;
        this.periodEnd = this.params.context?.expenseGridParamsService?.periodEnd;
        this.timeReportStatus = this.params.context?.expenseGridParamsService?.timeReportStatus;
        this.timeReportStoreService.timeReport$
        if (this.timeReportStatus != undefined) {
            this.isGCUser = this.params.context?.expenseGridParamsService?.shouldShowReceiptType;
        }
    }

    public refresh(): boolean {
        return false;
    }

    public dropHandler(event): void {
        if (!this.isGCUser) {
            if (!this.editable) return;
            // this.ensureIsLoaded();
            if (this.isGroupByTrip) return;
            event.preventDefault();
            event.stopPropagation();
            this.removeRowClass(event, 'row-file-dragged');
            event.dataTransfer.effectAllowed = 'copyMove';

            this.expenseGridEventService.dispatchShowLoadingOverlayEvent(true);
            if (this.params?.data?.type.value === 'Salary Supplement' && this.params.value.type != ReceiptRequirementTypes.Both) {
                this.actionService.dispatchAction(ActionType.LOAD_EXPENSES, {
                    shouldInitialize: true,
                    periodEnd: this.periodEnd
                });
                return;
            }
            this.receiptsService.attachReceipts(event.dataTransfer.files, this.params.data.id, '')
            .subscribe(() => {
                this.actionService.dispatchAction(ActionType.LOAD_EXPENSES, {
                    shouldInitialize: true,
                    periodEnd: this.periodEnd
                });
            });
        }
    }

    public dragEnterHandler(event): boolean {
        if (!this.editable) return;
        // this.ensureIsLoaded();
        if (this.isGroupByTrip) return;
        event.preventDefault();
        event.stopPropagation();
        return true;
    }

    public dragOverHandler(event): boolean {
        if (!this.editable) return;
        // this.ensureIsLoaded();
        if (this.isGroupByTrip) return;
        event.preventDefault();
        event.stopPropagation();
        this.setRowClass(event, 'row-file-dragged');
        return true;
    }

    public dragLeaveHandler(event): boolean {
        if (!this.editable) return;
        // this.ensureIsLoaded();
        if (this.isGroupByTrip) return;
        event.preventDefault();
        event.stopPropagation();
        this.removeRowClass(event, 'row-file-dragged');
        return true;
    }

    public check(event: any): void {
        //this.params.node.setSelected(!this.params.node.isSelected())
    }

    public saveNewTripNameHandler(event): void {
        this.expenseGridService.saveTripName(event.data.frequentTrip.id, event.data.assignment.value);
    }

    private setRowClass(event, className: string): void {
        const row = this.getRow(event);
        if (row && !row.classList.contains(className)) {
            row.classList.add(className);
        }
    }

    private removeRowClass(event, className: string): void {
        const row = this.getRow(event);
        if (row)
            row.classList.remove(className);
    }

    private getRow = (event) => {
        const path = event.path || (event.composedPath && event.composedPath()) || this.customComposedPath(event);
        if (path) {
            return path.find(p =>
                p.attributes && p.attributes.role && p.attributes.role.value == 'row' && p.attributes['row-index']);
        }
        return false;
    }

    private customComposedPath(event): any[] {
        let path = [];
        let currentElement = event.target;

        while (currentElement) {
            path.push(currentElement);
            if (currentElement.tagName === 'HTML') {
                path.push(document);
                path.push(window);
                return path;
            }
            currentElement = currentElement.parentElement;
        }
        return path;
    }

    public configureCustomFocus(params: ICellRendererParams): void {
        this.customFocus = true;
        if (params.colDef.colId == "select") {
            this.isCheckBox = true;
            this.Checked = "No Checked";
        }
    }
}