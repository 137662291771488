export class TimeCategoryRequestValidationOutput {
    public erroMessage: string;
    public entryValidations: TimeCategoryRequestEntryValidationOutput[];
}

export class TimeCategoryRequestEntryValidationOutput{
    public date: Date;
    public daySequenceNbr: number
    public valueValidations: TimeCategoryRequestEntryValueValidationOutput[];
}

export class TimeCategoryRequestEntryValueValidationOutput{
    public key: string;
    public hasError: boolean;
    public errorMessage: string;
}