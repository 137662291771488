import AmexInput from '../../../../shared/models/expense/amex/expense-amex-import';
import { ImportAmexInput, ExpenseChargeAdj, ExpenseOutput, MealsClaimedOutput, GiftEntryOutput, MealsClaimedInput, FieldInputDto, GiftEntryInput, ReceiptInput, ReceiptImage, AmexExpenseOutputDto, ExpenseTypeModel, FieldOutput, SimpleExpense, ValidationError, ExpenseImportInput, ExpenseSplitOutput } from '../../../../shared/clients/expense-api-client';
import { ExpenseType } from '../../../../shared/models/expense/expense-type';
import { ExpenseReceipts, Receipt } from '../../../../shared/models/expense/receipt/expense-receipt';
import ExpenseView from '../../../../shared/models/expense/expense-view';
import { ExpenseViewFieldRow } from '../../../../shared/models/expense/expense-view-field-row';
import { ExpenseViewField } from '../../../../shared/models/expense/field/expense-view-field';
import ReceiptViewField from '../../../../shared/models/expense/field/expense-receipt-view-field';
import MealsClaimedField from '../../../../shared/models/expense/field/expense-meals-claimed-field';
import MealClaimed from '../../../../shared/models/expense/expense-meal-claimed';
import GiftField from '../../../../shared/models/expense/field/expense-gift-field';
import Gift from '../../../../shared/models/expense/expense-gift';
import AmexForAgGrid from '../../../../shared/models/expense/amex/expense-amex-view';
import { AmexExpenseTypeModel } from '../../../../shared/models/expense/amex/expense-amex-type-list';
import { ExpenseViewFieldValidation } from '../../../../shared/models/expense/expense-view-field-validation';
import { ExpenseViewFieldOption } from '../../../../shared/models/expense/expense-view-field-option';
import Expense from '../../../../shared/models/expense/expense';
import { ReceiptRequirement } from '../../../../shared/models/expense/receipt/expense-receipt-requirement';
import { ExpenseViewSource } from '../../../..//shared/models/expense/expense-view-source';
import { ExpenseViewSoftWarning } from '../../../../shared/models/expense/expense-view-soft-warning';
import { ExpenseAmount } from '../../../../shared/models/expense/expense-amount';
import * as moment from 'moment';
import ExpenseTripDetail from '../../../../shared/models/expense/trip/expense-trip-detail';
import MyteApiMessage from '../../../../shared/models/myte-api-message';
import { ExpenseViewFieldChargeCodeOption } from '../../../../shared/models/expense/expense-view-field-charge-code-option';
import { ExpenseViewChargeCodeField } from '../../../../shared/models/expense/expense-view-charge-code-field';
import CorporateCardExpenseInput from '../../../../shared/models/expense/amex/expense-corporate-card-import';
import DropdownItem from '@shared/models/controls/dropdown/dropdown-item';

export default class ExpenseServiceMapper {

    //#region Mappers

    static mapSaveAmexInput(amexInfo: AmexInput): ImportAmexInput {
        let importAmexInput = new ImportAmexInput();
        importAmexInput.transactionId = amexInfo.transactionId;
        importAmexInput.amexHotelImportDataId = amexInfo.amexHotelImportDataId;
        return importAmexInput;
    }

    static mapSaveCorporateCardInput(expenseImportInfo: CorporateCardExpenseInput): ExpenseImportInput {
        let corporateCardInput = new ExpenseImportInput();
        corporateCardInput.transactionId = expenseImportInfo.transactionId;
        corporateCardInput.amexHotelImportDataId = expenseImportInfo.amexHotelImportDataId;
        return corporateCardInput;
    }

    static mapExpenseTypes(expenseTypes: ExpenseTypeModel[]): ExpenseType[] {
        return expenseTypes.map(expenseTypeModel => {
            return new ExpenseType(expenseTypeModel.code, expenseTypeModel.description);
        });
    }

    static mapExpenseChargesAjd(expenseCharges: ExpenseSplitOutput[]): ExpenseChargeAdj[] {
        let splitExpenseAjds: ExpenseChargeAdj[] = [];
        expenseCharges.forEach(expenseCharge => {
            let splitExpenseAjd = new ExpenseChargeAdj();
            splitExpenseAjd.amount = expenseCharge.amount;
            splitExpenseAjd.percentage = expenseCharge.percentage;
            splitExpenseAjd.projectNumber = expenseCharge.projectNumber;
            splitExpenseAjd.sequenceNumber = expenseCharge.sequenceNumber;
            splitExpenseAjds.push(splitExpenseAjd);
        });
        return splitExpenseAjds;
    }


    /** This method will map the Object retrived by MyTE.Api and transform it to a ExpenseView model to be
     * consumed by the components.
     * @param expenseOutput The object that represent a Expense from MyTE.API.
     * @returns ExpenseView model to be consumed by components.
     */
    static mapExpenseView(expenseOutput: ExpenseOutput, expenseReceipts?: ExpenseReceipts): ExpenseView {
        if (expenseOutput.periodEnd?.getFullYear() != 1) {
            let expenseView = new ExpenseView(
                expenseOutput.expenseId,
                expenseOutput.expenseTypeCd,
                expenseOutput.expenseTypeDescription,
                expenseOutput.periodEnd,
                expenseOutput.countryDecimalPlaces,
                new Array<ExpenseViewFieldRow[]>());
                expenseView.timeReportStatus = expenseOutput.timeReportStatus;
            if (expenseOutput.amexInfo != null) {
                expenseView.amexInfo = this.mapAmexInfo(expenseOutput.amexInfo);
            }

            if (expenseOutput.importInfo != null) {
                expenseView.importInfo = this.mapImportInfo(expenseOutput.importInfo);
            }

            if (expenseOutput.messages != null && expenseOutput.messages?.length > 0) {
                expenseView.messages = expenseOutput.messages?.filter(msg=> msg?.text?.indexOf("The photo image is not clear.") == -1 && msg?.text?.indexOf("MyTime&Expenses is experiencing issues with receipt uploads. Please try again later. If you continue to experience this issue, please contact Technology Support.") == -1)?.map(msg => new MyteApiMessage(msg.text));
            }

            if (expenseOutput.validationErrors) {
                let validation = expenseOutput.validationErrors.filter(v => v.type.toLowerCase() == 'showconfirmationpopup');
                expenseView.softWarning = validation[0] ? new ExpenseViewSoftWarning(validation[0].value, false) : null;
                let validationForReceipt = expenseOutput.validationErrors.filter(v => v.type.toLowerCase() == 'blockerror');
                if (validationForReceipt != null && validationForReceipt?.length > 0) {
                    expenseView.validations.push(validationForReceipt[0]);
                }
            }

            expenseOutput.fields?.forEach(fieldOutput => {
                this.initializeCategories(expenseView, fieldOutput.category, fieldOutput.row);
                expenseView.categories[fieldOutput.category][fieldOutput.row].elements.push(this.mapExpenseField(fieldOutput));
            });


            expenseOutput.fields?.filter(fieldOutput => fieldOutput.fieldCurrencyKey).forEach(field => {
                let currencyField = expenseView.getFields().find(evf => evf.key === field.fieldCurrencyKey);
                expenseView.getFields().find(f => f.key == field.key).currency = currencyField.value;
                expenseView.categories[currencyField.category][currencyField.row].elements =
                    expenseView.categories[currencyField.category][currencyField.row].elements.filter(f => f.key != currencyField.key);
            });

            let totalAmountField = expenseOutput.fields?.length > 0 ?
                expenseView.getFields().find(f => f.key == 'TotalAmount') : undefined;
            let mealsCurrency = expenseOutput.fields?.find(f => f.key == 'CurrencyCountryDropdown');

            if (expenseOutput.mealsClaimed != null && expenseOutput.mealsClaimed.length != 0 && mealsCurrency) {
                let currency = mealsCurrency.value.indexOf('-') > -1 ? mealsCurrency.value.split('-')[1] : mealsCurrency.value;
                this.initializeCategories(expenseView, 1, 9);
                expenseView.categories[1][9].elements.push(this.mapMealsClaimed(expenseOutput.mealsClaimed, currency));
            }

            if (expenseOutput.giftEntryDto != null && totalAmountField && totalAmountField.currency) {
                this.initializeCategories(expenseView, 3, 9);
                expenseView.categories[3][9].elements.push(this.mapGift(expenseOutput.giftEntryDto, totalAmountField.currency));
            }

            let newReceiptsField = new ReceiptViewField();
            newReceiptsField.missingReceiptInfo = expenseOutput.missingReceiptInfo;
            newReceiptsField.receiptUploadMessage = expenseOutput.receiptUploadMessage;
            if (expenseReceipts != null) {
                newReceiptsField.warningMessages = expenseReceipts.warningMessages;
                if (expenseReceipts.receipts?.length != 0) {
                    newReceiptsField.value = expenseReceipts.receipts;
                }
            }

            this.initializeCategories(expenseView, 100, 0);
            expenseView.categories[100][0].elements.push(newReceiptsField);

            expenseView.isReversed = expenseOutput.isReversed;

            expenseView.categories = expenseView.categories.map(c => c = c.filter(r => r.elements.length > 0));
            expenseView.categories = expenseView.categories.filter(rows => rows.length > 0);
            expenseView.currencyDecimal = expenseOutput.currencyDecimal;
            expenseView.isSalarySupplement = expenseOutput.isSalarySupplement;
            expenseView.receiptRequirementCode = expenseOutput.receiptRequirementCode;
            expenseView.receiptTypes = expenseOutput.receiptTypes;
            return expenseView;
        }
        else {
            let expenseView = new ExpenseView(
                null, null, null, null,null, new Array<ExpenseViewFieldRow[]>());
            expenseView.validations.push(expenseOutput.validationErrors[0]);
            return expenseView;
        }
    }


    static mapMealsClaimed(meals: MealsClaimedOutput[], currency: string): MealsClaimedField {
        if (meals == null || meals.length == 0) return null;

        let mealsClaimed: MealClaimed[] = [];
        meals.map(x => {
            let meal = new MealClaimed();
            meal.amount = x.totalAmount.toFixed(2);
            meal.breakfast = x.hasBreakfast;
            meal.dinner = x.hasDinner;
            meal.launch = x.hasLunch;
            meal.dateClaimed = moment(x.entryDate, 'MM/DD/YYYY').toDate();
            meal.breakfastAdjusted = x.breakFastAdjusted;
            meal.launchAdjusted = x.launchAdjusted;
            meal.dinnerAdjusted = x.dinnerAdjusted;
            meal.IsNewDateAdjustment = x.isNewDateAdjustment;
            mealsClaimed.push(meal);
        });

        let mealsField = new MealsClaimedField();
        mealsField.currency = currency;
        mealsField.value = mealsClaimed;
        return mealsField;
    }

    static mapGift(gifts: GiftEntryOutput[], currency: string): GiftField {
        let giftField = new GiftField();
        giftField.value = [];
        giftField.currency = currency;
        if (gifts == null || gifts.length == 0) return giftField;
        gifts.map(x => {
            let gift = new Gift();
            gift.amount = this.numberExpend().toFixedMyte((x.amount)).toFixed(2);
            gift.enterpriseId = x.enterpriseId;
            gift.IsAmountAdjustment = x.isAmountAdjustment;
            gift.IsRecipientAdjustment = x.isRecipientAdjustment;
            gift.isError = x.isError;
            gift.validations = x.validations;
            giftField.value.push(gift);
        });
        return giftField;
    }

    static numberExpend(): any {
        return {
          roundMyte: function(value, length) {
            if (typeof (value) == 'string') {
              value = parseFloat(value);
            }
            let a1 = Math.pow(10, length) * value;
            a1 = Math.round(a1);
            let oldStr = value.toString();
            let start = oldStr.indexOf('.');
            if (start > 0 && oldStr.split('.')[1].length == length + 1) {
              if (oldStr.substr(start + length + 1, 1) == 5) {
                let flagVal = oldStr.substr(start + length, 1) - 0;
                if (flagVal % 2 == 0) {
                  a1 = a1 - 1;
                }
              }
            }
            let b1 = a1 / Math.pow(10, length);
            return b1;
          },
          toFixedMyte: function(value: number, length: number) {
            if (typeof (value) == 'string') {
              value = parseFloat(value);
            }
            if (!length) {
              length = 2;
            }
            let oldStr = value.toString();
            let start = oldStr.indexOf('.');
            if (length == 2 && start > 0 && oldStr.split('.')[1].length > 2) {
              return this.roundMyte(value, length);
            }
            else {
              return parseFloat(value.toFixed(length));
            }
          }
        };
      }

    static mapMealsClaimedToInput(expenseView: ExpenseView): MealsClaimedInput[] {
        let mealsField = expenseView.categories.map(expenseRow => expenseRow)
            .reduce((prev, curr) => prev.concat(curr))
            .map(expenseViewRow => expenseViewRow.elements)
            .reduce((prev, curr) => prev.concat(curr))
            .find(expenseViewFieldRow => expenseViewFieldRow instanceof MealsClaimedField);
        if (!mealsField) return null;

        let mealsClaimed = mealsField.value;

        let inputList: MealsClaimedInput[] = [];
        mealsClaimed.forEach(meal => {
            let input = new MealsClaimedInput;
            input.hasBreakfast = meal.breakfast;
            input.hasDinner = meal.dinner;
            input.hasLunch = meal.launch;
            input.entryDate = moment(meal.dateClaimed).format('MM/DD/YYYY');
            inputList.push(input);
        });

        return inputList;
    }

    static mapExpenseViewToFileInput(expenseView: ExpenseView): FieldInputDto[] {
        return expenseView.categories.map(expenseRow => expenseRow)
            .reduce((prev, curr) => prev.concat(curr))
            .map(expenseViewRow => expenseViewRow.elements)
            .reduce((prev, curr) => prev.concat(curr))
            .filter(expenseViewRow => expenseViewRow instanceof ExpenseViewField)
            .filter(expenseViewRow => expenseViewRow.visible != false || expenseViewRow.key.toLowerCase() == 'exchangerate' || expenseViewRow.key.toLowerCase() == 'amount')
            .map(expenseViewfield => {
                let fieldInput = new FieldInputDto();
                fieldInput.key = expenseViewfield.key;
                fieldInput.value = expenseViewfield.value != null ?
                expenseViewfield.uiType.toLowerCase() == 'dropdown' &&  new RegExp(`^0[0-9]$`).test(expenseViewfield.value)
                ? expenseViewfield.value.toString().substr(1)
                : expenseViewfield.value.toString() : null,
                fieldInput.defaultExpression = null;
                return fieldInput;
            });
    }

    static formatValueToUS(value: string): string {
        if (isNaN(Number(value)) || value === null || value === undefined || value === '' || value === '0') { return value; }
        const decimalSeparator = this.getDecimalLocale();
        const groupSeparator = decimalSeparator === ',' ? '.' : ',';
        return parseFloat(value.replace(groupSeparator, '').replace(decimalSeparator, '.')).toLocaleString('en-US', { minimumFractionDigits: 2 });
    }

    static getDecimalLocale(): string {
        const value = parseFloat('1').toLocaleString(navigator.language, { minimumFractionDigits: 2 });
        return value.replace(/\d+/g, '');
    }

    static mapGiftToInput(expenseView: ExpenseView): GiftEntryInput[] {
        let giftField = expenseView.categories.map(expenseRow => expenseRow)
            .reduce((prev, curr) => prev.concat(curr))
            .map(expenseViewRow => expenseViewRow.elements)
            .reduce((prev, curr) => prev.concat(curr))
            .find(expenseViewFieldRow => expenseViewFieldRow instanceof GiftField);
        if (!giftField) return null;

        let giftEntry = giftField.value;

        let inputList: GiftEntryInput[] = [];
        giftEntry.forEach(gift => {
            let input = new GiftEntryInput;
            input.amount = gift.amount;
            input.enterpriseId = gift.enterpriseId;
            inputList.push(input);
        });

        return inputList;
    }

    static mapReceiptsToReceiptInput(expenseView: ExpenseView, userEid: string): ReceiptInput[] {
        let receiptView = expenseView.categories.map(expenseRow => expenseRow)
            .reduce((prev, curr) => prev.concat(curr))
            .map(expenseViewRow => expenseViewRow.elements)
            .reduce((prev, curr) => prev.concat(curr))
            .find(expenseViewFieldRow => expenseViewFieldRow instanceof ReceiptViewField);
        if (!receiptView) return null;

        let receipts = receiptView.value as Receipt[];

        let inputList: ReceiptInput[] = [];
        receipts.forEach(receipt => {

            let input = new ReceiptInput;
            input.expenseId = expenseView.id;
            input.receiptId = receipt.id;
            input.enterpriseId = userEid;
            input.periodEnd = expenseView.periodEnd;
            input.file = new ReceiptImage();
            input.file.imageDataString = receipt.getFileData();
            input.file.fileName = receipt.fileName;
            input.receiptType = receipt.receiptType;
            inputList.push(input);

        });

        return inputList;
    }

    static mapAmexExpensesToGridExpenses(amexExpenseLists: AmexExpenseOutputDto): AmexForAgGrid[] {
        return amexExpenseLists.amexExpenseForDisplayList.map(amexExpenseItem => {
            let amexExpensesList = new AmexForAgGrid();
            amexExpensesList.transactionId = amexExpenseItem.transactionId;
            amexExpensesList.amexHotelImportDataId = amexExpenseItem.amexHotelImportDataId;
            amexExpensesList.date = amexExpenseItem.dateDisplay;
            amexExpensesList.dateFrom = amexExpenseItem.dateFrom;
            amexExpensesList.expenseTypeCode = amexExpenseItem.expenseTypeCodeDisplay;
            amexExpensesList.amount = amexExpenseItem.amountDisplay;
            amexExpensesList.detail = amexExpenseItem.detailDisplay;
            amexExpensesList.localCountry = amexExpenseItem.localCountryDisplay;
            amexExpensesList.localCurrency = amexExpenseItem.localCurrencyDisplay;
            amexExpensesList.amexExpenseType = amexExpenseItem.amexExpenseType;
            amexExpensesList.dateDisplayToDateWithDayOfWeek = amexExpenseItem.dateDisplayToDateWithDayOfWeek;
            amexExpensesList.expensePreSelectedKey = amexExpenseItem.expensePreSelectedKey;
            amexExpensesList.expensePreSelectedValue = amexExpenseItem.expensePreSelectedValue;
            amexExpensesList.stateProvinceCd = amexExpenseItem.stateProvinceCd;
            amexExpensesList.isAmexHotelFolioExpense = amexExpenseItem.isAmexHotelFolioExpense;
            amexExpensesList.expenseType = this.mapAmexExpenseTypesItem(amexExpenseLists.expenseTypeList);
            return amexExpensesList;
        });
    }

    static mapAmexExpenseTypesItem(amexExpenseTypeLists: ExpenseTypeModel[]): AmexExpenseTypeModel[] {
        return amexExpenseTypeLists.map(amexExpenseTypeItem => {
            let amexExpenseTypeList = new AmexExpenseTypeModel();
            amexExpenseTypeList.code = amexExpenseTypeItem.code;
            amexExpenseTypeList.description = amexExpenseTypeItem.description;
            amexExpenseTypeList.threshold = amexExpenseTypeItem.threshold;
            amexExpenseTypeList.amexExpenseType = amexExpenseTypeItem.amexExpenseType;
            amexExpenseTypeList.canBeImportedFromAMEXMobile = amexExpenseTypeItem.canBeImportedFromAMEXMobile;
            amexExpenseTypeList.isAmexExpenseType = amexExpenseTypeItem.isAmexExpenseType;
            amexExpenseTypeList.isExpenseDisplayingVATModelB = amexExpenseTypeItem.isExpenseDisplayingVATModelB;
            return amexExpenseTypeList;
        });
    }


    /** This method will map the field object retrived by MyTE.API and transform it to a ExpenseViewField, creating
     *  validation with regex base of the type of the UIType for the field and the basic validations.
     * @param fieldOutput The object field from MyTE.Api (ExpenseOutput.Fields).
     * @returns ExpenseViewField With all the validation defined.
     */
    static mapExpenseField(fieldOutput: FieldOutput): ExpenseViewField {
        let validations: Array<ExpenseViewFieldValidation> = new Array<ExpenseViewFieldValidation>();
        let isRounded = false;
        let types = fieldOutput.validations ? fieldOutput.validations.map(validation => validation.type ? validation.type.toLowerCase() : null) : null;
        let dataType = types && types.length > 0 && types.includes('datatype') ?
            fieldOutput.validations.filter(validation => validation.type.toLowerCase() == 'datatype')[0].value.toLowerCase() :
            null;

        if (!fieldOutput.readOnly) {
            if (fieldOutput.required) {
                validations.push(new ExpenseViewFieldValidation('required', /\S/, ' is required'));
            }

            switch (fieldOutput.uiType ? fieldOutput.uiType.toLowerCase() : null) {
                case 'text':
                case 'textarea': {
                    if (fieldOutput.elementType.toLowerCase() == 'integer') {
                        validations.push(new ExpenseViewFieldValidation('integer', new RegExp(/^[0-9]*$/), 'This entry can only contain integer numbers'));
                    }
                    if (fieldOutput.elementType.toLowerCase() == 'decimal' ||
                        fieldOutput.elementType.toLowerCase() == 'money' ||
                        fieldOutput.elementType.toLowerCase() == 'exchangerate') {
                        let regExpForDecimals = new RegExp(/^([0-9]+(\.[0-9]*)?)?$/);
                        validations.push(new ExpenseViewFieldValidation('decimal', regExpForDecimals, 'This entry can only contain decimal number'));
                    }
                    if (fieldOutput.validations && fieldOutput.validations.length > 0) {
                        let maxLength = types && types.length > 0 && types.includes('maxlength') ?
                            fieldOutput.validations.filter(validation => validation.type.toLowerCase() == 'maxlength')[0].value :
                            null;
                        let minLength = types && types.length > 0 && types.includes('minlength') ?
                            fieldOutput.validations.filter(validation => validation.type.toLowerCase() == 'minlength')[0].value :
                            null;

                        if (maxLength) {
                            validations.push(new ExpenseViewFieldValidation('maxlength', new RegExp(`^[\\s\\S]{0,${maxLength}}$`), `Max length is ${maxLength}`));
                        }
                        if (minLength) {
                            validations.push(new ExpenseViewFieldValidation('minlength', new RegExp(`^.{${minLength},}$`), `Min length is ${minLength}`));
                        }
                    }
                    break;
                }
                default:
                    break;
            }
        }
        if (fieldOutput.validations && fieldOutput.validations.length > 0) {
            fieldOutput.validations.forEach(element => {
                if (element.type == 'Regex') {
                    validations.push(new ExpenseViewFieldValidation(element.type, new RegExp(element.value), fieldOutput.tooltip));
                }
                if (element.type == 'NonBlockError') {
                  validations.push(new ExpenseViewFieldValidation(element.type, null, element.value));
                }
                if (element.type == 'BlockError') {
                    validations.push(new ExpenseViewFieldValidation(element.type, null, element.value));
                }
                if (element.type == 'BlockSaveExpenseError') {
                    validations.push(new ExpenseViewFieldValidation(element.type, null, element.value));
                }
                if (element.type == 'decimalplaces') {
                    validations.push(new ExpenseViewFieldValidation(element.type, new RegExp(`^[0-9]+(.[0-9]{${element.value}})?$`)));
                }
                if ((fieldOutput.uiType.toLowerCase() == 'label' || fieldOutput.uiType.toLowerCase() == 'staticnumber') && fieldOutput.elementType.toLowerCase() == 'expressionreturndecimal' && element.type.toLowerCase() == 'round') {
                    isRounded = Number.isInteger(Number(fieldOutput.value));
                }
                if (fieldOutput.uiType.toLowerCase() == 'staticnumber' && (fieldOutput.elementType.toLowerCase() == 'expressionreturnmoney') &&  element.type.toLowerCase() == 'round') {
                    isRounded = Number.isInteger(Number(fieldOutput.value));
                }
            });
        }
        fieldOutput.uiType = fieldOutput.uiType?.toLowerCase() == 'staticnumber' ? 'Label' : fieldOutput.uiType;
        let style = {};
        if (fieldOutput.styles && fieldOutput.styles.length > 0)
            fieldOutput.styles.forEach(s => style[s.cssKey] = s.cssValue);
        let field = new ExpenseViewField(
            fieldOutput.key,
            fieldOutput.uiType == 'CheckBox' ? fieldOutput.value.toLocaleLowerCase() == 'true' : fieldOutput.elementType == 'Decimal' || fieldOutput.elementType == 'Money' ?
                fieldOutput.value = fieldOutput.value.toString().replace(/,/g, '') : fieldOutput.value,
            fieldOutput.label,
            fieldOutput.tooltip,
            fieldOutput.required,
            fieldOutput.readOnly,
            fieldOutput.isAdjustment,
            fieldOutput.previousPPAValue,
            fieldOutput.uiType ? fieldOutput.uiType.toLowerCase() : null,
            dataType,
            fieldOutput.size,
            fieldOutput.order,
            // TODO: christian.m.venini: improve. Check for null in an isolated layer
            fieldOutput.options
                ? fieldOutput.options.map(option => new ExpenseViewFieldOption(option.key, option.value))
                : new Array<ExpenseViewFieldOption>(),
            validations,
            style,
            fieldOutput.elementType,
            fieldOutput.category,
            fieldOutput.row,
            fieldOutput.visible,
            isRounded,
            fieldOutput.childrenLabel);
        if (field.uiType === 'chargecode') {
            let chargeCodeOptions = fieldOutput.chargeCodeOptions
                ? fieldOutput.chargeCodeOptions.map(option => new ExpenseViewFieldChargeCodeOption(option.key, option.value, option.client, option.countryRegion,
                    option.type, option.subtype, option.isValid, option.tooltip, option.masterServicesAgreement, option.projectType, option.hasSuspenseExpenseProject))
                : new Array<ExpenseViewFieldChargeCodeOption>();
            field = new ExpenseViewChargeCodeField(field, chargeCodeOptions);
        }
        return field;
    }

    static mapExpenses(simpleExpenses: SimpleExpense[], timeReportStatus?: string, expenseTotalError?: string): Expense[] {
        return simpleExpenses.map(simpleExpense => {
            let expense = new Expense();
            expense.id = simpleExpense.expenseId;
            expense.sequence = simpleExpense.sequenceNm;
            expense.expenseTypeCd = simpleExpense.expenseTypeCd;
            expense.type = simpleExpense.expenseTypeDesc;
            expense.from = simpleExpense.fromDate;
            expense.to = simpleExpense.toDate;
            expense.source = simpleExpense.source;
            expense.assignment = simpleExpense.wbsDescription;
            expense.status = simpleExpense.approvalStatus;
            expense.additionalInformation = simpleExpense.additionalInfo;
            expense.isAdjusted = simpleExpense.hasAdjustment;
            expense.receiptRequirement = simpleExpense.receiptIcons ? this.mapReceiptRequirement(simpleExpense) : new ReceiptRequirement();
            expense.expenseAmount = simpleExpense.expenseAmount ? this.mapExpenseAmount(simpleExpense) : new ExpenseAmount();
            expense.frequentTrip = simpleExpense.frequentTrip ? this.mapFrequentTrip(simpleExpense) : new ExpenseTripDetail();
            expense.canBeEdited = simpleExpense.canBeEdited;
            expense.currency = simpleExpense.currencyCountryCd;
            expense.hasFederalWBSInd = simpleExpense.hasFederalWBSInd;
            expense.hasExpenseTotalErrorInd = simpleExpense.hasExpenseTotalErrorInd;
            expense.expenseTotalErrors = simpleExpense.expenseTotalErrors;
            expense.hasissues = simpleExpense.hasIssues;
            expense.decimalPlaces = simpleExpense.decimalPlaces;
            expense.expenseProjectChargeHistoryToolTipText = simpleExpense.expenseProjectChargeHistoryToolTipText;
            expense.expenseChargeHistoryToolTipText = simpleExpense.expenseChargeHistoryToolTipText;
            expense.expenseCountry = simpleExpense.expenseCountry;
            expense.isEscalatedExpense = simpleExpense.isEscalatedExpense;
            expense.isSalarySupplementExpense = simpleExpense.isEscalatedExpense;
            expense.taxReimbursableBool = simpleExpense.taxReimbursableBool;
            expense.isReversed = simpleExpense.isReversed;
            expense.exchangeRate = simpleExpense.exchangeRate;
            expense.gSTAmount = simpleExpense.gstAmount;
            expense.amountWithoutTax  = simpleExpense.amountWithoutTax;
            expense.selfContainedDays = simpleExpense.selfContainedDays;
            expense.hotelDays = simpleExpense.hotelDays;
            expense.foreignAmount = simpleExpense.foreignAmount;
            expense.timeReportStatus = timeReportStatus;
            expense.parentExpenseTotalError = expenseTotalError;
            expense.selfCertification = simpleExpense.selfCertification;
            expense.postPayEscalatedInd = simpleExpense.postPayEscalatedInd;
            expense.analyticsAudit = simpleExpense.analyticsAudit;
            expense.analyticsAuditReasons = simpleExpense.analyticsAuditReasons;
            expense.isEditableForAuditor = simpleExpense.isEditableForAuditor;
            return expense;
        });
    }

    static mapExpenseSplitList(responseRes: any): ValidationError {
        let errorMes = new ValidationError();
        if (responseRes.errors[0]) {
            errorMes.errorMsg = responseRes.errors[0].errorMsg;
            errorMes.errorSource = responseRes.errors[0].errorSource;
            errorMes.errorType = responseRes.errors[0].errorType;
        }
        return errorMes;
    }

    static mapAmexInfo(amexInfo: ImportAmexInput): AmexInput {
        let item = new AmexInput();
        item.transactionId = amexInfo.transactionId;
        item.amexHotelImportDataId = amexInfo.amexHotelImportDataId;
        item.expenseSelectedKey = amexInfo.expenseSelectedKey;
        item.expenseSelectedValue = amexInfo.expenseSelectedValue;
        item.detailDisplay = amexInfo.detailDisplay;
        return item;
    }

    static mapImportInfo(corporateCardInfo: ExpenseImportInput): CorporateCardExpenseInput {
        let importExpenseData = new CorporateCardExpenseInput();
        importExpenseData.transactionId = corporateCardInfo.transactionId;
        importExpenseData.amexHotelImportDataId = corporateCardInfo.amexHotelImportDataId;
        importExpenseData.chargeTypecd = corporateCardInfo.chargeTypeCd;
        importExpenseData.expenseSelectedValue = corporateCardInfo.expenseSelectedValue;
        importExpenseData.expenseSelectedKey = corporateCardInfo.expenseSelectedKey;
        importExpenseData.detailDisplay = corporateCardInfo.detailDisplay;

        return importExpenseData;
    }

    static mapExpenseViewForCopy(expenseOutput: ExpenseOutput, expenseReceipts?: ExpenseReceipts): ExpenseView {
        let expenseView = new ExpenseView(
            expenseOutput.expenseId,
            expenseOutput.expenseTypeCd,
            expenseOutput.expenseTypeDescription,
            expenseOutput.periodEnd,
            expenseOutput.countryDecimalPlaces,
            new Array<ExpenseViewFieldRow[]>());
        expenseView.expenseSource = ExpenseViewSource.Copy;

        if (expenseOutput.messages != null && expenseOutput.messages.length > 0) {
            expenseView.messages = expenseOutput.messages.map(msg => new MyteApiMessage(msg.text));
          }
          if (expenseOutput.validationErrors) {
              let validation = expenseOutput.validationErrors.filter(v => v.type.toLowerCase() == 'showconfirmationpopup');
              expenseView.softWarning = validation[0] ? new ExpenseViewSoftWarning(validation[0].value, false) : null;
          }
          expenseOutput.fields.forEach(fieldOutput => {
              this.initializeCategories(expenseView, fieldOutput.category, fieldOutput.row);
              expenseView.categories[fieldOutput.category][fieldOutput.row].elements.push(this.mapExpenseField(fieldOutput));
          });
          let currencyField = expenseOutput.fields.find(f => f.key == 'Currency');
          let totalAmountField = expenseOutput.fields.find(f => f.key == 'TotalAmount');
          let mealsCurrency = expenseOutput.fields.find(f => f.key == 'CurrencyCountryDropdown');
          if (totalAmountField && currencyField) {
              (expenseView.categories.reduce((prev, curr) => prev.concat(curr))
                  .map(r => r.elements).reduce((prev, curr) => prev.concat(curr))
                  .find(f => f.key == 'TotalAmount') as ExpenseViewField).currency = currencyField.value;
              expenseView.categories[currencyField.category][currencyField.row].elements =
                  expenseView.categories[currencyField.category][currencyField.row].elements.filter(f => f.key != currencyField.key);
          }
          if (expenseOutput.mealsClaimed != null && expenseOutput.mealsClaimed.length != 0 && mealsCurrency) {
              let currency = mealsCurrency.value.indexOf('-') > -1 ? mealsCurrency.value.split('-')[1] : mealsCurrency.value;
              this.initializeCategories(expenseView, 1, 9);
              expenseView.categories[1][9].elements.push(this.mapMealsClaimed(expenseOutput.mealsClaimed, currency));
          }
          if (expenseOutput.giftEntryDto != null && currencyField) {
              this.initializeCategories(expenseView, 3, 9);
              expenseView.categories[3][9].elements.push(this.mapGift(expenseOutput.giftEntryDto, currencyField.value));
          }
          let newReceiptsField = new ReceiptViewField();
          newReceiptsField.missingReceiptInfo = expenseOutput.missingReceiptInfo;
          newReceiptsField.receiptUploadMessage = expenseOutput.receiptUploadMessage;
          if (expenseReceipts != null) {
              newReceiptsField.warningMessages = expenseReceipts.warningMessages;
              if (expenseReceipts.receipts.length != 0) {
                  newReceiptsField.value = expenseReceipts.receipts;
              }
          }
          this.initializeCategories(expenseView, 100, 0);
          expenseView.categories[100][0].elements.push(newReceiptsField);
          expenseView.isReversed = expenseOutput.isReversed;
          expenseView.categories = expenseView.categories.map(c => c = c.filter(r => r.elements.length > 0));
          expenseView.categories = expenseView.categories.filter(rows => rows.length > 0);
          expenseView.currencyDecimal = expenseOutput.currencyDecimal;
          expenseView.receiptTypes = expenseOutput.receiptTypes;
          return expenseView;
    }

    static joinRecalculatedExpense(newExpenseView: ExpenseView, actualExpenseView: ExpenseView): ExpenseView {
        let newFields = newExpenseView.categories.map(expenseRow => expenseRow)
            .reduce((prev, curr) => prev.concat(curr))
            .map(expenseViewRow => expenseViewRow.elements)
            .reduce((prev, curr) => prev.concat(curr))
            .filter(expenseViewRow => expenseViewRow instanceof ExpenseViewField) as ExpenseViewField[];

        let actualFields = actualExpenseView.categories.map(expenseRow => expenseRow)
            .reduce((prev, curr) => prev.concat(curr))
            .map(expenseViewRow => expenseViewRow.elements)
            .reduce((prev, curr) => prev.concat(curr))
            .filter(expenseViewRow => expenseViewRow instanceof ExpenseViewField) as ExpenseViewField[];

        let receiptField = actualExpenseView.categories.map(expenseRow => expenseRow)
            .reduce((prev, curr) => prev.concat(curr))
            .map(expenseViewRow => expenseViewRow.elements)
            .reduce((prev, curr) => prev.concat(curr))
            .find(expenseViewRow => expenseViewRow instanceof ReceiptViewField);

        let mealsClaimedField = newExpenseView.categories.map(expenseRow => expenseRow)
            .reduce((prev, curr) => prev.concat(curr))
            .map(expenseViewRow => expenseViewRow.elements)
            .reduce((prev, curr) => prev.concat(curr))
            .find(expenseViewRow => expenseViewRow instanceof MealsClaimedField);

        let giftField = newExpenseView.categories.map(expenseRow => expenseRow)
            .reduce((prev, curr) => prev.concat(curr))
            .map(expenseViewRow => expenseViewRow.elements)
            .reduce((prev, curr) => prev.concat(curr))
            .find(expenseViewRow => expenseViewRow instanceof GiftField);

        let removeAccompaniedLabel = actualFields.find(x => x.key == 'T2_AccompaniedLabel');
        if (removeAccompaniedLabel) {
            let remove = actualFields.indexOf(removeAccompaniedLabel);
            if (remove >= 0)
                actualFields.splice(remove, 1);
        }

        newFields.forEach(field => {
            let fieldToChange = actualFields.find(x => x.key == field.key);

            if (Number.parseFloat(field.value) < 0) {
                let re = /-/gi;
                let newStr: string = field.value.replace(re, '(');
                field.value = newStr + ')';
            }
            if (fieldToChange) {
                // NOTE (m.fernandez.bortolas) The field has a change and need to be replaced
                fieldToChange.errors.forEach(err => {
                    if (err.type == 'required' && field.required) {
                        field.errors.push(err);
                    }
                });
                actualFields[actualFields.indexOf(fieldToChange)] = field;
            } else {
                // NOTE (m.fernandez.bortolas) The API has returned a new Field and need to be added.
                actualFields.push(field);
            }
        });


        actualExpenseView.messages = newExpenseView.messages;
        actualExpenseView.currencyDecimal = newExpenseView.currencyDecimal;
        actualExpenseView.categories = [];

        actualFields.forEach(field => {
            this.initializeCategories(actualExpenseView, field.category, field.row);
            actualExpenseView.categories[field.category][field.row].elements.push(field);
        });

        if (receiptField) {
            this.initializeCategories(actualExpenseView, receiptField.category, receiptField.row);
            actualExpenseView.categories[receiptField.category][receiptField.row].elements.push(receiptField);
        }

        if (mealsClaimedField) {
            this.initializeCategories(actualExpenseView, mealsClaimedField.category, mealsClaimedField.row);
            actualExpenseView.categories[mealsClaimedField.category][mealsClaimedField.row].elements.push(mealsClaimedField);
        }

        if (giftField) {
            this.initializeCategories(actualExpenseView, giftField.category, giftField.row);
            actualExpenseView.categories[giftField.category][giftField.row].elements.push(giftField);
        }

        actualExpenseView.categories = actualExpenseView.categories.map(c => c = c.filter(r => r.elements.length > 0));
        actualExpenseView.categories = actualExpenseView.categories.filter(rows => rows.length > 0);
        actualExpenseView.isSalarySupplement = newExpenseView.isSalarySupplement;
        actualExpenseView.receiptRequirementCode = newExpenseView.receiptRequirementCode;
        actualExpenseView.receiptTypes = newExpenseView.receiptTypes;

        return actualExpenseView;
    }

    static mapRequestRes(res): boolean {
        return res.errorMsg ? false : true;
    }

    //#endregion

    private static initializeCategories(expenseView: ExpenseView, categotyCode: number, row: number): void {
        if (!expenseView.categories[categotyCode])
            expenseView.categories[categotyCode] = new Array<ExpenseViewFieldRow>();
        if (!expenseView.categories[categotyCode][row])
            expenseView.categories[categotyCode][row] = new ExpenseViewFieldRow();
        if (!expenseView.categories[categotyCode][row].elements)
            expenseView.categories[categotyCode][row].elements = new Array<ExpenseViewField>();
    }

    private static mapReceiptRequirement = (expense: SimpleExpense) =>
        new ReceiptRequirement(expense.receiptIcons.type,
            expense.receiptIcons.electronicProvided,
            expense.receiptIcons.electronicTooltip,
            expense.receiptIcons.physicalProvided,
            expense.receiptIcons.physicalTooltip)

    private static mapExpenseAmount = (expense: SimpleExpense) =>
        new ExpenseAmount(expense.expenseAmount.totalAmount,
            expense.expenseAmount.message,
            ExpenseServiceMapper.parseMoney(expense.expenseAmount.taxTrueUpValue),
            expense.expenseAmount.isValid,
            expense.expenseAmount.validationErrors,
            expense.decimalPlaces)

    private static mapFrequentTrip = (expense: SimpleExpense) =>
        new ExpenseTripDetail(expense.frequentTrip.id,
            expense.frequentTrip.name,
            expense.frequentTrip.reason)

     static parseMoney(value: string): string {
        let decimalSeparator = this.decimalSeparator();
        let groupSeparator = this.groupSeparator();
        let output = value;
        if (groupSeparator) {
            output = output.replace(new RegExp('\\' + groupSeparator, 'g'), '');
        }
        output = output.replace(new RegExp('\\' + decimalSeparator, 'g'), '.');
        let parsedNumber = output.split('.');
        if (parsedNumber.length > 1) {
            let intPart = parsedNumber.slice(0, -1).join('');
            let decPart = parsedNumber.slice(-1)[0];
            return intPart + '.' + decPart;
        }
        return output;
    }

     static decimalSeparator = () => (1.1).toLocaleString(navigator.language).substring(1, 2);

    private static groupSeparator() {
        let separator = (1000).toLocaleString(navigator.language).substring(1, 2);
        if (separator.charCodeAt(0) == 160) return separator;
        if (isNaN(Number(separator))) return separator;
        separator = (10000).toLocaleString(navigator.language).substring(2, 3);
        if (isNaN(Number(separator))) return separator;

        return '';
    }

    public static mapAPIDropdownItems(apiDropdownItems: Array<[string, string]>): Array<DropdownItem> {
        const dropdownItems: Array<DropdownItem> = new Array<DropdownItem>();
        if (apiDropdownItems && apiDropdownItems.length > 0) {
            apiDropdownItems.map((apiDropdownItem: [string, string]) => {
                const dropdownItem: DropdownItem = new DropdownItem();
                if (apiDropdownItem && Object.keys(apiDropdownItem).length > 0) {
                    dropdownItem.key = apiDropdownItem[0];
                    dropdownItem.value = apiDropdownItem[1];
                }
                dropdownItems.push(dropdownItem);
            });
        }
        return dropdownItems;
    }
}
