import { EventEmitter } from '@angular/core';
import { Component, Input, Output } from '@angular/core';
import { SubordinatesService } from '../../../shared/services/subordinates/subordinates.service';
import Subordinate from '../../../shared/models/subordinates/subordinate';
import { GlobalEventsService } from '../../../shared/services/events/global-events.service';
import { GlobalCacheService } from '../../../shared/services/cache/global-cache.service';
import { TimeReportService } from '../../../shared/services/time-report/time-report.service';
import { mergeMap } from 'rxjs/operators';
import { PeriodEndService } from '../../../shared/services/periodend/periodend.service';
import { SubordinatesMode } from '../../../shared/models/subordinates/subordinate-mode';
import { TimeReportUtils } from '../../../shared/utils/timeReport.utils';
import { of } from 'rxjs';

@Component({
    selector: 'myte-time-submitAll-popup',
    templateUrl: './time-submitAll-popup.component.html',
    styleUrls: ['./time-submitAll-popup.component.sass']
  })
export class TimeSubmitAllPopupComponent {
    @Input() public subordinatesToSubmit:Subordinate[];
    @Input() public subordinateMode: SubordinatesMode;
    @Input() public isReview: boolean;
    @Output() public close = new EventEmitter<void>();

    constructor(
        private subordinatesService: SubordinatesService,
        private globalEventsService: GlobalEventsService,
        private globalCacheService: GlobalCacheService,
        private timeReportService: TimeReportService,
        private periodEndService: PeriodEndService,
    ){}

    public closeModal() {
        this.close.emit();
    }

    public sureToSubmit() {
        this.globalEventsService.dispatchShowGlobalSpinnerEvent(true);
        this.globalCacheService.resetSubordinatesList();
        let periodEnd = this.periodEndService.getActivePeriod();
        this.timeReportService.submitTimeReportGroup(this.subordinatesToSubmit, this.subordinateMode, periodEnd)
            .pipe(mergeMap((res) => {
                if (res)
                return this.subordinatesService.getSubordinatesList(this.subordinateMode, periodEnd, this.isReview);
            }))
            .subscribe(subordinateList => {
                subordinateList.map(sub => {
                    sub.errorMessage = this.subordinatesToSubmit.find(err => err.peopleKey === sub.peopleKey)?.errorMessage;
                });
                this.subordinatesToSubmit = subordinateList;
                this.globalEventsService.dispatchShowGlobalSpinnerEvent(false);
                this.globalEventsService.dispatchSubmitAllEvent({ subordinates: subordinateList, isReview: this.isReview, shouldRefreshSubordinateList: true})
                if (this.globalCacheService.getCurrentSubordinate()) {
                    let currentSubordinate = this.subordinatesToSubmit.filter( subordinate => {return subordinate.enterpriseId == this.globalCacheService.getCurrentSubordinate().enterpriseId});
                    this.refreshCurrentSubordinateTimeReport(currentSubordinate[0].timeReportStatus,periodEnd);
                }
            });
        this.closeModal();
    }
    public refreshCurrentSubordinateTimeReport(timeReportStatus: string , periodEnd: Date) {
        this.globalEventsService.dispatchShowGlobalSpinnerEvent(true);
        this.globalCacheService.handleResetTimeReport(periodEnd);
        let timeReport = TimeReportUtils.createTimeReport(periodEnd,timeReportStatus,this.globalCacheService.getSubordinateMode());
        this.globalCacheService.handleTimeReport(periodEnd, of(timeReport));
        this.globalEventsService.dispatchTimeReportStatusChangedEventBySubmitAll(timeReport);
        // this.confirmButton.nativeElement.disabled = false;
        this.globalEventsService.dispatchShowGlobalSpinnerEvent(false);
        this.globalEventsService.dispatchSubmitTimeEntryReportEvent(true);
    }


}
