export {};

declare global {
    interface Date {
      toISOStringMyTE: () => string;
    }
}

Object.defineProperty(Date.prototype, 'toISOStringMyTE', {
    value: function() { return `${[this.getMonth() + 1, this.getDate(), this.getFullYear()].join('-')}`; },
    writable: false,
    enumerable: false,
    configurable: false,
});