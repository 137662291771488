import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { environment } from '@environments/environment';
import { HttpErrorResponse } from '@angular/common/http';

export interface ILogService {
  logError(msg: any, showToast?: boolean, title?: string, log?: any, isAlways?: boolean, timeout?: number): void;
  logErrorWithMsgInfo(msgInfo): void;
  logWarning(msg: any, showToast?: boolean, title?: string, log?: any, isAlways?: boolean): void;
  logInfo(msg: any, showToast?: boolean, title?: string, log?: any, isAlways?: boolean): void;
  logWarningWithMsgInfo(msgInfo: any): void;
  showSuccessMessage(title: string, msg: string, isAlways?: boolean): void;
  showSuccessWithMsgInfo(msgInfo: any): void;
  showValidationMessage(title: string, msg: string, isAlways: boolean): void
}

@Injectable({
  providedIn: 'root'
})
export class LogService implements ILogService {
  constructor(private toast: ToastrService) {
  }

  public logError(msg: any, showToast?: boolean, title?: string, log?: any, isAlways: boolean = false, timeout: number = 5000): void {
    if (title === null) title = 'Error';
    let message = msg;

    if (showToast) {
      if (msg.isSwaggerException) {
        message = msg.message;
      }

      if (msg instanceof (Error)) {
        message = msg.message;
      }

      if (msg instanceof (HttpErrorResponse)) {
        message = msg.message;
      }

      this.toast.toastrConfig.disableTimeOut = isAlways;

      this.toast.error(message, title, {timeOut: timeout});
    }


    if (this.showInBrowser()) {
      console.error(message);
    }
  }

  public logErrorWithMsgInfo(msgInfo): void {
    let { msg: msg, showToast: showToast, title: title, log: log, isAlways: isAlways = false, timeout: timeout = 5000 } = msgInfo;
    if (title == null) title = 'Error';
    let message = msg;

    if (showToast) {
      if (msg.isSwaggerException) {
        message = msg.message;
      }

      if (msg instanceof (Error)) {
        message = msg.message;
      }

      if (msg instanceof (HttpErrorResponse)) {
        message = msg.message;
      }
      this.toast.toastrConfig.disableTimeOut = isAlways;
      this.toast.toastrConfig.enableHtml = true;

      var linkPosition = message.indexOf("href");
      if (linkPosition >= 0){
        const reg = new RegExp(/(<a[\s]+[^>]+>)([^>]+)(<\/a\>)/gm)
        var link = message.match(reg)[0];
        var newLink=this.formatHtml(link);
        this.toast.error(message.replace(link, newLink), title, {timeOut: timeout});
      }else{
        this.toast.error(this.formatHtml(message), title, {timeOut: timeout});
      } 
    }


    if (this.showInBrowser()) {
      console.error(message);
    }
  }
  public logWarningYellow(msg: any, showToast?: boolean, title?: string, log?: any, isAlways: boolean = false): void {
    if (title == null) title = 'Warning';
    let message = msg;

    if (showToast) {
      if (msg.isSwaggerException) {
        message = msg.message;
      }

      if (msg instanceof (Error)) {
        message = msg.message;
      }

      if (msg instanceof (HttpErrorResponse)) {
        message = msg.message;
      }

      this.toast.toastrConfig.disableTimeOut = isAlways;
      this.toast.toastrConfig.enableHtml = true;
      var linkPosition = message.indexOf("href");
      if (linkPosition >= 0){
        const reg = new RegExp(/(<a[\s]+[^>]+>)([^>]+)(<\/a\>)/gm)
        var link = message.match(reg)[0];
        var newLink=this.formatHtml(link);
        this.toast.warning(message.replace(link, newLink), title);
      }else{
        this.toast.warning(message, title);
      } 
    }


    if (this.showInBrowser()) {
      console.warn(message);
    }
  }
  public logWarning(msg: any, showToast?: boolean, title?: string, log?: any, isAlways: boolean = false): void {
    if (title == null) title = 'Warning';
    let message = msg;

    if (showToast) {
      if (msg.isSwaggerException) {
        message = msg.message;
      }

      if (msg instanceof (Error)) {
        message = msg.message;
      }

      if (msg instanceof (HttpErrorResponse)) {
        message = msg.message;
      }

      this.toast.toastrConfig.disableTimeOut = isAlways;
      this.toast.toastrConfig.enableHtml = true;

      this.toast.error(message, title);
    }


    if (this.showInBrowser()) {
      console.warn(message);
    }
  }

  public logInfo(msg: any, showToast?: boolean, title?: string, log?: any, isAlways: boolean = false): void {
    if (title == null) title = 'Information';
    let message = msg;

    if (showToast) {
      if (msg.isSwaggerException) {
        message = msg.message;
      }

      if (msg instanceof (Error)) {
        message = msg.message;
      }

      if (msg instanceof (HttpErrorResponse)) {
        message = msg.message;
      }

      this.toast.toastrConfig.disableTimeOut = isAlways;
      this.toast.toastrConfig.enableHtml = true;

      this.toast.info(message, title);
    }

    if (this.showInBrowser()) {
      console.log(message);
    }
  }

  public logWarningWithMsgInfo(msgInfo: any): void {
    let { msg: msg, showToast: showToast, title: title, log: log, isAlways: isAlways = false } = msgInfo;
    if (title == null) title = 'Warning';
    let message = msg;

    if (showToast) {
      if (msg.isSwaggerException) {
        message = msg.message;
      }

      if (msg instanceof (Error)) {
        message = msg.message;
      }

      if (msg instanceof (HttpErrorResponse)) {
        message = msg.message;
      }

      this.toast.toastrConfig.disableTimeOut = isAlways;
      this.toast.error(message, title);
    }


    if (this.showInBrowser()) {
      console.warn(message);
    }
  }

  public showSuccessMessage(title: string, msg: string, isAlways: boolean = false): void {
    this.toast.toastrConfig.disableTimeOut = isAlways;
    this.toast.success(msg, title);
  }

  public showSuccessWithMsgInfo(msgInfo: any): void {
    let { title, msg, isAlways: isAlways = false } = msgInfo;
    this.toast.toastrConfig.disableTimeOut = isAlways;
    this.toast.success(msg, title);
  }

  public showValidationMessage(title: string, msg: string, isAlways: boolean = false): void {
    this.toast.toastrConfig.disableTimeOut = isAlways;
    this.toast.toastrConfig.preventDuplicates = true;
    this.toast.error(msg, title);
  }

  private showInBrowser(): boolean {
    return environment.name == 'Mock' || environment.name == 'Local';
  }

  private formatHtml(msg: string): string {
    return msg
      .replace('<a href', `<a target="_blank" href`)
      .replace('">', `"><u>`)
      .replace('</a>', `</u></a>`);
  }
}
