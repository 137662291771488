import { LocationsSummaryRow } from './locations-summary-row';

export class LocationsGridData {
    public rowData: Array<LocationsSummaryRow>;
    public pinnedBottomRowData: Array<LocationsSummaryRow>;

    constructor(rowData?: Array<LocationsSummaryRow>, pinnedBottomRowData?: Array<LocationsSummaryRow>) {
    
        this.rowData = rowData ?? new Array(1).fill(new LocationsSummaryRow());
        this.pinnedBottomRowData = pinnedBottomRowData ?? [new LocationsSummaryRow('', 'Total')];
    }
}