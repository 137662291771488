import { ProjectDetailInfo, ProjectDescendantTreeValidationError, NetworkHierarchy } from '@sharedClients/chargecode-api-client';
import { Option } from '@sharedClients/expense-api-client';

export class ChargeCodeInfo {
    public isDisplay: boolean;
    public externalNumber: string;
    public description: string;
    public clientName: string;
    public clientId: string;
    public owner: string;
    public wbsDescription: Option;
    public contractName: string;
    public type: string;
    public subType: string;
    public projectType: string;
    public opportunityID: string;
    public country: string;
    public businessActivityLevel: string;
    public valid: boolean;
    public validForUser: boolean;
    public assignmentOrigin: string;
    public productivityCategory: string;
    public projectDetailInfo: ProjectDetailInfo;
    public toolTip?: string;
    public dismissValue: boolean;
    public hasNetworks: boolean;
    public hasCostCollectors: boolean;
    public hasDismissCheckboxChecked: boolean;
    public masterServicesAgreement: string;
    public treeValidationError: ProjectDescendantTreeValidationError;
    public treeNetworkHierarchy: NetworkHierarchy;
    public projectTypeCode: string;
    public isLeaf: boolean;
    public errorType: string;
    public isHoliday: boolean;

    constructor(isDisplay?: boolean, externalNumber?: string, description?: string, clientName?: string, clientId?: string, owner?: string, wbsDescription?: Option, 
        contractName?: string, type?: string, subType?: string, projectType?: string, opportunityID?: string, country?: string, businessActivityLevel?: string, 
        valid?: boolean, validForUser?: boolean, assignmentOrigin?: string, productivityCategory?: string, projectDetailInfo?: ProjectDetailInfo, toolTip?: string, 
        dismissValue?: boolean, hasNetworks?: boolean, hasCostCollectors?: boolean, hasDismissCheckboxChecked?: boolean, masterServicesAgreement?: string, 
        treeValidationError?: ProjectDescendantTreeValidationError, treeNetworkHierarchy?: NetworkHierarchy, projectTypeCode?: string, isLeaf?: boolean, 
        errorType?: string, isHoliday?: boolean) {
        
        this.isDisplay = isDisplay ?? true;
        this.externalNumber = externalNumber;
        this.description = description;
        this.clientName = clientName;
        this.clientId = clientId;
        this.owner = owner;
        this.wbsDescription = wbsDescription ?? this.initWbsDescription();
        this.contractName = contractName;
        this.type = type;
        this.subType = subType;
        this.projectType = projectType;
        this.opportunityID = opportunityID;
        this.country = country;
        this.businessActivityLevel = businessActivityLevel ?? '';
        this.valid = valid;
        this.validForUser = validForUser;
        this.assignmentOrigin = assignmentOrigin;
        this.productivityCategory = productivityCategory;
        this.projectDetailInfo = projectDetailInfo ?? new ProjectDetailInfo();
        this.toolTip = toolTip;
        this.dismissValue = dismissValue;
        this.hasNetworks = hasNetworks;
        this.hasCostCollectors = hasCostCollectors;
        this.hasDismissCheckboxChecked = hasDismissCheckboxChecked;
        this.masterServicesAgreement = masterServicesAgreement;
        this.treeValidationError = treeValidationError;
        this.treeNetworkHierarchy = treeNetworkHierarchy;
        this.projectTypeCode = projectTypeCode;
        this.isLeaf = isLeaf;
        this.errorType = errorType;
        this.isHoliday = isHoliday;
    }

    private initWbsDescription(): Option {
        const wbsDescription: Option = new Option();
        wbsDescription.isValid = true;
        return wbsDescription;
    }
}