import * as moment from 'moment';
export class WorkScheduleGroup {
    public id: any;
    public type: WorkScheduleType;
    public displayName: string;
    public default: boolean;
    public staticWorkScheduleId: any;
    public WorkSchedules: WorkSchedule[];
    public selected: boolean;

    constructor(type?: WorkScheduleType, displayName?: string, id?: any, isDefault?: boolean, staticWorkScheduleId?: any) {
        this.id = id ?? '';
        this.type = type ?? WorkScheduleType.Default;
        this.displayName = displayName ?? '';
        this.default = isDefault;
        this.staticWorkScheduleId = staticWorkScheduleId ?? '';
        this.WorkSchedules = [];
        this.selected = false;
    }

    public get isStatic(): boolean {
        return !this.isCustom;
    }

    public get isCustom(): boolean {
        return this.type == WorkScheduleType.CustomForPeriod || this.type == WorkScheduleType.PersonalSchedule;
    }
}
export class WorkSchedule {
    public shift: string;
    public charges: WorkScheduleCharge[] = [];
    public editable: boolean;
    public isInvalid : boolean;

    public get total(): number {
        let total = 0;
        this.charges.forEach(x => total += Number(x.charge));
        return total;
    }

    constructor(args: WorkScheduleConstructorArgs) {
        this.shift = args.shift;

        if (args.dates) {
            args.dates.forEach(date => {
                let simple = args.simpleWorkScheduleCharge.find(simple => simple.getDayName().substr(0,3) == date.toString().substr(0,3));
                   this.charges.push(new WorkScheduleChargeForPeriod(date, simple.charge));
            });
            if (args.type) console.warn("Cannot set WorkScheduleType for period configuration")
        } else {
            if (args.personalDays) {
                this.charges.push(new SimpleWorkScheduleCharge(args.personalDays[0], 'Monday'));
                this.charges.push(new SimpleWorkScheduleCharge(args.personalDays[1], 'Tuesday'));
                this.charges.push(new SimpleWorkScheduleCharge(args.personalDays[2], 'Wednesday'));
                this.charges.push(new SimpleWorkScheduleCharge(args.personalDays[3], 'Thursday'));
                this.charges.push(new SimpleWorkScheduleCharge(args.personalDays[4], 'Friday'));
                this.charges.push(new SimpleWorkScheduleCharge(args.personalDays[5], 'Saturday'));
                this.charges.push(new SimpleWorkScheduleCharge(args.personalDays[6], 'Sunday'));
            }
            else {
                this.charges.push(new SimpleWorkScheduleCharge(8, 'Monday'));
                this.charges.push(new SimpleWorkScheduleCharge(8, 'Tuesday'));
                this.charges.push(new SimpleWorkScheduleCharge(8, 'Wednesday'));
                this.charges.push(new SimpleWorkScheduleCharge(8, 'Thursday'));
                this.charges.push(new SimpleWorkScheduleCharge(8, 'Friday'));
                this.charges.push(new SimpleWorkScheduleCharge(8, 'Saturday'));
                this.charges.push(new SimpleWorkScheduleCharge(8, 'Sunday'));
            }
        }
    }
}

export interface WorkScheduleConstructorArgs {
    shift: string;
    type?: WorkScheduleType
    dates?: Date[];
    personalDays?: number[];
    simpleWorkScheduleCharge?: SimpleWorkScheduleCharge[];
}

export enum WorkScheduleType {
    Default = "default",
    NonStandar = "Non Standard",
    ShiftSchedule = "Shift Schedule",
    FullTime = "Full Time",
    FullTimeEightHours = "Full Time - 8 hrs",
    FullTimeSevenHalfHours = "Full Time - 7.5 hrs",
    FullTimeSAV = "Full Time - 38.5h SAV",
    FullTime352 = "Full Time - 38.5h",
    FullTime35 = "Full Time - 35h",
    FullTime39 = "Full Time - 39h",
    FullTime36 = "Full Time - 36.8h",
    FullTime42 = "Full Time - 42hs",
    FullTime88 = "Full Time – 8.8hs",
    FullTime68 = "Full Time – 6.8hs",
    FullTimeATSASF = "Full Time - 38.5h ATSASF",
    FullTime37 = "Full Time - 37h",
    FullTimeNineHours = "Full Time - 9 hrs",
    FullTimeEightHalfHours = "Full Time - 8.6 hrs",
    PartTimeEightHalfHours2 = "Part Time - 8.6 hrs",
    FullTime43 = "Full Time - 43h",
    PartTime35 = "Part Time - 35h",
    FullTime44 = "Full-Time - 44 h",
    PartTime20 = "Part Time - 20 hrs",
    PartTime = "Part Time",
    PartTimeEightHours = "Part Time - 8 hrs",
    PartTimeSevenHours = "Part Time - 7.5 hrs",
    NightShift = "Night Shift",
    SixDayWorking = "6 days working",
    NewEmployee = "Full Time - New Employee",
    PartTimeMW ="Part Time - 7.5 hrs M-W",
    PartTimeSixHours = "Part Time - 6 hrs",
    PartTimeSevenHalfHours = "Part Time - 7.5 hrs",
    PartTimeFourHours = "Part Time - 4 hrs",
    DayShiftEigthHours = "Day Shift - 8 hrs",
    MixedShiftSevenHours = "Mixed Shift - 7 hrs",
    MixedShiftSixHours = "Night Shift - 6 hrs",
    PersonalSchedule = "Personal Schedule",
    CustomForPeriod = "Personal Period Schedule",
    PartTimeNineHours = "Part Time - 9 hrs",
    FullTimeInterns = "Full Time - 35h Interns",
}

export namespace WorkScheduleType {
    export function getValueByString(type: string): WorkScheduleType {
        return (WorkScheduleType as any)[Object.keys(WorkScheduleType).filter(k => (WorkScheduleType as any)[k] === type)[0]];
    }
}

export interface WorkScheduleCharge {
    charge: number;
    getDayName(): string;
    isWeekend(): boolean;
    getFullDay(): Date;
}

export class WorkScheduleChargeForPeriod implements WorkScheduleCharge {
    constructor(public date: Date, public charge: number) { }

    public getDayName(): string {
        return moment(this.date).format('dddd');
    }

    public isWeekend(): boolean {
        return this.getDayName() === "Saturday" || this.getDayName() === "Sunday";
    }

    public getFullDay(): Date {
        return this.date;
    }
}

export class SimpleWorkScheduleCharge implements WorkScheduleCharge {
    constructor(public charge: number, private dayName: string) { }

    public getDayName(): string {
        return this.dayName;
    }

    public isWeekend(): boolean {
        return this.dayName === "Saturday" || this.dayName === "Sunday";
    }

    public getFullDay(): Date {
        return null;
    }
}
