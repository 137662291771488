import { PersonalCommuteInput } from './personalCommuteInput';

export default class SaveUserPreferencesInput {
    public enterpriseId: string;
    public reviewers: string[];
    public delegates: string[];
    public submission: string[];
    public approvers: string[];
    public backupApprovers: string[];
    public overtimeApprovers: string[];
    public allowanceApprovers: string[];
    public workshiftApprovers: string[];
    public onCallAndSWTimeApprovers: string[];
    public residentLocationCd: string;
    public homeOffice1Code: string;
    public homeOffice2Code: string;
    public personalCommuteData: PersonalCommuteInput;
    public uberForBusiness: boolean;
    public lyftAcceptanceIndicator: boolean;
    public isApprovalRequested: boolean;
    public isBackupApprover: boolean;
    public isAuditDelegateRequested: boolean;
    public compensatoryTabIndicator: boolean;
    public locationReasonId: number;
    public locationTypeId: number;

    constructor(
        enterpriseId?: string,
        reviewers?: string[],
        delegates?: string[],
        submission?: string[],
        approvers?: string[],
        backupApprovers?: string[],
        residentLocationCd?: string,
        homeOffice1Code?: string,
        homeOffice2Code?: string,
        personalCommuteData?: PersonalCommuteInput,
        uberForBusiness?: boolean,
        isApprovalRequested?: boolean,
        isBackupApprover?: boolean,
        isAuditDelegateRequested?: boolean,
        compensatoryTabIndicator?: boolean,
        locationReasonId?: number,
        locationTypeId?: number,
    ) {
        this.enterpriseId = enterpriseId;
        this.reviewers = reviewers;
        this.delegates = delegates;
        this.submission = submission;
        this.approvers = approvers;
        this.backupApprovers = backupApprovers;
        this.residentLocationCd = residentLocationCd;
        this.homeOffice1Code = homeOffice1Code;
        this.homeOffice2Code = homeOffice2Code;
        this.personalCommuteData = personalCommuteData;
        this.uberForBusiness = uberForBusiness;
        this.isApprovalRequested = isApprovalRequested;
        this.isBackupApprover  = isBackupApprover;
        this.isAuditDelegateRequested = isAuditDelegateRequested;
        this.compensatoryTabIndicator = compensatoryTabIndicator;
        this.locationReasonId = locationReasonId;
        this.locationTypeId = locationTypeId;
    }
}