import { Component } from '@angular/core';
import { TimeEntryTemplateAssignment, TimeEntryTemplateTask } from '@shared/models/time-sheet/time-entry/time-entry-template';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { FocusService } from '../../../../../shared/services/focus/focus.service';

@Component({
  selector: 'myte-time-entry-template-popup-grid-chargecode-render',
  templateUrl: './time-entry-template-popup-grid-chargecode-render.component.html',
  styleUrls: ['./time-entry-template-popup-grid-chargecode-render.component.sass']
})
export class TimeEntryTemplatePopupGridChargecodeRenderComponent implements ICellRendererAngularComp {
  public params: ICellRendererParams<TimeEntryTemplateTask, string> & { ariaLabel: string, id: string, cellId: string, assignments: TimeEntryTemplateAssignment[] };
  public value: string;
  public hasError: boolean;
  public tooltip: string;

  constructor() { }

  public agInit(params: ICellRendererParams<TimeEntryTemplateTask, string> & { ariaLabel: string, id: string, cellId: string, assignments: TimeEntryTemplateAssignment[] }): void {
    this.params = params;
    this.value = params.value;
    this.hasError = params.data.assignment.hasError;
    this.tooltip = params.data.assignment.tooltip;
    FocusService.redirectFocus(params?.eGridCell, params?.id);
  }

  public refresh(params: ICellRendererParams<TimeEntryTemplateTask, string>): boolean {
    return false;
  }
}