export abstract class DatePickerUtils {
    public static getWeekDayShortNameByLanguage(weekday) {
        switch (navigator.language) {
            case 'zh-CN':
            case 'zh-SG':
            case 'zh-TW':
            case 'zh':
                return ['一', '二', '三', '四', '五', '六', '日'][weekday - 1];
            case 'fr':
                return ['lu', 'ma', 'me', 'je', 've', 'sa', 'di'][weekday - 1];
            case 'pt':
                return ['S', 'T', 'Q', 'Q', 'S', 'S', 'D'][weekday - 1];
            case 'bg':
            case 'uk':
                return ['Пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'нд'][weekday - 1];
            case 'cs':
                return ['Po', 'Út', 'St', 'Čt', 'Pá', 'So', 'Ne'][weekday - 1];
            case 'da':
                return ['ma', 'ti', 'on', 'to', 'fr', 'lø', 'sø'][weekday - 1];
            case 'de':
                return ['mo', 'di', 'mi', 'do', 'fr', 'sa', 'so'][weekday - 1];
            case 'es':
            case 'es-us':
                return ['L', 'M', 'X', 'J', 'V', 'S', 'D'][weekday - 1];
            case 'ja':
                return ['月', '火', '水', '木', '金', '土', '日'][weekday - 1];
            case 'pl':
                return ['Pon', 'Wto', 'Śro', 'Czw', 'Pią', 'Sob', 'Nie'][weekday - 1];
            case 'lv':
                return ['Pr', 'Ot', 'Tr', 'Ce', 'Pk', 'Se', 'Sv'][weekday - 1];
            case 'nb':
                return ['ma.', 'ti.', 'on.', 'to.', 'fr.', 'lø.', 'sø.'][weekday - 1];
            case 'ca':
                return ['dl.', 'dt.', 'dc.', 'dj.', 'dv.', 'ds.', 'dg.'][weekday - 1];
            case 'hr':
                return ['po', 'ut', 'sr', 'če', 'pe', 'su', 'ne'][weekday - 1];

            default:
                return ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'][weekday - 1];
        }
    }

    public static getMonthShortNameByLanguage(month) {
        switch (navigator.language) {
            case 'zh-CN':
            case 'zh-SG':
            case 'zh-TW':
            case 'zh':
                return ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'][month - 1];
            case 'fr':
                return ['janv.', 'févr.', 'mars', 'avr.', 'mai', 'juin', 'juil.', 'août', 'sept.', 'oct.', 'nov.', 'déc.'][month - 1];
            case 'pt':
                return ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'][month - 1];
            case 'bg':
                return ['яну', 'фев', 'мар', 'апр', 'май', 'юни', 'юли', 'авг', 'сеп', 'окт', 'ное', 'дек'][month - 1];
            case 'cs':
                return ['Led', 'Led', 'Bře', 'Dub', 'Kvě', 'Čer', 'Čer', 'Srp', 'Zář', 'Říj', 'Lis', 'Pro'][month - 1];
            case 'da':
                return ['jan', 'feb', 'mar', 'apr', 'maj', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec'][month - 1];
            case 'de':
                return ['jan', 'feb', 'mär', 'apr', 'mai', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dez'][month - 1];
            case 'es':
                return ['ene.', 'feb.', 'mar.', 'abr.', 'may.', 'jun.', 'jul.', 'ago.', 'sep.', 'oct.', 'nov.', 'dic.'][month - 1];
            case 'ja':
                return ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'][month - 1];
            case 'uk':
                return ['Січ', 'лют', 'бер', 'кві', 'тра', 'чер', 'лип', 'сер', 'вер', 'жов', 'лис', 'гру'][month - 1];
            case 'pl':
                return ['sty', 'lut', 'mar', 'kwi', 'maj', 'cze', 'lip', 'sie', 'wrz', 'paź', 'lis', 'gru'][month - 1];
            case 'lv':
                return ['janv.', 'febr.', 'marts', 'apr.', 'maijs', 'jūn.', 'jūl.', 'aug.', 'sept.', 'okt.', 'nov.', 'de'][month - 1];
            case 'nb':
                return ['jan', 'feb', 'mar', 'apr', 'mai', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'des'][month - 1];
            case 'ca':
                return ['gen.', 'febr.', 'març', 'abr.', 'maig', 'juny', 'jul.', 'ag.', 'set.', 'oct.', 'nov.', 'des.'][month - 1];
            case 'hr':
                return ['sij', 'vlj', 'ožu', 'tra', 'svi', 'lip', 'srp', 'kol', 'ruj', 'lis', 'stu', 'pro'][month - 1];

            default:
                return ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][month - 1];
        }
    }
}

