import { Component, EventEmitter, Input, Output, OnInit, ViewChild, ElementRef, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';
import DropdownItem from '../../../shared/models/controls/dropdown/dropdown-item';
import { LogService } from '../../../shared/services/log/log.service';
import { ExpenseSplitOptions } from '../../../myte-expenses/constants/expense-split-options'
import moment from 'moment';
import { NavigatorBrowserUtils } from '../../../shared/utils/navigatorBrowser.utils';
import { NumberFormatUtils } from '../../../shared/utils/numberFormater.utils';
import { FocusService } from '../../../shared/services/focus/focus.service';
import { DateformatPipe } from '@shared/pipes/dateformat.pipe';
import { FormatDateType } from '@shared/utils/constants';

@Component({
  selector: 'myte-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.sass']
})
export class DropdownComponent implements OnInit, OnChanges {
  @Input() public elementsList: DropdownItem[] = [];
  @Input() public readOnly: boolean;
  @Input() public isValid: boolean;
  @Input() public value: string;
  @Input() public showCurrency: string;
  @Input() public showWbs: boolean;
  @Input() public placeholder: string;
  @Input() public height = 32;
  @Input() public width: any;
  @Input() public heightItems = 28;
  @Input() public minHeight = 28;
  @Input() public heightContent = 265;
  @Input() public isSearchable: boolean;
  @Input() public isAdjustment: boolean;
  @Input() public ariaLabel: string;
  @Input() public dropdownId: string;
  @Input() isSelectedOptionHighlight: boolean = false;
  @Input() isMultipleSelect: boolean = false;

  //this property is for story 1046789 display needed
  @Input() public isApproveHold: boolean;

  @Output() updateValueEvent: EventEmitter<any> = new EventEmitter();
  @Output() openDropdownEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() selectedItem: DropdownItem;
  @Input() isLoading: boolean;
  @Input() public precision: string = null;
  @Input() public focusDropdown : boolean = false;

  @Input() public hasnumbervalue: boolean = false;

  @Input() public hasdatevalue: boolean = false;
  @Input() public isFilter: boolean = false;
  @Input() label: string;
  @Input() required: boolean = false;
  @Input() idToLinkLabelAndInput: string;
  @Input() feedbackSrUsers: boolean;
  @Input() isExpenseEX08_EX09: boolean;
  @Input() fieldKey:string;
  @Input() isActivityTab: boolean = false;
  @Input() isActivityDropdown: boolean = false;
  @Input() selectedMultipleItem: Date[];

  public currency: string = null;
  public isDropdownOptionsOpen = false;
  public showSearch: boolean;
  public searchValue = undefined;
  public filteredListCount;
  public expenseLabel = 'Press Enter and select an option';
  public expenseText: string = null;

  @ViewChild('select', { static: true }) selectField: ElementRef;
  @ViewChild('search', { static: true }) searchField: ElementRef;
  @ViewChild('item', { static: true }) itemField: ElementRef;
  @ViewChild('selectContent') selectContentField: ElementRef;

  private defaultItem: DropdownItem;
  private emptyItem = new DropdownItem(null, ' - ');

  constructor(private logService: LogService,
    private focusService: FocusService) { }

  ngOnInit() {
    if (this.placeholder == null) {
      this.defaultItem = new DropdownItem('', '-- Select one --');
    } else {
      this.defaultItem = new DropdownItem('', this.placeholder);
    }
    this.focusService.searchSubject.subscribe(val => {
      if (val == this.fieldKey && this.isExpenseEX08_EX09) {
        this.defaultItem = new DropdownItem('', '');
      }
    })

    this.showSearch = this.isSearchable === undefined
      ? this.elementsList.length > 15
      : this.isSearchable;
    this.feedbackSrUsers = this.feedbackSrUsers ?? this.showSearch;
      if(this.ariaLabel == this.expenseLabel)
      this.expenseText = ' add expense';
    this.updateSelectedOption();
    if(!this.dropdownId)
      this.dropdownId= this.getRandomArbitrary(0, 1000000).toString();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if(this.focusService.getSavedElementId() === this.dropdownId)
        this.focusService.focusSavedElementId();
    }, 0);
  }

  public parseNumberToLocalString(value:string) : string
  {
      let fraction = this.precision.split('-');
      return NumberFormatUtils.formatNumberToLocaleString(Number(value), Number(fraction[0]), Number(fraction[1]));
  }

  updateSelectedOption() {
    if (this.value && this.value != '') {
      let element = this.elementsList.find(e => e.key == this.value);
      if (element) {
        this.selectedItem = element;
        this.currency = this.showCurrency && this.selectedItem ? String(this.selectedItem.key).substring(3, 6) : this.selectedItem.key;
      } else {
        this.selectedItem = this.defaultItem;
        this.logService.logWarning('Dropdown value: ' + this.value + ' is not in the elementList array.\n' +
          'The selected item will be the default item.');
      }
    } else {
      this.selectedItem = this.selectedItem ? this.selectedItem : this.defaultItem;
    }
    if(this.hasnumbervalue && this.value && this.value!="" && this.selectedItem.key!="")
      this.itemField.nativeElement.innerHTML=this.parseNumberToLocalString(this.value);
    else if(this.hasdatevalue && this.selectedItem && this.selectedItem.key!="")
      this.itemField.nativeElement.innerHTML=this.transformToLocalizeDate(this.selectedItem);
  }

  public transformToLocalizeDate(dropdownItem: DropdownItem) {
    if(dropdownItem.key === 'select')
      return dropdownItem.value;
    return new DateformatPipe().transform(dropdownItem.key, FormatDateType.FormatDate);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.value?.currentValue === ExpenseSplitOptions.PercentageAsKey ||
        changes?.value?.currentValue === ExpenseSplitOptions.AmountAsKey) {
          this.updateSelectedOption();
    }
    if(changes?.selectedItem?.currentValue !== changes?.selectedItem?.previousValue){ 
      this.updateSelectedOption(); 
    }
  }

  public selectItem(item: DropdownItem) {
    this.selectField.nativeElement.classList.remove('focus');
    this.selectedItem = item;
    this.currency = this.showCurrency ? String(this.selectedItem.key).substring(3, 6) : this.selectedItem.key;
    this.updateValueEvent.emit(item.key);
    this.selectField.nativeElement.focus();
  }

  public restoreDefaulItem() {
    this.selectedItem = this.defaultItem;
  }

  public showOptions(event?) {
    this.openDropdownEvent.emit(true);
    //this login for story 1046789 display needed
    if(this.isApproveHold)
      this.itemField.nativeElement.classList.remove('active');

    if (this.selectContentField == undefined || null ) {
      this.isDropdownOptionsOpen = false;
      return;
    }
    this.isDropdownOptionsOpen = true;
    this.selectField.nativeElement.classList.add('focus');

    // Display selected option on the top
    let selected = this.selectContentField.nativeElement.querySelectorAll('.selected');
    if (selected.length === 0)
      this.selectContentField.nativeElement.scrollTop = 0;
    else {
      let index = 0;
      for (let element of this.selectContentField.nativeElement.children) {
        if (element === selected[0])
          break;
        index++;
      }
      this.selectContentField.nativeElement.scrollTop = this.selectContentField.nativeElement.children[index].offsetTop;
    }

    this.showSearchInput();
    this.disableWindowScrolling();
    if (event)
      event.stopPropagation();
  }

  public hideOptions(event?) {
    if (!this.isDropdownOptionsOpen)
      return;
    this.openDropdownEvent.emit(false);

    //this login for story 1046789 display needed
    if(this.isApproveHold)
      this.itemField.nativeElement.classList.add('active');

    this.isDropdownOptionsOpen = false;
    this.hideSearchInput();
    this.searchValue = undefined;
    this.selectField.nativeElement.classList.remove('focus');
    this.enableWindowScrolling();
    if (event)
      event.stopPropagation();
  }

  public calculateSelectArrow(): string {
    return (this.height * 0.4375) + 'px';
  }

  public onFocusSelect() {
    this.filteredListCount = undefined;
    this.selectField.nativeElement.classList.add('focus');
    this.selectField.nativeElement.focus();
  }

  public onBlurSelect() {
    if (!this.showSearch || !this.isDropdownOptionsOpen) {
      this.selectField.nativeElement.classList.remove('focus');
    }
  }

  public onBlurSearch() {
    this.selectField.nativeElement.classList.remove('focus');
    if (this.searchValue && this.isExpenseEX08_EX09 && !parseInt(this.filteredListCount)) {
      this.updateValueEvent.emit('');
      this.focusService.searchSubject.next(this.fieldKey);
    }
  }

  public update(event: any) {
    this.searchValue = event;
    if (this.feedbackSrUsers === true) {
      this.filteredListCount = this.elementsList.filter(e=> e.isValid && e.value?.toLowerCase().includes(event.toLowerCase())).length.toString();
    }
  }

  public onKeySelect(event?) {
    if (!event)
      return;
    switch (event.key) {
      case 'Enter': {
        if (!this.isDropdownOptionsOpen) {
          this.showOptions();
          break;
        }
        let selected = this.selectContentField.nativeElement.querySelectorAll('.selected');
        if (selected.length === 0)
          this.hideOptions();
        else
          selected[0].click();
        break;
      }
      case 'Esc':
      case 'Escape':
        event.stopPropagation();
      case 'Tab': {
        this.hideOptions();
        break;
      }
      case 'ArrowUp':
      case 'Up': {
        if (event.altKey){
          this.hideOptions();
          break;
        }
        let selected = this.selectContentField.nativeElement.querySelectorAll('.selected');
        if ((selected.length === 0) || (selected[0] === this.selectContentField.nativeElement.firstElementChild)) {
          this.selectContentField.nativeElement.lastElementChild.classList.add('selected');
          this.selectContentField.nativeElement.lastElementChild.focus();
          this.selectContentField.nativeElement.scrollTop = this.selectContentField.nativeElement.lastElementChild.offsetTop;
        } else {
          let index = 0;
          for (let element of this.selectContentField.nativeElement.children) {
            if (element === selected[0])
              break;
            index++;
          }
          this.selectContentField.nativeElement.children[index - 1].classList.add('selected');
          if (this.selectContentField.nativeElement.scrollTop > this.selectContentField.nativeElement.children[index - 1].offsetTop)
            this.selectContentField.nativeElement.scrollTop = this.selectContentField.nativeElement.children[index - 1].offsetTop;

            this.selectContentField.nativeElement.children[index - 1].focus();
        }
        if (selected.length !== 0)
          selected[0].classList.remove('selected');
        break;
      }
      case 'ArrowDown':
      case 'Down': {
        let selected = this.selectContentField.nativeElement.querySelectorAll('.selected');
        if ((selected.length === 0) || (selected[0] === this.selectContentField.nativeElement.lastElementChild)) {
          this.selectContentField.nativeElement.firstElementChild.classList.add('selected');
          this.selectContentField.nativeElement.scrollTop = this.selectContentField.nativeElement.firstElementChild.offsetTop;
          this.selectContentField.nativeElement.firstElementChild.focus();
        } else {
          let index = 0;
          for (let element of this.selectContentField.nativeElement.children) {
            if (element === selected[0])
              break;
            index++;
          }
          this.selectContentField.nativeElement.children[index + 1].classList.add('selected');
          if ((this.selectContentField.nativeElement.scrollTop + this.selectContentField.nativeElement.offsetHeight)
            < (this.selectContentField.nativeElement.children[index + 1].offsetTop + this.selectContentField.nativeElement.children[index + 1].offsetHeight))
           { this.selectContentField.nativeElement.scrollTop = this.selectContentField.nativeElement.children[index + 1].offsetTop
              - this.selectContentField.nativeElement.offsetHeight + this.selectContentField.nativeElement.children[index + 1].offsetHeight;
           }


          this.selectContentField.nativeElement.children[index + 1].focus();
        }
        if (selected.length !== 0)
          selected[0].classList.remove('selected');
        break;
      }
      default:
        break;
    }
  }

  public disableWindowScrolling() {
    document.onkeydown = this.preventDefaultForScrollKeys;
  }

  public enableWindowScrolling() {
    document.onkeydown = null;
  }

  public preventDefaultForScrollKeys(e) {
    let keys = { 37: 1, 38: 1, 39: 1, 40: 1 };
    if (keys[e.keyCode]) {
      e.stopPropagation();
      return false;
    }
  }

  public resetSelection() {
    this.selectedItem = this.defaultItem;
  }

  public setEmptyList() {
    this.selectedItem = this.emptyItem;
  }

  public setDefaultItem() {
    this.defaultItem = new DropdownItem(3, 'Client Location',false,true);
    this.selectedItem = this.defaultItem;
  }
  

  public resetEmptySelection(){
    let emptyItem = new DropdownItem(null, null);
    this.selectedItem = emptyItem;
  }

  private showSearchInput() {
    if (this.openDropdownUpwards())
      this.selectContentField.nativeElement.classList.add('drop-up');
    else
      this.selectContentField.nativeElement.classList.remove('drop-up');
    if (!this.showSearch)
      return;
    this.searchField.nativeElement.classList.add('active');
    this.itemField.nativeElement.classList.remove('active');
    this.searchField.nativeElement.lastChild.focus();
  }

  private hideSearchInput() {
    if (!this.showSearch)
      return;
    this.searchField.nativeElement.classList.remove('active');
    this.itemField.nativeElement.classList.add('active');
  }

  private openDropdownUpwards() {
    let domRect = this.selectField.nativeElement.getBoundingClientRect();
    let spaceBelow = window.innerHeight - domRect.bottom - this.selectContentField.nativeElement.offsetHeight;
    let spaceAbove = domRect.top - this.selectContentField.nativeElement.offsetHeight;
    return this.isActivityTab ? (window.innerHeight < 482 ? spaceBelow <= 75 && (window.innerHeight - spaceAbove) < 118 : spaceBelow < 100) : spaceBelow <= 0 && spaceAbove > 0;
  }

  private getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min;
  }

  //highlights the multiple selected options in dropdown
  public handleSelectedItem(item: DropdownItem){
    return this.isMultipleSelect && this.selectedMultipleItem.findIndex(x => x.getTime() == new Date(item.value).getTime()) != -1;
  }
}
