import { MyteEvent } from '../myte-event';

export class ChargeCodeDismisedEvent extends MyteEvent<boolean> {
    public static eventId = "chargeCodeDismised";
    constructor() { super(ChargeCodeDismisedEvent.eventId); }
}

export class ShowRelatedChargeCodesEvent extends MyteEvent<string> {
    public static eventId ="showRelatedChargeCodes";
    constructor() { super(ShowRelatedChargeCodesEvent.eventId); }
}