import { SafeUrl } from '@angular/platform-browser';

export class PeoplePicker {
  peopleKey: number;
  enterpriseId: string;
  lastName: string;
  firstName: string;
  xWorkforceCareerLevelDescription: string;
  profilePicture: ProfilePicture;
  isValid: boolean;

  constructor(peopleKey?: number, enterpriseId?: string, lastName?: string, firstName?: string,
    xWorkforceCareerLevelDescription?: string, isValid?: boolean, profilePicture?: string) {
    this.enterpriseId = enterpriseId;
    this.peopleKey = peopleKey;
    this.lastName = lastName;
    this.firstName = firstName;
    this.xWorkforceCareerLevelDescription = xWorkforceCareerLevelDescription;
    this.isValid = isValid;
  }

}

export class ProfilePicture {
  public peopleKey: number | null;
  public enterpriseId: string | null;
  public data: SafeUrl | null;

  constructor(peopleKey?: number, enterpriseId?: string, data?: SafeUrl) {
    this.peopleKey = peopleKey;
    this.enterpriseId = enterpriseId;
    this.data = data;
  }
}

export const defaultProfilePicture = {
  data: `iVBORw0KGgoAAAANSUhEUgAAAJYAAACWCAIAAACzY+a1AAAABGdBTUEAALGPC/xhBQAAAAlwSFlzAAAOwgA
        ADsIBFShKgAAAABh0RVh0U29mdHdhcmUAcGFpbnQubmV0IDQuMS41ZEdYUgAABn5JREFUeF7tnaFy4zAQhu9
        VD5WFhoWahoaGB/cBws1DCsMzhZ1O75/zTidTN7Jky/vvKvuBm+tdakv6vNJKsp0/X4FzQqF7QqF7QqF7QqF
        7QqF7QqF7QqF7QqF7QqF7QqF7QqF7QqF7QqF7QqF7WlN4u936/5xOp+Md+HH4d3xAPtoKLSiEGEja7XYvLy9
        /M8DH8GH8Cn5RDuEZxwpfX1+7rhMtC8BBcCg5qEP8Kbxer4fDITPg8sEBcVgcXE7jB08K0b77/V6afDVwCl8
        ifShEDoIQkTZWAafzkvg4UHg+n6t3mzngpDi1FMIwphUiDqokLEtAAYyHo12Fl8tls9lIQ1JBMVAYKZY9jCp
        kdZ6PsNypWlSIWZq0nDFsTh/NKTTrb8CgRVsKjfsbsGbRkMK+76WRzGMqu7Gi8Hq9mspf0qCodlZwrCjcbrf
        SPE5AgaXobEwoPB6P0jCuQLGlAlT4Ch0NgWMsDIp8he660HssdKdkhS5mEWnocwymwtvt5igLfQSqwF0HZyo
        8nU7SDM5BRaRKDJgKjWxELAcVkSoxoClsYBS8hzgi0hTS93LrgupIxdThKMT4L1VvCFZSw1HYWC86wOpLOQo
        b60UHWH0pR2ED08ExqJRUTxeCwsvlIpVuDsqSKUFhMzP6MZQ5PkGh8n3ZmqBqUklFCAp3u53UuDlQNamkIgS
        FrneX0lD2nggKpbqNIpVUJBRWRiqpSCisjFRSkVBYGamkIqGwMlJJRUJhZaSSioTCykglFSEobOZ+izGUOzA
        ICmN1pi4EhQovHmHxLGuksVNRF4JC1w9RpKG81I2gEEiNm0OqpwtHYZMZDSWXARyFTQ6HlIEQcBRer1epd0O
        wHt3mKASNbfwSHzSkKYxnKmpBU/j+/t7M3aTcRwxpCoHTtySM4b43gabw8/OzjUDkhiBgRiFoIBDpry5hKhw
        C0fXeEwrPDUFAjkJwPp+lPRxi4SWlZIUIRPzp9Fk14pO99/CjEHjsTi10oQMmFAJ3T6zZeZ+lCYVDd+povYa
        4FjPGShQOuJhj0GcRP7ClEBi/swbFk4KawZxCYNaiQX/AokJgcFw0Nf7dY1QhsPNtIyiG2e8ZAXYVgre3N/r
        OMApg/LvwTCscIKap1pLPXzGt8OPjY/gL5tHKN73hdHYm72kcROE3fd8rrMPhFJQ7emfjSeEA2neliMRhfck
        b8KdwACnG4XCoEpQ4CA5lPGdJ4FXhNxixTqdT13VFMxB8GL+CX/Qy4CVwr/Ce2+2GnhBzcGSSALGFvhHgL8O
        /4L/wASObRLVoSuFzYk4hQgThgvFpv9+bCheEL7pfxLS1lRpDCpFQ/FjgtpPf46qSMv0HBbOzZGpC4VjePRj
        JiOGIa+jRIp8RkWSFQ7cpTfIYSmOlL6xvUDZu18pUCCtFMwE1kcOFVVQ2jJGsmSVH4ZI1T4jEfG6lrhUFy4m
        8R0C8HEgRgsKcnjMHtDV6sCouEUC4LKpsbOEgyssFqgrRUmvs/yGgcVmU5q4oDK6AWqt0P9AMRz2FaK+i0WU
        e8HG/HANwXoyg8sPx2HXd7D68CJxFJ5FWUog2lZo9E7hkFea1qyvElahz1Ztl7Sx6XYUY2NcY/NyBzEtaZAV
        WVAh/CoOfF9azuJbC8DcGHdIaCc4qCsPfI9awWF9h+EtT3WJlheEvh7qPB9dUGP7yqZjdVFOIziHmD0UcKy3
        CVVMY/mZQZdZfR+GSDZpnBuMORh9pxLlUUIhLSUoUlLNZ/OaMpQojhVnOwgR1qcIYAquw5KXQixQip5IiBMt
        ATzb71pv5CtGFyvmDGuzmvmB/vsLoQqszb44xUyH6bjltUA90pzOy0zkKcZrIQlfiUP7FXXMUPueNMGqU5jX
        FCnECOVWwDqV5TbHCWEtToOi+tzKFEYI6FAVimcIIQTXyA7FAYYSgJvl7wgUKYzlNmczUtEBhzAWVydzWz1U
        Ym4L6bPK+JT9XYefzqyS8k/MIeJbCSGRY5CQ1WQpjUZvI5MJ3lsLYVyIyuQM1rRBXgRwsYDDZl04rjFyUC+Z
        yYuIB0wpjUY1O+l7TaYVrvBAiKCI9x59QGNMJC6Q3LiYUxkBogfRwOKEw7rEwQmI4nFD45O8bsUNipW1CoRw
        gYJPIaFIKI5exQyKjSSns+14OELDZbrdiZURKYaxum0KsjEgpjDstTCFWRqQUxozCFI/uaUspjBmFKUKhe0K
        he35/mPvr6x8bWrLAOoMBjgAAAABJRU5ErkJggg==`
};
