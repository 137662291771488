import { HomeOfficeLocationList } from './homeOfficeLocationList';
import { InOutForShow } from './inOutForShow';
import { PersonalCommuteList } from './personalCommuteList';

export default class UserPreference {
  public reviewer: InOutForShow;
  public approver: InOutForShow;
  public delegate: InOutForShow;
  public submission: InOutForShow;
  public backupApprovers: InOutForShow;
  public overtimeApprovers: InOutForShow;
  public allowanceApprover: InOutForShow;
  public workshiftApprover: InOutForShow;
  public onCallAndSWTimeApprover: InOutForShow;
  public isApprovalRequested: boolean;
  public isBackupApprover: boolean;
  public homeOfficeLocation: HomeOfficeLocationList;
  public isAuditDelegateRequested: boolean;
  public personalCommuteData: PersonalCommuteList[];
  public compensatoryTabIndicator: boolean;
  public locationQuestionnaireTabIndicator: boolean;
  public uberAcceptanceIndicator: boolean;
  public lyftAcceptanceIndicator: boolean;
  public isAvanadeUser: boolean;
  public countryKey: string;
  public isCarPlan: boolean;
  public timeReportStatus: string;
  public get hasApprovers(): boolean {
    return this.approver != null && this.approver.value != null && this.approver.value.length > 0;
  }
}
