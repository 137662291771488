import { ExpenseViewFieldRow } from './expense-view-field-row';
import ExpenseViewRow from './expense-view-row';
import { Validation } from '@sharedClients/expense-api-client';
import AmexInput from './amex/expense-amex-import';
import { ExpenseViewField } from './field/expense-view-field';
import ReceiptViewField from './field/expense-receipt-view-field';
import MealsClaimedField from './field/expense-meals-claimed-field';
import GiftField from './field/expense-gift-field';
import ExpenseViewRowElement from './field/expense-view-row-element';
import { ExpenseViewSource } from './expense-view-source';
import { ExpenseViewSoftWarning } from './expense-view-soft-warning';
import MyteApiMessage from '@sharedModels/myte-api-message';
import CorporateCardExpenseInput from './amex/expense-corporate-card-import';

export default class ExpenseView {
    public id?: string;
    public code: string;
    public title: string;
    public periodEnd: Date;
    public countryDecimalPlaces: string;
    public categories: Array<ExpenseViewRow[]> = [];
    public expenseSource: ExpenseViewSource;
    public validations: Validation[] = [];
    public errors: string[] = [];
    public softWarning: ExpenseViewSoftWarning;
    public amexInfo?: AmexInput;
    public importInfo?: CorporateCardExpenseInput;
    public isReversed?: boolean;
    public messages: MyteApiMessage[];
    public timeReportStatus?: string;
    public currencyDecimal: number;
    public isSalarySupplement?: boolean;
    public receiptRequirementCode?: number;
    public receiptTypes: string[] = [];

    constructor(id?: string, code?: string, title?: string, periodEnd?: Date, decimalPlaces?:string, categories?: Array<ExpenseViewFieldRow[]>,
                source?: ExpenseViewSource, amexInfo?: AmexInput, importInfo?: CorporateCardExpenseInput, softWarning?: ExpenseViewSoftWarning, 
                IsReversed?: boolean, currencyDecimal?: number, isSalarySupplement?: boolean, receiptRequirementCode?: number) {
        this.id = id;
        this.code = code;
        this.title = title;
        this.periodEnd = periodEnd;
        this.countryDecimalPlaces=decimalPlaces;
        this.categories = categories;
        this.expenseSource = source ? source : ExpenseViewSource.Web;
        this.softWarning = softWarning;
        this.amexInfo = amexInfo;
        this.importInfo = importInfo;
        this.isReversed = this.isReversed;
        this.currencyDecimal = currencyDecimal;
        this.isSalarySupplement = isSalarySupplement;
        this.receiptRequirementCode = receiptRequirementCode;
    }

    public isNewExpense(): boolean {
        return this.id == '' || this.id == null;
    }

    public validateExpense(validateRequiredFields: boolean) {
        if (Array.isArray(this.validations) && this.validations.length > 0) {
            this.validations.forEach(validation => {
              if (validation.type != 'NonBlockError')
                this.errors.push(validation.value);
            });
        }
        if (validateRequiredFields) {
            this.categories.forEach(category =>
                category.forEach(row =>
                    row.elements.forEach(f => {
                        f.validateFieldErrors();
                    })
                )
            );
        }
    }


    public hasValidationsErrors(): boolean {
        let hasFieldErrors = false;

        this.categories.forEach(category =>
            category.forEach(row =>
                row.elements.forEach(f => {
                    if (!f.isValid())
                        hasFieldErrors = true;
                })
            )
        );

        if (hasFieldErrors || this.errors.length > 0)
            return true;

        return false;
    }

    public getFields(): ExpenseViewField[] {
        return this.getAllElements()
            .filter(expenseViewRow => expenseViewRow instanceof ExpenseViewField) as ExpenseViewField[];
    }

    public getReceiptField(): ReceiptViewField {
        return this.getAllElements()
            .find(expenseViewRow => expenseViewRow instanceof ReceiptViewField) as ReceiptViewField;
    }

    public getMealsClimedField(): MealsClaimedField {
        return this.getAllElements()
            .find(expenseViewRow => expenseViewRow instanceof MealsClaimedField) as MealsClaimedField;
    }

    public getGiftField(): GiftField {
        return this.getAllElements()
            .find(expenseViewRow => expenseViewRow instanceof GiftField) as GiftField;
    }

    public addField(element: ExpenseViewRowElement, categotyCode: number, row: number): void {
        this.initializeCategories(categotyCode, row);
        this.categories[categotyCode][row].elements.push(element);
    }

    private getAllElements(): ExpenseViewRowElement[] {
        return this.categories.map(expenseRow => expenseRow)
            .reduce((prev, curr) => prev.concat(curr))
            .map(expenseViewRow => expenseViewRow.elements)
            .reduce((prev, curr) => prev.concat(curr));
    }

    private initializeCategories(categotyCode: number, row: number): void {
        if (!this.categories[categotyCode])
            this.categories[categotyCode] = new Array<ExpenseViewFieldRow>();
        if (!this.categories[categotyCode][row])
            this.categories[categotyCode][row] = new ExpenseViewFieldRow();
        if (!this.categories[categotyCode][row].elements)
            this.categories[categotyCode][row].elements = new Array<ExpenseViewField>();
    }
}
