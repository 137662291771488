import { Component, ElementRef, HostListener, OnDestroy, OnInit, Optional, ViewChild } from '@angular/core';
import { AuthService } from '../../../auth/services/auth.service';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { MatIconRegistry } from '@angular/material/icon';
import { Title } from '@angular/platform-browser';
import jsonFile from '../../../../../package.json';
import { Router, NavigationStart, NavigationEnd, Event, NavigationCancel, NavigationError, ActivatedRoute, RouterEvent, NavigationSkipped } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { ReceiptRequirementIcons } from '../../../../assets/icons/receipt-requirement/receipt-requirement-icons';
import { DomSanitizer } from '@angular/platform-browser';
import { GlobalEventsService } from '../../../shared/services/events/global-events.service';
import { Subject, fromEvent, merge } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { InteractionStatus, SilentRequest } from '@azure/msal-browser';
import { GlobalCacheService } from '../../../shared/services/cache/global-cache.service';
import { InteractionRequiredAuthError } from '@azure/msal-common';
import { AppConfigService } from '@authServices/app-config.service';
import { SessionManagementService } from '@shared/services/sessionManagement/session-management.service';

declare var $: any;


@Component({
  selector: 'myte-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit, OnDestroy {
  public isGlobalSpinnerVisible = true;
  public isGlobalSpinnerVisibleStore = false;
  private title = 'MyTimeAndExpenses v' + jsonFile.version;
  private readonly _destroying$ = new Subject<void>();
  private appConfigData: any;
  private showIdlePopup: boolean = false;
  @ViewChild('loadingStatusFinish') loadingStatusFinish: ElementRef;
  public isIframe: boolean = false;
  public loggedIn: boolean = false;
  public isLocal: boolean = false;


  constructor(
    private globalEventsService: GlobalEventsService,
    private matIconRegistry: MatIconRegistry,
    private router: Router,
    private domSanitizer: DomSanitizer,
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private cacheService: GlobalCacheService,
    @Optional() private authService: AuthService,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private appConfigService: AppConfigService,
    private sessionManagementService: SessionManagementService) {
  }

  public ngOnInit(): void {
    if (environment.name == 'Local') {
      this.isLocal = !this.isLocal;
    }
    this.isIframe = window !== window.parent && !window.opener;
    this.loggedIn = this.msalService.instance.getAllAccounts().length > 0;
    if (!this.isIframe) {
      this.initializeAuthentication();
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
      ).subscribe(() => {
        const rt = this.getChild(this.router.routerState.root);
        rt.data.subscribe(data => {
          this.setTitle(data.title + ' - ' + this.title)
        });
      });
      this.disableDefaultDragAndDrop();
      this.initializeIcons();
      this.initializeEvents();
      this.initializeLocalEnviroment(); 

      const click = fromEvent(document, 'click');
      const keydown = fromEvent(document, 'keydown');
      merge(click,keydown).
        pipe(takeUntil(this._destroying$)).
        subscribe(() => {
          if(!this.showIdlePopup) {
          this.sessionManagementService.resetLogOutTimer();
          }          
        });
      
      this.sessionManagementService.initLogOutTimer();
      this.sessionManagementService.warningPopUpEvent.      
        pipe(takeUntil(this._destroying$)).
        subscribe((value:boolean)=>{
        this.showIdlePopup = value;
      })
       
    }
    this.setCurrentTheme();
  }

  public getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }

  public Show(): void {
    document.getElementById("myDropdown").style.display = "block";
  }

  public ngOnDestroy() {
    this._destroying$.next(null);
    this._destroying$.complete();
  }

  private initializeAuthentication(): void {
    if (environment.production) {
      this.msalBroadcastService.inProgress$.pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      ).subscribe(() => {
        this.loggedIn = this.msalService.instance.getAllAccounts().length > 0;
        this.checkAndSetActiveAccount();
        this.getTokenRedirect();
      });
    } else {
      this.authService.setup();
      if ((environment.name != 'Local' && !environment.production) || this.authService.IsUserAuthenticated()) {
        this.loggedIn = true;

        const item = sessionStorage.getItem('route');
        if (item != undefined && item !== 'undefined' && item != null && item != '') {
          if (window.location.href === item) {
            sessionStorage.setItem('route', '');
          } else if (window.location.href.includes('id_token')) {
            window.location.href = item;
          }
        }
      }
    }
  }

  private checkAndSetActiveAccount(): void {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     */
    let activeAccount = this.msalService.instance.getActiveAccount();
    if (!activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
      let accounts = this.msalService.instance.getAllAccounts();
      this.msalService.instance.setActiveAccount(accounts[0]);
    }
  }

  private setTitle(newTitle: string): void {
    this.titleService.setTitle(newTitle);
  }


  private setCurrentTheme(): void {
    let theme = this.cacheService.getTheme();
    const body = document.getElementsByTagName('body')[0];

    if (theme)
      body.className = theme;
  }

  private disableDefaultDragAndDrop(): void {
    window.addEventListener('dragover', function (e: any) {
      e = e || event;
      e.preventDefault();
    }, false);
    window.addEventListener('drop', function (e: any) {
      e = e || event;
      e.preventDefault();
    }, false);
  }

  private initializeEvents(): void {
    this.router.events.subscribe((routerEvent: Event | RouterEvent) => {
      if (routerEvent instanceof NavigationStart) {
        this.isGlobalSpinnerVisible = true;
      }
      if (
        routerEvent instanceof NavigationEnd ||
        routerEvent instanceof NavigationCancel ||
        routerEvent instanceof NavigationError ||
        routerEvent instanceof NavigationSkipped) {
        if (this.isTabWithCache(routerEvent.url)) {
          setTimeout(() => {
            this.isGlobalSpinnerVisible = false;
            this.showLoadingSuccessAlert(false);
          }, 100);
        } else {
          this.isGlobalSpinnerVisible = false;
          this.showLoadingSuccessAlert(false);
        }
        const uh = document.getElementById('acn-universal-header');
        if (this.isAmexTool(routerEvent.url))
          uh.style.display = 'none';
        else
          uh.style.display = 'block';
      }
    }
    );

    this.globalEventsService.selectShowGlobalSpinnerAutoSaveEvent()
      .subscribe(show => {
        this.isGlobalSpinnerVisibleStore = show
      });

      this.globalEventsService.selectShowGlobalSpinnerEvent()
      .subscribe(show => {
        this.isGlobalSpinnerVisible = show;
        this.showLoadingSuccessAlert(show);
      });


    this.globalEventsService.selectUpateshowSpinnerForErrorHandler()
      .subscribe(show => {
        this.isGlobalSpinnerVisible = show;
        this.showLoadingSuccessAlert(show);
      });
  }

  private showLoadingSuccessAlert(status) {
    if (status) return;
    this.loadingStatusFinish?.nativeElement?.setAttribute("aria-hidden", "false");
    this.loadingStatusFinish?.nativeElement?.setAttribute("role", "alert");
    setTimeout(() => {
      this.loadingStatusFinish?.nativeElement?.setAttribute("aria-hidden", "true");
      this.loadingStatusFinish?.nativeElement?.setAttribute("role", "none");
    }, 500);
  }

  private isTabWithCache(url: string): Boolean {
    let route = url == undefined ? '' : url.split('?')[0].split('/')[1];
    return route == "preferences" || route == "summary";
  }

  private isAmexTool(url: string): Boolean {
    let route = url == undefined ? '' : url.split('?')[0].split('/')[1];
    return route == "amextool";
  }

  private initializeIcons(): void {
    this.matIconRegistry.registerFontClassAlias('fa');

    ReceiptRequirementIcons.forEach(icon =>
      this.matIconRegistry.addSvgIcon(
        icon.name,
        this.domSanitizer.bypassSecurityTrustResourceUrl('../../../..'.concat(icon.url))
      )
    );
  }

  private initializeLocalEnviroment(): void {
    if (environment.name == 'Local' || environment.name == 'Mock') {
      // NOTE Load Fake Universal Header only in Local
      const uh = document.getElementById('acn-universal-header');
      uh.classList.add('acn-header-main');
      uh.classList.add('acn-header-main-gray');
      $("#acn-universal-header").load('../../../../assets/data/uh.html');
    };
  }

  private getTokenRedirect(): void {
    let activeAccount = this.msalService.instance.getActiveAccount();
    if (!activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
      let accounts = this.msalService.instance.getAllAccounts();
      const appConfigMSAL: any = this.appConfigService.getAppConfig.AADv2Eso;
      const msalInstanceFactory: [{ url: string; scopes: string[]; usage: string; }] = appConfigMSAL.protectedResourceMap;
      const requestManual: SilentRequest = {
        scopes: msalInstanceFactory.find(element => element.usage == "next").scopes,
        account: accounts[0]
      };
      this.msalService.instance.acquireTokenSilent(requestManual).then(result => sessionStorage.setItem('ddrumsdk.idtoken', result.idToken))
      .catch((error: any) => {
        if (error instanceof InteractionRequiredAuthError) {
          // fallback to interaction when silent call fails
          console.log('acquiring token using redirect');
          this.msalService.acquireTokenRedirect(requestManual);
        } else {
          console.error(error);
        }
      });
    }
 }

 private closeIdlePopup() {
  this.showIdlePopup = false;
}

private  timeSessionLeft() {
  return this.sessionManagementService.timeLeft();
}
}
