import { Directive, Output, EventEmitter, ElementRef, HostListener } from '@angular/core';
import { ExpenseGridEventsService } from '../services/events/expense-grid-events.service';

@Directive({
  selector: '[myteSimplifyedUploadReceipt]'
})
/**
 * This Directive implement the behavior defined in the following CR For the 
 * Story 811237 - Digital Receipts - Simplify Upload Receipts Functionality
 * https://accenturecio09.visualstudio.com/myTEWebCode_Project/_workitems/edit/811237
 * https://accenturecio09.visualstudio.com/myTEWebCode_Project/_workitems/edit/835948/
 * 
 * This need to be used only in <input type="file"> elements.
 */
export class SimplifyedUploadReceiptDirective {

  /** Cancel Event is going to be trigged when the user click in the cancel button of the file dialog */
  @Output() cancel = new EventEmitter<void>();
  @Output() filesSelected = new EventEmitter<any>()
  private uploadStarted = false;
  private files: any;
  private checkWindowFocus: boolean;

  constructor(private el: ElementRef, private expenseGridEventService: ExpenseGridEventsService) {
    this.expenseGridEventService.selectUploadReceiptStaredEvent().subscribe(() => this.uploadStarted = true);
    this.expenseGridEventService.selectUploadReceiptFinishedEvent().subscribe(() => this.uploadStarted = false);
  }

  @HostListener('click', ['$event']) public onInputClick(): void {
    this.checkWindowFocus = true;
  }

  @HostListener('change', ['$event']) public onInputChange(event): void {
    this.files = event.target.files;
    if (this.files && this.files.length > 0) {
      this.filesSelected.emit(this.files);
    }
  }

  @HostListener('window:focus') public windowFocus(): void {
    if (!this.checkWindowFocus) return;
    this.expenseGridEventService.dispatchShowLoadingOverlayEvent(true);
    setTimeout(() => {
      if (!this.files && !this.uploadStarted) {
        this.cancel.emit();
        this.checkWindowFocus = false;
      }
    }, 1500);
    this.expenseGridEventService.dispatchShowLoadingOverlayEvent(false);
  }

}
