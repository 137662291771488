import { Component, ViewChild } from '@angular/core';
import DatePickerViewModel from '@shared/models/controls/datepicker/date-picker-viewmodel';
import { ExpenseMultipleTaxiGrid } from '@shared/models/expense/multipleTaxi/expense-multiple-grid';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { DatePickerComponent } from '../../../../../myte-controls/components/date-picker/date-picker.component';

@Component({
  selector: 'myte-taxi-date-render',
  templateUrl: './taxi-date-render.component.html',
  styleUrls: ['./taxi-date-render.component.sass']
})
export class TaxiDateRenderComponent implements ICellRendererAngularComp {
  @ViewChild('fromDate', { static: true }) public fromDate: DatePickerComponent;
  public params: ICellRendererParams<ExpenseMultipleTaxiGrid>;
  public inputData: ExpenseMultipleTaxiGrid;
  public validationExpressions: RegExp[] = [/^[^A-Za-z]+$/];
  
  constructor() { }

  public agInit(params: ICellRendererParams<ExpenseMultipleTaxiGrid>): void {
    this.params = params;
    this.inputData = params.data;
  }

  public updateValue(event: any): void {
    this.inputData.dateFormat.value = event.value == undefined ? event : event.value;
    let selectedFromDate: DatePickerViewModel = this.fromDate.selectedDate as DatePickerViewModel;
    this.inputData.dateFormat.hiddenValue = new Date(selectedFromDate.year, selectedFromDate.month - 1, selectedFromDate.day);
  }

  public refresh(params: ICellRendererParams<ExpenseMultipleTaxiGrid>): boolean {
    let refreshValue = params.value ? params.value : this.inputData.dateFormat;
    this.updateValue(refreshValue);
    return true;
  }
}