import { Injectable } from '@angular/core';
import { LogService } from '@sharedServices/log/log.service';
import { UserService } from '@sharedServices/user/user.service';
import { TimeReportApiClient } from '@sharedClients/timereport-api-client';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { SubordinatesMode } from '@sharedModels/subordinates/subordinate-mode';
import User from '@sharedModels/user/user';
import { map, catchError, takeUntil } from 'rxjs/operators';
import { SubordinatesServiceMapper } from './subordinates.service.mapper';
import Subordinate from '@sharedModels/subordinates/subordinate';
import { GlobalCacheService } from '@sharedServices/cache/global-cache.service';
import { ExpenseApiClient, MyteTabControlOutput } from '@sharedClients/expense-api-client';
import { AppConfigService } from '@authServices/app-config.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TimeReportStatusCode } from '@sharedModels/time-report/time-report-status-code';
import { ActionWithMaticon } from '../../../shared/constant';
import { PeriodEndService } from '../periodend/periodend.service';
import { GlobalEventsService } from '../events/global-events.service';
import { MyteBaseService } from '../myte-base.service';
import { MyteTabControl } from '@shared/models/myte-tab-control.model';
import { AuditlogService } from '../../../myte-popups/shared/services/audit-log.service';

@Injectable({
    providedIn: 'root'
})
export class SubordinatesService extends MyteBaseService {
    //private user: User;
    private appConfigData: any;
    public statusToAddIcon = [
        TimeReportStatusCode.Submitted,
        TimeReportStatusCode.Processed,
        TimeReportStatusCode.DraftAdjustment,
        TimeReportStatusCode.CompletedAdjustment,
        TimeReportStatusCode.ProcessedAdjustment,
        TimeReportStatusCode.SubmittedAdjustment
    ];
    public heightReviwees: number;
    public heightCounselees: number;
    public totalReviewees: number = 0;
    public totalCounselees: number = 0;
    private isAuditBeSubject = new BehaviorSubject(false);
    constructor(public logService: LogService,
        private api: TimeReportApiClient,
        public userService: UserService,
        public globalCacheService: GlobalCacheService,
        private eventService: GlobalEventsService,
        private periodEndService: PeriodEndService,
        private router: Router,
        private appConfigService: AppConfigService,
        private expenseApi: ExpenseApiClient,
        private route: ActivatedRoute,
        public auditlogService: AuditlogService) {
        super(userService, globalCacheService, eventService, logService);
        //this.user = this.userService.getUser();
        this.appConfigData = this.appConfigService.getAppConfig;
    }

    public calculateHeightLists(isReviewee: boolean, hasSubordinateSelected: boolean = false, subordinatesMode: SubordinatesMode, isSearchEnabled: boolean) {
        if ((this.totalCounselees == undefined) || (this.totalCounselees == null))
            this.totalCounselees = 0;
        if ((this.totalReviewees == undefined) || (this.totalReviewees == null))
            this.totalReviewees = 0;

        const generalContainerElement = document.getElementById('generalContainerElement');
        const heightGeneralContainer = generalContainerElement?.offsetHeight > 0 ? generalContainerElement?.offsetHeight : 0;


        const heightCurrentSubordinateSelected = hasSubordinateSelected ? 50 : 0;
        const heightButtons = 90;
        const heightFilterStatus = 38;
        const heightPeoplePicker = isSearchEnabled ? 32 : 0;
        const heightTitle = 36;

        if (subordinatesMode == SubordinatesMode.Approver) {
            const buttons = 175;
            return heightGeneralContainer - buttons - heightFilterStatus - heightCurrentSubordinateSelected - heightTitle - heightPeoplePicker - 15;
        }

        if (subordinatesMode == SubordinatesMode.Delegate) {
            const buttons = 130;
            return heightGeneralContainer - buttons - heightFilterStatus - heightPeoplePicker - heightCurrentSubordinateSelected - heightTitle - 15;
        }

        const halfScreenReviewees = ((heightGeneralContainer - heightCurrentSubordinateSelected - heightButtons - heightFilterStatus - heightPeoplePicker - (heightTitle * 2) - 20) / 2);

        if (this.totalReviewees >= 10 && this.totalCounselees <= 10) {
            const heightListCounselees = this.totalCounselees > 5 ? 150 : this.totalCounselees == 0 ? 0 : (this.totalCounselees * 39);
            const newHeightCounselees = heightListCounselees != 0 ? heightListCounselees + 20 : 36;
            if (isReviewee) {
                this.heightReviwees = (heightGeneralContainer - newHeightCounselees - heightCurrentSubordinateSelected - heightButtons - heightFilterStatus - heightPeoplePicker - heightTitle);
                return this.heightReviwees;
            } else {
                this.heightCounselees = heightListCounselees - heightTitle;
                return this.heightCounselees;
            }
        }
        if (this.totalCounselees >= 10 && this.totalReviewees <= 10) {
            const heightListReviwees = this.totalReviewees > 5 ? 200 : (39 * this.totalReviewees);
            const newHeightReviwees = heightListReviwees + heightButtons + heightTitle + heightFilterStatus
            if (isReviewee) {
                this.heightReviwees = heightListReviwees;
                return this.heightReviwees;
            } else {
                this.heightCounselees = (heightGeneralContainer - newHeightReviwees - heightCurrentSubordinateSelected - heightPeoplePicker - heightTitle - 20);
                return this.heightCounselees;
            }
        }
        if (this.totalCounselees >= 1 && this.totalReviewees <= 10) {
            const heightListReviwees = this.totalReviewees > 5 ? 200 : (39 * this.totalReviewees);
            const newHeightReviwees = heightListReviwees + heightButtons + heightTitle + heightFilterStatus
            if (isReviewee) {
                this.heightReviwees = heightListReviwees;
                return this.heightReviwees;
            } else {
                this.heightCounselees = (heightGeneralContainer - newHeightReviwees - heightCurrentSubordinateSelected - heightPeoplePicker - heightTitle - 20);
                return this.heightCounselees;
            }
        }
        if (this.totalCounselees == 0) {
            if (isReviewee) {
                this.heightReviwees = halfScreenReviewees * 2;
                return this.heightReviwees;
            }
        }
        return halfScreenReviewees;
    }

    public saveTotalSubs(isReviwee: boolean, total: number, hasSubordinateSelected: boolean = false) {
        if (isReviwee) {
            this.totalReviewees = total;
        } else {
            this.totalCounselees = total;
        }
    }
    public getSubordinatesList(mode: SubordinatesMode, periodEnd: Date, isRevieweeSelected: boolean): Observable<Subordinate[]> {
        return this.globalCacheService.handleSubordinatesList(mode, isRevieweeSelected, periodEnd,
            this.api.getSubordinateList(this.loginEid, mode, periodEnd, isRevieweeSelected)
                .pipe(map(dtos => {
                    const subordinates: Subordinate[] = dtos.map(dto => SubordinatesServiceMapper.mapDtotoSubordinate(dto));
                    if (subordinates && subordinates.length > 0) {
                        const isRevieweeLink: boolean = !(mode == SubordinatesMode.Supervisor && isRevieweeSelected);
                        return subordinates.filter(subordinate => subordinate.isCounselee !== isRevieweeLink || subordinate.isReviewee !== isRevieweeLink);
                    }
                    return subordinates;
                }))
                .pipe(catchError(err => {
                    this.logService.logError(`Unable to display Reviewees / Approvees for user ${this.loginEid}`, true);
                    this.logService.logError(err, false);
                    return of([]);
                }))
        );
    }

    public removeSubordinate(subordinatesPeopleKeys: any[], mode: SubordinatesMode): Observable<void> {
        return this.api.removeSubordinates(this.loginEid, SubordinatesServiceMapper.mapSubordinateModeToAssignedRole(mode), this.globalCacheService.getPeriodEnd(), subordinatesPeopleKeys)
            .pipe(map(() => this.logService.showSuccessMessage('Success!', 'Remove Subordinate successfully.')))
            .pipe(catchError(err => {
                this.logService.logError('Failed to remove Subordinate.', true);
                this.logService.logError(err, false);
                return of(null);
            })
            );
    }

    public getSubordinateTabPermissionsObservable(subordinateEID: string,subordinateMode?: SubordinatesMode): Observable<MyteTabControl> {
        const getSubordinateTabPermissionsObservable = this.expenseApi.isPilotUser(subordinateEID, this.periodEndService.getActivePeriod(), this.loginEid, subordinateMode)
            .pipe(map((myteTabControlOutput: MyteTabControlOutput) => {
                return SubordinatesServiceMapper.mapAPIMyteTabControlOutput(myteTabControlOutput);
            }))
            .pipe(catchError((error: any) => {
                this.logService.logError(error, true);
                return of(undefined);
            }));
        return this.globalCacheService.handleMyteTabControl(getSubordinateTabPermissionsObservable, subordinateEID);
    }

    public getSubordinateTabPermissions(subordinateEID: string): Observable<MyteTabControlOutput> {
        return this.expenseApi.isPilotUser(subordinateEID, this.globalCacheService.getPeriodEnd(), this.loginEid, undefined);
    }


    public resolveLegacyRedirection(userTabs: MyteTabControl, subordinateMode: SubordinatesMode, subordinate: string, route: string = null): boolean {
        if (route === null) {
            route = this.router.url.split('?')[0].split('/')[1];
        }
        if (!userTabs.timeTab.valueOf() && (route == "time" || route == "preferences" || route == "compensatory" || route == "carPlan" || route == "questionnaire")
            || !userTabs.expenseTab.valueOf() && (route == "expenses" || route == "preferences")
            || !userTabs.locationsTab.valueOf() && (route == "locations" || route == "preferences")
            || !userTabs.assignmentsTab.valueOf() && (route == "chargecodes" || route == "preferences")
            || !userTabs.adjustmentsTab.valueOf() && (route == "adjustments" || route == "preferences")
            || !userTabs.summaryTab.valueOf() && (route == "summary" || route == "preferences")) {
            this.globalCacheService.clearCurrentSubordinate();
            window.location.assign(this.buildLegacyURL(subordinateMode, subordinate));
            return true;
        }
        return false;
    }

    private buildLegacyURL(subordinateMode: SubordinatesMode, subordinate: string): string {
        let mode = "";
        switch (subordinateMode) {
            case "Supervisor":
                mode = "Review"
                break;

            case "Approver":
                mode = "Approve"
                break;

            case "Delegate":
                mode = "Represent"
                break;

            default:
                mode = "Record"
                break;

            //project approver?
            //backup approver?
        }
        return this.appConfigData.OLD_MYTE_APP + `/OGTE/secure/TimeSheetPage.aspx?action=${mode}&subordinate=${subordinate}`;
    }

    public shouldSetActionIconFromSupervisor(subordinate: Subordinate): void {
        subordinate.actionFromSupervisor = this.statusToAddIcon.some(status => status == subordinate.statusCode) ? ActionWithMaticon.ViewedTR : null;
    }
    public getAuditMode(): Observable<boolean> {
        return this.isAuditBeSubject.asObservable();
    }
    public setAuditMode(value: boolean) {
        return this.isAuditBeSubject.next(value);
    }
    public getDataBasedOnSubordinateMode(): any {
        let data: any;
        this.getAuditMode().pipe().subscribe((res) => {
            if (res || this.globalCacheService.getInfoFromSessionStorage(this.globalCacheService.auditeeInfoKey)) {
                data = {
                    enterpriseId: this.globalCacheService.getCurrentSubordinate()?.enterpriseId,
                    periodEndDate: this.globalCacheService.getPeriodEnd(),
                    loggedInUser: this.userService.getUser()?.enterpriseId,
                    viewMode: SubordinatesMode.Audit
                };
            }
            else {
                if (this.viewMode === SubordinatesMode.Delegate && this.globalCacheService.getCurrentSubordinate()?.enterpriseId) {
                    data = {
                        enterpriseId: this.globalCacheService.getCurrentSubordinate()?.enterpriseId,
                        periodEndDate: this.globalCacheService.getPeriodEnd(),
                        loggedInUser: this.userService.getUser()?.enterpriseId,
                        viewMode: SubordinatesMode.Delegate
                    };
                }
                else {
                    data = {
                        enterpriseId: this.userService.getUser()?.enterpriseId,
                        periodEndDate: this.globalCacheService.getPeriodEnd(),
                    };
                }
            }
        });
        return data;
    }
    public shouldEnableAuditCommunicationLog(): boolean {
        let activeperiodend = this.globalCacheService.getPeriodEnd().toLocaleDateString();
        let savedperiodend = this.globalCacheService.getInfoFromSessionStorage(this.globalCacheService.currentPeriodEnd) || "";
        return activeperiodend === savedperiodend;
    }
    public getAuditCommunicationLogInfo(): Observable<boolean> {
        let subject = new BehaviorSubject(false);
        if(this.shouldEnableAuditCommunicationLog()){
            subject.next(this.globalCacheService.getInfoFromSessionStorage(this.globalCacheService.auditLogInfoKey) || false);
        }
        else{
            let data = this.getDataBasedOnSubordinateMode();
            let activeperiodend = this.globalCacheService.getPeriodEnd().toLocaleDateString();
            this.auditlogService.showAuditCommunicationLog(data)
                .subscribe((res: boolean) => {
                    this.globalCacheService.setDataToSessionStorage(this.globalCacheService.auditLogInfoKey, res);
                    this.globalCacheService.setDataToSessionStorage(this.globalCacheService.currentPeriodEnd, activeperiodend);
                    subject.next(res);
                });
        }
        return subject.asObservable();
    }
}