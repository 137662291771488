import { ShellModel } from '../../../app-shell/data-store';
import DropdownItem from '../controls/dropdown/dropdown-item';
import { TimeReportHistoryLog } from './time-report-history-log';
import { TimeReportLog } from './time-report-log';

export class TimeReportHistory extends ShellModel {
    public timeReportLog: Array<TimeReportLog>;
    public timeReportHistoryLog: Array<TimeReportHistoryLog>;
    public addCommentsText: string;
    public isAddCommentsTextEnabled: boolean;
    public selectedTimeReportHistoryOption: DropdownItem;
    public timeReportHistoryOptions: Array<DropdownItem> = [
        { key: DropdownActionsKeys.Comments, value: DropdownActionsNames.Comments, isShow: null, isValid: true, numberKey: DropDownActionsNumbersKeys.Comments },
        { key: DropdownActionsKeys.Approve, value: DropdownActionsNames.Approve, isShow: null, isValid: true, numberKey: DropDownActionsNumbersKeys.Approve },
        { key: DropdownActionsKeys.RequestUpdates, value: DropdownActionsNames.RequestUpdates, isShow: null, isValid: true, numberKey: DropDownActionsNumbersKeys.RequestUpdates },
        { key: DropdownActionsKeys.Viewed, value: DropdownActionsNames.Viewed, isShow: null, isValid: true, numberKey: DropDownActionsNumbersKeys.Viewed },
    ];

    constructor(timeReportLog?: Array<TimeReportLog>, timeReportHistoryLog?: Array<TimeReportHistoryLog>, addCommentsText?: string, isAddCommentsTextEnabled?: boolean,
        selectedTimeReportHistoryOption?: DropdownItem) {
        super();
    
        this.timeReportLog = timeReportLog ?? new Array(5).fill(new TimeReportLog());
        this.timeReportHistoryLog = timeReportHistoryLog ?? new Array(5).fill(new TimeReportHistoryLog());
        this.addCommentsText = addCommentsText;
        this.isAddCommentsTextEnabled = isAddCommentsTextEnabled ?? false;
        this.selectedTimeReportHistoryOption = selectedTimeReportHistoryOption ?? this.timeReportHistoryOptions[0];
    }
}

export enum DropdownActionsNames {
    Comments = 'Add Comments',
    Approve = 'Approve Time Report',
    RequestUpdates = 'Request Updates to Time Report*',
    Viewed = 'Time Report Viewed'
}

export enum DropdownActionsKeys {
    Comments = 'AddComments',
    Approve = 'ApproveTR',
    RequestUpdates = 'RequestUpdatesTR',
    Viewed = 'ViewedTR'
}

export enum DropDownActionsNumbersKeys {
    Comments = 1,
    Approve = 2,
    RequestUpdates = 3,
    Viewed = 4
}