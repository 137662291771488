import { ShellModel } from '../../../../app-shell/data-store';
import { AnnualChargeabilityGrid } from './annual-chargeability-grid.model';

export class AnnualChargeability extends ShellModel {
    public currentHours: string;
    public totalHours: string;
    public hoursPercentage: string;
    public gridData: AnnualChargeabilityGrid;

    constructor(currentHours?: string, totalHours?: string, hoursPercentage?: string, gridData?: AnnualChargeabilityGrid) {
        super();
    
        this.currentHours = currentHours;
        this.totalHours = totalHours;
        this.hoursPercentage = hoursPercentage;
        this.gridData = gridData ?? new AnnualChargeabilityGrid();
    }
}