import { Component, ElementRef, EventEmitter, forwardRef, Injectable, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbDate, NgbDateParserFormatter, NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DateformatPipe } from '@shared/pipes/dateformat.pipe';
import { FormatDateType } from '@shared/utils/constants';
import moment from 'moment';
import DatePickerViewModel from '../../../shared/models/controls/datepicker/date-picker-viewmodel';
import { TimePeriodPipe } from '../../../shared/pipes/time-period.pipe';
import { DatePickerUtils } from '../../../shared/utils/datePicker.utils';
import { RegExPatternForDateInput, RegExPatternForDateValidation } from '@shared/constant';

const localeId = navigator.userAgent.search(/Trident/i) > 0 ? navigator['browserLanguage'] : navigator['language'];

const customValueProvider = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DatePickerComponent),
    multi: true
};

@Injectable()
export class YourOwnParserFormatter {

    format(date: NgbDateStruct): string {
        //let s_date = null;
        let o_date = null;

        if (date) {
            o_date = new Date(date.year, date.month - 1, date.day);
        }

        return new DateformatPipe().transform(o_date, FormatDateType.FormatDate)
    }

    parse(date: NgbDateStruct): string {
        let o_date = null;

        if (date && typeof(date) !== 'string') {
            o_date = new Date(date.year, date.month - 1, date.day);
        }

        return o_date
    }
}

@Component({
    selector: 'myte-date-picker',
    providers: [
        { provide: NgbDateParserFormatter, useClass: YourOwnParserFormatter }
    ],
    templateUrl: './date-picker.component.html',
    styleUrls: ['./date-picker.component.sass']
})

export class DatePickerComponent implements ControlValueAccessor,OnInit, OnChanges {
    @Input() public disable = false;
    @Input() public readOnly = false;
    @Input() public placeholder = "";
    @Input() public value: Date;
    @Input() public isValid: boolean;
    @Input() public isAdjustment: boolean;
    @Input() public periodId: string;
    @Input() public isFilter: boolean = false;
    @Input() public ariaLabel:string;
    @Input() public required:boolean = false;
    @Input() public isDisplayTooltip:boolean = true;
    @Input() public validationExpressions: RegExp[] = [];
    @Input() public id: string;
    @Input() public isHidden: boolean = false;
    @Output() public onDateChange: EventEmitter<string> = new EventEmitter<string>();
    @ViewChild('datePickerInput', { static: false }) ngbInputDatepicker: ElementRef<HTMLElement>;
    @ViewChild('datePickerButton', { static: false }) ngbButtonDatepicker: ElementRef<HTMLElement>;
    public selectedDate: DatePickerViewModel | string = new DatePickerViewModel(new Date());
    model: NgbDateStruct;
    public stringToday: string;
    public firstDayOfWeek: any;
    private periodIdList : string[] = [];
    private timePeriodPipe : TimePeriodPipe = new TimePeriodPipe();
    public positionTarget: string;
    public calendarButtonId: string;

    constructor(private i18n: NgbDatepickerI18n) { }

    ngOnInit() {
        this.i18n.getWeekdayLabel = function(weekday) {
            return DatePickerUtils.getWeekDayShortNameByLanguage(weekday);
        };
        this.i18n.getMonthShortName = function(month) {
            return DatePickerUtils.getMonthShortNameByLanguage(month);
        };
        this.firstDayOfWeek = new DateformatPipe().transform(null, FormatDateType.FirstDayOfWeekByLanguage);
        if(this.placeholder === ""){
            this.placeholder = new DateformatPipe().transform(null,FormatDateType.GetDateMaskByLanguage);
        }
        this.today();
        this.periodIdList.push('processedPeriod_input');
        this.positionTarget = this.isHidden? "#inputOfDatePicker": "#"+this.id;
        this.calendarButtonId = `calendarButton_${this.id}`
    }

    ngOnChanges() {
        if (!this.value || this.value && this.value.getFullYear() == 1900)
            this.selectedDate = undefined;
        else if (this.value && new DatePickerViewModel(this.value) != this.selectedDate)
            this.selectedDate = this.GetFormatedDate(this.value);
    }

    public OnCalendarDateChange(): void {
        let date = this.selectedDate;
        this.selectedDate = typeof this.selectedDate === 'string' ? new DatePickerViewModel(new DateformatPipe().transform(this.selectedDate, FormatDateType.FormatDate)) : this.selectedDate;
        if (this.selectedDate == null) {
            this.onDateChange.emit();
            return;
        }
        if(this.IsInvaildDate(date)){
            this.selectedDate = new DatePickerViewModel(null);
            this.onDateChange.emit();
            return;
        }

        this.selectedDate = this.GetFormatedDate(this.toDate(this.selectedDate));
        this.onDateChange.emit(this.toDate(this.selectedDate).toDateString().replace(/\u200E/g, ''));
        this.writeValue(this.selectedDate);
        this.focusToCalendarButton();
    }

    public IsInvaildDate(date: any): boolean {
        if (typeof date === 'string') {
          let regex = new RegExp(RegExPatternForDateValidation);
          if(!regex.test(date))
            return true;
          let inputsymbol = [...date.toString()].find((d) =>
            Number.isNaN(parseInt(d))
          );
          let symbol =
            this.placeholder.indexOf('yyyy') == 0
              ? this.placeholder.substring(4, 5)
              : this.placeholder.substring(2, 3);
          let arr = date.toString().split(symbol);
          if (!date.toString().includes(symbol)) {
            arr = date.toString().split(inputsymbol);
          }
          if (
            this.placeholder.indexOf('yyyy') == 0 &&
            (arr[0].length != 4 || arr[1].length > 2 || arr[2].length > 2)
          ) {
            return true;
          } else if (
            (this.placeholder.indexOf('yyyy') == 6 || this.placeholder.indexOf('yyyy') == 4)&&
            (arr[0].length > 2 || arr[1].length > 2 || arr[2].length != 4)
          ) {
            return true;
          } else if (
            this.placeholder.indexOf('yyyy') == 3 &&
            (arr[0].length > 2 || arr[1].length != 4 || arr[2].length > 2)
          ) {
            return true;
          }
          return false;
        } else {
          return false;
        }
    }
    isDisabled = (date: NgbDate, currentMonth:number) => date.month !== currentMonth;

    propagateChange: any = () => { };

    writeValue(value: any) {
        if (value) {
            this.selectedDate = value;
        }
    }

    registerOnChange(fn) {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: () => void): void { }

    onChange(event) {
        this.propagateChange(event.target.value);
    }

    public toDate(date: DatePickerViewModel): Date {
        return new Date(date.year, date.month - 1, date.day);
    }

    public toDatePickerViewModel(date : Date): DatePickerViewModel{
        return new DatePickerViewModel(date);
    }

    public hideOptions(event?) { }

    public onClickToday(): void {
        let date: NgbDateStruct = {
            year: new Date().getFullYear(),
            month: new Date().getMonth() + 1,
            day: new Date().getDate()
        }
        this.selectedDate = this.GetFormatedDate(this.toDate(date));
        this.OnCalendarDateChange();
    }
    public today(): void {
        let monthByLanguage = new DateformatPipe().transform(new Date(), FormatDateType.GetFullMonthByLanguage);
        let now = moment().locale(navigator.language).format('MMMM DD, YYYY');
        let month = now.substring(0, now.indexOf(' '));
        this.stringToday = 'Today: ' + now.replace(month, monthByLanguage);
    }
    public GetFormatedDate(date : any): DatePickerViewModel{
        if(this.periodIdList.includes(this.periodId))
            return this.toDatePickerViewModel(this.timePeriodPipe.transform(date));
        else
            return this.toDatePickerViewModel(date)
    }

    public onBlur(event: any) {
        let value = event.target.value;
        if (this.validationExpressions != null) {
          this.validationExpressions.forEach(expression => {
            if(expression && !expression.test(value || '')) {
              value = null;
              event.target.value = null;
            }
          });
        }
    }

    public focusToCalendarButton(): void {
        setTimeout(() => {
            const element = document.getElementById(this.calendarButtonId)
            element?.focus();
        }, 200);
    }

    public onInputChange(event: any){
        let regex = new RegExp(RegExPatternForDateInput);
        event.srcElement.value = event.srcElement.value.split('').filter((item)=> regex.test(item)).join('')
    }
}
