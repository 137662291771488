import { Injectable } from '@angular/core';
import DropdownItem from '@shared/models/controls/dropdown/dropdown-item';
import { DateformatPipe } from '@shared/pipes/dateformat.pipe';
import { TimePeriodPipe } from '@shared/pipes/time-period.pipe';
import { GlobalCacheService } from '@shared/services/cache/global-cache.service';
import { FormatDateType } from '@shared/utils/constants';
import { BehaviorSubject, Observable } from 'rxjs';
import * as moment from 'moment';
import PeriodChangerDate from '../../../myte-controls/components/period-changer/period-changer-date';


@Injectable({
    providedIn: 'root'
})
export class TimePeriodStoreService {
    private periodEnd: BehaviorSubject<Date> = new BehaviorSubject(new Date((new TimePeriodPipe).transform(new Date())));
    private isLoading: BehaviorSubject<boolean> = new BehaviorSubject(true);
    private dropdownDates: BehaviorSubject<DropdownItem[]> = new BehaviorSubject(Array<DropdownItem>());
    private stringDateToday: BehaviorSubject<string> = new BehaviorSubject('');

    public readonly periodEnd$: Observable<Date> = this.periodEnd.asObservable();
    public readonly isLoading$: Observable<boolean> = this.isLoading.asObservable();
    public readonly dropdownDates$: Observable<DropdownItem[]> = this.dropdownDates.asObservable();
    public readonly stringDateToday$: Observable<string> = this.stringDateToday.asObservable();

    constructor(public globalCacheService: GlobalCacheService) {
                    this.createStringToday();
                    this.calculatePeriodsForDropdown();
                }

    public updatePeriodEnd(newDate: Date, needRefresh: boolean = true): void {
        if (+this.periodEnd.value == +newDate) return;
        this.isLoading.next(true);
        this.periodEnd.next(newDate);
        this.globalCacheService.setPeriodEnd(this.periodEnd.value);
    }

    public getPeriodEnd(): Date {
        return this.periodEnd.value;
    }


    private calculatePeriodsForDropdown(): void {
        let current = new Date();
        let datesForDropdown: DropdownItem[] = [];
        for (let i = 1; i < 12; i++) {
            let aux = new TimePeriodPipe().transform(current);
            datesForDropdown.push(new PeriodChangerDate(aux).toDropdownItem());
            current = this.backOnePeriod(current, aux);
        }
        this.dropdownDates.next(datesForDropdown);
    }

    private backOnePeriod(current: Date, aux: Date): Date {
        return (current.getDate() <= 15) ?  new Date(aux.getFullYear(), aux.getMonth() - 1, 16) :
                                            new Date(aux.getFullYear(), aux.getMonth(), 1);
    }

    public createStringToday(): void {
        let monthByLanguage = new DateformatPipe().transform(new Date(), FormatDateType.GetFullMonthByLanguage);
        let now = moment().format('MMMM DD, YYYY');
        let month = now.substring(0, now.indexOf(' '));
        this.stringDateToday.next('Today: ' + now.replace(month, monthByLanguage));
    }

}
