import { Component, Output, EventEmitter, Input } from '@angular/core';
import { SubordinatesService } from '../../../shared/services/subordinates/subordinates.service';
import { GlobalEventsService } from '../../../shared/services/events/global-events.service';
import Subordinate from '../../../shared/models/subordinates/subordinate';
import { SubordinatesMode } from '../../../shared/models/subordinates/subordinate-mode';

@Component({
    selector: 'myte-time-remove-subordinate-popup',
    templateUrl: './remove-subordinate-popup.component.html',
    styleUrls: ['./remove-subordinate-popup.component.sass']
})
export class RemoveSubordinatePopUpComponent {
    @Input() public subordinatesToRemove: Subordinate[];
    @Input() public subordinateMode: SubordinatesMode;
    @Output() public cancel = new EventEmitter<void>();
    @Output() public close = new EventEmitter<void>();

    constructor(
        private subordinatesService: SubordinatesService,
        private globalEventsService: GlobalEventsService
    ) { }

    public closeModal() {
        this.close.emit();
    }

    public sureToRemove() {
        this.subordinatesService.removeSubordinate(this.subordinatesToRemove.map(subordinate => subordinate.peopleKey), this.subordinateMode)
        .subscribe(() => {
            this.globalEventsService.dispatchUpdateSubordinateList(this.subordinatesToRemove);
            this.closeModal();
        });
    }

}