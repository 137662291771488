import { MyteEvent } from '../myte-event';

export class ShowLoadingOverlayEvent extends MyteEvent<boolean> {
    public static eventId = "showLoadingOverlay";
    constructor() { super(ShowLoadingOverlayEvent.eventId); }
}

export class ShowUploadReceiptPopupEvent extends MyteEvent<void> {
    public static eventId = "showUploadReceiptPopup";
    constructor() { super(ShowUploadReceiptPopupEvent.eventId); }
}

export class ShowRelatedChargeCodesPopupEvent extends MyteEvent<string> {
    public static eventId = "showRelatedChargesCodePopup";
    constructor() { super(ShowRelatedChargeCodesPopupEvent.eventId); }
}

export class RefreshGridEvent extends MyteEvent<void> {
    public static eventId = "refreshExpenseGrid";
    constructor() { super(RefreshGridEvent.eventId); }
}

export class ExpenseFileReceipt extends MyteEvent<void> {
    public static eventId = "ExpenseFileReceipt";
    constructor() { super(ExpenseFileReceipt.eventId); }
}

export class UploadReceiptStartedEvent extends MyteEvent<void> {
    public static eventId = "uploadReceiptStarted";
    constructor() { super(UploadReceiptStartedEvent.eventId); }
}

export class UploadReceiptFinishedEvent extends MyteEvent<void> {
    public static eventId = "uploadReceiptFinished";
    constructor() { super(UploadReceiptFinishedEvent.eventId); }
}