import { GlobalCacheService } from '@sharedServices/cache/global-cache.service';
import { ElectronicReceiptValidExtension } from '@sharedClients/timereport-api-client';
import TimeReportSettings from './time-report/time-report-settings';

export class MyteFile {
    public id: number;
    public fileName: string;
    public fileType: ReceiptFileType;
    public fileUrl: string;
    public fileSize: number;
    public electronicReceiptValidExtension: ElectronicReceiptValidExtension;
    private base64Data: string;

    constructor(file?: File , cacheService?: GlobalCacheService,  settings?: TimeReportSettings) {
        if (!file) return;
        this.fileName = file.name;
        this.fileSize = file.size;
        this.electronicReceiptValidExtension = settings != null ? settings.electronicReceiptValidExtension : null;
        if (cacheService != undefined && this.electronicReceiptValidExtension == null) {
            cacheService.getTimeReportSettings(cacheService.getPeriodEnd(), null).subscribe(settings => {
                this.electronicReceiptValidExtension = settings != null ? settings.electronicReceiptValidExtension : null;
            });
        }
        if (this.electronicReceiptValidExtension && !this.IsValidName(this.electronicReceiptValidExtension.extension)) {
            throw new Error (this.electronicReceiptValidExtension.message.replace('\\r', ' '));
        }

        switch (file.type.split('/')[0]) {
            case 'image':
                switch (file.type.split('/')[1]) {
                    case 'tif':
                        this.fileType = ReceiptFileType.Undefined;
                        break;
                    case 'tiff':
                        this.fileType = ReceiptFileType.Undefined;
                        break;
                    default:
                        this.fileType = ReceiptFileType.Image;
                        break;
                }
                break;

            case 'application':
                switch (file.type.split('/')[1]) {
                    case 'pdf':
                        this.fileType = ReceiptFileType.Pdf;
                        break;
                    default:
                        this.fileType = ReceiptFileType.Undefined;
                        break;
                }
                break;
            default:
                this.fileType = ReceiptFileType.Undefined;
                break;
        }
    }

    public IsValidName(extension: string): boolean {
        let extensionArray = extension.split(',');
        let exten = this.fileName.split('.')[this.fileName.split('.').length - 1].toLowerCase();
        return exten == '' ? false : extensionArray.find(e => e == exten) != undefined;
    }

    public processFileData(buffer: string): void {
        this.base64Data = buffer;
    }

    public getFileData(): string {
        return this.base64Data;
    }

    public isLocalFile(): boolean {
        return this.fileUrl == null;
    }

    public canPreview(): boolean {
        return this.fileType == ReceiptFileType.Image || this.fileType == ReceiptFileType.Pdf;
    }

    public isImage(): boolean {
        return this.fileType == ReceiptFileType.Image;
    }

    public isPdf(): boolean {
        return this.fileType == ReceiptFileType.Pdf;
    }
}

export enum ReceiptFileType {
    Image,
    Pdf,
    Undefined,
}
