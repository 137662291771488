import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PeoplePicker } from '../../../shared/models/controls/people-picker/people-picker';
import { GlobalEventsService } from '../../../shared/services/events/global-events.service';
import { SubordinatesServiceMapper } from '../../../shared/services/subordinates/subordinates.service.mapper';
import { SubordinatesMode } from '../../../shared/models/subordinates/subordinate-mode';
import { GlobalCacheService } from '../../../shared/services/cache/global-cache.service';
import { UserRole } from '../../../shared/models/user/user-roles';
import { SubordinatesService } from '../../../shared/services/subordinates/subordinates.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TimeReportService } from '../../../shared/services/time-report/time-report.service';
import Subordinate from '@shared/models/subordinates/subordinate';

@Component({
  selector: 'myte-subordinates-bar',
  templateUrl: './subordinates-bar.component.html',
  styleUrls: ['./subordinates-bar.component.sass']
})
export class SubordinatesBarComponent implements OnInit {
  public counselees: Subordinate[];
  public showSubordinatesMenu: boolean = false;
  public subordinateMode: SubordinatesMode;
  public subordinatesReady: boolean = false;
  public userRole: UserRole;
  public get isSubordinateMode(): boolean {
    return this.subordinateMode != SubordinatesMode.None
  }
  public get isSearchEnabled(): boolean {

    switch (this.subordinateMode) {
      case SubordinatesMode.Supervisor:
        if (this.userRole === UserRole.Auditor
          || this.userRole === UserRole.ReadOnly
          || this.userRole === UserRole.Administrator
          || this.userRole === UserRole.AuditSupport
          || this.userRole === UserRole.EMSUser
          || this.userRole === UserRole.TEAuditReviewerAFS
          || this.userRole === UserRole.TEAuditReviewerMD
          || this.userRole === UserRole.TEAuditReviewerNonMD
          || this.userRole === UserRole.FraudAuditor
          || this.userRole === UserRole.AuditLead
          || this.userRole === UserRole.VipAuditor) {
          return true;
        } else {
          return false;
        };
      case SubordinatesMode.Delegate:
        if (this.userRole === UserRole.SuperUser
          || this.userRole === UserRole.EditOnly
          || this.userRole === UserRole.Administrator
          || this.userRole === UserRole.Support) {
          return true;
        } else {
          return false;
        };
      default:
        return false;
    }
  }

  constructor(private route: ActivatedRoute,
    private router: Router,
    private globalCacheService: GlobalCacheService,
    private globalEventsService: GlobalEventsService,
    private subordinatesService: SubordinatesService,
    private changeDetectorRef: ChangeDetectorRef,
    private timeReportService: TimeReportService
  ) { }

  public ngOnInit(): void {
    this.setSubordinateMode(this.globalCacheService.getSubordinateMode());
    this.userRole = this.globalCacheService.getUserRole();
    if (this.route.snapshot.queryParams.subordinate) {
      const subordinate = atob(decodeURIComponent(this.route.snapshot.queryParams.subordinate));
      if (subordinate) this.selectSubordinateFromPeoplePicker(new PeoplePicker(null, subordinate), true);
    }
  }

  ngAfterViewChecked() {
    this.changeDetectorRef.detectChanges();
  }

  public selectSubordinateFromPeoplePicker(people: PeoplePicker, isLocalTrigger: boolean = false, isClickEvent: boolean = false): void {
    let subordinate = SubordinatesServiceMapper.mapPeoplePickerToSubrodinate(people);
    if (!isLocalTrigger) this.globalCacheService.clearBufferCache();
    this.subordinatesService.getSubordinateTabPermissionsObservable(subordinate.enterpriseId, this.subordinateMode)
      .subscribe(userTabs => {
        if (!this.subordinatesService.resolveLegacyRedirection(userTabs, this.subordinateMode, subordinate.enterpriseId) &&
          this.globalCacheService.getCurrentSubordinate()?.enterpriseId != subordinate.enterpriseId) {
          this.globalCacheService.setCurrentSubordinate(subordinate);
          this.globalEventsService.dispatchSubordinateSelectedEvent({ subordinate: subordinate, mode: this.subordinateMode });
          this.globalEventsService.dispatchUpdateSubordinateList([subordinate]);

          if (isClickEvent)
            this.globalEventsService.dispatchWorkLocationUpdateEvent(true);
          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          this.setSubordinateTabs(subordinate.countryKey, subordinate.enterpriseId);
        }
      });
  }

  public setSubordinateTabs(countryKey: string, enterpriseId: string) {
    return this.timeReportService.getTimeReportSettings(this.globalCacheService.getPeriodEnd(), countryKey)
      .subscribe(setting => {
        var route = this.router.url.split('?')[0].split('/')[1];
        var subordinateUrlValue: string = this.router.url.substr(this.router.url.lastIndexOf("subordinate"))?.split('=')[1];
        var newSubordinate: string = encodeURIComponent(btoa(enterpriseId));
        let newUrl = subordinateUrlValue && subordinateUrlValue != newSubordinate ? this.router.url.replace(subordinateUrlValue, newSubordinate) : this.router.url;
        if ((route == "compensatory" && !setting?.showCompensatoryTab) ||
          (route == "questionnaire" && !setting?.showLocationQuestionnaireTab) ||
          (route == "carplan" && !setting?.showCarPlan))
          this.router.navigateByUrl(newUrl.replace(route, 'time'));
        else
          this.router.navigateByUrl(newUrl);
      });
  }

  private setSubordinateMode(viewMode: SubordinatesMode): void {
    this.showSubordinatesMenu = viewMode != SubordinatesMode.None;
    if (viewMode == SubordinatesMode.ReviewEmail) {
      this.showSubordinatesMenu = false;
    }
    this.subordinateMode = viewMode;
  }
}
