export class ChargeCodeRelated {
    public type: Type;
    public node: string;
    public description: string;
    public parentNode: string;
    public showNode: boolean;
    public hasError: boolean;

    constructor(type?: string, node?: string, desc?: string, parentNode?: string, hasError?: boolean) {
        this.type = new Type(type);
        this.node = node;
        this.description = desc;
        this.parentNode = parentNode;
        this.showNode = false;
        this.hasError = hasError;
    }
}

export class Type {
    public name: string;
    public chlidNodeShowed: boolean;
    public sequence?: string;
    public hasChild: boolean;

    constructor(type: string) {
        this.name = type;
        this.chlidNodeShowed = false;
        this.sequence = null;
        this.hasChild = false;
    }
}
