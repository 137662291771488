<div class="selector-container">
    <div><label id="xmlTypeLabel">Xml Type</label></div>
    <myte-dropdown 
                id="xmlTypeDropdown"
                class="input"
                [value]="endPointId"
                [elementsList]="endPointDropdownElements"
                (updateValueEvent)="onEndPointChange($event)">
    </myte-dropdown>
    <div><label id="eidLabel">Eid</label></div>
    <myte-textbox 
                id="eidInput"
                class="input"
                (updateValueEvent)="onTextChange($event)">
    </myte-textbox>
    <div><label id="periodLabel">Period</label></div>
    <myte-date-picker 
                id="periodInput"
                class="input"
                (onDateChange)="onDatePickerChange($event)" [value]="date">
    </myte-date-picker>
    <div><label id="dataTypeLabel">Data Type</label></div>
    <myte-dropdown 
                    id="dataTypeDropdown"
                    class="input"
                    [value]="myteVersionId"
                    [elementsList]="myteVersionDropdownElements" 
                    (updateValueEvent)="onMyteVersionChange($event)">
    </myte-dropdown>
    <button id="getDataButton" (click)="onGetDataClick()">GetData</button>
</div>
<div class="result-container">
    <div><label id="resultLabel">Result</label></div>
    <textarea 
                id="resultTextarea"
                class="result" 
                [value]="text"></textarea>
</div>
<div class="download-container">
    <button id="download-button" (click)="onDownloadClick()">download result</button>
</div>
