import { Injectable, EventEmitter } from '@angular/core';
import { DatePipe } from '@angular/common';
import { TimePeriodPipe } from '@sharedPipes/time-period.pipe';
import { GlobalCacheService } from '@sharedServices/cache/global-cache.service';
import TimeSheetDate from '@sharedModels/time-sheet/time-sheet-date';
import { TimeSheetService } from '../../../myte-time/shared/services/timesheet/timesheet.service';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import moment from 'moment';

                            
@Injectable({
  providedIn: 'root'
})
export class PeriodEndService {
  /** Subscrive to this Event to get called when the actual period change */
  public onPeriodChange: EventEmitter<Date> = new EventEmitter<Date>();
  private actualPeriod: Date;

  constructor(private cacheService: GlobalCacheService, private timeSheetService: TimeSheetService) { }

  /** Change the period and emit the event */
  public changePeriod(selectedPointsIfo: Date): void {
    this.actualPeriod = selectedPointsIfo;
    this.onPeriodChange.emit(selectedPointsIfo);
  }

  /** Get the actual period at this time */
  public getActualPeriod(): Date {
    if (!this.actualPeriod) {
      this.actualPeriod = new Date((new TimePeriodPipe).transform(new Date()));
    }
    return this.actualPeriod;
  }

  /** Get All dates of the actual period */
  public getDatesOfActualPeriod(): Date[] {
    const startDate = new Date(this.getActivePeriod());
    const stopDate = new Date(this.getActivePeriod());

    if (startDate.getDate() > 15) {
      startDate.setDate(16);
    } else {
      startDate.setDate(1);
    }
    let currentDate = startDate;
    let dateArray = new Array();

    while (currentDate <= stopDate) {
      dateArray.push(new Date(currentDate));
      currentDate = this.addDays(1, currentDate);
    }
    return dateArray;
  }

  /** Get the actual period on short date format */
  public getActualPeriodEndOnShortDateFormat(): any {

    let datePipe = new DatePipe('en-US');
    if (!this.actualPeriod) {
      this.actualPeriod = new Date((new TimePeriodPipe).transform(new Date()))
    }
    return datePipe.transform(this.actualPeriod, 'MM-dd-yyyy');
  }

  public getTimeWithTimezone(date: Date): Date {
    date.setHours(date.getHours() + (-1 * date.getTimezoneOffset() / 60));
    return date;
  }

  /** @summary Retrieve the current selected period */
  public getActivePeriod(): Date {
    let periodEnd = this.cacheService.getPeriodEnd();
    if (!periodEnd) {
        periodEnd = this.getActualPeriod();
        this.cacheService.setPeriodEnd(periodEnd);
    }
    return periodEnd;
  }

  private addDays(days: number, date: Date): Date {
    var date = new Date(date.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  }

  public getTimesheetPeriodDates(): Observable<TimeSheetDate[]> {
    let periodEnd = this.getActivePeriod();

    return this.cacheService.getTimeSheet(periodEnd)
    .pipe(switchMap(tsc => {
      if(!tsc){
        return this.timeSheetService.getTimeSheet(periodEnd).pipe(map(ts => {return ts.timeSheetDates;}));
      } else {
        return of(tsc.timeSheetDates);
      }
    }));
  }

    /** @summary Check if selected period is Mid Month or Month End */
    public isPeriodMidMonth(period: Date): boolean {
      if (moment(new Date(period)).isValid()) {
        return moment(period).get('date') === 15;
      }
    }
  
    /** @summary Retrieve the next of selected period */
    public getActiveNextPeriod(period: Date): Date {
      if (moment(new Date(period)).isValid()) {
        return (this.isPeriodMidMonth(period) ? moment(period).set('date', moment(period).endOf('month').date()) :
        moment(period).add(1, 'month').set('date', 15)).toDate();
      }
    }
  
    /** @summary Retrieve the previous of selected period */
    public getActivePreviousPeriod(period: Date): Date {
      if (moment(new Date(period)).isValid()) {
        return (this.isPeriodMidMonth(period) ? moment(period).subtract(1, 'month').set('date', moment(period).subtract(1, 'month').endOf('month').date()) :
        moment(period).set('date', 15)).toDate();
      }
    }
}