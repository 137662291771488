import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, finalize, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { LocationsApiClient, LocationByDayOutput, Metadata, WorkLocationDayInfo, LocationReasonOutput, MultipleLocationsOutput, LocationOneOutput, LocationTwoOutput } from '../../../../shared/clients/locations-api-client';
import {
  LocationsByDay,
  Country,
  LocationValue,
  LocationTwo,
  LocationOne,
  MultipleLocation,
  LocationInformation,
  LocationEntry,
  LocationEntryStatus,
  LocationOnes
} from '../../../../shared/models/locations';
import LocationsServiceMapper from './locations.service.mapper';
import { PeriodEndService } from '../../../../shared/services/periodend/periodend.service';
import { UserService } from '../../../../shared/services/user/user.service';
import { LogService } from '../../../../shared/services/log/log.service';
import { GlobalCacheService } from '../../../../shared/services/cache/global-cache.service';
import MyteApiMessage from '../../../../shared/models/myte-api-message';
import { MyteBaseService } from '../../../../shared/services/myte-base.service';
import { GlobalEventsService } from '../../../../shared/services/events/global-events.service';
import { TimeReportUtils } from '../../../../shared/utils/timeReport.utils';
import { TimeReportStoreService } from '@shared/services/store/time-report-store.service';

@Injectable()
export class LocationsService extends MyteBaseService {
  public selectedLocationInformation: LocationEntry;
  public defaultLocationsByDay: LocationInformation;

  constructor(private locationApiClient: LocationsApiClient,
    private periodEndService: PeriodEndService,
    userService: UserService,
    globalCacheService: GlobalCacheService,
    globalEventsService: GlobalEventsService,
    logService: LogService,
    private timeReportStoreService: TimeReportStoreService) {
    super(userService, globalCacheService, globalEventsService, logService);
  }

  /** Get locations charges for a period
   * @param periodend The time report period
   * @returns Observable of locations by day
   */
  public getLocationsByDay(periodend?: Date, selectedTab?: string): Observable<LocationsByDay> {
    if (!periodend)
      periodend = this.periodEndService.getActivePeriod();
    return this.locationApiClient.getLocationByDay(this.userEid, periodend, this.supervisorEid, this.viewMode, selectedTab).pipe(
      map(locationByDayOutput => {
        let res = LocationsServiceMapper.mapLocationsByDay(locationByDayOutput);
        let timeReport = TimeReportUtils.createTimeReport(periodend, locationByDayOutput.metadata.timeReportStatus, this.viewMode);
        this.globalCacheService.handleResetTimeReport(periodend);
        this.globalCacheService.handleTimeReport(periodend, of(timeReport));
        res.readOnly = !timeReport.locationsByDayCanbeEdited();
        let isAudit = this.globalCacheService.getInfoFromSessionStorage(this.globalCacheService.auditeeInfoKey);
        if(isAudit) res.isEditable = !isAudit; 
        else res.isEditable = (this.viewMode != 'Supervisor' && this.viewMode != 'Approver');
        return res;
      }
      ))
      .pipe(catchError(error => {
        this.logService.logError(error, true);
        return throwError(null);
      }));
  }

  public getWorkLocations(): Observable<WorkLocationDayInfo[]> {
    let periodEnd = this.periodEndService.getActivePeriod();
    return this.globalCacheService.handleGetWorkLocation(
      this.locationApiClient.getWorkLocations(
        this.userEid, periodEnd, this.supervisorEid, this.viewMode),
        this.userEid,
        periodEnd,
        this.supervisorEid,
        this.viewMode);
  }

  /** Get all countries
   * @returns List of countries
   */
  public getCountries(): Observable<Country[]> {
    return this.globalCacheService.handleLocationsCountries(
      this.locationApiClient.getCountries(this.periodEndService.getActivePeriod()).pipe(
        map(countryOutput => LocationsServiceMapper.mapCountries(countryOutput)),
        catchError(this.handleError<Country[]>('', []))
      ));
  }

  /** Get all states for a country
   * @param countryCode The country code
   * @returns List of states
   */
  public getLocationsOne(countryCode: string): Observable<LocationOnes> {
    return this.locationApiClient.getLocationOne(this.userEid, this.periodEndService.getActivePeriod(), countryCode, this.supervisorEid, this.viewMode).pipe(
      map(locationOneOutput => LocationsServiceMapper.mapLocationsOne(locationOneOutput)),
      catchError(this.handleError<LocationOnes>('', null))
    );
  }

  public getLocationOneObservable(countryCode: string): Observable<LocationOnes> {
    if (!countryCode) return of(undefined);
    const getLocationOneObservable = this.locationApiClient.getLocationOne(this.userEid, this.periodEndService.getActivePeriod(), countryCode, this.supervisorEid, this.viewMode)
    .pipe(map((locationOneOutput: LocationOneOutput) => {
      return LocationsServiceMapper.mapLocationsOne(locationOneOutput);
    }))
    .pipe(catchError((error: any) => {
      this.logService.showValidationMessage('', error.message);
      return of(undefined);
    }));
    return this.globalCacheService.handleLocationOnes(getLocationOneObservable, this.periodEndService.getActivePeriod(), countryCode);
  }

  /** Get all cities for a state
   * @param countryCode The country code
   * @param locationOneCode The state code
   * @returns List of cities
   */
  public getLocationsTwo(countryCode: string, locationOneCode: string): Observable<LocationTwo> {
    return this.locationApiClient.getLocationTwo(this.userEid, this.periodEndService.getActivePeriod(), countryCode, locationOneCode, this.supervisorEid, this.viewMode).pipe(
      map(locationTwoOutput => LocationsServiceMapper.mapLocationsTwo(locationTwoOutput)),
      catchError(this.handleError<LocationTwo>('', null))
    );
  }

  public getLocationTwoObservable(countryCode: string, locationOneCode: string): Observable<LocationTwo> {
    if (!countryCode || !locationOneCode) return of(undefined);
    const getLocationTwoObservable = this.locationApiClient.getLocationTwo(this.userEid, this.periodEndService.getActivePeriod(), countryCode, locationOneCode, this.supervisorEid, this.viewMode)
    .pipe(map((locationTwoOutput: LocationTwoOutput) => {
      return LocationsServiceMapper.mapLocationsTwo(locationTwoOutput);
    }))
    .pipe(catchError((error: any) => {
      this.logService.showValidationMessage('', error.message);
      return of(undefined);
    }));
    return this.globalCacheService.handleLocationTwo(getLocationTwoObservable, this.periodEndService.getActivePeriod(), countryCode, locationOneCode);
  }

  /** Get all Location Reason for US/CA
   * @returns List of Location Reasons
   */
  public getLocationReason(): Observable<LocationValue[]> {
    let periodEnd = this.periodEndService.getActivePeriod();
    let asd = this.locationApiClient.getLocationReason(periodEnd);
    return this.locationApiClient.getLocationReason(periodEnd).pipe(
      map(locationReasonOutput => LocationsServiceMapper.mapLocationReason(locationReasonOutput)),
      catchError(this.handleError<LocationValue[]>('', []))
    );
  }
  
  /** Save all locations
   * @param locationsByDay Data to be saved
   * @returns True if the data was saved
   */
  public saveLocations(locationsByDay: LocationsByDay, isSubmit?: boolean): Observable<LocationsByDay> {
    try {
      if (isSubmit) locationsByDay.isSubmit = isSubmit;
      locationsByDay.validate.onSave();
    } catch (error) {
      locationsByDay.messages = [] as MyteApiMessage[];
      locationsByDay.messages.push(new MyteApiMessage(error.message));
      locationsByDay.isSubmit = false;
      return of(locationsByDay);
    }
    return this.locationApiClient.saveLocations(this.supervisorEid, this.viewMode,
      LocationsServiceMapper.mapLocationByDayInput(locationsByDay, this.userEid, this.periodEndService.getActivePeriod(), isSubmit))
      .pipe(map((res: LocationByDayOutput) => LocationsServiceMapper.mapLocationsByDay(res)))
      .pipe(tap(() => this.globalCacheService.clearTimeReportSummary(this.periodEndService.getActivePeriod(), this.userEid)))
      .pipe(tap(() => this.globalCacheService.cleartWorkLocationByDay(this.userEid, this.periodEndService.getActivePeriod(), this.supervisorEid, this.viewMode)))
      .pipe(catchError(this.handleError<LocationsByDay>('', locationsByDay)));
  }

  /** Get multiple locations for a day
   * @param date Date of a period
   * @param locations Locations chaged for this day
   * @returns Multiple locations data
   */
  public getMultipleLocations(date: Date, locations: LocationInformation[]): Observable<MultipleLocation> {
    return this.locationApiClient.getMultipleLocations(this.userEid, this.periodEndService.getActivePeriod(), date, this.supervisorEid, this.viewMode).pipe(
      map(multipleLocations => LocationsServiceMapper.mapMultipleLocations(multipleLocations, locations, date)),
      catchError(this.handleError<MultipleLocation>('', null))
    );
  }

  public getMultipleLocationsObservable(date: Date, locationInformations: Array<LocationInformation>): Observable<MultipleLocation> {
    return this.locationApiClient.getMultipleLocations(this.userEid, this.periodEndService.getActivePeriod(), date, this.supervisorEid, this.viewMode)
    .pipe(map((multipleLocationsOutput: MultipleLocationsOutput) => {
      if (multipleLocationsOutput && multipleLocationsOutput.assignments && multipleLocationsOutput.assignments.length === 0)
        this.logService.showValidationMessage('', 'You have selected multiple locations for one or more dates. Click the date on the Locations tab to set the corresponding hours for each charge code.');
      return LocationsServiceMapper.mapMultipleLocations(multipleLocationsOutput, locationInformations, date);
    }))
    .pipe(catchError((error: any) => {
      this.logService.showValidationMessage('', error.message, false);
      return of(undefined);
    }));
  }

  public saveMultipleLocationObservable(locationsByDay: LocationsByDay, multipleLocation: MultipleLocation): Observable<LocationsByDay> {
    if (!multipleLocation || (multipleLocation && multipleLocation.charges && multipleLocation.charges.length === 0)) return of(undefined);
    const periodEndDate: Date = this.periodEndService.getActivePeriod();
    this.globalEventsService.dispatchShowGlobalSpinnerEvent(true);
    return this.locationApiClient.saveMultipleLocation(this.supervisorEid, this.viewMode, LocationsServiceMapper.mapMultipleLocationsInput(locationsByDay, multipleLocation, this.userEid, periodEndDate))
      .pipe(mergeMap((response: Metadata) => {
        if (response.errors && response.errors.length) {
          response.errors.forEach(e => this.logService.showValidationMessage('', e.message, false));
          return of(undefined);
        }
        this.logService.showSuccessMessage('', `The multiple location entry hours was saved`, false);
        this.timeReportStoreService.updateTimeReportStatus(response.timeReportStatus);
        const getLocationsByDayObservable: Observable<LocationsByDay> = this.getLocationsByDay(periodEndDate);
        this.globalCacheService.setLocationsByDay(getLocationsByDayObservable, periodEndDate);
        return getLocationsByDayObservable;
      }))
      .pipe(catchError((error: any) => {
        this.logService.showValidationMessage('', error.message, false);
        return of(undefined);
      }))
      .pipe(finalize(() => {
        this.globalEventsService.dispatchShowGlobalSpinnerEvent(false);
      }));
  }

  public saveMultipleLocations(locationsByDay: LocationsByDay, multipleLocations: MultipleLocation): Observable<Metadata> {

    return this.locationApiClient.saveMultipleLocation(this.supervisorEid, this.viewMode,
      LocationsServiceMapper.mapMultipleLocationsInput(locationsByDay, multipleLocations, this.userEid, this.periodEndService.getActivePeriod())
    ).pipe(catchError(this.handleError<Metadata>('', null)));
  }

  /** Delete a location
   * @param locationsByDay Current data
   * @param locationEntry Data to be deleted
   * @param periodend The time report period
   * @returns The locations by day data updated
   */
  public deleteLocation(locationsByDay: LocationsByDay, locationEntry: LocationEntry, periodend?: Date): Observable<LocationsByDay> {
    if (!periodend)
      periodend = this.periodEndService.getActivePeriod();
    if (locationEntry.status != LocationEntryStatus.added) {
      return this.locationApiClient.deleteLocation(this.supervisorEid, this.viewMode,
        LocationsServiceMapper.mapDeleteLocationInput(this.userEid, periodend, locationEntry.location)).pipe(
          map(result => {
            if (!result.isDeletedLocation)
              return null;
            locationsByDay.timeReportStatus = result.timeReportStatus;
            locationsByDay.locationEntries = locationsByDay.locationEntries.filter(entry =>
              !entry.location.equals(locationEntry.location));
            return locationsByDay;
          }),
          catchError(this.handleError<LocationsByDay>('', null))
        ).pipe(tap(() => this.globalCacheService.clearTimeReportSummary(periodend, this.userEid)));
    }
    locationsByDay.locationEntries = locationsByDay.locationEntries.filter(entry =>
      !entry.location.equals(locationEntry.location));
    return of(locationsByDay);
  }

  public setDefaultLocation(location: LocationInformation): Observable<boolean> {
    return this.locationApiClient.setDefaultLocation(this.periodEndService.getActivePeriod(), this.supervisorEid, this.viewMode,
      LocationsServiceMapper.mapDefaultLocationInput(location, this.userEid))
      .pipe(catchError(this.handleError<boolean>('', false)));
  }

  public saveChangeLBDReason(periodEnd: Date, value: any): Observable<boolean> {
    return this.locationApiClient.saveChangeLBDReason(LocationsServiceMapper.mapSaveSwipeCardReasonInput(periodEnd, this.userEid, value))
      .pipe(map(res => {
        if (res) {
          this.logService.showSuccessMessage('Save Locations', 'Location Saved Successfully');
          return true;
        } 
        else {
          this.logService.logWarning('Save Locations fails', true, 'Save Locations', false);
          return false;
        }
      }))
      .pipe(catchError(this.handleError<boolean>('', null)));
  }

  private handleError<T>(operation: string, result?: T, showToast: boolean = true) {
    return (error: Error): Observable<T> => {
      if (showToast)
        this.logService.showValidationMessage(operation, error.message, false);
      return of(result as T);
    };
  }
}
