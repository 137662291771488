export class ExpenseAmount {
    public amount: number;
    public amountToDisplay: string;
    public message: string;
    public taxTrueUpValue: string;
    public isValid: boolean;
    public validationErrors: string;
    public code: string;
    public isCarPlan: boolean;
    public decimalPlaces: number;

    constructor(amount?: number, message?: string, taxTrueUpValue?: string, isValid?: boolean, validationErrors?: string, decimalPlaces?: number) {
      this.amount = amount;
      this.message = message;
      this.taxTrueUpValue = taxTrueUpValue;
      this.isValid = isValid;
      this.validationErrors = validationErrors;
      this.decimalPlaces = decimalPlaces;
    }

    public getAmount() {
      if (!this.taxTrueUpValue && !this.message) {
        return this.amount;
      }
      if (this.taxTrueUpValue && this.message) {
        return Number(this.taxTrueUpValue);
      }
      if (!this.taxTrueUpValue && this.message) {
        return 0;
      }
      return this.amount + Number(this.taxTrueUpValue);
    }

    public currencyFormatter(string) {
      string = this.formatNumber(string).toString()
      var newStr = "";
      var count = 0;
      for (var i = string.indexOf(".") - 1; i >= 0; i--) {
        if (count != 0 && count % 3 == 0) {
          newStr = string.charAt(i) + "," + newStr;
        }
        else {
          newStr = string.charAt(i) + newStr;
        }
        count++;
      }
      string = newStr + string.substr(string.indexOf("."), 3);
      return string;
    }

    public decimalPlacesFormatter(number){
      let decimalPipe = number >= 0 ? '.' + number + '-' + number : '.2-2';
      return decimalPipe;
    }

    public formatNumber(number) {
        number = +number;
        if (isNaN(number)) {
          return 0;
        }
  
        number = number.toString().split('e');
        number = Math.round(+(number[0] + 'e' + (number[1] ? (+number[1] + 2) : 2)));
        number = number.toString().split('e');
        return (+(number[0] + 'e' + (number[1] ? (+number[1] - 2) : -2))).toFixed(2);
    }
}