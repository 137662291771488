import { Injectable } from '@angular/core';
import TabRelationModel from '@sharedModels/tabs/tab-relation-model.component';




export enum TabsEnum {
  Time ,
  Expenses ,
  Locations ,
  ChargeCodes ,
  Adjustments ,
  Summary ,
  Preferences 
  
}

export interface ITabsRelationsService {
  setRelation(_model:TabRelationModel) :void
  clearRelations():void
  hasAnyRelation() : boolean
  getRelation():TabRelationModel
  getRelationAndClear():TabRelationModel
}

@Injectable({
  providedIn: 'root'
})


  export default class TabsRelationsService implements ITabsRelationsService
  {
      private hasRelation: boolean=false;
      private model:TabRelationModel=null;
      constructor() { }

      public setRelation(_model:TabRelationModel)
      {
          this.model=_model;
          this.hasRelation=true;
      }

      public clearRelations()
      {
          this.model=null;
          this.hasRelation=false;
      }

      public hasAnyRelation() : boolean
      {
          return this.hasRelation;
      }

      public getRelation():TabRelationModel
      {
          return this.model;
      }

      public getRelationAndClear():TabRelationModel
      {
        let model=this.getRelation();
        this.clearRelations();
        return model;

      }

  }