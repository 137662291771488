import { LocalizeFormatUtils } from "./localizeFormater.utils";

export abstract class NavigatorBrowserUtils {

    public static getBrowserName() : string {
        const agent = window.navigator.userAgent.toLowerCase();
        const isIE=/trident\/|msie/.test(agent);
        if(isIE)
          return 'ie';
        switch (true) {
          case agent.indexOf('edg') > -1:
            return 'edge';
          case agent.indexOf('opr') > -1 && !!(<any>window).opr:
            return 'opera';
          case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
            return 'chrome';
          case agent.indexOf('trident') > -1:
            return 'ie';
          case agent.indexOf('firefox') > -1:
            return 'firefox';
          case agent.indexOf('safari') > -1:
            return 'safari';
          default:
            return 'other';
        }
    }

    public static isIEPolyfilSeparator()
    {
      if (this.getBrowserName()=="ie" && LocalizeFormatUtils.getDecimalSeparator()==".")
          return true;
      return false;
    }

    public static ValidInputKeyDownIE(event):boolean {
        var keynum;
        var keynum2=event.char;
        var keynum3=event.key;
        if(window.event) { // IE
        keynum = event.keyCode;
      } else if(event.which){
        keynum = event.which;
      }
      var sfrc=String.fromCharCode(keynum);
      let isNumber = /^[0-9]$/i.test(event.key) || /^[0-9]$/i.test(event.keyCode);
      if(isNumber || sfrc=="." || keynum=="." || keynum2=="." || keynum3=="." || keynum=="DEL" || keynum2=="DEL" || keynum3=="DEL" || event.keyCode==46 || keynum==46)
          return true;
      else
        return false;

    }
}