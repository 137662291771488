import { RequestMocked , RequestTypes } from '../request-mocked';

export abstract class ExpenseRequestsMocks {
    // TODO (elias.e.wasinger): Change RequestMocked interface to a abstract class and add method to compare request's body
    public static expensesGeneralGrid: RequestMocked = {
        description: 'Expenses for general grid',
        requestBody: `{"enterpriseId":"emiliano.gonzalez","periodEnd":"10-31-2020","countryKey":null}`,
        requestType: RequestTypes.POST,
        url: 'ExpenseList',
        urlJson: '../../../assets/data/expenses-tab/expenses-mock.json'

    }

    public static expensesAmex: RequestMocked = {
        description: 'Get amex expenses',
        requestBody: null,
        requestType: RequestTypes.GET,
        url: '/GetAmexExpenses?',
        urlJson: '../../../assets/data/expenses-tab/expense-amex-mock.json'

    }

    public static getTripNames: RequestMocked = {
        description: 'Get trip names',
        requestBody: null,
        requestType: RequestTypes.GET,
        url: '/GetTripNames',
        urlJson: '../../../assets/data/user/getTripNames.json'

    }

    public static expenseDetailsAddTaxiTravel: RequestMocked = {
        description: 'Add a new expense for Travel Taxi - EX01',
        requestBody: ``,
        requestType: RequestTypes.POST,
        url: '/ExpenseDetails?viewMode=',
        urlJson: '../../../assets/data/expenses/ex01-mock.json'

    }

    public static expenseDetailsAddTaxiTravelTriggerByAmount: RequestMocked = {
        description: 'Add a new expense for Travel Taxi - EX01 - Triggered by click on Amount',
        requestBody: `{"periodEnd": "10-31-2020","expenseTypeCd": "EX01","expenseId": "w3lGHPbMlMmZCL1qqasIug==","triggeredBy": "Amount"}`,
        requestType: RequestTypes.POST,
        url: '/ExpenseDetails?viewMode=',
        urlJson: '../../../assets/data/expenses/SaveExpenses/taxiTravelSaveTriggerByAmount.json'

    }

    public static expensesGetIssues: RequestMocked = {
        description: 'Mock for GetIssuesWithAdjustmentsByEnterpriseId',
        requestBody: null,
        requestType: RequestTypes.GET,
        url: '/GetIssuesWithAdjustmentsByEnterpriseId',
        urlJson: '../../../assets/data/get-issues.json'
    }

    public static expensesSaveTaxiTravel: RequestMocked = {
        description: 'Mock for Save Taxi Travel - EX01',
        requestBody: '{"periodEnd": "10-31-2020","expenseTypeCd": "EX01","expenseId": "w3lGHPbMlMmZCL1qqasIug=="}',
        requestType: RequestTypes.POST,
        url: '/SaveExpense?viewMode=',
        urlJson: '../../../assets/data/expenses/SaveExpenses/taxiTravelSave.json'
    }

    public static expensesGridAfterSaveTaxiTravel: RequestMocked = {
        description: 'Mock to display expense grid after to save a new expense with Taxi Travel - EX01',
        requestBody: `{"enterpriseId":"emiliano.gonzalez","periodEnd":"10-31-2020","countryKey":null,"externalNumber":"975X00"}`,
        requestType: RequestTypes.POST,
        url: 'ExpenseList',
        urlJson: '../../../assets/data/expenses-tab/expenses-list-after-travel-taxi-save-mock.json'
    }

    public static compareBody(params: string, expense: RequestMocked) {
        const bodyJson = JSON.parse(params);
        const bodySaveTaxiExpense = JSON.parse(expense.requestBody);
        if (expense.description === this.expensesSaveTaxiTravel.description) {
            return ((bodyJson.expenseTypeCd === bodySaveTaxiExpense.expenseTypeCd) &&
                    (bodyJson.periodEnd === bodySaveTaxiExpense.periodEnd) &&
                    (bodyJson.expenseId === bodySaveTaxiExpense.expenseId));
        }
        if (expense.description === this.expenseDetailsAddTaxiTravelTriggerByAmount.description) {
            return ((bodyJson.expenseTypeCd === bodySaveTaxiExpense.expenseTypeCd) &&
                    (bodyJson.periodEnd === bodySaveTaxiExpense.periodEnd) &&
                    (bodyJson.expenseId === bodySaveTaxiExpense.expenseId) &&
                    (bodyJson.triggeredBy === bodySaveTaxiExpense.triggeredBy));
        }
        if (expense.description === this.expenseDetailsAddTaxiTravelTriggerByAmount.description) {
            return ((bodyJson.expenseTypeCd === bodySaveTaxiExpense.expenseTypeCd) &&
                    (bodyJson.periodEnd === bodySaveTaxiExpense.periodEnd) &&
                    (bodyJson.expenseId === bodySaveTaxiExpense.expenseId) &&
                    (bodyJson.triggeredBy === bodySaveTaxiExpense.triggeredBy));
        }
        if (expense.description === this.expensesGridAfterSaveTaxiTravel.description) {
            // TODO (elias.e.wasinger): Add more validation, be care, long body request for this one.
            return (bodyJson.periodEnd === bodySaveTaxiExpense.periodEnd)
        }
    }
}
