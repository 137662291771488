import { Component, HostListener } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams, GridApi } from 'ag-grid-community';
import DropdownItem from '../../../../../shared/models/controls/dropdown/dropdown-item';

@Component({
  selector: 'myte-taxi-assignment-editor',
  templateUrl: './taxi-assignment-editor.component.html',
  styleUrls: ['./taxi-assignment-editor.component.sass']
})
export class TaxiAssignmentEditorComponent implements ICellEditorAngularComp {
  public expenseAssignmentLists: DropdownItem[] = [];
  public selectedAssignments: any;
  private api: GridApi;

  constructor() { }

  public getValue() {
   return this.selectedAssignments;
  }

  public agInit(params: any): void {
    this.api = params.api;
    this.selectedAssignments = params.value;
    this.expenseAssignmentLists = [...params.value.assignmentsList]; 
  }

  public isPopup?(): boolean {
    return true;
  }

  public ngAfterViewInit() {
    this.expenseAssignmentLists.unshift({key: -1, value: "--Select one--", isValid: true})
  }

  public updateAssignmentValue(assignment) {
    this.expenseAssignmentLists.forEach(as => {
      if (as.value === assignment.value){
        this.selectedAssignments.selectedExpenseCode = as.key;
        this.selectedAssignments.selectedExpenseCodeDescription = as.value;
      }
    });

    this.api.stopEditing();
  }

  @HostListener('keydown', ['$event']) onKeyDown(event) {
    if (event.key == 'Tab' || event.code == 'Tab' || event.keyCode == 9) {
      event.stopPropagation();
      event.preventDefault();
    }
    else if(event.key == 'Escape' || event.code == 'Escape' || event.keyCode == 27) {
      event.stopPropagation();
      this.api.stopEditing();
      var focusedCell = this.api.getFocusedCell();
      if(focusedCell != null && focusedCell != undefined){
        this.api.setFocusedCell(focusedCell.rowIndex, focusedCell.column.getColId());
      }
    }
    else if (event.key == 'ArrowDown' || event.code == 'ArrowDown' || event.keyCode == 40) {
      event.stopPropagation();
      event.preventDefault();
      var next = document.activeElement.nextElementSibling;
      if(next != null && next != undefined){
        (next as HTMLElement).focus()
      }
    }
    else if (event.key == 'ArrowUp' || event.code == 'ArrowUp' || event.keyCode == 38) {
      event.stopPropagation();
      event.preventDefault();
      var previous = document.activeElement.previousElementSibling;
      if(previous != null && previous != undefined){
        (previous as HTMLElement).focus()
      }
    }
  }

}
