import ElementList from './elementList';

export class PreferencesPopupList {
    public inOutElem: ElementList[];
    public inOutCheckboxElem: ElementList[];
    public personalElem: ElementList[];
    public locationElem: ElementList[];
    public uberElem: ElementList[];
    constructor(
        inOutElem?: ElementList[],
        inOutCheckboxElem?: ElementList[],
        personalElem?: ElementList[],
        locationElem?: ElementList[],
        uberElem?: ElementList[],
    ) {
        this.inOutElem = inOutElem;
        this.inOutCheckboxElem = inOutCheckboxElem;
        this.personalElem = personalElem;
        this.locationElem = locationElem;
        this.uberElem = uberElem;
    }
}