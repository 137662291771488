<form class="receipt-container"
(drop)="dropHandler($event)"
(dragenter)="dragEnterHandler($event)"
(dragover)="dragOverHandler($event)"
(dragleave)="dragLeaveHandler($event)"
>
  <input #inputfile type="file" myteSimplifyedUploadReceipt (cancel)="onCancelFileUpload()" (filesSelected)="onFileChange($event)">
  <ng-container *ngTemplateOutlet="selected"></ng-container>

  <ng-template #None>
    <div>
      <span>No Icon</span>
    </div>
  </ng-template>
  <ng-template #Neither>
    <button id="buttonReceipt" class="menu-item accessibility" (click)="handleClick()" placement="left-top" container="body" ngbTooltip="{{tooltip.camera}}">
      <mat-icon aria-hidden="true" [svgIcon]="icon.electronic"></mat-icon>
    </button>
  </ng-template>
  <ng-template #Electronic>
    <button id="buttonReceipt" class="menu-item accessibility" (click)="handleClick()" placement="left-top" container="body" ngbTooltip="{{tooltip.camera}}">
    <mat-icon aria-hidden="true" [svgIcon]="icon.electronic"></mat-icon>
    </button>
  </ng-template>
  <ng-template #Physical>
    <button id="buttonReceipt" *ngIf="icon.electronic" class="accessibility" (click)="handleClick()" placement="left-top" container="body" ngbTooltip="{{tooltip.camera}}">
      <mat-icon aria-hidden="true" [svgIcon]="icon.electronic"></mat-icon>
    </button>
    <button id="buttonReceipt" class="accessibility" (click)="handleClick()" placement="left-top" container="body" ngbTooltip="{{tooltip.envelope}}">
      <mat-icon aria-hidden="true" [svgIcon]="icon.physical"></mat-icon>
    </button>
  </ng-template>
  <ng-template #Both>
    <button id="buttonReceipt" class="accessibility" (click)="handleClick()" placement="left-top" container="body" ngbTooltip="{{tooltip.camera}}">
      <mat-icon aria-hidden="true" [svgIcon]="icon.electronic"></mat-icon>
    </button>
    <button id="buttonReceipt" class="accessibility" (click)="handleClick()" placement="left-top" container="body" ngbTooltip="{{tooltip.envelope}}">
      <mat-icon aria-hidden="true" [svgIcon]="icon.physical"></mat-icon>
    </button>
  </ng-template>
  <ng-template #Either>
    <button id="buttonReceipt" class="accessibility" (click)="handleClick()" placement="left-top" container="body" ngbTooltip="{{tooltip.camera}}">
      <mat-icon aria-hidden="true" [svgIcon]="icon.electronic"></mat-icon>
    </button>
    <span *ngIf="!value.electronicProvided && !value.physicalProvided">
      OR
    </span>
    <button id="buttonReceipt" *ngIf="icon.physical" class="accessibility" (click)="handleClick()" placement="left-top" container="body" ngbTooltip="{{tooltip.envelope}}">
      <mat-icon aria-hidden="true" [svgIcon]="icon.physical"></mat-icon>
    </button>
  </ng-template>
</form>
