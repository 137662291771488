<br>
<br>
<br>
<div class="container">
  <div class="row">
    <div class="col-12 text-center">
      <h1 style="padding-right: 18px; margin-bottom: 0;">
        <mat-icon aria-hidden="true" fontIcon="bug_report"></mat-icon>401
      </h1>
      <h2>Page not authorized</h2>
    </div>
  </div>
  <hr>
  <div class="row">
    <div class="col-12 text-center">
      <h4>You are not authorized to access this function which is only available for Japan reviewers.</h4>
    </div>
  </div>
</div>