import { MyteEvent } from './myte-event';
import { AppConfigService } from '@authServices/app-config.service';

export abstract class MyteEventHandler {
  private events: MyteEvent<any>[] = [];
  private appConfigData: any;

  constructor(appConfigService: AppConfigService, events?: MyteEvent<any>[]) {
    this.events = events;
    this.appConfigData = appConfigService.getAppConfig;
  }

  protected selectEvent<T>(id: string): MyteEvent<T> {
      let ev = this.events.find(x => x.eventId == id);
      if (!ev) throw new Error(`Event key: ${id} was never registered`);
      return ev;
  }

  protected dispatchEvent<T>(id: string, data: T): void {
    let ev = this.events.find(x => x.eventId == id);
    if (!ev) new Error(`Event key: ${id} was never registered`);
    ev.next(data);
    if(this.appConfigData.AdditionalConfig.eventDispatcherLog) {
      console.log('[MyTE Event Dispatcher] '+ id, data);
    }
  }

  protected registerNewEvent(event: MyteEvent<any>): void {
    this.events.push(event);
  }
}
