import { Injectable } from '@angular/core';
import { OAuthService, OAuthEvent } from 'angular-oauth2-oidc';
import { Router, NavigationEnd } from '@angular/router';
import { AppConfigService } from '../services/app-config.service';
import { environment } from '../../../environments/environment';
import { GlobalCacheService } from '../../shared/services/cache/global-cache.service';

@Injectable()
export class OAuth2Service {
  private isRouteRestored = false;
  private storage: any;
  private appConfigData: any;
  constructor(
    private oauthService: OAuthService,
    private router: Router,
    private appConfigService: AppConfigService,
    private globalCacheService: GlobalCacheService
  ) {
    this.storage = sessionStorage;
    this.appConfigData = this.appConfigService.getAppConfig;
  }

  public setup() {
    if (this.authenticationEnabled()) {
      this.setupConfiguration();
      this.setupAutomaticRefresh();
      this.oauthService.events.subscribe(({ type }: OAuthEvent) => {
        switch (type) {
          case 'token_received':
            break;
          // case 'silently_refreshed':
          //   this.logService.logWarning('AccessToken has been refreshed');
          //   break;
          // case 'session_terminated':
          //   this.oauthService.refreshToken();
          //   break;
          // case 'token_expires':
          //   this.oauthService.refreshToken();
          //   break;
        }
      });
      this.oauthService.tryLogin({});
    }
  }

  public authenticationEnabled(): boolean {
    return environment.name === 'Local';
  }

  public logout() {
    this.globalCacheService.clearCurrentSubordinateMode();
    this.globalCacheService.resetSessionStorage(this.globalCacheService.auditeeInfoKey);
    this.oauthService.logOut();
  }

  public handleAuthentication(url: string): boolean {
    url = url.split('?')[0].split('/')[1];
    let result = false;
    if (this.authenticationDisabled()) {
      return !result;
    } else if (!this.oauthService.hasValidAccessToken()) {
      this.oauthService.clearHashAfterLogin = true;
      this.oauthService.initImplicitFlow();
      return result;
    } else if (this.oauthService.hasValidAccessToken() && !this.getUserProfile()) {
      return false;
    } else {
      return !result;
    }
  }

  public IsAuthenticated(): boolean {
    return this.oauthService.hasValidAccessToken();
  }

  public removeHashFromUrl(): void {
    if (window.location.href.indexOf('#') >= 0) {
      window.location.hash = '';
    }

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.reStoreFirstRoute();
      }
    });
  }

  reStoreFirstRoute() {
    const currentRoute = this.router.url.split('/')[1];
    const item = this.storage.getItem('route');
    if (!this.isRouteRestored) {
      if (item != null && item != '') {
        const queryArray = item.split('?');
        const route: string = queryArray[0];
        if (queryArray.length > 1) {
          this.router.navigateByUrl(item);
        } else {
          this.router.navigate([route]);
        }
        if (currentRoute == route) {
          this.isRouteRestored = true;
        }
      }
    }
  }

  public getUserProfile() {
    return this.oauthService.getIdentityClaims();
  }

  private authenticationDisabled(): boolean {
    return !this.authenticationEnabled();
  }

  private setupConfiguration() {
    this.oauthService.configure({
      skipIssuerCheck: true,
      clearHashAfterLogin: true,
      issuer: this.appConfigData.AdfsEso.issuer,
      loginUrl: this.appConfigData.AdfsEso.loginUrl,
      oidc: this.appConfigData.AdfsEso.oidc,
      logoutUrl: this.appConfigData.AdfsEso.logoutUrl,
      redirectUri: this.appConfigData.AdfsEso.redirectUri,
      clientId: this.appConfigData.AdfsEso.clientId,
      scope: this.appConfigData.AdfsEso.scope,
      responseType: this.appConfigData.AdfsEso.responseType
    });
    this.oauthService.setStorage(sessionStorage);
  }

  private setupAutomaticRefresh() {
    this.oauthService.silentRefreshRedirectUri =
    this.appConfigData.AdfsEso.silentRefreshRedirectUri;
    this.oauthService.setupAutomaticSilentRefresh();
  }
}
