import { Injectable } from '@angular/core';
import { LocationTemplate } from '../../../shared/models/locations';
import { MyteEventHandler } from '../../../shared/services/events/myte-event-handler';
import { SetTemplateLocationEvent } from '../../../shared/services/events/time-entry-template/time-entry-template-events';
import { Subject } from 'rxjs';
import { AppConfigService } from '../../../auth/services/app-config.service';

@Injectable()
export class TimeEntryTemplateEventsService extends MyteEventHandler {
  constructor(appConfigService: AppConfigService) {
    super(appConfigService, [new SetTemplateLocationEvent()])
   }

   public setLocations(template: LocationTemplate): void {
    this.dispatchEvent(SetTemplateLocationEvent.eventId, template);
   }

   public get selectLocationsSetEvent(): Subject<LocationTemplate> {
     return this.selectEvent<LocationTemplate>(SetTemplateLocationEvent.eventId);
   }

}
