import { Pipe, PipeTransform } from '@angular/core';
import { FormatDateType } from '@shared/utils/constants';
import * as moment from 'moment';
import { DateformatPipe } from './dateformat.pipe';

@Pipe({
  name: 'localDateFormated'
})
export class LocalDateFormatPipe implements PipeTransform
{

  transform(date: any,formatype:string="shortDate",alreadyinformat:boolean=false): any
    {
        if (!date || !Date.parse(date)) { return date; }
        let language=navigator.language;
        switch (language)
        {
            case 'en-IN':
                return this.transformToLocalizeDate(date,formatype,alreadyinformat);
                break;
            default:
                return this.transformToLocalizeDate(date.toLocaleString(language),formatype,alreadyinformat);
                break;
        }
    }

    public transformToLocalizeDate(value: string,formatype:string="shortDate",alreadyinformat:boolean=false) {
        let formatlang=navigator.language;
        if(formatype=="shortDate")
        {
            if(alreadyinformat && navigator.language!='en-IN')
                return value;
            else if(alreadyinformat && navigator.language=='en-IN')
                return value.replace("/","-").replace("/","-");
            else
            {
                let languagedate=moment(value, "L", formatlang).toDate();
                 return languagedate.toLocaleString(formatlang, {
                    year: "numeric",
                month: "2-digit",
                day: "2-digit"
                });
            }
        }
       else if(formatype=="full")
       {
        if(navigator.language=='en-IN')
        {
            let languagedate=moment(value).toDate();
            return languagedate.toLocaleString(undefined, {
                        year: "numeric",
                    month: "2-digit",
                    day: "2-digit"
                    }).replace("/","-").replace("/","-");
        }
        let languagedate=moment(value, "L", formatlang).toDate();
        return languagedate.toLocaleString(formatlang, {
            year: "numeric",
        month: "2-digit",
        day: "2-digit"
        });
       }
       else if(formatype=="short" && !alreadyinformat)
       {
            var languagedate = moment(value).toDate();
            if(navigator.language=='en-IN')
            {
                let languagedatestrings= languagedate.toLocaleString(formatlang, {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                    hour12: true
                });
                return languagedatestrings.replace("/","-").replace("/","-");
            }
            return languagedate.toLocaleString(formatlang, {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour12: true
        });
      }

      else if(formatype=="shortnoseconds" && !alreadyinformat)
       {
            let languagedate = moment(value).toDate();
            if(navigator.language=='en-IN')
            {
                let languagedatestrings= languagedate.toLocaleString(formatlang, {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true
                });
                return languagedatestrings.replace("/","-").replace("/","-");
            }
            if(formatlang == 'mr' || formatlang == 'bn' || formatlang == 'bn-IN') {
                return new DateformatPipe().transform(languagedate, FormatDateType.FormatDateWithTimeHoursMinutes);
                
            }
            return languagedate.toLocaleString(formatlang, {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            hour12: true
        });
      }

    }


}
