import { Injectable } from '@angular/core'
import { TimeReportUtils } from '@sharedUtils/timeReport.utils';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { PersonalCommuteInput, UserPreferencesInput } from '@sharedClients/timereport-api-client';
import SaveUserPreferencesInput from '@shared/models/saveUserPreferencesInput';
import { TimeReportApiClient, UserPreferencesOutput} from  '@sharedClients/timereport-api-client';
import UserPreference from '@shared/models/userPreference';
import { GlobalCacheService } from '../cache/global-cache.service';
import { GlobalEventsService } from '../events/global-events.service';
import { LogService } from '../log/log.service';
import { MyteBaseService } from '../myte-base.service';
import { UserProfileServiceMapper } from './user-profile.service.mapper';
import { UserService } from './user.service';
import { PeriodEndService } from '../periodend/periodend.service';
import Subordinate from '@shared/models/subordinates/subordinate';

@Injectable({
  providedIn: 'root'
})
export class UserProfileService extends MyteBaseService {

  constructor(userService: UserService,
    public globalCacheService: GlobalCacheService,
    public globalEventsService: GlobalEventsService,
    public logService: LogService,
    private timereportApi: TimeReportApiClient,
    private periodEndService: PeriodEndService) {
    super(userService, globalCacheService, globalEventsService, logService);
  }

  public getProfileByPeriodEnd(periodEnd: Date, shouldReturnTRStatus: boolean = false): Observable<UserPreference> {
    let subordinate = this.globalCacheService.getCurrentSubordinate();
    let enterpriseId = subordinate ? subordinate.enterpriseId : this.userEid;
    let loginUserEid = this.supervisorEid;
    if (subordinate && !loginUserEid) {
      loginUserEid = this.userService.getUser().enterpriseId.toLowerCase();
    }
    let request = this.timereportApi.getUserPreferences(enterpriseId, periodEnd, loginUserEid, this.viewMode, shouldReturnTRStatus)
      .pipe(map(res => {
        if (shouldReturnTRStatus) {
          let periodEnd = this.globalCacheService.getPeriodEnd();
          let timeReport = TimeReportUtils.createTimeReport(periodEnd, res.timeReportStatus, this.viewMode);
          this.globalCacheService.handleResetTimeReport(periodEnd);
          this.globalCacheService.handleTimeReport(periodEnd, of(timeReport));
        }
        let userPreference = UserProfileServiceMapper.mapUserPreferences(res);
        this.globalCacheService.setIsAvanadeUser(res.isAvanadeUser);
        return userPreference;
      }),
        catchError(err => {
          this.logService.logError(err, true);
          return of(null);
        })
      );
    return this.globalCacheService.handleUserPreferences(enterpriseId, periodEnd, request);
  }

  public getUserPreferencesObservable(): Observable<UserPreference> {
    const subordinate: Subordinate = this.globalCacheService.getCurrentSubordinate();
    const loginEnterpriseId = subordinate && !this.supervisorEid ? this.loginEid : this.supervisorEid;
    const getUserPreferencesObservable = this.timereportApi.getUserPreferences(this.userEid, this.periodEndService.getActivePeriod(), loginEnterpriseId, this.viewMode, false)
    .pipe(map((userPreferencesOutput: UserPreferencesOutput) => {
      this.globalCacheService.setIsAvanadeUser(userPreferencesOutput.isAvanadeUser);
      return UserProfileServiceMapper.mapUserPreferences(userPreferencesOutput);
    }))
    .pipe(catchError((error: any) => {
      this.logService.logError(error, true);
      return of(undefined);
    }));
    return this.globalCacheService.handleUserPreferences(this.userEid, this.periodEndService.getActivePeriod(), getUserPreferencesObservable);
  }

  public saveUserPreferences(savePersonalInput: SaveUserPreferencesInput, periodEnd?: Date): Observable<string> {
    let contract = new UserPreferencesInput();
    contract.enterpriseId = this.userEid;
    contract.onCallAndSWTimeApprovers = savePersonalInput.onCallAndSWTimeApprovers;
    contract.workShiftApprovers = savePersonalInput.workshiftApprovers;
    contract.approvers = savePersonalInput.approvers;
    contract.timeReportReviewer = savePersonalInput.reviewers;
    contract.delegates = savePersonalInput.delegates;
    contract.overtimeApprovers = savePersonalInput.overtimeApprovers;
    contract.allowanceApprovers = savePersonalInput.allowanceApprovers;
    contract.notifyOfSubmission = savePersonalInput.submission;
    contract.homeOfficeLocationOneCode = savePersonalInput.homeOffice1Code;
    contract.homeOfficeLocationTwoCode = savePersonalInput.homeOffice2Code;
    contract.residentLocationCd = savePersonalInput.residentLocationCd;
    contract.uberForBusiness = savePersonalInput.uberForBusiness;
    contract.lyftForBusiness = savePersonalInput.lyftAcceptanceIndicator;
    contract.personalCommute = new PersonalCommuteInput();
    contract.personalCommute.numberOfKmMiles = savePersonalInput.personalCommuteData.numberOfKmMiles;
    contract.personalCommute.otherTransportationExpenses = savePersonalInput.personalCommuteData.otherTransportationExpenses;
    contract.personalCommute.carPlan = savePersonalInput.personalCommuteData.carPlan;
    contract.personalCommute.carType = savePersonalInput.personalCommuteData.carType;
    contract.personalCommute.carConsumption = savePersonalInput.personalCommuteData.carConsumption;
    contract.personalCommute.carPlanChargeNumber = savePersonalInput.personalCommuteData.carPlanChargeNumber;
    contract.personalCommute.carRegistrationNo = savePersonalInput.personalCommuteData.carRegistrationNo;
    contract.personalCommute.companyCar = savePersonalInput.personalCommuteData.companyCar;
    contract.personalCommute.vehicleType = savePersonalInput.personalCommuteData.vehicleType;
    contract.personalCommute.methodOfTravel = savePersonalInput.personalCommuteData.methodOfTravel;
    contract.isApprovalRequested = savePersonalInput.isApprovalRequested;
    contract.isBackUpApproverActive = savePersonalInput.isBackupApprover;
    contract.isAuditDelegateRequested = savePersonalInput.isAuditDelegateRequested;
    contract.compensatoryTabIndicator = savePersonalInput.compensatoryTabIndicator;
    contract.locationReasonId = savePersonalInput.locationReasonId;
    contract.locationTypeId = savePersonalInput.locationTypeId;

    if (periodEnd != undefined)
      this.globalCacheService.clearTimeReportSummary(periodEnd, this.userEid);
    this.globalCacheService.clearTimeReportSettingsBuffer();
    return this.globalCacheService.handleSaveUserPreferences(this.timereportApi.saveUserPreferences(this.periodEndService.getActivePeriod(), this.supervisorEid, this.viewMode, contract))
      .pipe(catchError(err => {
        return of(err.message);
      }));
  }
}
